import { type LoaderFunctionArgs } from 'react-router'
import { client } from '#src/main'
import { criteriaAssignedQuery } from '#src/routes/calibrate/ecosystem-management/criteria/queries'
import { ecosystemQuery, ecosystemsTemplatesQuery } from '#src/routes/calibrate/ecosystem-management/ecosystem/queries'
import { getCompany } from '#src/utils/server/company'

export type EcosystemLoaderResponse = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)

	return {
		ecosystem: params.ecosystemId ? await client.fetchQuery(ecosystemQuery(company.id, params.ecosystemId)) : null,
		criteria: params.ecosystemId
			? await client.fetchQuery(criteriaAssignedQuery(company.id, params.ecosystemId))
			: null,
		ecosystemTemplates: await client.fetchQuery(ecosystemsTemplatesQuery(company.id)),
	}
}
