import { ACCOUNT_EXPORT_JOB_STATUS } from '#src/api/icp/company/account-export/schemas'

export const JobStatusName: Record<ACCOUNT_EXPORT_JOB_STATUS, string> = {
	[ACCOUNT_EXPORT_JOB_STATUS.InQueue]: 'In queue',
	[ACCOUNT_EXPORT_JOB_STATUS.InProgress]: 'In progress...',
	[ACCOUNT_EXPORT_JOB_STATUS.Completed]: 'Completed',
	[ACCOUNT_EXPORT_JOB_STATUS.Error]: 'Error',
} as const

export enum FORM_STEPS {
	Query = 'query',
	Confirm = 'confirm',
}
