import _ from 'lodash'
import {
	type Context,
	createContext,
	createElement,
	type ReactElement,
	type ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { useNavigate } from 'react-router'
import { type MODAL_NAME } from '#src/constants/modals'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TGeneric<T = any> = (props: T) => ReactElement

type ModalState<T extends TGeneric> = {
	component: T
	settings?: Partial<{
		routeToRedirectOnClose: string
		routeToRedirectOnOpen: string
	}>
	name?: `${MODAL_NAME}`
} & (Parameters<T>[0] extends undefined ? object : { props: Parameters<T>[0] })

export type ModalsContextType<T extends TGeneric = TGeneric> = {
	activeModal: ModalState<T> | undefined
	activeModals: ModalState<T>[]
	openModal: <K extends TGeneric>(data: ModalState<K>) => void
	closeModal: (name?: `${MODAL_NAME}`, redirect?: string) => void
}

export const ModalsContext = createContext<ModalsContextType | undefined>(undefined)

export const ModalsProvider = <T extends TGeneric>({ children }: { children: ReactNode }) => {
	const [activeModals, setActiveModals] = useState<ModalsContextType<T>['activeModals']>([])
	const Context = ModalsContext as unknown as Context<ModalsContextType<T>>

	const navigate = useNavigate()

	const closeModal = useCallback(
		(name?: `${MODAL_NAME}`, redirect?: string) => {
			setActiveModals(curr => {
				const modalToRemove = name ? curr.find(modal => modal.name === name) : curr[0]
				if (!modalToRemove) {
					return curr
				}
				if (redirect || modalToRemove.settings?.routeToRedirectOnClose) {
					void navigate((redirect || modalToRemove.settings?.routeToRedirectOnClose) as string)
				}
				return curr.filter(modal => modal !== modalToRemove)
			})
		},
		[setActiveModals, navigate],
	)
	const openModal = useCallback(
		<K extends TGeneric>(data: ModalState<K>) => {
			setActiveModals(curr => {
				if (_.isEqual(curr[0], data)) {
					return curr
				}
				if (data.settings?.routeToRedirectOnOpen) {
					void navigate(data.settings.routeToRedirectOnOpen)
				}
				return [data, ...curr] as ModalState<T>[]
			})
		},
		[setActiveModals, navigate],
	)

	const activeModal: ModalState<T> | undefined = useMemo(() => activeModals[0], [activeModals])

	useEffect(() => {
		// this useEffect is used to catch modal closing from server actions
		const listener = (e: MessageEvent<unknown>) => {
			if (e.data && typeof e.data === 'object' && 'type' in e.data && e.data.type === 'modal' && 'name' in e.data) {
				closeModal(
					e.data.name as MODAL_NAME,
					'redirect' in e.data && e.data.redirect ? (e.data.redirect as string) : undefined,
				)
			}
		}

		window.addEventListener('message', listener)
		return () => window.removeEventListener('message', listener)
	}, [closeModal])

	return (
		<Context.Provider value={{ activeModal, activeModals, closeModal, openModal }}>
			{children}
			{activeModal && createElement(activeModal.component, 'props' in activeModal ? activeModal.props : undefined)}
		</Context.Provider>
	)
}
