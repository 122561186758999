// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
//TODO: Fix typescript issues

/**
 * DatePicker Component
 * This component is based on the Tremor Date Picker [v1.0.5].
 * It was refactored as little as possible. Going to be updated in the future.
 * Still contains unwanted class names and other stuff.
 * Refer to the official documentation for more details:
 * https://tremor.so/docs/inputs/date-range-picker#api-reference-date-range-picker
 */
import { Time } from '@internationalized/date'
import * as PopoverPrimitives from '@radix-ui/react-popover'
import { type AriaTimeFieldProps, type TimeValue, useDateSegment, useTimeField } from '@react-aria/datepicker'
import { useTimeFieldState, type DateFieldState, type DateSegment } from '@react-stately/datepicker'
import { format, type Locale } from 'date-fns'
import { enUS } from 'date-fns/locale'
import {
	useState,
	useEffect,
	useRef,
	forwardRef,
	useImperativeHandle,
	type ComponentProps,
	type ElementRef,
} from 'react'
import { type Matcher } from 'react-day-picker'
import { Button } from '#src/components/ui/button'
import { Icon, type IconProps } from '#src/components/ui/icon'
import { inputCommonClasses } from '#src/theme'
import { type ClassName } from '#src/types/styles'
import { cn } from '#src/utils/misc'
import { Calendar as CalendarPrimitive } from './Calendar'

export const DISABLED_DATE_WITH_TOGGLE = '1970-01-01T00:00:00.000Z'

export type DateRange = [string | undefined, string | undefined] | undefined

const dateRangePresets: DateRangePreset[] = [
	{
		label: 'Today',
		dateRange: [
			new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
			new Date(new Date().setHours(23, 59, 59, 999)).toISOString(),
		],
	},
	{
		label: 'Yesterday',
		dateRange: [
			new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0, 0, 0, 0)).toISOString(),
			new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(23, 59, 59, 999)).toISOString(),
		],
	},
	{
		label: 'Last week',
		dateRange: [new Date(new Date().setDate(new Date().getDate() - 7)).toISOString(), new Date().toISOString()],
	},
	{
		label: 'Last month',
		dateRange: [new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString(), new Date().toISOString()],
	},
]

const isBrowserLocaleClockType24h = () => {
	const lang = typeof window !== 'undefined' ? window.navigator.language : 'en-US'
	const hr = new Intl.DateTimeFormat(lang, { hour: 'numeric' }).format()
	return Number.isInteger(Number(hr))
}

const formatDate = (date: Date | string, locale: Locale, includeTime?: boolean): string => {
	if (date instanceof Date && !isNaN(date.getTime())) {
		const usesAmPm = !isBrowserLocaleClockType24h()
		if (includeTime) {
			return usesAmPm ? format(date, 'dd MMM, yyyy h:mm a', { locale }) : format(date, 'dd MMM, yyyy HH:mm', { locale })
		}
		return format(date, 'dd MMM, yyyy', { locale })
	}
	return ''
}

const parseDateString = (value?: string | Date): Date | undefined => {
	if (!value) return undefined

	if (value instanceof Date) return value

	const d = new Date(value)
	return Number.isNaN(d.getTime()) ? undefined : d
}

const compareDates = (d1: Date, d2: Date) =>
	d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear()

const compareRanges = (r1: DateRange, r2: DateRange) => {
	if (!r1 || !r2) return false
	const d1From = parseDateString(r1[0])
	const d1To = parseDateString(r1[1])
	const d2From = parseDateString(r2[0])
	const d2To = parseDateString(r2[1])
	const sameFrom = d1From && d2From && compareDates(d1From, d2From)
	const sameTo = d1To && d2To && compareDates(d1To, d2To)
	return !!sameFrom && !!sameTo
}

/* ----------------------------------------------------------------------------
  Time Input / Segments
----------------------------------------------------------------------------- */

type TimeSegmentProps = {
	segment: DateSegment
	state: DateFieldState
}

const TimeSegment = ({ segment, state }: TimeSegmentProps) => {
	const ref = useRef<HTMLDivElement>(null)
	const { segmentProps } = useDateSegment(segment, state, ref)

	const isColon = segment.type === 'literal' && segment.text === ':'
	const isSpace = segment.type === 'literal' && segment.text === ' '

	const isDecorator = isColon || isSpace

	return (
		<div
			{...segmentProps}
			ref={ref}
			className={cn(
				'rounded-md sm:text-sm relative block w-full appearance-none border px-2.5 py-1.5 text-left uppercase tabular-nums shadow-sm outline-none transition',
				'border-gray-300 dark:border-gray-800',
				'text-gray-900 dark:text-gray-50',
				'dark:bg-gray-950 bg-white',
				'invalid:border-red-500 invalid:ring-red-200 group-aria-[invalid=true]/time-input:border-red-500 group-aria-[invalid=true]/time-input:ring-red-200 group-aria-[invalid=true]/time-input:dark:ring-red-400/20 invalid:ring-2 group-aria-[invalid=true]/time-input:ring-2',
				{
					'text-gray-400 !w-fit border-none bg-transparent px-0 shadow-none': isDecorator,
					hidden: isSpace,
					'border-gray-300 text-gray-400 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-500 bg-gray-100':
						state.isDisabled,
					'!text-gray-400 !bg-transparent': !segment.isEditable,
				},
			)}
		>
			<span
				aria-hidden="true"
				className={cn('text-gray-700 sm:text-sm pointer-events-none block w-full text-left', {
					hidden: !segment.isPlaceholder,
					'h-0': !segment.isPlaceholder,
				})}
			>
				{segment.placeholder}
			</span>
			{segment.isPlaceholder ? ' ' : segment.text}
		</div>
	)
}

type TimeInputProps = Omit<
	AriaTimeFieldProps<TimeValue>,
	'label' | 'shouldForceLeadingZeros' | 'description' | 'errorMessage'
>

export const TimeInput = forwardRef<HTMLDivElement, TimeInputProps>(({ hourCycle, ...props }, ref) => {
	const innerRef = useRef<HTMLDivElement>(null)

	useImperativeHandle(ref, () => innerRef.current || document.createElement('div'))

	const locale = typeof window !== 'undefined' ? window.navigator.language : 'en-US'

	const state = useTimeFieldState({
		hourCycle,
		locale,
		shouldForceLeadingZeros: true,
		autoFocus: true,
		...props,
	})

	const { fieldProps } = useTimeField({ hourCycle, shouldForceLeadingZeros: true, ...props }, state, innerRef)

	return (
		<div {...fieldProps} ref={innerRef} className="group/time-input inline-flex w-full gap-x-2">
			{state.segments.map((segment, i) => (
				<TimeSegment key={i} segment={segment} state={state} />
			))}
		</div>
	)
})
TimeInput.displayName = 'TimeInput'

/* ----------------------------------------------------------------------------
  Trigger
----------------------------------------------------------------------------- */

type TriggerProps = ComponentProps<'button'> & {
	placeholder?: string
	triggerRightIconProps?: IconProps | null
	triggerLeftIconProps?: IconProps | null
	placeholderClassName?: ClassName
}

export const Trigger = forwardRef<HTMLButtonElement, TriggerProps>(
	(
		{ className, children, placeholder, triggerRightIconProps, triggerLeftIconProps, placeholderClassName, ...props },
		forwardedRef,
	) => {
		return (
			<PopoverPrimitives.Trigger asChild>
				<button
					ref={forwardedRef}
					className={cn(inputCommonClasses, className)}
					style={{ cursor: props.disabled ? 'not-allowed' : 'pointer' }}
					{...props}
				>
					{triggerLeftIconProps === null ? null : triggerLeftIconProps ? (
						<Icon {...triggerLeftIconProps} />
					) : (
						<Icon name="calendar" className="mr-2 text-neutral-3-fg" />
					)}
					<span className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap text-left">
						{children ||
							(placeholder && <span className={cn('text-neutral-3-fg', placeholderClassName)}>{placeholder}</span>)}
					</span>
					{triggerRightIconProps === null ? null : triggerRightIconProps ? (
						<Icon {...triggerRightIconProps} />
					) : (
						<Icon name="chevron-down" className="text-neutral-3-fg" />
					)}
				</button>
			</PopoverPrimitives.Trigger>
		)
	},
)
Trigger.displayName = 'DatePicker.Trigger'

/* ----------------------------------------------------------------------------
  Calendar Popover
----------------------------------------------------------------------------- */

export const CalendarPopover = forwardRef<
	ElementRef<typeof PopoverPrimitives.Content>,
	ComponentProps<typeof PopoverPrimitives.Content>
>(({ align, className, children, ...props }, forwardedRef) => (
	<PopoverPrimitives.Portal>
		<PopoverPrimitives.Content
			ref={forwardedRef}
			sideOffset={5}
			side="bottom"
			align={align}
			avoidCollisions
			onOpenAutoFocus={e => e.preventDefault()}
			className={cn(
				'relative z-50 w-fit rounded border border-neutral-1-bd shadow-sm',
				'min-w-[calc(var(--radix-select-trigger-width)-2px)] max-w-[95vw]',
				'dark:bg-gray-950 bg-white',
				'will-change-[transform,opacity]',
				'data-[state=closed]:animate-hide',
				'data-[state=open]:data-[side=bottom]:animate-slideDownAndFade data-[state=open]:data-[side=left]:animate-slideLeftAndFade data-[state=open]:data-[side=right]:animate-slideRightAndFade data-[state=open]:data-[side=top]:animate-slideUpAndFade',
				className,
			)}
			{...props}
		>
			{children}
		</PopoverPrimitives.Content>
	</PopoverPrimitives.Portal>
))
CalendarPopover.displayName = 'DatePicker.CalendarPopover'

/* ----------------------------------------------------------------------------
  Presets
----------------------------------------------------------------------------- */

type Preset = {
	label: string
}

export type DateRangePreset = Preset & {
	dateRange: DateRange
}

export type DatePreset = Preset & {
	date: Date
}

type PresetContainerProps<TPreset extends Preset, TValue> = {
	presets: TPreset[]
	onSelect: (value: TValue) => void
	currentValue?: TValue
}

export const PresetContainer = <TPreset extends Preset, TValue>({
	presets,
	onSelect,
	currentValue,
}: PresetContainerProps<TPreset, TValue>) => {
	const isDateRangePresets = (p: TPreset): p is DateRangePreset => 'dateRange' in p
	const isDatePresets = (p: TPreset): p is DatePreset => 'date' in p

	const matchesCurrent = (preset: TPreset) => {
		if (isDateRangePresets(preset)) {
			return currentValue && compareRanges(currentValue as DateRange, preset.dateRange)
		}
		if (isDatePresets(preset)) {
			return false
		}
		return false
	}

	const handleClick = (preset: TPreset) => {
		if (isDateRangePresets(preset)) {
			onSelect(preset.dateRange as TValue)
		} else if (isDatePresets(preset)) {
			onSelect(preset.date as TValue)
		}
	}

	return (
		<ul className="flex items-start gap-x-2 sm:flex-col">
			{presets.map((preset, index) => (
				<li key={index} className="sm:py-px sm:w-full">
					<button
						title={preset.label}
						className={cn(
							'sm:text-sm relative w-full overflow-hidden text-ellipsis whitespace-nowrap rounded border px-2.5 py-1 text-left text-body-md shadow-sm outline-none transition-all sm:border-none sm:py-2 sm:shadow-none',
							'focus-visible:dark:bg-gray-900 border-neutral-1-bd hover:bg-neutral-1-bg-hover focus-visible:bg-gray-100',
							{
								'bg-brand-1-bg': matchesCurrent(preset),
							},
						)}
						onClick={() => handleClick(preset)}
						aria-label={`Select ${preset.label}`}
					>
						<span>{preset.label}</span>
					</button>
				</li>
			))}
		</ul>
	)
}
PresetContainer.displayName = 'DatePicker.PresetContainer'

/* ----------------------------------------------------------------------------
  Picker Props
----------------------------------------------------------------------------- */

export type CalendarProps = {
	fromYear?: number
	toYear?: number
	fromMonth?: Date
	toMonth?: Date
	fromDay?: Date
	toDay?: Date
	fromDate?: Date
	toDate?: Date
	locale?: Locale
}

export type Translations = {
	cancel?: string
	apply?: string
	start?: string
	end?: string
	range?: string
}

type BasePickerProps = CalendarProps & {
	className?: string
	disabled?: boolean
	disabledDays?: Matcher | Matcher[] | undefined
	required?: boolean
	showTimePicker?: boolean
	placeholder?: string
	enableYearNavigation?: boolean
	disableNavigation?: boolean
	id?: string
	translations?: Translations
	align?: 'center' | 'end' | 'start'
	'aria-invalid'?: boolean
	'aria-label'?: string
	'aria-labelledby'?: string
	'aria-required'?: boolean
}

/* ----------------------------------------------------------------------------
  Single DatePicker (unchanged logic)
----------------------------------------------------------------------------- */

export type DatePickerProps = BasePickerProps & {
	presets?: DatePreset[]
	value?: Date
	onChange?: (date: Date | undefined) => void
	asISOString?: boolean
}

const SingleDatePicker = ({
	value,
	onChange,
	presets,
	disabled,
	disabledDays,
	disableNavigation,
	className,
	showTimePicker,
	placeholder = 'Select date',
	translations,
	enableYearNavigation = false,
	locale = enUS,
	align = 'start',
	asISOString = true,
	...props
}: DatePickerProps) => {
	const [open, setOpen] = useState(false)
	const initialValue = parseDateString(value)

	const [tempDate, setTempDate] = useState<Date | undefined>(initialValue)
	const [month, setMonth] = useState<Date | undefined>(initialValue)
	const [tempTime, setTempTime] = useState<Time>(() =>
		initialValue ? new Time(initialValue.getHours(), initialValue.getMinutes()) : new Time(0, 0),
	)

	useEffect(() => {
		const nextValue = parseDateString(value)
		setTempDate(nextValue)
		if (nextValue) {
			setMonth(nextValue)
			setTempTime(new Time(nextValue.getHours(), nextValue.getMinutes()))
		} else {
			setTempTime(new Time(0, 0))
		}
	}, [value])

	const handleOpenChange = (nextOpen: boolean) => {
		if (!nextOpen) {
			const revertValue = parseDateString(value)
			setTempDate(revertValue)
			if (revertValue) {
				setMonth(revertValue)
				setTempTime(new Time(revertValue.getHours(), revertValue.getMinutes()))
			} else {
				setTempTime(new Time(0, 0))
			}
		}
		setOpen(nextOpen)
	}

	const handleDateSelect = (selected: Date | undefined) => {
		if (showTimePicker && selected) {
			selected.setHours(tempTime.hour)
			selected.setMinutes(tempTime.minute)
		}
		setTempDate(selected)
	}

	const handleTimeChange = (t: Time | null) => {
		if (!t) return
		setTempTime(t)
		if (tempDate) {
			const updated = new Date(tempDate.getTime())
			updated.setHours(t.hour)
			updated.setMinutes(t.minute)
			setTempDate(updated)
		}
	}

	const handleCancel = () => {
		const revertValue = parseDateString(value)
		setTempDate(revertValue)
		if (revertValue) {
			setTempTime(new Time(revertValue.getHours(), revertValue.getMinutes()))
		} else {
			setTempTime(new Time(0, 0))
		}
		setOpen(false)
	}

	const handleApply = () => {
		onChange?.(asISOString ? tempDate?.toISOString() : tempDate)
		setOpen(false)
	}

	const formattedDate = tempDate ? formatDate(tempDate, locale, showTimePicker) : null

	return (
		<PopoverPrimitives.Root open={open} onOpenChange={handleOpenChange} tremor-id="tremor-raw">
			<Trigger
				placeholder={placeholder}
				disabled={disabled}
				className={className}
				aria-required={props.required || props['aria-required']}
				aria-invalid={props['aria-invalid']}
				aria-label={props['aria-label']}
				aria-labelledby={props['aria-labelledby']}
			>
				{tempDate?.toISOString() !== DISABLED_DATE_WITH_TOGGLE && formattedDate}
			</Trigger>
			<CalendarPopover align={align}>
				<div className="flex">
					<div className="flex flex-col sm:flex-row sm:items-start">
						{presets && presets.length > 0 && (
							<div
								className={cn(
									'relative flex h-14 w-full items-center sm:h-full sm:w-40',
									'dark:border-gray-800 border-b border-neutral-1-bd sm:border-b-0 sm:border-r',
									'overflow-auto',
								)}
							>
								<div className="absolute px-2 pr-2 sm:inset-0 sm:left-0 sm:py-2">
									<PresetContainer currentValue={tempDate} presets={presets} onSelect={handleDateSelect} />
								</div>
							</div>
						)}
						<div>
							<CalendarPrimitive
								mode="single"
								month={month}
								onMonthChange={setMonth}
								selected={tempDate}
								onSelect={handleDateSelect}
								disabled={disabledDays}
								locale={locale}
								enableYearNavigation={enableYearNavigation}
								disableNavigation={disableNavigation}
								initialFocus
								{...props}
							/>
							{showTimePicker && (
								<div className="dark:border-gray-800 border-t border-neutral-1-bd p-3">
									<TimeInput
										aria-label="Time"
										onChange={handleTimeChange}
										isDisabled={!tempDate}
										value={tempTime}
										isRequired={props.required}
									/>
								</div>
							)}
							<div className="flex items-center gap-x-2 border-t border-neutral-1-bd p-2">
								<Button
									variant="secondary"
									className="h-8 w-full text-neutral-3-fg"
									type="button"
									onClick={handleCancel}
								>
									{translations?.cancel ?? 'Cancel'}
								</Button>
								<Button variant="secondary" className="h-8 w-full" type="button" onClick={handleApply}>
									{translations?.apply ?? 'Done'}
								</Button>
							</div>
						</div>
					</div>
				</div>
			</CalendarPopover>
		</PopoverPrimitives.Root>
	)
}

/* ----------------------------------------------------------------------------
  Range DatePicker (refactored to use DateRange as [string|undefined, string|undefined])
----------------------------------------------------------------------------- */

export type DateRangePickerProps = BasePickerProps & {
	presets?: DateRangePreset[]
	value?: DateRange
	onChange?: (dateRange: DateRange | undefined) => void
	asISOStringArray?: boolean
	disablePresets?: boolean
	triggerRightIconProps?: IconProps | null
	triggerLeftIconProps?: IconProps | null
	placeholderClassName?: ClassName
}

const RangeDatePicker = ({
	value,
	onChange,
	presets = dateRangePresets,
	disabled,
	disableNavigation,
	disabledDays,
	enableYearNavigation = false,
	locale = enUS,
	showTimePicker,
	placeholder = 'Select date range',
	translations,
	align = 'start',
	className,
	asISOStringArray = true,
	disablePresets,
	triggerRightIconProps,
	triggerLeftIconProps,
	placeholderClassName,
	...props
}: DateRangePickerProps) => {
	const [open, setOpen] = useState(false)
	const start = parseDateString(value?.[0])
	const end = parseDateString(value?.[1])

	const [tempRange, setTempRange] = useState<DateRange>(value)
	const [month, setMonth] = useState<Date | undefined>(start)
	const [startTime, setStartTime] = useState<TimeValue | null>(() =>
		start ? new Time(start.getHours(), start.getMinutes()) : new Time(0, 0),
	)
	const [endTime, setEndTime] = useState<TimeValue | null>(() =>
		end ? new Time(end.getHours(), end.getMinutes()) : new Time(0, 0),
	)

	const parseValue = () => {
		setTempRange(value)

		if (!value || (!value[0] && !value[1])) {
			setTempRange(undefined)
			return
		}

		const freshStart = parseDateString(value?.[0])
		const freshEnd = parseDateString(value?.[1])

		setMonth(freshStart)
		setStartTime(freshStart ? new Time(freshStart.getHours(), freshStart.getMinutes()) : new Time(0, 0))
		setEndTime(freshEnd ? new Time(freshEnd.getHours(), freshEnd.getMinutes()) : new Time(0, 0))
	}

	useEffect(() => {
		parseValue()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value])

	const handleOpenChange = (nextOpen: boolean) => {
		if (!nextOpen) {
			parseValue()
		}
		setOpen(nextOpen)
	}

	// DayPicker range selection passes { from: Date|undefined; to: Date|undefined }
	const handleRangeChange = (newSelection?: { from?: Date; to?: Date }) => {
		if (!newSelection?.from && !newSelection?.to) {
			setTempRange(undefined)
			return
		}
		const fromDate = newSelection?.from ? new Date(newSelection.from) : undefined
		const toDate = newSelection?.to ? new Date(newSelection.to) : undefined

		if (showTimePicker) {
			if (fromDate && startTime) {
				fromDate.setHours(startTime.hour)
				fromDate.setMinutes(startTime.minute)
			}

			if (toDate && endTime) {
				toDate.setHours(endTime.hour)
				toDate.setMinutes(endTime.minute)
			}
		}

		setTempRange([fromDate?.toISOString(), toDate?.toISOString()])
	}

	const handleTimeChange = (t: TimeValue | null, which: 'start' | 'end') => {
		if (!tempRange) return
		const [startIso, endIso] = tempRange
		const nextRange: DateRange = [...(tempRange || [])]

		if (which === 'start') {
			setStartTime(t)

			if (startIso && t) {
				const d = parseDateString(startIso)
				if (d) {
					d.setHours(t.hour)
					d.setMinutes(t.minute)
					nextRange[0] = d.toISOString()
				}
			}
		} else {
			setEndTime(t)

			if (endIso && t) {
				const d = parseDateString(endIso)
				if (d) {
					d.setHours(t.hour)
					d.setMinutes(t.minute)
					nextRange[1] = d.toISOString()
				}
			}
		}
		setTempRange(nextRange)
	}

	const handleCancel = () => {
		parseValue()
		setOpen(false)
	}

	const handleApply = () => {
		if (!tempRange?.[0] && !tempRange?.[1]) {
			onChange?.(undefined)
		} else if (asISOStringArray) {
			onChange?.(tempRange)
		} else {
			onChange?.(tempRange)
		}
		setOpen(false)
	}

	const displayRange = (() => {
		if (!tempRange) return null
		const [start, end] = tempRange
		if (start === DISABLED_DATE_WITH_TOGGLE || end === DISABLED_DATE_WITH_TOGGLE) {
			return null
		}
		const startDate = start ? parseDateString(start) : undefined
		const endDate = end ? parseDateString(end) : undefined

		const fromStr = startDate ? formatDate(startDate, locale, showTimePicker) : ''
		const toStr = endDate ? formatDate(endDate, locale, showTimePicker) : ''
		return `${fromStr} - ${toStr}`
	})()

	return (
		<PopoverPrimitives.Root open={open} onOpenChange={handleOpenChange} tremor-id="tremor-raw">
			<Trigger
				placeholder={placeholder}
				disabled={disabled}
				className={className}
				aria-required={props.required || props['aria-required']}
				aria-invalid={props['aria-invalid']}
				aria-label={props['aria-label']}
				aria-labelledby={props['aria-labelledby']}
				triggerRightIconProps={triggerRightIconProps}
				triggerLeftIconProps={triggerLeftIconProps}
				placeholderClassName={placeholderClassName}
			>
				{displayRange}
			</Trigger>
			<CalendarPopover align={align}>
				<div className="flex">
					<div className="flex flex-col overflow-x-auto sm:flex-row sm:items-start">
						{presets && presets.length > 0 && !disablePresets && (
							<div
								className={cn(
									'relative flex h-16 w-full items-center sm:h-full sm:w-40',
									'dark:border-gray-800 border-b border-neutral-1-bd sm:border-b-0 sm:border-r',
									'overflow-auto',
								)}
							>
								<div className="absolute px-3 sm:inset-0 sm:left-0 sm:p-2">
									<PresetContainer currentValue={tempRange} presets={presets} onSelect={setTempRange} />
								</div>
							</div>
						)}
						<div className="overflow-x-auto">
							<CalendarPrimitive
								mode="range"
								selected={
									tempRange
										? {
												from: parseDateString(tempRange[0]),
												to: parseDateString(tempRange[1]),
											}
										: undefined
								}
								onSelect={handleRangeChange}
								month={month}
								onMonthChange={setMonth}
								numberOfMonths={2}
								disabled={disabledDays}
								disableNavigation={disableNavigation}
								enableYearNavigation={enableYearNavigation}
								locale={locale}
								initialFocus
								classNames={{
									months: 'flex flex-row divide-x divide-neutral-1-bd dark:divide-gray-800 overflow-x-auto',
								}}
								{...props}
							/>
							{showTimePicker && (
								<div className="dark:border-gray-800 flex items-center justify-evenly gap-x-3 border-t border-neutral-1-bd p-3">
									<div className="flex flex-1 items-center gap-x-2">
										<span className="text-gray-700 dark:text-gray-30">{translations?.start ?? 'Start'}:</span>
										<TimeInput
											value={startTime}
											onChange={v => handleTimeChange(v, 'start')}
											aria-label="Start date time"
											isDisabled={!tempRange?.[0]}
											isRequired={props.required}
										/>
									</div>
									<div className="flex flex-1 items-center gap-x-2">
										<span className="text-gray-700 dark:text-gray-30">{translations?.end ?? 'End'}:</span>
										<TimeInput
											value={endTime}
											onChange={v => handleTimeChange(v, 'end')}
											aria-label="End date time"
											isDisabled={!tempRange?.[1]}
											isRequired={props.required}
										/>
									</div>
								</div>
							)}
							<div className="dark:border-gray-800 border-t border-neutral-1-bd p-3 sm:flex sm:items-center sm:justify-between">
								<p className="text-gray-900 tabular-nums dark:text-gray-50">
									<span className="text-body-md text-neutral-3-fg">{translations?.range ?? 'Range'}:</span>{' '}
									<span className="text-body-md font-medium">{displayRange}</span>
								</p>
								<div className="mt-2 flex items-center gap-x-2 sm:mt-0">
									<Button variant="secondary" className="h-8 w-full sm:w-fit" type="button" onClick={handleCancel}>
										{translations?.cancel ?? 'Cancel'}
									</Button>
									<Button className="h-8 w-full sm:w-fit" type="button" onClick={handleApply}>
										{translations?.apply ?? 'Done'}
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</CalendarPopover>
		</PopoverPrimitives.Root>
	)
}

/* ----------------------------------------------------------------------------
  Final exported components
----------------------------------------------------------------------------- */

const validatePresets = (presets: DateRangePreset[] | DatePreset[], rules: BasePickerProps) => {
	// original validation left intact
	const { toYear, fromYear, fromMonth, toMonth, fromDay, toDay } = rules
	if (!presets || presets.length === 0) return

	presets.forEach(preset => {
		if ('date' in preset) {
			const { date } = preset
			const presetYear = date.getFullYear()
			if (fromYear && presetYear < fromYear) {
				throw new Error(`Preset ${preset.label} is before fromYear ${fromYear}.`)
			}
			if (toYear && presetYear > toYear) {
				throw new Error(`Preset ${preset.label} is after toYear ${toYear}.`)
			}
			if (fromMonth && date.getMonth() < fromMonth.getMonth()) {
				throw new Error(`Preset ${preset.label} is before fromMonth ${fromMonth.toISOString()}.`)
			}
			if (toMonth && date.getMonth() > toMonth.getMonth()) {
				throw new Error(`Preset ${preset.label} is after toMonth ${toMonth.toISOString()}.`)
			}
			if (fromDay && date.getDate() < fromDay.getDate()) {
				throw new Error(`Preset ${preset.label} is before fromDay ${fromDay.toISOString()}.`)
			}
			if (toDay && date.getDate() > toDay.getDate()) {
				throw new Error(`Preset ${preset.label} is after toDay ${toDay.toISOString()}.`)
			}
		}

		if ('dateRange' in preset) {
			const [f, t] = preset.dateRange || []
			const from = f ? parseDateString(f) : undefined
			const to = t ? parseDateString(t) : undefined

			if (from && fromYear && from.getFullYear() < fromYear) {
				throw new Error(`Preset ${preset.label}'s 'from' is before fromYear ${fromYear}.`)
			}
			if (to && toYear && to.getFullYear() > toYear) {
				throw new Error(`Preset ${preset.label}'s 'to' is after toYear ${toYear}.`)
			}
			if (from && fromMonth && from.getMonth() < fromMonth.getMonth()) {
				throw new Error(`Preset ${preset.label}'s 'from' is before fromMonth ${fromMonth.toISOString()}.`)
			}
			if (to && toMonth && to.getMonth() > toMonth.getMonth()) {
				throw new Error(`Preset ${preset.label}'s 'to' is after toMonth ${toMonth.toISOString()}.`)
			}
			if (from && fromDay && from.getDate() < fromDay.getDate()) {
				throw new Error(`Preset ${preset.label}'s 'from' is before fromDay ${fromDay.toISOString()}.`)
			}
			if (to && toDay && to.getDate() > toDay.getDate()) {
				throw new Error(`Preset ${preset.label}'s 'to' is after toDay ${toDay.toISOString()}.`)
			}
		}
	})
}

export const DatePicker = (props: DatePickerProps) => {
	if (props.presets) {
		validatePresets(props.presets, props)
	}
	return <SingleDatePicker {...props} />
}
DatePicker.displayName = 'DatePicker'

export const DateRangePicker = (props: DateRangePickerProps) => {
	if (props.presets) {
		validatePresets(props.presets, props)
	}
	return <RangeDatePicker {...props} />
}
DateRangePicker.displayName = 'DateRangePicker'
