import { type RouteObject } from 'react-router'
import { action as activateAction } from '#src/routes/settings/team/actions/activate'
import { action as deactivateAction } from '#src/routes/settings/team/actions/deactivate'
import { action as editAction } from '#src/routes/settings/team/actions/edit'
import { action as inviteAction } from '#src/routes/settings/team/actions/invite'
import { action as resetAction } from '#src/routes/settings/team/actions/reset'
import { View as EditView } from '#src/routes/settings/team/views/edit'
import { View as TeamView } from '#src/routes/settings/team/views/index'
import { loader as editLoader } from './loaders/edit'
import { loader as teamLoader } from './loaders/index'

export default {
	path: 'team',
	handle: {
		layout: {
			enableHeader: true,
		},
		crumb: () => [
			{
				label: 'Team members',
				icon: 'user-multiple',
			},
		],
	},
	children: [
		{
			path: '',
			element: <TeamView />,
			loader: teamLoader,
			children: [
				{
					path: 'edit/:userId',
					element: <EditView />,
					loader: editLoader,
					action: editAction,
				},
				{
					path: 'deactivate/:userId',
					action: deactivateAction,
				},
				{
					path: 'activate/:userId',
					action: activateAction,
				},
				{
					path: 'reset/:userId',
					action: resetAction,
				},
			],
		},
		{
			path: 'invite',
			action: inviteAction,
		},
	],
} satisfies RouteObject
