import { type ReactNode } from 'react'
import { useLoaderData } from 'react-router'
import Markdown from '#src/components/markdown'
import { PersonaAvatar } from '#src/components/persona'
import { TrainingAgenda } from '#src/components/training-agenda'
import { Button } from '#src/components/ui/button'
import { Surface } from '#src/components/ui/surface'
import { TabSwitcher } from '#src/components/ui/tabs'
import { type LoaderRes } from '#src/routes/enable/roleplay-scenarios/loaders/item'
import { cn, toSentenceCase } from '#src/utils/misc'

const ScenarioProperty = ({
	label,
	children,
	className,
}: {
	label: string
	value?: string
	children?: ReactNode
	className?: string
}) => {
	return (
		<section className={cn('flex flex-row flex-nowrap py-3.5', className)}>
			<h3 className="w-1/6 shrink-0 pe-2 text-label-md text-neutral-3-fg">{label}</h3>
			<div className="w-full text-body-md">{children}</div>
		</section>
	)
}

export const ScenarioTabs = () => {
	const { scenario } = useLoaderData<LoaderRes>()
	return (
		<Surface className="w-full">
			<TabSwitcher
				tabs={[
					{
						value: 'details',
						label: 'Details',
						content: (
							<>
								<ScenarioProperty label="Name">{scenario.name}</ScenarioProperty>
								<ScenarioProperty label="Agenda">
									<TrainingAgenda title={scenario.title ?? ''} titleClassName="font-normal" icon="user-service-desk" />
								</ScenarioProperty>
								<ScenarioProperty label="Scenario tuning">
									{scenario.scenario ? <Markdown>{scenario.scenario}</Markdown> : '-'}
								</ScenarioProperty>
							</>
						),
					},
					{
						value: 'persona-mapping',
						label: 'Persona Mapping',
						content: (
							<>
								{scenario.personaMapping ? (
									<>
										<ScenarioProperty label="Ecosystem">{scenario.personaMapping.ecosystem}</ScenarioProperty>
										<ScenarioProperty label="Vertical">{scenario.personaMapping.vertical}</ScenarioProperty>
										<ScenarioProperty label="Assigned persona">
											<div className="grid grid-cols-[max-content,1fr] items-center gap-x-2">
												<PersonaAvatar
													type={scenario.personaMapping.personaType ?? ''}
													size="sm"
													className="row-span-2"
												/>
												<div>
													<p className="w-full truncate text-label-sm text-neutral-3-fg">
														{scenario.personaMapping.expertise}
													</p>
													<p className="w-full truncate text-body-sm font-semibold text-neutral-1-fg">
														{scenario.personaMapping.personaType}
													</p>
												</div>
											</div>
										</ScenarioProperty>
										<ScenarioProperty label="Linkedin URL">
											{scenario.linkedinUrl && (
												<Button asChild size="sm" variant="ghost" className="cursor-pointer gap-1 p-0">
													<a href={scenario.linkedinUrl} target="__blank">
														{scenario.linkedinUrl}
													</a>
												</Button>
											)}
										</ScenarioProperty>
									</>
								) : (
									<p className="text-body-sm text-neutral-3-fg">Persona has been deleted</p>
								)}
							</>
						),
					},
					{
						value: 'personality',
						label: 'Personality',
						content: (
							<>
								<ScenarioProperty label="Personality traits">
									{toSentenceCase(scenario.personality.personalityTrait)}
								</ScenarioProperty>
								<ScenarioProperty label="Emotional states">
									{toSentenceCase(scenario.personality.emotionalState)}
								</ScenarioProperty>
								<ScenarioProperty label="Communication styles">
									{toSentenceCase(scenario.personality.communicationStyle)}
								</ScenarioProperty>
								<ScenarioProperty label="Decision making process">
									{toSentenceCase(scenario.personality.decisionMakingProcess)}
								</ScenarioProperty>
								<ScenarioProperty label="Voice">{scenario.personality.voiceMapping.name}</ScenarioProperty>
							</>
						),
					},
					{
						value: 'evaluation',
						label: 'Evaluation',
						content: (
							<>
								<ScenarioProperty label="Evaluation template">
									{scenario.evaluationTemplate?.name ?? '-'}
								</ScenarioProperty>
								<ScenarioProperty label="Evaluation criteria">
									{scenario.evaluation ? <Markdown>{scenario.evaluation}</Markdown> : '-'}
								</ScenarioProperty>
							</>
						),
					},
					{
						value: 'instructions-frequency',
						label: 'Instruction/frequency',
						content: (
							<>
								<ScenarioProperty label="Frequency">
									{scenario.cycleFrequencyUnit !== null && scenario.cycleFrequencyValue !== null
										? `${scenario.cycleFrequencyValue} time(s) per ${scenario.cycleFrequencyUnit}`
										: '-'}
								</ScenarioProperty>
								<ScenarioProperty label="Instructions">
									{scenario.instructions ? <Markdown>{scenario.instructions}</Markdown> : '-'}
								</ScenarioProperty>
							</>
						),
					},
				]}
			/>
		</Surface>
	)
}
