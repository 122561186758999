import { type LoaderFunctionArgs } from 'react-router'
import { z } from 'zod'
import { PLAY_STATUS } from '#src/api/icp/company/plays/constants'
import { playsQueries } from '#src/api/icp/company/plays/queries'
import { PlaysItemAPISchema } from '#src/api/icp/company/plays/schemas'
import { client } from '#src/main'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem-management/ecosystem/queries'
import { PLAY_STATUS_NAME } from '#src/routes/enable/plays/root/constants'
import { paramsToObjectSafe } from '#src/utils/requests'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ request, params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)

	const filtersSelected = {
		status: PLAY_STATUS.Ongoing,
		...paramsToObjectSafe(new URL(request.url).searchParams, PlaysItemAPISchema.extend({ search: z.string() })),
	}

	const [playsData, ecosystems] = await Promise.all([
		client.fetchQuery(playsQueries.list(company.id, { all_plays: 'true' })),
		client.fetchQuery(ecosystemsQuery(company.id, { personas: false, exclude: true })),
	])

	let plays = playsData

	const personaTypes = [...new Set(plays.map(play => play.personaTypes).flat(1))]
	const expertise = [...new Set(plays.map(play => play.expertise).flat(1))]
	const tags = [...new Set(plays.map(play => play.tags).flat(1))]
	const createdBy = [...new Set(plays.map(play => play.createdBy))]

	if (filtersSelected.personaTypes) {
		const personaTypes = Array.isArray(filtersSelected.personaTypes)
			? filtersSelected.personaTypes
			: [filtersSelected.personaTypes]
		plays = plays.filter(play => {
			return play.personaTypes.filter(item => personaTypes.includes(item)).length > 0
		})
	}
	if (filtersSelected.expertise) {
		const expertises = Array.isArray(filtersSelected.expertise)
			? filtersSelected.expertise
			: [filtersSelected.expertise]
		plays = plays.filter(play => {
			return play.expertise.filter(item => expertises.includes(item)).length > 0
		})
	}
	if (filtersSelected.tags) {
		const tags = Array.isArray(filtersSelected.tags) ? filtersSelected.tags : [filtersSelected.tags]
		plays = plays.filter(play => {
			return play.tags.filter(item => tags.includes(item)).length > 0
		})
	}
	if (filtersSelected.inCrm) {
		const flag = filtersSelected.inCrm === 'true'
		plays = plays.filter(play => {
			return play.inCrm === flag
		})
	}
	if (filtersSelected.inCopilot) {
		const flag = filtersSelected.inCopilot === 'true'
		plays = plays.filter(play => {
			return play.inCopilot === flag
		})
	}
	if (filtersSelected.status) {
		const statuses = Array.isArray(filtersSelected.status) ? filtersSelected.status : [filtersSelected.status]
		plays = plays.filter(play => {
			return statuses.includes(play.status)
		})
	}
	if (filtersSelected.createdBy) {
		const createdBy = Array.isArray(filtersSelected.createdBy) ? filtersSelected.createdBy : [filtersSelected.createdBy]
		plays = plays.filter(play => {
			return createdBy.includes(play.createdBy)
		})
	}
	if (filtersSelected.search) {
		const search = (
			Array.isArray(filtersSelected.search) ? filtersSelected.search[0] : filtersSelected.search
		).toLowerCase()
		plays = plays.filter(play => {
			if (play.name.toLowerCase().includes(search)) {
				return true
			}
			if (play.expertise.find(item => item.toLowerCase().includes(search))) {
				return true
			}
			if (play.personaTypes.find(item => item.toLowerCase().includes(search))) {
				return true
			}
			if (play.expertise.find(item => item.toLowerCase().includes(search))) {
				return true
			}
			if (play.tags.find(item => item.toLowerCase().includes(search))) {
				return true
			}
			if (play.createdBy.toLowerCase().includes(search)) {
				return true
			}
			if (play.status.toLowerCase().includes(search)) {
				return true
			}

			return false
		})
	}

	const playsBase = await client.fetchQuery(playsQueries.list(company.id, { all_plays: 'true' }))

	return {
		filters: {
			values: {
				personaTypes,
				expertise,
				tags,
				inCrm: [
					{
						value: 'true',
						label: 'Yes',
					},
					{
						value: 'false',
						label: 'No',
					},
				],
				inCopilot: [
					{
						value: 'true',
						label: 'Yes',
					},
					{
						value: 'false',
						label: 'No',
					},
				],
				status: [
					{
						value: PLAY_STATUS.Draft,
						label: PLAY_STATUS_NAME[PLAY_STATUS.Draft],
						counter: playsBase.filter(play => play.status === PLAY_STATUS.Draft).length,
					},
					{
						value: PLAY_STATUS.Paused,
						label: PLAY_STATUS_NAME[PLAY_STATUS.Paused],
						counter: playsBase.filter(play => play.status === PLAY_STATUS.Paused).length,
					},
					{
						value: PLAY_STATUS.Ongoing,
						label: PLAY_STATUS_NAME[PLAY_STATUS.Ongoing],
						counter: playsBase.filter(play => play.status === PLAY_STATUS.Ongoing).length,
					},
					{
						value: PLAY_STATUS.Archived,
						label: PLAY_STATUS_NAME[PLAY_STATUS.Archived],
						counter: playsBase.filter(play => play.status === PLAY_STATUS.Archived).length,
					},
					{
						value: PLAY_STATUS.ToReview,
						label: PLAY_STATUS_NAME[PLAY_STATUS.ToReview],
						counter: playsBase.filter(play => play.status === PLAY_STATUS.ToReview).length,
					},
				],
				createdBy,
			},
			selected: filtersSelected,
		},
		data: {
			plays,
			ecosystems,
		},
	}
}
