import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useLoaderData } from 'react-router'
import { type unknown, type z } from 'zod'
import { CreateEnrichCriterionAnswersFormSchema } from '#src/api/icp/company/criteria/schema'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import { Button } from '#src/components/ui/button'
import { DropdownButton } from '#src/components/ui/DropdownButton'
import { Icon } from '#src/components/ui/icon'
import { StatusButton } from '#src/components/ui/status-button'
import useCompany from '#src/hooks/useCompany'
import { type CriterionAPISchema } from '#src/routes/calibrate/ecosystem-management/criteria/schema'
import { type LoaderResType } from '#src/routes/calibrate/ecosystem-management/verticals/loaders'
import { cn, useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

type CreateEnrichmentFormProps = {
	criterion: z.infer<typeof CriterionAPISchema>
	criterionVerticalsHasAtLeastOneAnswer: (criterionId: z.infer<typeof CriterionAPISchema>['criterion']['id']) => boolean
}

export const CreateEnrichmentForm = ({
	criterion,
	criterionVerticalsHasAtLeastOneAnswer,
}: CreateEnrichmentFormProps) => {
	const {
		handle: { ecosystemId },
	} = useLoaderData<LoaderResType>()
	const { companyId } = useCompany()

	const form = useForm<typeof unknown>({
		resolver: zodResolver(CreateEnrichCriterionAnswersFormSchema),
		defaultValues: {
			runs: 3,
			ecosystemId: Number(ecosystemId),
			criterionId: criterion.criterion.id,
		},
	})

	const action = routes.calibrate.verticals.criteriaSandbox.createEnrichment({
		companyId,
		ecosystemId,
		criterionId: String(criterion.criterion.id),
	})
	const method = 'POST'

	const isPending = useIsPending({
		formMethod: method,
		formAction: action,
	})
	const formId = `createEnrichment-${criterion.criterion.id}`

	return (
		<DropdownButton
			trigger={
				<Button
					variant="icon"
					size="icon"
					className="absolute right-[64px] top-1/2 -translate-y-1/2 disabled:bg-transparent"
					disabled={!criterionVerticalsHasAtLeastOneAnswer(criterion.criterion.id)}
				>
					<Icon
						name="play-filled-alt"
						size="sm"
						className={!criterionVerticalsHasAtLeastOneAnswer(criterion.criterion.id) ? '' : cn('text-brand-1-fg')}
					/>
				</Button>
			}
			content={
				<FormWrapper
					formProps={form}
					action={action}
					formId={formId}
					method={method}
					className="flex min-w-[200px] flex-col gap-4"
				>
					<FormField name="runs" fieldType="slider" min={1} max={5} label="Amount of repetitions" />
					<StatusButton
						status={isPending ? 'pending' : 'idle'}
						disabled={isPending || !criterionVerticalsHasAtLeastOneAnswer(criterion.criterion.id)}
						size="md"
						className="flex w-full gap-2"
					>
						<Icon name="play-filled-alt" size="sm" />
						Run
					</StatusButton>
				</FormWrapper>
			}
		/>
	)
}
