import { type LoaderFunctionArgs, redirect } from 'react-router'
import { routes } from '#src/utils/routes'
import { getUser } from '#src/utils/server/user'

export const loader = async ({ request }: LoaderFunctionArgs) => {
	const { adminSession } = await getUser()

	if (!adminSession) {
		throw new Response('Page Not Found', {
			status: 404,
			statusText: 'Not Found',
		})
	}

	if (request.url.endsWith('/admin')) {
		throw redirect(routes.admin.company.list)
	}

	return null
}
