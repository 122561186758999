import { z } from 'zod'
import { zNumber } from '#src/utils/validation'

export const CreateEnrichCriterionAnswersFormSchema = z.object({
	ecosystemId: zNumber(z.number()),
	criterionId: zNumber(z.number()),
	runs: zNumber(z.number().min(1)),
})

export const CancelEnrichCriterionAnswersFormSchema = z.object({
	ecosystemId: zNumber(z.number()),
	criterionId: zNumber(z.number()),
})

export const CriteriaModelTypeAPISchema = z.array(
	z.object({
		name: z.string(),
		description: z.string(),
	}),
)
