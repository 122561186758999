import { useMemo } from 'react'
import { type COMPANY_PILLARS } from '#src/api/icp/admin/company/constants'
import { Icon, type IconName } from '#src/components/ui/icon'
import { CustomTooltip } from '#src/components/ui/tooltip'
import { COMPANY_PILLARS_LABELS } from '#src/routes/admin/company/constants'

type Props = {
	modules: COMPANY_PILLARS[]
	activeModules: COMPANY_PILLARS[]
	icon: IconName
}

const PillarCard = (props: Props) => {
	const { modules, activeModules, icon } = props

	const filteredModules = useMemo(() => {
		return modules.filter(module => activeModules.includes(module))
	}, [modules, activeModules])

	const isActive = filteredModules.length > 0

	if (isActive) {
		return (
			<CustomTooltip
				label={
					<>
						{filteredModules.map((module, index) => {
							if (index > 0) {
								return (
									<>
										<br />
										{COMPANY_PILLARS_LABELS[module]}
									</>
								)
							}
							return <>{COMPANY_PILLARS_LABELS[module]}</>
						})}
					</>
				}
			>
				<div className="rounded bg-neutral-2-bg p-3">
					<Icon name={icon} className="h-6 w-6 text-brand-1-fg" />
				</div>
			</CustomTooltip>
		)
	}

	return (
		<div className="rounded bg-neutral-2-bg p-3">
			<Icon name={icon} className="h-6 w-6 text-neutral-3-fg" />
		</div>
	)
}

export default PillarCard
