import { type LoaderFunctionArgs, redirect } from 'react-router'
import { companyChatRoleplayScenarioQueries } from '#src/api/icp/company/chat/roleplay/scenario/queries'
import { showToast } from '#src/context/ToastContext'
import { client } from '#src/main'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company, companyId } = await getCompany(params)
	validateRouteParams(params, ['scenarioId'])

	const scenario = await client.fetchQuery(companyChatRoleplayScenarioQueries.item(company.id, params.scenarioId))
	if (!scenario.isValid) {
		showToast({
			message: 'Scenario is not valid for inviting participants',
			type: 'error',
		})
		throw redirect(
			routes.enable.roleplay.scenario.item({
				companyId,
				scenarioId: params.scenarioId,
			}),
		)
	}

	return {
		users: await client.fetchQuery(
			companyChatRoleplayScenarioQueries.itemAvailableUsers(company.id, params.scenarioId),
		),
		scenarioId: params.scenarioId,
	}
}
