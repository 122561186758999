import { createContext, useContext, useState, type ReactNode, useMemo, useEffect } from 'react'
import { type z } from 'zod'
import { type EcosystemAPIVerticalSchema } from '#src/routes/calibrate/ecosystem-management/ecosystem/schema'

type PersonaCheckboxItem = {
	id: string
	verticalId: string
	checked: boolean
}

type PersonasCheckboxMap = Record<string, Record<string, PersonaCheckboxItem>>

type PersonasCheckboxGroupContextType = {
	checkedItems: PersonasCheckboxMap
	setCheckedItems: (callback: (prev: PersonasCheckboxMap) => PersonasCheckboxMap) => void
	isAllSelected: (verticalId: string) => boolean
	handleSelectAll: (verticalId: string) => void
	clearCheckedItems: () => void
	isIndeterminate: (verticalId: string) => boolean
	getPersonasPerVerticalCount: (verticalId: string) => number
	getCheckedPersonasPerVerticalCount: (verticalId: string) => number
	getCheckedPersonasIdsPerVertical: (verticalId: string) => string[]
	initialVal: PersonasCheckboxMap
	hasMixedVerticalsSelection: boolean
	allCheckedPersonasIds: string[]
}

export type PersonasCheckboxGroupProviderProps = {
	children: ReactNode
	verticals: z.infer<typeof EcosystemAPIVerticalSchema>[]
}

const PersonasCheckboxGroupContext = createContext<PersonasCheckboxGroupContextType | undefined>(undefined)

export const PersonasCheckboxGroupProvider = ({ children, verticals }: PersonasCheckboxGroupProviderProps) => {
	const personas = useMemo(() => {
		return verticals.reduce((acc, vertical) => {
			if (!vertical.personas?.length) return acc

			vertical.personas.forEach(persona => {
				acc[vertical.id] = acc[vertical.id] || {}

				acc[vertical.id][persona.id] = {
					id: persona.id.toString(),
					verticalId: vertical.id.toString(),
					checked: false,
				}
			})

			return acc
		}, {} as PersonasCheckboxMap)
	}, [verticals])

	const [checkedItems, setCheckedItems] = useState(personas)

	useEffect(() => {
		setCheckedItems(personas)
	}, [personas])

	const isAllSelected = (verticalId: string) => {
		const items = checkedItems[verticalId] || {}

		return Object.values(items).every(item => item.checked)
	}

	const clearCheckedItems = () => {
		setCheckedItems(personas)
	}

	const isIndeterminate = (verticalId: string) => {
		const items = checkedItems[verticalId] || {}
		const totalItems = Object.keys(items).length
		const checkedCount = Object.values(items).filter(item => item.checked).length

		return checkedCount > 0 && checkedCount < totalItems
	}

	const getPersonasPerVerticalCount = (verticalId: string) => {
		const items = checkedItems[verticalId] || {}

		return Object.keys(items).length
	}

	const getCheckedPersonasPerVerticalCount = (verticalId: string) => {
		const items = checkedItems[verticalId]

		if (!items) return 0

		return Object.values(items).filter(item => item.checked).length
	}

	const getCheckedPersonasIdsPerVertical = (verticalId: string) => {
		const items = checkedItems[verticalId]

		return Object.entries(items)
			.filter(([, item]) => item.checked)
			.map(([id]) => id)
	}

	const handleSelectAll = (verticalId: string) => {
		const newState = !isAllSelected(verticalId)

		setCheckedItems(prevState => {
			return {
				...prevState,
				[verticalId]: Object.keys(personas[verticalId]).reduce(
					(personaAcc, personaId) => {
						personaAcc[personaId] = {
							...personas[verticalId][personaId],
							checked: newState,
						}
						return personaAcc
					},
					{} as Record<string, PersonaCheckboxItem>,
				),
			}
		})
	}

	const hasMixedVerticalsSelection = useMemo(() => {
		const selectedVerticals = Object.keys(checkedItems).filter(verticalId =>
			Object.values(checkedItems[verticalId]).some(item => item.checked),
		)

		return selectedVerticals.length > 1
	}, [checkedItems])

	const allCheckedPersonasIds = useMemo(() => {
		return Object.values(checkedItems).flatMap(personas =>
			Object.entries(personas)
				.filter(([, item]) => item.checked)
				.map(([id]) => id),
		)
	}, [checkedItems])

	return (
		<PersonasCheckboxGroupContext.Provider
			value={{
				checkedItems,
				setCheckedItems,
				isAllSelected,
				isIndeterminate,
				handleSelectAll,
				getPersonasPerVerticalCount,
				getCheckedPersonasPerVerticalCount,
				getCheckedPersonasIdsPerVertical,
				clearCheckedItems,
				hasMixedVerticalsSelection,
				allCheckedPersonasIds,
				initialVal: personas,
			}}
		>
			{children}
		</PersonasCheckboxGroupContext.Provider>
	)
}

export const usePersonasCheckboxGroup = () => {
	const context = useContext(PersonasCheckboxGroupContext)
	if (!context) {
		throw new Error('usePersonasCheckboxGroup must be used within a CheckboxGroupProvider')
	}
	return context
}
