import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { baseApi } from '#src/utils/endpoints'
import { buildAPISearchParams } from '#src/utils/requests'
import { type PlaysItemAPISchema } from './schemas'

const base = (companyId: z.infer<typeof CompanySchema.shape.id>) => `${baseApi}/company/${companyId}/plays`

const endpoints = {
	list: (companyId: z.infer<typeof CompanySchema.shape.id>, filters?: Record<string, string>) => {
		if (!filters) {
			return base(companyId)
		}
		return `${base(companyId)}?${buildAPISearchParams(filters).toString()}`
	},
	create: (companyId: z.infer<typeof CompanySchema.shape.id>) => base(companyId),
	item: (companyId: z.infer<typeof CompanySchema.shape.id>, playId: z.infer<typeof PlaysItemAPISchema.shape.id>) =>
		`${base(companyId)}/${playId}`,
	update: (companyId: z.infer<typeof CompanySchema.shape.id>, playId: z.infer<typeof PlaysItemAPISchema.shape.id>) =>
		`${base(companyId)}/${playId}`,
	delete: (companyId: z.infer<typeof CompanySchema.shape.id>, playId: z.infer<typeof PlaysItemAPISchema.shape.id>) =>
		`${base(companyId)}/${playId}`,
	criterias: (companyId: z.infer<typeof CompanySchema.shape.id>) => `${base(companyId)}/criteria`,
	operators: (companyId: z.infer<typeof CompanySchema.shape.id>) => `${base(companyId)}/operators`,
}

export { endpoints as playsEndpoints }
