import { useMemo } from 'react'
import { Link, useLoaderData } from 'react-router'
import { COMPANY_PILLARS } from '#src/api/icp/admin/company/constants'
import { Button } from '#src/components/ui/button'
import { PageLayout } from '#src/components/ui/PageLayout'
import useCompany from '#src/hooks/useCompany'
import { type RoleDataType } from '#src/routes/auth/schema'
import { formatDateToReadableString } from '#src/utils/date'
import { checkIsAdminSession, checkIsUserSession } from '#src/utils/misc'
import { formatNumber } from '#src/utils/numbers'
import { routes } from '#src/utils/routes'
import { type LoaderRes } from '../loaders/dashboard'
import BillingStatsCard from './components/BillingStatsCard'
import PillarCard from './components/PillarCard'

export const View = () => {
	const { company, companyId } = useCompany()
	const { users } = useLoaderData<LoaderRes>()

	const managersCount = useMemo(
		() =>
			users.data.filter(
				user => checkIsUserSession(user.roles as RoleDataType) || checkIsAdminSession(user.roles as RoleDataType),
			).length,
		[users],
	)

	const salesExecutivesCount = useMemo(
		() =>
			users.data.filter(
				user => !(checkIsUserSession(user.roles as RoleDataType) || checkIsAdminSession(user.roles as RoleDataType)),
			).length,
		[users],
	)

	return (
		<PageLayout
			title="Billing"
			description={`Plan renews at ${company.contractRenewalDate ? formatDateToReadableString(company.contractRenewalDate) : '-'}`}
			actions={[
				{
					to: '#',
					title: 'Request update',
					onClick: e => {
						e.preventDefault()
						window.Intercom?.('show')
					},
				},
			]}
		>
			<div className="grid grid-cols-3 gap-6">
				<BillingStatsCard icon="user" title="Seats">
					<div className="grid grid-cols-2 gap-4">
						<div className="flex flex-col gap-1 rounded bg-neutral-2-bg px-3 py-2">
							<div className="text-title-md">
								{managersCount}/{company.totalSeats ?? '-'}
							</div>
							<div className="text-label-sm text-neutral-3-fg">Managers</div>
						</div>
						<div className="flex flex-col gap-1 rounded bg-neutral-2-bg px-3 py-2">
							<div className="text-title-md">{salesExecutivesCount}</div>
							<div className="text-label-sm text-neutral-3-fg">Sales executives</div>
						</div>
					</div>
				</BillingStatsCard>
				<BillingStatsCard icon="application" title="Pillars">
					<div className="flex flex-row flex-wrap gap-4">
						<PillarCard
							modules={[COMPANY_PILLARS.CentralizedInteligence]}
							activeModules={company.activeModules}
							icon="machine-learning-model"
						/>
						<PillarCard modules={[COMPANY_PILLARS.Organize]} activeModules={company.activeModules} icon="data-unreal" />
						<PillarCard modules={[COMPANY_PILLARS.Prioritize]} activeModules={company.activeModules} icon="upstream" />
						<PillarCard
							modules={[COMPANY_PILLARS.EnableAIPlaysCopilots, COMPANY_PILLARS.EnableGenAIVoiceRoleplay]}
							activeModules={company.activeModules}
							icon="data-enrichment"
						/>
					</div>
				</BillingStatsCard>
				<BillingStatsCard icon="database" title="Credits included in the plan">
					<div className="my-auto text-center text-title-lg">{formatNumber(company.credits ?? 0)}</div>
				</BillingStatsCard>
			</div>
			<div className="flex flex-col gap-6">
				<div className="flex flex-row items-center justify-between">
					<div className="text-title-md">Credit usage</div>
					<div className="flex flex-row gap-2">
						<Button asChild className="inline-flex">
							<Link to={routes.billing.request({ companyId })}>Buy credits</Link>
						</Button>
						<Button asChild variant="outline">
							<Link to={routes.report.index({ companyId })}>View usage details</Link>
						</Button>
					</div>
				</div>
				<iframe
					className="aspect-[1000/540] w-full"
					src={`https://evergrowth.metabaseapp.com/public/dashboard/ac6b2c72-9542-4c66-b6cf-38f9cb2d1d39?companyid=${company.id}`}
				/>
			</div>
			<p className="text-center text-body-md text-neutral-3-fg">
				To make changes to your plan, please contact our Support team for assistance. We’ll work with you to find the
				best fit for your needs.{' '}
				<a
					href="#"
					className="text-link"
					onClick={e => {
						e.preventDefault()
						window.Intercom?.('show')
					}}
				>
					Contact support
				</a>
			</p>
		</PageLayout>
	)
}
