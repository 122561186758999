import { z, type ZodSchema } from 'zod'
import { clearSession, getAccessToken, refreshSession } from '#src/routes/auth/utils'

/**
 * @description Patched `Response` type which allows us to type `json` method.
 */
type TypedResponse<T extends NonNullable<unknown>> = Omit<Response, 'json'> & {
	json: () => Promise<T>
}

/**
 * @description ReturnType for `safeFetch` with T type generic, so consumers can infer T. Useful for utilities
 *  so you do not have to manually extract/infer type, but is rather done by utility function, which can help
 *  us ensure better type safety.
 * @example
 * ```ts
 * function useSafeFetch<T extends {}>(safeFetch: SafeFetch<T>): T {
 *   return {};
 * }
 *
 * const fetchUser = safeFetch(userSchema);
 * function UserCard() {
 *   const user = useSafeFetch(fetchUser); // z.infer<userSchema>
 *
 *   return null;
 * }
 * ```
 */
export type SafeFetch<T extends NonNullable<unknown>> = (init?: RequestInit) => Promise<TypedResponse<T>>

/**
 * @description extracts return type of function created with `safeFetch` for when `SafeFetch` can not do the job.
 * @example
 * ```ts
 * const fetchUser = safeFetch(userSchema);
 * type User = ExtractDataFromResponse<typeof fetchUser>; // z.infer<userSchema>
 * ```
 */
export type ExtractDataFromResponse<
	R extends SafeFetch<T>,
	T extends NonNullable<unknown> = NonNullable<unknown>,
> = Awaited<ReturnType<Awaited<ReturnType<R>>['json']>>

/**
 * @description This schema is used to validate response from API
 */
export const apiSchema = z.object({ data: z.unknown() })

export const apiErrorSchema = z.object({ message: z.string() })

/**
 * @description type-safe(r) fetch, which internally uses zod library
 */
export async function safeFetch<T extends NonNullable<unknown>, Path extends string>(
	schema: ZodSchema<T>,
	path: Path,
	init?: RequestInit,
): Promise<T> {
	const res = await fetch(path, {
		...init,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...init?.headers,
		},
	})

	/**
	 * If response is not ok, we throw it, so we can handle it in catch block.
	 */
	if (!res.ok) {
		throw res
	}

	/**
	 * Because we want to return whole Response object,
	 * we have to clone response first, as .body(), .text() and .json()
	 * can only be called once on instance of Response.
	 */
	const clonedRes = res.clone()

	const { data } = apiSchema.parse(await clonedRes.json())

	return schema.parse(data)
}

/**
 * @description This function fetches data from the given path and parses the entire response object
 *              including 'data', 'meta', and 'links' using the provided schema.
 * @param schema - Zod schema for validating the entire response object.
 * @param path - The API endpoint to fetch data from.
 * @param init - Optional fetch initialization parameters.
 * @returns A promise that resolves to the parsed and validated response object.
 */
export async function safeFetchMeta<T extends NonNullable<unknown>, Path extends string>(
	schema: ZodSchema<T>,
	path: Path,
	init?: RequestInit,
): Promise<T> {
	const res = await fetch(path, {
		...init,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			...init?.headers,
		},
	})

	if (!res.ok) {
		throw res
	}

	const jsonData = await res.json()

	// Parse and validate the entire response object using the provided schema
	return schema.parse(jsonData)
}

/**
 * IMPORTANT: Dark magic sourcery ahead!
 *
 * @description This function is used to refresh token, so we can keep user logged in seamlessly.
 *                            It blocks other adjacent XHR requests until `refreshSession` is resolved.
 *                            Lastly, in case of error response from Auht0 API, we clear session and redirect user to login page.
 *                            This can happen in various scenarions, i.e. refresh token is invalid (revoked, expired, etc)
 */
let refreshingTokenPromise: ReturnType<typeof refreshSession> | undefined = undefined

export async function fetch<Path extends string>(path: Path, init?: RequestInit) {
	const xhr = async () =>
		await window.fetch(path, {
			...init,
			headers: {
				...(getAccessToken() ? { Authorization: `Bearer ${getAccessToken()}` } : {}),
				...init?.headers,
			},
		})

	let res = await xhr()

	/**
	 * If response is not ok, we throw it, so we can handle it in catch block.
	 */
	if (!res.ok) {
		if (res.status === 401) {
			if (!refreshingTokenPromise) {
				refreshingTokenPromise = refreshSession()
			}

			try {
				await refreshingTokenPromise
			} catch (error) {
				clearSession()
				throw error
			}

			res = await xhr()

			refreshingTokenPromise = undefined
		} else {
			throw res
		}
	}

	return res
}
