import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useActionData, useNavigate, useParams, useSearchParams } from 'react-router'
import { type z } from 'zod'
import { COMPANY_TRANSACTION_TYPE } from '#src/api/icp/admin/company/constants'
import { CompanyTransactionFormSchema } from '#src/api/icp/admin/company/schemas'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import { Dialog } from '#src/components/ui/dialog'
import { COMPANY_TRANSACTION_TYPE_LABELS } from '#src/routes/admin/company/constants'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { type ActionRes } from '../actions/add-credits'

const FORM_ID = 'admin-company-add-credits'

export const View = () => {
	const actionData = useActionData<ActionRes>()
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const { companyId } = useParams<{ companyId: string }>()

	const form = useForm<z.infer<typeof CompanyTransactionFormSchema>>({
		resolver: zodResolver(CompanyTransactionFormSchema),
		defaultValues: {
			transaction_type: COMPANY_TRANSACTION_TYPE.TopUp,
			comment: null,
			amount: undefined,
			transaction_date: undefined,
		},
		mode: 'onSubmit',
	})

	useEffect(() => {
		const errors = actionData && 'errors' in actionData ? actionData.errors : undefined
		if (errors) {
			Object.entries(errors).forEach(([fieldName, errorMessages]) => {
				form.setError(fieldName as keyof z.infer<typeof CompanyTransactionFormSchema>, {
					type: 'server',
					message: errorMessages.join(', '),
				})
			})
		}
	}, [actionData, form])

	const isPending = useIsPending()

	return (
		<Dialog
			defaultOpen={true}
			onOpenChange={isOpen =>
				!isOpen &&
				void navigate({
					pathname: routes.admin.company.edit({ companyId: companyId! }),
					search: searchParams.toString(),
				})
			}
			dialogHeading="Add credits"
			actions={[
				{ type: 'cancel', label: 'Close' },
				{ type: 'submit', formId: FORM_ID, label: 'Add' },
			]}
			isSubmitting={isPending}
		>
			<FormWrapper formId={FORM_ID} formProps={form} className="flex flex-col gap-6">
				<FormField
					label="Type"
					fieldType="radio"
					name="transaction_type"
					options={[
						{
							value: COMPANY_TRANSACTION_TYPE.TopUp,
							label: COMPANY_TRANSACTION_TYPE_LABELS[COMPANY_TRANSACTION_TYPE.TopUp],
						},
						{
							value: COMPANY_TRANSACTION_TYPE.Discount,
							label: COMPANY_TRANSACTION_TYPE_LABELS[COMPANY_TRANSACTION_TYPE.Discount],
						},
						{
							value: COMPANY_TRANSACTION_TYPE.ContractCredits,
							label: COMPANY_TRANSACTION_TYPE_LABELS[COMPANY_TRANSACTION_TYPE.ContractCredits],
						},
						{
							value: COMPANY_TRANSACTION_TYPE.Correction,
							label: COMPANY_TRANSACTION_TYPE_LABELS[COMPANY_TRANSACTION_TYPE.Correction],
						},
					]}
				/>
				<FormField name="amount" fieldType="negativeNumber" label="Credits" />
				<FormField name="comment" fieldType="textarea" label="Comment" />
				<FormField name="transaction_date" fieldType="date" label="Date" />
			</FormWrapper>
		</Dialog>
	)
}
