import { type LoaderFunctionArgs } from 'react-router'
import { verticalsQueries } from '#src/api/icp/company/verticals/queries'
import { client } from '#src/main'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem-management/ecosystem/queries'
import { generateSaveVerticalContext } from '#src/routes/calibrate/ecosystem-management/verticals/utils'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type LoaderResType = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	validateRouteParams(params, ['ecosystemId'])
	const { company } = await getCompany(params)

	const ecosystems = await client.fetchQuery(ecosystemsQuery(company.id, { includeCriteriaSandbox: true }))
	const ecosystem = ecosystems?.find(e => e.id === Number(params.ecosystemId))

	const url = new URL(request.url)
	const duplicate = url.searchParams.get('duplicate')
	const verticalId = params.verticalId || duplicate

	let vertical

	if (verticalId) {
		vertical = await client.fetchQuery(verticalsQueries.item(company.id, Number(verticalId)))
	}

	if (duplicate && vertical) {
		vertical = duplicate ? { ...vertical, id: undefined, name: vertical.name + ' (Copy)' } : undefined
	}

	const { criteria, enrichmentCriteria, schema, defaultValues } = await generateSaveVerticalContext(
		company.id,
		params.ecosystemId,

		// @ts-expect-error: TODO: How duplicating (above) was done is nonsense,  fix in the future
		vertical,
	)

	return {
		ecosystemId: params.ecosystemId,
		vertical,
		criteria,
		enrichmentCriteria,
		schema,
		defaultValues,
		ecosystem,
	}
}
