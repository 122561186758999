import { type z } from 'zod'
import { client } from '#src/main'
import { objectToFormData } from '#src/utils/forms'
import { fetch } from '#src/utils/safeFetch'
import { orgDataEnrichmentWorkflowsEndpoints } from './endpoints'
import { orgDataEnrichmentWorkflowsQueriesKeys } from './queries'
import {
	type DataEnrichmentItemAPISchema,
	type DataEnrichmentJobDownloadFormSchema,
	type ContactWaterfallingJobFormSchema,
	WORKFLOW_EXPORT_TYPE,
	type AccountEnrichmentJobFormSchema,
} from './schemas'

const mutations = {
	stop: async (jobId: z.infer<typeof DataEnrichmentItemAPISchema.shape.id>) => {
		const res = await fetch(orgDataEnrichmentWorkflowsEndpoints.itemStop(jobId), {
			method: 'POST',
		})

		await client.invalidateQueries({
			queryKey: orgDataEnrichmentWorkflowsQueriesKeys.all,
		})

		return res
	},
	create: async (
		data: z.infer<typeof ContactWaterfallingJobFormSchema> | z.infer<typeof AccountEnrichmentJobFormSchema>,
	) => {
		const body = objectToFormData(data)
		const res = await fetch(orgDataEnrichmentWorkflowsEndpoints.create(), {
			method: 'POST',
			headers: {
				Accept: 'application/json',
			},
			body,
		})

		await client.invalidateQueries({
			queryKey: orgDataEnrichmentWorkflowsQueriesKeys.all,
		})

		return res
	},

	download: async (
		jobId: z.infer<typeof DataEnrichmentItemAPISchema.shape.id>,
		data: z.infer<typeof DataEnrichmentJobDownloadFormSchema>,
	) => {
		const query = ((): Record<string, string> => {
			switch (data.type) {
				case WORKFLOW_EXPORT_TYPE.All:
					return {}
				case WORKFLOW_EXPORT_TYPE.OnlyFound:
					return { ['phone-email-exist']: 'true' }
			}
		})()
		return await fetch(orgDataEnrichmentWorkflowsEndpoints.itemDownload(jobId, query), {
			method: 'GET',
			// timeout is 10 minutes, because of this issue: https://evergrowth.sentry.io/issues/6102575650/?project=4506823228915712
			signal: AbortSignal.timeout(10 * 60 * 1000),
		})
	},
}

export { mutations as orgDataEnrichmentWorkflowsMutations }
