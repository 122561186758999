import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { PaginatedAPIResSchema } from '#src/schemas/global'
import { safeFetch, safeFetchMeta } from '#src/utils/safeFetch'
import { companyChatRoleplayScenarioEndpoints } from './endpoints'
import {
	ScenarioParticipantAPISchema,
	ScenarioAPISchema,
	ScenariosAPISchema,
	ScenarioTemplatesAPISchema,
	ScenarioUsersAPISchema,
	VoicesAPISchema,
	ScenarioSessionAPISchema,
} from './schemas'

const keys = {
	all: ['icp', 'company', 'chat', 'roleplay', 'scenario'] as const,
	list: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		page: number,
		filters?: Record<string, string | string[]>,
	) => [...keys.all, 'list', companyId, page, JSON.stringify(filters)] as const,
	item: (companyId: z.infer<typeof CompanySchema.shape.id>, scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>) =>
		[...keys.all, 'item', companyId, scenarioId] as const,
	voices: (companyId: z.infer<typeof CompanySchema.shape.id>) => [...keys.all, 'item', companyId, 'voices'] as const,
	templates: (companyId: z.infer<typeof CompanySchema.shape.id>) =>
		[...keys.all, 'item', companyId, 'templates'] as const,
	itemAvailableUsers: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>,
	) => [...keys.all, 'item', companyId, scenarioId, 'available-users'] as const,
	users: (companyId: z.infer<typeof CompanySchema.shape.id>) => [...keys.all, 'item', companyId, 'users'] as const,
	itemParticipants: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>,
	) => [...keys.all, 'item', companyId, scenarioId, 'participants'] as const,
	itemSessions: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>,
		page: number,
		sort: string[],
		filters: Record<string, string | string[]>,
	) => [...keys.all, 'item', companyId, scenarioId, 'sessions', page, sort, JSON.stringify(filters)] as const,
}

const listQuery = (
	companyId: z.infer<typeof CompanySchema.shape.id>,
	page: number,
	filters?: Record<string, string | string[]>,
) => ({
	queryKey: keys.list(companyId, page, filters),
	queryFn: async () =>
		await safeFetchMeta(ScenariosAPISchema, companyChatRoleplayScenarioEndpoints.list(companyId, page, filters), {
			method: 'GET',
		}),
})

const itemQuery = (
	companyId: z.infer<typeof CompanySchema.shape.id>,
	scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>,
) => ({
	queryKey: keys.item(companyId, scenarioId),
	queryFn: async () =>
		await safeFetch(ScenarioAPISchema, companyChatRoleplayScenarioEndpoints.item(companyId, scenarioId), {
			method: 'GET',
		}),
})

const voicesQuery = (companyId: z.infer<typeof CompanySchema.shape.id>) => ({
	queryKey: keys.voices(companyId),
	queryFn: async () =>
		await safeFetch(VoicesAPISchema, companyChatRoleplayScenarioEndpoints.voices(companyId), {
			method: 'GET',
		}),
})

const templatesQuery = (companyId: z.infer<typeof CompanySchema.shape.id>) => ({
	queryKey: keys.templates(companyId),
	queryFn: async () =>
		await safeFetch(ScenarioTemplatesAPISchema, companyChatRoleplayScenarioEndpoints.templates(companyId), {
			method: 'GET',
		}),
})

const itemAvailableUsersQuery = (
	companyId: z.infer<typeof CompanySchema.shape.id>,
	scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>,
) => ({
	queryKey: keys.itemAvailableUsers(companyId, scenarioId),
	queryFn: async () =>
		await safeFetch(ScenarioUsersAPISchema, companyChatRoleplayScenarioEndpoints.itemUsers(companyId, scenarioId), {
			method: 'GET',
		}),
})

const usersQuery = (companyId: z.infer<typeof CompanySchema.shape.id>) => ({
	queryKey: keys.users(companyId),
	queryFn: async () =>
		await safeFetch(ScenarioUsersAPISchema, companyChatRoleplayScenarioEndpoints.users(companyId), {
			method: 'GET',
		}),
})

const itemParticipantsQuery = (
	companyId: z.infer<typeof CompanySchema.shape.id>,
	scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>,
) => ({
	queryKey: keys.itemParticipants(companyId, scenarioId),
	queryFn: async () =>
		await safeFetchMeta(
			PaginatedAPIResSchema(ScenarioParticipantAPISchema),
			companyChatRoleplayScenarioEndpoints.itemParticipants(companyId, scenarioId),
			{
				method: 'GET',
			},
		),
})

const itemSessionsQuery = (
	companyId: z.infer<typeof CompanySchema.shape.id>,
	scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>,
	page: number,
	sort: string[],
	filters: Record<string, string | string[]>,
) => ({
	queryKey: keys.itemSessions(companyId, scenarioId, page, sort, filters),
	queryFn: async () =>
		await safeFetchMeta(
			PaginatedAPIResSchema(ScenarioSessionAPISchema),
			companyChatRoleplayScenarioEndpoints.itemSessions(companyId, scenarioId, page, sort, filters),
			{
				method: 'GET',
			},
		),
})

const queries = {
	list: listQuery,
	item: itemQuery,
	voices: voicesQuery,
	templates: templatesQuery,
	itemAvailableUsers: itemAvailableUsersQuery,
	users: usersQuery,
	itemParticipants: itemParticipantsQuery,
	itemSessions: itemSessionsQuery,
}

export { queries as companyChatRoleplayScenarioQueries, keys as companyChatRoleplayScenarioQueriesKeys }
