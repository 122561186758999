import { CURRENCIES } from '#src/constants/currencies'

export const formatNumberToPct = (data: number) => {
	return Math.round(data)
}

export const formatNumber = (value: number, currency?: string | null) => {
	if (!currency) return value.toLocaleString('de-DE')

	const currencyObj = CURRENCIES.find(item => item.code === currency)
	return new Intl.NumberFormat(currencyObj?.code === 'EUR' ? 'de-DE' : 'en-US', {
		style: 'currency',
		currency: currencyObj?.code,
	}).format(value)
}
