import { api } from '#src/utils/endpoints'
import { safeFetch } from '#src/utils/safeFetch'
import { CriteriaAPISchema, CriteriaQuestionAPISchema, CriterionQuestionAPISchema } from './schema'

export const criteriaKeys = {
	all: ['criteria'] as const,
	list: (companyId: string, isSandbox?: boolean) => [...criteriaKeys.all, 'list', companyId, isSandbox] as const,
	externalList: (companyId: string) => [...criteriaKeys.all, 'externalList', companyId] as const,
	templatesList: (companyId: string) => [...criteriaKeys.all, 'templatesList', companyId] as const,
	details: (companyId: string, details: string) => [...criteriaKeys.all, 'details', companyId, details] as const,
	assigned: (companyId: string, ecosystemId: string) =>
		[...criteriaKeys.all, 'assigned', companyId, ecosystemId] as const,
}

export const criteriaAssignedQuery = (companyId: string, ecosystemId: string) => ({
	queryKey: criteriaKeys.assigned(companyId, ecosystemId),
	queryFn: async () => {
		return await safeFetch(CriteriaAPISchema, api.criterion.list.assigned(companyId, ecosystemId), {
			method: 'GET',
		})
	},
})

export const criteriaListQuery = (companyId: string, isSandbox?: boolean) => ({
	queryKey: criteriaKeys.list(companyId, isSandbox),
	queryFn: async () => {
		return await safeFetch(CriteriaQuestionAPISchema, api.criterion.list.all(companyId, isSandbox), {
			method: 'GET',
		})
	},
})

export const externalCriteriaQuery = (companyId: string) => ({
	queryKey: criteriaKeys.externalList(companyId),
	queryFn: async () => {
		return await safeFetch(CriteriaQuestionAPISchema, api.criterion.list.external(companyId), {
			method: 'GET',
		})
	},
})

export const criteriaTemplatesQuery = (companyId: string) => ({
	queryKey: criteriaKeys.templatesList(companyId),
	queryFn: async () => {
		return await safeFetch(CriteriaQuestionAPISchema, api.criterion.list.templates(companyId), {
			method: 'GET',
		})
	},
})

export const criterionQuery = (companyId: string, criterionId: string) => ({
	queryKey: criteriaKeys.details(companyId, criterionId),
	queryFn: async () => {
		return await safeFetch(CriterionQuestionAPISchema, api.criterion.details(companyId, criterionId), {
			method: 'GET',
		})
	},
})
