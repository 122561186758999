import { type ReactNode } from 'react'
import { Icon, type IconName } from '#src/components/ui/icon'
import { Surface } from '#src/components/ui/surface'

type Props = {
	icon: IconName
	title: string
	children: ReactNode
}

const BillingStatsCard = (props: Props) => {
	const { icon, title, children } = props

	return (
		<Surface className="flex flex-col justify-between gap-4 p-6">
			<div className="flex flex-row gap-1 text-neutral-3-fg">
				<Icon name={icon} className="h-6 w-6" />
				<div className="flex-1 text-title-sm">{title}</div>
			</div>
			{children}
		</Surface>
	)
}

export default BillingStatsCard
