import { type ActionFunctionArgs, redirect } from 'react-router'
import { verticalsMutations } from '#src/api/icp/company/verticals/mutations'
import { verticalsQueriesKeys } from '#src/api/icp/company/verticals/queries'
import { client } from '#src/main'
import { criteriaKeys } from '#src/routes/calibrate/ecosystem-management/criteria/queries'
import { ecosystemKeys } from '#src/routes/calibrate/ecosystem-management/ecosystem/queries'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export const action = async ({ params }: ActionFunctionArgs) => {
	validateRouteParams(params, ['verticalId', 'ecosystemId'])
	const { company, companyId } = await getCompany(params)

	await verticalsMutations.delete(company.id, params.verticalId)

	await client.invalidateQueries({
		queryKey: verticalsQueriesKeys.all,
	})
	void client.invalidateQueries({
		queryKey: ecosystemKeys.list(company.id),
	})
	void client.invalidateQueries({
		queryKey: criteriaKeys.all,
	})

	return redirect(
		routes.calibrate.verticals.index({
			companyId,
			ecosystemId: params.ecosystemId,
		}),
	)
}
