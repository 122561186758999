import { type ButtonHTMLAttributes, forwardRef, type ReactNode } from 'react'
import DeleteConfirm from '#src/components/delete-confirm'
import useCompany from '#src/hooks/useCompany'
import { cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

export interface DeleteVerticalAsyncFormProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	ecosystemId: number
	verticalId: string
	children: ReactNode
}

export const DeleteVerticalAsyncForm = forwardRef<HTMLButtonElement, DeleteVerticalAsyncFormProps>(
	({ ecosystemId, verticalId, children, className, ...props }, ref) => {
		const { companyId } = useCompany()
		const action = routes.calibrate.verticals.delete({
			companyId,
			ecosystemId: ecosystemId.toString(),
			verticalId: verticalId,
		})

		return (
			<DeleteConfirm
				trigger={
					<button {...props} ref={ref} type="button" className={cn('flex items-center justify-center', className)}>
						{children}
					</button>
				}
				action={action}
				title="Delete vertical?"
				description="This action cannot be undone. This will permanently delete the vertical and remove any associated data such as personas."
			/>
		)
	},
)
DeleteVerticalAsyncForm.displayName = 'DeleteVerticalAsyncForm'
