import { useMemo } from 'react'
import { useMatches } from 'react-router'
import { z } from 'zod'

const LayoutSchema = z.object({
	enableHeader: z.boolean().optional(),
	fullWidth: z.boolean().optional(),
	disableSidebar: z.boolean().optional(),
	noPadding: z.boolean().optional(),
	sidePanelDefaultExpanded: z.boolean().optional(),
	sidePanelHeader: z.any().optional(),
	sidePanelContent: z.any().optional(),
})

const RouteLayoutSchema = z.object({
	layout: LayoutSchema,
})

const DEFAULT_PROPS: z.infer<typeof LayoutSchema> = {
	enableHeader: false,
	fullWidth: false,
	sidePanelDefaultExpanded: false,
	disableSidebar: false,
	noPadding: false,
	sidePanelHeader: null,
	sidePanelContent: null,
}

const LAYOUT_KEYS = Object.keys(LayoutSchema.shape)

const useLayoutProps = (props: z.infer<typeof LayoutSchema>) => {
	const matches = useMatches()

	return useMemo(() => {
		let res = {
			...DEFAULT_PROPS,
			...props,
		}
		const layoutMatches = [...matches]
			.reverse()
			.filter(match => RouteLayoutSchema.safeParse(match.handle).success)
			.map(match => RouteLayoutSchema.safeParse(match.handle).data!.layout)

		LAYOUT_KEYS.forEach(key => {
			const closestPropsIncludingKey = layoutMatches.find(layout => key in layout)
			if (closestPropsIncludingKey) {
				res = {
					...res,
					// @ts-expect-error eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					[key]: closestPropsIncludingKey[key],
				}
			}
		})

		return res
	}, [matches, props])
}

export default useLayoutProps
