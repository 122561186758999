import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useFetcher } from 'react-router'
import { type z } from 'zod'
import {
	type DataEnrichmentItemAPISchema,
	DataEnrichmentJobDownloadFormSchema,
	WORKFLOW_EXPORT_TYPE,
} from '#src/api/organise/data-enrichment/workflows/schemas'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import LoaderRequiredComponent from '#src/components/LoaderRequiredComponent'
import { Dialog } from '#src/components/ui/dialog'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { type ActionRes } from '#src/routes/organize/account-enrichment/actions/download'
import { type LoaderRes } from '#src/routes/organize/account-enrichment/loaders/download'
import { routes } from '#src/utils/routes'

export type AccountEnrichmentJobDownloadModalProps = {
	jobId: z.infer<typeof DataEnrichmentItemAPISchema.shape.id>
}

export const AccountEnrichmentJobDownloadModal = (props: AccountEnrichmentJobDownloadModalProps) => {
	const { companyId } = useCompany()

	return (
		<LoaderRequiredComponent<LoaderRes, AccountEnrichmentJobDownloadModalProps>
			route={routes.organize.accountEnrichment.stop({ jobId: props.jobId, companyId })}
			component={ModalInner}
			initialProps={props}
		/>
	)
}

const getFormName = (jobId: z.infer<typeof DataEnrichmentItemAPISchema.shape.id>) =>
	`account-enrichment-job-${jobId}-download`

const ModalInner = (props: AccountEnrichmentJobDownloadModalProps & LoaderRes) => {
	const {
		data: { job },
	} = props
	const { closeModal } = useModals()
	const { companyId } = useCompany()

	const actionFetcher = useFetcher<ActionRes>()

	const form = useForm<z.infer<typeof DataEnrichmentJobDownloadFormSchema>>({
		resolver: zodResolver(DataEnrichmentJobDownloadFormSchema),
		mode: 'onSubmit',
		defaultValues: {
			type: WORKFLOW_EXPORT_TYPE.All,
		},
	})

	return (
		<Dialog
			size="xl"
			defaultOpen={true}
			onOpenChange={isOpen => !isOpen && closeModal(MODAL_NAME.AccountEnrichmentDownload)}
			dialogHeading="Select export options"
			isSubmitting={actionFetcher.state === 'submitting'}
			actions={[
				{
					type: 'cancel',
					label: 'Close',
				},
				{
					type: 'submit',
					label: 'Download',
					formId: getFormName(job.id),
				},
			]}
		>
			<FormWrapper
				formId={getFormName(job.id)}
				formProps={form}
				method="POST"
				action={routes.organize.accountEnrichment.download({ companyId, jobId: job.id })}
				className="flex flex-col gap-6"
				fetcher={actionFetcher}
			>
				<FormField
					disableLabel
					name="type"
					fieldType="radioCards"
					options={[
						{
							value: WORKFLOW_EXPORT_TYPE.All,
							label: 'All',
						},
					]}
					className="flex-col"
				/>
			</FormWrapper>
		</Dialog>
	)
}
