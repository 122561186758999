import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useActionData, useNavigate, useRouteLoaderData } from 'react-router'
import { type z } from 'zod'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import { Dialog } from '#src/components/ui/dialog'
import useCompany from '#src/hooks/useCompany'
import { type ActionRes } from '#src/routes/calibrate/ecosystem-management/ecosystem/actions/save'
import { ECOSYSTEM_TYPE } from '#src/routes/calibrate/ecosystem-management/ecosystem/constants'
import { type EcosystemLoaderResponse } from '#src/routes/calibrate/ecosystem-management/ecosystem/loaders/save'
import { EcosystemFormSchema } from '#src/routes/calibrate/ecosystem-management/ecosystem/schema'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

export default function Ecosystem({ type }: { type: ECOSYSTEM_TYPE }) {
	const { ecosystem, ecosystemTemplates } = useRouteLoaderData('ecosystem') as EcosystemLoaderResponse
	const navigate = useNavigate()
	const { companyId } = useCompany()

	const dialogHeading = (() => {
		switch (ecosystem?.type || type) {
			case ECOSYSTEM_TYPE.KEY_ACCOUNT:
				return 'Key Accounts Ecosystem'
			case ECOSYSTEM_TYPE.CRITERIA_SANDBOX:
				return 'Criteria Sandbox Ecosystem'
			default:
				return 'Ecosystem'
		}
	})()

	const action = routes.calibrate.ecosystem.save({
		companyId,
		ecosystemId: ecosystem?.id ? ecosystem.id.toString() : null,
	})
	const method = ecosystem?.id ? 'PATCH' : 'POST'

	const isPending = useIsPending({ formAction: action, formMethod: method })

	const FORM_ID = `ecosystem-${ecosystem ? `edit-${ecosystem.id}` : 'create'}-form`

	const form = useForm<z.infer<typeof EcosystemFormSchema>>({
		resolver: zodResolver(EcosystemFormSchema),
		defaultValues: {
			id: ecosystem?.id,
			name: ecosystem?.name ?? '',
			template: 'new',
			type: ecosystem?.type || type,
		},
	})

	const actionData = useActionData<ActionRes>()

	return (
		<Dialog
			dialogHeading={ecosystem ? `${dialogHeading} Details` : `Create ${dialogHeading}`}
			defaultOpen={true}
			onOpenChange={isOpen => !isOpen && void navigate(-1)}
			actions={[
				{ type: 'cancel', label: 'Close' },
				{ type: 'submit', formId: FORM_ID, label: ecosystem ? 'Update' : 'Create' },
			]}
			isSubmitting={isPending}
		>
			<FormWrapper
				formProps={form}
				action={action}
				method={method}
				className="flex flex-col gap-6"
				actionResponse={actionData}
				formId={FORM_ID}
			>
				<div className="hidden">
					<FormField fieldType="text" name="id" />
					<FormField fieldType="text" name="type" />
				</div>
				{type === ECOSYSTEM_TYPE.DEFAULT && (
					<FormField
						fieldType="select"
						name="template"
						options={[
							{ value: 'new', label: 'Create Ecosystem' },
							...ecosystemTemplates.map(template => ({
								value: template.id.toString(),
								label: template.name,
							})),
						]}
						label="Select a template or start from scratch"
					/>
				)}
				<FormField fieldType="text" name="name" label="Name" />
			</FormWrapper>
		</Dialog>
	)
}
