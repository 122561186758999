import { type ActionFunctionArgs } from 'react-router'
import { playsTemplatesMutations } from '#src/api/icp/company/plays-templates/mutations'
import { PlaysTemplatesFormSchema } from '#src/api/icp/company/plays-templates/schemas'
import { MODAL_NAME } from '#src/constants/modals'
import { showToast } from '#src/context/ToastContext'
import { parseFormDataNew } from '#src/utils/forms'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'
import { closeModalServer } from '#src/utils/server/modals'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const { company } = await getCompany(params)
	validateRouteParams(params, ['templateId'])

	const formData = await request.formData()

	try {
		const submission = parseFormDataNew(formData, PlaysTemplatesFormSchema)
		await playsTemplatesMutations.update(company.id, Number(params.templateId), submission.value)
		closeModalServer(MODAL_NAME.PlayTemplateEdit)
		showToast({
			message: 'Play template edited',
			type: 'success',
		})
		return { ok: true }
	} catch (e) {
		return await serverFormErrorHandler(e)
	}
}
