import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import { forwardRef } from 'react'
import { Icon } from '#src/components/ui/icon'
import { cn } from '#src/utils/misc'
import { type IconName } from '@/icon-name'

const textVariants = {
	default: 'Generating...',
	output: 'Generating output...',
	none: '',
} as const

const spinnerVariants = cva('flex items-center', {
	variants: {
		iconSize: {
			sm: 'h-4 w-4',
			md: 'h-6 w-6',
			lg: 'h-8 w-8',
		},
		textSize: {
			sm: 'text-body-sm',
			md: 'text-body-md',
			lg: 'text-body-lg',
		},
	},
	defaultVariants: {
		iconSize: 'sm',
		textSize: 'sm',
	},
})

type InlineSpinnerProps = VariantProps<typeof spinnerVariants> & {
	message?: string
	iconName?: IconName
	asChild?: boolean
	className?: string
	size?: 'sm' | 'md' | 'lg'
	textVariant?: keyof typeof textVariants
}

export const InlineSpinner = forwardRef<HTMLDivElement, InlineSpinnerProps>(
	(
		{ className, size = 'sm', textVariant = 'default', message = '', iconName = 'update', asChild = false, ...props },
		ref,
	) => {
		const Comp = asChild ? Slot : 'div'
		const spinnerText = message || textVariants[textVariant]

		return (
			<Comp className={cn('flex items-center gap-2', className)} ref={ref} {...props}>
				<Icon name={iconName} className={cn('animate-spin', spinnerVariants({ iconSize: size, textSize: null }))} />
				{textVariant !== 'none' && (
					<span className={cn(spinnerVariants({ textSize: size, iconSize: null }))}>{spinnerText}</span>
				)}
			</Comp>
		)
	},
)

InlineSpinner.displayName = 'InlineSpinner'
