import { useQuery } from '@tanstack/react-query'
import { useEffect, useMemo } from 'react'
import { Outlet, useLoaderData } from 'react-router'
import { CRITERION_ANSWERS_ENRICHING } from '#src/api/icp/company/criteria/constants'
import { verticalsQueries } from '#src/api/icp/company/verticals/queries'
import { EmptyStateCard } from '#src/components'
import AlertBanner from '#src/components/AlertBanner'
import Table, { type TableDataGroup } from '#src/components/table'
import { BASE_REFETCH_INTERVAL } from '#src/constants/refetchIntervals'
import useCompany from '#src/hooks/useCompany'
import { ECOSYSTEM_TYPE } from '#src/routes/calibrate/ecosystem-management/ecosystem/constants'
import { ROW_CONFIG } from '#src/routes/calibrate/ecosystem-management/verticals/constants'
import { type LoaderResType } from '#src/routes/calibrate/ecosystem-management/verticals/loaders'
import { EcosystemEmptyState } from '#src/routes/calibrate/ecosystem-management/verticals/views/components'
import {
	useBusinessModelGroup,
	useCriteriaSandboxGroup,
	useEnrichmentGroup,
	useHeader,
	useNotesGroup,
	usePersonasGroup,
	useQualificationGroup,
} from '#src/routes/calibrate/ecosystem-management/verticals/views/components/table'
import { QUEUE_STATUS } from '#src/utils/enumerations'
import { routes } from '#src/utils/routes'
import { autofocusVerticalQuery } from '../autofocus'

export const View = () => {
	const {
		handle: { ecosystemId },
		ecosystem,
		allCriteria,
	} = useLoaderData<LoaderResType>()
	const { company } = useCompany()
	const { data: autofocusVertical } = useQuery(autofocusVerticalQuery())

	const { data: verticals } = useQuery({
		...verticalsQueries.list(company.id, Number(ecosystemId)),
		refetchInterval: ({ state: { data } }) => {
			if (ecosystem?.type === ECOSYSTEM_TYPE.CRITERIA_SANDBOX) {
				const hasActiveEnrichedAnswers = data?.some(v =>
					v.enrichedAnswers?.some(answer => CRITERION_ANSWERS_ENRICHING.includes(answer.status)),
				)

				return hasActiveEnrichedAnswers ? BASE_REFETCH_INTERVAL : false
			}

			const hasActivePrompts = data?.some(
				v => v.prompt.status && [QUEUE_STATUS.pending, QUEUE_STATUS.inProgress].includes(v.prompt.status),
			)

			return hasActivePrompts ? BASE_REFETCH_INTERVAL : false
		},
		refetchIntervalInBackground: true,
		throwOnError: true,
	})

	useEffect(() => {
		if (autofocusVertical) {
			setTimeout(() => {
				const element = document.getElementById(`vertical-column-${autofocusVertical}`)
				element?.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'center',
				})
			}, 100)
		}
	}, [autofocusVertical])

	const ecosystemType = ecosystem?.type ?? ECOSYSTEM_TYPE.DEFAULT
	const rowsToRender = useMemo(() => ROW_CONFIG[ecosystemType] ?? ROW_CONFIG[ECOSYSTEM_TYPE.DEFAULT], [ecosystemType])

	const header = useHeader(verticals ?? [])
	const personas = usePersonasGroup(verticals ?? [])
	const qualification = useQualificationGroup(verticals ?? [])
	const enrichment = useEnrichmentGroup(verticals ?? [])
	const businessModel = useBusinessModelGroup(verticals ?? [])
	const notes = useNotesGroup(verticals ?? [])
	const criteriaSandbox = useCriteriaSandboxGroup(verticals ?? [])

	const rows = useMemo(
		() => ({
			personas,
			qualification,
			enrichment,
			businessModel,
			notes,
			criteriaSandbox,
		}),
		[businessModel, criteriaSandbox, enrichment, notes, personas, qualification],
	)

	const bodyGroups = useMemo(() => rowsToRender.flatMap(key => rows[key]), [rowsToRender, rows])

	const data = useMemo<TableDataGroup>(
		() => ({
			head: header,
			bodyGroups,
		}),
		[header, bodyGroups],
	)

	if (!verticals?.length) {
		return <EcosystemEmptyState />
	}

	return (
		<>
			{ecosystemType === ECOSYSTEM_TYPE.CRITERIA_SANDBOX && (
				<AlertBanner icon="information-filled" className="rounded-none">
					<span className="font-semibold">Criteria sandbox</span> is for testing purposes only and do not impact
					ecosystems or key accounts
				</AlertBanner>
			)}
			<main className="w-full flex-grow overflow-auto">
				<section className="flex gap-8">
					<Table key={verticals.length} data={data} />
					<Outlet />
				</section>
				{ecosystemType === ECOSYSTEM_TYPE.CRITERIA_SANDBOX && !allCriteria.length && (
					<EmptyStateCard
						title="No criteria"
						description="No criteria added yet"
						icon="path"
						className="bg-transparent"
						actions={[
							{
								title: 'Add New',
								to: routes.calibrate.criteria.list({ companyId: company.id, ecosystemId, criterionType: 'enrichment' }),
							},
						]}
					/>
				)}
			</main>
		</>
	)
}
