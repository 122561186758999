import { COMPANY_PILLARS, COMPANY_TRANSACTION_TYPE } from '#src/api/icp/admin/company/constants'

export const COMPANY_PILLARS_LABELS: Record<COMPANY_PILLARS, string> = {
	[COMPANY_PILLARS.CentralizedInteligence]: 'Centralized Inteligence',
	[COMPANY_PILLARS.Organize]: 'Organize',
	[COMPANY_PILLARS.Prioritize]: 'Prioritize',
	[COMPANY_PILLARS.EnableAIPlaysCopilots]: 'Enable - AI plays & Copilots',
	[COMPANY_PILLARS.EnableGenAIVoiceRoleplay]: 'Enable - GenAI voice roleplay',
} as const

export const COMPANY_TRANSACTION_TYPE_LABELS: Record<COMPANY_TRANSACTION_TYPE, string> = {
	[COMPANY_TRANSACTION_TYPE.Correction]: 'Correction',
	[COMPANY_TRANSACTION_TYPE.TopUp]: 'Topup',
	[COMPANY_TRANSACTION_TYPE.ContractCredits]: 'Contract credits',
	[COMPANY_TRANSACTION_TYPE.Spend]: 'Spend',
	[COMPANY_TRANSACTION_TYPE.Discount]: 'Discount',
} as const
