import { client as queryClient } from '#src/main'
import { FILTERS } from '#src/routes/prioritize/constants'
import { api } from '#src/utils/endpoints'
import { safeFetch } from '#src/utils/safeFetch'
import {
	AssignedSignalsAPISchema,
	IntentSourceSchema,
	IntentTypeSchema,
	MappedPersonasSchema,
	SignalsTemplatesAPISchema,
	SortSchema,
	WeightSchema,
} from './schema'

export const signalsKeys = {
	all: ['signals'] as const,
	list: (companyId: string) => [...signalsKeys.all, 'list', companyId] as const,
	assigned: (companyId: string) => [...signalsKeys.list(companyId), 'assigned'] as const,
	details: (companyId: string, signalId: string) => [...signalsKeys.all, 'details', companyId, signalId] as const,
}

export const assignedSignalsQuery = (companyId: string) => ({
	queryKey: signalsKeys.assigned(companyId),
	queryFn: async () => {
		return await safeFetch(AssignedSignalsAPISchema, api.signal.assigned(companyId), {
			method: 'GET',
		})
	},
})
export const signalsTemplatesQuery = (companyId: string) => ({
	queryKey: signalsKeys.list(companyId),
	queryFn: async () => {
		return await safeFetch(SignalsTemplatesAPISchema, api.signal.list(companyId), {
			method: 'GET',
		})
	},
})

export const signalTemplateQuery = (companyId: string, signalId: string) => ({
	queryKey: signalsKeys.details(companyId, signalId),
	queryFn: async () => {
		const signals = await queryClient.fetchQuery(signalsTemplatesQuery(companyId))

		if (!signals?.length) {
			throw new Response('Signals Not Found', {
				status: 404,
				statusText: 'Not Found',
			})
		}

		const signal = signals.find(v => v.id === Number(signalId))

		if (!signal) {
			throw new Response('Signal Not Found', {
				status: 404,
				statusText: 'Not Found',
			})
		}

		return signal
	},
})

export const signalFilterKeys = {
	all: ['filters'] as const,
	detail: (key: FILTERS) => ['filters', key] as const,
}

export const sortKey = {
	all: ['sort'] as const,
}

export const filterQuery = (key: FILTERS) => ({
	queryKey: signalFilterKeys.detail(key),
	queryFn: () => {
		const result = queryClient.getQueryData(signalFilterKeys.detail(key))

		switch (key) {
			case FILTERS.TYPE:
				return IntentTypeSchema.parse(result) ?? null
			case FILTERS.SOURCE:
				return IntentSourceSchema.parse(result) ?? null
			case FILTERS.WEIGHT:
				return WeightSchema.parse(result) ?? null
			case FILTERS.MAPPED_PERSONAS:
				return MappedPersonasSchema.parse(result) ?? null
			default:
				throw new Error('Invalid filter key')
		}
	},
})
export const sortQuery = () => ({
	queryKey: sortKey.all,
	queryFn: () => {
		const result = queryClient.getQueryData(sortKey.all)

		return SortSchema.parse(result) ?? null
	},
})
