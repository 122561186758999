import * as Sentry from '@sentry/react'
import { showToast } from '#src/context/ToastContext'
import { getEarliestFailedStepFromResErrors } from '#src/utils/actions'

type Settings<T extends string = string> = Partial<{
	toast: boolean
	steps: Parameters<typeof getEarliestFailedStepFromResErrors<T>>[1]
	customAPIMessage: string
}>

type APIErrorRes = {
	success: boolean
	message: string
	errors?: Record<string, string[]>
}

export type ServerFormErrorHandlerRes =
	| {
			ok: false
			message: string
			errors?: APIErrorRes['errors']
			failedStep?: string | undefined
			// eslint-disable-next-line no-mixed-spaces-and-tabs
	  }
	| { ok: boolean }

export const serverFormErrorHandler = async (
	error: unknown,
	settings?: Settings,
): Promise<ServerFormErrorHandlerRes> => {
	const settingsDefaults = { toast: true }
	settings = { ...settingsDefaults, ...settings }

	if (import.meta.env.DEV) {
		console.warn('----serverFormErrorHandler----')
		console.warn(error)
		console.warn('----/serverFormErrorHandler----')
	}

	switch (true) {
		case error instanceof Error: {
			if (settings.toast) {
				showToast({
					message: error.message,
					type: 'error',
				})
			}
			return {
				ok: false,
				message: error.message,
			}
		}
		case error instanceof Response: {
			try {
				const res = error.clone()
				const body = (await res.json()) as APIErrorRes
				if (settings.toast) {
					showToast({
						message: `API: ${settings.customAPIMessage ?? body.message}`,
						type: 'error',
					})
				}
				let failedStep = undefined
				if (settings.steps) {
					failedStep = getEarliestFailedStepFromResErrors(body.errors, settings.steps)
				}

				return {
					ok: false,
					message: body.message,
					errors: body.errors,
					failedStep,
				}
			} catch (e) {
				const message =
					settings.customAPIMessage ??
					(e !== null && typeof e === 'object' && 'message' in e ? (e.message as string) : 'Server error')
				if (settings.toast) {
					showToast({
						message: `API: ${message}`,
						type: 'error',
					})
				}
				return {
					ok: false,
					message,
				}
			}
		}
		default: {
			Sentry.captureMessage(JSON.stringify(error), 'log')
			const message = 'Whoops, we were unable to handle this case'
			if (settings.toast) {
				showToast({
					message,
					type: 'error',
				})
			}

			return {
				ok: false,
				message,
			}
		}
	}
}
