import { useLoaderData } from 'react-router'
import ContactAvatar from '#src/components/chat/avatar'
import Markdown from '#src/components/markdown'
import { Icon } from '#src/components/ui/icon'
import { LabeledValue } from '#src/components/ui/LabeledValue'
import { formatAvatarAbbr } from '#src/utils/misc'
import { type LoaderRes } from '../../loaders/item'

const ItemConversationDetails = () => {
	const { conversationData } = useLoaderData<LoaderRes>()

	return (
		<section className="hidden h-full w-[368px] max-w-full flex-col overflow-hidden rounded-lg bg-white lg:flex">
			<div className="flex flex-row items-center gap-1 p-4">
				<h6 className="text-title-sm">Roleplay details</h6>
			</div>
			<div className="flex-1 overflow-hidden">
				<div className="h-full w-full overflow-y-auto p-6">
					<div className="flex flex-col gap-8">
						<section className="flex flex-row items-center justify-center gap-2 text-brand-1-fg">
							<Icon name="voice-activate" size="lg" />
							<h1 className="text-title-md">{conversationData.conversation.title}</h1>
						</section>
						<section className="flex flex-col gap-4">
							<div className="flex flex-col items-center gap-2.5">
								<ContactAvatar
									variant="blue"
									size="lg"
									initial={formatAvatarAbbr(conversationData.conversation.name)}
								/>
								<h4 className="text-title-sm">{conversationData.conversation.name}</h4>
								<div className="flex flex-col gap-1 text-body-sm">
									{conversationData.conversation.personaInfo?.ecosystem && (
										<div>
											<b>Ecosystem: </b> {conversationData.conversation.personaInfo.ecosystem}
										</div>
									)}
									{conversationData.conversation.personaInfo?.vertical && (
										<div>
											<b>Vertical: </b> {conversationData.conversation.personaInfo.vertical}
										</div>
									)}
								</div>
							</div>
							{conversationData.conversation.scenarioTitle && (
								<LabeledValue label="Agenda">{conversationData.conversation.scenarioTitle}</LabeledValue>
							)}
							{conversationData.conversation.scenario?.instructions && (
								<LabeledValue label="Instructions">
									<Markdown>{conversationData.conversation.scenario?.instructions}</Markdown>
								</LabeledValue>
							)}
						</section>
					</div>
				</div>
			</div>
		</section>
	)
}

export default ItemConversationDetails
