import { useEffect, useState } from 'react'

const useDebounce = <T>(value: T, delay: number = 500, onDebounce?: (value: T) => void): T => {
	const [debouncedValue, setDebouncedValue] = useState<T>(value)

	useEffect(() => {
		if (debouncedValue === value) {
			// lets ignore debounce, because its initial useEffect trigger
			return
		}

		const timer = setTimeout(() => {
			setDebouncedValue(value)
			onDebounce?.(value)
		}, delay)

		return () => {
			clearTimeout(timer)
		}
	}, [value, delay, debouncedValue, onDebounce])

	return debouncedValue
}

export default useDebounce
