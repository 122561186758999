import { captureException } from '@sentry/react'
import { type ActionFunctionArgs, redirect } from 'react-router'
import { contactsEnrichMutations } from '#src/api/icp/company/contacts-enrich/mutations'
import { NewContactEnrichFormSchema } from '#src/api/icp/company/contacts-enrich/schemas'
import { showToast } from '#src/context/ToastContext'
import { parseFormDataNew } from '#src/utils/forms'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const { companyId, company } = await getCompany(params)
	const formData = await request.formData()
	const submission = parseFormDataNew(formData, NewContactEnrichFormSchema)

	try {
		await contactsEnrichMutations.create(company.id, submission.value)

		showToast({
			message: `Successfully ${params.pointId ? 'updated' : 'added'}`,
			type: 'success',
		})
	} catch (err) {
		captureException(err)
		showToast({
			message: 'Unexpected error occurred',
			type: 'error',
		})

		return { ok: false }
	}

	return redirect(routes.enable.contactsEnrich.index({ companyId }))
}
