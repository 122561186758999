import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { PaginatedAPIResSchema } from '#src/schemas/global'
import { safeFetch, safeFetchMeta } from '#src/utils/safeFetch'
import { userEndpoints } from './endpoints'
import { CompanyUserAPISchema, RolesAPISchema } from './schemas'

const keys = {
	all: ['icp', 'company', 'user'] as const,
	list: (companyId: z.infer<typeof CompanySchema>['id'], page: number, perPage?: number) =>
		[...keys.all, 'list', companyId, page, perPage] as const,
	item: (companyId: z.infer<typeof CompanySchema>['id'], itemId: z.infer<typeof CompanyUserAPISchema>['id']) =>
		[...keys.all, 'item', companyId, itemId] as const,
	roles: (companyId: z.infer<typeof CompanySchema>['id']) => [...keys.all, 'roles', companyId] as const,
	allRoles: (companyId: z.infer<typeof CompanySchema>['id']) => [...keys.all, 'all-roles', companyId] as const,
}

const listQuery = (companyId: z.infer<typeof CompanySchema>['id'], page: number, perPage?: number) => ({
	queryKey: keys.list(companyId, page, perPage),
	queryFn: async () =>
		await safeFetchMeta(PaginatedAPIResSchema(CompanyUserAPISchema), userEndpoints.list(companyId, page, perPage), {
			method: 'GET',
		}),
})

const itemQuery = (
	companyId: z.infer<typeof CompanySchema>['id'],
	itemId: z.infer<typeof CompanyUserAPISchema>['id'],
) => ({
	queryKey: keys.item(companyId, itemId),
	queryFn: async () =>
		await safeFetch(CompanyUserAPISchema, userEndpoints.item(companyId, itemId), {
			method: 'GET',
		}),
})

const rolesQuery = (companyId: z.infer<typeof CompanySchema>['id']) => ({
	queryKey: keys.roles(companyId),
	staleTime: Infinity,
	queryFn: async () =>
		await safeFetch(RolesAPISchema, userEndpoints.roles(companyId), {
			method: 'GET',
		}),
})

const allRolesQuery = (companyId: z.infer<typeof CompanySchema>['id']) => ({
	queryKey: keys.allRoles(companyId),
	staleTime: Infinity,
	queryFn: async () =>
		await safeFetch(RolesAPISchema, userEndpoints.allRoles(companyId), {
			method: 'GET',
		}),
})

const queries = {
	list: listQuery,
	item: itemQuery,
	roles: rolesQuery,
	allRoles: allRolesQuery,
}

export { queries as userQueries, keys as userQueriesKeys }
