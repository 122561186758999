import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useLoaderData, useNavigate, Link } from 'react-router'
import { type z } from 'zod'
import { type VerticalListAPISchema } from '#src/api/icp/company/verticals/schemas'
import ProductTip from '#src/components/product-tip'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { CustomTooltip } from '#src/components/ui/tooltip'
import useCompany from '#src/hooks/useCompany'
import { renderCriterionAnswerValue } from '#src/routes/calibrate/ecosystem-management/criteria/utils'
import {
	autofocusFieldMutation,
	autofocusFieldQuery,
} from '#src/routes/calibrate/ecosystem-management/verticals/autofocus'
import { type LoaderResType } from '#src/routes/calibrate/ecosystem-management/verticals/loaders'
import { generateCriterionFieldId } from '#src/routes/calibrate/ecosystem-management/verticals/utils'
import { routes } from '#src/utils/routes'

export const useQualificationGroup = (verticals: z.infer<typeof VerticalListAPISchema>) => {
	const {
		handle: { ecosystemId, readOnlySession },
		criteria,
	} = useLoaderData<LoaderResType>()
	const { companyId } = useCompany()
	const navigate = useNavigate()
	const { data: autofocus } = useQuery(autofocusFieldQuery())

	const header = useMemo(
		() => ({
			collapsible: {
				options: { sticky: true, collapsible: true },
				columns: [
					{
						children: (
							<section className="flex w-full items-center justify-between">
								<p className="flex items-center gap-1 text-body-md font-semibold text-neutral-2-fg">
									Qualification Criteria
									<span className="font-normal text-neutral-3-fg">{criteria.length}</span>
									<ProductTip content="Qualification Criteria: The specific characteristics used to define your personas based on market research and real customer data." />
								</p>
								{!readOnlySession && (
									<div>
										<CustomTooltip label="Add criterion">
											<Button variant="icon" size="icon" asChild>
												<Link
													to={routes.calibrate.criteria.list({
														companyId,
														ecosystemId,
														criterionType: 'qualification',
													})}
												>
													<Icon name="add" size="sm" />
												</Link>
											</Button>
										</CustomTooltip>
										<CustomTooltip label="Edit all qualification criteria">
											<Button variant="icon" size="icon" asChild>
												<Link
													to={routes.calibrate.criteria.editAnswers({
														companyId,
														ecosystemId,
														criterionType: 'qualification',
													})}
												>
													<Icon name="edit" size="sm" />
												</Link>
											</Button>
										</CustomTooltip>
									</div>
								)}
							</section>
						),
					},
					...verticals.map(() => ({ className: 'border-r-transparent', children: '' })),
				],
			},
		}),
		[criteria, verticals, readOnlySession, companyId, ecosystemId],
	)

	const body = useMemo(
		() => ({
			id: 'group-4',
			rows: criteria.map((item, index) => ({
				focus: autofocus?.includes(`answers[${index}].value`),
				columns: [
					{
						className: 'align-top',
						children: (
							<div>
								<CustomTooltip
									label={
										<>
											AI Agent instructions:&nbsp;
											{item.criterion.instructions}
										</>
									}
									side="right"
									sideOffset={16}
								>
									<p className="w-[90%] whitespace-pre-line break-words text-body-md font-medium">
										{item.criterion.name}
									</p>
								</CustomTooltip>
								<div className="absolute right-0 top-0 h-0 w-0 border-b-0 border-l-[15px] border-t-[13px] border-l-transparent border-t-brand-2-bd" />
								<Button variant="icon" size="icon" asChild className="absolute right-[16px] top-1/2 -translate-y-1/2">
									<Link
										to={routes.calibrate.criteria.edit({
											companyId,
											ecosystemId,
											criterionId: String(item.criterion.id),
											criterionType: 'qualification',
										})}
									>
										<Icon name="edit" size="sm" />
									</Link>
								</Button>
							</div>
						),
					},
					...verticals.map(vertical => ({
						...(readOnlySession
							? {}
							: {
									onClick: () => {
										autofocusFieldMutation(
											generateCriterionFieldId(
												vertical.answers?.find(answer => answer.criterionId === item.criterion.id)?.criterionId ?? 0,
											),
										)
										void navigate(
											routes.calibrate.verticals.edit({
												companyId,
												ecosystemId,
												verticalId: vertical.id.toString(),
											}),
											{ replace: true, preventScrollReset: true },
										)
									},
								}),
						className: 'align-top',
						children: (
							<p className="text-body-md font-normal text-neutral-2-fg">
								{renderCriterionAnswerValue(vertical.answers?.find(answer => answer.criterionId === item.criterion.id))}
							</p>
						),
					})),
				],
			})),
		}),
		[criteria, autofocus, companyId, ecosystemId, verticals, readOnlySession, navigate],
	)

	return { ...header, body }
}
