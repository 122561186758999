import { clsx } from 'clsx'
import { Icon, type IconName } from '#src/components/ui/icon'
import { type ClassName } from '#src/types/styles'
import { QUEUE_STATUS, QUEUE_STATUS_LABEL } from '#src/utils/enumerations'
import { cn } from '#src/utils/misc'

type StatusLabelProps = {
	status: QUEUE_STATUS
}

const LABEL_BG: Record<QUEUE_STATUS, ClassName> = {
	[QUEUE_STATUS.pending]: 'bg-neutral-2-bg',
	[QUEUE_STATUS.queued]: 'bg-neutral-2-bg',
	[QUEUE_STATUS.inProgress]: 'bg-brand-1-bg',
	[QUEUE_STATUS.completed]: 'bg-green-10',
	[QUEUE_STATUS.canceled]: 'bg-red-10',
	[QUEUE_STATUS.crashed]: 'bg-red-10',
} as const

const ICON: Record<QUEUE_STATUS, IconName> = {
	[QUEUE_STATUS.pending]: 'circle-dash',
	[QUEUE_STATUS.queued]: 'circle-dash',
	[QUEUE_STATUS.inProgress]: 'loading-sm',
	[QUEUE_STATUS.completed]: 'checkmark-filled',
	[QUEUE_STATUS.canceled]: 'stop-sign-filled',
	[QUEUE_STATUS.crashed]: 'stop-sign-filled',
} as const

const COLOR: Record<QUEUE_STATUS, ClassName> = {
	[QUEUE_STATUS.pending]: 'text-neutral-3-fg',
	[QUEUE_STATUS.queued]: 'text-neutral-1-fg',
	[QUEUE_STATUS.inProgress]: 'text-brand-1-fg',
	[QUEUE_STATUS.completed]: 'text-green-80',
	[QUEUE_STATUS.canceled]: 'text-red-60',
	[QUEUE_STATUS.crashed]: 'text-red-60',
} as const

const getQueueStatusLabel = (status: QUEUE_STATUS): string => {
	switch (status) {
		case QUEUE_STATUS.pending:
			return QUEUE_STATUS_LABEL[QUEUE_STATUS.pending]
		case QUEUE_STATUS.queued:
			return QUEUE_STATUS_LABEL[QUEUE_STATUS.queued]
		case QUEUE_STATUS.inProgress:
			return `${QUEUE_STATUS_LABEL[QUEUE_STATUS.inProgress]}...`
		case QUEUE_STATUS.completed:
			return QUEUE_STATUS_LABEL[QUEUE_STATUS.completed]
		case QUEUE_STATUS.crashed:
			return QUEUE_STATUS_LABEL[QUEUE_STATUS.crashed]
		case QUEUE_STATUS.canceled:
			return QUEUE_STATUS_LABEL[QUEUE_STATUS.canceled]
		default:
			return 'Unknown'
	}
}

export const getQueueStatus = (value: string = QUEUE_STATUS.pending): QUEUE_STATUS => {
	if (Object.values(QUEUE_STATUS).includes(value as QUEUE_STATUS)) {
		return value as QUEUE_STATUS
	}

	return QUEUE_STATUS.pending
}

export const QueueStatusLabel = ({ status }: StatusLabelProps) => {
	return (
		<div
			className={clsx(
				'inline-flex flex-row items-center gap-1 rounded-xl py-1 pe-1.5 ps-1',
				LABEL_BG[status],
				COLOR[status],
			)}
		>
			<Icon name={ICON[status]} className={cn('h-4 w-4', ICON[status].includes('loading') && 'animate-spin')} />
			<div className="text-body-sm">{getQueueStatusLabel(status)}</div>
		</div>
	)
}
