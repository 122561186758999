import { type z } from 'zod'
import { type PlaysItemAPISchema } from '#src/api/icp/company/plays/schemas'
import DeleteConfirm from '#src/components/delete-confirm'
import LoaderRequiredComponent from '#src/components/LoaderRequiredComponent'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { type LoaderRes } from '#src/routes/enable/plays/root/loaders/item'
import { routes } from '#src/utils/routes'

export type PlayDeleteModalProps = {
	playId: z.infer<typeof PlaysItemAPISchema.shape.id>
}

export const PlayDeleteModal = (props: PlayDeleteModalProps) => {
	const { companyId } = useCompany()

	return (
		<LoaderRequiredComponent<LoaderRes, PlayDeleteModalProps>
			route={routes.enable.plays.item({ companyId, playId: String(props.playId) })}
			component={ModalInner}
			initialProps={props}
		/>
	)
}

const ModalInner = (props: PlayDeleteModalProps & LoaderRes) => {
	const {
		data: { play },
	} = props
	const { closeModal } = useModals()
	const { companyId } = useCompany()

	return (
		<DeleteConfirm
			defaultOpen={true}
			onOpenChange={isOpen => !isOpen && closeModal(MODAL_NAME.PlayDelete)}
			action={routes.enable.plays.delete({
				companyId,
				playId: String(play.id),
			})}
			method="DELETE"
			actionTitle="Delete"
			title="Delete play?"
			description={
				<>
					Are you sure you want to remove the <b>{play.name}</b> Play?
				</>
			}
		/>
	)
}
