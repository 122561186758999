import { type ActionFunctionArgs, redirect } from 'react-router'
import { type z } from 'zod'
import { playsQueriesKeys } from '#src/api/icp/company/plays/queries'
import { verticalsQueriesKeys, verticalsQueries } from '#src/api/icp/company/verticals/queries'
import { showToast } from '#src/context/ToastContext'
import { client } from '#src/main'
import { type CRITERION_TYPE_ENUM } from '#src/routes/calibrate/ecosystem-management/criteria/constants'
import {
	assignCriterion,
	createCriterion,
	updateAssignedAnswers,
	updateCriterion,
} from '#src/routes/calibrate/ecosystem-management/criteria/mutations'
import { criteriaKeys, criterionQuery } from '#src/routes/calibrate/ecosystem-management/criteria/queries'
import {
	generateSaveCriterionContext,
	transformAnswersSubmissionToPayload,
} from '#src/routes/calibrate/ecosystem-management/criteria/utils'
import { ECOSYSTEM_TYPE } from '#src/routes/calibrate/ecosystem-management/ecosystem/constants'
import { ecosystemKeys, ecosystemQuery } from '#src/routes/calibrate/ecosystem-management/ecosystem/queries'
import { type APIFieldType } from '#src/schemas/global'
import { parseFormData } from '#src/utils/forms'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'
import { transformAndCleanToggleableFieldsSchema } from '#src/utils/validation'

import {
	CriterionAllAssignedVerticalsAnswersPayload,
	CriterionNewPayloadSchema,
	getCriterionNewFormSchema,
} from '../schema'

export type ActionResType = Awaited<ReturnType<ReturnType<typeof action>>>

export const action =
	(criterionType: z.infer<typeof CRITERION_TYPE_ENUM>) =>
	async ({ params, request }: ActionFunctionArgs) => {
		validateRouteParams(params, ['ecosystemId'])
		const { company, companyId } = await getCompany(params)
		const url = new URL(request.url)
		const redirectTo = url.searchParams.get('to')

		const formData = await request.formData()
		const fieldType = formData.get('type') as APIFieldType
		const isEdit = !!params.criterionId
		const submitAction = formData.get('submitAction') as string | null

		const ecosystem = await client.fetchQuery(ecosystemQuery(company.id, params.ecosystemId))
		const isCriteriaSandbox = ecosystem.type === ECOSYSTEM_TYPE.CRITERIA_SANDBOX
		const criterion = isEdit ? await client.fetchQuery(criterionQuery(company.id, params.criterionId)) : undefined

		const formSchema = getCriterionNewFormSchema(fieldType)
		const formSubmission = parseFormData(formData, formSchema)

		const verticals = await client.fetchQuery(verticalsQueries.list(company.id, Number(params.ecosystemId)))

		if (criterion) {
			criterion.isEnrichment = criterionType === 'enrichment'
		}

		const { schema: verticalsAnswersSchema } = generateSaveCriterionContext(
			verticals,
			formSubmission.value.type,
			criterionType,
			criterion,
		)

		const verticalsAnswersSubmission = parseFormData(
			formData,
			verticalsAnswersSchema.transform(transformAndCleanToggleableFieldsSchema),
		)

		const createOrUpdateCriterion = async () => {
			const formPayload = {
				...formSubmission.value,
				isEnrichment: criterionType === 'enrichment',
				...(isCriteriaSandbox ? { isSandbox: true } : {}),
			}
			try {
				const parsedForm = CriterionNewPayloadSchema.parse(formPayload)

				if (isEdit) {
					/*
					   If a criterion is inside the `criteria_sandbox` ecosystem but is not marked as a sandbox itself,
					   it must be duplicated (created as a new one), and vice versa.

					   Using a sandbox criterion inside a non-sandbox ecosystem acts only as a template, and vice versa.
					   Logic will be moved to backend.
					*/
					const shouldCreateNew =
						(isCriteriaSandbox && !criterion?.isSandbox) ||
						(!isCriteriaSandbox && criterion?.isSandbox) ||
						criterion?.isTemplate ||
						criterion?.isThirdParty

					return shouldCreateNew
						? await createCriterion(company.id, parsedForm)
						: await updateCriterion(company.id, params.criterionId, parsedForm)
				}

				return await createCriterion(company.id, parsedForm)
			} catch (err) {
				const serverErr = await serverFormErrorHandler(err)

				if ('message' in serverErr) {
					throw new Error(serverErr.message)
				}
			}
		}

		const updateAnswers = async (criterionId: string) => {
			const verticalsAnswersPayload = transformAnswersSubmissionToPayload(
				verticalsAnswersSubmission.value,
				criterionType,
				params.ecosystemId,
				criterionId,
			)

			try {
				const parsedForm = CriterionAllAssignedVerticalsAnswersPayload.parse(verticalsAnswersPayload)
				await updateAssignedAnswers(company.id, criterionId, parsedForm)
			} catch (err) {
				const serverErr = await serverFormErrorHandler(err)

				if ('message' in serverErr) {
					throw new Error(serverErr.message)
				}
			}
		}

		const assignCriterionToEcosystem = async (criterionId: string) => {
			try {
				await assignCriterion(company.id, {
					criterionId,
					ecosystemId: params.ecosystemId,
				})
			} catch (err) {
				const serverErr = await serverFormErrorHandler(err)

				if ('message' in serverErr) {
					throw new Error(serverErr.message)
				}
			}
		}

		try {
			const criterionResult = await createOrUpdateCriterion()

			if (criterionResult && 'id' in criterionResult) {
				await assignCriterionToEcosystem(String(criterionResult.id))
				await updateAnswers(String(criterionResult.id))
			}

			await Promise.all([
				client.invalidateQueries({ queryKey: criteriaKeys.all }),
				client.invalidateQueries({ queryKey: ecosystemKeys.all }),
				client.invalidateQueries({ queryKey: verticalsQueriesKeys.all }),
				// connected to plays, because it has criterias inside, so need to invalidate all queries below
				client.invalidateQueries({ queryKey: playsQueriesKeys.all }),
			])

			showToast({
				message:
					submitAction === 'add'
						? 'Criterion successfully added'
						: isEdit
							? 'Criterion successfully updated'
							: 'Criterion successfully created',
				type: 'success',
				bottom: '60px',
			})
		} catch (err) {
			showToast({
				type: 'error',
				message: 'Failed to save criterion',
			})
		}

		let closeTo

		if (submitAction === 'update-close' || submitAction === 'add') {
			if (redirectTo === 'index') {
				closeTo = routes.calibrate.verticals.index({
					companyId,
					ecosystemId: params.ecosystemId,
				})
			} else {
				closeTo = routes.calibrate.criteria.editAnswers({
					companyId,
					ecosystemId: params.ecosystemId,
					criterionType,
				})
			}
		} else {
			return null
		}

		return redirect(closeTo)
	}
