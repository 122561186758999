import { type z } from 'zod'
import { type LeadResearchAgentItemAPISchema } from '#src/api/organise/lists/schemas'
import { EXPORT_TYPE } from '#src/api/organise/lists-csv/constants'
import {
	type LeadResearchAgentJobDownloadFormSchema,
	type LeadResearchAgentTemplateDownloadFormSchema,
} from '#src/api/organise/lists-csv/schemas'
import { fetch } from '#src/utils/safeFetch'
import { orgListsCsvEndpoints } from './endpoints'

const mutations = {
	downloadJob: async (
		jobId: z.infer<typeof LeadResearchAgentItemAPISchema.shape.id>,
		data: z.infer<typeof LeadResearchAgentJobDownloadFormSchema>,
	) => {
		const query = ((): Record<string, string> => {
			switch (data.type) {
				case EXPORT_TYPE.Full:
					return { type: 'account_contact' }
				case EXPORT_TYPE.Accounts:
					return { type: 'account' }
				case EXPORT_TYPE.Contacts:
					return { type: 'contact' }
				case EXPORT_TYPE.ICPAccounts:
					return { type: 'account', icp: 'true' }
				case EXPORT_TYPE.IBPContacts:
					return { type: 'contact', ibp: 'true' }
				case EXPORT_TYPE.IBPContactsWithDetails:
					return { type: 'contact', ibp: 'true', ['phone-email-exist']: 'true' }
				case EXPORT_TYPE.ICPIBPFull:
					return { type: 'account_contact', icp: 'true', ibp: 'true' }
				case EXPORT_TYPE.ICPIBPFullWithDetails:
					return { type: 'account_contact', icp: 'true', ibp: 'true', ['phone-email-exist']: 'true' }
			}
		})()
		return await fetch(orgListsCsvEndpoints.downloadJob(jobId, query), {
			method: 'GET',
			// timeout is 10 minutes, because of this issue: https://evergrowth.sentry.io/issues/6102575650/?project=4506823228915712
			signal: AbortSignal.timeout(10 * 60 * 1000),
		})
	},
	downloadTemplate: async (data: z.infer<typeof LeadResearchAgentTemplateDownloadFormSchema>) => {
		return await fetch(orgListsCsvEndpoints.downloadTemplate(data.type), {
			method: 'GET',
		})
	},
}

export { mutations as orgListsCsvMutations }
