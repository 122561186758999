import { type RouteObject } from 'react-router'
import { DevelopersPlaygroundView } from '#src/routes/developers/routes'
import { playgroundSchema } from '#src/routes/developers/views/playground'
import { parseFormDataNew } from '#src/utils/forms'
import { transformAndCleanToggleableFieldsSchema } from '#src/utils/validation'

const developersRouter = {
	id: 'developers-loader',
	path: 'developers',
	children: [
		{
			path: 'playground',
			element: <DevelopersPlaygroundView />,
			action: async ({ request }) => {
				const data = await request.formData()
				const formDataObj = Object.fromEntries(data)
				console.log(formDataObj)

				const submission = parseFormDataNew(data, playgroundSchema.transform(transformAndCleanToggleableFieldsSchema))
				console.log('submission', submission)

				return { ok: true }
			},
		},
	],
} satisfies RouteObject

export default developersRouter
