import { type z } from 'zod'
import { type PersonaAPISchema } from '#src/api/icp/company/persona/schemas'
import { PersonaAvatar } from '#src/components/persona'
import Priority from '#src/components/priority'
import Status from '#src/components/status'
import { type EcosystemAPIPersonaSchema } from '#src/routes/calibrate/ecosystem-management/ecosystem/schema'

type Props = {
	persona: z.infer<typeof EcosystemAPIPersonaSchema> | z.infer<typeof PersonaAPISchema>
	avatarSize?: 'sm' | 'md' | 'lg'
	showJobSeniority?: boolean
}

export const PersonaDetailsCard = (props: Props) => {
	const { persona, avatarSize = 'md', showJobSeniority } = props

	return (
		<section className="flex items-center gap-3.5">
			<PersonaAvatar type={persona.type} size={avatarSize} />
			<div className="flex flex-col gap-1 overflow-hidden">
				<p className="w-full truncate text-body-sm font-semibold text-neutral-3-fg">{persona.type}</p>
				<p className="w-full truncate text-body-lg font-semibold text-neutral-1-fg">{persona.expertise}</p>
				{!!showJobSeniority && (
					<p className="w-full truncate text-body-sm font-semibold text-neutral-3-fg">{persona.jobSeniority}</p>
				)}
				<div className="flex items-center gap-2">
					<Status status={persona.status} />
					<Priority priority={persona.priority} />
				</div>
			</div>
		</section>
	)
}
