import { type ActionFunctionArgs, redirect } from 'react-router'
import { adminCompanyMutations } from '#src/api/icp/admin/company/mutations'
import { CompanyTransactionFormSchema } from '#src/api/icp/admin/company/schemas'
import { showToast } from '#src/context/ToastContext'
import { parseFormDataNew } from '#src/utils/forms'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ params, request }: ActionFunctionArgs) => {
	validateRouteParams(params, ['companyId'])
	const formData = await request.formData()

	try {
		const submission = parseFormDataNew(formData, CompanyTransactionFormSchema)
		await adminCompanyMutations.addTransaction(params.companyId, submission.value)

		showToast({
			message: 'Transaction added successfully',
			type: 'success',
		})

		redirect(`${routes.admin.company.edit({ companyId: params.companyId })}?tab=credits_usage`)
		return { ok: true }
	} catch (e) {
		return await serverFormErrorHandler(e)
	}
}
