import { type RouteObject } from 'react-router'
import { MaintenancePage } from '#src/components/MaintenancePage'
import DefaultSidebarLayout from '#src/layouts/DefaultSidebarLayout'
import adminRouter from './admin/router'
import authRouter from './auth/router'
import billingRouter from './billing/router'
import calibrateRouter from './calibrate/router'
import companyRouter from './company/router'
import copilotRouter from './copilot/router'
import developersRouter from './developers/router'
import enableRouter from './enable/router'
import { loader as mainLoader } from './main/loaders/root'
import organizeRouter from './organize/router'
import prioritizeRouter from './prioritize/router'
import reportRouter from './report/router'
import { loader as rootLoader } from './root/loaders/root'
import Root from './root/views/root'
import RootBoundary from './root/views/root-boundary'
import settingsRouter from './settings/router'

const mainRouter = [
	...(import.meta.env.VITE_MAINTENANCE_MODE_HEADING && import.meta.env.VITE_MAINTENANCE_MODE_HEADING !== 'false'
		? [
				{
					path: '*',
					element: <MaintenancePage />,
				},
			]
		: [
				{
					element: <Root />,
					errorElement: <RootBoundary />,
					loader: rootLoader,
					children: [
						authRouter,
						copilotRouter,
						{
							id: 'main-loader',
							path: '/:companyId?',
							element: <DefaultSidebarLayout />,
							loader: mainLoader,
							children: [
								companyRouter,
								calibrateRouter,
								organizeRouter,
								prioritizeRouter,
								enableRouter,
								settingsRouter,
								adminRouter,
								reportRouter,
								billingRouter,
								...(import.meta.env.VITE_APP_ENVIRONMENT !== 'production' ? [developersRouter] : []),
							],
						},
					],
				},
			]),
] satisfies RouteObject[]

export default mainRouter
