import { z } from 'zod'

export const StatsEnableAPISchema = z.object({
	copilot: z.number(),
	personas: z.number(),
	scenarios: z.number(),
	sessions: z.number(),
	terms: z.number(),
	plays: z.number(),
	enriched_contacts: z.number(),
})

export const StatsAPISchema = z.object({
	time: z.object({
		verticals: z.number(),
		personas: z.number(),
		signals: z.number(),
		terms: z.number(),
	}),
	calibrate: z.object({
		ecosystem_count: z.number(),
		vertical_count: z.number(),
		persona_count: z.number(),
		value_proposition_count: z.number(),
	}),
	prioritize: z.object({
		signals: z.number(),
		personas: z.number(),
	}),
	enableNew: StatsEnableAPISchema,
	organize: z.object({
		completed_account_exports: z.number(),
		launched_account_exports: z.number(),
	}),
})

export const StatsValuePropositionSchema = z.object({
	cost: z.number(),
	time: z.number(),
	timeSaved: z.number(),
})
