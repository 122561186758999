import { useCallback, useEffect, useRef } from 'react'
import { type BlockerFunction, Link, useBlocker, useLoaderData, useNavigate } from 'react-router'
import { EmptyStateCard } from '#src/components'
import { Chip } from '#src/components/chip'
import Markdown from '#src/components/markdown'
import { Accordion } from '#src/components/ui/accordion'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { LabeledValue } from '#src/components/ui/LabeledValue'
import { PageLayout } from '#src/components/ui/PageLayout'
import useCompany from '#src/hooks/useCompany'
import { type IndexLoaderRes } from '#src/routes/calibrate/value-proposition/routes'
import { formatDateToReadableString } from '#src/utils/date'
import { routes } from '#src/utils/routes'
import { AccordionTrigger, AccordionContent } from './components'

export const View = ({ isReadOnly }: { isReadOnly?: boolean }) => {
	const { companyId } = useCompany()
	const {
		data: { valueProposition, wordCount },
	} = useLoaderData<IndexLoaderRes>()
	const navigate = useNavigate()
	const bypassBlocker = useRef(false)

	const shouldBlock = useCallback<BlockerFunction>(
		({ currentLocation, nextLocation }) =>
			!bypassBlocker.current &&
			valueProposition.isDraft &&
			currentLocation.pathname !== nextLocation.pathname &&
			!nextLocation.pathname.includes('value-proposition'),
		[valueProposition.isDraft],
	)

	const blocker = useBlocker(shouldBlock)

	useEffect(() => {
		if (blocker.state === 'blocked') {
			bypassBlocker.current = true
			blocker.reset?.()

			void navigate(routes.calibrate.valueProposition.publishWarn({ companyId }), {
				state: { redirectPath: blocker.location.pathname },
			})
		}
	}, [blocker, companyId, navigate])

	return (
		<PageLayout
			title="Value Proposition"
			description={wordCount ? `Total ${wordCount} words` : undefined}
			titleItems={
				<>
					<Chip variant="gray">
						{valueProposition.isDraft
							? 'Draft'
							: formatDateToReadableString(valueProposition.publishedAt ?? '', { includeTime: true })}
					</Chip>
					{!isReadOnly && valueProposition.isDraft && valueProposition.publishedAt && (
						<Button asChild size="sm" variant="ghost" className="gap-1.5">
							<Link to={routes.calibrate.valueProposition.published({ companyId })} target="_blank">
								View currently published version
								<Icon name="launch" size="sm" />
							</Link>
						</Button>
					)}
				</>
			}
			actions={
				!isReadOnly
					? [
							{
								title: valueProposition.isDraft ? 'Publish' : 'Published',
								icon: valueProposition.isDraft ? 'arrow-up' : 'check',
								to: routes.calibrate.valueProposition.publish({ companyId }),
								variant: 'outline',
								disabled: !valueProposition.isDraft || !valueProposition.valuePropositions.length,
							},
							{
								title: 'Add New Point',
								icon: 'add',
								to: routes.calibrate.valueProposition.new({ companyId }),
							},
						]
					: []
			}
		>
			{!isReadOnly ? (
				<Accordion
					data={valueProposition.valuePropositions.map((item, i) => ({
						trigger: <AccordionTrigger key={item.id} item={item} id={i + 1} />,
						content: <AccordionContent key={item.id} item={item} />,
					}))}
					type="multiple"
					className="rounded-lg border border-neutral-1-bd"
					triggerProps={{ className: 'py-3.5 px-4.5', separator: true }}
				/>
			) : (
				valueProposition.valuePropositions.length &&
				valueProposition.valuePropositions.map(item => (
					<LabeledValue key={item.id} label={item.name}>
						<Markdown>{item.description}</Markdown>
					</LabeledValue>
				))
			)}
			{!valueProposition.valuePropositions.length && (
				<EmptyStateCard title="You have no value proposition yet" icon="white-paper" />
			)}
		</PageLayout>
	)
}
