export const CURRENCIES = [
	{
		code: 'USD',
		symbol: '$',
		name: 'Dollars',
	},
	{
		code: 'EUR',
		symbol: '€',
		name: 'Euros',
	},
] as const
