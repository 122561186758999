import { type z } from 'zod'
import { verticalsEndpoints } from '#src/api/icp/company/verticals/endpoints'
import { verticalsQueriesKeys } from '#src/api/icp/company/verticals/queries'
import {
	type CreateCsvFormSchema,
	VerticalAPISchema,
	type VerticalFormSchemaBase,
} from '#src/api/icp/company/verticals/schemas'
import { client } from '#src/main'
import { type ECOSYSTEM_TYPE } from '#src/routes/calibrate/ecosystem-management/ecosystem/constants'
import { ecosystemKeys } from '#src/routes/calibrate/ecosystem-management/ecosystem/queries'
import { type CompanySchema } from '#src/routes/company/schema'
import { makeDownloadableFromRes } from '#src/utils/files'
import { objectToFormData } from '#src/utils/forms'
import { fetch, safeFetch } from '#src/utils/safeFetch'

const mutations = {
	create: async (companyId: string, ecosystemId: number, data: z.infer<typeof VerticalFormSchemaBase>) => {
		return await safeFetch(VerticalAPISchema, verticalsEndpoints.create(companyId), {
			method: 'POST',
			body: JSON.stringify({ ...data, ecosystemId }),
		})
	},
	update: async (companyId: string, verticalId: number, data: z.infer<typeof VerticalFormSchemaBase>) => {
		return await safeFetch(VerticalAPISchema, verticalsEndpoints.item(companyId, verticalId), {
			method: 'PATCH',
			body: JSON.stringify(data),
		})
	},
	delete: async (companyId: string, verticalId: string) => {
		return await fetch(verticalsEndpoints.item(companyId, Number(verticalId)), {
			method: 'DELETE',
		})
	},
	import: async (companyId: z.infer<typeof CompanySchema>['id'], data: z.infer<typeof CreateCsvFormSchema>) => {
		const body = objectToFormData(data)

		await fetch(verticalsEndpoints.import(companyId), {
			method: 'POST',
			headers: {
				Accept: 'application/json',
			},
			body,
		})
		await Promise.all([
			client.invalidateQueries({
				queryKey: verticalsQueriesKeys.all,
			}),
			client.invalidateQueries({
				queryKey: ecosystemKeys.all,
			}),
		])
	},
	downloadTemplate: async (companyId: z.infer<typeof CompanySchema>['id'], ecosystemType: ECOSYSTEM_TYPE) => {
		const res = await fetch(verticalsEndpoints.downloadTemplate(companyId, ecosystemType), {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		})
		await makeDownloadableFromRes(res)
	},
}

export { mutations as verticalsMutations }
