import { type ActionFunctionArgs, redirect } from 'react-router'
import { resetPassword } from '#src/routes/auth/mutations'
import { ResetPasswordFormSchema } from '#src/routes/auth/schema'
import { parseFormDataNew } from '#src/utils/forms'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ params, request }: ActionFunctionArgs) => {
	validateRouteParams(params, ['token'])

	const formData = await request.formData()

	try {
		const submission = parseFormDataNew(formData, ResetPasswordFormSchema)
		await resetPassword({
			token: params.token,
			password: submission.value.password,
		})

		return redirect(routes.auth.login)
	} catch (e) {
		return await serverFormErrorHandler(e)
	}
}
