import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router'
import { Dropdown, DropdownItem } from '#src/components/ui/dropdown'
import { Icon } from '#src/components/ui/icon'
import useAuth from '#src/hooks/useAuth'
import { PanelSectionItem, PanelSectionItemAction } from '#src/layouts/Panel'
import { RecalculatePersonasAsyncForm } from '#src/routes/calibrate/ecosystem-management/personas/recalculate'
import { DeleteBatchPersonaForm } from '#src/routes/calibrate/ecosystem-management/personas/views/components/DeleteBatchPersonaForm'
import { type CalibrateNavVertical } from '#src/routes/calibrate/ecosystem-management/sidebar/schema'
import { PersonaItem } from '#src/routes/calibrate/ecosystem-management/sidebar/views/components/PersonaItem'
import { autofocusVerticalMutation } from '#src/routes/calibrate/ecosystem-management/verticals/autofocus'
import { DeleteVerticalAsyncForm } from '#src/routes/calibrate/ecosystem-management/verticals/views/delete'
import { cn } from '#src/utils/misc'

export const VerticalItem = ({ verticals }: { verticals: CalibrateNavVertical[] }) => {
	const { readOnlySession } = useAuth()
	const [open, setOpen] = useState<string[]>([])
	const params = useParams()
	const [isDropdownOpen, setIsDropdownOpen] = useState<{
		id: string
		open: boolean
	}>({ id: '', open: false })

	useEffect(() => {
		if (params.ecosystemId && params.personaId) {
			const openVertical = verticals.find(
				vertical => !!vertical.personas.find(p => p.id.toString() === params.personaId),
			)

			const newOpen = openVertical ? [...open, openVertical.id.toString()] : [...open]

			setOpen(newOpen)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.ecosystemId, params.personaId])

	return (
		<AccordionPrimitive.Root className="flex flex-col" type="multiple" value={open} onValueChange={setOpen}>
			{verticals.map(vertical => (
				<AccordionPrimitive.Item key={vertical.id} value={vertical.id.toString()}>
					<PanelSectionItem
						className="group justify-between"
						isTrigger
						isActive={
							open.includes(vertical.id.toString()) && vertical.personas.some(p => p.id.toString() === params.personaId)
						}
					>
						<section className="-my-2 flex w-full flex-nowrap items-start gap-1 truncate py-2">
							<AccordionPrimitive.Trigger className="group" disabled={!vertical.personas?.length && readOnlySession}>
								<Icon
									name="chevron-down"
									size="sm"
									aria-hidden
									className="transform text-neutral-3-fg group-disabled:invisible group-radix-state-open:rotate-180"
								/>
							</AccordionPrimitive.Trigger>
							<Link
								to={`${vertical.to}`}
								className="-my-2 flex w-full flex-nowrap gap-2 truncate py-2"
								onClick={() => autofocusVerticalMutation(vertical.id.toString())}
							>
								<Icon name="category" size="sm" aria-hidden className="shrink-0 text-neutral-3-fg" />
								<span className={cn('truncate', open.includes(vertical.id.toString()) ? 'font-semibold' : '')}>
									{vertical.name}
								</span>
							</Link>
						</section>

						{readOnlySession ? null : (
							<section
								className={cn('panel-section-item-actions-meta', 'grid-cols-[repeat(2,20px)] justify-items-end')}
							>
								<Dropdown
									key={vertical.id}
									open={isDropdownOpen.open && isDropdownOpen.id === String(vertical.id)}
									onOpenChange={v => setIsDropdownOpen({ id: String(vertical.id), open: v })}
									trigger={
										<PanelSectionItemAction asChild isTrigger>
											<button type="button" data-type="contextmenu">
												<Icon name="overflow-menu-horizontal" size="sm" />
											</button>
										</PanelSectionItemAction>
									}
									contentProps={{
										side: 'bottom',
										align: 'start',
									}}
								>
									{vertical.personas?.length ? (
										<DropdownItem asChild className="justify-start">
											<RecalculatePersonasAsyncForm
												ecosystemId={vertical.ecosystemId!}
												verticalId={vertical.id.toString()}
												className="flex items-center gap-2"
												size="none"
											>
												<Icon name="rotate" size="sm" />
												Recalculate Personas
											</RecalculatePersonasAsyncForm>
										</DropdownItem>
									) : null}
									<DropdownItem asChild className="justify-start">
										<Link to={vertical.duplicate}>
											<Icon name="copy" size="sm" />
											Duplicate Vertical
										</Link>
									</DropdownItem>
									<DropdownItem asChild className="justify-start" onSelect={e => e.preventDefault()}>
										<DeleteVerticalAsyncForm ecosystemId={vertical.ecosystemId!} verticalId={vertical.id.toString()}>
											<div className="flex items-center gap-2 text-status-danger-fg">
												<Icon name="trash" size="sm" />
												Delete Vertical
											</div>
										</DeleteVerticalAsyncForm>
									</DropdownItem>
									{!!vertical.personas.length && (
										<DropdownItem asChild className="justify-start" onSelect={e => e.preventDefault()}>
											<DeleteBatchPersonaForm
												ecosystemId={vertical.ecosystemId!.toString()}
												verticalName={vertical.name}
												personas={vertical.personas}
												setIsDropdownOpen={setIsDropdownOpen}
											>
												<div className="flex items-center gap-2 text-status-danger-fg">
													<Icon name="user-remove" size="sm" />
													Delete All Personas
												</div>
											</DeleteBatchPersonaForm>
										</DropdownItem>
									)}
								</Dropdown>

								<PanelSectionItemAction asChild isTrigger>
									<Link to={vertical.addPersona}>
										<Icon name="user-follow" size="sm" aria-hidden />
									</Link>
								</PanelSectionItemAction>
							</section>
						)}

						<section
							className={cn(
								readOnlySession ? 'grid' : 'panel-section-item-actions-primary',
								'grid-cols-[repeat(1,20px)] justify-items-end',
							)}
						>
							<PanelSectionItemAction className="text-label-sm font-normal text-neutral-3-fg">
								<span className="block h-5 w-5 pt-[0.15rem] text-center">{vertical.personas?.length ?? '0'}</span>
							</PanelSectionItemAction>
						</section>
					</PanelSectionItem>

					<AccordionPrimitive.Content
						className={cn(
							'overflow-hidden',
							'transition-opacity radix-state-closed:opacity-50 radix-state-open:opacity-100',
							'radix-state-closed:animate-[acc-slide-up_150ms_ease-in-out] radix-state-open:animate-[acc-slide-down_150ms_ease-in-out]',
						)}
					>
						<PersonaItem vertical={vertical} isActive={open.includes(vertical.id.toString())} />
					</AccordionPrimitive.Content>
				</AccordionPrimitive.Item>
			))}
		</AccordionPrimitive.Root>
	)
}
