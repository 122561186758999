import { type ActionFunctionArgs, redirect } from 'react-router'
import { userMutations } from '#src/api/icp/company/user/mutations'
import { EditFormSchema } from '#src/api/icp/company/user/schemas'
import { showToast } from '#src/context/ToastContext'
import { parseFormDataNew } from '#src/utils/forms'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ request, params }: ActionFunctionArgs) => {
	validateRouteParams(params, ['userId'])
	const { company, companyId } = await getCompany(params)
	const formData = await request.formData()

	try {
		const submission = parseFormDataNew(formData, EditFormSchema)
		await userMutations.update(company.id, params.userId, submission.value)

		showToast({
			message: `User successfully saved`,
			type: 'success',
		})

		return redirect(routes.settings.team.index({ companyId: companyId }))
	} catch (e) {
		return await serverFormErrorHandler(e)
	}
}
