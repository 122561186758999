import { type MotionValue, Reorder, animate, useDragControls, useMotionValue, useMotionValueEvent } from 'framer-motion'
import { useEffect, useState } from 'react'
import { Link } from 'react-router'
import { AccordionRoot } from '#src/components/ui/accordion'
import { Icon } from '#src/components/ui/icon'
import useCompany from '#src/hooks/useCompany'
import { routes } from '#src/utils/routes'
import { EcosystemAccordionItem, type EcosystemAccordionData } from './ecosystems-accordion'

export default function EcosystemsSort({
	ecosystems,
	order,
	onReorder,
	enabled,
}: {
	ecosystems: EcosystemAccordionData[]
	order: number[]
	onReorder: (order: number[]) => void
	enabled: boolean
}) {
	const [value, setValue] = useState<string[]>([])

	useEffect(() => {
		if (enabled && value.length) {
			setValue([])
		}
	}, [enabled, value.length])

	return (
		<AccordionRoot
			type="multiple"
			className="flex flex-col gap-4"
			asChild
			disabled={enabled}
			value={value}
			onValueChange={value => setValue(value)}
		>
			<Reorder.Group
				axis="y"
				values={order}
				onReorder={order => {
					onReorder(order)
				}}
			>
				{ecosystems
					.sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id))
					.map(ecosystem => (
						<ReorderEcosystemAccordionItem
							key={ecosystem.id}
							value={ecosystem.id}
							ecosystem={ecosystem}
							enabled={enabled}
						/>
					))}
			</Reorder.Group>
		</AccordionRoot>
	)
}

const inactiveShadow = '0 0 0 0 rgb(0 0 0 / 0.1), 0 0 0 0 rgb(0 0 0 / 0.1)'
const activeShadow = '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)'

function ReorderEcosystemAccordionItem({
	value,
	ecosystem,
	enabled,
}: {
	value: number
	ecosystem: EcosystemAccordionData
	enabled: boolean
}) {
	const { companyId } = useCompany()
	const dragControls = useDragControls()

	const y = useMotionValue(0)
	const { boxShadow, x } = useRaisedEffect(y)

	return (
		<Reorder.Item
			key={value}
			value={value}
			style={{ boxShadow, y, x }}
			className="rounded-lg"
			dragListener={false}
			dragControls={dragControls}
		>
			<EcosystemAccordionItem
				{...ecosystem}
				prefix={
					enabled ? (
						<Icon
							onPointerDown={event => dragControls.start(event)}
							name="drag-vertical"
							size="sm"
							className="cursor-pointer"
						/>
					) : null
				}
				suffix={
					<Link
						to={routes.calibrate.verticals.index({
							companyId,
							ecosystemId: ecosystem.id.toString(),
						})}
						className="group flex items-center gap-2 text-title-md transition-colors hover:text-brand-1-fg"
					>
						{ecosystem.name}
						<Icon name="arrow-right" className="opacity-0 transition-opacity group-hover:opacity-100" size="sm" />
					</Link>
				}
			/>
		</Reorder.Item>
	)
}

function useRaisedEffect(value: MotionValue<number>) {
	const boxShadow = useMotionValue(inactiveShadow)
	const x = useMotionValue(0)

	let isActive = false
	useMotionValueEvent(value, 'change', latest => {
		const wasActive = isActive
		if (latest !== 0) {
			isActive = true
			if (isActive !== wasActive) {
				void animate(boxShadow, activeShadow)
				void animate(x, 10)
			}
		} else {
			isActive = false
			if (isActive !== wasActive) {
				void animate(boxShadow, inactiveShadow)
				void animate(x, 0)
			}
		}
	})

	return { boxShadow, x }
}
