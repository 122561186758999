import { type LoaderFunctionArgs } from 'react-router'
import { type z } from 'zod'
import { companyPersonaQueriesKeys } from '#src/api/icp/company/persona/queries'
import { CopyValidatePersonaAPIResSchema } from '#src/api/icp/company/persona/schemas'
import { client } from '#src/main'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type LoaderResType = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	validateRouteParams(params, ['ecosystemId'])
	const { company } = await getCompany(params)

	const res = client.getQueryData<{
		data: z.infer<typeof CopyValidatePersonaAPIResSchema>
		personaIdsArray: string[]
	}>(companyPersonaQueriesKeys.copyValidate(company.id))

	const emptyReturn = {
		ecosystemsWithValidatedPersonas: [],
		selectedPersonasIds: [],
		ecosystemId: params.ecosystemId,
	}

	try {
		if (!res?.data) {
			return emptyReturn
		}

		const { data, personaIdsArray } = res

		const parsedData = CopyValidatePersonaAPIResSchema.parse(data)

		return {
			ecosystemsWithValidatedPersonas: parsedData,
			selectedPersonasIds: personaIdsArray,
			ecosystemId: params.ecosystemId,
		}
	} catch (error) {
		return emptyReturn
	}
}
