import { queryOptions } from '@tanstack/react-query'
import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { safeFetch } from '#src/utils/safeFetch'
import { playsTemplatesEndpoints } from './endpoints'
import { PlaysTemplatesItemAPISchema, PlaysTemplatesListItemAPISchema } from './schemas'

const keys = {
	all: ['icp', 'company', 'plays-templates'] as const,
	list: (companyId: z.infer<typeof CompanySchema.shape.id>) => [...keys.all, 'list', companyId] as const,
	item: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		templateId: z.infer<typeof PlaysTemplatesItemAPISchema.shape.id>,
	) => [...keys.all, 'item', companyId, templateId] as const,
}

const listQuery = (companyId: z.infer<typeof CompanySchema.shape.id>) =>
	queryOptions({
		queryKey: keys.list(companyId),
		queryFn: async () =>
			await safeFetch(PlaysTemplatesListItemAPISchema, playsTemplatesEndpoints.list(companyId), {
				method: 'GET',
			}),
	})

const itemQuery = (
	companyId: z.infer<typeof CompanySchema.shape.id>,
	templateId: z.infer<typeof PlaysTemplatesItemAPISchema.shape.id>,
) =>
	queryOptions({
		queryKey: keys.item(companyId, templateId),
		queryFn: async () =>
			await safeFetch(PlaysTemplatesItemAPISchema, playsTemplatesEndpoints.item(companyId, templateId), {
				method: 'GET',
			}),
	})

const queries = {
	list: listQuery,
	item: itemQuery,
}

export { queries as playsTemplatesQueries, keys as playsTemplatesQueriesKeys }
