import { useEffect } from 'react'
import { MODAL_NAME } from '#src/constants/modals'
import { useModals } from '#src/hooks/useModals'
import { AdminCompanyCreateModal } from '#src/modals/admin/CompanyCreate'
import { routes } from '#src/utils/routes'

export default function New() {
	const { openModal } = useModals()

	useEffect(() => {
		openModal({
			name: MODAL_NAME.AdminCompanyCreate,
			component: AdminCompanyCreateModal,
			settings: { routeToRedirectOnClose: routes.admin.company.list },
		})
	}, [openModal])

	return null
}
