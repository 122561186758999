import { useEffect } from 'react'
import { type ActionFunctionArgs, type LoaderFunctionArgs } from 'react-router'
import { MODAL_NAME } from '#src/constants/modals'
import { showToast } from '#src/context/ToastContext'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { client } from '#src/main'
import { PersonaSalesCoachEditModal } from '#src/modals/personas/PersonaSalesCoachEdit'
import { parseFormDataNew } from '#src/utils/forms'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'
import { closeModalServer } from '#src/utils/server/modals'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'
import { createSalesCoach, updateSalesCoach } from './mutations'
import { salesCoachKeys, salesCoachQuery } from './queries'
import { SalesCoachAdjustmentsFormSchema } from './schema'

export type SalesCoachLoaderResponse = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	validateRouteParams(params, ['ecosystemId', 'verticalId', 'personaId'])
	const { company } = await getCompany(params)

	return {
		salesCoach: await client.fetchQuery(salesCoachQuery(company.id, params.personaId)),
	}
}

export type SalesCoachActionResponse = Awaited<ReturnType<typeof action>>

export const action = async ({ params, request }: ActionFunctionArgs) => {
	validateRouteParams(params, ['ecosystemId', 'verticalId', 'personaId'])
	const { company } = await getCompany(params)

	const formData = await request.formData()

	try {
		const submission = parseFormDataNew(formData, SalesCoachAdjustmentsFormSchema)
		const salesCoach = await client.ensureQueryData(salesCoachQuery(company.id, params.personaId))

		const { month, ...adjustments } = submission.value

		if (salesCoach?.calibrated) {
			await updateSalesCoach(company.id, params.personaId, salesCoach.calibrated.id, adjustments)
		}

		if (salesCoach?.timing && salesCoach?.timing?.month === month) {
			await updateSalesCoach(company.id, params.personaId, salesCoach.timing.id, adjustments)
		} else if (month) {
			const sc = salesCoach?.rest.find(sc => sc.month == month)

			if (sc) {
				await updateSalesCoach(company.id, params.personaId, sc.id, adjustments)
			} else {
				await createSalesCoach(company.id, params.personaId, submission.value)
			}
		}

		void client.invalidateQueries({
			queryKey: salesCoachKeys.list(company.id, params.personaId),
		})

		showToast({
			message: 'Sales coach adjustments updated',
			type: 'success',
		})
		closeModalServer(MODAL_NAME.PersonaSalesCoachEdit)

		return { ok: true }
	} catch (e) {
		return await serverFormErrorHandler(e)
	}
}

export default function SalesCoach() {
	const { companyId } = useCompany()
	const { ecosystemId, verticalId, personaId } = useParsedRouteParams(['ecosystemId', 'verticalId', 'personaId'])

	const { openModal } = useModals()

	useEffect(() => {
		openModal({
			name: MODAL_NAME.PersonaSalesCoachEdit,
			component: PersonaSalesCoachEditModal,
			props: {
				ecosystemId: Number(ecosystemId),
				verticalId: Number(verticalId),
				personaId: Number(personaId),
			},
			settings: {
				routeToRedirectOnClose: `${routes.calibrate.persona.index({
					companyId,
					ecosystemId,
					verticalId,
					personaId,
				})}?tab=persona-sales-coach`,
			},
		})
	}, [companyId, ecosystemId, verticalId, personaId, openModal])

	return null
}
