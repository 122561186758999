import { useMemo } from 'react'
import { useMatches } from 'react-router'
import { z } from 'zod'

const ActionSchema = z.array(
	z.object({
		to: z.string().optional(),
		id: z.string().optional(),
		action: z.string().optional(),
		method: z.enum(['PUT', 'POST', 'PATCH', 'DELETE']).optional(),
		label: z.string().optional(),
		icon: z.string().optional(),
		className: z.string().optional(),
		onClick: z.function().args().returns(z.any()).optional(),
		dropdownActions: z
			.array(
				z.object({
					to: z.string().optional(),
					label: z.string(),
					icon: z.string().optional(),
				}),
			)
			.optional(),
	}),
)

const RouteActionsSchema = z.object({
	action: z.function().returns(ActionSchema),
})

const useHeaderActions = () => {
	const matches = useMatches()

	return useMemo(
		() =>
			matches
				.filter(match => RouteActionsSchema.safeParse(match.handle).success)
				.map(match => (match.handle as z.infer<typeof RouteActionsSchema>).action(match.data))
				.filter(action => ActionSchema.safeParse(action).success)
				.flat(1),
		[matches],
	)
}

export default useHeaderActions
