import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { PaginatedAPIResSchema } from '#src/schemas/global'
import { safeFetch, safeFetchMeta } from '#src/utils/safeFetch'
import { companyAccountExportEndpoints } from './endpoints'
import {
	AccountExportCreateSettingsAPISchema,
	AccountExportItemDownloadAPISchema,
	AccountExportListItemAPISchema,
	AccountExportStatisticsAPISchema,
} from './schemas'

const keys = {
	all: ['icp', 'account-export'] as const,
	list: (companyId: z.infer<typeof CompanySchema.shape.id>, page: number) =>
		[...keys.all, 'list', companyId, page] as const,
	statistics: (companyId: z.infer<typeof CompanySchema.shape.id>) => [...keys.all, 'statistics', companyId] as const,
	itemDownload: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		jobId: z.infer<typeof AccountExportListItemAPISchema.shape.id>,
	) => [...keys.all, 'item-download', companyId, jobId] as const,
	item: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		jobId: z.infer<typeof AccountExportListItemAPISchema.shape.id>,
	) => [...keys.all, 'item', companyId, jobId] as const,
	createSettings: (companyId: z.infer<typeof CompanySchema.shape.id>) =>
		[...keys.all, 'create-settings', companyId] as const,
}

export const statisticsQuery = (companyId: z.infer<typeof CompanySchema.shape.id>) => ({
	queryKey: keys.statistics(companyId),
	queryFn: async () => {
		return await safeFetch(AccountExportStatisticsAPISchema, companyAccountExportEndpoints.statistics(companyId), {
			method: 'GET',
		})
	},
	staleTime: 30000,
})

export const listQuery = (companyId: z.infer<typeof CompanySchema.shape.id>, page: number) => ({
	queryKey: keys.list(companyId, page),
	queryFn: async () => {
		return await safeFetchMeta(
			PaginatedAPIResSchema(AccountExportListItemAPISchema),
			companyAccountExportEndpoints.list(companyId, page),
			{ method: 'GET' },
		)
	},
	staleTime: 30000,
})

export const itemDownloadQuery = (
	companyId: z.infer<typeof CompanySchema.shape.id>,
	jobId: z.infer<typeof AccountExportListItemAPISchema.shape.id>,
) => ({
	queryKey: keys.itemDownload(companyId, jobId),
	queryFn: async () => {
		return await safeFetch(
			AccountExportItemDownloadAPISchema,
			companyAccountExportEndpoints.itemDownload(companyId, jobId),
			{
				method: 'GET',
			},
		)
	},
	staleTime: 30000,
})

export const createSettingsQuery = (companyId: z.infer<typeof CompanySchema.shape.id>) => ({
	queryKey: keys.createSettings(companyId),
	queryFn: async () => {
		return await safeFetch(
			AccountExportCreateSettingsAPISchema,
			companyAccountExportEndpoints.createSettings(companyId),
			{
				method: 'GET',
			},
		)
	},
	staleTime: 30000,
})

export const itemQuery = (
	companyId: z.infer<typeof CompanySchema.shape.id>,
	jobId: z.infer<typeof AccountExportListItemAPISchema.shape.id>,
) => ({
	queryKey: keys.item(companyId, jobId),
	queryFn: async () => {
		return await safeFetch(AccountExportListItemAPISchema, companyAccountExportEndpoints.item(companyId, jobId), {
			method: 'GET',
		})
	},
	staleTime: 30000,
})

const queries = {
	statistics: statisticsQuery,
	list: listQuery,
	itemDownload: itemDownloadQuery,
	createSettings: createSettingsQuery,
	item: itemQuery,
}

export { queries as companyAccountExportQueries, keys as companyAccountExportQueriesKeys }
