import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { baseApi } from '#src/utils/endpoints'

const base = `${baseApi}/company`

const endpoints = {
	list: `${base}/list`,
	update: (companyId: z.infer<typeof CompanySchema.shape.id>) => `${base}/${companyId}`,
	requestCredits: (companyId: z.infer<typeof CompanySchema.shape.id>) => `${base}/${companyId}/billing/top-up-credits`,
}

export { endpoints as companyEndpoints }
