import { useNavigate, useLoaderData, createSearchParams, Link } from 'react-router'
import { Chip } from '#src/components/chip'
import FiltersSection from '#src/components/FiltersSection'
import { PersonaAvatar } from '#src/components/persona'
import { TrainingAgenda } from '#src/components/training-agenda'
import { Button } from '#src/components/ui/button'
import { PageLayout } from '#src/components/ui/PageLayout'
import { PaginatedTable } from '#src/components/ui/PaginatedTable'
import useCompany from '#src/hooks/useCompany'
import LayoutContentHack from '#src/layouts/LayoutContentHack'
import { type LoaderRes } from '#src/routes/enable/roleplay-scenarios/loaders/list'
import { formatDateToReadableString } from '#src/utils/date'
import { routes } from '#src/utils/routes'

const View = () => {
	const { companyId } = useCompany()
	const {
		data: { scenarios, filters },
	} = useLoaderData<LoaderRes>()
	const navigate = useNavigate()

	return (
		<PageLayout
			title="Roleplay scenarios"
			actions={[
				{
					variant: 'outline',
					to: routes.enable.roleplay.scenario.report({ companyId }),
					icon: 'chart-column',
					title: 'Report',
				},
				{
					variant: 'default',
					to: routes.enable.roleplay.scenario.create({ companyId }),
					icon: 'add',
					title: 'New scenario',
				},
			]}
		>
			<LayoutContentHack>
				<PaginatedTable
					filtersComponent={
						<FiltersSection
							total={scenarios.meta.total}
							selected={filters}
							values={scenarios.meta.filters!}
							filters={[
								{
									name: 'agenda',
									label: 'Agenda',
								},
								{
									name: 'personaType',
									label: 'Persona type',
								},
								{
									name: 'tags',
									label: 'Tags',
									multiple: false,
								},
								{
									name: 'createdBy',
									label: 'Created by',
								},
								{
									name: ['dateFrom', 'dateTo'],
									label: 'Date added',
									type: 'dateRange',
								},
							]}
						/>
					}
					headerVariant="gray"
					stickyHeader
					data={scenarios.data}
					currentPage={scenarios.meta.current_page}
					totalPages={scenarios.meta.last_page}
					onPageChange={page =>
						navigate({
							search: createSearchParams({
								page: (page + 1).toString(),
							}).toString(),
						})
					}
					onItemClick={scenario =>
						navigate(
							routes.enable.roleplay.scenario.item({
								companyId: companyId,
								scenarioId: scenario.id,
							}),
						)
					}
					columns={[
						{
							name: 'name',
							heading: 'Name',
							render: scenario => scenario.name,
						},
						{
							name: 'agenda',
							heading: 'Agenda',
							render: scenario => <TrainingAgenda title={scenario.title ?? ''} icon="user-service-desk" />,
						},
						{
							name: 'persona_used',
							heading: 'Persona used',
							render: scenario =>
								scenario.personaMapping ? (
									<div className="flex max-w-[200px] flex-row items-center gap-2">
										<PersonaAvatar type={scenario.personaMapping.personaType} size="sm" className="row-span-2" />
										<div className="flex-1 overflow-hidden">
											<p className="w-full truncate text-label-sm text-neutral-3-fg">
												{scenario.personaMapping.expertise}
											</p>
											<p className="w-full truncate text-body-sm font-semibold text-neutral-1-fg">
												{scenario.personaMapping.personaType}
											</p>
										</div>
									</div>
								) : (
									<p className="text-body-sm text-neutral-3-fg">Persona has been deleted</p>
								),
						},
						{
							name: 'participants',
							heading: 'Participants',
							render: scenario => <Chip variant="blue">{scenario.participantsCount}</Chip>,
						},
						{
							name: 'frequency',
							heading: 'Frequency',
							render: scenario => (
								<>
									{scenario.cycleFrequencyValue !== null && scenario.cycleFrequencyUnit !== null
										? `${scenario.cycleFrequencyValue}/${scenario.cycleFrequencyUnit}`
										: '-'}
								</>
							),
						},
						{
							name: 'created_by',
							heading: 'Created by',
							render: scenario => scenario.createdBy,
						},
						{
							name: 'tags',
							heading: 'Tags',
							render: item => (
								<div className="flex flex-row flex-wrap gap-1">
									{!!item.tags.length &&
										item.tags.map(item => (
											<Chip variant="gray" key={`tag-${item}`}>
												{item}
											</Chip>
										))}
									<Button
										onClick={e => e.stopPropagation()}
										asChild
										variant="secondary"
										size="none"
										className="px-1.5 py-1 text-body-sm font-medium text-neutral-3-fg"
									>
										<Link to={routes.enable.roleplay.scenario.editTags({ companyId, scenarioId: item.id })}>
											Add Tag
										</Link>
									</Button>
								</div>
							),
						},
						{
							name: 'created_at',
							heading: 'Date created',
							render: scenario => formatDateToReadableString(scenario.createdAt),
						},
					]}
				/>
			</LayoutContentHack>
		</PageLayout>
	)
}

export default View
