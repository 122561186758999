import * as ScrollArea from '@radix-ui/react-scroll-area'
import { useMemo, useState } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { type z } from 'zod'
import { type PlaysItemAPISchema } from '#src/api/icp/company/plays/schemas'
import { Chip } from '#src/components/chip'
import { FormField } from '#src/components/forms/v2/FormField'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { Input } from '#src/components/ui/input'
import useDebounce from '#src/hooks/useDebounce'
import { cn } from '#src/utils/misc'

type Props = {
	options: z.infer<typeof PlaysItemAPISchema>[]
}

const LRAPlaysCheckbox = (props: Props) => {
	const { options } = props
	const [search, setSearch] = useState<string>('')
	const { control } = useFormContext()
	const searchQuery = useDebounce(search, 100)

	const { field } = useController({
		name: 'plays',
		control,
	})

	const filteredOptions = useMemo(() => {
		if (!searchQuery) {
			return options
		}
		return options.filter(play => {
			if (play.name.toLowerCase().includes(searchQuery)) {
				return true
			}
			return !!play.tags.find(tag => tag.toLowerCase().includes(searchQuery))
		})
	}, [options, searchQuery])

	const onValueChange = (value: string[]) => {
		field.onChange(value)
	}

	const onSelectAll = () => {
		onValueChange(options.map(item => String(item.id)))
	}

	const onDeselectAll = () => {
		onValueChange([])
	}

	return (
		<div className="flex flex-col gap-2">
			<Input
				value={search}
				onChange={e => setSearch(e.target.value)}
				clearable
				placeholder="Enter name or tags to filter plays"
				iconRight="search"
			/>
			{!search && (
				<div className="flex flex-row gap-2">
					<Button type="button" onClick={onSelectAll} size="sm" variant="ghost">
						Select all
					</Button>
					<Button type="button" onClick={onDeselectAll} size="sm" variant="ghost">
						Deselect all
					</Button>
				</div>
			)}
			<ScrollArea.Root>
				<ScrollArea.Viewport
					className={cn(
						'max-h-[400px] min-h-[238px] overflow-hidden pe-3',
						options.length && !filteredOptions.length && 'flex items-center justify-center',
					)}
				>
					<FormField
						name="plays"
						fieldType="checkbox"
						options={filteredOptions.map(play => ({
							value: String(play.id),
							label: (
								<div className="grid w-full grid-cols-[1fr,max-content]">
									<p>{play.name}</p>
									<div className="flex flex-row flex-wrap gap-1">
										{play.tags.map(item => (
											<Chip variant="gray" key={`tag-${item}`}>
												{item}
											</Chip>
										))}
									</div>
								</div>
							),
						}))}
						disableLabel
					/>
					{options.length && !filteredOptions.length && (
						<section className="m-auto h-full gap-1 text-center">
							<Icon name="search-dialogue" className="mb-2 h-14 w-14 text-brand-1-fg" />
							<h3 className="text-center text-body-lg font-semibold text-neutral-2-fg">No search results</h3>
							<div className="mx-auto max-w-[300px]">
								<p className="truncate text-body-md">{`No results for “${search}”`}</p>
							</div>
						</section>
					)}
				</ScrollArea.Viewport>
				<ScrollArea.Scrollbar orientation="vertical" className="w-1.5">
					<ScrollArea.Thumb className="rounded-full bg-neutral-2-bd" />
				</ScrollArea.Scrollbar>
				<ScrollArea.Corner />
			</ScrollArea.Root>
		</div>
	)
}

export default LRAPlaysCheckbox
