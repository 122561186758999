import { createElement, type ReactElement, useEffect, useMemo, useRef } from 'react'
import { useFetcher, type useLoaderData, useSearchParams } from 'react-router'
import PageLoader from '#src/components/loader'

type Props<T extends object, K extends object> = {
	route: string
	initialProps?: K
	component: (props: T & K) => ReactElement
}

type SerializeFrom<T> = ReturnType<typeof useLoaderData<T>>

const LoaderRequiredComponent = <T extends object, K extends object = never>(props: Props<T, K>) => {
	const { route, initialProps, component } = props
	const initialPropsFinal = initialProps ?? ({} as K)
	const fetcher = useFetcher<SerializeFrom<T>>()
	const [searchParams] = useSearchParams()

	const routeWithSearchParams = useMemo(
		() => `${route}${searchParams.size ? `?${searchParams.toString()}` : ''}`,
		[route, searchParams],
	)
	const loadedRoute = useRef<string | null>(null)

	useEffect(() => {
		if ((fetcher.state === 'idle' && !fetcher.data) || loadedRoute.current !== routeWithSearchParams) {
			loadedRoute.current = routeWithSearchParams
			void fetcher.load(routeWithSearchParams)
		}
	}, [fetcher, routeWithSearchParams])

	if ((fetcher.state === 'idle' || fetcher.state === 'loading') && !fetcher.data) {
		return <PageLoader show />
	}

	if (fetcher.data) {
		return createElement<T & K>(component, { ...initialPropsFinal, ...fetcher.data } as T & K)
	}

	return null
}

export default LoaderRequiredComponent
