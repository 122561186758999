import { captureException } from '@sentry/react'
import { type ActionFunctionArgs, redirect } from 'react-router'
import { type z } from 'zod'
import { verticalsQueriesKeys, verticalsQueries } from '#src/api/icp/company/verticals/queries'
import { showToast } from '#src/context/ToastContext'
import { client } from '#src/main'
import { type CRITERION_TYPE_ENUM } from '#src/routes/calibrate/ecosystem-management/criteria/constants'
import {
	assignCriterion,
	createCriterion,
	updateAssignedAnswers,
} from '#src/routes/calibrate/ecosystem-management/criteria/mutations'
import { criteriaKeys, criterionQuery } from '#src/routes/calibrate/ecosystem-management/criteria/queries'
import {
	generateSaveCriterionContext,
	transformAnswersSubmissionToPayload,
} from '#src/routes/calibrate/ecosystem-management/criteria/utils'
import { ecosystemKeys } from '#src/routes/calibrate/ecosystem-management/ecosystem/queries'
import { parseFormData } from '#src/utils/forms'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'
import { transformAndCleanToggleableFieldsSchema } from '#src/utils/validation'

import {
	CriterionAllAssignedVerticalsAnswersPayload,
	CriterionNewPayloadSchema,
	getCriterionNewFormSchema,
} from '../schema'

export type ActionResType = Awaited<ReturnType<ReturnType<typeof action>>>

export const action =
	(criterionType: z.infer<typeof CRITERION_TYPE_ENUM>) =>
	async ({ params, request }: ActionFunctionArgs) => {
		validateRouteParams(params, ['ecosystemId'])
		const { company, companyId } = await getCompany(params)
		const formData = await request.formData()

		const criterion = await client.fetchQuery(criterionQuery(company.id, params.criterionId))
		const formSchema = getCriterionNewFormSchema(criterion.type)
		const formSubmission = parseFormData(formData, formSchema)

		const verticals = await client.fetchQuery(verticalsQueries.list(company.id, Number(params.ecosystemId)))

		const { schema: verticalsAnswersSchema } = generateSaveCriterionContext(
			verticals,
			formSubmission.value.type,
			criterionType,
			criterion,
		)

		const verticalsAnswersSubmission = parseFormData(
			formData,
			verticalsAnswersSchema.transform(transformAndCleanToggleableFieldsSchema),
		)

		const createCriterionDuplicate = async () => {
			const formPayload = {
				...formSubmission.value,
				isEnrichment: criterionType === 'enrichment',
				isSandbox: criterion.isSandbox,
			}

			try {
				const parsedForm = CriterionNewPayloadSchema.parse(formPayload)

				return await createCriterion(company.id, parsedForm)
			} catch (err) {
				captureException(err)
				console.error(err)
			}
		}

		const updateAnswers = async (criterionId: string) => {
			const verticalsAnswersPayload = transformAnswersSubmissionToPayload(
				verticalsAnswersSubmission.value,
				criterionType,
				params.ecosystemId,
				criterionId,
			)

			try {
				const parsedForm = CriterionAllAssignedVerticalsAnswersPayload.parse(verticalsAnswersPayload)
				await updateAssignedAnswers(company.id, criterionId, parsedForm)
			} catch (err) {
				captureException(err)
				console.error(err)
				showToast({
					message: 'Failed to save verticals answers',
					type: 'error',
				})
			}
		}

		const assignCriterionToEcosystem = async (criterionId: string) => {
			try {
				await assignCriterion(company.id, {
					criterionId,
					ecosystemId: params.ecosystemId,
				})
			} catch (err) {
				captureException(err)
				console.error(err)
				showToast({
					message: 'Failed to save verticals answers',
					type: 'error',
				})
			}
		}

		try {
			const criterionDuplicate = await createCriterionDuplicate()

			if (criterionDuplicate) {
				await assignCriterionToEcosystem(String(criterionDuplicate.id))
				await updateAnswers(String(criterionDuplicate.id))
			}

			await Promise.all([
				client.invalidateQueries({ queryKey: criteriaKeys.all }),
				client.invalidateQueries({ queryKey: ecosystemKeys.all }),
				client.invalidateQueries({ queryKey: verticalsQueriesKeys.all }),
			])

			showToast({
				message: 'Criterion successfully duplicated',
				type: 'success',
				bottom: '60px',
			})
		} catch (err) {
			return await serverFormErrorHandler(err)
		}

		return redirect(
			routes.calibrate.verticals.index({
				companyId,
				ecosystemId: params.ecosystemId,
			}),
		)
	}
