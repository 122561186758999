import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useFetcher } from 'react-router'
import { type z } from 'zod'
import { PLAY_STATUS } from '#src/api/icp/company/plays/constants'
import { PlaysFormSchema } from '#src/api/icp/company/plays/schemas'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import LoaderRequiredComponent from '#src/components/LoaderRequiredComponent'
import { Dialog } from '#src/components/ui/dialog'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { type ActionRes } from '#src/routes/enable/plays/root/actions/create'
import { type LoaderRes } from '#src/routes/enable/plays/root/loaders/create'
import PlayFormFields from '#src/routes/enable/plays/root/views/components/PlayFormFields'
import { routes } from '#src/utils/routes'

const FORM_ID = 'create-play-form'

export const PlayCreateModal = () => {
	const { companyId } = useCompany()

	return <LoaderRequiredComponent<LoaderRes> route={routes.enable.plays.create({ companyId })} component={ModalInner} />
}

export const ModalInner = (props: LoaderRes) => {
	const {
		data: { template, ecosystems },
	} = props
	const { closeModal } = useModals()
	const { companyId } = useCompany()
	const actionFetcher = useFetcher<ActionRes>()

	const form = useForm<z.infer<typeof PlaysFormSchema>>({
		resolver: zodResolver(PlaysFormSchema),
		defaultValues: {
			name: template?.name ?? '',
			instructions: template?.instructions ?? '',
			inCopilot: template?.inCopilot ?? true,
			inCrm: template?.inCrm ?? false,
			personaTypes: template?.personaTypes ?? [],
			expertise: [],
			tags: template?.tags ?? [],
			status: template?.status ?? PLAY_STATUS.Draft,
			conditions: [],
			...(!!template && { templateId: template.id }),
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-expect-error
			verticals: ecosystems.map(ecosystem => ecosystem.verticals.map(vertical => String(vertical.id))).flat(),
		},
		mode: 'onSubmit',
	})

	return (
		<Dialog
			defaultOpen={true}
			onOpenChange={isOpen => !isOpen && closeModal(MODAL_NAME.PlayCreate)}
			dialogHeading="Create new Play"
			actions={[
				{ type: 'cancel', label: 'Close' },
				{ type: 'submit', formId: FORM_ID, label: 'Create' },
			]}
			size="xl"
			isSubmitting={actionFetcher.state === 'submitting'}
		>
			<FormWrapper
				formId={FORM_ID}
				formProps={form}
				action={routes.enable.plays.create({ companyId })}
				className="flex flex-col gap-6"
				fetcher={actionFetcher}
				actionResponse={actionFetcher.data}
			>
				<PlayFormFields />
			</FormWrapper>
		</Dialog>
	)
}
