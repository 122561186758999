import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { organizeApi } from '#src/utils/endpoints'
import { buildAPISearchParams } from '#src/utils/requests'
import { type DATA_ENRICHMENT_JOB_ENRICHERS, type DataEnrichmentItemAPISchema } from './schemas'

const base = () => `${organizeApi}/data-enrichment/workflows`

const endpoints = {
	list: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		page: number,
		sort: string[],
		enricher: DATA_ENRICHMENT_JOB_ENRICHERS,
	) =>
		`${base()}/?${buildAPISearchParams({
			companyId,
			page,
			sort,
			enricher,
		}).toString()}`,
	item: (jobId: z.infer<typeof DataEnrichmentItemAPISchema.shape.id>) => `${base()}/${jobId}`,
	itemStop: (jobId: z.infer<typeof DataEnrichmentItemAPISchema.shape.id>) => `${base()}/${jobId}/cancel`,
	itemDownload: (jobId: z.infer<typeof DataEnrichmentItemAPISchema.shape.id>, query: Record<string, string>) =>
		`${base()}/${jobId}/download?${new URLSearchParams(query).toString()}`,
	create: () => `${base()}/`,
	statistics: (companyId: z.infer<typeof CompanySchema.shape.id>, enricher: DATA_ENRICHMENT_JOB_ENRICHERS) =>
		`${base()}/statistic/company/${companyId}?${buildAPISearchParams({ enricher }).toString()}`,
}

export { endpoints as orgDataEnrichmentWorkflowsEndpoints }
