import { useMemo } from 'react'
import { type z } from 'zod'
import { type VerticalListAPISchema } from '#src/api/icp/company/verticals/schemas'
import AiGenerated from '#src/components/ai-generated'
import Markdown from '#src/components/markdown'
import ProductTip from '#src/components/product-tip'
import { PreviewBizDescriptionModal } from '#src/routes/calibrate/ecosystem-management/verticals/views/components'
import { cn } from '#src/utils/misc'

export const useBusinessModelGroup = (verticals: z.infer<typeof VerticalListAPISchema>) => {
	const header = useMemo(
		() => ({
			collapsible: {
				options: { sticky: true, collapsible: true },
				columns: [
					{
						children: (
							<p className="flex items-center gap-1 text-body-md font-semibold text-neutral-2-fg">
								Business model description <AiGenerated />
								<ProductTip content="The business model description is created using the vertical’s criteria values. This data helps the AI generate detailed descriptions for each vertical. These descriptions are crucial for identifying pain points and mapping personas accurately." />
							</p>
						),
					},
					...verticals.map(vertical => ({
						children: (
							<p
								className={cn(
									'truncate text-body-sm font-normal',
									['Pending', 'In Progress'].includes(vertical.prompt.status ?? '') ? 'animate-pulse' : '',
									vertical.prompt.status === 'Crashed' ? 'text-status-danger-fg' : 'text-neutral-2-fg',
								)}
							>
								{['Pending', 'In Progress'].includes(vertical.prompt.status ?? '') ? (
									vertical.bizDescription ? (
										'Regenerating...'
									) : (
										'Generating...'
									)
								) : vertical.prompt.status === 'Crashed' ? (
									`Failed to ${vertical.bizDescription ? 'regenerate' : 'generate'}`
								) : (
									<Markdown className="markdown-inline truncate text-body-sm font-normal text-neutral-2-fg">
										{vertical.bizDescription}
									</Markdown>
								)}
							</p>
						),
					})),
				],
			},
		}),
		[verticals],
	)

	const body = useMemo(
		() => ({
			rows: [
				{
					columns: [
						{
							className: 'align-top',
							static: true,
							children: <p className="text-body-md font-medium">Description</p>,
						},
						...verticals.map(vertical => ({
							className: 'align-top',
							static: true,
							children: (
								<PreviewBizDescriptionModal title={vertical.name} description={vertical.bizDescription}>
									<div className="cursor-pointer">
										<Markdown className="line-clamp-[10] text-neutral-2-fg">{vertical.bizDescription}</Markdown>
									</div>
								</PreviewBizDescriptionModal>
							),
						})),
					],
				},
			],
		}),
		[verticals],
	)

	return { ...header, body }
}
