import { type ReactNode, useEffect, useRef, type WheelEvent } from 'react'
import { cn } from '#src/utils/misc'

interface ChatLayoutProps {
	status?: ReactNode
	content: ReactNode
	footer: ReactNode
	suggestions?: ReactNode
	containerClassName?: string
}

export default function ChatLayout({ containerClassName, status, content, footer, suggestions }: ChatLayoutProps) {
	const chatScrollLockedRef = useRef<boolean>(true)
	const chatRef = useRef<HTMLDivElement>(null) // NOTE: Scrollable container
	const chatInnerRef = useRef<HTMLDivElement>(null) // NOTE: Inner container to observe for height changes
	const chatResizeObserver = useRef<ResizeObserver>(
		new ResizeObserver(entries => {
			if (!entries.length || entries.length > 1) throw Error("There's more than one entry")

			if (!chatRef.current) return

			if (!chatScrollLockedRef.current) return

			chatRef.current.scrollTop = chatRef.current.scrollHeight
		}),
	)

	/**
	 * Observe inner container for height changes
	 * regardless of component lifecycle or prop/state changes
	 */
	useEffect(() => {
		if (chatInnerRef.current) {
			chatResizeObserver.current.observe(chatInnerRef.current)
		}
	}, [])

	function onWheel(e: WheelEvent<HTMLElement>) {
		if (!chatRef.current) {
			return
		}

		if (e.deltaY < 0) {
			chatScrollLockedRef.current = false
		} else if (e.deltaY > 1) {
			chatScrollLockedRef.current =
				chatRef.current.scrollHeight - (chatRef.current.scrollTop + chatRef.current.clientHeight) < 50
		}
	}

	return (
		<section className="flex max-h-[calc(100vh-var(--builder-header-height)-var(--credits-warning-height))] w-full flex-1 flex-col justify-self-center overflow-x-hidden">
			{status && <div className="sticky w-full">{status}</div>}
			<section
				ref={chatRef}
				onWheel={onWheel}
				className="flex w-full flex-1 flex-col items-center justify-between overflow-x-hidden px-20"
			>
				<div
					ref={chatInnerRef}
					className={cn('flex w-full max-w-[920px] flex-1 flex-col gap-4 py-8', containerClassName)}
				>
					{content}
				</div>
				{!!suggestions && <div className="w-full max-w-[920px] pb-8">{suggestions}</div>}
				{!!footer && <div className="sticky bottom-0 w-full max-w-[920px]">{footer}</div>}
			</section>
		</section>
	)
}
