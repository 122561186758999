import { type RouteObject } from 'react-router'
import ChatsPanel from '#src/routes/enable/chat/_layout/ChatsPanel'
import ChatsPanelHeader from '#src/routes/enable/chat/_layout/ChatsPanelHeader'
import DeleteChat, { action as deleteChatAction } from '#src/routes/enable/chat/delete'
import Chat, { type ChatLoaderResponse, loader as chatLoader } from '#src/routes/enable/chat/index'
import ChatContact, { loader as chatContactLoader } from '#src/routes/enable/chat/info/contact'
import ChatPersona, { loader as chatPersonaLoader } from '#src/routes/enable/chat/info/persona'
import { action as chatSettingsAction } from '#src/routes/enable/chat/info/settings/action'
import { loader as chatSettingsLoader } from '#src/routes/enable/chat/info/settings/loader'
import ChatSettings from '#src/routes/enable/chat/info/settings/view'
import NewChat from '#src/routes/enable/chat/new'
import { action as restartChatAction } from '#src/routes/enable/chat/restart'
import { routes } from '#src/utils/routes'
import { action as newAction } from './actions/new'
import { loader as newLoader } from './loaders/new'

export default {
	path: 'copilot',
	handle: {
		crumb: () => [
			{
				label: 'Copilot chats',
				icon: 'chat',
			},
		],
	},
	children: [
		{
			path: '',
			loader: chatLoader,
			element: <Chat />,
			handle: {
				layout: {
					enableHeader: true,
				},
			},
			children: [
				{
					path: 'new',
					element: <NewChat />,
					loader: newLoader,
					action: newAction,
					handle: {
						layout: {
							enableHeader: true,
						},
					},
				},
			],
		},
		{
			path: ':conversationId',
			id: 'copilot-module',
			element: <Chat />,
			loader: chatLoader,
			handle: {
				layout: {
					enableHeader: true,
					noPadding: true,
					sidePanelHeader: <ChatsPanelHeader />,
					sidePanelContent: <ChatsPanel />,
				},
				crumb: (data: ChatLoaderResponse) => [
					{
						label: data.conversationData?.conversation.name,
						icon: 'user',
					},
				],
				action: (data: ChatLoaderResponse) => [
					{
						to: routes.enable.copilot.persona({
							companyId: data.handle.companyId,
							conversationId: data.handle.conversationId!,
						}),
						label: 'Persona Info',
					},
					{
						to: routes.enable.copilot.contact({
							companyId: data.handle.companyId,
							conversationId: data.handle.conversationId!,
						}),
						label: 'Contact Info',
					},
					...(data.handle.canRestartChat
						? [
								{
									id: 'chat-restart',
									action: routes.enable.copilot.restart({
										companyId: data.handle.companyId,
										conversationId: data.handle.conversationId,
									}),
									method: 'PUT',
									tooltip: 'Restart Chat',
									icon: 'reset',
									className: 'text-neutral-2-fg',
								},
							]
						: []),
					...(data.handle.canChangeSettings
						? [
								{
									to: routes.enable.copilot.settings({
										companyId: data.handle.companyId,
										conversationId: data.handle.conversationId,
									}),
									icon: 'settings-adjust',
									className: 'text-neutral-2-fg',
								},
							]
						: []),
					...(data.handle.canDeleteChat
						? [
								{
									to: routes.enable.copilot.delete({
										companyId: data.handle.companyId,
										conversationId: data.handle.conversationId,
									}),
									icon: 'trash',
									className: 'text-status-danger-fg',
								},
							]
						: []),
				],
			},
			children: [
				{
					path: 'new',
					element: <NewChat />,
					loader: newLoader,
					action: newAction,
				},
				{
					path: 'restart',
					action: restartChatAction,
				},
				{
					path: 'delete',
					element: <DeleteChat />,
					action: deleteChatAction,
				},
				{
					path: 'persona',
					element: <ChatPersona />,
					loader: chatPersonaLoader,
				},
				{
					path: 'contact',
					element: <ChatContact />,
					loader: chatContactLoader,
				},
				{
					path: 'settings',
					element: <ChatSettings />,
					loader: chatSettingsLoader,
					action: chatSettingsAction,
				},
			],
		},
	],
} satisfies RouteObject
