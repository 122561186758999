import { type ButtonHTMLAttributes, forwardRef, type ReactNode } from 'react'
import { type z } from 'zod'
import DeleteConfirm from '#src/components/delete-confirm'
import useCompany from '#src/hooks/useCompany'
import { type EcosystemAPISchema } from '#src/routes/calibrate/ecosystem-management/ecosystem/schema'
import {
	getEcosystemCTALabel,
	getEcosystemDeleteDescription,
} from '#src/routes/calibrate/ecosystem-management/ecosystem/utils'
import { cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

export interface DeleteEcosystemAsyncFormProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	ecosystem: z.infer<typeof EcosystemAPISchema>
	children: ReactNode
	onSubmit?: () => void
}

export const DeleteEcosystemAsyncForm = forwardRef<HTMLButtonElement, DeleteEcosystemAsyncFormProps>(
	({ ecosystem, children, className }, ref) => {
		const { companyId } = useCompany()
		const action = routes.calibrate.ecosystem.delete({
			companyId,
			ecosystemId: String(ecosystem.id),
		})

		return (
			<DeleteConfirm
				trigger={
					<button type="button" className={cn('w-full', className)} ref={ref}>
						{children}
					</button>
				}
				action={action}
				title={`${getEcosystemCTALabel('Delete', ecosystem.type)}?`}
				description={getEcosystemDeleteDescription(ecosystem.type)}
			/>
		)
	},
)
DeleteEcosystemAsyncForm.displayName = 'DeleteEcosystemAsyncForm'
