import { useLoaderData, type LoaderFunctionArgs } from 'react-router'
import ContactAvatar, { avatarVariantByPersonaType } from '#src/components/chat/avatar'
import Markdown from '#src/components/markdown'
import { Icon } from '#src/components/ui/icon'
import { Sidebar, SidebarHeader } from '#src/components/ui/sidebar'
import { TabSwitcher } from '#src/components/ui/tabs'
import useCompany from '#src/hooks/useCompany'
import { client } from '#src/main'
import { conversationQuery } from '#src/routes/enable/chat/queries'
import { formatPersonaTypeAbbr, validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export type PersonaInfoLoaderResponse = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	validateRouteParams(params, ['conversationId'])

	return {
		conversationId: params.conversationId,
		conversationData: await client.fetchQuery(conversationQuery(company.id, params.conversationId)),
	}
}

export default function Persona() {
	const { conversationId, conversationData } = useLoaderData<PersonaInfoLoaderResponse>()
	const { companyId } = useCompany()

	return (
		<Sidebar
			header={
				<>
					<SidebarHeader
						heading={
							<h1 className="flex items-center gap-1 text-body-md font-semibold text-neutral-1-fg">
								<Icon name="user" size="font" />
								Persona Info
							</h1>
						}
						closeTo={routes.enable.copilot.index({
							companyId,
							conversationId,
						})}
					/>
					<section className="sticky top-[44px] z-[50] bg-neutral-1-bg">
						<div className="relative grid w-full grid-cols-[2.5rem,1fr] gap-1.5 bg-transparent px-4 py-4">
							{conversationData.participant.persona.type ? (
								<ContactAvatar
									className="inline-flex shrink-0 items-center justify-center rounded-full"
									variant={avatarVariantByPersonaType(conversationData.participant.persona.type?.toLowerCase())}
									size="md"
									initial={formatPersonaTypeAbbr(conversationData.participant.persona.type ?? '')}
								/>
							) : null}

							<div className="flex flex-col justify-center gap-0.5 overflow-hidden">
								<p className="flex items-center gap-0.5 truncate text-label-sm text-neutral-2-fg">
									{conversationData.participant.persona.expertise ?? ''}
								</p>
								<p className="flex items-center gap-0.5 truncate text-label-sm font-normal text-neutral-3-fg">
									<Icon name="ibm-cloud-pak" size="font" />
									{conversationData.participant.persona.ecosystem} /
									<Icon name="category" size="font" />
									{conversationData.participant.persona.vertical}
								</p>
							</div>
						</div>
					</section>
				</>
			}
			className="flex flex-col p-0"
			main={
				<>
					<TabSwitcher
						defaultValue="pain-points"
						contentClassName="p-4 overflow-y-auto"
						listClassName="sticky"
						tabs={[
							{
								label: 'Pain points',
								value: 'pain-points',
								content: <Markdown>{conversationData.participant.mapping.pain}</Markdown>,
							},
							{
								label: 'Persona Mapping',
								value: 'mapping',
								content: (
									<section className="flex flex-col gap-2">
										<h4 className="text-body-md font-semibold">Responsibilities</h4>
										<Markdown>{conversationData.participant.mapping.responsibilities}</Markdown>
										<h4 className="pt-2 text-body-md font-semibold">KPIs</h4>
										<Markdown>{conversationData.participant.mapping.kpis}</Markdown>
										<h4 className="pt-2 text-body-md font-semibold">Frustrations</h4>
										<Markdown>{conversationData.participant.mapping.frustrations}</Markdown>
										<h4 className="pt-2 text-body-md font-semibold">Value Propositions</h4>
										<Markdown>{conversationData.participant.mapping.valuePropositions}</Markdown>
										<h4 className="pt-2 text-body-md font-semibold">Objections Concerns</h4>
										<Markdown>{conversationData.participant.mapping.objectionsConcerns}</Markdown>
										<h4 className="pt-2 text-body-md font-semibold">Objection Addressing</h4>
										<Markdown>{conversationData.participant.mapping.objectionAddressing}</Markdown>
										<h4 className="pt-2 text-body-md font-semibold">Buyer Journey Info</h4>
										<Markdown>{conversationData.participant.mapping.buyerJourneyInfo}</Markdown>
										<h4 className="pt-2 text-body-md font-semibold">Buyer Journey Use Cases</h4>
										<Markdown>{conversationData.participant.mapping.buyerJourneyUseCases}</Markdown>
										<h4 className="pt-2 text-body-md font-semibold">Gain Information From</h4>
										<Markdown>{conversationData.participant.mapping.gainInformationFrom}</Markdown>
									</section>
								),
							},
							{
								label: 'Copilot Recos',
								value: 'copilot-recos',
								content: <Markdown>{conversationData.participant.mapping.calibratedQuestions}</Markdown>,
							},
						]}
					/>
				</>
			}
			noFooter
		/>
	)
}
