import { type z } from 'zod'
import { type LeadResearchAgentItemAPISchema } from '#src/api/organise/lists/schemas'
import DeleteConfirm from '#src/components/delete-confirm'
import LoaderRequiredComponent from '#src/components/LoaderRequiredComponent'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { type LoaderRes } from '#src/routes/organize/lead-research-agent/loaders/archive'
import { routes } from '#src/utils/routes'

export type LeadResearchAgentJobArchiveModalProps = {
	jobId: z.infer<typeof LeadResearchAgentItemAPISchema.shape.id>
}

export const LeadResearchAgentJobArchiveModal = (props: LeadResearchAgentJobArchiveModalProps) => {
	const { companyId } = useCompany()

	return (
		<LoaderRequiredComponent<LoaderRes, LeadResearchAgentJobArchiveModalProps>
			route={routes.leadResearchAgent.archive({ jobId: props.jobId, companyId })}
			component={ModalInner}
			initialProps={props}
		/>
	)
}

const ModalInner = (props: LeadResearchAgentJobArchiveModalProps & LoaderRes) => {
	const {
		data: { job },
	} = props
	const { closeModal } = useModals()
	const { companyId } = useCompany()

	return (
		<DeleteConfirm
			defaultOpen={true}
			onOpenChange={isOpen => !isOpen && closeModal(MODAL_NAME.LeadResearchAgentJobArchive)}
			action={routes.leadResearchAgent.archive({ jobId: job.id, companyId })}
			method="DELETE"
			actionTitle="Archive"
			title={`Archive "${job.title}"?`}
			description={
				<>
					Are you sure you want to archive the <b>{job.title}</b>?
				</>
			}
		/>
	)
}
