import { type ActionFunctionArgs } from 'react-router'
import { playsMutations } from '#src/api/icp/company/plays/mutations'
import { PlaysFormSchema } from '#src/api/icp/company/plays/schemas'
import { MODAL_NAME } from '#src/constants/modals'
import { showToast } from '#src/context/ToastContext'
import { parseFormDataNew } from '#src/utils/forms'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'
import { closeModalServer } from '#src/utils/server/modals'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const { company } = await getCompany(params)
	validateRouteParams(params, ['playId'])

	const formData = await request.formData()

	try {
		const submission = parseFormDataNew(formData, PlaysFormSchema)
		await playsMutations.update(company.id, Number(params.playId), submission.value)
		closeModalServer(MODAL_NAME.PlayEdit)
		showToast({
			message: 'Play edited',
			type: 'success',
		})
		return { ok: true }
	} catch (e) {
		return await serverFormErrorHandler(e)
	}
}
