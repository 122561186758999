import { type z } from 'zod'
import { type ValuePropositionItemAPISchema } from '#src/api/icp/company/value-proposition/schemas'
import { Chip } from '#src/components/chip'
import { Icon } from '#src/components/ui/icon'
import { DeletePointModal } from './DeletePointModal'

export const AccordionTrigger = ({ item, id }: { item: z.infer<typeof ValuePropositionItemAPISchema>; id: number }) => {
	return (
		<div className="w-full text-body-lg">
			<div className="grid grid-cols-12 items-center">
				<div className="col-span-11 flex items-center gap-2.5">
					<Chip variant="blue" size="md" shape="circle">
						{id}
					</Chip>
					<div className="truncate">{item.name}</div>
					{item.status === 'draft' && (
						<span className="flex items-center gap-1.5 text-label-md text-neutral-3-fg">
							<Icon name="edited" /> Edited
						</span>
					)}
				</div>
				<div className="flex justify-end">
					<span onClick={e => e.stopPropagation()}>
						<DeletePointModal item={item} />
					</span>
				</div>
			</div>
		</div>
	)
}
