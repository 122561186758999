import { format } from 'date-fns'
import { useNavigate, useLoaderData, createSearchParams, useSearchParams } from 'react-router'
import ContactAvatar from '#src/components/chat/avatar'
import { Chip } from '#src/components/chip'
import FiltersSection from '#src/components/FiltersSection'
import { PersonaAvatar } from '#src/components/persona'
import { TrainingAgenda } from '#src/components/training-agenda'
import { Icon } from '#src/components/ui/icon'
import { PageLayout } from '#src/components/ui/PageLayout'
import { PaginatedTable } from '#src/components/ui/PaginatedTable'
import { CustomTooltip } from '#src/components/ui/tooltip'
import useAuth from '#src/hooks/useAuth'
import useCompany from '#src/hooks/useCompany'
import LayoutContentHack from '#src/layouts/LayoutContentHack'
import { type LoaderRes } from '#src/routes/enable/roleplay-sessions/loaders/list'
import { formatDateToReadableString } from '#src/utils/date'
import { cn, formatAvatarAbbr } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

const View = () => {
	const { companyId } = useCompany()
	const navigate = useNavigate()
	const { sessions, values, filters } = useLoaderData<LoaderRes>()
	const { adminSession, readOnlySession } = useAuth()
	const [searchParams] = useSearchParams()

	return (
		<PageLayout
			title="Roleplay training"
			{...(!readOnlySession && {
				actions: [
					{
						variant: 'default',
						to: routes.enable.roleplay.session.create({ companyId }),
						icon: 'add',
						title: 'New session',
					},
				],
			})}
		>
			<LayoutContentHack>
				<PaginatedTable
					stickyHeader
					filtersComponent={
						<FiltersSection
							total={sessions.meta.total}
							selected={filters.selected}
							values={filters.values}
							filters={[
								{
									name: 'agenda',
									label: 'Agenda',
									multiple: false,
								},
								{
									name: 'createdBy',
									label: 'Created by',
									multiple: false,
								},
								...(adminSession
									? [
											{
												name: 'user',
												label: 'Participant',
												multiple: false,
											},
										]
									: []),
								{
									name: 'hasAttempts',
									label: 'Attempts',
									multiple: false,
								},
							]}
						/>
					}
					data={sessions.data}
					currentPage={sessions.meta.current_page}
					totalPages={sessions.meta.last_page}
					sort={values.sort}
					onPageChange={page =>
						navigate({
							search: createSearchParams({
								...Object.fromEntries(searchParams),
								page: (page + 1).toString(),
							}).toString(),
						})
					}
					onSortChange={data =>
						navigate({
							search: createSearchParams({
								...Object.fromEntries(searchParams),
								sort: data,
							}).toString(),
						})
					}
					onItemClick={session =>
						navigate({
							pathname: routes.enable.roleplay.session.attempts({
								companyId: companyId,
								conversationId: session.id,
							}),
							search: searchParams.toString(),
						})
					}
					columns={[
						{
							name: 'name',
							heading: 'Name',
							render: session => session.title ?? 'Untitled',
						},
						{
							name: 'created_by',
							heading: 'Created by',
							render: session => (
								<div className="flex items-center gap-2 text-body-sm font-medium text-neutral-2-fg">
									<div className="flex items-center gap-2 text-body-sm font-medium text-neutral-2-fg">
										<ContactAvatar
											className="inline-flex shrink-0 items-center justify-center rounded-full"
											variant="gray"
											size="sm"
											initial={formatAvatarAbbr(session.createdBy)}
										/>
										{session.createdBy}
									</div>
								</div>
							),
						},
						{
							hidden: !adminSession,
							name: 'participant',
							heading: 'Participant',
							render: session => (
								<div className="flex items-center gap-2 text-body-sm font-medium text-neutral-2-fg">
									<div className="flex items-center gap-2 text-body-sm font-medium text-neutral-2-fg">
										<ContactAvatar
											className="inline-flex shrink-0 items-center justify-center rounded-full"
											variant="gray"
											size="sm"
											initial={formatAvatarAbbr(session.user)}
										/>
										{session.user}
									</div>
								</div>
							),
						},
						{
							name: 'attempts',
							heading: 'Attempts',
							render: session => <Chip variant="blue">{session.attempts}</Chip>,
						},
						{
							name: 'status',
							heading: 'Status',
							render: session => {
								const isCompleted = session.attempts > 0
								return (
									<Chip variant={isCompleted ? 'green' : 'gray'} className="inline-flex flex-row items-center gap-1">
										<Icon name={isCompleted ? 'checkmark-filled' : 'circle-dash'} className="-ml-1 h-4 w-4" />
										<span>{isCompleted ? 'Completed' : 'To do'}</span>
									</Chip>
								)
							},
						},
						{
							name: 'dueAt',
							heading: 'Deadline',
							render: session => {
								if (!session.dueAt) {
									return '-'
								}

								const overdue = new Date() > new Date(session.dueAt)
								return (
									<div className={cn('flex flex-row items-center gap-1', overdue && 'text-status-warning-fg')}>
										<span>{formatDateToReadableString(session.dueAt)}</span>
										{overdue && (
											<CustomTooltip label="Overdue">
												<Icon name="warning-alt-filled" className="h-4 w-4" />
											</CustomTooltip>
										)}
									</div>
								)
							},
						},
						{
							name: 'agenda',
							heading: 'Agenda',
							render: session => <TrainingAgenda title={session.scenarioTitle ?? ''} icon="user-service-desk" />,
						},
						{
							name: 'persona_used',
							heading: 'Persona used',
							render: session =>
								session.personaType ? (
									<div className="flex max-w-[200px] flex-row items-center gap-2">
										<PersonaAvatar type={session.personaType} size="sm" className="row-span-2" />
										<div className="flex-1 overflow-hidden">
											<p className="w-full truncate text-label-sm text-neutral-3-fg">{session.name}</p>
											<p className="w-full truncate text-body-sm font-semibold text-neutral-1-fg">
												{session.personaType}
											</p>
										</div>
									</div>
								) : (
									<p className="text-body-sm text-neutral-3-fg">Persona has been deleted</p>
								),
						},
						{
							name: 'createdAt',
							sortable: true,
							heading: 'Date created',
							render: session => format(new Date(session.createdAt), 'PP'),
						},
					]}
				/>
			</LayoutContentHack>
		</PageLayout>
	)
}

export default View
