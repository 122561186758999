import { useEffect } from 'react'
import { Link } from 'react-router'
import { Icon } from '#src/components/ui/icon'
import useAuth from '#src/hooks/useAuth'
import useCompany from '#src/hooks/useCompany'
import { routes } from '#src/utils/routes'

const OutOfCreditsWarning = () => {
	const { userSession, adminSession } = useAuth()
	const { companyId, company } = useCompany()

	useEffect(() => {
		document.body.style.setProperty('--credits-warning-height', company.outOfCredits ? '32px' : '0px')
	}, [company.outOfCredits])

	if (!company.outOfCredits) {
		return null
	}

	return (
		<div className="sticky left-0 top-0 z-40 flex w-full flex-row items-center justify-center gap-2 bg-orange-10 p-2 pl-[calc(0.5rem+var(--navsidebar-width))]">
			<Icon name="warning-alt-filled" className="h-4 w-4 text-status-warning-fg" />
			<div className="text-label-sm text-neutral-2-fg">
				<b>You are out of credits.</b> Please purchase more to continue using our services.
				{(userSession || adminSession) && (
					<>
						{' '}
						<Link to={routes.billing.index({ companyId })} className="text-link-hover">
							Purchase credits
						</Link>
					</>
				)}
			</div>
		</div>
	)
}

export default OutOfCreditsWarning
