import {
	BlockTypeSelect,
	BoldItalicUnderlineToggles,
	headingsPlugin,
	listsPlugin,
	ListsToggle,
	MDXEditor,
	type MDXEditorMethods,
	type MDXEditorProps,
	quotePlugin,
	thematicBreakPlugin,
	toolbarPlugin,
	UndoRedo,
} from '@mdxeditor/editor'
import { useEffect, useRef, useState } from 'react'
import { inputCommonClasses } from '#src/theme'
import { cn } from '#src/utils/misc'
import { generateUniqueId } from '#src/utils/randoms'

export type MarkdownEditorProps = Omit<MDXEditorProps, 'markdown'> & {
	value?: string
	onChange?: (value: string) => void
	'aria-invalid'?: boolean | undefined
}

const MarkdownEditor = (props: MarkdownEditorProps) => {
	const { value, className, 'aria-invalid': ariaInvalid, ...rest } = props
	const inputRef = useRef<MDXEditorMethods>(null)
	const [editorAccessableClassName] = useState(`mdeditorcn-${generateUniqueId()}`)

	useEffect(() => {
		if (!inputRef.current) {
			return
		}
		if (inputRef.current.getMarkdown() !== value) {
			inputRef.current.setMarkdown(value ?? '')
		}
	}, [value])

	useEffect(() => {
		// this required to add aria-invalid which gives error border
		const el = document.getElementsByClassName(editorAccessableClassName)[0]
		if (!el) {
			return
		}
		el.ariaInvalid = ariaInvalid ? 'true' : null
	}, [ariaInvalid, editorAccessableClassName])

	return (
		<MDXEditor
			className={cn(
				inputCommonClasses,
				editorAccessableClassName,
				'z-50 h-[350px] flex-col overflow-hidden p-0 read-only:cursor-auto',
				className,
			)}
			ref={inputRef}
			markdown={value ?? ''}
			plugins={[
				headingsPlugin(),
				listsPlugin(),
				quotePlugin(),
				thematicBreakPlugin(),
				toolbarPlugin({
					toolbarClassName: 'flex flex-row gap-2 w-full rounded-[0] bg-neutral-2-bg',
					toolbarContents: () => (
						<>
							<BlockTypeSelect />
							<BoldItalicUnderlineToggles options={['Bold', 'Italic']} />
							<ListsToggle options={['number', 'bullet']} />
							<div className="ml-auto">
								<UndoRedo />
							</div>
						</>
					),
				}),
			]}
			contentEditableClassName="markdown p-3 h-full"
			{...rest}
		/>
	)
}

export default MarkdownEditor
