import { type BaseSyntheticEvent, type FormEvent, type ReactNode, useEffect } from 'react'
import { type FieldPath, type FieldValues, FormProvider, type UseFormReturn } from 'react-hook-form'
import { type FormEncType, type useFetcher, useSubmit } from 'react-router'
import PageLoader from '#src/components/loader'
import { objectToFormData } from '#src/utils/forms'
import { type ServerFormErrorHandlerRes } from '#src/utils/server/form-errors'

type FormWrapperProps<T extends FieldValues> = {
	children: ReactNode
	formProps: UseFormReturn<T>
	action?: string
	onSubmit?: (formData: FormData, data: T) => void
	method?: 'POST' | 'PUT' | 'PATCH' | 'DELETE'
	className?: string
	formId?: string
	encType?: FormEncType
	fetcher?: ReturnType<typeof useFetcher>
	/* todo: cia butu galima apiResponse nenurodyt, o paimt is fetcher.data if exists, bet tada reik fetcheriui type uzdet ir gali uzraudonuot projektas, kad nesutampa daug vietu tipas */
	actionResponse?: ServerFormErrorHandlerRes | Response
	onFailedStep?: (data: unknown) => void
}

export function FormWrapper<T extends FieldValues>({
	children,
	formProps,
	method = 'POST',
	action,
	onSubmit,
	className,
	formId,
	encType,
	fetcher,
	actionResponse,
	onFailedStep,
}: FormWrapperProps<T>) {
	const submit = useSubmit()

	const handleFormSubmit = (data: T, e?: BaseSyntheticEvent) => {
		const submitter = (e?.nativeEvent as SubmitEvent)?.submitter as HTMLButtonElement
		const btnValue = submitter?.name ? { [submitter.name]: submitter.value } : {}

		const submitData = { ...btnValue, ...data }
		const submitFormData = objectToFormData(submitData, false)

		if (onSubmit) {
			onSubmit(submitFormData, submitData)
			return
		}

		if (fetcher) {
			void fetcher.submit(submitFormData, { method, action, encType })
		} else {
			void submit(submitFormData, { method, action, encType })
		}
	}

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		void formProps.handleSubmit(handleFormSubmit, console.warn)(e)
	}

	useEffect(() => {
		if (!actionResponse || actionResponse instanceof Response) {
			return
		}
		const failedStep = actionResponse && 'failedStep' in actionResponse ? actionResponse?.failedStep : undefined
		if (failedStep) {
			onFailedStep?.(failedStep)
		}
		if ('errors' in actionResponse && actionResponse.errors) {
			Object.entries(actionResponse.errors).forEach(([fieldName, errorMessages]) => {
				formProps.setError(fieldName as FieldPath<T>, {
					type: 'server',
					message: errorMessages.join(', '),
				})
			})
		}
	}, [actionResponse, formProps, onFailedStep])

	const FormComponent = fetcher ? fetcher.Form : 'form'

	return (
		<>
			<FormProvider {...formProps}>
				<FormComponent onSubmit={handleSubmit} className={className} id={formId}>
					{children}
				</FormComponent>
			</FormProvider>
			{fetcher && fetcher.state === 'submitting' && <PageLoader show />}
		</>
	)
}
