import { useQuery } from '@tanstack/react-query'
import { createSearchParams, Link, useLoaderData, useNavigate, useSearchParams } from 'react-router'
import { companyAccountExportQueries } from '#src/api/icp/company/account-export/queries'
import { EmptyStateCard } from '#src/components'
import { Chip } from '#src/components/chip'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { LabeledValue } from '#src/components/ui/LabeledValue'
import { PageLayout } from '#src/components/ui/PageLayout'
import { PaginatedTable } from '#src/components/ui/PaginatedTable'
import { CustomTooltip } from '#src/components/ui/tooltip'
import useCompany from '#src/hooks/useCompany'
import LayoutContentHack from '#src/layouts/LayoutContentHack'
import { formatDateToReadableString } from '#src/utils/date'
import { routes } from '#src/utils/routes'
import { type LoaderRes } from '../loaders/list'
import { canDownload } from '../utils'
import { ListStatisticsCard, StatusLabel } from './components'

export const View = () => {
	const {
		values: { page },
		data,
	} = useLoaderData<LoaderRes>()
	const { company, companyId } = useCompany()

	const { data: statistics } = useQuery({
		...companyAccountExportQueries.statistics(company.id),
		initialData: data.statistics,
		refetchInterval: 10000,
	})

	const { data: jobs } = useQuery({
		...companyAccountExportQueries.list(company.id, page),
		initialData: data.jobs,
		...(statistics.inProgressCount + statistics.inQueueCount > 0 && {
			refetchInterval: 10000,
		}),
	})

	const navigate = useNavigate()
	const [searchParams] = useSearchParams()

	if (!jobs.meta.total) {
		return (
			<PageLayout title="Account Data Explorer">
				<EmptyStateCard
					icon="distribution-ledger"
					title="No Account Data Explorer jobs found"
					description="You don’t have any Account Data Explorer jobs yet."
					actions={[
						{
							title: 'New query',
							to: {
								pathname: routes.organize.accountDataExplorer.create({ companyId }),
								search: searchParams.toString(),
							},
						},
					]}
				/>
			</PageLayout>
		)
	}

	return (
		<PageLayout
			title="Account Data Explorer"
			actions={[
				{
					icon: 'add',
					title: 'New query',
					to: {
						pathname: routes.organize.accountDataExplorer.create({ companyId }),
						search: searchParams.toString(),
					},
					variant: 'default',
				},
			]}
		>
			<div className="grid w-full grid-cols-3 grid-rows-1 gap-6">
				<ListStatisticsCard name="In queue" count={statistics.inQueueCount} icon="circle-dash" />
				<ListStatisticsCard name="In progress" count={statistics.inProgressCount} icon="incomplete" />
				<ListStatisticsCard
					name="Completed"
					count={statistics.completedCount}
					icon="checkmark-filled"
					iconVariant="success"
				/>
			</div>
			<LayoutContentHack>
				<PaginatedTable
					currentPage={jobs.meta.current_page}
					totalPages={jobs.meta.last_page}
					onPageChange={page =>
						navigate({
							search: createSearchParams({
								...Object.fromEntries(searchParams),
								page: (page + 1).toString(),
							}).toString(),
						})
					}
					columns={[
						{
							name: 'createdAt',
							heading: 'Date created',
							render: item => (
								<CustomTooltip
									side="right"
									className="max-h-[400px] max-w-[500px] overflow-y-auto"
									label={
										<div
											onClick={e => {
												e.preventDefault()
												e.stopPropagation()
											}}
											className="flex flex-col gap-4"
										>
											{Object.entries(item.filters).map(([key, value]) => (
												<LabeledValue
													key={`job-${item.id}-${key}`}
													label={data.filters.find(filter => filter.name === key)?.label ?? key}
												>
													{Array.isArray(value) ? (
														<div className="flex flex-row flex-wrap gap-1">
															{value.map(val => (
																<Chip variant="gray" key={`job-${item.id}-${key}-${val}`}>
																	{val}
																</Chip>
															))}
														</div>
													) : (
														value
													)}
												</LabeledValue>
											))}
										</div>
									}
									variant="secondary"
								>
									{formatDateToReadableString(item.createdAt, { includeTime: true })}
								</CustomTooltip>
							),
						},
						{
							name: 'recordCount',
							heading: 'Record count',
							render: item => item.totalResults ?? '-',
						},
						{
							name: 'status',
							heading: 'Status',
							render: item => <StatusLabel status={item.status} />,
						},
						{
							name: 'actions',
							heading: '',
							render: item => (
								<div className="inline-flex flex-row gap-1" onClick={e => e.stopPropagation()}>
									<CustomTooltip label="New query using this job filters" noChildrenWrapper>
										<Button variant="icon" size="icon" asChild>
											<Link
												to={{
													pathname: routes.organize.accountDataExplorer.create({ companyId }),
													search: new URLSearchParams({ jobId: String(item.id) }).toString(),
												}}
											>
												<Icon name="copy" size="font" />
											</Link>
										</Button>
									</CustomTooltip>
									{canDownload(item.status) && (
										<Button variant="icon" size="icon" asChild>
											<Link
												to={routes.organize.accountDataExplorer.download({
													companyId,
													jobId: String(item.id),
												})}
												target="_blank"
											>
												<Icon name="download" size="font" className="text-brand-1-fg" />
											</Link>
										</Button>
									)}
								</div>
							),
						},
					]}
					data={jobs.data}
				/>
			</LayoutContentHack>
		</PageLayout>
	)
}
