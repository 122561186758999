import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useFetcher } from 'react-router'
import { type z } from 'zod'
import { type LeadResearchAgentItemAPISchema } from '#src/api/organise/lists/schemas'
import { EXPORT_TYPE } from '#src/api/organise/lists-csv/constants'
import { LeadResearchAgentJobDownloadFormSchema } from '#src/api/organise/lists-csv/schemas'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import LoaderRequiredComponent from '#src/components/LoaderRequiredComponent'
import { Dialog } from '#src/components/ui/dialog'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { type LRADownloadActionRes } from '#src/routes/organize/lead-research-agent/routes/download'
import { type LRAStopLoaderRes } from '#src/routes/organize/lead-research-agent/routes/stop'
import { routes } from '#src/utils/routes'

export type LeadResearchAgentJobDownloadModalProps = {
	jobId: z.infer<typeof LeadResearchAgentItemAPISchema.shape.id>
}

export const LeadResearchAgentJobDownloadModal = (props: LeadResearchAgentJobDownloadModalProps) => {
	const { companyId } = useCompany()

	return (
		<LoaderRequiredComponent<LRAStopLoaderRes, LeadResearchAgentJobDownloadModalProps>
			route={routes.leadResearchAgent.stop({ jobId: props.jobId, companyId })}
			component={ModalInner}
			initialProps={props}
		/>
	)
}

const getFormName = (jobId: z.infer<typeof LeadResearchAgentItemAPISchema.shape.id>) => `lra-job-${jobId}-download`

const ModalInner = (props: LeadResearchAgentJobDownloadModalProps & LRAStopLoaderRes) => {
	const {
		data: { job },
	} = props
	const { closeModal } = useModals()
	const { companyId } = useCompany()

	const actionFetcher = useFetcher<LRADownloadActionRes>()

	const form = useForm<z.infer<typeof LeadResearchAgentJobDownloadFormSchema>>({
		resolver: zodResolver(LeadResearchAgentJobDownloadFormSchema),
		mode: 'onSubmit',
		defaultValues: {
			type: EXPORT_TYPE.Full,
		},
	})

	return (
		<Dialog
			size="xl"
			defaultOpen={true}
			onOpenChange={isOpen => !isOpen && closeModal(MODAL_NAME.LeadResearchAgentJobDownload)}
			dialogHeading="Select export options"
			isSubmitting={actionFetcher.state === 'submitting'}
			actions={[
				{
					type: 'cancel',
					label: 'Close',
				},
				{
					type: 'submit',
					label: 'Download',
					formId: getFormName(job.id),
				},
			]}
		>
			<FormWrapper
				formId={getFormName(job.id)}
				formProps={form}
				method="POST"
				action={routes.leadResearchAgent.download({ companyId, jobId: job.id })}
				className="flex flex-col gap-6"
				fetcher={actionFetcher}
			>
				<FormField
					disableLabel
					name="type"
					fieldType="radioCards"
					options={[
						{
							value: EXPORT_TYPE.Full,
							label: 'Accounts + Contacts',
							description: (
								<>
									Download all accounts and their associated contacts, if any.
									{!!job && (
										<>
											<br />
											{job.statistic.accountCount} accounts | {job.statistic.contactCount} contacts
										</>
									)}
								</>
							),
						},
						{
							value: EXPORT_TYPE.ICPIBPFull,
							label: 'ICP Accounts + IBP Contacts',
							description: (
								<>
									Download only ICP-qualified accounts and IBP-qualified contacts.
									{!!job && (
										<>
											<br />
											{job.statistic.icpStatusYesCount} accounts | {job.statistic.ibpStatusYesCount} contacts
										</>
									)}
								</>
							),
						},
						{
							value: EXPORT_TYPE.ICPIBPFullWithDetails,
							label: 'ICP Accounts + IBP Contacts with Contact Details',
							description: (
								<>
									Download ICP accounts and only IBP contacts that have email or phone available.
									{!!job && (
										<>
											<br />
											{job.statistic.icpStatusYesCount} accounts | {job.statistic.contactWithEmailPhoneCount} contacts
										</>
									)}
								</>
							),
						},
						{
							value: EXPORT_TYPE.ICPAccounts,
							label: 'ICP Accounts only',
							description: (
								<>
									Download only accounts that are ICP-qualified.
									{!!job && (
										<>
											<br />
											{job.statistic.icpStatusYesCount} accounts
										</>
									)}
								</>
							),
						},
						{
							value: EXPORT_TYPE.IBPContacts,
							label: 'IBP Contacts only',
							description: (
								<>
									Download only contacts that are IBP-qualified.
									{!!job && (
										<>
											<br />
											{job.statistic.ibpStatusYesCount} contacts
										</>
									)}
								</>
							),
						},
						{
							value: EXPORT_TYPE.IBPContactsWithDetails,
							label: 'IBP Contacts with Contact Details',
							description: (
								<>
									Download only IBP contacts that have email or phone available.
									{!!job && (
										<>
											<br />
											{job.statistic.contactWithEmailPhoneCount} contacts
										</>
									)}
								</>
							),
						},
						{
							value: EXPORT_TYPE.Accounts,
							label: 'Accounts only',
							description: (
								<>
									Download all accounts.
									{!!job && (
										<>
											<br />
											{job.statistic.accountCount} accounts
										</>
									)}
								</>
							),
						},
						{
							value: EXPORT_TYPE.Contacts,
							label: 'Contacts only',
							description: (
								<>
									Download all contacts.
									{!!job && (
										<>
											<br />
											{job.statistic.contactCount} contacts
										</>
									)}
								</>
							),
						},
					]}
				/>
			</FormWrapper>
		</Dialog>
	)
}
