import { type z } from 'zod'
import { type VerticalAPISchema } from '#src/api/icp/company/verticals/schemas'
import { type ECOSYSTEM_TYPE } from '#src/routes/calibrate/ecosystem-management/ecosystem/constants'
import { type CompanySchema } from '#src/routes/company/schema'
import { baseApi } from '#src/utils/endpoints'

const base = (companyId: z.infer<typeof CompanySchema>['id']) => `${baseApi}/company/${companyId}/vertical`

const endpoints = {
	create: (companyId: z.infer<typeof CompanySchema>['id']) => base(companyId),
	item: (companyId: z.infer<typeof CompanySchema>['id'], itemId: z.infer<typeof VerticalAPISchema>['id']) =>
		`${base(companyId)}/${itemId}`,
	downloadTemplate: (companyId: z.infer<typeof CompanySchema>['id'], ecosystemType: ECOSYSTEM_TYPE) =>
		`${base(companyId)}/export/template?ecosystemType=${ecosystemType}`,
	import: (companyId: z.infer<typeof CompanySchema>['id']) => `${base(companyId)}/import`,
}

export { endpoints as verticalsEndpoints }
