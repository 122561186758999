import { useEffect } from 'react'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { ContactWaterfallingCreateModal } from '#src/modals/contact-waterfalling/Create'
import { routes } from '#src/utils/routes'

export const View = () => {
	const { openModal } = useModals()
	const { companyId } = useCompany()

	useEffect(() => {
		openModal({
			name: MODAL_NAME.ContactWaterfallingCreate,
			component: ContactWaterfallingCreateModal,
			settings: {
				routeToRedirectOnClose: routes.organize.contactWaterfalling.index({ companyId }),
			},
		})
	}, [companyId, openModal])

	return null
}
