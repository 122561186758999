import { type RouteObject } from 'react-router'
import { action as adminCompanyAddCredits } from '#src/routes/admin/company/actions/add-credits'
import { action as adminCompaniesDeleteAction } from '#src/routes/admin/company/actions/delete'
import { action as adminCompanyBillingEditAction } from '#src/routes/admin/company/actions/editBilling'
import { action as adminCompaniesDashboardEditAction } from '#src/routes/admin/company/actions/editDashboard'
import { action as adminCompanyDetailsEditAction } from '#src/routes/admin/company/actions/editDetails'
import { action as adminCompaniesNewAction } from '#src/routes/admin/company/actions/new'
import { loader as adminCompaniesLoader } from '#src/routes/admin/company/loaders/companies'
import {
	loader as adminCompaniesEditLoader,
	type LoaderRes as EditLoaderRes,
} from '#src/routes/admin/company/loaders/edit'
import { View as CompanyAddCredits } from '#src/routes/admin/company/views/add-credits'
import Companies from '#src/routes/admin/company/views/companies'
import EditCompanyDashboard from '#src/routes/admin/company/views/edit'
import NewCompany from '#src/routes/admin/company/views/new'

const adminCompanyRouter = {
	path: 'company',
	handle: {
		layout: {
			enableHeader: true,
		},
		crumb: () => [
			{
				label: 'Companies',
			},
		],
	},
	children: [
		{
			path: '',
			element: <Companies />,
			loader: adminCompaniesLoader,
			handle: {
				layout: {
					fullWidth: true,
				},
			},
			children: [
				{
					path: 'new',
					element: <NewCompany />,
					action: adminCompaniesNewAction,
				},
			],
		},
		{
			path: ':companyId',
			children: [
				{
					path: 'delete',
					action: adminCompaniesDeleteAction,
				},
				{
					path: 'edit',
					element: <EditCompanyDashboard />,
					loader: adminCompaniesEditLoader,
					handle: {
						crumb: (res: EditLoaderRes) => [
							{
								label: res.company.name,
							},
						],
					},
					children: [
						{
							path: 'add-credits',
							element: <CompanyAddCredits />,
							action: adminCompanyAddCredits,
						},
					],
				},
				{
					path: 'editDetails',
					action: adminCompanyDetailsEditAction,
				},
				{
					path: 'editDashboard',
					action: adminCompaniesDashboardEditAction,
				},
				{
					path: 'editBilling',
					action: adminCompanyBillingEditAction,
				},
			],
		},
	],
} satisfies RouteObject

export default adminCompanyRouter
