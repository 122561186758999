import { clsx } from 'clsx'
import { ACCOUNT_EXPORT_JOB_STATUS } from '#src/api/icp/company/account-export/schemas'
import { Icon, type IconName } from '#src/components/ui/icon'
import { type ClassName } from '#src/types/styles'
import { cn } from '#src/utils/misc'
import { JobStatusName } from '../../constants'

type Props = {
	status: ACCOUNT_EXPORT_JOB_STATUS
}

const LABEL_BG: Record<ACCOUNT_EXPORT_JOB_STATUS, ClassName> = {
	[ACCOUNT_EXPORT_JOB_STATUS.InQueue]: 'bg-brand-1-bg',
	[ACCOUNT_EXPORT_JOB_STATUS.InProgress]: 'bg-brand-1-bg',
	[ACCOUNT_EXPORT_JOB_STATUS.Completed]: 'bg-green-10',
	[ACCOUNT_EXPORT_JOB_STATUS.Error]: 'bg-red-10',
} as const

const ICON: Record<ACCOUNT_EXPORT_JOB_STATUS, IconName> = {
	[ACCOUNT_EXPORT_JOB_STATUS.InQueue]: 'loading-sm',
	[ACCOUNT_EXPORT_JOB_STATUS.InProgress]: 'loading-sm',
	[ACCOUNT_EXPORT_JOB_STATUS.Completed]: 'checkmark-filled',
	[ACCOUNT_EXPORT_JOB_STATUS.Error]: 'stop-sign-filled',
} as const

const COLOR: Record<ACCOUNT_EXPORT_JOB_STATUS, ClassName> = {
	[ACCOUNT_EXPORT_JOB_STATUS.InQueue]: 'text-brand-1-fg',
	[ACCOUNT_EXPORT_JOB_STATUS.InProgress]: 'text-brand-1-fg',
	[ACCOUNT_EXPORT_JOB_STATUS.Completed]: 'text-green-80',
	[ACCOUNT_EXPORT_JOB_STATUS.Error]: 'text-red-60',
} as const

export const StatusLabel = (props: Props) => {
	const { status } = props

	return (
		<div className={clsx('inline-flex flex-row items-center gap-1 rounded-xl p-1', LABEL_BG[status], COLOR[status])}>
			<Icon name={ICON[status]} className={cn('h-4 w-4', ICON[status].includes('loading') && 'animate-spin')} />
			<div className={cn('text-body-sm', 'mr-1')}>{JobStatusName[status]}</div>
		</div>
	)
}
