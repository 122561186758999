import { Link, useLoaderData } from 'react-router'
import { Button } from '#src/components/ui/button'
import { Sidebar, SidebarFooter, SidebarHeader } from '#src/components/ui/sidebar'
import { StatusButton } from '#src/components/ui/status-button'
import useCompany from '#src/hooks/useCompany'
import { getVerticalCTALabel } from '#src/routes/calibrate/ecosystem-management/ecosystem/utils'
import { type LoaderResType } from '#src/routes/calibrate/ecosystem-management/verticals/loaders/save'
import { VerticalForm } from '#src/routes/calibrate/ecosystem-management/verticals/views/components'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

export const View = () => {
	const { ecosystemId, vertical, criteria, enrichmentCriteria, schema, defaultValues, ecosystem } =
		useLoaderData<LoaderResType>()
	const { companyId } = useCompany()

	const action = vertical?.id
		? routes.calibrate.verticals.update({
				companyId,
				ecosystemId,
				verticalId: vertical.id.toString(),
			})
		: routes.calibrate.verticals.save({
				companyId,
				ecosystemId,
			})

	const method = vertical?.id ? 'PATCH' : 'POST'
	const formId = 'verticalSaveForm'

	const isPending = useIsPending({
		formAction: action,
		formMethod: method,
	})

	return (
		<div className="sticky right-0 w-[26rem] shrink-0">
			<Sidebar
				key={vertical?.id}
				header={
					<SidebarHeader
						heading={
							vertical?.id ? getVerticalCTALabel('Edit', ecosystem?.type) : getVerticalCTALabel('Add', ecosystem?.type)
						}
						closeTo={routes.calibrate.verticals.index({
							companyId,
							ecosystemId,
						})}
					/>
				}
				main={
					<VerticalForm
						key={formId}
						formId={formId}
						action={action}
						method={method}
						criteria={criteria}
						enrichmentCriteria={enrichmentCriteria}
						schema={schema}
						defaultValues={defaultValues}
						ecosystemType={ecosystem?.type}
					/>
				}
				footer={
					<SidebarFooter>
						<Button asChild variant="outline" size="sm">
							<Link
								to={routes.calibrate.verticals.index({
									companyId,
									ecosystemId,
								})}
							>
								Cancel
							</Link>
						</Button>
						<StatusButton
							status={isPending ? 'pending' : 'idle'}
							type="submit"
							size="sm"
							name="intent"
							value="vertical"
							form={formId}
							disabled={isPending}
						>
							{vertical?.id ? 'Update' : 'Create'}
						</StatusButton>
					</SidebarFooter>
				}
				className="px-0"
			/>
		</div>
	)
}
