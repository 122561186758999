import { type LoaderFunctionArgs } from 'react-router'
import { type z } from 'zod'
import { statsQueries } from '#src/api/icp/company/stats/queries'
import { valuePropositionQueries } from '#src/api/icp/company/value-proposition/queries'
import { type ValuePropositionListQuerySchema } from '#src/api/icp/company/value-proposition/schemas'
import { client } from '#src/main'
import { getWordCount } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<ReturnType<typeof loader>>>

export const loader =
	(status?: z.infer<typeof ValuePropositionListQuerySchema.shape.status>) =>
	async ({ params }: LoaderFunctionArgs) => {
		const { company } = await getCompany(params)
		const [statistics, valueProposition] = await Promise.all([
			client.refetchQueries(statsQueries.dashboard(company.id)),
			client.fetchQuery(valuePropositionQueries.list(company.id, { status })),
		])

		return {
			data: {
				statistics,
				valueProposition,
				wordCount: valueProposition.valuePropositions
					.map(item => getWordCount(`${item.name ?? ''} ${item.description ?? ''}`))
					.reduce((partialSum, a) => partialSum + a, 0),
			},
		}
	}
