import { useEffect } from 'react'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { PlayTemplateDeleteModal } from '#src/modals/play-templates/PlayTemplateDelete'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'

export const View = () => {
	const { openModal } = useModals()
	const { templateId } = useParsedRouteParams(['templateId'])
	const { companyId } = useCompany()

	useEffect(() => {
		openModal({
			name: MODAL_NAME.PlayTemplateDelete,
			component: PlayTemplateDeleteModal,
			settings: {
				routeToRedirectOnClose: routes.enable.playTemplates.index({ companyId }),
			},
			props: {
				templateId: Number(templateId),
			},
		})
	}, [companyId, openModal, templateId])

	return null
}
