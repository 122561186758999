export enum ECOSYSTEM_TYPE {
	DEFAULT = 'default',
	KEY_ACCOUNT = 'key_account',
	CRITERIA_SANDBOX = 'criteria_sandbox',
}

export const ECOSYSTEM_TYPE_LABELS: Record<ECOSYSTEM_TYPE, { singular: string; plural: string }> = {
	[ECOSYSTEM_TYPE.DEFAULT]: { singular: 'Ecosystem', plural: 'Ecosystems' },
	[ECOSYSTEM_TYPE.KEY_ACCOUNT]: { singular: 'Key Account', plural: 'Key Accounts' },
	[ECOSYSTEM_TYPE.CRITERIA_SANDBOX]: { singular: 'Criteria Sandbox', plural: 'Criteria Sandboxes' },
} as const
