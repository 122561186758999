import { z } from 'zod'
import { COMPANY_BILLING_FREQUENCY, COMPANY_PILLARS } from '#src/api/icp/admin/company/constants'

export const DashboardSchema = z.object({
	id: z.number(),
	name: z.string(),
	url: z.string().url().nullable().optional(),
})

export const CompanySchema = z.object({
	id: z.string(),
	name: z.string(),
	dashboard: DashboardSchema.array().nullable().optional(),
	activeModules: z.array(z.nativeEnum(COMPANY_PILLARS)),
	totalSeats: z.number().nullable(),
	credits: z.number().nullable(),
	outOfCredits: z.boolean(),
	contractStartDate: z.string().nullable(),
	contractRenewalDate: z.string().nullable(),
	billingFrequency: z.nativeEnum(COMPANY_BILLING_FREQUENCY).nullable(),
	currency: z.string().nullable(),
})

export const CompanyListAPISchema = z.object({
	id: z.string(),
	name: z.string(),
	dashboard: DashboardSchema.array().nullable().optional(),
})

export const CompaniesSchema = CompanyListAPISchema.array()
