import { type z } from 'zod'
import { client } from '#src/main'
import { type CompanySchema } from '#src/routes/company/schema'
import { makeDownloadableFromRes } from '#src/utils/files'
import { fetch } from '#src/utils/safeFetch'
import { contactsEnrichEndpoints } from './endpoints'
import { contactsEnrichQueriesKeys } from './queries'
import { type DownloadContactsEnrichSchema, type NewContactEnrichFormSchema } from './schemas'

const mutations = {
	create: async (companyId: z.infer<typeof CompanySchema>['id'], data: z.infer<typeof NewContactEnrichFormSchema>) => {
		await fetch(contactsEnrichEndpoints.create(companyId), {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
		await client.invalidateQueries({
			queryKey: contactsEnrichQueriesKeys.all,
		})
	},
	download: async (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		data: z.infer<typeof DownloadContactsEnrichSchema>,
		sort: string[],
		filters: Record<string, string>,
	) => {
		const res = await fetch(contactsEnrichEndpoints.download(companyId, sort, filters), {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
		await makeDownloadableFromRes(res)
	},
}

export { mutations as contactsEnrichMutations }
