import { type LoaderFunctionArgs } from 'react-router'
import { userQueries } from '#src/api/icp/company/user/queries'
import { client } from '#src/main'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	validateRouteParams(params, ['userId'])

	const [user, roles] = await Promise.all([
		client.fetchQuery(userQueries.item(company.id, params.userId)),
		client.fetchQuery(userQueries.roles(company.id)),
	])

	return {
		data: {
			company,
			user,
			roles,
		},
	}
}
