import { useEffect } from 'react'
import { useLoaderData } from 'react-router'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { RoleplayScenarioEditModal } from '#src/modals/RoleplayScenarioEdit'
import { routes } from '#src/utils/routes'
import { type LoaderRes } from '../loaders/edit'

const View = () => {
	const { scenario } = useLoaderData<LoaderRes>()
	const { openModal } = useModals()
	const { companyId } = useCompany()

	useEffect(() => {
		openModal({
			name: MODAL_NAME.RoleplayScenarioEdit,
			props: { scenarioId: scenario.id },
			component: RoleplayScenarioEditModal,
			settings: {
				routeToRedirectOnClose: routes.enable.roleplay.scenario.item({
					companyId,
					scenarioId: scenario.id,
				}),
			},
		})
	}, [companyId, openModal, scenario.id])

	return null
}

export default View
