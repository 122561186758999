import { type LoaderFunctionArgs } from 'react-router'
import { companyPersonaQueries } from '#src/api/icp/company/persona/queries'
import { verticalsQueries } from '#src/api/icp/company/verticals/queries'
import { client } from '#src/main'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem-management/ecosystem/queries'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ request, params }: LoaderFunctionArgs) => {
	validateRouteParams(params, ['ecosystemId', 'verticalId'])
	const { company, companyId } = await getCompany(params)
	const url = new URL(request.url)
	const isDuplicating = url.searchParams.get('duplicating') === 'true'

	const [ecosystems, vertical, personas, persona] = await Promise.all([
		client.fetchQuery(ecosystemsQuery(company.id)),
		client.fetchQuery(verticalsQueries.item(company.id, Number(params.verticalId))),
		client.fetchQuery(
			companyPersonaQueries.list(company.id, {
				ecosystem_id: params.ecosystemId,
				vertical_id: params.verticalId,
				per_page: 50,
			}),
		),
		params.personaId ? client.fetchQuery(companyPersonaQueries.item(company.id, Number(params.personaId))) : null,
	])

	return {
		companyId,
		ecosystemId: params.ecosystemId,
		verticalId: params.verticalId,
		personaId: params.personaId,
		ecosystems,
		vertical,
		personas: personas.data,
		persona: isDuplicating && persona ? { ...persona, id: null } : persona,
		isDuplicating,
	}
}
