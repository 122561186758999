import { type LoaderFunctionArgs } from 'react-router'
import { companyPersonaQueries } from '#src/api/icp/company/persona/queries'
import { playsQueries } from '#src/api/icp/company/plays/queries'
import { playsTemplatesQueries } from '#src/api/icp/company/plays-templates/queries'
import { client } from '#src/main'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem-management/ecosystem/queries'
import { PERSONA_TYPES } from '#src/utils/enumerations'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	const url = new URL(request.url)
	const searchParams = new URLSearchParams(url.search)
	const templateId = searchParams.get('templateId')

	const [expertises, criterias, operators, plays, template, ecosystems] = await Promise.all([
		client.fetchQuery(companyPersonaQueries.expertiseList(company.id, {})),
		client.fetchQuery(playsQueries.criterias(company.id)),
		client.fetchQuery(playsQueries.operators(company.id)),
		client.fetchQuery(playsQueries.list(company.id, { all_plays: 'true' })),
		templateId ? client.fetchQuery(playsTemplatesQueries.item(company.id, Number(templateId))) : null,
		client.fetchQuery(ecosystemsQuery(company.id, { personas: false, exclude: true })),
	])

	return {
		data: {
			personaTypes: Object.values(PERSONA_TYPES),
			expertises,
			tags: [...new Set(plays.map(play => play.tags).flat(1))],
			criterias,
			operators,
			template,
			ecosystems,
		},
	}
}
