import { type RouteObject } from 'react-router'
import adminCompanyRouter from '#src/routes/admin/company/router'
import adminPlaysExpertsRouter from '#src/routes/admin/plays-experts/router'
import { loader as rootLoader } from '#src/routes/admin/root/loaders/root'

export default {
	path: 'admin',
	loader: rootLoader,
	handle: {
		crumb: () => [
			{
				label: 'Admin',
				icon: 'settings',
			},
		],
	},
	children: [adminCompanyRouter, adminPlaysExpertsRouter],
} satisfies RouteObject
