import { type RouteObject } from 'react-router'
import { action as createAction } from './actions/create'
import { loader as createLoader } from './loaders/create'
import { loader as downloadLoader } from './loaders/download'
import { loader as listLoader } from './loaders/list'
import { View as CreateView } from './views/create'
import { View as DownloadView } from './views/download'
import { View as ListView } from './views/list'

const accountDataExplorerRouter = {
	path: 'account-data-explorer',
	handle: {
		crumb: () => [
			{
				label: 'Account Data Explorer',
				icon: 'enterprise',
			},
		],
	},
	children: [
		{
			path: '',
			loader: listLoader,
			element: <ListView />,
			handle: {
				layout: {
					enableHeader: true,
					fullWidth: true,
				},
			},
			children: [
				{
					path: 'new',
					element: <CreateView />,
					loader: createLoader,
					action: createAction,
				},
			],
		},
		{
			path: ':jobId/download',
			loader: downloadLoader,
			element: <DownloadView />,
		},
	],
} satisfies RouteObject

export default accountDataExplorerRouter
