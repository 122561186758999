'use client'

/**
 * Calendar
 * This component is based on the Tremor Calendar [v0.1.0].
 * It was refactored as little as possible. Going to be updated in the future.
 * Still contains unwanted class names and other stuff.
 * Refer to the official documentation for more details:
 * https://tremor.so/docs/inputs/calendar
 */

import { addYears, format, isSameMonth } from 'date-fns'
import { useRef, type HTMLAttributes, type ElementType, type FC } from 'react'
import {
	DayPicker,
	useDayPicker,
	useDayRender,
	useNavigation,
	type DayPickerRangeProps,
	type DayPickerSingleProps,
	type DayProps,
} from 'react-day-picker'

import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { cn } from '#src/utils/misc'

/* ----------------------------------------------------------------------------
   Types
----------------------------------------------------------------------------- */

export type NavigationButtonProps = HTMLAttributes<HTMLButtonElement> & {
	onClick: () => void
	icon: ElementType
	disabled?: boolean
}

type OmitKeys<T, K extends keyof T> = {
	[P in keyof T as P extends K ? never : P]: T[P]
}

type KeysToOmit = 'showWeekNumber' | 'captionLayout' | 'mode'

export type SingleProps = OmitKeys<DayPickerSingleProps, KeysToOmit>
export type RangeProps = OmitKeys<DayPickerRangeProps, KeysToOmit>

export type CalendarProps =
	| ({ mode: 'single' } & SingleProps)
	| ({ mode?: undefined } & SingleProps)
	| ({ mode: 'range' } & RangeProps)

/* ----------------------------------------------------------------------------
   Calendar Component
----------------------------------------------------------------------------- */

export const Calendar: FC<CalendarProps & { enableYearNavigation?: boolean }> = ({
	mode = 'single',
	weekStartsOn = 1,
	numberOfMonths = 1,
	enableYearNavigation = false,
	disableNavigation,
	locale,
	className,
	classNames,
	...props
}) => {
	return (
		<DayPicker
			mode={mode}
			weekStartsOn={weekStartsOn}
			numberOfMonths={numberOfMonths}
			locale={locale}
			showOutsideDays={numberOfMonths === 1}
			className={cn(className)}
			classNames={{
				months: 'flex space-y-0',
				month: 'space-y-4 p-3',
				nav: 'gap-1 flex items-center rounded-full size-full justify-between p-4',
				table: 'w-full border-collapse space-y-1',
				head_cell: 'w-9 text-body-md sm:text-xs text-center text-neutral-3-fg font-normal pb-2',
				row: 'w-full mt-0.5',
				cell: cn('relative p-0 text-center focus-within:relative'),
				day: cn('size-9 rounded text-body-md focus:z-10', 'hover:bg-neutral-1-bg-hover'),
				day_today: 'font-semibold',
				day_selected: cn('rounded', 'aria-selected:bg-brand-3-bg aria-selected:text-white'),
				day_disabled: '!text-neutral-3-fg-disabled line-through disabled:hover:bg-transparent',
				day_outside: 'text-neutral-4-fg',
				day_range_middle: cn('!rounded-none', 'aria-selected:!bg-brand-1-bg aria-selected:!text-neutral-1-fg'),
				day_range_start: 'rounded-r-none !rounded-l',
				day_range_end: 'rounded-l-none !rounded-r',
				day_hidden: 'invisible',
				...classNames,
			}}
			components={{
				IconLeft: () => <Icon name="arrow-left" />,
				IconRight: () => <Icon name="arrow-right" />,
				Caption: ({ ...propsCaption }) => {
					const { goToMonth, nextMonth, previousMonth, currentMonth, displayMonths } = useNavigation()
					const { numberOfMonths, fromDate, toDate } = useDayPicker()

					const displayIndex = displayMonths.findIndex(month => isSameMonth(propsCaption.displayMonth, month))
					const isFirst = displayIndex === 0
					const isLast = displayIndex === displayMonths.length - 1

					const hideNextButton = numberOfMonths > 1 && (isFirst || !isLast)
					const hidePreviousButton = numberOfMonths > 1 && (isLast || !isFirst)

					const goToPreviousYear = () => {
						const targetMonth = addYears(currentMonth, -1)
						if (previousMonth && (!fromDate || targetMonth.getTime() >= fromDate.getTime())) {
							goToMonth(targetMonth)
						}
					}

					const goToNextYear = () => {
						const targetMonth = addYears(currentMonth, 1)
						if (nextMonth && (!toDate || targetMonth.getTime() <= toDate.getTime())) {
							goToMonth(targetMonth)
						}
					}

					return (
						<div className="flex items-center justify-between">
							<div className="flex items-center gap-1">
								{enableYearNavigation && !hidePreviousButton && (
									<Button
										disabled={
											disableNavigation ||
											!previousMonth ||
											(fromDate && addYears(currentMonth, -1).getTime() < fromDate.getTime())
										}
										aria-label="Go to previous year"
										onClick={goToPreviousYear}
										size="sm"
										variant="secondary"
									>
										<Icon name="chevron-right" className="rotate-180 text-body-md text-neutral-3-fg" />
									</Button>
								)}
								{!hidePreviousButton && (
									<Button
										disabled={disableNavigation || !previousMonth}
										aria-label="Go to previous month"
										onClick={() => previousMonth && goToMonth(previousMonth)}
										size="sm"
										variant="secondary"
									>
										<Icon name="chevron-right" className="rotate-180 text-body-md text-neutral-3-fg" />
									</Button>
								)}
							</div>

							<div
								role="presentation"
								aria-live="polite"
								className="text-sm text-gray-900 font-medium capitalize tabular-nums dark:text-gray-50"
							>
								{format(propsCaption.displayMonth, 'LLLL yyy', { locale })}
							</div>

							<div className="flex items-center gap-1">
								{!hideNextButton && (
									<Button
										disabled={disableNavigation || !nextMonth}
										aria-label="Go to next month"
										onClick={() => nextMonth && goToMonth(nextMonth)}
										size="sm"
										variant="secondary"
									>
										<Icon name="chevron-right" className="text-body-md text-neutral-3-fg" />
									</Button>
								)}
								{enableYearNavigation && !hideNextButton && (
									<Button
										disabled={
											disableNavigation ||
											!nextMonth ||
											(toDate && addYears(currentMonth, 1).getTime() > toDate.getTime())
										}
										aria-label="Go to next year"
										onClick={goToNextYear}
										size="sm"
										variant="secondary"
									>
										<Icon name="chevron-right" className="text-body-md text-neutral-3-fg" />
									</Button>
								)}
							</div>
						</div>
					)
				},
				Day: ({ date, displayMonth }: DayProps) => {
					const buttonRef = useRef<HTMLButtonElement>(null)
					const { activeModifiers, buttonProps, divProps, isButton, isHidden } = useDayRender(
						date,
						displayMonth,
						buttonRef,
					)

					const { selected, today, disabled, range_middle } = activeModifiers

					if (isHidden) {
						return null
					}

					if (!isButton) {
						return <div {...divProps} className={cn('flex items-center justify-center', divProps.className)} />
					}

					const { children: buttonChildren, className: dayButtonClass, ...buttonPropsRest } = buttonProps

					return (
						<button ref={buttonRef} {...buttonPropsRest} type="button" className={cn('relative', dayButtonClass)}>
							{buttonChildren}
							{today && (
								<span
									className={cn('absolute inset-x-1/2 bottom-1.5 h-0.5 w-4 -translate-x-1/2 rounded-[2px]', {
										'bg-blue-500 dark:bg-blue-500': !selected,
										'dark:!bg-gray-950 !bg-white': selected,
										'!bg-gray-400 dark:!bg-gray-600': selected && range_middle,
										'bg-gray-400 text-gray-400 dark:bg-gray-400 dark:text-gray-600': disabled,
									})}
								/>
							)}
						</button>
					)
				},
			}}
			tremor-id="tremor-raw"
			{...(props as SingleProps & RangeProps)}
		/>
	)
}

Calendar.displayName = 'Calendar'
