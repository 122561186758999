import { cva, type VariantProps } from 'class-variance-authority'
import { type ReactNode } from 'react'
import { Icon } from '#src/components/ui/icon'
import { type ClassName } from '#src/types/styles'
import { cn } from '#src/utils/misc'
import { type IconName } from '@/icon-name'

type Props = {
	icon?: IconName
	children: ReactNode
	className?: ClassName
} & VariantProps<typeof variants>

const variants = cva('flex flex-row items-center justify-center gap-1 rounded p-2', {
	variants: {
		variant: {
			info: 'bg-brand-1-bg text-brand-1-fg',
			error: 'bg-red-10 text-red-60',
		},
	},
	defaultVariants: {
		variant: 'info',
	},
})

const AlertBanner = (props: Props) => {
	const { icon, children, variant, className } = props

	return (
		<div className={cn(variants({ variant }), className)}>
			{icon && <Icon name={icon} size="sm" className="block" />}
			{<div className="w-fit text-label-md font-normal text-neutral-2-fg">{children}</div>}
		</div>
	)
}

export default AlertBanner
