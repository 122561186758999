import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { type TooltipProps } from '@radix-ui/react-tooltip'
import { cva, type VariantProps } from 'class-variance-authority'
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, type ReactNode } from 'react'
import { type ClassName } from '#src/types/styles'
import { cn } from '#src/utils/misc'

const TooltipProvider = TooltipPrimitive.Provider

const Tooltip = TooltipPrimitive.Root

const TooltipTrigger = TooltipPrimitive.Trigger

const tooltipContentVariants = cva(
	'z-50 overflow-hidden rounded-sm px-3 py-2 text-body-sm text-neutral-inverse-fg zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
	{
		variants: {
			variant: {
				default: 'bg-neutral-2-fg',
				secondary: 'bg-white shadow-sm',
			},
		},
		defaultVariants: {
			variant: 'default',
		},
	},
)

const tooltipArrowVariants = cva('', {
	variants: {
		variant: {
			default: 'fill-neutral-2-fg',
			secondary: 'fill-white',
		},
	},
	defaultVariants: {
		variant: 'default',
	},
})

const TooltipContent = forwardRef<
	ElementRef<typeof TooltipPrimitive.Content>,
	ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> & VariantProps<typeof tooltipContentVariants>
>(({ className, sideOffset = 4, children, variant, ...props }, ref) => (
	<TooltipPrimitive.Portal>
		<TooltipPrimitive.Content
			ref={ref}
			sideOffset={sideOffset}
			className={cn(tooltipContentVariants({ variant }), className)}
			{...props}
		>
			{children}
			<TooltipPrimitive.Arrow className={cn(tooltipArrowVariants({ variant }))} />
		</TooltipPrimitive.Content>
	</TooltipPrimitive.Portal>
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName
const CustomTooltip = ({
	children,
	label,
	delayDuration = 0,
	side = 'top',
	align = 'center',
	rootProps,
	noChildrenWrapper,
	sideOffset,
	disabled,
	variant,
	className,
}: {
	rootProps?: TooltipProps
	children: ReactNode
	label: ReactNode
	delayDuration?: number
	side?: 'top' | 'right' | 'bottom' | 'left'
	align?: 'start' | 'center' | 'end'
	noChildrenWrapper?: boolean
	sideOffset?: number
	disabled?: boolean
	variant?: VariantProps<typeof tooltipContentVariants>['variant']
	className?: ClassName
}) => (
	<TooltipProvider delayDuration={delayDuration}>
		<Tooltip {...rootProps}>
			<TooltipTrigger asChild>{noChildrenWrapper ? children : <span>{children}</span>}</TooltipTrigger>
			{!disabled && (
				<TooltipContent
					side={side}
					align={align}
					sideOffset={sideOffset}
					variant={variant}
					className={cn('max-w-[400px] whitespace-normal break-words', className)}
				>
					{label}
				</TooltipContent>
			)}
		</Tooltip>
	</TooltipProvider>
)

export { CustomTooltip, Tooltip, TooltipTrigger, TooltipContent, TooltipProvider }
