import { Fragment, type ReactNode, type TdHTMLAttributes, useState } from 'react'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { type ClassName } from '#src/types/styles'
import { cn } from '#src/utils/misc'

export type TableColumn<T> = {
	name: string
	hidden?: boolean
	heading: {
		onClick?: () => void
		className?: ClassName
		title: ReactNode
	}
	body: {
		className?: ClassName
		render: (data: T) => ReactNode
		colSpan?: number
	}
}

type TableExpandableColumn<T> = Omit<TableColumn<T>, 'heading'>

type TableProps<T> = {
	stickyHeader?: boolean
	filtersComponent?: ReactNode
	noDataComponent?: ReactNode
	expandable?: boolean
	columns: TableColumn<T>[]
	expandableColumns?: TableExpandableColumn<T>[]
	data: T[]
	onItemClick?: (data: T) => void
} & (
	| { expandable?: false; expandableColumns?: TableExpandableColumn<T>[] }
	| {
			expandable: true
			expandableColumns: TableExpandableColumn<T>[]
			// eslint-disable-next-line no-mixed-spaces-and-tabs
	  }
)

export function Table<T extends { id: number | string }>(props: TableProps<T>) {
	const { expandable, columns, data, expandableColumns, onItemClick, stickyHeader, filtersComponent, noDataComponent } =
		props
	const clickable = typeof onItemClick !== 'undefined'
	const [opened, setOpened] = useState<T['id'][]>([])

	return (
		<table className="w-full table-auto border-separate border-spacing-0 border-b border-neutral-1-bd text-left">
			<thead className={cn(stickyHeader && 'sticky left-0 top-0 z-10 w-full')}>
				{filtersComponent && (
					<tr className="bg-white">
						<Cell as="th" colSpan={columns.length + (expandable ? 1 : 0)} className="w-auto border-t-0">
							{filtersComponent}
						</Cell>
					</tr>
				)}
				{!!data.length && (
					<tr className="table-header group border-t bg-neutral-2-bg">
						{expandable && <Cell as="th" className="w-9 text-label-sm text-neutral-3-fg" />}
						{columns
							.filter(col => !col.hidden)
							.map(col => (
								<Cell
									key={col.name}
									as="th"
									className={cn('text-label-sm text-neutral-3-fg', col.heading.className)}
									onClick={col.heading.onClick}
								>
									{col.heading.title}
								</Cell>
							))}
					</tr>
				)}
			</thead>
			<tbody>
				{data.length ? (
					data.map(item => (
						<Fragment key={item.id}>
							<tr
								className={cn('bg-neutral-1-bg transition-colors hover:bg-neutral-2-bg', clickable && 'cursor-pointer')}
								onClick={e => {
									e.preventDefault()
									e.stopPropagation()
									onItemClick?.(item)
								}}
							>
								{expandable && (
									<Cell
										className="group text-body-md font-medium text-neutral-1-fg"
										data-state="open"
										onClick={e => {
											e.preventDefault()
											e.stopPropagation()
											setOpened(curr => {
												if (curr.includes(item.id)) {
													return curr.filter(i => i !== item.id)
												} else {
													return [...opened, item.id]
												}
											})
										}}
									>
										<Button variant="icon" size="icon">
											<Icon
												name="chevron-down"
												size="sm"
												className={cn('transition-all', opened.includes(item.id) && 'rotate-180')}
											/>
										</Button>
									</Cell>
								)}
								{columns
									.filter(col => !col.hidden)
									.map(col => (
										<Cell
											key={`${item.id}-${col.name}`}
											className={cn('text-body-md font-medium text-neutral-1-fg', col.body.className)}
										>
											{col.body.render?.(item)}
										</Cell>
									))}
							</tr>
							{expandable && (
								<tr
									className={cn(
										'bg-neutral-1-bg transition-colors hover:bg-neutral-2-bg',
										!opened.includes(item.id) && 'hidden',
									)}
								>
									{expandableColumns.map(col => (
										<Cell
											key={`${item.id}-${col.name}`}
											className={cn('text-body-md font-medium text-neutral-1-fg', col.body.className)}
											colSpan={col.body.colSpan}
										>
											{col.body.render?.(item)}
										</Cell>
									))}
								</tr>
							)}
						</Fragment>
					))
				) : (
					<tr>
						<Cell>{noDataComponent ?? 'No data'}</Cell>
					</tr>
				)}
			</tbody>
		</table>
	)
}

const Cell = ({
	as = 'td',
	colSpan,
	className,
	children,
	onClick,
}: {
	as?: 'td' | 'th'
	colSpan?: number
	className?: string
	children?: ReactNode
	onClick?: TdHTMLAttributes<HTMLTableCellElement>['onClick']
}) => {
	const Slot = as
	return (
		<Slot
			className={cn(
				'h-full px-6 py-3',
				'border-t border-neutral-1-bd last:text-right group-[.table-header]:border-t-0',
				className,
				onClick ? 'cursor-pointer' : '',
			)}
			onClick={onClick}
			colSpan={colSpan}
		>
			{children}
		</Slot>
	)
}
