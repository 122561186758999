import { type RouteObject } from 'react-router'
import { action as createAction } from '#src/routes/enable/plays/templates/actions/create'
import { action as deleteAction } from '#src/routes/enable/plays/templates/actions/delete'
import { action as editAction } from '#src/routes/enable/plays/templates/actions/edit'
import { loader as createLoader } from '#src/routes/enable/plays/templates/loaders/create'
import { loader as itemLoader } from '#src/routes/enable/plays/templates/loaders/item'
import { loader as listLoader } from '#src/routes/enable/plays/templates/loaders/list'
import { View as CreateView } from '#src/routes/enable/plays/templates/views/create'
import { View as DeleteView } from '#src/routes/enable/plays/templates/views/delete'
import { View as EditView } from '#src/routes/enable/plays/templates/views/edit'
import { View as ListView } from '#src/routes/enable/plays/templates/views/list'
import { View as PreviewView } from '#src/routes/enable/plays/templates/views/preview'

const playsTemplatesRouter = {
	path: 'templates',
	loader: listLoader,
	element: <ListView />,
	children: [
		{
			path: 'new',
			loader: createLoader,
			action: createAction,
			element: <CreateView />,
		},
		{
			path: ':templateId',
			loader: itemLoader,
			children: [
				{
					path: 'edit',
					action: editAction,
					element: <EditView />,
				},
				{
					path: 'delete',
					action: deleteAction,
					element: <DeleteView />,
				},
				{
					path: 'preview',
					element: <PreviewView />,
				},
			],
		},
	],
} satisfies RouteObject

export default playsTemplatesRouter
