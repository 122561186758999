import { forwardRef, type InputHTMLAttributes, useRef, useImperativeHandle, type ReactNode } from 'react'
import { Icon, type IconName } from '#src/components/ui/icon'
import { inputCommonClasses } from '#src/theme'
import { cn, isIconName } from '#src/utils/misc'

export type NumberInputProps = InputHTMLAttributes<HTMLInputElement> & {
	step?: number
	min?: number
	max?: number
	iconLeft?: IconName | Exclude<ReactNode, string>
	iconRight?: IconName | Exclude<ReactNode, string>
}

const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
	({ className, iconLeft, iconRight, min, max, ...props }, ref) => {
		const inputRef = useRef<HTMLInputElement | null>(null)
		useImperativeHandle(ref, () => inputRef.current!, [inputRef])

		const dispatchEvent = () => {
			if (inputRef.current) {
				const event = new Event('input', { bubbles: true })
				inputRef.current.dispatchEvent(event)
			}
		}

		const increment = () => {
			if (inputRef.current) {
				inputRef.current.stepUp()
				dispatchEvent()
			}
		}

		const decrement = () => {
			if (inputRef.current) {
				inputRef.current.stepDown()
				dispatchEvent()
			}
		}

		return (
			<div className="relative flex w-full items-center">
				{iconLeft && (
					<div className="absolute left-3 flex items-center text-neutral-4-fg">
						{isIconName(iconLeft) ? (
							<Icon name={iconLeft} className="text-body-md" />
						) : (
							<span className="text-body-sm">{iconLeft}</span>
						)}
					</div>
				)}
				<input
					type="number"
					className={cn(inputCommonClasses, iconLeft && 'pl-8', iconRight && 'pr-14', className)}
					ref={inputRef}
					min={min}
					max={max}
					{...props}
				/>
				<div className="absolute right-3 top-1/2 flex -translate-y-1/2 transform gap-2 text-body-md text-neutral-4-fg">
					{iconRight &&
						(isIconName(iconRight) ? (
							<Icon name={iconRight} className="text-body-md" />
						) : (
							<span className="flex items-center text-body-sm">{iconRight}</span>
						))}
					{!props.disabled && (
						<div className="flex flex-col items-center -space-y-1">
							<button
								type="button"
								onClick={increment}
								className="flex items-center justify-center text-neutral-3-fg hover:text-neutral-4-fg"
							>
								<Icon name="chevron-down" className="rotate-180 text-body-md" />
							</button>
							<button
								type="button"
								onClick={decrement}
								className="flex items-center justify-center text-neutral-3-fg hover:text-neutral-4-fg"
							>
								<Icon name="chevron-down" className="text-body-md" />
							</button>
						</div>
					)}
				</div>
			</div>
		)
	},
)

NumberInput.displayName = 'NumberInput'

export { NumberInput }
