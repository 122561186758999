import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { adminApi } from '#src/utils/endpoints'
import { buildAPISearchParams } from '#src/utils/requests'

const base = `${adminApi}/company`

const endpoints = {
	create: () => base,
	delete: (companyId: z.infer<typeof CompanySchema>['id']) => `${base}/${companyId}`,
	updateDashboard: (companyId: z.infer<typeof CompanySchema>['id']) => `${base}/${companyId}/dashboard`,
	updateBilling: (companyId: z.infer<typeof CompanySchema.shape.id>) => `${base}/${companyId}/billing`,
	transactions: (companyId: z.infer<typeof CompanySchema.shape.id>, page: number) => {
		const searchParams = buildAPISearchParams({
			page,
		})
		return `${base}/${companyId}/transaction/list?${searchParams.toString()}`
	},
	addTransaction: (companyId: z.infer<typeof CompanySchema.shape.id>) => `${base}/${companyId}/transaction`,
}

export { endpoints as adminCompanyEndpoints }
