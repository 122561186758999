import { useLoaderData } from 'react-router'
import { FormField } from '#src/components/forms/v2/FormField'
import { type CriteriaSaveLoaderType } from '#src/routes/calibrate/ecosystem-management/criteria/routes/save'

export const FormLLMSection = () => {
	const {
		data: { llmModels },
	} = useLoaderData<CriteriaSaveLoaderType>()
	return (
		<FormField
			fieldType="select"
			name="llmModel"
			label="Select LLM model"
			options={llmModels.map(model => ({
				value: model.name,
				label: model.name,
				description: model.description,
			}))}
			size="lg"
		/>
	)
}
