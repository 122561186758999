import { type MODAL_NAME } from '#src/constants/modals'

enum POST_MESSAGE_TYPES {
	Modal = 'modal',
}

export const sendMessage = (type: POST_MESSAGE_TYPES, args: Record<string, unknown>) => {
	if (!window) {
		return
	}

	window.postMessage({ type, ...args })
}

/** @warning If you are calling DELETE, use it above delete method, because when it invalidates keys it refetches and gets 404 for entity */
export const closeModalServer = (name: MODAL_NAME, redirect?: string) =>
	sendMessage(POST_MESSAGE_TYPES.Modal, { name, redirect })
