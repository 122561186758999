import { type LoaderFunctionArgs, redirect } from 'react-router'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { companyId } = await getCompany(params)
	validateRouteParams(params, ['conversationId'])

	throw redirect(
		routes.enable.roleplay.session.attempts({
			companyId,
			conversationId: params.conversationId,
		}),
	)
}
