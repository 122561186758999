import { Button } from '#src/components/ui/button'
import { cn } from '#src/utils/misc'

type Props = {
	items: {
		value: string
		label: string
		counter?: number
	}[]
	selected: string
	onSelect: (value: string) => void
}

const ListItems = (props: Props) => {
	const { items, selected, onSelect } = props

	return (
		<div className="flex flex-col gap-0.5">
			{items.map(item => (
				<Button
					variant="none"
					key={`list-item-${item.value}`}
					onClick={() => onSelect(item.value)}
					className={cn(
						'flex flex-row items-center justify-between gap-2 text-left text-neutral-2-fg hover:bg-neutral-1-bg-hover',
						selected === item.value && 'bg-neutral-1-bg-selected text-neutral-1-fg',
					)}
				>
					<p>{item.label}</p>
					{'counter' in item && <p className="text-label-sm">{item.counter}</p>}
				</Button>
			))}
		</div>
	)
}

export default ListItems
