import { z } from 'zod'
import { zString } from '#src/utils/validation'

export const AccountExportStatisticsAPISchema = z.object({
	inQueueCount: z.number(),
	inProgressCount: z.number(),
	completedCount: z.number(),
})

export enum ACCOUNT_EXPORT_JOB_STATUS {
	Completed = 'COMPLETED',
	InProgress = 'IN_PROGRESS',
	InQueue = 'IN_QUEUE',
	Error = 'FAILED',
}

export const AccountExportListItemAPISchema = z.object({
	id: z.number(),
	totalResults: z.number().nullable(),
	filters: z.record(z.string(), z.array(z.string()).or(z.string())),
	status: z.nativeEnum(ACCOUNT_EXPORT_JOB_STATUS),
	createdAt: z.string(),
})

export const AccountExportCreateSettingsAPISchema = z.object({
	exportLimit: z.number(),
	filters: z.array(
		z
			.object({
				label: z.string(),
				name: z.string(),
			})
			.and(
				z
					.object({
						type: z.enum(['text', 'date', 'number']),
					})
					.or(
						z.object({
							type: z.enum(['select', 'multi-select']),
							options: z.array(z.string()),
						}),
					),
			),
	),
})

export const AccountExportFormSchema = z.object({
	filters: z.array(
		z.object({
			name: zString(),
			value: z.array(zString()).nonempty().or(zString()),
		}),
	),
})

export const AccountExportValidateAPISchema = z.object({
	totalResults: z.number(),
	totalCredits: z.number(),
})

export const AccountExportItemDownloadAPISchema = z.object({
	downloadUrl: z.string(),
})
