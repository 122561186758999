import { type LoaderFunctionArgs } from 'react-router'
import { companyPersonaQueries } from '#src/api/icp/company/persona/queries'
import { playsQueries } from '#src/api/icp/company/plays/queries'
import { verticalsQueries } from '#src/api/icp/company/verticals/queries'
import { client } from '#src/main'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type LoaderResType = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	validateRouteParams(params, ['verticalId', 'personaId'])

	const [vertical, persona, plays, intents] = await Promise.all([
		client.fetchQuery(verticalsQueries.item(company.id, Number(params.verticalId))),
		client.fetchQuery(companyPersonaQueries.item(company.id, Number(params.personaId))),
		client.fetchQuery(playsQueries.list(company.id)),
		client.fetchQuery(companyPersonaQueries.signals(company.id, Number(params.personaId))),
	])

	return {
		persona,
		vertical,
		plays: plays.filter(
			item =>
				persona.expertise &&
				persona.type &&
				item.expertise.includes(persona.expertise) &&
				item.personaTypes.includes(persona.type),
		),
		intents,
	}
}
