import { captureException } from '@sentry/react'
import { type ActionFunctionArgs, redirect } from 'react-router'
import { userMutations } from '#src/api/icp/company/user/mutations'
import { showToast } from '#src/context/ToastContext'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ params }: ActionFunctionArgs) => {
	validateRouteParams(params, ['userId'])
	const { company, companyId } = await getCompany(params)

	try {
		await userMutations.activate(company.id, params.userId)

		showToast({
			message: `User successfully activated`,
			type: 'success',
		})

		return redirect(routes.settings.team.index({ companyId }))
	} catch (error) {
		captureException(error)

		showToast({
			message: `Failed to activate user`,
			type: 'error',
		})

		return null
	}
}
