import { z } from 'zod'

export const EcosystemsCriteriaItemAPISchema = z.object({
	id: z.number(),
	name: z.string(),
	type: z.string(),
	ecosystems: z.array(
		z.object({
			id: z.number(),
			name: z.string(),
		}),
	),
})

export const EcosystemsCriteriaListAPISchema = EcosystemsCriteriaItemAPISchema.array()
