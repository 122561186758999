import { type LoaderFunctionArgs } from 'react-router'
import { companyChatRoleplayScenarioQueries } from '#src/api/icp/company/chat/roleplay/scenario/queries'
import { ScenarioFiltersAPISchema } from '#src/api/icp/company/chat/roleplay/scenario/schemas'
import { client } from '#src/main'
import { paramsToObjectSafe } from '#src/utils/requests'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	const page = parseInt(new URL(request.url).searchParams.get('page') ?? '1')
	const filters = paramsToObjectSafe(new URL(request.url).searchParams, ScenarioFiltersAPISchema)

	return {
		data: {
			filters,
			scenarios: await client.fetchQuery(companyChatRoleplayScenarioQueries.list(company.id, page, filters)),
		},
	}
}
