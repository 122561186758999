import { useContext } from 'react'
import { ModalsContext, type ModalsContextType } from '#src/context/ModalsContext'

export const useModals = (): ModalsContextType => {
	const context = useContext(ModalsContext)
	if (!context) {
		throw new Error('ModalsContext does not exist. Check if your components is not using it outside the provider')
	}

	return context
}
