import { type LoaderFunctionArgs } from 'react-router'
import { client } from '#src/main'
import { contactQuery } from '#src/routes/enable/chat/queries'
import { validateRouteParams } from '#src/utils/misc'
import { getUser } from '#src/utils/server/user'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	validateRouteParams(params, ['contactId'])

	const { readOnlySession } = await getUser()
	if (readOnlySession) {
		throw new Response('Page Not Found', {
			status: 404,
			statusText: 'Not Found',
		})
	}

	return {
		data: {
			conversation: await client.fetchQuery(contactQuery(params.contactId)),
		},
	}
}
