import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { NewValuePropositionFormSchema } from '#src/api/icp/company/value-proposition/schemas'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import { Dialog } from '#src/components/ui/dialog'
import useCompany from '#src/hooks/useCompany'
import { getWordCount, useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

export const NewPointModal = () => {
	const { companyId } = useCompany()
	const formProps = useForm<typeof NewValuePropositionFormSchema>({
		resolver: zodResolver(NewValuePropositionFormSchema),
	})
	const isPending = useIsPending()
	const formId = 'addNewPoint'
	const closeTo = routes.calibrate.valueProposition.index({ companyId })

	const words = getWordCount(formProps.watch('description') ?? '')

	return (
		<Dialog
			open={true}
			dialogHeading="Add New Point"
			closeTo={closeTo}
			size="lg"
			actions={[
				{
					label: 'Cancel',
					type: 'cancel',
					to: closeTo,
				},
				{
					label: 'Add',
					type: 'submit',
					formId,
				},
			]}
			isSubmitting={isPending}
		>
			<FormWrapper formProps={formProps} formId={formId} className="flex flex-col gap-4">
				<FormField fieldType="text" name="name" />
				<FormField fieldType="markdown" name="description" label={`Description (${words} words)`} />
			</FormWrapper>
		</Dialog>
	)
}
