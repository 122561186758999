import { type LoaderFunctionArgs } from 'react-router'
import { type z } from 'zod'
import { client } from '#src/main'
import { type CRITERION_TYPE_ENUM } from '#src/routes/calibrate/ecosystem-management/criteria/constants'
import { ECOSYSTEM_TYPE } from '#src/routes/calibrate/ecosystem-management/ecosystem/constants'
import { ecosystemQuery } from '#src/routes/calibrate/ecosystem-management/ecosystem/queries'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type LoaderResType = Awaited<ReturnType<ReturnType<typeof loader>>>

export const loader =
	(type: z.infer<typeof CRITERION_TYPE_ENUM>) =>
	async ({ request, params }: LoaderFunctionArgs) => {
		validateRouteParams(params, ['ecosystemId'])
		const { companyId, company } = await getCompany(params)
		const url = new URL(request.url)
		const redirectTo = url.searchParams.get('to')
		const ecosystem = await client.fetchQuery(ecosystemQuery(company.id, params.ecosystemId))
		const isCriteriaSandbox = ecosystem.type === ECOSYSTEM_TYPE.CRITERIA_SANDBOX
		const isDuplicating = request.url.includes('duplicate')

		return {
			values: {
				companyId,
				ecosystemId: params.ecosystemId,
				type,
				redirectTo,
				key: new Date().getTime(),
				isCriteriaSandbox,
				isDuplicating,
			},
		}
	}
