import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { baseApi } from '#src/utils/endpoints'

const base = (companyId: z.infer<typeof CompanySchema>['id']) => `${baseApi}/company/${companyId}/ecosystem`

const endpoints = {
	criteria: (companyId: z.infer<typeof CompanySchema.shape.id>) => `${base(companyId)}/criteria`,
}

export { endpoints as companyEcosystemsEndpoints }
