import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useMemo, useState } from 'react'
import { type FieldErrors, get, useForm } from 'react-hook-form'
import { useFetcher, useNavigate, useSearchParams } from 'react-router'
import { type z } from 'zod'
import {
	AccountEnrichmentJobFormSchema,
	DATA_ENRICHMENT_JOB_ENRICHERS,
} from '#src/api/organise/data-enrichment/workflows/schemas'
import { EmptyStateCard } from '#src/components'
import AlertBanner from '#src/components/AlertBanner'
import { Chip } from '#src/components/chip'
import { FieldError } from '#src/components/forms/v2/FieldError'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import LoaderRequiredComponent from '#src/components/LoaderRequiredComponent'
import { FormFieldTypeChip } from '#src/components/shared'
import { Button } from '#src/components/ui/button'
import { Dialog } from '#src/components/ui/dialog'
import { FormStepper } from '#src/components/ui/FormStepper'
import { Icon } from '#src/components/ui/icon'
import { Switch } from '#src/components/ui/switch'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { type ActionRes } from '#src/routes/organize/account-enrichment/actions/create'
import { FORM_STEPS } from '#src/routes/organize/account-enrichment/constants'
import { type LoaderRes } from '#src/routes/organize/account-enrichment/loaders/create'
import { type APIFieldType } from '#src/schemas/global'
import { getFormErrorAsMessage } from '#src/utils/forms'
import { routes } from '#src/utils/routes'

export const AccountEnrichmentCreateModal = () => {
	const { companyId } = useCompany()

	return (
		<LoaderRequiredComponent<LoaderRes>
			route={routes.organize.accountEnrichment.create({ companyId })}
			component={ModalInner}
		/>
	)
}

const FORM_NAME = 'account-enrichment-job-create'

export const ModalInner = (props: LoaderRes) => {
	const {
		data: { criteria },
	} = props
	const { company, companyId } = useCompany()

	const actionFetcher = useFetcher<ActionRes>()
	const actionData = actionFetcher.data

	const navigate = useNavigate()

	const [step, setStep] = useState<FORM_STEPS>(FORM_STEPS.File)

	const form = useForm<z.infer<typeof AccountEnrichmentJobFormSchema>>({
		resolver: zodResolver(AccountEnrichmentJobFormSchema),
		defaultValues: {
			companyId: company.id,
			enrichers: [DATA_ENRICHMENT_JOB_ENRICHERS.EnrichmentCriteria],
			comment: null,
			metadata: {
				enrichmentCriteriasIds: criteria.map(item => item.id),
			},
		},
		mode: 'onSubmit',
	})

	useEffect(() => {
		const failedStep = actionData && 'failedStep' in actionData ? actionData?.failedStep : undefined
		if (failedStep) {
			setStep(failedStep)
		}
		const errors = actionData && 'errors' in actionData ? actionData.errors : undefined
		if (errors) {
			Object.entries(errors).forEach(([fieldName, errorMessages]) => {
				form.setError(fieldName as keyof z.infer<typeof AccountEnrichmentJobFormSchema>, {
					type: 'server',
					message: errorMessages.join(', '),
				})
			})
		}
	}, [actionData, form])

	const fileWatch = form.watch('file')

	const enrichmentCriteriasIdsErrors = get(form.formState.errors, 'metadata.enrichmentCriteriasIds') as
		| FieldErrors
		| FieldErrors[]
		| undefined

	const enrichmentCriteriasErrors = useMemo(
		() => getFormErrorAsMessage(enrichmentCriteriasIdsErrors),
		[enrichmentCriteriasIdsErrors],
	)

	useEffect(() => {
		form.setValue('title', fileWatch instanceof File ? fileWatch.name : '')
	}, [form, fileWatch])

	const handleContinue = async () => {
		switch (step) {
			case FORM_STEPS.File: {
				await form.trigger('file')
				if (!('file' in form.formState.errors)) {
					setStep(FORM_STEPS.EnrichmentCriteria)
				}
				break
			}
			case FORM_STEPS.EnrichmentCriteria: {
				await form.trigger('metadata.enrichmentCriteriasIds')
				if (!('metadata' in form.formState.errors)) {
					setStep(FORM_STEPS.AdditionalInfo)
				}
				break
			}
			case FORM_STEPS.AdditionalInfo: {
				await form.trigger('title')
				await form.trigger('comment')
				if (!('title' in form.formState.errors) && !('comment' in form.formState.errors)) {
					const formEl = document.getElementById(FORM_NAME) as HTMLFormElement
					formEl.requestSubmit()
				}
				break
			}
		}
	}

	const handleCancel = () => {
		switch (step) {
			case FORM_STEPS.File:
				void navigate({
					pathname: routes.organize.contactWaterfalling.index({ companyId }),
					search: searchParams.toString(),
				})
				break
			case FORM_STEPS.EnrichmentCriteria:
				setStep(FORM_STEPS.File)
				break
			case FORM_STEPS.AdditionalInfo:
				setStep(FORM_STEPS.EnrichmentCriteria)
				break
		}
	}

	const [searchParams] = useSearchParams()
	const { closeModal } = useModals()

	return (
		<Dialog
			size="lg"
			defaultOpen={true}
			onOpenChange={isOpen => !isOpen && closeModal(MODAL_NAME.AccountEnrichmentCreate)}
			dialogHeading="Create Account Enrichment Job"
			actions={[
				{
					type: 'cancel',
					label: 'Back',
					onClick: e => {
						e.preventDefault()
						handleCancel()
					},
				},
				{
					type: 'submit',
					formId: FORM_NAME,
					label: step === FORM_STEPS.AdditionalInfo ? 'Start' : 'Continue',
					onClick: e => {
						e.preventDefault()
						void handleContinue()
					},
				},
			]}
			isSubmitting={actionFetcher.state === 'submitting'}
		>
			<FormWrapper
				formId={FORM_NAME}
				formProps={form}
				method="POST"
				action={routes.organize.accountEnrichment.create({ companyId })}
				encType="multipart/form-data"
				className="flex flex-col gap-6"
				fetcher={actionFetcher}
			>
				<FormStepper
					active={step}
					steps={[
						{ name: FORM_STEPS.File, label: 'Import file' },
						{ name: FORM_STEPS.EnrichmentCriteria, label: 'Select criteria' },
						{ name: FORM_STEPS.AdditionalInfo, label: 'Additional info' },
					]}
				/>
				{step === FORM_STEPS.File && (
					<>
						<FormField
							fieldType="file"
							name="file"
							accept={{ 'text/csv': ['.csv'] }}
							label="Import CSV"
							infoContent={
								<div className="flex flex-col items-center gap-8">
									<div className="flex flex-col gap-4">
										<Icon name="upload-thin" className="block h-10 w-10 text-brand-2-fg" />
										<div className="flex flex-col gap-1">
											<p className="text-center text-title-sm text-neutral-1-fg">Drag your CSV file here</p>
											<p className="text-center text-body-md text-neutral-2-fg">
												For best results, please provide as many details as possible to ensure the highest accuracy:
											</p>
										</div>
									</div>
									<div className="flex flex-row items-center justify-center gap-1">
										<Chip variant="blue">Account Name</Chip>
										<Icon name="add" className="text-neutral-4-fg" />
										<Chip variant="blue">Domain</Chip>
									</div>
									<Button type="button">Upload</Button>
								</div>
							}
						/>
					</>
				)}
				{step === FORM_STEPS.EnrichmentCriteria && (
					<>
						{criteria.length ? (
							<div className="flex flex-col gap-3">
								{criteria.map(item => (
									<div
										key={`criteria-${item.id}`}
										className="flex flex-col overflow-hidden rounded border border-neutral-1-bd"
									>
										<div className="flex flex-row items-center p-6">
											<div className="flex-1">
												<Switch
													labelText={<span className="text-title-sm">{item.name}</span>}
													checked={form.watch('metadata.enrichmentCriteriasIds').includes(item.id)}
													onCheckedChange={val =>
														form.setValue(
															'metadata.enrichmentCriteriasIds',
															// it throws error when filtering out to empty array, because zod validation is .nonempty()
															// eslint-disable-next-line @typescript-eslint/ban-ts-comment
															// @ts-expect-error
															val
																? [...form.watch('metadata.enrichmentCriteriasIds'), item.id]
																: form
																		.watch('metadata.enrichmentCriteriasIds')
																		.filter(criteriaId => criteriaId !== item.id),
														)
													}
												/>
											</div>
											<FormFieldTypeChip type={item.type as APIFieldType} />
										</div>
										<div className="flex flex-col gap-1 bg-neutral-2-bg px-6 py-4">
											<p className="text-label-sm text-neutral-2-fg">
												Used in {item.ecosystems.length} ecosystem{item.ecosystems.length > 1 ? 's' : ''}:
											</p>
											<div className="flex flex-row flex-wrap gap-1">
												{item.ecosystems.map(ecosystem => (
													<Chip key={`criteria-${item.id}-ecosystem-${ecosystem.id}`} variant="black">
														{ecosystem.name}
													</Chip>
												))}
											</div>
										</div>
									</div>
								))}
								{enrichmentCriteriasErrors && <FieldError errorMsg={enrichmentCriteriasErrors} />}
							</div>
						) : (
							<EmptyStateCard
								title="No criteria found"
								description="Criterias does not exist, you can create them in calibrate section"
								icon="information-filled"
							/>
						)}
					</>
				)}
				{step === FORM_STEPS.AdditionalInfo && (
					<>
						<AlertBanner icon="information-filled">
							Additional info does not affect the Contact waterfalling job results
						</AlertBanner>
						<FormField name="title" fieldType="text" label="Name" placeholder="Enter job name" clearable />
						<FormField
							name="comment"
							fieldType="textarea"
							label="Note"
							placeholder="You can type extra notes about your job"
							rows={10}
						/>
					</>
				)}
			</FormWrapper>
		</Dialog>
	)
}
