import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import { cva, type VariantProps } from 'class-variance-authority'
import { type ComponentProps, type HTMLAttributes, type ReactNode, useEffect } from 'react'
import {
	selectionControlDisabledVariants,
	defaultLabelSizeVariants,
	selectionControlSizeVariants,
	selectionControlLayoutVariants,
	selectionControlSpacingVariants,
} from '#src/theme'
import { cn } from '#src/utils/misc'

const radioItemVariants = cva('peer relative rounded-full border text-white radix-state-checked:border-brand-1-bd', {
	variants: {
		size: selectionControlSizeVariants,
		disabled: selectionControlDisabledVariants,
	},
	defaultVariants: {
		size: 'md',
		disabled: false,
	},
})

const indicatorSizeVariants = cva('rounded-full bg-brand-3-bg', {
	variants: {
		size: {
			sm: 'w-1 h-1',
			md: 'w-2 h-2',
			lg: 'w-2.5 h-2.5',
		},
	},
	defaultVariants: {
		size: 'md',
	},
})

const labelSizeVariants = cva('ml-2 block text-neutral-1-fg', {
	variants: {
		size: defaultLabelSizeVariants,
		disabled: selectionControlDisabledVariants,
	},
	defaultVariants: {
		size: 'md',
		disabled: false,
	},
})

export type RadioGroupOption = {
	value: string
	label: string
	content?: ReactNode
}

export type RadioGroupProps = {
	options: RadioGroupOption[]
	value?: string
	onChange?: (value: string) => void
	size?: VariantProps<typeof radioItemVariants>['size']
	layout?: keyof typeof selectionControlLayoutVariants
	spacing?: keyof (typeof selectionControlSpacingVariants)['horizontal']
	disabled?: boolean
	selectFirstByDefault?: boolean
	itemWrapperProps?: HTMLAttributes<HTMLDivElement>
} & ComponentProps<typeof RadioGroupPrimitive.Root>

export const RadioGroup = ({
	options = [],
	value,
	onChange,
	size = 'md',
	disabled,
	selectFirstByDefault,
	layout = 'vertical',
	spacing = 'md',
	itemWrapperProps,
	...rest
}: RadioGroupProps) => {
	useEffect(() => {
		if (selectFirstByDefault && !value && options.length) {
			if (onChange) {
				onChange(options[0].value)
			}
		}
	}, [onChange, options, selectFirstByDefault, value])

	return (
		<RadioGroupPrimitive.Root value={value} onValueChange={onChange} disabled={disabled} className="w-full" {...rest}>
			<div className={cn(selectionControlLayoutVariants[layout], selectionControlSpacingVariants[layout][spacing])}>
				{options.map(
					option =>
						option.value !== '-' && (
							<div key={option.value} {...itemWrapperProps}>
								<div className="flex items-center">
									<RadioGroupPrimitive.Item
										id={option.value}
										value={option.value}
										disabled={disabled}
										className={cn(
											radioItemVariants({ size, disabled }),
											'border-2 radix-state-unchecked:border-neutral-3-bd radix-state-unchecked:bg-transparent',
										)}
									>
										<RadioGroupPrimitive.Indicator className="leading-0 absolute inset-0 flex items-center justify-center">
											<div className={cn(indicatorSizeVariants({ size }))} />
										</RadioGroupPrimitive.Indicator>
									</RadioGroupPrimitive.Item>
									<label htmlFor={option.value} className={cn(labelSizeVariants({ size, disabled }))}>
										{option.label}
									</label>
								</div>
								{option.value === value && option.content && <div className="px-6 pb-2 pt-2.5">{option.content}</div>}
							</div>
						),
				)}
			</div>
		</RadioGroupPrimitive.Root>
	)
}
