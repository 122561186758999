import { useCallback, useMemo } from 'react'
import { Link, useLoaderData } from 'react-router'
import { type z } from 'zod'
import { EmptyStateCard } from '#src/components'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { TabSwitcher } from '#src/components/ui/tabs'
import useCompany from '#src/hooks/useCompany'
import { type CriteriaListLoaderType } from '#src/routes/calibrate/ecosystem-management/criteria/routes/list'
import { type CriterionQuestionAPISchema } from '#src/routes/calibrate/ecosystem-management/criteria/schema'
import { routes } from '#src/utils/routes'
import { SelectableCriterionCard } from './components'

export const View = () => {
	const {
		values: { ecosystemId, type },
		data: { externalCriteriaList, criteriaTemplatesList, criteriaList, sandboxCriteriaList },
	} = useLoaderData<CriteriaListLoaderType>()
	const { companyId } = useCompany()

	const renderTabContent = useCallback(
		(items: typeof criteriaList, tab: string, showEcosystems?: boolean) => {
			const getCriterionLinkProps = (tab: string, criterion: z.infer<typeof CriterionQuestionAPISchema>) => {
				switch (tab) {
					case '3rd-party':
						return {
							to: routes.calibrate.criteria.details({
								companyId,
								ecosystemId,
								criterionType: type,
								criterionId: String(criterion.id),
							}),
						}
					case 'templates':
						return {
							to: routes.calibrate.criteria.add({
								companyId,
								ecosystemId,
								criterionType: type,
								criterionId: String(criterion.id),
							}),
						}
					case 'sandbox-criteria':
						return {
							to: routes.calibrate.criteria.add({
								companyId,
								ecosystemId,
								criterionType: type,
								criterionId: String(criterion.id),
							}),
						}
					case 'in-use':
						return {
							to: routes.calibrate.criteria.add({
								companyId,
								ecosystemId,
								criterionType: type,
								criterionId: String(criterion.id),
							}),
						}
					default:
						return {
							to: '#',
						}
				}
			}

			return (
				<section className="flex flex-col gap-2">
					{items.map(item => (
						<SelectableCriterionCard
							key={item.id}
							heading={item.name}
							description={item.source}
							fieldType={item.type}
							ecosystemsInUse={showEcosystems ? item.ecosystems : []}
							linkProps={getCriterionLinkProps(tab, item)}
						/>
					))}
				</section>
			)
		},
		[companyId, ecosystemId, type],
	)

	const tabs = useMemo(() => {
		return [
			externalCriteriaList.length && {
				label: '3rd Party',
				value: '3rd-party',
				content: renderTabContent(externalCriteriaList, '3rd-party'),
			},
			criteriaTemplatesList.length && {
				label: 'Templates',
				value: 'templates',
				content: renderTabContent(criteriaTemplatesList, 'templates'),
			},
			sandboxCriteriaList.length && {
				label: 'Sandbox Criteria',
				value: 'sandbox-criteria',
				content: renderTabContent(sandboxCriteriaList, 'sandbox-criteria', true),
			},
			criteriaList.length && {
				label: 'Criteria In Use',
				value: 'in-use',
				content: renderTabContent(criteriaList, 'in-use', true),
			},
		].filter(Boolean)
	}, [externalCriteriaList, renderTabContent, criteriaTemplatesList, sandboxCriteriaList, criteriaList])

	return (
		<>
			{tabs.length ? (
				<>
					<div className="relative">
						<TabSwitcher tabs={tabs} scrollAreaProps={{ maxHeight: 'calc(90vh - 180px)' }} contentScrollable />
						<Button size="sm" asChild className="absolute right-0 top-0 gap-1">
							<Link to={routes.calibrate.criteria.new({ companyId, ecosystemId, criterionType: type })}>
								<Icon name="add" />
								Create New
							</Link>
						</Button>
					</div>
				</>
			) : (
				<EmptyStateCard
					icon="list-checked"
					title="No criteria available for selection."
					className="flex min-h-full flex-col"
					actions={[
						{
							title: 'Create New',
							to: routes.calibrate.criteria.new({ companyId, ecosystemId, criterionType: type }),
						},
					]}
				/>
			)}
		</>
	)
}
