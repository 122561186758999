import { zodResolver } from '@hookform/resolvers/zod'
import { type ReactNode, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { ExpandableTree } from '#src/components/ExpandableTree'
import CheckboxTreeInput from '#src/components/forms/CheckboxTreeInput'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import { Button } from '#src/components/ui/button'
import { Dialog } from '#src/components/ui/dialog'
import { FullPageAccordion } from '#src/components/ui/FullPageAccordion'
import { InlineError } from '#src/components/ui/InlineError'
import { InlineSpinner } from '#src/components/ui/InlineSpinner'
import { PageLayout } from '#src/components/ui/PageLayout'
import Paginator from '#src/components/ui/Paginator'
import { MODAL_NAME } from '#src/constants/modals'
import { EMOTIONAL_STATE_OPTIONS } from '#src/constants/options'
import { useModals } from '#src/hooks/useModals'
import { LeadResearchAgentJobCreateModal } from '#src/modals/lead-research-agent/Create'
import { PlayCreateModal } from '#src/modals/plays/PlayCreate'
import { checkboxTreeViewDataMock, expandableTreeDataMock } from '#src/routes/developers/mock'
import { routes } from '#src/utils/routes'
import usePaginator from '#src/utils/usePaginator'
import { nonEmptyArray, RegionsSelectFieldSchema, zNumber, zString } from '#src/utils/validation'

type PlayComponentProps = {
	heading: string
	children: ReactNode
}

const PlayComponent = ({ children, heading }: PlayComponentProps) => (
	<>
		<h3 className="text-title-sm">{heading}</h3>
		{children}
		<hr className="w-full border-t border-neutral-1-bd" />
	</>
)

export const playgroundSchema = z.object({
	textField: zString(),
	radioGroup: zString(),
	multiselect: nonEmptyArray(),
	switch: z.boolean().optional(),
	cardSwitch: z.boolean().optional(),
	checkboxGroup: nonEmptyArray(),
	textareaField: z.string().optional(),
	textareaFieldHideable: zString(),
	selectField: zString(),
	numberField: zNumber(z.number().min(1)),
	rangeInputField: z.array(zString()),
	regionsSelectField: RegionsSelectFieldSchema,
	dateField: z.string().datetime(),
	dateRangeField: z.array(z.string().datetime()),
	sliderField: zNumber(z.number().min(1).max(10)),
})

export const Playground = () => {
	const { currentPage, setCurrentPage, totalPages } = usePaginator({
		totalItems: 15,
	})
	const [checkboxTreeValue, setCheckboxTreeValue] = useState<string[]>(['1-1', '1-2', '3-1'])

	const formProps = useForm({
		// resolver: zodResolver(schema),
		resolver: async (values, context, options) => {
			const createResolver = zodResolver(playgroundSchema)
			const errors = await createResolver(values, context, options)
			console.log('zodErrorrs', errors)
			return createResolver(values, context, options)
		},
		defaultValues: {
			textField: 'Web Developer',
			numberField: 250,
			checkboxGroup: ['wifi'],
			multiselect: ['orange'],
			textareaFieldHideable:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
			// Exclude `switch` initial value if you want to exclude untouched field from submission
			switch: false,
			cardSwitch: false,
			tree: ['1-1', '3-1'],
			dateRangeField: ['2025-01-07T22:00:00.000Z', '2025-01-07T22:00:00.000Z'],
			dateField: '2025-01-07T22:00:00.000Z',
			sliderField: 1,
		},
		mode: 'onSubmit',
		reValidateMode: 'onSubmit',
	})

	const { openModal } = useModals()

	const components = [
		{
			heading: 'Modal',
			children: (
				<div className="flex flex-col items-start gap-4">
					<Dialog
						trigger={
							<div>
								<Button>Open Modal</Button>
							</div>
						}
						dialogHeading="Dialog (Modal)"
						dialogDescription="Description of the dialog content."
						footerInfo="Additional info in the footer."
						actions={[
							{ type: 'cancel', label: 'Go Back' },
							{ type: 'submit', label: 'Confirm', onClick: () => console.log('Trigger') },
						]}
						contentProps={{
							className: 'w-[500px]',
						}}
					>
						<div className="flex flex-col gap-3">
							<h2>Main content of the dialog.</h2>
							<p className="text-body-sm">It can be anything.</p>
						</div>
					</Dialog>
					<Button
						onClick={() =>
							openModal({
								name: MODAL_NAME.PlayCreate,
								component: PlayCreateModal,
							})
						}
					>
						Open plays create
					</Button>
					<Button
						onClick={() =>
							openModal({
								name: MODAL_NAME.LeadResearchAgentJobCreate,
								component: LeadResearchAgentJobCreateModal,
							})
						}
					>
						Open lra create
					</Button>
				</div>
			),
		},
		{
			heading: 'Form V2',
			children: (
				<FormWrapper
					formProps={formProps}
					method="POST"
					action={routes.developers.playground()}
					className="flex flex-col gap-6 rounded border border-neutral-1-bd p-10"
				>
					<FormField fieldType="slider" name="sliderField" min={1} max={10} enableToggle />
					<FormField fieldType="dateRange" name="dateRangeField" enableToggle />
					<FormField fieldType="date" name="dateField" enableToggle />
					<FormField fieldType="regions" name="regionsSelectField" enableToggle />
					<FormField
						fieldType="range"
						name="rangeInputField"
						enableToggle
						fromInputProps={{
							iconLeft: 'user-multiple',
						}}
						toInputProps={{
							iconLeft: 'max',
							className: 'pl-10',
						}}
					/>
					<FormField fieldType="text" name="textField" clearable enableToggle />
					<FormField fieldType="textarea" name="textareaField" enableToggle rows={4} />
					<FormField fieldType="textarea" name="textareaFieldHideable" enableToggle hideWhenToggledOff />
					<FormField
						fieldType="number"
						name="numberField"
						step={5}
						max={260}
						iconRight="dollar"
						iconLeft={<span className="pl-1.5 text-neutral-2-fg">≈</span>}
						enableToggle
					/>
					<FormField
						fieldType="select"
						options={EMOTIONAL_STATE_OPTIONS}
						name="selectField"
						selectFirstByDefault
						enableToggle
						iconRight="eu"
					/>
					<FormField
						fieldType="multiselect"
						name="multiselect"
						options={[
							{ value: 'apple', label: 'Apple' },
							{ value: 'banana', label: 'Banana' },
							{ value: 'orange', label: 'Orange' },
							{ value: 'appleJuice', label: 'Apple Juice' },
							{ value: 'bananaSmoothie', label: 'Banana Smoothie' },
							{ value: 'orangeSmoothie', label: 'Orange Smoothie' },
						]}
						enableSearch
						enableToggle
					/>
					<FormField
						fieldType="radio"
						name="radioGroup"
						options={[
							{ value: 'first', label: 'Yes' },
							{ value: 'second', label: 'No' },
							{ value: 'third', label: 'Maybe' },
							{
								value: 'withHiddenContent',
								label: 'Click to see hidden content',
								content: <FormField fieldType="textarea" name="instructions" rows={6} className="bg-white" />,
							},
						]}
						selectFirstByDefault
						enableToggle
						layout="horizontal"
					/>
					<FormField
						fieldType="checkbox"
						name="checkboxGroup"
						layout="horizontal"
						options={[
							{ value: 'tv', label: 'TV' },
							{ value: 'wifi', label: 'WiFi' },
							{ value: 'kitchen', label: 'Kitchen' },
						]}
						enableToggle
					/>
					<FormField fieldType="switch" name="switch" labelText="Send this command to Copilot" enableToggle />
					<FormField
						fieldType="cardSwitch"
						name="cardSwitch"
						heading="Browsing agent"
						description="Enable Copilot to browse web"
						enableToggle
					/>
					<FormField fieldType="file" name="file" accept={{ 'text/csv': ['.csv'] }} enableToggle />
					<FormField
						fieldType="radioCards"
						name="radiocards"
						options={[
							{ value: '1', label: 'Test 1' },
							{ value: '2', label: 'Test 2' },
						]}
						enableToggle
					/>
					<FormField
						fieldType="checkboxTree"
						name="tree"
						hideSearch
						options={[
							{
								id: '1',
								label: '1',
								children: [
									{
										id: '1-1',
										label: '1-1',
									},
									{
										id: '1-2',
										label: '1-2',
									},
									{
										id: '1-3',
										label: '1-3',
									},
								],
							},
							{
								id: '2',
								label: '2',
							},
							{
								id: '3',
								label: '3',
								children: [
									{
										id: '3-1',
										label: '3-1',
									},
									{
										id: '3-2',
										label: '3-2',
									},
								],
							},
						]}
						enableToggle
					/>
					<div className="flex self-end">
						<Button type="submit">Submit</Button>
					</div>
				</FormWrapper>
			),
		},
		{
			heading: 'Inline Spinner',
			children: <InlineSpinner />,
		},
		{
			heading: 'Inline Error',
			children: <InlineError />,
		},
		{
			heading: 'Expandable Tree',
			children: <ExpandableTree data={expandableTreeDataMock} size="md" />,
		},
		{
			heading: 'Paginator',
			children: (
				<Paginator
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					totalPages={totalPages}
					paginatorPosition="left"
				/>
			),
		},
		{
			heading: 'Checkbox Tree Dashboard',
			children: (
				<CheckboxTreeInput
					value={checkboxTreeValue}
					onChange={setCheckboxTreeValue}
					options={checkboxTreeViewDataMock}
				/>
			),
		},
		{
			heading: 'Full Page Accordion',
			children: (
				<FullPageAccordion
					defaultExpandedSections={['1']}
					sections={[
						{
							id: '1',
							header: 'Company profile',
							content: <p>Content</p>,
						},
						{
							id: '2',
							header: 'Persona info',
							content: <p>Content</p>,
						},
					]}
				/>
			),
		},
	]

	return (
		<PageLayout>
			<div className="mx-auto flex w-full max-w-screen-sm flex-col gap-6">
				{components.map(({ heading, children }, index) => (
					<PlayComponent key={index} heading={heading}>
						{children}
					</PlayComponent>
				))}
			</div>
		</PageLayout>
	)
}
