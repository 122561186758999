import { type RouteObject } from 'react-router'
import { routes } from '#src/utils/routes'
import { action as deleteAction } from './actions/delete'
import { action as endAction } from './actions/end'
import { action as newAction } from './actions/new'
import { action as restartRoleplayAction } from './actions/restart'
import { action as startRoleplayAction } from './actions/start'
import { loader as attemptsLoader } from './loaders/attempts'
import { loader as itemLoader, type LoaderRes as ItemLoaderRes } from './loaders/item'
import { loader as joinLoader } from './loaders/join'
import { loader as listLoader } from './loaders/list'
import { loader as newLoader } from './loaders/new'
import { loader as reviewLoader } from './loaders/review'
import Attempts from './views/attempts'
import Delete from './views/delete'
import End from './views/end'
import Item from './views/item'
import List from './views/list'
import New from './views/new'
import Review from './views/review'

const roleplayRouter = {
	path: 'roleplay',
	handle: {
		layout: {
			enableHeader: true,
		},
		crumb: () => [
			{
				label: 'Roleplay training',
				icon: 'voice-activate',
			},
		],
	},
	children: [
		{
			path: '',
			element: <List />,
			loader: listLoader,
			handle: {
				layout: {
					fullWidth: true,
				},
			},
			children: [
				{
					path: 'session/:conversationId/attempts',
					loader: attemptsLoader,
					element: <Attempts />,
				},
				{
					path: 'session/new',
					element: <New />,
					loader: newLoader,
					action: newAction,
				},
			],
		},
		{
			path: 'session',
			children: [
				{
					path: ':conversationId',
					element: <Item />,
					loader: itemLoader,
					handle: {
						layout: {
							fullWidth: true,
							noPadding: true,
						},
						crumb: () => [
							{
								label: 'Voice chat',
								icon: 'user-service-desk',
							},
						],
						action: (data: ItemLoaderRes) =>
							data.handle.canDeleteChat
								? [
										{
											to: routes.enable.roleplay.session.delete({
												companyId: data.handle.companyId,
												conversationId: data.handle.conversationId,
											}),
											icon: 'trash',
											className: 'text-status-danger-fg',
										},
									]
								: [],
					},
					children: [
						{
							path: 'restart',
							action: restartRoleplayAction,
						},
						{
							path: 'end-session',
							element: <End />,
							action: endAction,
						},
						{
							path: 'delete',
							element: <Delete />,
							action: deleteAction,
						},
						{
							path: 'start',
							action: startRoleplayAction,
						},
					],
				},
				{
					path: ':conversationId',
					children: [
						{
							// this one is left for old emails, now join redirects to attempts modal
							path: 'join',
							loader: joinLoader,
						},
						{
							path: 'review/:attemptId',
							element: <Review />,
							loader: reviewLoader,
							handle: {
								crumb: () => [
									{
										label: 'Review',
										icon: 'user-service-desk',
									},
								],
							},
						},
					],
				},
			],
		},
	],
} satisfies RouteObject

export default roleplayRouter
