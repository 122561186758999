import { useFetcher, useLoaderData } from 'react-router'
import { type z } from 'zod'
import { type ContactsEnrichItemAPISchema } from '#src/api/icp/company/contacts-enrich/schemas'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { CustomTooltip } from '#src/components/ui/tooltip'
import { type IndexLoaderRes } from '#src/routes/enable/contacts-enrich/routes'
import { routes } from '#src/utils/routes'

export const SingleDownload = ({ item }: { item: z.infer<typeof ContactsEnrichItemAPISchema> }) => {
	const {
		values: { companyId },
	} = useLoaderData<IndexLoaderRes>()
	const fetcher = useFetcher()
	const downloadAction = routes.enable.contactsEnrich.download({ companyId })

	return (
		<fetcher.Form action={downloadAction} method="POST">
			<input type="hidden" name="ids[]" value={item.id} />
			<CustomTooltip label="Export CSV">
				<Button size="icon" variant="icon" type="submit" disabled={fetcher.state !== 'idle'}>
					<Icon name="download" />
				</Button>
			</CustomTooltip>
		</fetcher.Form>
	)
}
