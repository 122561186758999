import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useLoaderData } from 'react-router'
import { type z } from 'zod'
import { criteriaMutations } from '#src/api/icp/company/criteria/mutations'
import { FormField } from '#src/components/forms/v2/FormField'
import { Divider } from '#src/components/ui/Divider'
import { Icon } from '#src/components/ui/icon'
import { InlineSpinner } from '#src/components/ui/InlineSpinner'
import { StatusButton } from '#src/components/ui/status-button'
import { CustomTooltip } from '#src/components/ui/tooltip'
import { showToast } from '#src/context/ToastContext'
import useAuth from '#src/hooks/useAuth'
import useCompany from '#src/hooks/useCompany'
import { type CriteriaSaveLoaderType } from '#src/routes/calibrate/ecosystem-management/criteria/routes/save'
import {
	type CriterionAnswerFieldId,
	type GetInstructionsFormSchema,
} from '#src/routes/calibrate/ecosystem-management/criteria/schema'
import { extractCriterionFieldValues } from '#src/routes/calibrate/ecosystem-management/criteria/utils'
import { type FormData } from '#src/routes/calibrate/ecosystem-management/criteria/views/components/Form'
import { ECOSYSTEM_TYPE } from '#src/routes/calibrate/ecosystem-management/ecosystem/constants'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'

export const InstructionsField = () => {
	const {
		data: { ecosystem },
		values: { type },
	} = useLoaderData<CriteriaSaveLoaderType>()
	const { adminSession } = useAuth()
	const { watch, setValue } = useFormContext<FormData>()
	const name = watch('name')
	const { company } = useCompany()
	const [isLoading, setIsLoading] = useState(false)

	const handleGenerateInstructions = async () => {
		const form = watch() as Record<string, unknown>

		try {
			setIsLoading(true)
			//TODO: Rewrite if this solution concept works and move into action.
			const answers = Object.entries(form)
				.filter(([key, value]) => key.endsWith('Toggle') && value === true)
				.map(([toggleKey]) => {
					const baseKey = toggleKey.replace('Toggle', '') as CriterionAnswerFieldId
					const keys = extractCriterionFieldValues(baseKey)

					return { verticalId: keys?.verticalId, value: form[baseKey] }
				})

			const verticalsRelevant = type === 'enrichment' && ecosystem.type !== ECOSYSTEM_TYPE.CRITERIA_SANDBOX

			if (verticalsRelevant && !answers?.length) {
				showToast({
					message: `At least one vertical answer is required`,
					type: 'warning',
				})

				return
			}

			const payload = {
				criterionName: form.name as string,
				manualInstructions: form.manualInstructions as string,
				ecosystemName: ecosystem.name,
				answers: verticalsRelevant ? answers : [],
			} as unknown as z.infer<typeof GetInstructionsFormSchema>

			const res = await criteriaMutations.getInstructions(company.id, payload)

			if (res && 'instructions' in res) {
				setValue('instructions', res.instructions)
			}

			showToast({
				message: `Successfully generated`,
				type: 'success',
			})
		} catch (err) {
			return await serverFormErrorHandler(err)
		} finally {
			setIsLoading(false)
		}
	}

	const handleCheckboxManualInstructionsChange = (v: unknown) => {
		if (!(v as string[]).length) {
			setValue('manualInstructions', null)
		}
	}

	return (
		<>
			{adminSession && (
				<>
					<Divider marginY="none" />
					<FormField
						fieldType="checkbox"
						name="checkboxManualInstructions"
						onChange={handleCheckboxManualInstructionsChange}
						disableLabel
						spacing="sm"
						className="p-0"
						disableField={isLoading}
						options={[
							{
								value: 'true',
								label: 'Generate Instructions With AI',
								content: (
									<div className="flex w-full flex-col items-center gap-2">
										<FormField
											fieldType="textarea"
											name="manualInstructions"
											placeholder="Describe where and how you find this information now"
											wrapperClassName="w-full"
										/>
										<div className="self-end">
											<CustomTooltip
												label="Criterion name and at least one vertical answer is required to generate"
												disabled={!!name}
											>
												<StatusButton
													onClick={() => {
														void handleGenerateInstructions()
													}}
													status={isLoading ? 'pending' : 'idle'}
													disabled={!name || isLoading}
													type="button"
													size="sm"
												>
													<Icon name="magic-wand" />
													Generate
												</StatusButton>
											</CustomTooltip>
										</div>
									</div>
								),
							},
						]}
					/>
				</>
			)}
			<div className="relative">
				<FormField
					fieldType="textarea"
					name="instructions"
					rows={6}
					className="bg-white"
					disableField={isLoading}
					label={
						<div className="flex w-full items-center justify-between">
							<div>AI-Driven Lead Research Agent Instructions</div>
						</div>
					}
				/>
				{isLoading && <InlineSpinner className="absolute inset-0 m-auto flex items-center justify-center" />}
			</div>
		</>
	)
}
