import { Fragment, useEffect, useMemo } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useFetcher } from 'react-router'
import { type z } from 'zod'
import { PLAY_STATUS } from '#src/api/icp/company/plays/constants'
import { type PlaysFormSchema } from '#src/api/icp/company/plays/schemas'
import { EmptyStateCard } from '#src/components'
import { FormField } from '#src/components/forms/v2/FormField'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { CustomTooltip } from '#src/components/ui/tooltip'
import useCompany from '#src/hooks/useCompany'
import StatusDisplay from '#src/routes/enable/plays/root/views/components/StatusDisplay'
import { routes } from '#src/utils/routes'
import { type LoaderRes as PlayFormLoaderRes } from '../../loaders/create'

const PlayFormFields = () => {
	const fetcher = useFetcher<PlayFormLoaderRes>()
	const { companyId } = useCompany()

	useEffect(() => {
		if (fetcher.state === 'idle' && !fetcher.data) {
			void fetcher.load(routes.enable.plays.create({ companyId }))
		}
	}, [fetcher, companyId])

	const personaTypesOptions = useMemo(() => {
		if (!fetcher.data) {
			return []
		}
		return fetcher.data.data.personaTypes.map(item => ({ value: item, label: item }))
	}, [fetcher.data])

	const expertiseOptions = useMemo(() => {
		if (!fetcher.data) {
			return []
		}
		return fetcher.data.data.expertises.map(item => ({ value: item, label: item }))
	}, [fetcher.data])

	const tagsOptions = useMemo(() => {
		if (!fetcher.data) {
			return []
		}
		return fetcher.data.data.tags.map(item => ({ value: item, label: item }))
	}, [fetcher.data])

	const criteriasOptions = useMemo(() => {
		if (!fetcher.data) {
			return []
		}
		return fetcher.data.data.criterias.map(item => ({ value: item.id, label: item.name, disabled: !item.active }))
	}, [fetcher.data])

	const operatorsOptions = useMemo(() => {
		if (!fetcher.data) {
			return []
		}
		return fetcher.data.data.operators.map(item => ({
			value: item.name,
			label: item.name,
			requires_input: item.requires_input,
		}))
	}, [fetcher.data])

	const verticalsOptions = useMemo(() => {
		if (!fetcher.data) {
			return []
		}
		return fetcher.data.data.ecosystems.map(ecosystem => ({
			id: String(ecosystem.id),
			label: ecosystem.name,
			children: ecosystem.verticals.map(vertical => ({
				id: String(vertical.id),
				label: vertical.name,
			})),
		}))
	}, [fetcher.data])

	const form = useFormContext<z.infer<typeof PlaysFormSchema>>()

	const conditionsInstance = useFieldArray<z.infer<typeof PlaysFormSchema>>({
		control: form.control,
		name: 'conditions',
	})

	const addNewCondition = () => {
		conditionsInstance.append({
			condition: 'AND',
			variable_id: criteriasOptions.filter(item => !item.disabled)[0]?.value ?? undefined,
			operator: operatorsOptions[0]?.value ?? undefined,
			value: null,
		})
	}

	return (
		<>
			<div className="grid grid-cols-[3fr,1fr] gap-6">
				<FormField fieldType="text" name="name" label="Play name" />
				<FormField
					fieldType="select"
					name="status"
					label="Status"
					options={[
						{
							value: PLAY_STATUS.Draft,
							label: <StatusDisplay status={PLAY_STATUS.Draft} />,
						},
						{
							value: PLAY_STATUS.Paused,
							label: <StatusDisplay status={PLAY_STATUS.Paused} />,
						},
						{
							value: PLAY_STATUS.Ongoing,
							label: <StatusDisplay status={PLAY_STATUS.Ongoing} />,
						},
						{
							value: PLAY_STATUS.Archived,
							label: <StatusDisplay status={PLAY_STATUS.Archived} />,
						},
						{
							value: PLAY_STATUS.ToReview,
							label: <StatusDisplay status={PLAY_STATUS.ToReview} />,
							disabled: true,
						},
					]}
				/>
			</div>
			<FormField fieldType="multiselect" name="tags" label="Tags" options={tagsOptions} enableCreate />
			<div className="flex flex-col gap-2">
				<FormField fieldType="switch" name="inCrm" disableLabel labelText="Use this play in Lead Research Agent jobs" />
				<FormField fieldType="switch" name="inCopilot" disableLabel labelText="Use this play in Copilot" />
			</div>
			<FormField fieldType="textarea" name="instructions" label="Play instructions" rows={14} />
			<FormField fieldType="multiselect" name="personaTypes" label="Persona types" options={personaTypesOptions} />
			<FormField fieldType="multiselect" name="expertise" label="Expertise" options={expertiseOptions} />
			<div className="flex flex-col gap-2">
				<h6 className="text-title-sm">Ecosystems and verticals</h6>
				<FormField
					disableLabel
					name="verticals"
					fieldType="checkboxTree"
					searchPlaceholder="Find verticals"
					options={verticalsOptions}
					noItemsText="No Ongoing Verticals"
				/>
			</div>
			<div className="flex flex-col gap-2">
				<h6 className="text-title-sm">Conditional rules</h6>
				<div className="flex flex-col gap-2 rounded border border-neutral-2-bd bg-neutral-2-bg p-4">
					{!conditionsInstance.fields.length ? (
						<EmptyStateCard
							className="min-h-0"
							icon="automation-decision"
							title="Play does not have any rules"
							description="Rules check if a condition is met before performing an action"
							actions={[
								{
									title: 'Add new',
									onClick: addNewCondition,
								},
							]}
						/>
					) : (
						<>
							<div className="grid grid-cols-[2fr,4fr,3fr,3fr,auto] gap-2">
								{conditionsInstance.fields.map((field, index) => (
									<Fragment key={`condition-${index}`}>
										<div>
											{index === 0 ? (
												<div className="flex h-full items-center justify-end text-right text-label-lg">If</div>
											) : (
												<FormField
													key={field.id}
													name={`conditions.${index}.condition`}
													fieldType="select"
													disableLabel
													options={[
														{
															value: 'AND',
															label: 'And',
														},
														{
															value: 'OR',
															label: 'Or',
														},
													]}
												/>
											)}
										</div>
										<div className="flex flex-row gap-1">
											<div className="flex-1">
												<FormField
													key={field.id}
													name={`conditions.${index}.variable_id`}
													fieldType="select"
													disableLabel
													options={criteriasOptions}
													placeholder="Select criteria"
												/>
											</div>
											{criteriasOptions.find(i => i.value === form.watch(`conditions.${index}.variable_id`))
												?.disabled && (
												<CustomTooltip label="This criteria has been deleted">
													<div className="flex h-10 items-center justify-center">
														<Icon name="warning-alt" size="sm" color="red" />
													</div>
												</CustomTooltip>
											)}
										</div>
										<div>
											<FormField
												key={field.id}
												name={`conditions.${index}.operator`}
												fieldType="select"
												disableLabel
												options={operatorsOptions}
												placeholder="Select operator"
											/>
										</div>
										<div>
											{operatorsOptions.find(operator => operator.value === form.watch(`conditions.${index}.operator`))
												?.requires_input === true && (
												<FormField
													key={field.id}
													name={`conditions.${index}.value`}
													fieldType="text"
													disableLabel
													placeholder="Enter value"
												/>
											)}
										</div>
										<div>
											<Button
												className="h-10 w-10"
												size="icon"
												variant="icon"
												type="button"
												onClick={() => conditionsInstance.remove(index)}
											>
												<Icon name="trash" size="sm" color="red" />
											</Button>
										</div>
									</Fragment>
								))}
							</div>
							<Button size="sm" variant="ghost" type="button" onClick={addNewCondition}>
								<Icon name="add" /> Add condition
							</Button>
						</>
					)}
				</div>
			</div>
		</>
	)
}

export default PlayFormFields
