import { Link, useRouteLoaderData } from 'react-router'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import useAuth from '#src/hooks/useAuth'
import useCompany from '#src/hooks/useCompany'
import { type ChatLoaderResponse } from '#src/routes/enable/chat'
import { routes } from '#src/utils/routes'

const ChatsPanelHeader = () => {
	const {
		handle: { conversationId },
	} = useRouteLoaderData('copilot-module') as ChatLoaderResponse
	const { user, adminSession, userSession, enableOnlySession } = useAuth()
	const { companyId } = useCompany()

	return (
		<div className="flex w-full items-center justify-between gap-4">
			<span className="w-full text-center text-body-sm font-semibold tracking-[.02em] text-neutral-3-fg">
				{user.company && enableOnlySession ? user.company : 'Copilot'}
			</span>
			{(adminSession || userSession || enableOnlySession) && (
				<Button asChild size="sm" className="h-6 w-6 shrink-0 p-0">
					<Link
						to={routes.enable.copilot.new({
							companyId,
							conversationId,
						})}
					>
						<Icon name="add" size="sm" />
					</Link>
				</Button>
			)}
		</div>
	)
}

export default ChatsPanelHeader
