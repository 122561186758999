import { type LoaderFunctionArgs } from 'react-router'
import { companyChatRoleplayScenarioQueries } from '#src/api/icp/company/chat/roleplay/scenario/queries'
import { client } from '#src/main'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem-management/ecosystem/queries'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ request, params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)

	const scenarioId = new URL(request.url).searchParams.get('scenarioId')

	const [scenarioTemplates, ecosystems, voices, users, scenario, participants] = await Promise.all([
		client.fetchQuery(companyChatRoleplayScenarioQueries.templates(company.id)),
		client.fetchQuery(ecosystemsQuery(company.id)),
		client.fetchQuery(companyChatRoleplayScenarioQueries.voices(company.id)),
		client.fetchQuery(companyChatRoleplayScenarioQueries.users(company.id)),
		scenarioId ? client.fetchQuery(companyChatRoleplayScenarioQueries.item(company.id, scenarioId)) : undefined,
		scenarioId
			? client.fetchQuery(companyChatRoleplayScenarioQueries.itemParticipants(company.id, scenarioId))
			: undefined,
	])

	return { scenarioTemplates, ecosystems, voices, users, scenario, participants }
}
