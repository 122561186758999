import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useMemo, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useLoaderData } from 'react-router'
import { type z } from 'zod'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import { type CriteriaSaveLoaderType } from '#src/routes/calibrate/ecosystem-management/criteria/routes/save'
import { generateSaveCriterionContext } from '#src/routes/calibrate/ecosystem-management/criteria/utils'
import {
	FormAnswersSection,
	getOptionsForArrayField,
} from '#src/routes/calibrate/ecosystem-management/criteria/views/components/FormAnswersSection'
import { FormDetailsSection } from '#src/routes/calibrate/ecosystem-management/criteria/views/components/FormDetailsSection'
import { FormLLMSection } from '#src/routes/calibrate/ecosystem-management/criteria/views/components/FormLLMSection'
import { FormVerticalsSection } from '#src/routes/calibrate/ecosystem-management/criteria/views/components/FormVerticalsSection'
import { UNITS } from '#src/utils/enumerations'
import { routes } from '#src/utils/routes'
import { type CriterionNewSchema, getCriterionNewFormSchema } from '../../schema'
import { FormSections } from './index'

export type FormData = z.infer<typeof CriterionNewSchema>

export const Form = () => {
	const {
		values: { companyId, ecosystemId, type, defaultValues, redirectTo, isDuplicating, isCriteriaSandbox },
		data: { criterion: loaderCriterion, verticals, llmModels },
	} = useLoaderData<CriteriaSaveLoaderType>()
	const criterion = loaderCriterion
	const [isInitialized, setIsInitialized] = useState(false)
	const formId = 'saveCriterionForm'

	const formProps = useForm<FormData>({
		resolver: async (values, context, options) => {
			const { schema } = generateSaveCriterionContext(verticals, values.type, type, criterion)
			const formSchema = getCriterionNewFormSchema(values.type)
			const merged = formSchema.merge(schema)
			const refined = merged.superRefine((data, ctx) => {
				if (data.unit === 'other') {
					if (!data.otherUnit) {
						ctx.addIssue({
							code: 'custom',
							path: ['otherUnit'],
							message: 'Required',
						})
					} else {
						data.unit = data.otherUnit
						delete data.otherUnit
					}
				}
			})

			const createResolver = zodResolver(refined)

			return createResolver(values, context, options)
		},

		/*
		 * Due to React Strict Mode, the validation behavior for some fields, such as
		 * the "name" field and "options" fields, may behave inconsistently on the first
		 * submission attempt. This issue typically occurs only in local development.
		 */
		shouldUnregister: false,
		resetOptions: {
			keepDirtyValues: false,
		},
		defaultValues: {
			llmModel: llmModels[0].name,
			...defaultValues,
			...criterion,
			inBizmodel: criterion?.inBizmodel ?? type === 'qualification',
			otherUnit: !Object.values(UNITS).includes(criterion?.unit as UNITS) ? (criterion?.unit ?? undefined) : undefined,
			unit:
				criterion?.unit && !Object.values(UNITS).includes(criterion.unit as UNITS)
					? 'other'
					: (criterion?.unit ?? undefined),
			checkboxUnit: criterion?.unit ? ['true'] : undefined,
			checkboxManualInstructions: criterion?.manualInstructions ? ['true'] : undefined,
			options: (getOptionsForArrayField(criterion, criterion?.type) as string[]) || [''],
		},
		mode: 'onSubmit',
		reValidateMode: 'onSubmit',
	})
	const { watch, resetField, setValue } = formProps

	const action = (() => {
		switch (true) {
			case isDuplicating:
				return routes.calibrate.criteria.duplicate({
					companyId,
					ecosystemId,
					criterionType: type,
					criterionId: String(loaderCriterion?.id),
				})
			case !!loaderCriterion?.id:
				return routes.calibrate.criteria.edit({
					companyId,
					ecosystemId,
					criterionType: type,
					criterionId: String(loaderCriterion.id),
					returnTo: redirectTo as 'index' | undefined,
				})
			default:
				return routes.calibrate.criteria.new({ companyId, ecosystemId, criterionType: type })
		}
	})()

	const fieldType = watch('type')

	/* Criterion inside criteria_sandbox ecosystem which is not marked as sandbox itself must be
                    created as new one (duplicated) and vice versa.
                    Using sandbox criteria inside non sandbox ecosystems serves just as template and vice versa.
                 */
	const isNonSandboxCriterionInsideSandbox = isCriteriaSandbox && !criterion?.isSandbox
	const isSandboxCriterionInsideNonSandbox = !isCriteriaSandbox && criterion?.isSandbox

	const fieldsDisabled =
		(criterion?.isAnsweredInEcosystem &&
			!isDuplicating &&
			!isNonSandboxCriterionInsideSandbox &&
			!isSandboxCriterionInsideNonSandbox) ??
		false

	useEffect(() => {
		if (!fieldType) return

		const { schema, defaultValues } = generateSaveCriterionContext(verticals, fieldType, type, criterion)
		const verticalFields = Object.keys(schema.shape) as (keyof FormData)[]
		const fieldsWithToggles = Object.keys(defaultValues) as (keyof FormData)[]

		fieldsWithToggles.forEach(fieldName => {
			if (fieldName.endsWith('Toggle')) {
				setValue(fieldName, false)
			}
		})

		verticalFields.forEach(fieldName => resetField(fieldName))

		setIsInitialized(true)
	}, [fieldType, verticals, type, criterion, setValue, resetField])

	const getVerticalToggles = (): (keyof FormData)[] => {
		return Object.keys(formProps.getValues()).filter((name): name is keyof FormData => name.endsWith('Toggle'))
	}

	const watchedToggles = useWatch({
		control: formProps.control,
		name: getVerticalToggles(),
	})

	const handleToggleAllVerticals = (v: boolean) => getVerticalToggles().forEach(fieldName => setValue(fieldName, v))

	useEffect(() => {
		const allEnabled = watchedToggles.every(item => item === true)
		setValue('toggleAll', allEnabled)
	}, [watchedToggles, setValue, criterion?.id])

	const getVerticalSection = () => {
		if (!fieldType) return null

		return {
			heading: (
				<div className="flex w-full items-center justify-between">
					Select Verticals
					<div className="flex items-center gap-2 text-neutral-2-fg">
						<span className="text-label-md font-semibold">Enable all</span>
						<FormField
							name="toggleAll"
							fieldType="switch"
							onChange={v => handleToggleAllVerticals(v as boolean)}
							disableLabel
						/>
					</div>
				</div>
			),
			content: <FormVerticalsSection key={fieldType} criterion={criterion} isInitialized={isInitialized} />,
		}
	}

	const verticalSection = useMemo(
		getVerticalSection,

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[fieldType, verticals, type, criterion, resetField, isInitialized],
	)

	return (
		<FormWrapper formId={formId} formProps={formProps} action={action}>
			<FormSections
				sections={[
					{
						heading: 'Details',
						content: <FormDetailsSection disableField={fieldsDisabled} ecosystemsInUse={criterion?.ecosystems} />,
					},
					{
						heading: 'LLM model',
						content: <FormLLMSection />,
					},
					{
						heading: 'Answers',
						content: (
							<FormAnswersSection
								fieldType={fieldType}
								criterion={criterion}
								fieldsDisabled={fieldsDisabled}
								setIsInitialized={setIsInitialized}
							/>
						),
					},
					...(verticalSection ? [verticalSection] : []),
				]}
			/>
		</FormWrapper>
	)
}
