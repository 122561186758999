import { type LoaderFunctionArgs } from 'react-router'
import { orgListsQueries } from '#src/api/organise/lists/queries'
import { client } from '#src/main'
import { validateRouteParams } from '#src/utils/misc'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	validateRouteParams(params, ['jobId'])

	return {
		values: {
			jobId: params.jobId,
		},
		data: {
			job: await client.fetchQuery(orgListsQueries.item(params.jobId)),
		},
	}
}
