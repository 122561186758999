import { type LoaderFunctionArgs } from 'react-router'
import { companyChatRoleplayScenarioQueries } from '#src/api/icp/company/chat/roleplay/scenario/queries'
import { client } from '#src/main'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	validateRouteParams(params, ['scenarioId'])
	const { company } = await getCompany(params)

	const scenario = await client.fetchQuery(companyChatRoleplayScenarioQueries.item(company.id, params.scenarioId))
	const scenarios = await client.fetchQuery(companyChatRoleplayScenarioQueries.list(company.id, 1))

	return {
		data: {
			scenario,
			allTags: scenarios?.meta?.filters?.tags ?? [],
		},
	}
}
