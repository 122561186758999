import { type LoaderFunctionArgs } from 'react-router'
import { orgListsQueries } from '#src/api/organise/lists/queries'
import { client } from '#src/main'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	validateRouteParams(params, ['jobId'])

	return {
		values: {
			jobId: params.jobId,
		},
		data: {
			job: await client.fetchQuery(orgListsQueries.item(params.jobId)),
			tags: (await client.fetchQuery(orgListsQueries.list(company.id, 1, []))).meta.filters!.tags,
		},
	}
}
