import { type Submission } from '@conform-to/dom'
import { parseWithZod } from '@conform-to/zod'
import _ from 'lodash'
import { type FieldErrors } from 'react-hook-form'
import { z, type AnyZodObject, type z as zType } from 'zod'

/** @description objectToFormData - takes JSON object and makes it to FormData
 * @Usage - it is used in FormWrapper (client to server form submits) and in organize-api creations (because they expects FormData)
 * @Usage - encode = true use only when sending FormData to api, because it crashes to parse old parser (maybe someday it will work)
 *  */
export const objectToFormData = (data: z.infer<AnyZodObject>, encode: boolean = true) => {
	const formData = new FormData()

	const encodeKey = (key: string): string => {
		return encode ? encodeURIComponent(key) : key
	}

	// recursive method to fill out data
	const fillFormDataWithObject = (data: z.infer<AnyZodObject>, fd: FormData, root: string = '') => {
		Object.entries(data).forEach(([key, value]) => {
			const formattedKey = root ? (encode ? `${root}[${encodeKey(key)}]` : `${root}.${encodeKey(key)}`) : encodeKey(key)
			if (value === undefined) {
				// if value is empty => ignores to be added to FormData
				return
			}
			if (value === null) {
				// if value is null => adds as empty string
				fd.append(formattedKey, '')
				return
			} else if (Array.isArray(value)) {
				// if value is array => adds as array to FormData
				value.forEach((val, index) => {
					if (_.isPlainObject(val)) {
						fillFormDataWithObject(val as z.infer<AnyZodObject>, fd, `${formattedKey}[${index}]`)
					} else {
						fd.append(`${formattedKey}[${index}]`, val as string | Blob)
					}
				})
				return
			} else if (_.isPlainObject(value)) {
				// if value is object => recursively calls method to feed with inner data
				fillFormDataWithObject(value as z.infer<AnyZodObject>, fd, formattedKey)
				return
			} else {
				fd.append(formattedKey, value as string | Blob)
			}
		})
		return fd
	}

	return fillFormDataWithObject(data, formData)
}

export const unwrapSchema = (schema: z.ZodTypeAny): z.ZodObject<z.ZodRawShape> => {
	if (schema instanceof z.ZodEffects) {
		const innerSchema = schema._def.schema as z.ZodTypeAny

		return unwrapSchema(innerSchema)
	}
	if (schema instanceof z.ZodObject) {
		return schema as z.ZodObject<z.ZodRawShape>
	}

	throw new Error('Provided schema is not a ZodObject or ZodEffects.')
}

const transformBooleanFieldsToStrings = <T extends z.ZodTypeAny>(schema: T): T => {
	const replaceBooleansSchemasWithStrings = (shape: z.ZodRawShape) => {
		const booleanKeys = Object.entries(shape)
			.filter(([, value]) => {
				while (value instanceof z.ZodOptional || value instanceof z.ZodNullable) {
					value = value._def.innerType as z.ZodTypeAny
				}
				return value instanceof z.ZodBoolean
			})
			.map(([key]) => key)

		if (!booleanKeys.length) return {}

		return Object.fromEntries(booleanKeys.map(key => [key, z.string().optional()])) as z.ZodRawShape
	}

	if (schema instanceof z.ZodObject) {
		const shape = schema.shape as z.ZodRawShape
		const transformedShape = replaceBooleansSchemasWithStrings(shape)

		if (!Object.keys(transformedShape).length) {
			return schema
		}

		return schema.extend(transformedShape) as unknown as T
	}

	// Applied when transformation is done to the schema
	if (schema instanceof z.ZodEffects) {
		const def = schema._def as z.ZodEffectsDef
		const innerSchema = def.schema

		if (innerSchema && innerSchema instanceof z.ZodObject) {
			const transformedShape = replaceBooleansSchemasWithStrings(innerSchema.shape as Record<string, z.ZodTypeAny>)

			if (!Object.keys(transformedShape).length) {
				return schema
			}

			const extendedInnerSchema = innerSchema.extend(transformedShape)

			return new z.ZodEffects({
				...def,
				schema: extendedInnerSchema,
			}) as unknown as T
		}
	}

	// Add more schema instances if needed

	console.error(schema)

	throw new Error('Provided schema does not support booleans')
}

/**
 * @deprecated this one is used for verticals/criterions for now. please use new one if you need
 * Parses `FormData` using a Zod schema and processes the resulting data.
 *
 * This utility performs the following operations:
 * - Parses the `FormData` against the provided Zod schema.
 * - Handles boolean fields by converting them to string representations for parsing,
 *   then back to their original boolean form.
 * - Logs errors to the console and invokes an optional error callback when parsing fails.
 * - Ensures nullable fields are included in the result if they have a `z.nullable` schema definition.
 * - Excludes fields considered disabled (not present in the payload) from nullable values.
 */
export const parseFormData = <T extends zType.ZodTypeAny>(
	formData: FormData,
	schema: T,
	onError?: (error: Submission<unknown>) => void,
) => {
	const extendedSchema: T = transformBooleanFieldsToStrings(schema)
	const submission = parseWithZod(formData, { schema: extendedSchema })

	if (submission.status !== 'success') {
		if (onError) {
			onError(submission)
		}

		console.error('Parsing errors:', submission.error)
		throw new Error('Failed to parse form data')
	}

	// After successful parsing, convert boolean strings back to actual booleans for the payload.
	Object.entries(submission.value).forEach(([key, value]) => {
		if (value === 'true') {
			submission.value[key] = true
		} else if (value === 'false') {
			submission.value[key] = false
		}
	})

	return {
		...submission,
		value: {
			...submission.value,
		},
	}
}

export const parseFormDataNew = <T extends zType.ZodTypeAny>(
	formData: FormData,
	schema: T,
): {
	status: 'success'
	value: z.infer<typeof schema>
	payload: unknown
} => {
	const formatValue = (value: unknown) => {
		switch (true) {
			case value === '':
				return null
			case value === 'true' || value === 'false':
				return value === 'true'
			// number case is not needed, because it does more complications
			// case !Number.isNaN(Number(value)):
			// 	return Number(value)
			default:
				return value
		}
	}
	// 1. transform FormData to JSON
	const data: Record<string, unknown> = {}
	formData.forEach((value, key) => _.set(data, key, formatValue(value)))
	// 2. validate json
	const result = schema.safeParse(data)

	if (!result.success) {
		if (import.meta.env.DEV) {
			console.warn('-----parseFormData-----')
			console.warn({
				error: result.error,
				payload: data,
				schema,
			})
			console.warn('-----/parseFormData-----')
		}
		throw new Error('Failed to validate form inputs')
	}

	return {
		status: 'success',
		value: result.data as z.infer<typeof schema>,
		payload: data,
	}
}

export const getFormErrorAsMessage = (error: FieldErrors | FieldErrors[] | undefined) => {
	if (Array.isArray(error)) {
		return error
			.map(err => (err && typeof err.message === 'string' ? err.message : undefined))
			.filter(Boolean) as string[]
	}

	if (error && typeof error.message === 'string') {
		return error.message
	}

	if (typeof error === 'object') {
		return Object.values(error).flatMap(err => (err && typeof err.message === 'string' ? err.message : []))
	}
}
