import { zodResolver } from '@hookform/resolvers/zod'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { type z } from 'zod'
import { type VerticalFormSchemaBase } from '#src/api/icp/company/verticals/schemas'
import AlertBanner from '#src/components/AlertBanner'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import { ECOSYSTEM_TYPE } from '#src/routes/calibrate/ecosystem-management/ecosystem/constants'
import { autofocusFieldQuery } from '#src/routes/calibrate/ecosystem-management/verticals/autofocus'
import { type LoaderResType } from '#src/routes/calibrate/ecosystem-management/verticals/loaders/save'
import { generateCriterionFieldsForVertical } from '#src/routes/calibrate/ecosystem-management/verticals/utils'
import { getAvailablePriorities, getAvailableStatuses } from '#src/utils/fieldOptions'

export const VerticalForm = ({
	formId,
	action,
	method,
	criteria,
	enrichmentCriteria,
	schema,
	defaultValues,
	ecosystemType,
}: {
	formId: string
	action: string
	method: 'PATCH' | 'POST'
	criteria: LoaderResType['criteria']
	enrichmentCriteria: LoaderResType['enrichmentCriteria']
	schema: LoaderResType['schema']
	defaultValues: LoaderResType['defaultValues']
	ecosystemType?: ECOSYSTEM_TYPE
}) => {
	const formProps = useForm<z.infer<typeof VerticalFormSchemaBase>>({
		resolver: zodResolver(schema),
		defaultValues,
		mode: 'onSubmit',
		reValidateMode: 'onSubmit',
	})

	const renderCriteriaFields = useMemo(() => generateCriterionFieldsForVertical(criteria), [criteria])
	const renderEnrichmentCriteriaFields = useMemo(
		() => generateCriterionFieldsForVertical(enrichmentCriteria),
		[enrichmentCriteria],
	)

	const { data: autofocusField } = useQuery(autofocusFieldQuery())

	useEffect(() => {
		if (autofocusField) {
			formProps.setFocus(autofocusField as keyof z.infer<typeof VerticalFormSchemaBase>)
		}
	}, [autofocusField, formProps])

	return (
		<FormWrapper formProps={formProps} formId={formId} method={method} action={action} className="flex flex-col gap-6">
			<section className="flex flex-col gap-3.5 px-4">
				<FormField fieldType="text" name="name" placeholder="Enter here" autoFocus />
				{(ecosystemType === ECOSYSTEM_TYPE.KEY_ACCOUNT || ecosystemType === ECOSYSTEM_TYPE.CRITERIA_SANDBOX) && (
					<FormField fieldType="text" name="domain" placeholder="www" />
				)}
				{(ecosystemType === ECOSYSTEM_TYPE.DEFAULT || ecosystemType === ECOSYSTEM_TYPE.KEY_ACCOUNT) && (
					<>
						<FormField fieldType="select" name="status" options={getAvailableStatuses()} selectFirstByDefault />
						<FormField
							fieldType="select"
							name="priority"
							label="Priority"
							options={getAvailablePriorities()}
							selectFirstByDefault
						/>
					</>
				)}
			</section>
			{ecosystemType === ECOSYSTEM_TYPE.DEFAULT && (
				<section>
					<h3 className="mb-2.5 bg-neutral-2-bg px-4 py-2 text-label-sm font-semibold">Business Model Description</h3>
					<div className="flex flex-col gap-3.5 px-4">
						<FormField
							fieldType="textarea"
							name="bizDescriptionOverride"
							label="Manual Input to Business Model Description"
							placeholder="Enter here"
							rows={4}
						/>
						<FormField
							fieldType="number"
							name="bizModelLen"
							label="Business Model Description Length (# of Words)"
							placeholder="Enter here"
							step={1}
						/>
					</div>
				</section>
			)}
			{!!criteria?.length && (
				<section>
					<h3 className="mb-2.5 flex items-center gap-1 bg-neutral-2-bg px-4 py-2 text-label-sm font-semibold">
						Qualification Criteria
						<span className="text-body-sm text-neutral-3-fg">{criteria.length}</span>
					</h3>
					<div className="flex flex-col gap-3.5 px-4">{renderCriteriaFields}</div>
				</section>
			)}
			{!!enrichmentCriteria?.length && (
				<section>
					<h3 className="mb-2.5 flex items-center gap-1 bg-neutral-2-bg px-4 py-2 text-label-sm font-semibold">
						Enrichment Criteria
						<span className="text-body-sm text-neutral-3-fg">{enrichmentCriteria.length}</span>
					</h3>
					<div className="flex flex-col gap-3.5 px-4">{renderEnrichmentCriteriaFields}</div>
				</section>
			)}
			{ecosystemType !== ECOSYSTEM_TYPE.CRITERIA_SANDBOX && (
				<section>
					<h3 className="mb-2.5 bg-neutral-2-bg px-4 py-2 text-label-sm font-semibold">Notes</h3>
					<div className="flex flex-col gap-3.5 px-4">
						<AlertBanner icon="information-filled">
							Notes are not used by AI Agents. These are only for yourself. This does not impact the outcome in any way.
						</AlertBanner>
						<FormField fieldType="textarea" name="listBuildingNotes" disableLabel rows={3} />
					</div>
				</section>
			)}
		</FormWrapper>
	)
}
