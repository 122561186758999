import { type ActionFunctionArgs } from 'react-router'
import { adminPlaysExpertsMutations } from '#src/api/icp/admin/plays-experts/mutations'
import { MODAL_NAME } from '#src/constants/modals'
import { showToast } from '#src/context/ToastContext'
import { validateRouteParams } from '#src/utils/misc'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'
import { closeModalServer } from '#src/utils/server/modals'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ params, request }: ActionFunctionArgs) => {
	if (request.method !== 'DELETE') {
		throw new Response('Wrong action method', {
			status: 400,
			statusText: 'Bad Request',
		})
	}
	validateRouteParams(params, ['expertId'])

	try {
		await adminPlaysExpertsMutations.delete(Number(params.expertId))
		closeModalServer(MODAL_NAME.AdminPlaysExpertsDelete)
		showToast({
			message: 'Expert deleted',
			type: 'success',
		})
		return { ok: true }
	} catch (e) {
		return await serverFormErrorHandler(e)
	}
}
