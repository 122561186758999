import { PageLayout } from '#src/components/ui/PageLayout'
import useCompany from '#src/hooks/useCompany'

const View = () => {
	const { company } = useCompany()

	return (
		<PageLayout title="Report">
			<iframe
				className="min-h-[1000px]"
				src={`https://evergrowth.metabaseapp.com/public/dashboard/88e057bb-64f9-4a63-bd68-b008105ea406?clientid=${company.id}`}
			/>
		</PageLayout>
	)
}

export default View
