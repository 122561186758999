import { type z } from 'zod'
import { criteriaEndpoints } from '#src/api/icp/company/criteria/endpoints'
import {
	type CancelEnrichCriterionAnswersFormSchema,
	type CreateEnrichCriterionAnswersFormSchema,
} from '#src/api/icp/company/criteria/schema'
import { verticalsQueriesKeys } from '#src/api/icp/company/verticals/queries'
import { client } from '#src/main'
import { criteriaKeys } from '#src/routes/calibrate/ecosystem-management/criteria/queries'
import {
	GetInstructionsAPISchema,
	type GetInstructionsFormSchema,
} from '#src/routes/calibrate/ecosystem-management/criteria/schema'
import { type CompanySchema } from '#src/routes/company/schema'
import { fetch, safeFetch } from '#src/utils/safeFetch'

const mutations = {
	createEnrichCriterionAnswers: async (
		companyId: string,
		data: z.infer<typeof CreateEnrichCriterionAnswersFormSchema>,
	) => {
		await fetch(criteriaEndpoints.createEnrichCriterionAnswers(companyId, data.criterionId), {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ ...data }),
		})

		await Promise.all([
			client.invalidateQueries({
				queryKey: verticalsQueriesKeys.all,
			}),
			client.invalidateQueries({
				queryKey: criteriaKeys.all,
			}),
		])
	},
	cancelEnrichCriterionAnswers: async (
		companyId: string,
		data: z.infer<typeof CancelEnrichCriterionAnswersFormSchema>,
	) => {
		await fetch(criteriaEndpoints.cancelEnrichCriterionAnswers(companyId, data.criterionId), {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ ...data }),
		})

		await Promise.all([
			client.invalidateQueries({
				queryKey: verticalsQueriesKeys.all,
			}),
			client.invalidateQueries({
				queryKey: criteriaKeys.all,
			}),
		])
	},
	getInstructions: async (
		companyId: z.infer<typeof CompanySchema>['id'],
		data: z.infer<typeof GetInstructionsFormSchema>,
	) => {
		return await safeFetch(GetInstructionsAPISchema, criteriaEndpoints.getInstructions(companyId), {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
	},
}

export { mutations as criteriaMutations }
