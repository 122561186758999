import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useLoaderData } from 'react-router'
import { orgDataEnrichmentWorkflowsQueries } from '#src/api/organise/data-enrichment/workflows/queries'
import { DATA_ENRICHMENT_WORKFLOW_STATUS } from '#src/api/organise/data-enrichment/workflows/schemas'
import { Chip } from '#src/components/chip'
import { Button } from '#src/components/ui/button'
import { Icon, type IconName } from '#src/components/ui/icon'
import { LabeledValue } from '#src/components/ui/LabeledValue'
import { PageLayout, type PageLayoutProps } from '#src/components/ui/PageLayout'
import { Surface } from '#src/components/ui/surface'
import { CustomTooltip } from '#src/components/ui/tooltip'
import { MODAL_NAME } from '#src/constants/modals'
import { useModals } from '#src/hooks/useModals'
import { AccountEnrichmentJobDownloadModal } from '#src/modals/account-enrichment/Download'
import { AccountEnrichmentJobStopModal } from '#src/modals/account-enrichment/Stop'
import { formatDateToReadableString } from '#src/utils/date'
import { JobStatusName } from '../constants'
import { type LoaderRes } from '../loaders/item'
import { canDownload, canStop } from '../utils'
import { BarLineStatistics, ItemStatisticsCard, type ItemStatisticsCardProps } from './components'

const FIRST_CARD_ICON: Record<DATA_ENRICHMENT_WORKFLOW_STATUS, IconName> = {
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Pending]: 'loading-sm',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Completed]: 'checkmark-filled',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Cancelled]: 'stop-sign-filled',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Error]: 'stop-sign-filled',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.InProgress]: 'loading-sm',
} as const

const FIRST_CARD_ICON_VARIANT: Record<DATA_ENRICHMENT_WORKFLOW_STATUS, ItemStatisticsCardProps['iconVariant']> = {
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Pending]: undefined,
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Completed]: 'success',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Cancelled]: 'danger',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.Error]: 'danger',
	[DATA_ENRICHMENT_WORKFLOW_STATUS.InProgress]: undefined,
} as const

export const View = () => {
	const {
		values: { jobId },
		data,
	} = useLoaderData<LoaderRes>()

	const { data: job } = useQuery({
		...orgDataEnrichmentWorkflowsQueries.item(jobId),
		initialData: data.job,
		refetchInterval: ({ state }) => {
			if (!state.data) {
				return 0
			}
			if (canStop(state.data.status)) {
				return 5000
			}
			return 0
		},
	})

	const [copyIdTooltipShow, setCopyIdTooltipShow] = useState(false)
	const onClickCopyId = () => {
		navigator.clipboard
			.writeText(job.id)
			.then(() => {
				setCopyIdTooltipShow(true)
				setTimeout(() => setCopyIdTooltipShow(false), 1500)
			})
			.catch(() => {})
	}

	const { openModal } = useModals()

	return (
		<PageLayout
			title={job.title}
			actions={[
				...((canStop(job.status)
					? [
							<Button
								key="cw-job-stop"
								variant="danger-outline"
								onClick={() =>
									openModal({
										name: MODAL_NAME.AccountEnrichmentStop,
										component: AccountEnrichmentJobStopModal,
										props: {
											jobId: job.id,
										},
									})
								}
							>
								<Icon name="stop-filled-alt" className="mr-2 text-status-danger-fg" />
								Stop
							</Button>,
						]
					: []) satisfies PageLayoutProps['actions']),
				...((canDownload(job.status)
					? [
							<Button
								key="cw-job-download"
								onClick={() =>
									openModal({
										name: MODAL_NAME.AccountEnrichmentDownload,
										component: AccountEnrichmentJobDownloadModal,
										props: {
											jobId: job.id,
										},
									})
								}
							>
								<Icon name="download" className="mr-2" />
								Download
							</Button>,
						]
					: []) satisfies PageLayoutProps['actions']),
			]}
		>
			<ItemStatisticsCard
				icon={FIRST_CARD_ICON[job.status]}
				iconVariant={FIRST_CARD_ICON_VARIANT[job.status]}
				name={JobStatusName[job.status]}
			>
				<BarLineStatistics
					name="Accounts"
					items={[
						{
							id: 'in_queue',
							icon: 'circle-dash',
							name: 'In queue',
							color: 'bg-brand-2-fg',
							iconColor: 'text-neutral-3-fg',
							value: job.statistics?.enrichmentCriteria?.inQueueCount ?? 0,
						},
						{
							id: 'in_progress',
							icon: 'incomplete',
							name: 'In progress',
							color: 'bg-brand-1-fg',
							iconColor: 'text-brand-1-fg',
							value: job.statistics?.enrichmentCriteria?.inProgressCount ?? 0,
						},
						{
							id: 'completed',
							icon: 'checkmark-filled',
							name: 'Completed',
							color: 'bg-status-success-bg',
							iconColor: 'text-status-success-bg',
							value: job.statistics?.enrichmentCriteria?.completedCount ?? 0,
						},
						{
							id: 'with_errors',
							icon: 'checkmark-filled',
							name: 'With errors',
							color: 'bg-status-danger-bg',
							iconColor: 'text-status-danger-bg',
							value: job.statistics?.enrichmentCriteria?.errorCount ?? 0,
						},
					]}
				/>
			</ItemStatisticsCard>
			<Surface className="flex w-full flex-col gap-8 p-10">
				<h2 className="text-title-lg">Details</h2>
				<div className="flex w-full flex-row gap-8">
					<div className="flex flex-1 flex-col gap-6">
						<LabeledValue label="Name">{job.title}</LabeledValue>
						{!!job.comment && (
							<LabeledValue label="Comment">
								<div className="whitespace-pre-wrap">{job.comment}</div>
							</LabeledValue>
						)}
						{job.metadata && (
							<LabeledValue label="Criteria">
								<div className="flex flex-row flex-wrap gap-1">
									{job.metadata.enrichmentCriterias.map(criteria => (
										<Chip variant="gray" key={`criteria-${criteria.dataPointTitle}`}>
											{criteria.dataPointTitle}
										</Chip>
									))}
								</div>
							</LabeledValue>
						)}
					</div>
					<div className="flex w-full max-w-[330px] flex-col gap-6 rounded-lg bg-neutral-2-bg p-6">
						<LabeledValue label="Id">
							<span>{job.id}</span>
							<CustomTooltip
								rootProps={{
									open: copyIdTooltipShow,
								}}
								label="Copied"
							>
								<Icon
									name="copy"
									className="ml-1 size-4 cursor-pointer text-neutral-3-fg hover:text-neutral-1-fg"
									onClick={onClickCopyId}
								/>
							</CustomTooltip>
						</LabeledValue>
						<LabeledValue label="Date created">{formatDateToReadableString(job.createdAt)}</LabeledValue>
						{!!job.importFileUrl && (
							<LabeledValue label="List source file">
								<a
									href={job.importFileUrl}
									target="_blank"
									rel="noreferrer"
									className="flex w-full flex-row items-center gap-1"
								>
									<Icon name="document" size="md" className="block text-brand-1-fg" />
									<div className="flex flex-1 flex-col truncate">
										<div className="max-w-full truncate text-body-md text-brand-1-fg">Uploaded file</div>
									</div>
								</a>
							</LabeledValue>
						)}
						<LabeledValue label="Accounts uploaded">{job.originalEntryCount ?? '-'}</LabeledValue>
					</div>
				</div>
			</Surface>
		</PageLayout>
	)
}
