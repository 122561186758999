import { useLoaderData, Link } from 'react-router'
import { type z } from 'zod'
import { type VerticalListAPISchema } from '#src/api/icp/company/verticals/schemas'
import Priority from '#src/components/priority'
import Status from '#src/components/status'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import useCompany from '#src/hooks/useCompany'
import { ECOSYSTEM_TYPE } from '#src/routes/calibrate/ecosystem-management/ecosystem/constants'
import { autofocusFieldMutation } from '#src/routes/calibrate/ecosystem-management/verticals/autofocus'
import { type LoaderResType } from '#src/routes/calibrate/ecosystem-management/verticals/loaders'
import { DeleteVerticalAsyncForm } from '#src/routes/calibrate/ecosystem-management/verticals/views/delete'
import { routes } from '#src/utils/routes'

export const useHeader = (verticals: z.infer<typeof VerticalListAPISchema>) => {
	const {
		handle: { ecosystemId, readOnlySession },
		ecosystem,
	} = useLoaderData<LoaderResType>()
	const { companyId } = useCompany()

	return {
		options: { sticky: true },
		columns: [
			{ children: '' },
			...verticals.map(vertical => ({
				children: (
					<section className="flex w-full flex-col gap-1">
						<div className="flex w-full items-center justify-between gap-2">
							{readOnlySession ? (
								<p className="truncate text-title-sm">{vertical.name}</p>
							) : (
								<Link
									replace
									preventScrollReset
									className="grid grid-cols-[1fr,max-content] grid-rows-1 items-center gap-1"
									id={`vertical-column-${vertical.id ?? ''}`}
									to={routes.calibrate.verticals.edit({
										companyId,
										ecosystemId,
										verticalId: vertical.id.toString(),
									})}
									onClick={() => autofocusFieldMutation('name')}
								>
									<p className="w-full truncate text-title-sm" title={vertical.name}>
										{vertical.name}
									</p>
									<Button size="icon" variant="icon">
										<Icon name="edit" className="inline-block text-title-sm" />
									</Button>
								</Link>
							)}
							{readOnlySession ? null : (
								<div className="flex items-center justify-between">
									<Link
										replace
										preventScrollReset
										className="flex items-center gap-1 text-title-sm"
										to={routes.calibrate.verticals.duplicate({
											companyId,
											ecosystemId,
											verticalId: vertical.id.toString(),
										})}
										onClick={() => autofocusFieldMutation('name')}
									>
										<Button size="icon" variant="icon">
											<Icon name="copy" className="text-title-sm" />
										</Button>
									</Link>
									<DeleteVerticalAsyncForm ecosystemId={Number(ecosystemId)} verticalId={String(vertical.id)}>
										<Button size="icon" variant="icon">
											<Icon name="trash" className="text-title-sm" />
										</Button>
									</DeleteVerticalAsyncForm>
								</div>
							)}
						</div>
						{vertical.domain && (
							<a
								href={vertical.domain.startsWith('http') ? vertical.domain : `https://${vertical.domain}`}
								target="__blank"
								className="mb-1 text-body-sm leading-none text-link"
							>
								{vertical.domain}
							</a>
						)}
						{ecosystem?.type !== ECOSYSTEM_TYPE.CRITERIA_SANDBOX && (
							<div className="flex items-center gap-2">
								<Status status={vertical.status} />
								<Priority priority={vertical.priority} />
							</div>
						)}
					</section>
				),
			})),
		],
	}
}
