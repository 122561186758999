import { type ChangeEvent } from 'react'
import { type ClassName } from '#src/types/styles'
import { cn } from '#src/utils/misc'
import { Icon } from '../ui/icon'
import { Input } from '../ui/input'

type Props = {
	value: string
	onSearch: (query: string) => void
	placeholder?: string
	className?: ClassName
}

export function Search({ value = '', onSearch, placeholder, className }: Props) {
	const handleSearch = (evt: ChangeEvent<HTMLInputElement>) => {
		const target = evt.target
		onSearch(target.value)
	}

	return (
		<div className={cn('relative', className)}>
			<Icon name="search" size="sm" className="absolute left-2 top-1/2 z-[1] -translate-y-1/2 text-neutral-3-fg" />
			<Input
				value={value}
				onChange={handleSearch}
				className={cn('h-8 bg-white px-8 py-1.5 font-normal placeholder:text-neutral-4-fg', value ? 'px-8' : 'pr-2')}
				placeholder={placeholder ?? 'Search'}
			/>
			{value ? (
				<div
					onClick={() => {
						onSearch('')
					}}
					className="absolute right-0 top-1/2 flex h-8 w-8 -translate-y-1/2 cursor-pointer items-center justify-center text-neutral-3-fg"
				>
					<Icon name="close-filled" size="sm" />
				</div>
			) : null}
		</div>
	)
}

export const NoResults = ({ query, className }: { query: string; className?: ClassName }) => (
	<div className={cn('flex h-full flex-col justify-center gap-2', className)}>
		<Icon name="search-dialogue" className="h-16 w-16 text-brand-2-bd" />
		<div>
			<p className="text-center text-title-sm text-neutral-2-fg">No search results</p>
			<p className="break-words text-center text-body-md text-neutral-2-fg">No results for “{query}”</p>
		</div>
	</div>
)
