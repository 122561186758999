import { createSearchParams, Link, useNavigate, useSearchParams } from 'react-router'
import { EmptyStateCard } from '#src/components'
import ContactAvatar from '#src/components/chat/avatar'
import { Chip } from '#src/components/chip'
import FiltersSection from '#src/components/FiltersSection'
import ListItems from '#src/components/ListItems'
import LoaderRequiredComponent from '#src/components/LoaderRequiredComponent'
import Markdown from '#src/components/markdown'
import { Button } from '#src/components/ui/button'
import { Dialog } from '#src/components/ui/dialog'
import { Dropdown, DropdownItem } from '#src/components/ui/dropdown'
import { Icon } from '#src/components/ui/icon'
import { LabeledValue } from '#src/components/ui/LabeledValue'
import { Surface } from '#src/components/ui/surface'
import { CustomTooltip } from '#src/components/ui/tooltip'
import { MODAL_NAME } from '#src/constants/modals'
import useAuth from '#src/hooks/useAuth'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { type LoaderRes } from '#src/routes/enable/plays/templates/loaders/list'
import { formatAvatarAbbr } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

export const PlayTemplatesModal = () => {
	const { companyId } = useCompany()

	return (
		<LoaderRequiredComponent<LoaderRes, object>
			route={routes.enable.playTemplates.index({ companyId })}
			component={ModalInner}
		/>
	)
}

const ModalInner = (props: LoaderRes) => {
	const {
		data: { templates },
		filters,
	} = props
	const { closeModal } = useModals()
	const navigate = useNavigate()
	const [, setSearchParams] = useSearchParams()
	const { companyId } = useCompany()
	const { adminSession, user, userSession } = useAuth()

	return (
		<Dialog
			defaultOpen={true}
			onOpenChange={isOpen => !isOpen && closeModal(MODAL_NAME.PlayTemplates)}
			dialogHeading="Play templates"
			size="xl"
			footer={null}
			contentProps={{
				className: 'flex-row p-0 h-[60vh]',
				disableScrollbar: true,
			}}
		>
			<div className="w-[264px] max-w-full border-r border-neutral-1-bd p-4">
				<ListItems
					onSelect={value => setSearchParams(`?${createSearchParams({ category: value }).toString()}`)}
					selected={filters.selected.category as string}
					items={[
						{
							value: 'all',
							label: 'All templates',
						},
						{
							value: 'recently',
							label: 'Recently used',
						},
						{
							value: 'team',
							label: 'Team templates',
						},
						{
							value: 'evergrowth',
							label: 'Evergrowth templates',
						},
						{
							value: 'expert',
							label: 'Expert templates',
						},
					]}
				/>
			</div>
			<div className="relative flex flex-1 flex-col">
				<div className="sticky flex flex-row gap-2 border-b border-neutral-1-bd px-4 py-3">
					<FiltersSection
						minimalistic
						selected={filters.selected}
						values={filters.values}
						filters={[
							{
								type: 'search',
								name: 'search',
								label: 'Search',
							},
							{
								name: 'createdBy',
								label: 'Created by',
							},
						]}
					/>
					{(adminSession || userSession) && (
						<Button size="sm" asChild>
							<Link
								to={routes.enable.playTemplates.create({ companyId })}
								className="flex flex-nowrap items-center gap-2"
							>
								<Icon name="add" /> Add new
							</Link>
						</Button>
					)}
				</div>
				<div className="flex-1 overflow-y-auto p-4">
					{!templates.length ? (
						<EmptyStateCard
							title="No play templates found"
							icon="player-flow-1"
							{...((adminSession || userSession) && {
								actions: [
									{
										to: routes.enable.playTemplates.create({ companyId }),
										title: 'Add new',
									},
								],
							})}
						/>
					) : (
						<div className="grid grid-cols-2 gap-4">
							{templates.map(template => (
								<Surface
									key={`template-${template.id}`}
									className="flex min-h-[180px] cursor-pointer flex-col justify-between p-4 hover:border-brand-2-bd"
									onClick={() => void navigate(routes.enable.plays.create({ companyId }, String(template.id)))}
								>
									<div className="flex flex-row items-center justify-between gap-2">
										<CustomTooltip
											variant="secondary"
											className="max-h-[400px] max-w-[500px] overflow-y-auto"
											label={
												<div
													onClick={e => {
														e.preventDefault()
														e.stopPropagation()
													}}
												>
													<LabeledValue label="Instructions">
														<Markdown>{template.instructions}</Markdown>
													</LabeledValue>
												</div>
											}
											noChildrenWrapper
										>
											<h6 className="flex-1 text-title-sm">{template.name}</h6>
										</CustomTooltip>
										{(adminSession || template.createdBy === user.email) && (
											<Dropdown
												trigger={
													<Button variant="icon" size="icon" type="button">
														<Icon name="overflow-menu-vertical" />
													</Button>
												}
												contentProps={{
													side: 'right',
													align: 'start',
												}}
											>
												<DropdownItem asChild className="justify-start">
													<Link
														to={routes.enable.playTemplates.edit({
															companyId,
															templateId: String(template.id),
														})}
														onClick={e => e.stopPropagation()}
													>
														<Icon name="edit" size="sm" />
														Edit
													</Link>
												</DropdownItem>
												<DropdownItem asChild className="justify-start">
													<Link
														to={routes.enable.playTemplates.delete({
															companyId,
															templateId: String(template.id),
														})}
														onClick={e => e.stopPropagation()}
													>
														<Icon name="trash" size="sm" className="text-status-danger-fg" />
														Delete
													</Link>
												</DropdownItem>
											</Dropdown>
										)}
									</div>
									<div className="flex flex-col gap-4">
										{!!template.tags.length && (
											<div className="flex flex-row flex-wrap gap-1">
												{template.tags.map(tag => (
													<Chip variant="gray" key={`tag-${tag}`}>
														{tag}
													</Chip>
												))}
											</div>
										)}
										{template.templateExpert ? (
											<CustomTooltip
												variant="secondary"
												noChildrenWrapper
												className="max-h-[400px] max-w-[500px] overflow-y-auto"
												label={
													<div
														className="flex flex-col gap-4"
														onClick={e => {
															e.preventDefault()
															e.stopPropagation()
														}}
													>
														<LabeledValue label="LinkedIn">
															<a
																onClick={e => e.stopPropagation()}
																href={template.templateExpert.linkedin}
																target="_blank"
																rel="noreferrer"
																className="text-link"
															>
																{template.templateExpert.linkedin}
															</a>
														</LabeledValue>
														<LabeledValue label="About">
															<Markdown>{template.templateExpert.about}</Markdown>
														</LabeledValue>
													</div>
												}
											>
												<div className="flex items-center gap-2 text-body-sm font-medium text-neutral-2-fg">
													<ContactAvatar
														className="inline-flex shrink-0 items-center justify-center rounded-full"
														variant="blue"
														size="xs"
														initial={formatAvatarAbbr(template.templateExpert.fullName)}
													/>
													{template.templateExpert.fullName}
												</div>
											</CustomTooltip>
										) : (
											<div className="flex items-center gap-2 text-body-sm font-medium text-neutral-2-fg">
												<ContactAvatar
													className="inline-flex shrink-0 items-center justify-center rounded-full"
													variant="blue"
													size="xs"
													initial={formatAvatarAbbr(template.createdBy)}
												/>
												{template.createdBy}
											</div>
										)}
									</div>
								</Surface>
							))}
						</div>
					)}
				</div>
			</div>
		</Dialog>
	)
}
