import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu'
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, type ReactNode } from 'react'
import { Icon } from '#src/components/ui/icon'
import { cn } from '#src/utils/misc'

export type DropdownContentProps = ComponentPropsWithoutRef<typeof DropdownPrimitive.Content>

const Dropdown = forwardRef<
	ElementRef<typeof DropdownPrimitive.Trigger>,
	ComponentPropsWithoutRef<typeof DropdownPrimitive.Root> & {
		trigger: ReactNode
		suffix?: ReactNode
		contentProps?: DropdownContentProps
	}
>(({ children, trigger, suffix, contentProps, ...props }, ref) => {
	return (
		<DropdownPrimitive.Root {...props}>
			<div>
				<DropdownPrimitive.Trigger asChild ref={ref}>
					{trigger}
				</DropdownPrimitive.Trigger>
				{suffix ?? null}
			</div>
			<DropdownPrimitive.Portal>
				<DropdownContent {...contentProps}>{children}</DropdownContent>
			</DropdownPrimitive.Portal>
		</DropdownPrimitive.Root>
	)
})
Dropdown.displayName = DropdownPrimitive.Root.displayName

const DropdownItem = forwardRef<
	ElementRef<typeof DropdownPrimitive.Item>,
	ComponentPropsWithoutRef<typeof DropdownPrimitive.Item>
>(({ children, className, ...props }, ref) => {
	return (
		<DropdownPrimitive.Item
			className={cn(
				// TODO onclick close
				'flex cursor-pointer items-center justify-between gap-2 rounded-sm px-2 py-1.5 text-body-md outline-none hover:bg-neutral-1-bg-hover focus-visible:bg-neutral-1-bg-hover',
				className,
			)}
			{...props}
			ref={ref}
		>
			{children}
		</DropdownPrimitive.Item>
	)
})
DropdownItem.displayName = DropdownPrimitive.Item.displayName

const DropdownLabel = forwardRef<
	ElementRef<typeof DropdownPrimitive.Label>,
	ComponentPropsWithoutRef<typeof DropdownPrimitive.Label>
>(({ children, className, ...props }, ref) => {
	return (
		<DropdownPrimitive.Label
			className={cn(
				'flex items-center justify-between gap-2 rounded-sm px-2 py-1 text-body-sm text-neutral-2-fg outline-none',
				className,
			)}
			{...props}
			ref={ref}
		>
			{children}
		</DropdownPrimitive.Label>
	)
})
DropdownLabel.displayName = DropdownPrimitive.Label.displayName

const DropdownCheckboxItem = forwardRef<
	ElementRef<typeof DropdownPrimitive.CheckboxItem>,
	ComponentPropsWithoutRef<typeof DropdownPrimitive.CheckboxItem>
>(({ children, className, ...props }, ref) => {
	return (
		<DropdownPrimitive.CheckboxItem
			className={cn(
				'flex cursor-pointer items-center justify-between gap-2 rounded-sm px-2 py-1.5 outline-none hover:bg-neutral-1-bg-hover focus-visible:bg-neutral-1-bg-hover',
				className,
			)}
			{...props}
			ref={ref}
		>
			{children}
			<DropdownPrimitive.ItemIndicator>
				<Icon name="check" />
			</DropdownPrimitive.ItemIndicator>
		</DropdownPrimitive.CheckboxItem>
	)
})
DropdownCheckboxItem.displayName = DropdownPrimitive.CheckboxItem.displayName

const DropdownContent = forwardRef<
	ElementRef<typeof DropdownPrimitive.Content>,
	ComponentPropsWithoutRef<typeof DropdownPrimitive.Content>
>(({ children, className, ...props }, ref) => {
	return (
		<DropdownPrimitive.Content
			sideOffset={5}
			{...props}
			className={cn(
				'z-50 flex max-h-[20rem] min-w-[var(--radix-dropdown-menu-trigger-width)] flex-col overflow-hidden overflow-y-auto rounded border border-neutral-1-bd bg-neutral-1-bg p-2 text-neutral-2-fg shadow',
				className,
			)}
			ref={ref}
		>
			{children}
		</DropdownPrimitive.Content>
	)
})
DropdownContent.displayName = DropdownPrimitive.Content.displayName

const DropdownSubContent = forwardRef<
	ElementRef<typeof DropdownPrimitive.SubContent>,
	ComponentPropsWithoutRef<typeof DropdownPrimitive.SubContent>
>(({ children, className, ...props }, ref) => {
	return (
		<DropdownPrimitive.SubContent
			sideOffset={2}
			alignOffset={-5}
			{...props}
			className={cn(
				'z-50 flex max-h-[20rem] min-w-[var(--radix-dropdown-menu-trigger-width)] flex-col overflow-hidden overflow-y-auto rounded border border-neutral-1-bd bg-neutral-1-bg p-2 text-neutral-2-fg shadow',
				className,
			)}
			ref={ref}
		>
			{children}
		</DropdownPrimitive.SubContent>
	)
})
DropdownSubContent.displayName = DropdownPrimitive.SubContent.displayName

const DropdownSubTrigger = forwardRef<
	ElementRef<typeof DropdownPrimitive.SubTrigger>,
	ComponentPropsWithoutRef<typeof DropdownPrimitive.SubTrigger>
>(({ children, className, ...props }, ref) => {
	return (
		<DropdownPrimitive.SubTrigger
			{...props}
			className={cn(
				'flex cursor-pointer items-center justify-between gap-2 rounded-sm px-2 py-1.5 text-body-md outline-none hover:bg-neutral-1-bg-hover focus-visible:bg-neutral-1-bg-hover',
				className,
			)}
			ref={ref}
		>
			{children}
			<Icon name="chevron-down" size="sm" className="-rotate-90" />
		</DropdownPrimitive.SubTrigger>
	)
})
DropdownSubTrigger.displayName = DropdownPrimitive.SubTrigger.displayName

const DropdownSub = DropdownPrimitive.Sub

export {
	Dropdown,
	DropdownItem,
	DropdownCheckboxItem,
	DropdownLabel,
	DropdownSub,
	DropdownSubContent,
	DropdownSubTrigger,
}
