import { type ActionFunctionArgs } from 'react-router'
import { adminCompanyMutations } from '#src/api/icp/admin/company/mutations'
import { MODAL_NAME } from '#src/constants/modals'
import { showToast } from '#src/context/ToastContext'
import { validateRouteParams } from '#src/utils/misc'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'
import { closeModalServer } from '#src/utils/server/modals'

export const action = async ({ params }: ActionFunctionArgs) => {
	validateRouteParams(params, ['companyId'])
	try {
		await adminCompanyMutations.delete(params.companyId)
		closeModalServer(MODAL_NAME.AdminCompanyDelete)
		showToast({
			message: 'Company successfully deleted',
			type: 'success',
		})
		return { ok: true }
	} catch (e) {
		return await serverFormErrorHandler(e)
	}
}
