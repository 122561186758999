import { type ActionFunctionArgs } from 'react-router'
import { MODAL_NAME } from '#src/constants/modals'
import { client } from '#src/main'
import { createChat } from '#src/routes/enable/chat/mutations'
import { enableKeys } from '#src/routes/enable/chat/queries'
import { NewConversationFormSchema } from '#src/routes/enable/chat/schema'
import { parseFormDataNew } from '#src/utils/forms'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'
import { closeModalServer } from '#src/utils/server/modals'

export type NewChatActionResponse = Awaited<ReturnType<typeof action>>

export const action = async ({ request, params }: ActionFunctionArgs) => {
	const { company, companyId } = await getCompany(params)

	const formData = await request.formData()

	try {
		const submission = parseFormDataNew(formData, NewConversationFormSchema)
		const response = await createChat(company.id, {
			personaId: submission.value.personaId,
			linkedinUrl: submission.value.linkedinUrl,
		})

		await client.invalidateQueries({
			queryKey: enableKeys.all,
		})

		closeModalServer(
			MODAL_NAME.CopilotCreate,
			routes.enable.copilot.index({
				companyId,
				conversationId: response.conversationId,
			}),
		)

		return { ok: true }
	} catch (e) {
		return await serverFormErrorHandler(e)
	}
}
