import { type LoaderFunctionArgs } from 'react-router'
import { client } from '#src/main'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem-management/ecosystem/queries'
import { getCompany } from '#src/utils/server/company'

export type CalibrateModuleLoaderResponse = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)

	const ecosystems = await client.fetchQuery(ecosystemsQuery(company.id, { includeCriteriaSandbox: true }))

	if (!ecosystems)
		throw new Response('Missing parameters', {
			status: 400,
			statusText: 'Bad Request',
		})

	return {
		ecosystems,
	}
}
