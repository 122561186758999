import { type LoaderFunctionArgs } from 'react-router'
import { type z } from 'zod'
import { companyCriteriaQueries } from '#src/api/icp/company/criteria/queries'
import { verticalsQueries } from '#src/api/icp/company/verticals/queries'
import { client } from '#src/main'
import { type CRITERION_TYPE_ENUM } from '#src/routes/calibrate/ecosystem-management/criteria/constants'
import { criterionQuery } from '#src/routes/calibrate/ecosystem-management/criteria/queries'
import { generateSaveCriterionContext } from '#src/routes/calibrate/ecosystem-management/criteria/utils'
import { ECOSYSTEM_TYPE } from '#src/routes/calibrate/ecosystem-management/ecosystem/constants'
import { ecosystemQuery } from '#src/routes/calibrate/ecosystem-management/ecosystem/queries'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type LoaderResType = Awaited<ReturnType<ReturnType<typeof loader>>>

export const loader =
	(type: z.infer<typeof CRITERION_TYPE_ENUM>) =>
	async ({ request, params }: LoaderFunctionArgs) => {
		validateRouteParams(params, ['ecosystemId'])
		const { company, companyId } = await getCompany(params)
		const url = new URL(request.url)
		const redirectTo = url.searchParams.get('to')
		let criterion
		let defaultValues = {}
		const isDuplicating = request.url.includes('duplicate')

		const [ecosystem, verticals, llmModels] = await Promise.all([
			client.fetchQuery(ecosystemQuery(company.id, params.ecosystemId)),
			client.fetchQuery(verticalsQueries.list(company.id, Number(params.ecosystemId))),
			client.fetchQuery(companyCriteriaQueries.modelTypes(company.id)),
		])

		const isCriteriaSandbox = ecosystem.type === ECOSYSTEM_TYPE.CRITERIA_SANDBOX

		if (params.criterionId) {
			criterion = await client.fetchQuery(criterionQuery(company.id, params.criterionId))
			criterion.isEnrichment = type === 'enrichment'

			if (criterion) {
				const { defaultValues: verticalsDefaults } = generateSaveCriterionContext(
					verticals,
					criterion.type ?? 'text',
					type,
					criterion,
				)
				defaultValues = verticalsDefaults
			}

			if (isDuplicating) {
				criterion.name = `${criterion.name} (Copy)`
			}

			// Criteria sandbox uses only enrichment criteria
			if (isCriteriaSandbox) {
				criterion.isEnrichment = true
			}
		}

		return {
			values: {
				companyId,
				ecosystemId: params.ecosystemId,
				type,
				defaultValues,
				redirectTo,
				key: `${type}-${companyId}-${params.ecosystemId}-${params.criterionId || 'new'}`,
				isCriteriaSandbox,
				isDuplicating,
			},
			data: {
				verticals,
				criterion,
				ecosystem,
				llmModels,
			},
		}
	}
