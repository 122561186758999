import { type z } from 'zod'
import { client } from '#src/main'
import { type CompanySchema } from '#src/routes/company/schema'
import { safeFetch } from '#src/utils/safeFetch'
import { companyAccountExportEndpoints } from './endpoints'
import { companyAccountExportQueriesKeys } from './queries'
import { type AccountExportFormSchema, AccountExportValidateAPISchema } from './schemas'

const mutations = {
	createValidate: async (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		data: z.infer<typeof AccountExportFormSchema>,
	) =>
		await safeFetch(AccountExportValidateAPISchema, companyAccountExportEndpoints.createValidate(companyId), {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		}),
	create: async (companyId: z.infer<typeof CompanySchema.shape.id>, data: z.infer<typeof AccountExportFormSchema>) => {
		const res = await fetch(companyAccountExportEndpoints.create(companyId), {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
		await client.invalidateQueries({
			queryKey: [...companyAccountExportQueriesKeys.all, 'list'],
		})
		await client.invalidateQueries({
			queryKey: [...companyAccountExportQueriesKeys.all, 'statistics'],
		})
		return res
	},
}

export { mutations as companyAccountExportMutations }
