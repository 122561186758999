import { FormField } from '#src/components/forms/v2/FormField'

const PlaysExpertsFormFields = () => (
	<>
		<FormField fieldType="text" name="fullName" label="Full name" placeholder="Enter full name" />
		<FormField fieldType="text" name="linkedin" label="LinkedIn" placeholder="Enter LinkedIn profile url" />
		<FormField fieldType="textarea" name="about" label="About" rows={14} />
	</>
)

export default PlaysExpertsFormFields
