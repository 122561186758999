import { Fragment, useCallback, useMemo } from 'react'
import { Link, useRouteLoaderData } from 'react-router'
import { Icon } from '#src/components/ui/icon'
import useAuth from '#src/hooks/useAuth'
import useCompany from '#src/hooks/useCompany'
import { PanelSectionItem } from '#src/layouts/Panel'
import { ECOSYSTEM_TYPE, ECOSYSTEM_TYPE_LABELS } from '#src/routes/calibrate/ecosystem-management/ecosystem/constants'
import { type CalibrateModuleLoaderResponse } from '#src/routes/calibrate/ecosystem-management/sidebar/loaders'
import { type CalibrateNav } from '#src/routes/calibrate/ecosystem-management/sidebar/schema'
import { EcosystemItem } from '#src/routes/calibrate/ecosystem-management/sidebar/views/components'
import { routes } from '#src/utils/routes'

export const View = () => {
	const { readOnlySession } = useAuth()
	const { ecosystems } = useRouteLoaderData('calibrate-module') as CalibrateModuleLoaderResponse
	const { companyId } = useCompany()

	const ROOT_NAVIGATION = useMemo(
		() => ({
			[ECOSYSTEM_TYPE.DEFAULT]: {
				title: ECOSYSTEM_TYPE_LABELS[ECOSYSTEM_TYPE.DEFAULT].plural,
				toCreate: routes.calibrate.ecosystem.create({ companyId }),
			},
			[ECOSYSTEM_TYPE.KEY_ACCOUNT]: {
				title: ECOSYSTEM_TYPE_LABELS[ECOSYSTEM_TYPE.KEY_ACCOUNT].plural,
				toCreate: routes.calibrate.ecosystem.createKeyAccounts({ companyId }),
			},
			[ECOSYSTEM_TYPE.CRITERIA_SANDBOX]: {
				title: ECOSYSTEM_TYPE_LABELS[ECOSYSTEM_TYPE.CRITERIA_SANDBOX].plural,
				toCreate: routes.calibrate.ecosystem.createCriteriaSandbox({ companyId }),
			},
		}),
		[companyId],
	)

	const getNavEcosystems = useCallback(
		(type: ECOSYSTEM_TYPE) =>
			({
				...ROOT_NAVIGATION[type],
				ecosystems: ecosystems?.length
					? ecosystems
							.filter(item => item.type === type)
							.map(e => ({
								id: e.id,
								type: e.type,
								to: routes.calibrate.verticals.index({
									companyId,
									ecosystemId: e.id.toString(),
								}),
								rename: routes.calibrate.ecosystem.edit({
									companyId,
									ecosystemId: e.id.toString(),
								}),
								addVertical: routes.calibrate.verticals.create({
									companyId,
									ecosystemId: e.id.toString(),
								}),
								name: e.name,
								verticals: e.verticals.map(v => ({
									id: v.id,
									ecosystemId: e.id,
									to: routes.calibrate.verticals.index({
										companyId,
										ecosystemId: e.id.toString(),
									}),
									name: v.name,
									duplicate: routes.calibrate.verticals.duplicate({
										companyId,
										ecosystemId: e.id.toString(),
										verticalId: v.id.toString(),
										autoFocus: 'name',
									}),
									addPersona: routes.calibrate.persona.edit({
										companyId,
										ecosystemId: e.id.toString(),
										verticalId: v.id.toString(),
										personaId: v.personas?.[0]?.id?.toString() ?? null,
									}),
									personas:
										v.personas?.map(p => ({
											id: p.id,
											to: routes.calibrate.persona.index({
												companyId,
												ecosystemId: e.id.toString(),
												verticalId: v.id.toString(),
												personaId: p.id.toString(),
											}),
											type: p.type,
											title: p.expertise,
											status: p.status,
											priority: p.priority,
										})) ?? [],
								})),
							}))
					: null,
			}) satisfies CalibrateNav,
		[ROOT_NAVIGATION, ecosystems, companyId],
	)

	const navEcosystems = useMemo(() => getNavEcosystems(ECOSYSTEM_TYPE.DEFAULT), [getNavEcosystems])
	const navKeyAccounts = useMemo(() => getNavEcosystems(ECOSYSTEM_TYPE.KEY_ACCOUNT), [getNavEcosystems])
	const navCriteriaSandbox = useMemo(() => getNavEcosystems(ECOSYSTEM_TYPE.CRITERIA_SANDBOX), [getNavEcosystems])

	return (
		<section className="flex flex-col px-2">
			{[navEcosystems, navKeyAccounts, navCriteriaSandbox].map(
				item =>
					item.ecosystems && (
						<Fragment key={item.title}>
							<PanelSectionItem className="group justify-between">
								<h3 className="text-label-sm font-semibold tracking-widest text-neutral-3-fg">{item.title}</h3>
								{!readOnlySession && (
									<Link
										to={item.toCreate}
										className="flex items-center opacity-0 transition-opacity group-hover:opacity-100"
									>
										<Icon name="add" size="sm" />
									</Link>
								)}
							</PanelSectionItem>

							{item.ecosystems.length ? (
								<EcosystemItem ecosystems={item.ecosystems} />
							) : (
								!readOnlySession && (
									<PanelSectionItem
										asChild
										isTrigger
										className="gap-2 text-body-md text-link hover:bg-transparent hover:text-link-hover active:text-link-pressed"
									>
										<Link to={item.toCreate}>
											<Icon name="add" size="sm" aria-hidden />
											{`Add ${item.title}`}
										</Link>
									</PanelSectionItem>
								)
							)}
						</Fragment>
					),
			)}
		</section>
	)
}
