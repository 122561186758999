import { type LoaderFunctionArgs } from 'react-router'
import { verticalsQueries } from '#src/api/icp/company/verticals/queries'
import { client } from '#src/main'
import { ecosystemQuery } from '#src/routes/calibrate/ecosystem-management/ecosystem/queries'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	validateRouteParams(params, ['ecosystemId', 'verticalId'])
	const { company, companyId } = await getCompany(params)

	const [ecosystem, vertical] = await Promise.all([
		client.fetchQuery(ecosystemQuery(company.id, params.ecosystemId)),
		client.fetchQuery(verticalsQueries.item(company.id, Number(params.verticalId))),
	])

	return {
		handle: {
			companyId,
			ecosystemId: params.ecosystemId,
			verticalId: params.verticalId,
		},
		ecosystem,
		vertical,
	}
}
