import { type ActionFunctionArgs } from 'react-router'
import { personaMutations } from '#src/api/icp/company/persona/mutations'
import { GetTypesOfTasksFormSchema } from '#src/api/icp/company/persona/schemas'
import { showToast } from '#src/context/ToastContext'
import { parseFormDataNew } from '#src/utils/forms'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ params, request }: ActionFunctionArgs) => {
	validateRouteParams(params, ['verticalId'])
	const { company } = await getCompany(params)
	const formData = await request.formData()

	try {
		const submission = parseFormDataNew(formData, GetTypesOfTasksFormSchema)
		const res = await personaMutations.getTypesOfTasks(company.id, submission.value)

		showToast({
			message: `Successfully generated`,
			type: 'success',
		})

		return { ok: true, result: res.typesOfTasks }
	} catch (err) {
		return await serverFormErrorHandler(err)
	}
}
