import { type z } from 'zod'

export const getValidSortKeys = (data: string[], schema: z.SomeZodObject): string[] => {
	const keys = Object.keys(schema.shape)
	const ascDescKeys: string[] = []
	keys.forEach(key => {
		if ('shape' in schema.shape[key]) {
			/**
			 * this case handles when we want to sort by inner object values f.e.: conversation.createdAt
			 * it's not recursive, but feel free to change when you will get into this case
			 * */
			const keys2 = Object.keys(schema.shape[key].shape as z.SomeZodObject)
			keys2.forEach(key2 => {
				ascDescKeys.push(`${key}.${key2}`)
				ascDescKeys.push(`-${key}.${key2}`)
			})
		} else {
			ascDescKeys.push(key)
			ascDescKeys.push(`-${key}`)
		}
	})

	return data.filter(item => ascDescKeys.includes(item))
}

export const paramsToObjectSafe = <T extends z.SomeZodObject>(params: URLSearchParams, schema: T) => {
	const keys = Object.keys(schema.shape) as Array<keyof z.infer<typeof schema>>
	const result: Partial<Record<keyof z.infer<typeof schema>, string | string[]>> = {}
	for (const [key, value] of params.entries() as IterableIterator<[keyof z.infer<typeof schema>, string]>) {
		if (keys.includes(key)) {
			if (key in result) {
				if (Array.isArray(result[key])) {
					result[key] = [...result[key], value] as string[]
				} else {
					result[key] = [result[key] as string, value]
				}
			} else {
				result[key] = value
			}
		}
	}
	return result
}

export const buildAPISearchParams = (data: Record<string, string | number | string[] | number[] | undefined>) => {
	const query = new URLSearchParams({})
	Object.entries(data).forEach(([key, value]) => {
		if (value === undefined) {
			return
		}
		if (Array.isArray(value)) {
			value.forEach(val => {
				query.append(`${key}[]`, String(val))
			})
		} else {
			query.append(key, String(value))
		}
	})

	return query
}
