import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { type z } from 'zod'
import { CompanyFormSchema } from '#src/api/icp/company/base/schema'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import { StatusButton } from '#src/components/ui/status-button'
import { type CompanySchema } from '#src/routes/company/schema'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

type Props = {
	company: z.infer<typeof CompanySchema>
}

export const CompanyDetailsForm = (props: Props) => {
	const { company } = props
	const formProps = useForm<z.infer<typeof CompanyFormSchema>>({
		resolver: zodResolver(CompanyFormSchema),
		mode: 'onSubmit',
	})

	useEffect(() => {
		formProps.reset({
			name: company.name,
		})
	}, [formProps, company])

	const action = routes.admin.company.editDetails({ companyId: company.id })
	const method = 'PATCH'

	const isPending = useIsPending({
		formMethod: method,
		formAction: action,
	})
	const formId = 'company-form'

	return (
		<FormWrapper
			formProps={formProps}
			formId={formId}
			action={action}
			method={method}
			className="flex w-full flex-col gap-6"
		>
			<FormField fieldType="text" name="name" />
			<div className="flex justify-start">
				<StatusButton
					status={isPending ? 'pending' : 'idle'}
					disabled={isPending}
					type="submit"
					size="sm"
					className="min-w-32"
				>
					Save
				</StatusButton>
			</div>
		</FormWrapper>
	)
}
