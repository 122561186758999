import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { baseApi } from '#src/utils/endpoints'
import { type SessionAPISchema } from './schemas'

const base = (companyId: z.infer<typeof CompanySchema.shape.id>) =>
	`${baseApi}/company/${companyId}/chat/roleplay/session`

const endpoints = {
	list: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		page: number,
		sort: string[],
		filters: Record<string, string | string[]>,
	) => {
		const query = new URLSearchParams({
			page: page.toString(),
			...filters,
		})
		sort.forEach(value => {
			query.append('sort[]', value)
		})
		return `${base(companyId)}/list?${new URLSearchParams(query).toString()}`
	},
	listFilters: (companyId: z.infer<typeof CompanySchema.shape.id>) => `${base(companyId)}/list-filters`,
	item: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		sessionId: z.infer<typeof SessionAPISchema.shape.conversation.shape.id>,
	) => `${base(companyId)}/${sessionId}`,
	itemStatus: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		sessionId: z.infer<typeof SessionAPISchema.shape.conversation.shape.id>,
	) => `${base(companyId)}/${sessionId}/status`,
	create: (companyId: z.infer<typeof CompanySchema.shape.id>) => `${base(companyId)}/new`,
}

export { endpoints as companyChatRoleplaySessionEndpoints }
