import { type LoaderFunctionArgs } from 'react-router'
import { playsQueries } from '#src/api/icp/company/plays/queries'
import { client } from '#src/main'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	validateRouteParams(params, ['playId'])

	return {
		data: {
			play: await client.fetchQuery(playsQueries.item(company.id, Number(params.playId))),
		},
	}
}
