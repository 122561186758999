import { type LoaderFunctionArgs } from 'react-router'
import { companyAccountExportQueries } from '#src/api/icp/company/account-export/queries'
import { client } from '#src/main'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	const page = parseInt(new URL(request.url).searchParams.get('page') ?? '1')

	const values = {
		page: Number.isNaN(page) ? 1 : page,
	}

	const [statistics, jobs, settings] = await Promise.all([
		client.fetchQuery(companyAccountExportQueries.statistics(company.id)),
		client.fetchQuery(companyAccountExportQueries.list(company.id, values.page)),
		client.fetchQuery(companyAccountExportQueries.createSettings(company.id)),
	])

	return {
		values,
		data: {
			statistics,
			jobs,
			filters: settings.filters,
		},
	}
}
