import { Link, useLoaderData, useNavigate } from 'react-router'
import FiltersSection from '#src/components/FiltersSection'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { PageLayout } from '#src/components/ui/PageLayout'
import { Table } from '#src/components/ui/Table'
import { MODAL_NAME } from '#src/constants/modals'
import { useModals } from '#src/hooks/useModals'
import LayoutContentHack from '#src/layouts/LayoutContentHack'
import { AdminCompanyDeleteModal } from '#src/modals/admin/CompanyDelete'
import { routes } from '#src/utils/routes'
import { type LoaderRes } from '../loaders/companies'

export default function Companies() {
	const {
		data: { companies },
		filters,
	} = useLoaderData<LoaderRes>()

	const navigate = useNavigate()
	const { openModal } = useModals()

	return (
		<PageLayout
			title="Companies"
			actions={[
				{
					variant: 'default',
					icon: 'add',
					to: routes.admin.company.create,
					title: 'Create new',
				},
			]}
		>
			<LayoutContentHack>
				<Table
					stickyHeader
					filtersComponent={
						<FiltersSection
							total={companies.length}
							selected={filters.selected}
							values={filters.values}
							filters={[
								{
									type: 'search',
									name: 'search',
									label: 'Search',
								},
							]}
						/>
					}
					data={companies}
					onItemClick={item => navigate(routes.company.index({ companyId: item.id }))}
					columns={[
						{
							name: 'name',
							heading: 'Name',
							render: item => item.name,
						},
						{
							name: 'actions',
							heading: '',
							render: item => (
								<div
									className="inline-flex flex-row gap-1"
									onClick={e => {
										e.preventDefault()
										e.stopPropagation()
									}}
								>
									<Button variant="icon" size="icon" asChild>
										<Link to={routes.admin.company.edit({ companyId: item.id })}>
											<Icon name="edit" size="sm" />
										</Link>
									</Button>
									<Button
										variant="icon"
										size="icon"
										type="button"
										onClick={() =>
											openModal({
												name: MODAL_NAME.AdminCompanyDelete,
												component: AdminCompanyDeleteModal,
												props: { companyId: item.id },
											})
										}
									>
										<Icon name="trash" size="font" className="block text-status-danger-fg" />
									</Button>
								</div>
							),
						},
					]}
				/>
			</LayoutContentHack>
		</PageLayout>
	)
}
