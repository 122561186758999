import { type LoaderFunctionArgs } from 'react-router'
import { adminCompanyQueries } from '#src/api/icp/admin/company/queries'
import { client } from '#src/main'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	validateRouteParams(params, ['companyId'])
	const { company } = await getCompany(params)

	const qPage = parseInt(new URL(request.url).searchParams.get('page') ?? '1')
	const page = Number.isNaN(qPage) ? 1 : qPage

	return {
		company,
		credits: await client.fetchQuery(adminCompanyQueries.transactions(company.id, page)),
	}
}
