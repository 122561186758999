import { type LoaderFunctionArgs } from 'react-router'
import { companyCopilotConversationQueries } from '#src/api/icp/company/copilot/conversation/queries'
import { client } from '#src/main'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'
import { getUser } from '#src/utils/server/user'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	validateRouteParams(params, ['conversationId'])

	const { readOnlySession } = await getUser()
	if (readOnlySession) {
		throw new Response('Access Denied', {
			status: 403,
			statusText: 'Forbidden',
		})
	}

	return {
		handle: {
			conversationId: params.conversationId,
		},
		data: {
			general: await client.fetchQuery(companyCopilotConversationQueries.settings(company.id, params.conversationId)),
		},
	}
}
