import { Outlet, ScrollRestoration } from 'react-router'
import { ModalsProvider } from '#src/context/ModalsContext'

const Root = () => {
	return (
		<ModalsProvider>
			<Outlet />
			<ScrollRestoration getKey={({ pathname }) => pathname} />
		</ModalsProvider>
	)
}

export default Root
