import { type LoaderFunctionArgs } from 'react-router'
import { companyChatRoleplayScenarioQueries } from '#src/api/icp/company/chat/roleplay/scenario/queries'
import {
	ScenarioSessionAPISchema,
	ScenarioSessionsFiltersAPISchema,
} from '#src/api/icp/company/chat/roleplay/scenario/schemas'
import { client } from '#src/main'
import { validateRouteParams } from '#src/utils/misc'
import { getValidSortKeys, paramsToObjectSafe } from '#src/utils/requests'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	validateRouteParams(params, ['scenarioId'])

	const page = parseInt(new URL(request.url).searchParams.get('page') ?? '1')
	const filters = paramsToObjectSafe(new URL(request.url).searchParams, ScenarioSessionsFiltersAPISchema)
	const sort = getValidSortKeys(new URL(request.url).searchParams.getAll('sort'), ScenarioSessionAPISchema)

	return {
		sort,
		filters,
		scenario: await client.fetchQuery(companyChatRoleplayScenarioQueries.item(company.id, params.scenarioId)),
		participants: (
			await client.fetchQuery(companyChatRoleplayScenarioQueries.itemParticipants(company.id, params.scenarioId))
		).data,
		sessions: await client.fetchQuery(
			companyChatRoleplayScenarioQueries.itemSessions(company.id, params.scenarioId, page, sort, filters),
		),
	}
}
