import { z } from 'zod'
import { CompanySchema } from '#src/routes/company/schema'
import { fileCsv, zNumber } from '#src/utils/validation'
import { CONTACT_FINDER_SETTINGS_TYPE, FILE_TYPES, LRA_JOB_STATUS, LRA_JOB_STATUS_REASONING } from './constants'

export const LeadResearchAgentStatisticsAPISchema = z.object({
	inQueueCount: z.number(),
	inProgressCount: z.number(),
	completedCount: z.number(),
})

export const LeadResearchAgentListItemAPISchema = z.object({
	id: z.string(),
	createdAt: z.string(),
	status: z.nativeEnum(LRA_JOB_STATUS),
	title: z.string(),
	contactFinderEnabled: z.boolean(),
	contactWaterfallingEnabled: z.boolean(),
	comment: z.string().nullable(),
	importType: z.nativeEnum(FILE_TYPES).nullable(),
	statistic: z.object({
		accountCompletedCount: z.number(),
		accountContactCount: z.number(),
		accountCount: z.number(),
		accountInProgressCount: z.number(),
		accountInQueueCount: z.number(),
		accountWithErrorsCount: z.number(),
		contactCompletedCount: z.number(),
		contactCount: z.number(),
		contactInProgressCount: z.number(),
		contactInQueueCount: z.number(),
		contactWithEmailPhoneCount: z.number(),
		contactWithErrorsCount: z.number(),
		ibpRatio: z.number(),
		icpRatio: z.number(),
		// new fields after api changes
		icpStatusYesCount: z.number(),
		icpStatusNoCount: z.number(),
		icpStatusInconclusiveCount: z.number(),
		icpStatusNullCount: z.number(),
		ibpStatusYesCount: z.number(),
		ibpStatusNoCount: z.number(),
		ibpStatusInconclusiveCount: z.number(),
		ibpStatusNullCount: z.number(),
	}),
	accountsContactsImported: z.number().nullable(),
	userEmail: z.string().nullable(),
	emailVerificationEnabled: z.boolean(),
	contactEnrichingEnabled: z.boolean(),
	statusReason: z.nativeEnum(LRA_JOB_STATUS_REASONING).nullable(),
	archived: z.boolean(),
	tags: z.array(z.string()),
})
export const LeadResearchAgentItemAPISchema = LeadResearchAgentListItemAPISchema.merge(
	z.object({
		verticals: z.array(
			z.object({
				id: z.string(),
				title: z.string(),
				ecosystem: z.object({
					id: z.string(),
					title: z.string(),
				}),
			}),
		),
		importFileUrl: z.string().nullable(),
		// when fixes contactsPerPersonas empty array to null move it to list schema
		contactFinderSettings: z.object({
			type: z.nativeEnum(CONTACT_FINDER_SETTINGS_TYPE),
			contactsPerAccount: z.number().nullable(),
			contactsPerPersonas: z.record(z.string(), z.record(z.string(), z.number())).nullable(),
		}),
		plays: z.array(z.object({ id: z.string(), title: z.string() })),
		enrichmentCriterias: z.array(
			z.object({
				id: z.string(),
				title: z.string(),
			}),
		),
	}),
)

export const LeadResearchAgentJobFileFormSchema = z.object({
	type: z.nativeEnum(FILE_TYPES),
	file: fileCsv,
})

export const LeadResearchAgentJobVerticalsFormSchema = z.object({
	verticals: z.array(z.string()).nonempty('You need to select at least one vertical'),
})

export const LeadResearchAgentJobCriteriaFormSchema = z.object({
	enrichmentCriterias: z.array(zNumber(z.number())).default([]),
})

export const LeadResearchAgentJobSettingsContactFinderSettingsSchema = z.discriminatedUnion('type', [
	z.object({
		type: z.literal(CONTACT_FINDER_SETTINGS_TYPE.ContactsPerAccount),
		contactsPerAccount: zNumber(z.number().min(1).max(5)),
	}),
	z.object({
		type: z.literal(CONTACT_FINDER_SETTINGS_TYPE.ContactsPerPersonas),
		contactsPerPersonas: z.record(z.string(), z.record(z.string(), zNumber(z.number().min(0).max(3)))),
	}),
])

export const LeadResearchAgentJobSettingsFormSchema = z
	.discriminatedUnion('contactFinderEnabled', [
		z.object({
			contactFinderEnabled: z.literal(true),
			contactFinderSettings: LeadResearchAgentJobSettingsContactFinderSettingsSchema,
		}),
		z.object({
			contactFinderEnabled: z.literal(false),
		}),
	])
	.and(
		z.object({
			contactWaterfallingEnabled: z.boolean(),
			emailVerificationEnabled: z.boolean(),
			contactEnrichingEnabled: z.boolean(),
			plays: z.array(z.string()).default([]),
		}),
	)

export const LeadResearchAgentJobAdditionalInfoFormSchema = z.object({
	title: z.string(),
	comment: z.string().nullable(),
	tags: z.array(z.string()).default([]),
})

export const LeadResearchAgentJobFormSchema = LeadResearchAgentJobSettingsFormSchema.and(
	z
		.object({ companyId: CompanySchema.shape.id })
		.merge(LeadResearchAgentJobFileFormSchema)
		.merge(LeadResearchAgentJobVerticalsFormSchema)
		.merge(LeadResearchAgentJobCriteriaFormSchema)
		.merge(LeadResearchAgentJobAdditionalInfoFormSchema),
)
