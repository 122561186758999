import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { baseApi } from '#src/utils/endpoints'
import { buildAPISearchParams } from '#src/utils/requests'
import { type ScenarioAPISchema, type ScenarioParticipantAPISchema } from './schemas'

const base = (companyId: z.infer<typeof CompanySchema.shape.id>) =>
	`${baseApi}/company/${companyId}/chat/roleplay/scenario`

const endpoints = {
	list: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		page: number,
		filters?: Record<string, string | string[]>,
	) => {
		const searchParams = buildAPISearchParams({
			...filters,
			page,
		})
		return `${base(companyId)}?${searchParams.toString()}`
	},
	item: (companyId: z.infer<typeof CompanySchema.shape.id>, scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>) =>
		`${base(companyId)}/${scenarioId}`,
	delete: (companyId: z.infer<typeof CompanySchema.shape.id>, scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>) =>
		`${base(companyId)}/${scenarioId}`,
	voices: (companyId: z.infer<typeof CompanySchema.shape.id>) => `${base(companyId)}/voices`,
	templates: (companyId: z.infer<typeof CompanySchema.shape.id>) => `${base(companyId)}/templates`,
	itemUsers: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>,
	) => `${base(companyId)}/${scenarioId}/users`,
	itemParticipants: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>,
	) => `${base(companyId)}/${scenarioId}/participants?per_page=50`,
	createTestSession: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>,
	) => `${base(companyId)}/${scenarioId}/self`,
	assign: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>,
		userId: number,
	) => `${base(companyId)}/${scenarioId}/assign/${userId}`,
	update: (companyId: z.infer<typeof CompanySchema.shape.id>, scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>) =>
		`${base(companyId)}/${scenarioId}`,
	createTags: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>,
	) => `${base(companyId)}/${scenarioId}/tags`,
	create: (companyId: z.infer<typeof CompanySchema.shape.id>) => `${base(companyId)}`,
	users: (companyId: z.infer<typeof CompanySchema.shape.id>) => `${base(companyId)}/users`,
	unassign: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>,
		participantId: z.infer<typeof ScenarioParticipantAPISchema.shape.id>,
	) => `${base(companyId)}/${scenarioId}/${participantId}`,
	itemSessions: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>,
		page: number,
		sort: string[],
		filters: Record<string, string | string[]>,
	) => {
		const searchParams = buildAPISearchParams({
			...filters,
			sort,
			page,
		})
		return `${base(companyId)}/${scenarioId}/sessions?${searchParams.toString()}`
	},
}

export { endpoints as companyChatRoleplayScenarioEndpoints }
