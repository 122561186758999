import { type LoaderFunctionArgs } from 'react-router'
import { client } from '#src/main'
import { ecosystemQuery } from '#src/routes/calibrate/ecosystem-management/ecosystem/queries'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type LoaderResType = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	validateRouteParams(params, ['ecosystemId'])

	const ecosystem = await client.fetchQuery(ecosystemQuery(company.id, params.ecosystemId))

	return {
		data: {
			ecosystem,
		},
		values: {
			ecosystemId: params.ecosystemId,
		},
	}
}
