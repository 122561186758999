import { useMemo } from 'react'
import { useMatches } from 'react-router'
import { z } from 'zod'

const CrumbSchema = z
	.object({
		to: z.string().optional(),
		label: z.string(),
		icon: z.string().optional(),
		onClick: z.function().optional(),
	})
	.array()

const RouteCrumbsSchema = z.object({
	crumb: z.function().returns(CrumbSchema),
})

const useHeaderCrumbs = () => {
	const matches = useMatches()

	return useMemo(
		() =>
			matches
				.filter(match => RouteCrumbsSchema.safeParse(match.handle).success)
				.map(match => {
					const crumbs = (match.handle as z.infer<typeof RouteCrumbsSchema>).crumb(match.data)
					// if crumb to does not exists, lets add current match pathname as a "default pathname"
					return crumbs.map(crumb => ({
						...crumb,
						to: crumb.to ?? match.pathname,
					}))
				})
				.filter(crumb => CrumbSchema.safeParse(crumb).success)
				.flat(1),
		[matches],
	)
}

export default useHeaderCrumbs
