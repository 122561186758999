import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useActionData, useLoaderData } from 'react-router'
import { type z } from 'zod'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import GuestPageLayout from '#src/components/ui/GuestPageLayout'
import { StatusButton } from '#src/components/ui/status-button'
import {
	type AuthResetPasswordActionRes,
	type AuthResetPasswordLoaderRes,
} from '#src/routes/auth/routes/reset-password'
import { ResetPasswordFormSchema } from '#src/routes/auth/schema'
import { useIsPending } from '#src/utils/misc'

export const View = () => {
	const { user } = useLoaderData<AuthResetPasswordLoaderRes>()
	const actionData = useActionData<AuthResetPasswordActionRes>()

	const isPending = useIsPending()

	const form = useForm<z.infer<typeof ResetPasswordFormSchema>>({
		resolver: zodResolver(ResetPasswordFormSchema),
		defaultValues: {
			password: '',
			confirm: '',
		},
		mode: 'onChange',
	})

	return (
		<GuestPageLayout
			title={user.invitedBy ? `${user.invitedBy} invited you to make an account!` : 'Reset your password'}
			description={
				user.invitedBy && (
					<>
						Your email <span className="font-semibold">{user.email}</span> was invited. Set up a password to join the
						team.
					</>
				)
			}
		>
			<FormWrapper method="POST" formProps={form} actionResponse={actionData} className="flex w-full flex-col gap-4">
				<FormField fieldType="password" label="New password" name="password" autoFocus />
				<FormField fieldType="password" label="Reenter password" name="confirm" autoComplete="off" />
				<StatusButton type="submit" status={isPending ? 'pending' : 'idle'} disabled={isPending}>
					{user.invitedBy ? 'Get Started' : 'Reset'}
				</StatusButton>
			</FormWrapper>
		</GuestPageLayout>
	)
}
