import { type RouteObject } from 'react-router'
import { indexLoader, IndexView } from '#src/routes/calibrate/dashboard/routes'
import { action as deleteEcosystemAction } from '#src/routes/calibrate/ecosystem-management/ecosystem/actions/delete'
import { action as saveEcosystemAction } from '#src/routes/calibrate/ecosystem-management/ecosystem/actions/save'
import { action as reorderEcosystemsAction } from '#src/routes/calibrate/ecosystem-management/ecosystem/actions/sort'
import { ECOSYSTEM_TYPE } from '#src/routes/calibrate/ecosystem-management/ecosystem/constants'
import { loader as ecosystemLoader } from '#src/routes/calibrate/ecosystem-management/ecosystem/loaders/save'
import Ecosystem from '#src/routes/calibrate/ecosystem-management/ecosystem/views/save'

export default {
	id: 'calibrate-ecosystem',
	path: '',
	handle: {
		crumb: () => [
			{
				label: 'Value Proposition',
				icon: 'white-paper',
			},
		],
	},
	children: [
		{
			path: '',
			element: <IndexView />,
			loader: indexLoader,
			children: [
				{
					path: 'ecosystem',
					id: 'ecosystem',
					loader: ecosystemLoader,
					children: [
						{
							path: 'key-accounts',
							element: <Ecosystem type={ECOSYSTEM_TYPE.KEY_ACCOUNT} />,
						},
						{
							path: 'criteria-sandbox',
							element: <Ecosystem type={ECOSYSTEM_TYPE.CRITERIA_SANDBOX} />,
						},
						{
							path: ':ecosystemId?',
							element: <Ecosystem type={ECOSYSTEM_TYPE.DEFAULT} />,
							children: [
								{
									path: 'save',
									action: saveEcosystemAction,
								},
								{
									path: 'delete',
									action: deleteEcosystemAction,
								},
								{
									path: 'sort',
									action: reorderEcosystemsAction,
								},
							],
						},
					],
				},
			],
		},
	],
} satisfies RouteObject
