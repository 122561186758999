import { Link, useLoaderData, type LoaderFunctionArgs } from 'react-router'
import { statsQueries } from '#src/api/icp/company/stats/queries'
import { DashboardCard, DashboardCardCount } from '#src/components/dashboard-card'
import ProductTip from '#src/components/product-tip'
import { PageLayout } from '#src/components/ui/PageLayout'
import useAuth from '#src/hooks/useAuth'
import useCompany from '#src/hooks/useCompany'
import { client } from '#src/main'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { type IconName } from '@/icon-name'

type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)

	return {
		statistics: await client.fetchQuery(statsQueries.enable(company.id)),
	}
}

export default function EnableDashboard() {
	const { companyId } = useCompany()
	const { statistics } = useLoaderData<LoaderRes>()
	const { adminSession, userSession } = useAuth()
	const isAdminOrUser = adminSession || userSession

	const cardData = [
		{
			link: routes.enable.copilot.index({
				companyId,
				conversationId: null,
			}),
			icon: 'chat',
			title: (
				<div className="flex items-center gap-1">
					Copilot chats
					<ProductTip
						className="text-neutral-3-fg"
						content="Copilot Chat: Engage in conversations with simulated versions of your prospects, generated from real data, and receive live coaching on best outreach practices."
					/>
				</div>
			),
			count: statistics.copilot,
			countSuffix: statistics.copilot !== 1 ? 'Chats' : 'Chat',
			condition: true,
		},
		{
			link: routes.enable.roleplay.index({ companyId }),
			icon: 'voice-activate',
			title: (
				<div className="flex items-center gap-1">
					Roleplay training
					<ProductTip
						className="text-neutral-3-fg"
						content="Roleplay training: Practice sales conversations with AI-generated responses, and receive feedback on your performance to improve your sales skills."
					/>
				</div>
			),
			countComponent: (
				<div className="flex gap-4">
					<DashboardCardCount
						count={statistics.sessions}
						countSuffix={statistics.sessions !== 1 ? 'Sessions' : 'Session'}
					/>
				</div>
			),
			condition: true,
		},
		{
			link: routes.enable.roleplay.scenario.index({ companyId }),
			icon: 'user-group',
			title: (
				<div className="flex items-center gap-1">
					Roleplay scenarios
					<ProductTip
						className="text-neutral-3-fg"
						content="Roleplay scenarios: Create roleplays and invite your sales persons to participate in sessions"
					/>
				</div>
			),
			countComponent: (
				<div className="flex gap-4">
					<DashboardCardCount
						count={statistics.scenarios}
						countSuffix={statistics.scenarios !== 1 ? 'Scenarios' : 'Scenario'}
					/>
				</div>
			),
			condition: isAdminOrUser,
		},
		{
			link: routes.enable.glossary.index({ companyId }),
			icon: 'notebook-reference',
			title: (
				<div className="flex items-center gap-1">
					Industry jargons
					<ProductTip
						className="text-neutral-3-fg"
						content="Industry Jargon: Specialized terms and phrases commonly used within a specific industry or field, enriched with AI. Synchronized with an ecosystem of your choice to enhance relevance and contextual accuracy."
					/>
				</div>
			),
			count: statistics.terms,
			countSuffix: statistics.terms ? 'Terms' : 'Term',
			condition: true,
		},
		{
			link: routes.enable.personas.index({
				companyId,
				verticalId: null,
			}),
			icon: 'user-multiple',
			title: (
				<div className="flex items-center gap-1">
					Personas
					<ProductTip
						className="text-neutral-3-fg"
						content="Personas: AI-powered personas that help enrich the sales process by understanding customer preferences and behaviors."
					/>
				</div>
			),
			count: statistics.personas,
			countSuffix: statistics.personas !== 1 ? 'Personas' : 'Persona',
			condition: true,
		},
		{
			link: routes.enable.plays.index({ companyId }),
			icon: 'continue',
			title: 'Plays',
			count: statistics.plays,
			countSuffix: statistics.plays ? 'Plays' : 'Play',
			condition: true,
		},
		{
			link: routes.enable.contactsEnrich.index({ companyId }),
			icon: 'share-knowledge',
			title: 'Waterfall Enrichment',
			count: statistics.enriched_contacts,
			countSuffix: statistics.enriched_contacts ? 'Contacts' : 'Contact',
			condition: true,
		},
	]

	return (
		<PageLayout
			title="Enable"
			description="Equip your team with AI-powered insights to personalize interactions and enhance sales engagement, delivering a customer-centric approach that prioritizes the unique needs and preferences of each prospect."
		>
			<div className="grid w-full grid-cols-3 grid-rows-1 gap-6">
				{cardData.map(
					({ link, icon, title, count, countSuffix, condition, countComponent }) =>
						condition && (
							<Link key={link} to={link} className="block">
								<DashboardCard icon={icon as IconName} title={title} isHoverable>
									{countComponent ? countComponent : <DashboardCardCount count={count} countSuffix={countSuffix} />}
								</DashboardCard>
							</Link>
						),
				)}
			</div>
		</PageLayout>
	)
}
