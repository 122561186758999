import { type LoaderFunctionArgs } from 'react-router'
import { userQueries } from '#src/api/icp/company/user/queries'
import { client } from '#src/main'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ request, params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	const page = parseInt(new URL(request.url).searchParams.get('page') ?? '1')

	const [members, roles, allRoles] = await Promise.all([
		client.fetchQuery(userQueries.list(company.id, page)),
		client.fetchQuery(userQueries.roles(company.id)),
		client.fetchQuery(userQueries.allRoles(company.id)),
	])

	return {
		data: {
			members,
			roles,
			allRoles,
		},
	}
}
