import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useFetcher } from 'react-router'
import { type z } from 'zod'
import {
	type LeadResearchAgentItemAPISchema,
	LeadResearchAgentJobAdditionalInfoFormSchema,
} from '#src/api/organise/lists/schemas'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import LoaderRequiredComponent from '#src/components/LoaderRequiredComponent'
import { Dialog } from '#src/components/ui/dialog'
import { type MultiSelectOption } from '#src/components/ui/MultiSelect'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { type ActionRes } from '#src/routes/organize/lead-research-agent/actions/edit'
import { type LoaderRes } from '#src/routes/organize/lead-research-agent/loaders/edit'
import { routes } from '#src/utils/routes'

export type LeadResearchAgentJobEditModalProps = {
	jobId: z.infer<typeof LeadResearchAgentItemAPISchema.shape.id>
}

export const LeadResearchAgentJobEditModal = (props: LeadResearchAgentJobEditModalProps) => {
	const { companyId } = useCompany()

	return (
		<LoaderRequiredComponent<LoaderRes, LeadResearchAgentJobEditModalProps>
			route={routes.leadResearchAgent.edit({ companyId, jobId: props.jobId })}
			component={ModalInner}
			initialProps={props}
		/>
	)
}

const FORM_ID = (jobId: z.infer<typeof LeadResearchAgentItemAPISchema.shape.id>) => `lra-job-edit-${jobId}`

const ModalInner = (props: LoaderRes & LeadResearchAgentJobEditModalProps) => {
	const {
		data: { job, tags },
	} = props
	const { companyId } = useCompany()
	const actionFetcher = useFetcher<ActionRes>()

	const form = useForm<z.infer<typeof LeadResearchAgentJobAdditionalInfoFormSchema>>({
		resolver: zodResolver(LeadResearchAgentJobAdditionalInfoFormSchema),
		defaultValues: {
			title: job.title,
			comment: job.comment,
			tags: job.tags,
		},
		mode: 'onSubmit',
	})

	const { closeModal } = useModals()

	return (
		<Dialog
			size="xl"
			defaultOpen={true}
			onOpenChange={isOpen => !isOpen && closeModal(MODAL_NAME.LeadResearchAgentJobEdit)}
			dialogHeading="Edit Lead Research Agent Job"
			isSubmitting={actionFetcher.state === 'submitting'}
			actions={[
				{
					type: 'cancel',
					label: 'Cancel',
				},
				{ type: 'submit', formId: FORM_ID(job.id), label: 'Save' },
			]}
		>
			<FormWrapper
				formId={FORM_ID(job.id)}
				formProps={form}
				method="POST"
				action={routes.leadResearchAgent.edit({ companyId, jobId: props.jobId })}
				className="flex flex-col gap-6"
				fetcher={actionFetcher}
				actionResponse={actionFetcher.data}
			>
				<FormField name="title" fieldType="text" label="Name" placeholder="Enter job name" clearable />
				<FormField
					name="comment"
					fieldType="textarea"
					label="Note"
					placeholder="You can type extra notes about your job"
					rows={10}
				/>
				<FormField
					fieldType="multiselect"
					name="tags"
					label="Tags"
					options={tags as MultiSelectOption[]}
					enableCreate
				/>
			</FormWrapper>
		</Dialog>
	)
}
