import { type RouteObject } from 'react-router'
import { action as deleteAction } from './actions/delete'
import { action as editAction } from './actions/edit'
import { action as inviteAction } from './actions/invite'
import { action as newAction } from './actions/new'
import { action as saveTagsAction } from './actions/save-tags'
import { action as unassignAction } from './actions/unassign'
import { loader as attemptsLoader } from './loaders/attempts'
import { loader as deleteLoader } from './loaders/delete'
import { loader as editLoader } from './loaders/edit'
import { loader as inviteLoader } from './loaders/invite'
import { loader as itemLoader, type LoaderRes as RoleplayScenarioLoaderResponse } from './loaders/item'
import { loader as listLoader } from './loaders/list'
import { loader as newLoader } from './loaders/new'
import { loader as rootLoader } from './loaders/root'
import { loader as saveTagsLoader } from './loaders/save-tags'
import { loader as testSessionLoader } from './loaders/test-session'
import Attempts from './views/attempts'
import Edit from './views/edit'
import Invite from './views/invite'
import Item from './views/item'
import List from './views/list'
import New from './views/new'
import Report from './views/report'
import { View as SaveTagsView } from './views/save-tags'

const roleplayScenariosRouter = {
	path: 'roleplay/scenarios',
	loader: rootLoader,
	handle: {
		layout: {
			enableHeader: true,
		},
		crumb: () => [
			{
				label: 'Roleplay scenarios',
				icon: 'user-group',
			},
		],
	},
	children: [
		{
			path: '',
			element: <List />,
			loader: listLoader,
			handle: {
				layout: {
					fullWidth: true,
				},
			},
			children: [
				{
					path: 'new',
					element: <New />,
					loader: newLoader,
					action: newAction,
				},
				{
					path: ':scenarioId/edit/tags',
					loader: saveTagsLoader,
					action: saveTagsAction,
					element: <SaveTagsView />,
				},
			],
		},
		{
			path: ':scenarioId',
			element: <Item />,
			loader: itemLoader,
			handle: {
				crumb: (data: RoleplayScenarioLoaderResponse) => [
					{
						label: data.scenario.name ?? data.scenario.title,
						icon: 'user-service-desk',
					},
				],
			},
			children: [
				{
					path: 'invite',
					element: <Invite />,
					loader: inviteLoader,
					action: inviteAction,
				},
				{
					path: 'test-session',
					loader: testSessionLoader,
				},
				{
					path: 'attempts/:sessionId',
					loader: attemptsLoader,
					element: <Attempts />,
				},
				{
					path: 'delete',
					loader: deleteLoader,
					action: deleteAction,
				},
				{
					path: 'edit',
					loader: editLoader,
					action: editAction,
					element: <Edit />,
				},
				{
					path: 'unassign/:participantId',
					action: unassignAction,
				},
			],
		},
		{
			path: 'report',
			element: <Report />,
			handle: {
				crumb: () => [
					{
						label: 'Report',
					},
				],
			},
		},
	],
} satisfies RouteObject

export default roleplayScenariosRouter
