import { type LoaderFunctionArgs } from 'react-router'
import { statsQueries } from '#src/api/icp/company/stats/queries'
import { valuePropositionQueries } from '#src/api/icp/company/value-proposition/queries'
import { client } from '#src/main'
import { getWordCount } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	const [statistics, statisticsDashboard, valueProposition] = await Promise.all([
		statsQueries.valueProposition(company.id),
		client.fetchQuery(statsQueries.dashboard(company.id)),
		client.fetchQuery(valuePropositionQueries.list(company.id)),
	])

	return {
		data: {
			statistics,
			statisticsDashboard,
			wordCount: valueProposition.valuePropositions
				.map(item => getWordCount(`${item.name ?? ''} ${item.description ?? ''}`))
				.reduce((partialSum, a) => partialSum + a, 0),
		},
	}
}
