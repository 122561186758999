import { useLoaderData } from 'react-router'
import { type CriteriaSaveLoaderType } from '#src/routes/calibrate/ecosystem-management/criteria/routes/save'
import { Form } from './components'

export const View = () => {
	const {
		values: { key },
	} = useLoaderData<CriteriaSaveLoaderType>()

	return <Form key={key} />
}
