import { z } from 'zod'
import { zString } from '#src/utils/validation'

export const PlaysExpertsItemAPISchema = z.object({
	id: z.number(),
	fullName: z.string(),
	about: z.string(),
	linkedin: z.string(),
})

export const PlaysExpertsListAPISchema = z.array(PlaysExpertsItemAPISchema)

export const PlaysExpertsFormSchema = z.object({
	fullName: zString(),
	about: zString(),
	linkedin: z.string().url(),
})
