import Paginator from '#src/components/ui/Paginator'
import { Table, type TableProps } from '#src/components/ui/Table'

type Props<T> = TableProps<T> & {
	currentPage: number
	totalPages: number
	onPageChange: (data: number) => void | Promise<void>
}

export const PaginatedTable = <T extends Record<string, unknown>>(props: Props<T>) => {
	const { currentPage, totalPages, onPageChange, ...tableProps } = props

	return (
		<div className="flex w-full flex-col gap-6">
			<Table totalPages={totalPages} {...tableProps} />
			<Paginator
				className="mb-0 mt-0"
				currentPage={currentPage}
				totalPages={totalPages}
				setCurrentPage={onPageChange}
				paginatorPosition="center"
			/>
		</div>
	)
}
