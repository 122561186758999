import { type RouteObject } from 'react-router'
import { action as deleteAction } from './actions/delete'
import { action as editAction } from './actions/edit'
import { action as newAction } from './actions/new'
import { loader as itemLoader } from './loaders/item'
import { loader as listLoader } from './loaders/list'
import { View as Delete } from './views/delete'
import { View as Edit } from './views/edit'
import { View as List } from './views/list'
import { View as New } from './views/new'

const adminPlaysExpertsRouter = {
	path: 'plays-experts',
	handle: {
		layout: {
			enableHeader: true,
			fullWidth: true,
		},
		crumb: () => [
			{
				label: 'Plays experts',
			},
		],
	},
	loader: listLoader,
	element: <List />,
	children: [
		{
			path: 'new',
			element: <New />,
			action: newAction,
		},
		{
			path: ':expertId',
			loader: itemLoader,
			children: [
				{
					path: 'edit',
					action: editAction,
					element: <Edit />,
				},
				{
					path: 'delete',
					action: deleteAction,
					element: <Delete />,
				},
			],
		},
	],
} satisfies RouteObject

export default adminPlaysExpertsRouter
