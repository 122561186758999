import { type ComponentType, type ReactNode } from 'react'
import { Link, useLoaderData } from 'react-router'
import { type z } from 'zod'
import { type VerticalListAPISchema } from '#src/api/icp/company/verticals/schemas'
import ProductTip from '#src/components/product-tip'
import { Icon } from '#src/components/ui/icon'
import useCompany from '#src/hooks/useCompany'
import { PersonasCheckboxGroupProvider } from '#src/routes/calibrate/ecosystem-management/verticals/context/PersonasCheckboxGroupContext'
import { type LoaderResType } from '#src/routes/calibrate/ecosystem-management/verticals/loaders'
import { PersonaList, PersonaListHeader } from '#src/routes/calibrate/ecosystem-management/verticals/views/components'
import { routes } from '#src/utils/routes'

export const usePersonasGroup = (verticals: z.infer<typeof VerticalListAPISchema>) => {
	const {
		handle: { ecosystemId, readOnlySession },
		ecosystem,
	} = useLoaderData<LoaderResType>()
	const { companyId } = useCompany()

	const header = {
		collapsible: {
			options: { sticky: true, collapsible: true, defaultCollapsed: true },
			columns: [
				{
					children: (
						<p className="flex items-center gap-1 text-body-md font-semibold text-neutral-2-fg">
							Personas
							<span className="font-normal text-neutral-3-fg">
								{ecosystem?.verticals.reduce(
									(totalPersonaCount, vertical) =>
										totalPersonaCount + (vertical.personas?.length ? vertical.personas.length : 0),
									0,
								) ?? 0}
							</span>
							<ProductTip content="Personas are generated using AI based on market research and customer data. They refine pain point generation, persona mapping, and sales coach modeling. To add or edit a persona, click “+ Add” and complete the form." />
						</p>
					),
				},
				...verticals.map(vertical => ({
					children: (
						<PersonaListHeader
							key={vertical.id}
							vertical={vertical}
							ecosystemId={ecosystemId}
							readOnlySession={readOnlySession}
						/>
					),
				})),
			],
		},
	}

	const body = {
		rows: [
			{
				columns: [
					{ children: '' },
					...verticals.map(vertical => {
						const v = ecosystem?.verticals.find(v => v.id === vertical.id)

						if (!v?.personas?.length || !ecosystem)
							return {
								className: 'align-middle py-4',
								children: (
									<section className="flex w-full flex-col items-center justify-center gap-2">
										<Icon name="user-avatar" size="lg" className="text-neutral-3-fg" />
										<p className="text-center text-body-sm font-normal">
											You don&apos;t have any personas in <br />
											<span className="font-semibold">{vertical.name}</span>
										</p>
										{readOnlySession ? null : (
											<Link
												to={routes.calibrate.persona.edit({
													companyId,
													ecosystemId: ecosystemId.toString(),
													verticalId: vertical.id.toString(),
													personaId: null,
												})}
												className="flex flex-nowrap items-center gap-1 bg-transparent py-2 text-button-sm text-link hover:text-link-hover active:text-link-pressed"
											>
												<Icon name="add" /> Add Persona
											</Link>
										)}
									</section>
								),
							}

						return {
							className: 'align-top',
							children: (
								<section className="flex w-full flex-col items-start justify-start gap-2">
									<PersonaList personas={v.personas} ecosystemId={ecosystemId} verticalId={v.id.toString()} />
								</section>
							),
						}
					}),
				],
			},
		],
	}

	return {
		provider: PersonasCheckboxGroupProvider as ComponentType<{ children: ReactNode }>,
		providerProps: { verticals: ecosystem?.verticals ?? [] },
		...header,
		body,
	}
}
