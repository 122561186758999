import { useQuery } from '@tanstack/react-query'
import { type z } from 'zod'
import { verticalsQueries } from '#src/api/icp/company/verticals/queries'
import { type VerticalAPISchema } from '#src/api/icp/company/verticals/schemas'
import { BASE_REFETCH_INTERVAL } from '#src/constants/refetchIntervals'
import useCompany from '#src/hooks/useCompany'

type Props = {
	verticalId: number | string
	initialData: z.infer<typeof VerticalAPISchema>
}

const useVerticalDetails = (props: Props) => {
	const { verticalId, initialData } = props
	const { company } = useCompany()

	const { data, isLoading } = useQuery({
		...verticalsQueries.item(company.id, Number(verticalId)),
		initialData,
		refetchInterval: ({ state: { data: currentData } }) =>
			currentData?.prompt?.status && ['Pending', 'In Progress'].includes(currentData.prompt.status)
				? BASE_REFETCH_INTERVAL
				: false,
		refetchOnWindowFocus: true,
	})

	return {
		data,
		isLoading,
	}
}

export default useVerticalDetails
