import { type z } from 'zod'
import { type CompanyUserAPISchema } from '#src/api/icp/company/user/schemas'
import { type CompanySchema } from '#src/routes/company/schema'
import { baseApi } from '#src/utils/endpoints'

const base = (companyId: z.infer<typeof CompanySchema>['id']) => `${baseApi}/company/${companyId}/user`

const endpoints = {
	list: (companyId: z.infer<typeof CompanySchema>['id'], page: number, perPage?: number) => {
		{
			const query = new URLSearchParams({ page: page.toString() })

			if (perPage) {
				query.append('per_page', perPage.toString())
			}

			return `${base(companyId)}/list?${new URLSearchParams(query).toString()}`
		}
	},
	item: (companyId: z.infer<typeof CompanySchema>['id'], itemId: z.infer<typeof CompanyUserAPISchema>['id']) =>
		`${base(companyId)}/${itemId}`,
	invite: (companyId: z.infer<typeof CompanySchema>['id']) => `${base(companyId)}/invite`,
	update: (companyId: z.infer<typeof CompanySchema>['id'], itemId: z.infer<typeof CompanyUserAPISchema>['id']) =>
		`${base(companyId)}/${itemId}`,
	deactivate: (companyId: z.infer<typeof CompanySchema>['id'], itemId: z.infer<typeof CompanyUserAPISchema>['id']) =>
		`${base(companyId)}/deactivate/${itemId}`,
	activate: (companyId: z.infer<typeof CompanySchema>['id'], itemId: z.infer<typeof CompanyUserAPISchema>['id']) =>
		`${base(companyId)}/activate/${itemId}`,
	reset: (companyId: z.infer<typeof CompanySchema>['id']) => `${base(companyId)}/reset-password`,
	roles: (companyId: z.infer<typeof CompanySchema>['id']) => `${base(companyId)}/roles`,
	allRoles: (companyId: z.infer<typeof CompanySchema>['id']) => `${base(companyId)}/roles/all`,
}

export { endpoints as userEndpoints }
