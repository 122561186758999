import { type z } from 'zod'
import { client } from '#src/main'
import { type CompanySchema } from '#src/routes/company/schema'
import { fetch } from '#src/utils/safeFetch'
import { userEndpoints } from './endpoints'
import { userQueriesKeys } from './queries'
import { type CompanyUserAPISchema, type EditFormSchema, type InviteFormSchema } from './schemas'

const mutations = {
	invite: async (companyId: z.infer<typeof CompanySchema>['id'], data: z.infer<typeof InviteFormSchema>) => {
		await fetch(userEndpoints.invite(companyId), {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
		await client.invalidateQueries({
			queryKey: userQueriesKeys.all,
		})
	},
	update: async (
		companyId: z.infer<typeof CompanySchema>['id'],
		itemId: z.infer<typeof CompanyUserAPISchema>['id'],
		data: z.infer<typeof EditFormSchema>,
	) => {
		await fetch(userEndpoints.update(companyId, itemId), {
			method: 'PUT',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
		await client.invalidateQueries({
			queryKey: userQueriesKeys.all,
		})
	},
	deactivate: async (
		companyId: z.infer<typeof CompanySchema>['id'],
		itemId: z.infer<typeof CompanyUserAPISchema>['id'],
	) => {
		await fetch(userEndpoints.deactivate(companyId, itemId), {
			method: 'PUT',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		})
		await client.invalidateQueries({
			queryKey: userQueriesKeys.all,
		})
	},
	activate: async (
		companyId: z.infer<typeof CompanySchema>['id'],
		itemId: z.infer<typeof CompanyUserAPISchema>['id'],
	) => {
		await fetch(userEndpoints.activate(companyId, itemId), {
			method: 'PUT',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		})
		await client.invalidateQueries({
			queryKey: userQueriesKeys.all,
		})
	},
	reset: async (
		companyId: z.infer<typeof CompanySchema>['id'],
		email: z.infer<typeof CompanyUserAPISchema>['email'],
	) => {
		await fetch(userEndpoints.reset(companyId), {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ email }),
		})
		await client.invalidateQueries({
			queryKey: userQueriesKeys.all,
		})
	},
}

export { mutations as userMutations }
