import { type z } from 'zod'
import { statsQueriesKeys } from '#src/api/icp/company/stats/queries'
import { client } from '#src/main'
import { type CompanySchema } from '#src/routes/company/schema'
import { fetch } from '#src/utils/safeFetch'
import { playsTemplatesEndpoints } from './endpoints'
import { playsTemplatesQueriesKeys } from './queries'
import { type PlaysTemplatesFormSchema, type PlaysTemplatesItemAPISchema } from './schemas'

const mutations = {
	create: async (companyId: z.infer<typeof CompanySchema.shape.id>, data: z.infer<typeof PlaysTemplatesFormSchema>) => {
		await fetch(playsTemplatesEndpoints.create(companyId), {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
		await client.invalidateQueries({
			queryKey: playsTemplatesQueriesKeys.all,
		})
		await client.invalidateQueries({
			queryKey: statsQueriesKeys.all,
		})
	},
	update: async (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		templateId: z.infer<typeof PlaysTemplatesItemAPISchema.shape.id>,
		data: z.infer<typeof PlaysTemplatesFormSchema>,
	) => {
		await fetch(playsTemplatesEndpoints.update(companyId, templateId), {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
		await client.invalidateQueries({
			queryKey: playsTemplatesQueriesKeys.all,
		})
	},
	delete: async (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		templateId: z.infer<typeof PlaysTemplatesItemAPISchema.shape.id>,
	) => {
		await fetch(playsTemplatesEndpoints.delete(companyId, templateId), {
			method: 'DELETE',
			headers: {
				Accept: 'application/json',
			},
		})
		await client.invalidateQueries({
			queryKey: [...playsTemplatesQueriesKeys.all, 'list'],
		})
	},
}

export { mutations as playsTemplatesMutations }
