import { useNavigation } from 'react-router'
import { type ClassName } from '#src/types/styles'
import { cn } from '#src/utils/misc'

type Props = {
	show?: boolean
	className?: ClassName
}

export default function PageLoader(props: Props) {
	const { show, className } = props
	const { state } = useNavigation()

	return (
		<div
			className={cn(
				'fixed left-0 right-0 top-0 z-[9999] h-[3px] w-full bg-white transition-opacity',
				'bg-loading-gradient',
				"after:absolute after:left-0 after:right-0 after:block after:h-full after:animate-loading after:bg-loading-mask after:content-['']",
				state === 'loading' || show ? 'opacity-100 after:opacity-100' : 'opacity-0 after:opacity-0',
				className,
			)}
		/>
	)
}
