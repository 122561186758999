import { type ActionFunctionArgs } from 'react-router'
import { adminCompanyMutations } from '#src/api/icp/admin/company/mutations'
import { AdminCompanyFormSchema } from '#src/api/icp/admin/company/schemas'
import { MODAL_NAME } from '#src/constants/modals'
import { parseFormDataNew } from '#src/utils/forms'
import { routes } from '#src/utils/routes'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'
import { closeModalServer } from '#src/utils/server/modals'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ request }: ActionFunctionArgs) => {
	const formData = await request.formData()

	try {
		const submission = parseFormDataNew(formData, AdminCompanyFormSchema)
		const company = await adminCompanyMutations.create(submission.value)

		closeModalServer(MODAL_NAME.AdminCompanyCreate, routes.calibrate.valueProposition.index({ companyId: company.id }))

		return { ok: true }
	} catch (e) {
		return await serverFormErrorHandler(e)
	}
}
