import { z } from 'zod'
import { PLAY_STATUS } from '#src/api/icp/company/plays/constants'
import { zNumber, zString } from '#src/utils/validation'

export const PlaysItemAPISchema = z.object({
	id: z.number(),
	name: z.string(),
	instructions: z.string(),
	inCopilot: z.boolean(),
	inCrm: z.boolean(),
	createdAt: z.string(),
	personaTypes: z.array(z.string()),
	expertise: z.array(z.string()),
	tags: z.array(z.string()),
	status: z.nativeEnum(PLAY_STATUS),
	createdBy: z.string(),
	conditions: z.array(
		z.object({
			variable_id: z.number(),
			operator: z.string(),
			value: z.string().nullable(),
			condition: z.string(),
		}),
	),
	templateId: z.number().nullable(),
	verticals: z.array(z.number()),
})

export const PlaysListItemAPISchema = PlaysItemAPISchema.array()

export const PlaysFormSchema = z.object({
	name: zString(),
	instructions: zString(),
	inCopilot: z.boolean(),
	inCrm: z.boolean(),
	personaTypes: z.array(z.string()).default([]),
	expertise: z.array(z.string()).default([]),
	tags: z.array(z.string()).default([]),
	status: z.nativeEnum(PLAY_STATUS),
	conditions: z
		.array(
			z.object({
				variable_id: zNumber(z.number()),
				operator: z.string(),
				value: z.string().nullable(),
				condition: z.string(),
			}),
		)
		.default([]),
	templateId: zNumber(z.number()).optional(),
	verticals: z.array(zNumber(z.number())).default([]),
})

export const PlaysCriteriasAPISchema = z
	.object({
		active: z.boolean(),
		id: z.number(),
		name: z.string(),
	})
	.array()

export const PlaysOperatorsAPISchema = z
	.object({
		name: z.string(),
		requires_input: z.boolean(),
	})
	.array()
