import { type ReactNode } from 'react'
import Actions from '#src/layouts/header/Actions'
import Crumbs from '#src/layouts/header/Crumbs'
import { PanelToggle } from '#src/layouts/Panel'
import { cn } from '#src/utils/misc'

const Header = ({ className, panelToggle }: { className?: string; panelToggle?: ReactNode }) => (
	<header
		className={cn(
			'sticky left-0 top-0 z-40',
			'flex items-center justify-between gap-4',
			'h-[var(--builder-header-height)] px-3 py-2',
			'border-b border-neutral-1-bd bg-neutral-1-bg',
			'pl-[calc(0.75rem+var(--navsidebar-width))]',
			className,
		)}
	>
		<div className="flex items-center gap-2">
			{panelToggle && <PanelToggle />}
			<Crumbs />
		</div>
		<Actions />
	</header>
)

export default Header
