import { type ActionFunctionArgs } from 'react-router'
import { orgListsMutations } from '#src/api/organise/lists/mutations'
import { LeadResearchAgentJobAdditionalInfoFormSchema } from '#src/api/organise/lists/schemas'
import { MODAL_NAME } from '#src/constants/modals'
import { showToast } from '#src/context/ToastContext'
import { parseFormDataNew } from '#src/utils/forms'
import { validateRouteParams } from '#src/utils/misc'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'
import { closeModalServer } from '#src/utils/server/modals'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ request, params }: ActionFunctionArgs) => {
	validateRouteParams(params, ['jobId'])
	const formData = await request.formData()

	try {
		const submission = parseFormDataNew(formData, LeadResearchAgentJobAdditionalInfoFormSchema)
		await orgListsMutations.update(params.jobId, submission.value)

		showToast({
			message: 'Job edited successfully',
			type: 'success',
		})
		closeModalServer(MODAL_NAME.LeadResearchAgentJobEdit)

		return { ok: true }
	} catch (e) {
		return await serverFormErrorHandler(e)
	}
}
