import { type z } from 'zod'
import { playsQueriesKeys } from '#src/api/icp/company/plays/queries'
import { client } from '#src/main'
import { fetch } from '#src/utils/safeFetch'
import { adminPlaysExpertsEndpoints } from './endpoints'
import { adminPlaysExpertsQueriesKeys } from './queries'
import { type PlaysExpertsFormSchema, type PlaysExpertsItemAPISchema } from './schemas'

const mutations = {
	create: async (data: z.infer<typeof PlaysExpertsFormSchema>) => {
		await fetch(adminPlaysExpertsEndpoints.create(), {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
		await client.invalidateQueries({
			queryKey: adminPlaysExpertsQueriesKeys.all,
		})
		await client.invalidateQueries({
			queryKey: playsQueriesKeys.all,
		})
	},
	update: async (
		expertId: z.infer<typeof PlaysExpertsItemAPISchema.shape.id>,
		data: z.infer<typeof PlaysExpertsFormSchema>,
	) => {
		await fetch(adminPlaysExpertsEndpoints.edit(expertId), {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
		await client.invalidateQueries({
			queryKey: adminPlaysExpertsQueriesKeys.all,
		})
		await client.invalidateQueries({
			queryKey: playsQueriesKeys.all,
		})
	},
	delete: async (expertId: z.infer<typeof PlaysExpertsItemAPISchema.shape.id>) => {
		await fetch(adminPlaysExpertsEndpoints.delete(expertId), {
			method: 'DELETE',
			headers: {
				Accept: 'application/json',
			},
		})
		await client.invalidateQueries({
			queryKey: adminPlaysExpertsQueriesKeys.list(),
		})
		await client.invalidateQueries({
			queryKey: playsQueriesKeys.all,
		})
	},
}

export { mutations as adminPlaysExpertsMutations }
