import { type ButtonHTMLAttributes, forwardRef, type ReactNode } from 'react'
import DeleteConfirm from '#src/components/delete-confirm'
import useCompany from '#src/hooks/useCompany'
import { cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

export interface DeleteBatchPersonaForm extends ButtonHTMLAttributes<HTMLButtonElement> {
	ecosystemId: string
	verticalId: string
	personaId: string
	children: ReactNode
}

export const DeletePersonaAsyncForm = forwardRef<HTMLButtonElement, DeleteBatchPersonaForm>(
	({ ecosystemId, verticalId, personaId, children, className, ...props }, ref) => {
		const { companyId } = useCompany()
		const action = routes.calibrate.persona.delete({
			companyId,
			ecosystemId: ecosystemId,
			verticalId: verticalId,
			personaId: personaId,
		})

		return (
			<DeleteConfirm
				trigger={
					<button {...props} ref={ref} type="button" className={cn('flex items-center justify-center', className)}>
						{children}
					</button>
				}
				action={action}
				title="Delete persona?"
				description="This action cannot be undone. This will permanently delete the persona and it's associated data."
			/>
		)
	},
)
DeletePersonaAsyncForm.displayName = 'DeletePersonaAsyncForm'
