import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useFetcher, useLoaderData } from 'react-router'
import { type z } from 'zod'
import { InviteFormSchema } from '#src/api/icp/company/user/schemas'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import { StatusButton } from '#src/components/ui/status-button'
import useCompany from '#src/hooks/useCompany'
import { type LoaderRes as SettingsTeamLoaderRes } from '#src/routes/settings/team/loaders'
import { routes } from '#src/utils/routes'
import { type ActionRes } from '../../actions/invite'

export const InviteForm = () => {
	const {
		data: { roles },
	} = useLoaderData<SettingsTeamLoaderRes>()
	const { companyId } = useCompany()

	const fetcher = useFetcher<ActionRes>()
	const formId = 'inviteMember'
	const action = routes.settings.team.invite({ companyId })
	const method = 'POST'

	const formProps = useForm<z.infer<typeof InviteFormSchema>>({
		resolver: zodResolver(InviteFormSchema),
	})

	return (
		<>
			<h2 className="-mb-1 text-title-md">Invite your team</h2>
			<FormWrapper
				formId={formId}
				formProps={formProps}
				method={method}
				action={action}
				fetcher={fetcher}
				actionResponse={fetcher.data}
				className="mb-2 grid grid-cols-5 gap-2"
			>
				<div className="col-span-2">
					<FormField name="email" fieldType="text" />
				</div>
				<FormField name="name" fieldType="text" label="Name (optional)" />
				<FormField
					name="role"
					fieldType="select"
					label="Select Role"
					placeholder="Select Role"
					options={Object.entries(roles).map(([role, value]) => ({ label: role, value }))}
				/>
				<StatusButton
					className="mt-5"
					type="submit"
					status={fetcher.state !== 'idle' ? 'pending' : 'idle'}
					disabled={fetcher.state !== 'idle'}
				>
					Invite
				</StatusButton>
			</FormWrapper>
		</>
	)
}
