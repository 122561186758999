import { useEffect } from 'react'
import { useLoaderData } from 'react-router'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { PersonaCreateModal } from '#src/modals/personas/PersonaCreate'
import { type LoaderRes } from '#src/routes/calibrate/ecosystem-management/personas/loaders/save'
import { routes } from '#src/utils/routes'

export const View = () => {
	const { openModal } = useModals()
	const { companyId } = useCompany()

	const { ecosystemId, verticalId, persona } = useLoaderData<LoaderRes>()

	useEffect(() => {
		openModal({
			name: MODAL_NAME.PersonaCreateModal,
			component: PersonaCreateModal,
			props: {
				ecosystemId: Number(ecosystemId),
				verticalId: Number(verticalId),
			},
			settings: {
				routeToRedirectOnClose: routes.calibrate.persona.index({
					companyId,
					ecosystemId,
					verticalId,
					personaId: persona?.id ? String(persona.id) : null,
				}),
			},
		})
	}, [companyId, ecosystemId, openModal, persona?.id, verticalId])

	return null
}
