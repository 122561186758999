import { type z } from 'zod'
import {
	type PersonaAPISchema,
	type PersonasExpertiseQueryPayload,
	type PersonasQueryPayload,
} from '#src/api/icp/company/persona/schemas'
import { type VerticalAPISchema } from '#src/api/icp/company/verticals/schemas'
import { type CompanySchema } from '#src/routes/company/schema'
import { baseApi } from '#src/utils/endpoints'
import { buildQueryParams } from '#src/utils/misc'

const base = (companyId: z.infer<typeof CompanySchema.shape.id>) => `${baseApi}/company/${companyId}/persona`

const endpoints = {
	create: (companyId: z.infer<typeof CompanySchema>['id']) => base(companyId),
	item: (companyId: z.infer<typeof CompanySchema>['id'], itemId: z.infer<typeof PersonaAPISchema>['id']) =>
		`${base(companyId)}/${itemId}`,
	update: (companyId: z.infer<typeof CompanySchema>['id'], itemId: z.infer<typeof PersonaAPISchema>['id']) =>
		`${base(companyId)}/${itemId}`,
	list: (companyId: z.infer<typeof CompanySchema>['id'], queryParams: PersonasQueryPayload) => {
		const queryString = buildQueryParams(queryParams)
		return `${base(companyId)}/list${queryString}`
	},
	getTypesOfTasks: (companyId: z.infer<typeof CompanySchema>['id']) => `${base(companyId)}/types-of-tasks`,
	signals: (companyId: z.infer<typeof CompanySchema.shape.id>, personaId: z.infer<typeof PersonaAPISchema.shape.id>) =>
		`${base(companyId)}/${personaId}/signals`,
	expertiseAndType: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		verticalIds: z.infer<typeof VerticalAPISchema.shape.id>[],
	) => {
		const query = new URLSearchParams({})
		verticalIds.forEach(value => {
			query.append('verticals[]', String(value))
		})
		return `${base(companyId)}/expertisetype?${new URLSearchParams(query).toString()}`
	},
	expertiseList: (companyId: z.infer<typeof CompanySchema>['id'], queryParams: PersonasExpertiseQueryPayload) => {
		const queryString = buildQueryParams(queryParams)
		return `${base(companyId)}/expertise${queryString}`
	},
	copyValidate: (companyId: z.infer<typeof CompanySchema>['id']) => `${base(companyId)}/copy-validate`,
	recalculate: (companyId: z.infer<typeof CompanySchema>['id'], itemId: z.infer<typeof PersonaAPISchema>['id']) =>
		`${base(companyId)}/${itemId}/recalculate`,
	copy: (companyId: z.infer<typeof CompanySchema>['id']) => `${base(companyId)}/copy`,
	batchDelete: (companyId: z.infer<typeof CompanySchema>['id']) => `${base(companyId)}/mass`,
}

export { endpoints as companyPersonaEndpoints }
