import { type ActionFunctionArgs, redirect } from 'react-router'
import { valuePropositionMutations } from '#src/api/icp/company/value-proposition/mutations'
import { NewValuePropositionFormSchema } from '#src/api/icp/company/value-proposition/schemas'
import { showToast } from '#src/context/ToastContext'
import { parseFormDataNew } from '#src/utils/forms'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const { companyId, company } = await getCompany(params)
	const formData = await request.formData()

	try {
		const submission = parseFormDataNew(formData, NewValuePropositionFormSchema)
		params.pointId
			? await valuePropositionMutations.update(company.id, Number(params.pointId), submission.value)
			: await valuePropositionMutations.create(company.id, submission.value)

		showToast({
			message: `Successfully ${params.pointId ? 'updated' : 'added'}`,
			type: 'success',
		})
	} catch (err) {
		showToast({
			message: 'Unexpected error while saving value proposition point',
			type: 'error',
		})
	}

	return redirect(routes.calibrate.valueProposition.index({ companyId }))
}
