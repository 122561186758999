import { Outlet, useLoaderData } from 'react-router'
import { EmptyStateCard } from '#src/components'
import useCompany from '#src/hooks/useCompany'
import { ECOSYSTEM_TYPE } from '#src/routes/calibrate/ecosystem-management/ecosystem/constants'
import { getEcosystemEmptyStateTitle } from '#src/routes/calibrate/ecosystem-management/ecosystem/utils'
import { type LoaderResType } from '#src/routes/calibrate/ecosystem-management/verticals/loaders'
import { routes } from '#src/utils/routes'

export const EcosystemEmptyState = () => {
	const {
		handle: { ecosystemId, readOnlySession },
		ecosystem,
	} = useLoaderData<LoaderResType>()
	const { companyId } = useCompany()

	const getEcosystemActions = (
		type: ECOSYSTEM_TYPE = ECOSYSTEM_TYPE.DEFAULT,
		companyId: string | null,
		ecosystemId: string,
		readOnly: boolean,
	) => {
		if (readOnly) return {}

		switch (type) {
			case ECOSYSTEM_TYPE.KEY_ACCOUNT:
				return {
					dropdownActions: {
						title: 'Add',
						items: [
							{
								label: 'Key Account',
								to: routes.calibrate.verticals.create({ companyId: companyId, ecosystemId }),
							},
							{
								label: 'CSV',
								to: routes.calibrate.verticals.import({ companyId: companyId, ecosystemId }),
							},
						],
					},
				}
			case ECOSYSTEM_TYPE.CRITERIA_SANDBOX:
				return {
					dropdownActions: {
						title: 'Add',
						items: [
							{
								label: 'Account',
								to: routes.calibrate.verticals.create({ companyId: companyId, ecosystemId }),
							},
							{
								label: 'CSV',
								to: routes.calibrate.verticals.import({ companyId: companyId, ecosystemId }),
							},
						],
					},
				}
			default:
				return {
					actions: [
						{
							title: 'Add Vertical',
							to: routes.calibrate.verticals.create({ companyId: companyId, ecosystemId: ecosystemId }),
						},
					],
				}
		}
	}

	return (
		<main className="flex w-full flex-grow flex-col items-center justify-center px-20">
			<EmptyStateCard
				icon="category"
				title={getEcosystemEmptyStateTitle(ecosystem?.type)}
				{...getEcosystemActions(ecosystem?.type, companyId, ecosystemId, readOnlySession)}
			/>
			<Outlet />
		</main>
	)
}
