import { useCallback, useMemo } from 'react'
import { useLoaderData } from 'react-router'
import { type z } from 'zod'
import { type EcosystemAPISchema } from '#src/routes/calibrate/ecosystem-management/ecosystem/schema'
import { findEcosystemByVerticalId as findEcosystem } from '#src/utils/misc'

type UseEcosystemLoaderType = {
	ecosystems: z.infer<typeof EcosystemAPISchema>[]
}

export const useEcosystem = () => {
	const loaderData = useLoaderData<UseEcosystemLoaderType>()
	const ecosystems = useMemo(() => loaderData?.ecosystems || [], [loaderData])

	const ecosystemsOptions = useMemo(() => {
		return ecosystems.map(({ id, name, verticals }) => ({
			value: id.toString(),
			label: `${name} (${verticals.length} verticals)`,
		}))
	}, [ecosystems])

	const findEcosystemByVerticalId = useCallback(
		(verticalId: string, ecosystemsArg?: z.infer<typeof EcosystemAPISchema>[]) => {
			const ecosystemsArr = ecosystemsArg || ecosystems
			return findEcosystem(verticalId, ecosystemsArr)
		},
		[ecosystems],
	)

	return { ecosystemsOptions, findEcosystemByVerticalId }
}
