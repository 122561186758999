import { type ActionFunctionArgs } from 'react-router'
import { orgDataEnrichmentWorkflowsMutations } from '#src/api/organise/data-enrichment/workflows/mutations'
import { DataEnrichmentJobDownloadFormSchema } from '#src/api/organise/data-enrichment/workflows/schemas'
import { makeDownloadableFromRes } from '#src/utils/files'
import { parseFormDataNew } from '#src/utils/forms'
import { validateRouteParams } from '#src/utils/misc'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ params, request }: ActionFunctionArgs) => {
	validateRouteParams(params, ['jobId'])
	const formData = await request.formData()

	try {
		const submission = parseFormDataNew(formData, DataEnrichmentJobDownloadFormSchema)
		const res = await orgDataEnrichmentWorkflowsMutations.download(params.jobId, submission.value)
		await makeDownloadableFromRes(res)

		return {
			ok: true,
		}
	} catch (e) {
		return await serverFormErrorHandler(e)
	}
}
