import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { baseApi } from '#src/utils/endpoints'
import { type PlaysTemplatesItemAPISchema } from './schemas'

const base = (companyId: z.infer<typeof CompanySchema.shape.id>) => `${baseApi}/company/${companyId}/plays-templates`

const endpoints = {
	list: (companyId: z.infer<typeof CompanySchema.shape.id>) => `${base(companyId)}`,
	create: (companyId: z.infer<typeof CompanySchema.shape.id>) => base(companyId),
	item: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		playId: z.infer<typeof PlaysTemplatesItemAPISchema.shape.id>,
	) => `${base(companyId)}/${playId}`,
	update: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		playId: z.infer<typeof PlaysTemplatesItemAPISchema.shape.id>,
	) => `${base(companyId)}/${playId}`,
	delete: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		playId: z.infer<typeof PlaysTemplatesItemAPISchema.shape.id>,
	) => `${base(companyId)}/${playId}`,
}

export { endpoints as playsTemplatesEndpoints }
