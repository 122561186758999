import { type RouteObject } from 'react-router'
import { action as requestAction } from './actions/request'
import { loader as dashboardLoader } from './loaders/dashboard'
import { loader as rootLoader } from './loaders/root'
import { View as DashboardView } from './views/dashboard'
import { View as RequestView } from './views/request'

const billingRouter = {
	path: 'billing',
	loader: rootLoader,
	handle: {
		crumb: () => [
			{
				label: 'Billing',
				icon: 'purchase',
			},
		],
	},
	children: [
		{
			path: '',
			loader: dashboardLoader,
			element: <DashboardView />,
			children: [
				{
					path: 'request',
					element: <RequestView />,
					action: requestAction,
				},
			],
		},
	],
} satisfies RouteObject

export default billingRouter
