import { z } from 'zod'
import { zNumber, zString } from '#src/utils/validation'
import { COMPANY_BILLING_FREQUENCY, COMPANY_PILLARS, COMPANY_TRANSACTION_TYPE } from './constants'

export const AdminCompanyFormSchema = z.object({
	name: zString(),
})

export const AdminCompanyDashboardFormSchema = z.object({
	dashboard: z
		.object({
			id: zNumber(z.number()),
			url: z.string().url().nullable().optional(),
		})
		.array(),
})

export const CompanyBillingFormSchema = z.object({
	contractStartDate: z.string(),
	totalSeats: zNumber(z.number()),
	credits: zNumber(z.number()),
	activeModules: z.array(z.nativeEnum(COMPANY_PILLARS)).default([]),
	outOfCredits: z.boolean(),
	billingFrequency: z.nativeEnum(COMPANY_BILLING_FREQUENCY),
	currency: z.string(),
})

export const CompanyTransactionAPISchema = z.object({
	id: z.number(),
	createdBy: z.string(),
	amount: z.number(),
	comment: z.string().nullable(),
	createdAt: z.string(),
	transactionDate: z.string(),
	transactionType: z.nativeEnum(COMPANY_TRANSACTION_TYPE),
})

export const CompanyTransactionFormSchema = z.object({
	transaction_type: z.nativeEnum(COMPANY_TRANSACTION_TYPE),
	amount: zNumber(z.number()),
	comment: z.string().nullable(),
	transaction_date: z.string(),
})
