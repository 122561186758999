import { Link, useParams } from 'react-router'
import { personaTypeColorVariants } from '#src/components/persona'
import { PriorityIcon } from '#src/components/priority'
import { StatusDot } from '#src/components/status'
import { Button } from '#src/components/ui/button'
import { Dropdown, DropdownItem } from '#src/components/ui/dropdown'
import { Icon } from '#src/components/ui/icon'
import { MODAL_NAME } from '#src/constants/modals'
import useAuth from '#src/hooks/useAuth'
import { useModals } from '#src/hooks/useModals'
import { PanelSectionItem, PanelSectionItemAction } from '#src/layouts/Panel'
import { PersonaCreateModal } from '#src/modals/personas/PersonaCreate'
import { DeletePersonaAsyncForm } from '#src/routes/calibrate/ecosystem-management/personas/delete'
import { RecalculatePersonaAsyncForm } from '#src/routes/calibrate/ecosystem-management/personas/recalculate'
import { type CalibrateNavVertical } from '#src/routes/calibrate/ecosystem-management/sidebar/schema'
import { cn, formatPersonaTypeAbbr } from '#src/utils/misc'

export const PersonaItem = ({ vertical, isActive }: { vertical: CalibrateNavVertical; isActive: boolean }) => {
	const { readOnlySession } = useAuth()
	const { personas } = vertical
	const params = useParams()
	const { openModal } = useModals()

	return personas?.length ? (
		personas.map((persona, index) => (
			<PanelSectionItem
				key={index}
				isTrigger
				className="group gap-2 pl-8 text-body-md"
				isActive={persona.id === Number(params.personaId)}
			>
				<Link
					to={persona.to}
					className="-my-2 grid w-full grid-cols-[max-content,1fr] items-center gap-2 py-1 radix-disabled:opacity-50"
				>
					<span
						className={cn(
							personaTypeColorVariants({
								type: persona.type,
								className: 'flex h-8 w-8 items-center justify-center rounded-full bg-neutral-1-bg text-label-sm',
							}),
						)}
					>
						{formatPersonaTypeAbbr(persona.type)}
					</span>
					<span className={cn('truncate', isActive && persona.id === Number(params.personaId) ? 'font-semibold' : '')}>
						{persona.title}
					</span>
				</Link>

				{readOnlySession ? null : (
					<section className={cn('panel-section-item-actions-meta', 'grid-cols-[repeat(2,20px)] justify-items-end')}>
						<Dropdown
							key={persona.id.toString()}
							trigger={
								<PanelSectionItemAction asChild isTrigger>
									<button type="button" data-type="contextmenu">
										<Icon name="overflow-menu-horizontal" size="sm" />
									</button>
								</PanelSectionItemAction>
							}
							contentProps={{
								side: 'bottom',
								align: 'start',
							}}
						>
							<DropdownItem asChild className="justify-start">
								<RecalculatePersonaAsyncForm
									ecosystemId={vertical.ecosystemId!}
									verticalId={vertical.id.toString()}
									personaId={persona.id.toString()}
									className="flex items-center gap-2"
									size="none"
								>
									<Icon name="rotate" size="sm" />
									Recalculate Persona
								</RecalculatePersonaAsyncForm>
							</DropdownItem>
							<DropdownItem asChild className="justify-start" onSelect={e => e.preventDefault()}>
								<DeletePersonaAsyncForm
									ecosystemId={vertical.ecosystemId!.toString()}
									verticalId={vertical.id.toString()}
									personaId={persona.id.toString()}
								>
									<div className="flex items-center gap-2">
										<Icon name="trash" size="sm" />
										Delete Persona
									</div>
								</DeletePersonaAsyncForm>
							</DropdownItem>
						</Dropdown>
					</section>
				)}

				<section
					className={cn(
						readOnlySession ? 'grid' : 'panel-section-item-actions-primary',
						'grid-cols-[repeat(2,20px)] justify-items-end',
					)}
				>
					<StatusDot status={persona.status} />
					<PriorityIcon priority={persona.priority} />
				</section>
			</PanelSectionItem>
		))
	) : (
		<PanelSectionItem
			asChild
			isTrigger
			className="gap-2 pl-8 text-body-md text-link hover:bg-transparent hover:text-link-hover active:text-link-pressed"
		>
			<Button
				variant="ghost"
				className="flex gap-1"
				onClick={() =>
					openModal({
						name: MODAL_NAME.PersonaCreateModal,
						component: PersonaCreateModal,
						props: {
							ecosystemId: Number(vertical.ecosystemId),
							verticalId: Number(vertical.id),
						},
					})
				}
			>
				Add Persona
				<Icon name="add" />
			</Button>
		</PanelSectionItem>
	)
}
