import { Link } from 'react-router'
import { type z } from 'zod'
import { type PersonaAPISchema } from '#src/api/icp/company/persona/schemas'
import ProductTip from '#src/components/product-tip'
import useAuth from '#src/hooks/useAuth'
import useCompany from '#src/hooks/useCompany'
import { useSalesCoachQuery } from '#src/hooks/useSalesCoachQuery'
import Aside from '#src/routes/calibrate/ecosystem-management/personas/views/components/Aside'
import { MONTH_SELECT_OPTIONS } from '#src/utils/enumerations'
import { routes } from '#src/utils/routes'

type Props = {
	persona: z.infer<typeof PersonaAPISchema>
}

const PersonaSalesCoachCard = (props: Props) => {
	const { persona } = props
	const { companyId } = useCompany()
	const { readOnlySession } = useAuth()

	const { salesCoachData } = useSalesCoachQuery(persona)

	return (
		<Aside
			title={
				<div className="flex items-center justify-between">
					Settings
					{!readOnlySession && (
						<Link
							to={{
								pathname: routes.calibrate.persona.coach.index({
									companyId,
									ecosystemId: String(persona.ecosystem.id),
									verticalId: String(persona.vertical.id),
									personaId: String(persona.id),
								}),
								search: new URLSearchParams({ tab: 'persona-sales-coach' }).toString(),
							}}
							className="text-button-sm text-link hover:text-link-hover active:text-link-pressed"
						>
							Edit
						</Link>
					)}
				</div>
			}
			list={[
				{
					label: (
						<div className="flex items-center gap-1">
							Number of Questions
							<ProductTip content="Number of Questions: Set the number of calibrated questions for sales interactions to control the depth and focus of prospect engagement." />
						</div>
					),
					value: salesCoachData?.adjustments?.questionCount ?? '-',
				},
				{
					label: (
						<div className="flex items-center gap-1">
							Question length in words
							<ProductTip content="Min/Max # of Words for Questions: Define the word count range for each question to ensure they are concise yet comprehensive enough for meaningful engagements." />
						</div>
					),
					value:
						salesCoachData?.adjustments?.wordsMin && salesCoachData?.adjustments?.wordsMin
							? `${salesCoachData?.adjustments?.wordsMin} - ${salesCoachData?.adjustments?.wordsMax}`
							: '-',
				},
				{
					label: (
						<div className="flex items-center gap-1">
							Manual input for calibrated questions
							<ProductTip content="Manual Input for Calibrated Questions: Enter specific questions to address the unique challenges and needs of your prospects." />
						</div>
					),
					value: salesCoachData?.adjustments?.criteria ?? '-',
				},
				{
					label: (
						<div className="flex items-center gap-1">
							Timing
							<ProductTip content="Choose Month: Select the month for setting the context and timing of your sales strategy to ensure it is timely and relevant to market conditions." />
						</div>
					),
					value: MONTH_SELECT_OPTIONS.find(m => m.value === String(salesCoachData?.timing?.month))?.label ?? '-',
				},
				{
					label: (
						<div className="flex items-center gap-1">
							Manual input for timing context
							<ProductTip content="Manual Input for Timing Context: Provide additional context for the selected month, such as seasonal trends or industry events, to tailor your sales efforts to current market happenings." />
						</div>
					),
					value: salesCoachData?.adjustments?.valueProp ?? '-',
				},
				{
					label: (
						<div className="flex items-center gap-1">
							Objections to defuse
							<ProductTip content="Objection Calibration - Objections to Defuse: Common objections from prospects to prepare effective responses for overcoming them during sales conversations." />
						</div>
					),
					value: salesCoachData?.adjustments?.defuse ?? '-',
				},
			]}
		/>
	)
}

export default PersonaSalesCoachCard
