import { useLoaderData, useNavigate } from 'react-router'
import ProductTip from '#src/components/product-tip'
import { Icon } from '#src/components/ui/icon'
import { LabeledValue } from '#src/components/ui/LabeledValue'
import { PageLayout } from '#src/components/ui/PageLayout'
import useAuth from '#src/hooks/useAuth'
import useCompany from '#src/hooks/useCompany'
import LayoutContentHack from '#src/layouts/LayoutContentHack'
import { IntentSource, SORT } from '#src/routes/prioritize/constants'
import { type PrioritizeListLoaderResType } from '#src/routes/prioritize/routes/list'
import { NoFilterResults } from '#src/routes/prioritize/views/components/list/NoFilterResults'
import { SignalTemplateContextMenu } from '#src/routes/prioritize/views/components/list/SignalTemplateContextMenu'
import { SignalStatus } from '#src/routes/prioritize/views/components/SignalStatus'
import { SignalType } from '#src/routes/prioritize/views/components/SignalType'
import { SignalWeightForm } from '#src/routes/prioritize/views/components/SignalWeightForm'
import { Table } from '#src/routes/prioritize/views/components/Table'
import { WeightValue } from '#src/routes/prioritize/views/components/WeightValue'
import { routes } from '#src/utils/routes'
import { Filters, Sort } from './components/list/filters'

export const View = () => {
	const { signalsTemplates } = useLoaderData<PrioritizeListLoaderResType>()
	const { readOnlySession } = useAuth()
	const navigate = useNavigate()
	const { companyId } = useCompany()

	return (
		<PageLayout title="Prioritize" description="Enrich and prioritize accounts & prospects with intent signals.">
			<LayoutContentHack>
				<Table
					stickyHeader
					noDataComponent={<NoFilterResults />}
					filtersComponent={<Filters />}
					expandable
					data={signalsTemplates}
					onItemClick={item =>
						void navigate(
							routes.prioritize.signal.index({
								companyId,
								signalId: item.id.toString(),
							}),
						)
					}
					columns={[
						{
							name: 'number',
							heading: {
								title: <Sort sortKey={SORT.NUMBER}>Number</Sort>,
								className: 'w-20',
							},
							body: {
								render: item => item.id,
							},
						},
						{
							name: 'intent',
							heading: {
								title: (
									<div className="flex items-center gap-1">
										<Sort sortKey={SORT.INTENT}>Intent</Sort>
										<ProductTip content="Intent Signals: Indicators suggesting a potential customer’s readiness or interest in your product or service, helping prioritize accounts and contacts." />
									</div>
								),
							},
							body: {
								render: item => item.name,
							},
						},
						{
							name: 'status',
							heading: {
								title: <Sort sortKey={SORT.STATUS}>Status</Sort>,
							},
							body: {
								render: item => <SignalStatus enabled={item.available} />,
							},
						},
						{
							name: 'intentType',
							heading: {
								title: (
									<div className="flex items-center gap-1">
										<Sort sortKey={SORT.INTENT_TYPE}>Intent Type</Sort>
										<ProductTip content="Intent Type: Categorizes the nature of the intent signal, such as “Cold Outbound Signal,” “Static Hook,” “Warm Outbound Signal,” or “Inbound Signal.”" />
									</div>
								),
							},
							body: {
								render: item => <SignalType type={item.type} />,
							},
						},
						{
							name: 'intentSource',
							heading: {
								title: (
									<div className="flex items-center gap-1">
										<Sort sortKey={SORT.INTENT_SOURCE}>Intent Source</Sort>
										<ProductTip content="Intent Source: Identifies whether the intent signal comes from an account (company-level) or a contact (individual-level)." />
									</div>
								),
							},
							body: {
								render: item =>
									item.source === IntentSource.ACCOUNT_PUBLIC_COMPANY ? (
										<div>
											Account
											<br />
											(publicly listed)
										</div>
									) : (
										item.source
									),
							},
						},
						{
							name: 'weight',
							heading: {
								title: (
									<div className="flex items-center gap-1">
										<Sort sortKey={SORT.WEIGHT}>Weight</Sort>
										<ProductTip content="Weight: The importance or relevance of the intent signal, which is converted later into a score and integrated into the CRM." />
									</div>
								),
								className: 'w-32',
							},
							body: {
								render: item =>
									readOnlySession ? (
										item.config?.weight ? (
											<WeightValue value={item.config.weight.toString()} />
										) : null
									) : (
										<SignalWeightForm signalId={item.id} weight={item.config.weight} />
									),
							},
						},
						{
							name: 'mappedPersonas',
							heading: {
								title: (
									<div className="flex items-center gap-1">
										<Sort sortKey={SORT.MAPPED_PERSONAS}>Mapped Personas</Sort>
										<ProductTip content="Mapped Personas: The number of personas linked to the intent signal." />
									</div>
								),
								className: 'w-40',
							},
							body: {
								render: item => item.mappedPersonaCount,
							},
						},
						{
							name: 'actions',
							heading: {
								title: '',
								className: 'w-10 text-center',
							},
							body: {
								render: item =>
									readOnlySession ? (
										<Icon name="chevron-right" size="sm" />
									) : (
										<SignalTemplateContextMenu signalId={item.id} />
									),
								className: 'w-14 last:text-left',
							},
						},
					]}
					expandableColumns={[
						{
							name: 'empty-1',
							body: {
								render: () => null,
							},
						},
						{
							name: 'empty-2',
							body: {
								render: () => null,
							},
						},
						{
							name: 'examples-and-context',
							body: {
								colSpan: 6,
								render: item => (
									<div className="flex max-w-2xl flex-col gap-4">
										<LabeledValue label="Example of manual steps">{item.manualSteps}</LabeledValue>
										<LabeledValue label="Context & Example">{item.context}</LabeledValue>
									</div>
								),
							},
						},
						{
							name: 'empty-4',
							body: {
								render: () => null,
							},
						},
					]}
				/>
			</LayoutContentHack>
		</PageLayout>
	)
}
