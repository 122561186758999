import { type ReactNode, useCallback, useMemo } from 'react'
import { useLoaderData } from 'react-router'
import { type z } from 'zod'
import { type PersonasExpertiseAPIResSchema } from '#src/api/icp/company/persona/schemas'
import { PersonaAvatar } from '#src/components/persona'
import { type EcosystemAPISchema } from '#src/routes/calibrate/ecosystem-management/ecosystem/schema'

type UsePersonaLoaderType = {
	ecosystems: z.infer<typeof EcosystemAPISchema>[]
}

type PersonaOption = { value: string; label: ReactNode }

export const usePersona = () => {
	const loaderData = useLoaderData<UsePersonaLoaderType>()
	const ecosystems = useMemo(() => loaderData?.ecosystems || [], [loaderData])

	const getPersonasOptions = useCallback(
		(ecosystemName: string, verticalName: string): PersonaOption[] => {
			return (
				ecosystems
					.find(({ name }) => name === ecosystemName)
					?.verticals.find(({ name }) => name === verticalName)
					?.personas.map(({ id, expertise, type, jobTitles }) => ({
						value: id.toString(),
						label: (
							<div className="grid grid-cols-[max-content,1fr] grid-rows-2 items-center gap-x-2">
								<PersonaAvatar type={type} size="sm" className="row-span-2" />
								<h3 className="text-label-sm text-neutral-3-fg">{expertise}</h3>
								<p className="w-full truncate text-label-sm text-neutral-2-fg">{jobTitles}</p>
							</div>
						),
					})) ?? []
			)
		},
		[ecosystems],
	)

	const getPersonasOptionsById = useCallback(
		(ecosystemId: string, verticalId: string): PersonaOption[] => {
			return (
				ecosystems
					.find(({ id }) => String(id) === ecosystemId)
					?.verticals.find(({ id }) => String(id) === verticalId)
					?.personas.map(({ id, expertise, type, jobTitles }) => ({
						value: id.toString(),
						label: (
							<div className="grid grid-cols-[max-content,1fr] grid-rows-2 items-center gap-x-2">
								<PersonaAvatar type={type} size="sm" className="row-span-2" />
								<h3 className="text-label-sm text-neutral-3-fg">{expertise}</h3>
								<p className="w-full truncate text-label-sm text-neutral-2-fg">{jobTitles}</p>
							</div>
						),
					})) ?? []
			)
		},
		[ecosystems],
	)

	const getPersonasCountPerCompany = useCallback(
		(ecosystemsArg?: z.infer<typeof EcosystemAPISchema>[]) => {
			const ecosystemsArr = ecosystemsArg || ecosystems

			return ecosystemsArr.reduce((totalCount, ecosystem) => {
				return (
					totalCount +
					ecosystem.verticals.reduce((verticalTotal, vertical) => {
						return verticalTotal + vertical.personas.length
					}, 0)
				)
			}, 0)
		},
		[ecosystems],
	)

	const getPersonasExpertiseOptions = useCallback(
		(expertises: z.infer<typeof PersonasExpertiseAPIResSchema>): PersonaOption[] => {
			return (
				expertises.map(item => ({
					label: item,
					value: item,
				})) ?? []
			)
		},
		[],
	)

	return {
		getPersonasOptions,
		getPersonasOptionsById,
		getPersonasCountPerCompany,
		getPersonasExpertiseOptions,
	}
}
