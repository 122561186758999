import { type ActionFunctionArgs } from 'react-router'
import { verticalsMutations } from '#src/api/icp/company/verticals/mutations'
import { type ECOSYSTEM_TYPE } from '#src/routes/calibrate/ecosystem-management/ecosystem/constants'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ params }: ActionFunctionArgs) => {
	const { company } = await getCompany(params)
	validateRouteParams(params, ['ecosystemType'])

	try {
		await verticalsMutations.downloadTemplate(company.id, params.ecosystemType as ECOSYSTEM_TYPE)
	} catch (err) {
		return serverFormErrorHandler(err)
	}

	return null
}
