import { zodResolver } from '@hookform/resolvers/zod'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useFetcher } from 'react-router'
import { type z } from 'zod'
import { AccountExportFormSchema } from '#src/api/icp/company/account-export/schemas'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import LoaderRequiredComponent from '#src/components/LoaderRequiredComponent'
import { Button } from '#src/components/ui/button'
import { Dialog } from '#src/components/ui/dialog'
import { FormStepper } from '#src/components/ui/FormStepper'
import { Icon } from '#src/components/ui/icon'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { type ActionRes } from '#src/routes/organize/account-data-explorer/actions/create'
import { FORM_STEPS } from '#src/routes/organize/account-data-explorer/constants'
import { type LoaderRes } from '#src/routes/organize/account-data-explorer/loaders/create'
import { routes } from '#src/utils/routes'

export const AccountDataExplorerCreateModal = () => {
	const { companyId } = useCompany()

	return (
		<LoaderRequiredComponent<LoaderRes>
			route={routes.organize.accountDataExplorer.create({ companyId })}
			component={ModalInner}
		/>
	)
}

const FORM_NAME = 'account-data-explorer-job-create'

export const ModalInner = (props: LoaderRes) => {
	const {
		data: { settings, job },
	} = props
	const { companyId } = useCompany()

	const actionFetcher = useFetcher<ActionRes>()

	const [step, setStep] = useState<FORM_STEPS>(FORM_STEPS.Query)

	const form = useForm<z.infer<typeof AccountExportFormSchema>>({
		resolver: zodResolver(AccountExportFormSchema),
		defaultValues: {
			filters: job
				? Object.entries(job.filters).map(([key, val]) => ({ name: key, value: val }))
				: [
						{
							name: undefined,
							value: undefined,
						},
					],
		},
		mode: 'onSubmit',
	})

	useEffect(() => {
		if (actionFetcher.data && actionFetcher.data.ok && 'data' in actionFetcher.data) {
			setStep(FORM_STEPS.Confirm)
		}
	}, [actionFetcher.data])

	const handleCancel = () => {
		switch (step) {
			case FORM_STEPS.Query:
				closeModal(MODAL_NAME.AccountDataExplorerCreate)
				break
			case FORM_STEPS.Confirm:
				setStep(FORM_STEPS.Query)
				break
		}
	}

	const { closeModal } = useModals()

	const filtersInstance = useFieldArray<z.infer<typeof AccountExportFormSchema>>({
		control: form.control,
		name: 'filters',
	})

	const addNewFilter = () => {
		filtersInstance.append({
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-expect-error
			name: undefined,
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-expect-error
			value: undefined,
		})
	}

	const activeFiltersNames = form.watch('filters').map(item => item.name)

	const filtersOptions = useMemo(() => {
		return settings.filters
			.filter(item => !activeFiltersNames.includes(item.name))
			.map(item => ({
				value: item.name,
				label: item.label,
			}))
	}, [activeFiltersNames, settings.filters])

	return (
		<Dialog
			size="lg"
			defaultOpen={true}
			onOpenChange={isOpen => !isOpen && closeModal(MODAL_NAME.AccountDataExplorerCreate)}
			dialogHeading="Explore companies"
			actions={[
				{
					type: 'cancel',
					label: 'Back',
					onClick: e => {
						e.preventDefault()
						handleCancel()
					},
				},
				{
					type: 'submit',
					formId: FORM_NAME,
					label: step === FORM_STEPS.Confirm ? 'Get data' : 'Continue',
					name: 'intent',
					value: step,
				},
			]}
			isSubmitting={actionFetcher.state === 'submitting'}
			{...(step === FORM_STEPS.Query && {
				footerInfo: (
					<>
						<b>{settings.exportLimit}</b> entries limit per 1 request
					</>
				),
			})}
		>
			<FormWrapper
				formId={FORM_NAME}
				formProps={form}
				method="POST"
				action={routes.organize.accountDataExplorer.create({ companyId })}
				className="flex flex-col gap-6"
				fetcher={actionFetcher}
				actionResponse={actionFetcher.data}
			>
				<FormStepper
					active={step}
					steps={[
						{ name: FORM_STEPS.Query, label: 'Select filters' },
						{ name: FORM_STEPS.Confirm, label: 'Confirm request' },
					]}
				/>
				{step === FORM_STEPS.Query && (
					<>
						<div className="grid grid-cols-[1fr,max-content,1fr,max-content] gap-x-2 gap-y-4">
							{filtersInstance.fields.map((field, index) => {
								const activeFilter = settings.filters.find(item => item.name === form.watch(`filters.${index}.name`))
								return (
									<Fragment key={field.id}>
										<FormField
											name={`filters.${index}.name`}
											fieldType="select"
											options={[
												...filtersOptions,
												...(activeFilter
													? [
															{
																value: activeFilter.name,
																label: activeFilter.label,
															},
														]
													: []),
											]}
											disableLabel
											placeholder="Select filter"
											onChange={() => {
												// eslint-disable-next-line @typescript-eslint/ban-ts-comment
												// @ts-expect-error
												form.setValue(`filters.${index}.value`, undefined)
											}}
										/>
										<div className="py-1.5 text-body-lg">=</div>
										<div>
											{activeFilter ? (
												<>
													{(() => {
														switch (activeFilter.type) {
															case 'text': {
																return (
																	<FormField
																		name={`filters.${index}.value`}
																		fieldType="text"
																		disableLabel
																		placeholder="Enter value"
																	/>
																)
															}
															case 'number': {
																return (
																	<FormField
																		name={`filters.${index}.value`}
																		fieldType="text"
																		disableLabel
																		placeholder="Enter value"
																	/>
																)
															}
															case 'select': {
																return (
																	<FormField
																		name={`filters.${index}.value`}
																		fieldType="select"
																		disableLabel
																		placeholder="Select value"
																		options={activeFilter.options.map(item => ({
																			value: item,
																			label: item,
																		}))}
																	/>
																)
															}
															case 'multi-select': {
																return (
																	<FormField
																		name={`filters.${index}.value`}
																		fieldType="multiselect"
																		disableLabel
																		placeholder="Select value"
																		options={activeFilter.options.map(item => ({
																			value: item,
																			label: item,
																		}))}
																	/>
																)
															}
															case 'date': {
																return (
																	<FormField
																		name={`filters.${index}.value`}
																		fieldType="date"
																		disableLabel
																		placeholder="Enter value"
																	/>
																)
															}
														}
													})()}
												</>
											) : (
												<FormField
													name={`filters.${index}.value`}
													fieldType="text"
													disableLabel
													placeholder="Enter value"
													disableField
												/>
											)}
										</div>
										<div>
											{filtersInstance.fields.length > 1 && (
												<Button
													className="h-10 w-10"
													size="icon"
													variant="icon"
													type="button"
													onClick={() => filtersInstance.remove(index)}
												>
													<Icon name="trash" size="sm" color="red" />
												</Button>
											)}
										</div>
									</Fragment>
								)
							})}
						</div>
						<Button size="sm" variant="ghost" type="button" onClick={addNewFilter}>
							<Icon name="add" /> Add filter
						</Button>
					</>
				)}
				{step === FORM_STEPS.Confirm && actionFetcher.data?.ok && 'data' in actionFetcher.data && (
					<>
						<div className="flex flex-col gap-2">
							<h6 className="text-title-sm">
								<span className="text-brand-1-fg">
									{actionFetcher.data.data?.totalResults ?? '-'} data records (Cost:{' '}
									{actionFetcher.data.data?.totalCredits ?? '-'} tokens)
								</span>{' '}
								were found based on the selected filters.
							</h6>
							<p className="text-body-md text-neutral-2-fg">
								You can collect up to {settings.exportLimit} data records at once. Adjust your filters or collect data
								in batches with multiple requests.
							</p>
						</div>
					</>
				)}
			</FormWrapper>
		</Dialog>
	)
}
