import { Icon } from '../ui/icon'

export default function ResendMessages({ errorMsg }: { errorMsg: string | null }) {
	return (
		<section className="w-full bg-red-10 p-2">
			<p className="item-center flex justify-center gap-1 text-center text-body-sm font-semibold text-status-danger-fg">
				<Icon name="warning-filled" size="sm" />
				{errorMsg}
			</p>
		</section>
	)
}
