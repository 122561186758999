import { useLoaderData, useNavigate, useSearchParams } from 'react-router'
import { EmptyStateCard } from '#src/components'
import ContactAvatar from '#src/components/chat/avatar'
import { Chip } from '#src/components/chip'
import FiltersSection from '#src/components/FiltersSection'
import { Icon } from '#src/components/ui/icon'
import { PaginatedTable } from '#src/components/ui/PaginatedTable'
import { Surface } from '#src/components/ui/surface'
import { CustomTooltip } from '#src/components/ui/tooltip'
import useCompany from '#src/hooks/useCompany'
import { type LoaderRes } from '#src/routes/enable/roleplay-scenarios/loaders/item'
import { formatDateToReadableString } from '#src/utils/date'
import { cn, formatAvatarAbbr } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

const ParticipationHistory = () => {
	const { scenario, sessions, filters, sort } = useLoaderData<LoaderRes>()
	const { companyId } = useCompany()
	const navigate = useNavigate()
	const [, setSearchParams] = useSearchParams()

	return (
		<Surface className="flex w-full flex-col gap-6 p-6">
			<h2 className="text-title-md text-neutral-1-fg">Participation history</h2>
			<FiltersSection
				total={sessions.meta.total}
				selected={filters}
				values={sessions.meta.filters!}
				filters={[
					{
						name: 'user',
						label: 'Participant',
					},
					{
						name: 'hasAttempts',
						label: 'Attempts',
						multiple: false,
					},
				]}
			/>
			{!sessions.data.length ? (
				<EmptyStateCard
					icon="group"
					title="No sessions"
					description="Firstly you need to add participants and after that all scenario sessions will be found here"
				/>
			) : (
				<div className="-mx-6 flex flex-col gap-6">
					<PaginatedTable
						data={sessions.data}
						sort={sort}
						currentPage={sessions.meta.current_page}
						totalPages={sessions.meta.last_page}
						onPageChange={page => setSearchParams({ ...filters, page: (page + 1).toString() })}
						onItemClick={session =>
							navigate({
								pathname: routes.enable.roleplay.scenario.attempts({
									companyId,
									scenarioId: scenario.id,
									sessionId: session.id,
								}),
							})
						}
						onSortChange={data => setSearchParams({ ...filters, sort: data })}
						columns={[
							{
								name: 'user',
								heading: 'Participant',
								render: session => (
									<div className="flex items-center gap-2 text-body-sm font-medium text-neutral-2-fg">
										<ContactAvatar
											className="inline-flex shrink-0 items-center justify-center rounded-full"
											variant="gray"
											size="sm"
											initial={formatAvatarAbbr(session.user)}
										/>
										{session.user}
									</div>
								),
							},
							{
								name: 'attempts',
								heading: 'Attempts',
								render: session => <Chip variant="blue">{session.attempts}</Chip>,
							},
							{
								name: 'status',
								heading: 'Status',
								render: session => {
									const isCompleted = session.attempts > 0
									return (
										<Chip variant={isCompleted ? 'green' : 'gray'} className="inline-flex flex-row items-center gap-1">
											<Icon name={isCompleted ? 'checkmark-filled' : 'circle-dash'} className="-ml-1 h-4 w-4" />
											<span>{isCompleted ? 'Completed' : 'To do'}</span>
										</Chip>
									)
								},
							},
							{
								name: 'dueAt',
								heading: 'Deadline',
								render: session => {
									if (!session.dueAt) {
										return '-'
									}

									const overdue = new Date() > new Date(session.dueAt)
									return (
										<div className={cn('flex flex-row items-center gap-1', overdue && 'text-status-warning-fg')}>
											<span>{formatDateToReadableString(session.dueAt)}</span>
											{overdue && (
												<CustomTooltip label="Overdue">
													<Icon name="warning-alt-filled" className="h-4 w-4" />
												</CustomTooltip>
											)}
										</div>
									)
								},
							},
							{
								name: 'createdAt',
								heading: 'Date created',
								render: session => formatDateToReadableString(session.createdAt),
								sortable: true,
							},
						]}
					/>
				</div>
			)}
		</Surface>
	)
}

export default ParticipationHistory
