export enum MODAL_NAME {
	PlayCreate = 'play_create',
	PlayEdit = 'play_edit',
	PlayDelete = 'play_delete',
	PlayTemplates = 'play_templates',
	PlayTemplateView = 'play_template_view',
	PlayTemplateCreate = 'play_template_create',
	PlayTemplateEdit = 'play_template_edit',
	PlayTemplateDelete = 'play_template_delete',
	LeadResearchAgentJobCreate = 'lra_job_create',
	LeadResearchAgentJobStop = 'lra_job_stop',
	LeadResearchAgentJobArchive = 'lra_job_archive',
	LeadResearchAgentJobDownload = 'lra_job_download',
	LeadResearchAgentJobEdit = 'lra_job_edit',
	RoleplayScenarioCreate = 'roleplay_scenario_create',
	RoleplayScenarioEdit = 'roleplay_scenario_edit',
	PersonaCreateModal = 'persona_save',
	ReasoningAndTips = 'reasoning_and_tips',
	AdminPlaysExpertsCreate = 'admin_plays_experts_create',
	AdminPlaysExpertsEdit = 'admin_plays_experts_edit',
	AdminPlaysExpertsDelete = 'admin_plays_experts_delete',
	AdminCompanyCreate = 'admin_company_create',
	AdminCompanyDelete = 'admin_company_delete',
	ContactWaterfallingCreate = 'contact_waterfalling_create',
	ContactWaterfallingDownload = 'contact_waterfalling_download',
	ContactWaterfallingStop = 'contact_waterfalling_stop',
	AccountEnrichmentCreate = 'account_enrichment_create',
	AccountEnrichmentDownload = 'account_enrichment_download',
	AccountEnrichmentStop = 'account_enrichment_stop',
	CopilotCreate = 'copilot_create',
	TermEdit = 'term_edit',
	PersonaSalesCoachEdit = 'persona_sales_coach_edit',
	AccountDataExplorerCreate = 'account_data_explorer_create',
}
