import { cva, type VariantProps } from 'class-variance-authority'
import { type ReactNode } from 'react'
import { Surface } from '#src/components/ui/surface'
import { Switch, type SwitchProps } from '#src/components/ui/switch'
import { CustomTooltip } from '#src/components/ui/tooltip'
import { selectionControlDisabledVariants } from '#src/theme'
import { cn } from '#src/utils/misc'

export type CardSwitchProps = SwitchProps & {
	heading: ReactNode
	description?: ReactNode
	tooltip?: ReactNode
	innerContent?: ReactNode
	locked?: boolean
}

const cardSwitchVariants = cva('flex flex-col gap-3 rounded p-6', {
	variants: {
		size: {
			sm: 'p-4',
			md: 'p-6',
			lg: 'p-8',
		},
		disabled: selectionControlDisabledVariants,
	},
	defaultVariants: {
		size: 'md',
		disabled: false,
	},
})

export const CardSwitch = ({
	heading,
	description,
	className,
	size = 'md',
	disabled = false,
	locked = false,
	tooltip,
	innerContent,
	...switchProps
}: CardSwitchProps & VariantProps<typeof cardSwitchVariants>) => {
	const switchComp = (
		<Surface className={cn(cardSwitchVariants({ size, disabled: disabled || locked }), className)}>
			<div className="flex items-center justify-between">
				<section className="flex flex-col gap-1">
					<span className="text-title-sm font-semibold">{heading}</span>
					{description && <span className="text-neutral-2-fd text-body-md">{description}</span>}
				</section>
				{!disabled && <Switch {...switchProps} size={size} disabled={disabled || locked} />}
			</div>
			{switchProps.checked && !disabled && innerContent}
		</Surface>
	)

	if (tooltip) {
		return (
			<CustomTooltip label={tooltip} side="right" noChildrenWrapper>
				{switchComp}
			</CustomTooltip>
		)
	}
	return switchComp
}
