import { useEffect } from 'react'
import { useParams, type LoaderFunctionArgs } from 'react-router'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { client } from '#src/main'
import { TermEditModal } from '#src/modals/terms/TermEdit'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem-management/ecosystem/queries'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { glossaryQuery } from './queries'

export type EditTermLoaderResponse = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)

	const ecosystems = await client.fetchQuery(ecosystemsQuery(company.id, { includeCriteriaSandbox: true }))

	const terms = params.termId ? await client.fetchQuery(glossaryQuery(company.id)) : null

	const term = terms?.find(term => term.id === Number(params.termId)) ?? null

	return {
		handle: {
			termId: params.termId ?? null,
		},
		ecosystems,
		term,
	}
}

export default function TermEdit() {
	const params = useParams()
	const { openModal } = useModals()
	const { companyId } = useCompany()

	useEffect(() => {
		openModal({
			name: MODAL_NAME.TermEdit,
			component: TermEditModal,
			props: { termId: params.termId ? Number(params.termId) : undefined },
			settings: { routeToRedirectOnClose: `${routes.enable.glossary.index({ companyId })}?source=calibrate` },
		})
	}, [companyId, openModal, params.termId])

	return null
}
