import { Link, Outlet } from 'react-router'
import PageLoader from '#src/components/loader'
import { Logo } from '#src/components/ui/logo'

const AuthLayout = () => {
	return (
		<div className="grid min-h-screen grid-cols-1 grid-rows-[max-content,1fr] items-center gap-10 bg-neutral-2-bg p-10 text-neutral-1-fg">
			<PageLoader />
			<header className="flex h-full items-center justify-center">
				<Link to="/">
					<Logo type="full" size="sm" />
				</Link>
			</header>
			<Outlet />
		</div>
	)
}

export default AuthLayout
