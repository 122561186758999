import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { safeFetch } from '#src/utils/safeFetch'
import { criteriaEndpoints } from './endpoints'
import { CriteriaModelTypeAPISchema } from './schema'

const keys = {
	all: ['icp', 'company', 'criteria'] as const,
	modelTypes: (companyId: z.infer<typeof CompanySchema.shape.id>) => [...keys.all, companyId, 'model-types'] as const,
}

const modelTypesQuery = (companyId: z.infer<typeof CompanySchema.shape.id>) => ({
	queryKey: keys.modelTypes(companyId),
	queryFn: async () =>
		await safeFetch(CriteriaModelTypeAPISchema, criteriaEndpoints.modelTypes(companyId), {
			method: 'GET',
		}),
})

const queries = {
	modelTypes: modelTypesQuery,
}

export { queries as companyCriteriaQueries, keys as companyCriteriaQueriesKeys }
