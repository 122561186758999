import { z } from 'zod'
import { zNumber, zString } from '#src/utils/validation'

export const CompanyFormSchema = z.object({
	name: zString(),
})

export const CompanyRequestCreditsFormSchema = z.discriminatedUnion('type', [
	z.object({
		type: z.literal('custom'),
		custom: zNumber(z.number()),
	}),
	z.object({
		type: z.enum(['10000', '20000', '30000', '40000', '50000']),
	}),
])

export const CompanyRequestCreditsFormAPISchema = CompanyRequestCreditsFormSchema.transform(val => {
	if (val.type === 'custom') {
		return { credits: val.custom }
	} else {
		return { credits: Number(val.type) }
	}
})
