import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useFetcher, useLoaderData } from 'react-router'
import { type z } from 'zod'
import { EditFormSchema } from '#src/api/icp/company/user/schemas'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import { Dialog } from '#src/components/ui/dialog'
import { DeactivateUserForm, ResetPasswordForm } from '#src/routes/settings/team/views/components'
import { routes } from '#src/utils/routes'
import { type ActionRes } from '../actions/edit'
import { type LoaderRes } from '../loaders/edit'

export const View = () => {
	const {
		data: { company, user, roles },
	} = useLoaderData<LoaderRes>()

	const formProps = useForm<z.infer<typeof EditFormSchema>>({
		resolver: zodResolver(EditFormSchema),
		defaultValues: {
			name: user.name ?? undefined,
			role: user.roles[0],
		},
	})

	const formId = 'editMember'
	const method = 'PUT'
	const action = routes.settings.team.edit({ companyId: company.id, userId: user.id })
	const closeTo = routes.settings.team.index({ companyId: company.id })
	const actionFetcher = useFetcher<ActionRes>()

	return (
		<Dialog
			defaultOpen={true}
			closeTo={closeTo}
			dialogHeading={`Edit "${user.email}" details`}
			actions={[
				{
					label: 'Cancel',
					type: 'cancel',
					to: closeTo,
				},
				{
					label: 'Save',
					type: 'submit',
					formId,
				},
			]}
			footerInfo={<DeactivateUserForm user={user} />}
			isSubmitting={actionFetcher.state === 'submitting'}
		>
			<FormWrapper
				formProps={formProps}
				action={action}
				formId={formId}
				method={method}
				className="flex flex-col gap-4"
				fetcher={actionFetcher}
				actionResponse={actionFetcher.data}
			>
				<FormField name="name" fieldType="text" clearable />
				<FormField
					name="role"
					fieldType="radio"
					options={Object.entries(roles).map(([key, value]) => ({ label: key, value: value }))}
				/>
			</FormWrapper>
			<ResetPasswordForm user={user} />
		</Dialog>
	)
}
