import { Link, useLoaderData } from 'react-router'
import { EmptyStateCard } from '#src/components'
import ContactAvatar from '#src/components/chat/avatar'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { Surface } from '#src/components/ui/surface'
import { Table } from '#src/components/ui/Table'
import useCompany from '#src/hooks/useCompany'
import { type LoaderRes } from '#src/routes/enable/roleplay-scenarios/loaders/item'
import { UnassignUserModal } from '#src/routes/enable/roleplay-scenarios/views/components/UnassignUserModal'
import { formatDateToReadableString } from '#src/utils/date'
import { formatAvatarAbbr } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

const Participants = () => {
	const { scenario, participants } = useLoaderData<LoaderRes>()
	const { companyId } = useCompany()

	return (
		<Surface className="w-full p-6">
			<div className="flex w-full items-center justify-between pb-6">
				<h2 className="flex-1 text-title-md text-neutral-1-fg">Participants</h2>
				{scenario.isValid && (
					<Button asChild className="flex flex-nowrap items-center gap-2">
						<Link
							to={routes.enable.roleplay.scenario.invite({
								companyId,
								scenarioId: scenario.id,
							})}
						>
							<Icon name="add-alt" />
							Invite Participants
						</Link>
					</Button>
				)}
			</div>
			{!participants.length ? (
				<EmptyStateCard
					icon="group"
					title="No participants"
					description="You didn’t invited any team participants yet"
					{...(scenario.isValid && {
						actions: [
							{
								title: 'Invite',
								to: routes.enable.roleplay.scenario.invite({
									companyId,
									scenarioId: scenario.id,
								}),
							},
						],
					})}
				/>
			) : (
				<div className="-mx-6">
					<Table
						data={participants}
						columns={[
							{
								name: 'user',
								heading: 'Participant',
								render: participant => (
									<div className="flex items-center gap-2 text-body-sm font-medium text-neutral-2-fg">
										<ContactAvatar
											className="inline-flex shrink-0 items-center justify-center rounded-full"
											variant="gray"
											size="sm"
											initial={formatAvatarAbbr(participant.username)}
										/>
										{participant.username}
									</div>
								),
							},
							{
								name: 'invited',
								heading: 'Invited at',
								render: participant => formatDateToReadableString(participant.createdAt),
							},
							{
								name: 'actions',
								heading: '',
								render: participant => (
									<UnassignUserModal scenario={scenario} participant={participant}>
										<Button variant="ghost">
											<Icon name="trash" size="font" className="text-status-danger-fg" />
										</Button>
									</UnassignUserModal>
								),
							},
						]}
					/>
				</div>
			)}
		</Surface>
	)
}

export default Participants
