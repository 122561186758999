import { useLoaderData, type LoaderFunctionArgs } from 'react-router'
import { Label } from '#src/components/ui/label'
import { Sidebar, SidebarHeader } from '#src/components/ui/sidebar'
import useCompany from '#src/hooks/useCompany'
import { client } from '#src/main'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { conversationQuery } from '../queries'
import { CONTACT_INFO_KEY_MAP } from '../schema'

export type ContactInfoLoaderResponse = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	validateRouteParams(params, ['conversationId'])

	return {
		conversationId: params.conversationId,
		conversationData: await client.fetchQuery(conversationQuery(company.id, params.conversationId)),
	}
}

export default function Contact() {
	const { conversationId, conversationData } = useLoaderData<ContactInfoLoaderResponse>()
	const { companyId } = useCompany()

	return (
		<Sidebar
			noFooter
			header={
				<SidebarHeader
					heading="Contact Info"
					closeTo={routes.enable.copilot.index({
						companyId,
						conversationId,
					})}
				/>
			}
			main={
				<section className="flex flex-col gap-6">
					{Object.entries(conversationData.participant.info)
						.filter(([key, value]) => Boolean(key && value))
						.map(([key, value]) => (
							<div key={key} className="flex flex-col gap-0.5">
								<Label>{CONTACT_INFO_KEY_MAP[key]}</Label>
								{['linkedin', 'website'].includes(key) && value ? (
									<a
										href={value}
										title={CONTACT_INFO_KEY_MAP[key]}
										target="_blank"
										className="text-body-md font-medium text-link hover:text-link-hover active:text-link-pressed"
										rel="noreferrer"
									>
										{value}
									</a>
								) : (
									<p className="text-body-md font-medium text-neutral-1-fg">{value}</p>
								)}
							</div>
						))}
				</section>
			}
		/>
	)
}
