import { type RouteObject } from 'react-router'
import criteriaRouter from '#src/routes/calibrate/ecosystem-management/criteria/router'
import { ECOSYSTEM_TYPE } from '#src/routes/calibrate/ecosystem-management/ecosystem/constants'
import { getVerticalCTALabel } from '#src/routes/calibrate/ecosystem-management/ecosystem/utils'
import { action as deleteBatchPersonasAction } from '#src/routes/calibrate/ecosystem-management/personas/actions/delete-batch'
import calibratePersonasRouter from '#src/routes/calibrate/ecosystem-management/personas/router'
import { loader as calibrateModuleLoader } from '#src/routes/calibrate/ecosystem-management/sidebar/loaders'
import { View as SidebarView } from '#src/routes/calibrate/ecosystem-management/sidebar/views'
import { action as copyPersonasAction } from '#src/routes/calibrate/ecosystem-management/verticals/actions/copyPersonasAction'
import { copyValidatePersonasAction } from '#src/routes/calibrate/ecosystem-management/verticals/actions/copyValidatePersonasAction'
import { action as cancelCriterionEnrichment } from '#src/routes/calibrate/ecosystem-management/verticals/actions/criteria-sandbox/cancelEnrichment'
import { action as createCriterionEnrichment } from '#src/routes/calibrate/ecosystem-management/verticals/actions/criteria-sandbox/createEnrichment'
import { action as deleteVerticalAction } from '#src/routes/calibrate/ecosystem-management/verticals/actions/delete'
import { action as downloadTemplateAction } from '#src/routes/calibrate/ecosystem-management/verticals/actions/downloadTemplate'
import { action as importAction } from '#src/routes/calibrate/ecosystem-management/verticals/actions/import'
import { action as saveVerticalAction } from '#src/routes/calibrate/ecosystem-management/verticals/actions/save'
import {
	loader as verticalIndexLoader,
	type LoaderResType as VerticalIndexLoaderResType,
} from '#src/routes/calibrate/ecosystem-management/verticals/loaders'
import { loader as copyPersonasLoader } from '#src/routes/calibrate/ecosystem-management/verticals/loaders/copyPersonas'
import { loader as importLoader } from '#src/routes/calibrate/ecosystem-management/verticals/loaders/import'
import { loader as verticalsSaveLoader } from '#src/routes/calibrate/ecosystem-management/verticals/loaders/save'
import { View as IndexVerticalView } from '#src/routes/calibrate/ecosystem-management/verticals/views'
import { View as CopyPersonasView } from '#src/routes/calibrate/ecosystem-management/verticals/views/copy-personas'
import { View as ImportView } from '#src/routes/calibrate/ecosystem-management/verticals/views/import'
import { View as SaveVerticalView } from '#src/routes/calibrate/ecosystem-management/verticals/views/save'
import { routes } from '#src/utils/routes'

export default {
	id: 'calibrate-module',
	path: '',
	loader: calibrateModuleLoader,
	handle: {
		layout: {
			enableHeader: true,
			sidePanelContent: <SidebarView />,
			noPadding: true,
			fullWidth: true,
		},
	},
	children: [
		{
			path: 'verticals/:ecosystemId',
			element: <IndexVerticalView />,
			loader: verticalIndexLoader,
			handle: {
				crumb: (data: VerticalIndexLoaderResType) => [
					{
						label: data.handle.ecosystemName || 'Ecosystem',
						icon: 'ibm-cloud-pak',
					},
				],
				action: (data: VerticalIndexLoaderResType) => {
					if (data.handle.readOnlySession) return []

					switch (data.ecosystem?.type) {
						case ECOSYSTEM_TYPE.KEY_ACCOUNT:
							return [
								{
									label: 'Add New',
									dropdownActions: [
										{
											label: getVerticalCTALabel('Add', data.ecosystem?.type),
											to: routes.calibrate.verticals.create({
												companyId: data.handle.companyId,
												ecosystemId: data.handle.ecosystemId,
											}),
										},
										{
											label: 'CSV',
											to: routes.calibrate.verticals.import({
												companyId: data.handle.companyId,
												ecosystemId: data.handle.ecosystemId,
											}),
										},
									],
								},
							]

						case ECOSYSTEM_TYPE.CRITERIA_SANDBOX:
							return [
								{
									label: 'Add New',
									dropdownActions: [
										{
											label: getVerticalCTALabel('Add', data.ecosystem?.type),
											to: routes.calibrate.verticals.create({
												companyId: data.handle.companyId,
												ecosystemId: data.handle.ecosystemId,
											}),
										},
										{
											label: 'CSV',
											to: routes.calibrate.verticals.import({
												companyId: data.handle.companyId,
												ecosystemId: data.handle.ecosystemId,
											}),
										},
									],
								},
							]

						default:
							return [
								{
									to: routes.calibrate.verticals.create({
										companyId: data.handle.companyId,
										ecosystemId: data.handle.ecosystemId,
									}),
									label: getVerticalCTALabel('Add', data.ecosystem?.type),
									icon: 'category-new',
								},
							]
					}
				},
			},
			children: [
				{
					path: 'save/:verticalId?',
					action: saveVerticalAction,
				},
				{
					path: 'delete/:verticalId',
					action: deleteVerticalAction,
				},
				{
					path: 'edit/:verticalId?',
					element: <SaveVerticalView />,
					loader: verticalsSaveLoader,
				},
				{
					path: 'import',
					element: <ImportView />,
					loader: importLoader,
					action: importAction,
				},
				{
					path: 'persona',
					children: [
						{
							path: 'batch-delete',
							action: deleteBatchPersonasAction,
						},
						{
							path: 'copy-validate',
							action: copyValidatePersonasAction,
						},
						{
							element: <CopyPersonasView />,
							path: 'copy',
							action: copyPersonasAction,
							loader: copyPersonasLoader,
						},
					],
				},
				{
					path: 'criteria-sandbox',
					children: [
						{
							path: ':criterionId/create-enrichment',
							action: createCriterionEnrichment,
						},
						{
							path: ':criterionId/cancel-enrichment',
							action: cancelCriterionEnrichment,
						},
					],
				},
				criteriaRouter,
			],
		},
		calibratePersonasRouter,
		{
			path: 'download',
			children: [
				{
					path: 'template/:ecosystemType',
					action: downloadTemplateAction,
				},
			],
		},
	],
} satisfies RouteObject
