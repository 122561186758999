import { useQuery } from '@tanstack/react-query'
import { useNavigate, useLoaderData, Link, Form, useSearchParams } from 'react-router'
import { companyChatArchiveQueries } from '#src/api/icp/company/chat/archive/queries'
import ContactAvatar from '#src/components/chat/avatar'
import Markdown from '#src/components/markdown'
import { Dialog } from '#src/components/ui/dialog'
import { Icon } from '#src/components/ui/icon'
import { LabeledValue } from '#src/components/ui/LabeledValue'
import { StatusButton } from '#src/components/ui/status-button'
import useAuth from '#src/hooks/useAuth'
import useCompany from '#src/hooks/useCompany'
import { formatDateToReadableString } from '#src/utils/date'
import { cn, formatAvatarAbbr, useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { type LoaderRes } from '../loaders/attempts'

const View = () => {
	const {
		handle: { conversationId },
		attempts: attemptsInitial,
		session,
	} = useLoaderData<LoaderRes>()
	const { company, companyId } = useCompany()
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()
	const { user } = useAuth()

	const action = routes.enable.roleplay.session.start({ companyId, conversationId })
	const method = 'POST'

	const isPending = useIsPending({
		formAction: action,
		formMethod: method,
	})

	const { data: attempts } = useQuery({
		...companyChatArchiveQueries.list(company.id, conversationId),
		initialData: attemptsInitial,
		refetchInterval: 5000,
	})

	const instructionsLayout = (
		<div className="flex h-full w-full flex-col gap-8 px-6 py-8">
			<section className="flex flex-row items-center justify-center gap-2 text-brand-1-fg">
				<Icon name="voice-activate" size="lg" />
				<h1 className="text-title-md">{session.conversation.title}</h1>
			</section>
			<section className="flex flex-col gap-4">
				<div className="flex flex-col items-center gap-2.5">
					<ContactAvatar variant="blue" size="lg" initial={formatAvatarAbbr(session.conversation.name)} />
					<h4 className="text-title-sm">{session.conversation.name}</h4>
					<div className="flex flex-col gap-1 text-body-sm">
						{session.conversation.personaInfo?.ecosystem && (
							<div>
								<b>Ecosystem: </b> {session.conversation.personaInfo.ecosystem}
							</div>
						)}
						{session.conversation.personaInfo?.vertical && (
							<div>
								<b>Vertical: </b> {session.conversation.personaInfo.vertical}
							</div>
						)}
					</div>
				</div>
				{session.conversation.scenarioTitle && (
					<LabeledValue label="Agenda">{session.conversation.scenarioTitle}</LabeledValue>
				)}
				{session.conversation.scenario?.instructions && (
					<LabeledValue label="Instructions">
						<Markdown>{session.conversation.scenario?.instructions}</Markdown>
					</LabeledValue>
				)}
			</section>
		</div>
	)

	return (
		<Dialog
			defaultOpen={true}
			onOpenChange={isOpen =>
				!isOpen &&
				void navigate({
					pathname: routes.enable.roleplay.index({ companyId }),
					search: searchParams.toString(),
				})
			}
			dialogHeading="Session history"
			footer={session.conversation.user === user.email ? undefined : null}
			actions={[
				{ type: 'cancel', label: 'Close' },
				...(session.conversation.user === user.email
					? [
							<Form key="restart-action" method={method} action={action}>
								<StatusButton
									className="w-full max-w-32"
									status={isPending ? 'pending' : 'idle'}
									type="submit"
									size="sm"
									disabled={isPending}
								>
									Start new
								</StatusButton>
							</Form>,
						]
					: []),
			]}
			contentProps={{
				className: 'p-0',
			}}
			size={attempts.length ? 'xl' : 'sm'}
			aside={
				attempts.length ? (
					<div className="w-[430px] overflow-y-auto bg-brand-1-bg p-6">
						<div className="rounded-lg bg-neutral-1-bg">{instructionsLayout}</div>
					</div>
				) : undefined
			}
		>
			{!attempts.length ? (
				<div className="p-6">
					{instructionsLayout}
					{/*<EmptyStateCard icon="voice-activate" title="No attempts" description="This session has no attempts yet." />*/}
				</div>
			) : (
				<div className="flex flex-col">
					{attempts.map((attempt, index) => (
						<Link
							key={attempt.id}
							to={routes.enable.roleplay.session.review({
								companyId,
								conversationId: session.conversation.id,
								attemptId: String(attempt.id),
							})}
							className="flex flex-row items-center gap-4 border-b border-neutral-1-bd p-4 last:border-b-0"
						>
							<div className="flex-1">
								<div className="flex flex-row items-center">
									<p className="text-title-sm text-neutral-1-fg">Attempt {index + 1}</p>
									<p
										className={cn(
											'flex flex-nowrap items-center gap-1 py-0.5 pl-0.5 pr-2 text-label-md transition-colors',
											attempt.evaluation?.status === 'Completed' ? 'text-green-70' : 'text-orange-70',
										)}
									>
										<Icon name="circle-fill" size="sm" />
										<span>{attempt.evaluation?.status ?? 'Evaluating'}</span>
									</p>
								</div>
								<p className="text-body-md">{formatDateToReadableString(attempt.createdAt, { includeTime: true })}</p>
							</div>
							<div>
								<LabeledValue label="Message count">
									<div className="text-right">{attempt.messageCount}</div>
								</LabeledValue>
							</div>
							<Icon name="chevron-right" className="text-neutral-3-fg" />
						</Link>
					))}
				</div>
			)}
		</Dialog>
	)
}

export default View
