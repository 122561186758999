import { useFetcher } from 'react-router'
import { EmptyStateCard } from '#src/components'
import useCompany from '#src/hooks/useCompany'
import { routes } from '#src/utils/routes'

export const NoFilterResults = () => {
	const { companyId } = useCompany()
	const filtersFetcher = useFetcher()
	return (
		<EmptyStateCard
			icon="search"
			title="No results match"
			actions={[
				{
					title: 'Clear all filters',
					noIcon: true,
					onClick: () =>
						void filtersFetcher.submit(
							{ intent: 'clear-all' },
							{
								method: 'POST',
								action: routes.prioritize.filters({ companyId }),
							},
						),
				},
			]}
		/>
	)
}
