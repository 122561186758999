import { useFetcher } from 'react-router'
import { type z } from 'zod'
import { type VerticalAPISchema } from '#src/api/icp/company/verticals/schemas'
import DeleteConfirm from '#src/components/delete-confirm'
import Role from '#src/components/Role'
import { Button } from '#src/components/ui/button'
import { Checkbox } from '#src/components/ui/checkbox'
import { Icon } from '#src/components/ui/icon'
import { CustomTooltip } from '#src/components/ui/tooltip'
import { MODAL_NAME } from '#src/constants/modals'
import useAuth from '#src/hooks/useAuth'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { PersonaCreateModal } from '#src/modals/personas/PersonaCreate'
import { type EcosystemAPISchema } from '#src/routes/calibrate/ecosystem-management/ecosystem/schema'
import { usePersonasCheckboxGroup } from '#src/routes/calibrate/ecosystem-management/verticals/context/PersonasCheckboxGroupContext'
import { routes } from '#src/utils/routes'

type VerticalItemProps = {
	vertical: z.infer<typeof VerticalAPISchema>
	ecosystem?: z.infer<typeof EcosystemAPISchema>
	ecosystemId: string
	readOnlySession: boolean
}

const MasterCheckbox = ({ verticalId }: { verticalId: string }) => {
	const {
		isAllSelected,
		isIndeterminate,
		handleSelectAll,
		getPersonasPerVerticalCount,
		getCheckedPersonasPerVerticalCount,
	} = usePersonasCheckboxGroup()
	const totalPersonas = getPersonasPerVerticalCount(verticalId)
	const checkedPersonasCount = getCheckedPersonasPerVerticalCount(verticalId)
	const isChecked = checkedPersonasCount > 0

	return (
		<div className="flex items-center gap-2">
			<Checkbox
				checked={isIndeterminate(verticalId) ? 'indeterminate' : isAllSelected(verticalId)}
				onCheckedChange={() => handleSelectAll(verticalId)}
			/>
			{isChecked ? (
				<p className="flex items-center gap-1 text-body-md">{`${checkedPersonasCount} selected`}</p>
			) : (
				<p className="flex items-center gap-1 text-body-md font-semibold text-neutral-1-fg">
					{!isChecked ? (
						<>
							<Icon name="user" size="sm" />
							<span className="flex font-normal text-neutral-3-fg">{totalPersonas}</span>
						</>
					) : (
						`${checkedPersonasCount} selected`
					)}
				</p>
			)}
		</div>
	)
}

export const PersonaListHeader = ({ vertical, ecosystemId }: VerticalItemProps) => {
	const { user } = useAuth()
	const fetcher = useFetcher()
	const { companyId } = useCompany()
	const { openModal } = useModals()

	const {
		getCheckedPersonasPerVerticalCount,
		getCheckedPersonasIdsPerVertical,
		getPersonasPerVerticalCount,
		hasMixedVerticalsSelection,
		allCheckedPersonasIds,
	} = usePersonasCheckboxGroup()

	const isPersonaChecked = !!getCheckedPersonasPerVerticalCount(String(vertical.id))
	const checkedPersonasCount = allCheckedPersonasIds.length

	const copyAction = routes.calibrate.verticals.copyValidatePersonas({
		companyId,
		ecosystemId,
	})

	const batchDeleteAction = routes.calibrate.verticals.batchDeletePersonas({
		companyId,
		ecosystemId,
	})

	const personasCount = getPersonasPerVerticalCount(String(vertical.id))
	const copyPersonasTooltipLabel = !hasMixedVerticalsSelection
		? 'Copy Personas'
		: 'Please select personas from a single vertical to enable copying'

	return (
		<section className="flex w-full flex-wrap items-center justify-between gap-2">
			<div className="flex gap-2">{!!personasCount && <MasterCheckbox verticalId={String(vertical.id)} />}</div>
			<Role user={user} allow={['user']} hideIfReadOnly>
				{!isPersonaChecked ? (
					<CustomTooltip label="Add Persona">
						<Button
							size="icon"
							variant="icon"
							onClick={() =>
								openModal({
									name: MODAL_NAME.PersonaCreateModal,
									component: PersonaCreateModal,
									props: {
										ecosystemId: Number(ecosystemId),
										verticalId: Number(vertical.id),
									},
								})
							}
						>
							<Icon name="add" />
						</Button>
					</CustomTooltip>
				) : (
					<div className="flex gap-0.5">
						<fetcher.Form action={copyAction} method="PUT">
							<CustomTooltip label={copyPersonasTooltipLabel}>
								<Button
									size="icon"
									variant="icon"
									type="submit"
									disabled={hasMixedVerticalsSelection}
									className="cursor-disabled"
								>
									<Icon name="copy" />
								</Button>
							</CustomTooltip>
							<input
								name="personas"
								value={JSON.stringify(getCheckedPersonasIdsPerVertical(String(vertical.id)))}
								type="hidden"
							/>
						</fetcher.Form>
						<DeleteConfirm
							trigger={
								<div>
									<CustomTooltip label="Delete Personas">
										<Button size="icon" variant="icon" type="button">
											<Icon name="trash" size="sm" />
										</Button>
									</CustomTooltip>
								</div>
							}
							action={batchDeleteAction}
							title={`Delete ${checkedPersonasCount} ${checkedPersonasCount === 1 ? 'persona' : 'personas'}?`}
							description={
								<>
									Are you sure you want to archive {checkedPersonasCount}{' '}
									{checkedPersonasCount === 1 ? 'persona' : 'personas'} for selected verticals?
								</>
							}
							input={<input name="personas" value={JSON.stringify(allCheckedPersonasIds)} type="hidden" />}
						/>
					</div>
				)}
			</Role>
		</section>
	)
}
