export enum COMPANY_BILLING_FREQUENCY {
	Monthly = 'monthly',
	Quarterly = 'quarterly',
	Yearly = 'yearly',
}

export enum COMPANY_PILLARS {
	CentralizedInteligence = 'centralized_inteligence',
	Organize = 'organize',
	Prioritize = 'prioritize',
	EnableAIPlaysCopilots = 'enable_ai_plays_copilots',
	EnableGenAIVoiceRoleplay = 'enable_genai_voice_roleplay',
}

export enum COMPANY_TRANSACTION_TYPE {
	Discount = 'discount',
	TopUp = 'topup',
	Spend = 'spend',
	Correction = 'correction',
	ContractCredits = 'contract_credits',
}
