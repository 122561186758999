import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useActionData, useLoaderData, useNavigate, useSearchParams } from 'react-router'
import { type z } from 'zod'
import { SessionCreateFormSchema } from '#src/api/icp/company/chat/roleplay/session/schemas'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import { PersonaAvatar } from '#src/components/persona'
import ProductTip from '#src/components/product-tip'
import { TrainingAgenda } from '#src/components/training-agenda'
import { Button } from '#src/components/ui/button'
import { Dialog } from '#src/components/ui/dialog'
import { FormStepper } from '#src/components/ui/FormStepper'
import { StatusButton } from '#src/components/ui/status-button'
import {
	COMMUNICATION_STYLE_OPTIONS,
	DECISION_MAKING_PROCESS_OPTIONS,
	EMOTIONAL_STATE_OPTIONS,
	PERSONALITY_TRAIT_OPTIONS,
} from '#src/constants/options'
import useCompany from '#src/hooks/useCompany'
import {
	COMMUNICATION_STYLES_DETAILS,
	DECISSION_MAKING_PROCESSES_DETAILS,
	EMOTIONAL_STATES_DETAILS,
	PERSONALITY_TRAITS_DETAILS,
	FORM_STEPS,
} from '#src/routes/enable/roleplay-sessions/constants'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { type ActionRes } from '../actions/new'
import { type LoaderRes } from '../loaders/new'

const FORM_NAME = 'session-create-form'

const View = () => {
	const actionData = useActionData<ActionRes>()
	const { scenarioTemplates, ecosystems, voices } = useLoaderData<LoaderRes>()
	const navigate = useNavigate()
	const { companyId } = useCompany()
	const [step, setStep] = useState<FORM_STEPS>(FORM_STEPS.Details)
	const isPending = useIsPending()
	const [searchParams] = useSearchParams()

	const form = useForm<z.infer<typeof SessionCreateFormSchema>>({
		resolver: zodResolver(SessionCreateFormSchema),
		defaultValues: {
			title: '',
			templateId: scenarioTemplates[0].id,
			scenario: scenarioTemplates[0].scenario ?? null,
			scenarioTitle: scenarioTemplates[0].name ?? null,
			evaluationTemplateId: null,
			evaluation: null,
			linkedinUrl: null,
			personality: {
				communicationStyle: COMMUNICATION_STYLE_OPTIONS[0].value,
				decisionMakingProcess: DECISION_MAKING_PROCESS_OPTIONS[0].value,
				emotionalState: EMOTIONAL_STATE_OPTIONS[0].value,
				personalityTrait: PERSONALITY_TRAIT_OPTIONS[0].value,
				voice: voices[0].voiceId,
			},
		},
		mode: 'onSubmit',
	})

	useEffect(() => {
		const failedStep = actionData && 'failedStep' in actionData ? actionData?.failedStep : undefined
		if (failedStep) {
			setStep(failedStep)
		}
		const errors = actionData && 'errors' in actionData ? actionData.errors : undefined
		if (errors) {
			Object.entries(errors).forEach(([fieldName, errorMessages]) => {
				form.setError(fieldName as keyof z.infer<typeof SessionCreateFormSchema>, {
					type: 'server',
					message: errorMessages.join(', '),
				})
			})
		}
	}, [actionData, form])

	const handleContinue = async () => {
		switch (step) {
			case FORM_STEPS.Details: {
				await form.trigger('title')
				await form.trigger('templateId')
				await form.trigger('scenario')
				if (
					!('title' in form.formState.errors) &&
					!('templateId' in form.formState.errors) &&
					!('scenario' in form.formState.errors)
				) {
					setStep(FORM_STEPS.PersonaMapping)
				}
				break
			}
			case FORM_STEPS.PersonaMapping: {
				await form.trigger('ecosystemId')
				await form.trigger('verticalId')
				await form.trigger('personaId')
				await form.trigger('linkedinUrl')
				if (
					!('ecosystemId' in form.formState.errors) &&
					!('verticalId' in form.formState.errors) &&
					!('personaId' in form.formState.errors) &&
					!('linkedinUrl' in form.formState.errors)
				) {
					setStep(FORM_STEPS.Personality)
				}
				break
			}
			case FORM_STEPS.Personality: {
				await form.trigger('personality')
				if (!('personality' in form.formState.errors)) {
					setStep(FORM_STEPS.Evaluation)
				}
				break
			}
			case FORM_STEPS.Evaluation: {
				await form.trigger('evaluation')
				if (!('evaluation' in form.formState.errors)) {
					const formEl = document.getElementById(FORM_NAME) as HTMLFormElement
					formEl.requestSubmit()
				}
				break
			}
		}
	}

	const handleCancel = () => {
		switch (step) {
			case FORM_STEPS.Details:
				void navigate({
					pathname: routes.enable.roleplay.scenario.index({ companyId }),
					search: searchParams.toString(),
				})
				break
			case FORM_STEPS.PersonaMapping:
				setStep(FORM_STEPS.Details)
				break
			case FORM_STEPS.Personality:
				setStep(FORM_STEPS.PersonaMapping)
				break
			case FORM_STEPS.Evaluation:
				setStep(FORM_STEPS.Personality)
				break
		}
	}

	const templateId = form.watch('templateId')
	useEffect(() => {
		const matchingTemplate = scenarioTemplates.find(template => String(template.id) === String(templateId))!
		form.setValue('scenarioTitle', matchingTemplate.name ?? null)
		form.setValue('scenario', matchingTemplate.scenario ?? null)
		form.setValue('evaluationTemplateId', null)
		form.setValue('evaluation', null)
	}, [templateId, form, scenarioTemplates])

	const evaluationTemplateId = form.watch('evaluationTemplateId')
	useEffect(() => {
		const matchingTemplate = scenarioTemplates.find(template => String(template.id) === String(templateId))!
		const matchingEvaluationTemplate = matchingTemplate.evaluationTemplates.find(
			template => String(template.id) === String(evaluationTemplateId),
		)
		form.setValue('evaluation', matchingEvaluationTemplate?.description ?? null)
	}, [templateId, evaluationTemplateId, form, scenarioTemplates])

	const ecosystemId = form.watch('ecosystemId')
	const selectedEcosystemVerticals = useMemo(
		() => ecosystems.find(ecosystem => String(ecosystem.id) === String(ecosystemId))?.verticals ?? [],
		[ecosystemId, ecosystems],
	)
	const verticalId = form.watch('verticalId')
	const selectedVerticalPersonas = useMemo(
		() => selectedEcosystemVerticals.find(vertical => String(vertical.id) === String(verticalId))?.personas ?? [],
		[verticalId, selectedEcosystemVerticals],
	)

	useEffect(() => {
		form.setValue('verticalId', selectedEcosystemVerticals[0]?.id)
	}, [ecosystemId, form, selectedEcosystemVerticals])

	useEffect(() => {
		form.setValue('personaId', selectedVerticalPersonas[0]?.id)
	}, [verticalId, form, selectedVerticalPersonas])

	const evaluationTemplates = useMemo(
		() => scenarioTemplates.find(template => template.id === Number(templateId))?.evaluationTemplates ?? [],
		[scenarioTemplates, templateId],
	)

	return (
		<Dialog
			size="xl"
			defaultOpen={true}
			onOpenChange={isOpen =>
				!isOpen &&
				void navigate({
					pathname: routes.enable.roleplay.index({ companyId }),
					search: searchParams.toString(),
				})
			}
			dialogHeading="Create Roleplay Session"
			actions={[
				<Button onClick={handleCancel} variant="outline" size="sm" key="lra-create-back">
					Back
				</Button>,
				<StatusButton
					status={isPending ? 'pending' : 'idle'}
					disabled={isPending}
					size="sm"
					onClick={() => {
						handleContinue()
							.then()
							.catch(() => {})
					}}
					type="button"
					form={FORM_NAME}
					key="lra-create-submit"
				>
					Continue
				</StatusButton>,
			]}
		>
			<FormWrapper formId={FORM_NAME} formProps={form} method="POST" className="flex flex-col gap-6">
				<FormStepper
					active={step}
					steps={[
						{ name: FORM_STEPS.Details, label: 'Details' },
						{ name: FORM_STEPS.PersonaMapping, label: 'Persona mapping' },
						{ name: FORM_STEPS.Personality, label: 'Personality' },
						{ name: FORM_STEPS.Evaluation, label: 'Evaluation' },
					]}
				/>
				{step === FORM_STEPS.Details && (
					<>
						<FormField name="title" fieldType="text" label="Name" placeholder="Enter session name" />
						<FormField
							name="templateId"
							fieldType="select"
							label="Agenda"
							options={scenarioTemplates.map(template => ({
								value: template.id,
								label: <TrainingAgenda title={template.name} icon="user-service-desk" />,
							}))}
							size="lg"
						/>
						<FormField
							name="scenario"
							fieldType="markdown"
							label={
								<div className="flex flex-row gap-1">
									<span>Scenario tuning</span>
									<ProductTip
										className="text-neutral-3-fg"
										content="If relevant, provide specific guidance for Roleplay behavior. You can include details such as desired tone, specific raised questions, extra knowledge about the company or product or any other special requirements. With more details the Roleplay can tailor is responses to meet your needs."
									/>
								</div>
							}
						/>
					</>
				)}
				{step === FORM_STEPS.PersonaMapping && (
					<>
						<FormField
							name="ecosystemId"
							fieldType="select"
							label="Ecosystem"
							options={ecosystems.map(ecosystem => ({
								value: ecosystem.id,
								label: `${ecosystem.name} (${ecosystem.verticals.length} verticals)`,
								disabled: !ecosystem.verticals.length,
							}))}
						/>
						<FormField
							name="verticalId"
							fieldType="select"
							label="Vertical"
							options={selectedEcosystemVerticals.map(vertical => ({
								value: vertical.id,
								label: `${vertical.name} (${vertical.personas.length} personas)`,
								disabled: !vertical.personas.length,
							}))}
						/>
						<FormField
							name="personaId"
							fieldType="select"
							label="Persona"
							options={selectedVerticalPersonas.map(persona => ({
								value: persona.id,
								label: (
									<div className="grid grid-cols-[max-content,1fr] grid-rows-2 items-center gap-x-2">
										<PersonaAvatar type={persona.type} size="sm" className="row-span-2" />
										<h3 className="text-label-sm text-neutral-3-fg">{persona.expertise}</h3>
										<p className="w-full truncate text-label-sm text-neutral-2-fg">{persona.jobTitles}</p>
									</div>
								),
							}))}
							size="lg"
						/>
						<FormField name="linkedinUrl" fieldType="text" label="LinkedIn URL" placeholder="Enter LinkedIn URL" />
					</>
				)}
				{step === FORM_STEPS.Personality && (
					<>
						<div className="flex flex-col gap-1">
							<FormField
								label="Personality trait"
								fieldType="select"
								name="personality.personalityTrait"
								options={PERSONALITY_TRAIT_OPTIONS}
							/>
							{!!form.watch('personality.personalityTrait') && (
								<div className="text-body-sm text-neutral-3-fg">
									{Object.entries(PERSONALITY_TRAITS_DETAILS[form.watch('personality.personalityTrait')]).map(
										([option, value]) => (
											<p key={`personality-traits-${option}`}>
												<b>{option}:</b> {value}
											</p>
										),
									)}
								</div>
							)}
						</div>
						<div className="flex flex-col gap-1">
							<FormField
								label="Emotional state"
								fieldType="select"
								name="personality.emotionalState"
								options={EMOTIONAL_STATE_OPTIONS}
							/>
							{!!form.watch('personality.emotionalState') && (
								<div className="text-body-sm text-neutral-3-fg">
									{Object.entries(EMOTIONAL_STATES_DETAILS[form.watch('personality.emotionalState')]).map(
										([option, value]) => (
											<p key={`personality-traits-${option}`}>
												<b>{option}:</b> {value}
											</p>
										),
									)}
								</div>
							)}
						</div>
						<div className="flex flex-col gap-1">
							<FormField
								label="Communication style"
								fieldType="select"
								name="personality.communicationStyle"
								options={COMMUNICATION_STYLE_OPTIONS}
							/>
							{!!form.watch('personality.communicationStyle') && (
								<div className="text-body-sm text-neutral-3-fg">
									{Object.entries(COMMUNICATION_STYLES_DETAILS[form.watch('personality.communicationStyle')]).map(
										([option, value]) => (
											<p key={`personality-traits-${option}`}>
												<b>{option}:</b> {value}
											</p>
										),
									)}
								</div>
							)}
						</div>
						<div className="flex flex-col gap-1">
							<FormField
								label="Decission making process"
								fieldType="select"
								name="personality.decisionMakingProcess"
								options={DECISION_MAKING_PROCESS_OPTIONS}
							/>
							{!!form.watch('personality.decisionMakingProcess') && (
								<div className="text-body-sm text-neutral-3-fg">
									{Object.entries(
										DECISSION_MAKING_PROCESSES_DETAILS[form.watch('personality.decisionMakingProcess')],
									).map(([option, value]) => (
										<p key={`personality-traits-${option}`}>
											<b>{option}:</b> {value}
										</p>
									))}
								</div>
							)}
						</div>
						<FormField
							label="Voice character"
							fieldType="select"
							name="personality.voice"
							options={voices.map(voice => ({ value: voice.voiceId, label: voice.name }))}
						/>
					</>
				)}
				{step === FORM_STEPS.Evaluation && (
					<>
						<FormField
							name="evaluationTemplateId"
							fieldType="select"
							label="Evaluation template"
							placeholder="Select evaluation template"
							options={evaluationTemplates.map(template => ({
								value: template.id,
								label: template.name,
							}))}
						/>
						<FormField name="evaluation" fieldType="markdown" label="Evaluation criteria" />
					</>
				)}
			</FormWrapper>
		</Dialog>
	)
}

export default View
