import { type RouteObject } from 'react-router'
import { ReportDashboardView } from './routes/dashboard'

export default {
	path: 'report',
	children: [
		{
			index: true,
			element: <ReportDashboardView />,
		},
	],
} satisfies RouteObject
