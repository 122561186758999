import { z } from 'zod'
import {
	EcosystemAPIPersonaSchema,
	EcosystemAPISchema,
	EcosystemAPIVerticalSchema,
} from '#src/routes/calibrate/ecosystem-management/ecosystem/schema'
import { SignalTemplateAPISchema } from '#src/routes/prioritize/schema'
import { PaginatedAPIResSchema } from '#src/schemas/global'
import { zNumber, zString } from '#src/utils/validation'

export const PersonaMapping = z.object({
	responsibilities: z.string().nullable().optional(),
	kpis: z.string().nullable().optional(),
	frustrations: z.string().nullable().optional(),
	valuePropositions: z.string().nullable().optional(),
	objectionsConcerns: z.string().nullable().optional(),
	objectionAddressing: z.string().nullable().optional(),
	buyerJourneyInfo: z.string().nullable().optional(),
	buyerJourneyUseCases: z.string().nullable().optional(),
	gainInformationFrom: z.string().nullable().optional(),
	boolLinkedin: z.string().nullable().optional(),
})

export const PersonaAPISchema = z.object({
	id: z.number(),
	ecosystem: z.object({ id: z.number() }),
	vertical: z.object({ id: z.number(), name: z.string() }),
	reportsTo: z.object({ id: z.number().nullable(), name: z.string().nullable() }).nullable(),
	reportsOverride: z.string().nullable(),
	type: z.string(),
	status: z.string(),
	priority: z.string(),
	pain: z.string().nullable().optional(),
	painLength: z.number(),
	painOverride: z.string().nullable().optional(),
	expertise: z.string(),
	jobTitles: z.string(),
	jobSeniority: z.string().nullable(),
	jobExperience: z.string().nullable(),
	typeOfTask: z.string().nullable(),
	personaMapping: PersonaMapping.nullable(),
	summary: z.string().nullable(),
	prompt: z.object({
		status: z.string().nullable(),
	}),
})

export const PersonaListAPISchema = z.array(PersonaAPISchema)

export const PersonaFormSchema = z.object({
	type: zString(),
	status: zString(),
	priority: zString(),
	expertise: zString(
		z
			.string()
			.refine(
				val => /^[\dA-Za-z-_ /&]*$/.test(val ?? ''),
				'Only digits, letters, spaces and "- _ / &" symbols allowed',
			),
	).nullable(),
	jobSeniority: zString().nullable(),
	jobExperience: zString().nullable(),
	typeOfTask: zString().nullable(),
	pain: z.string().nullable().optional(),
	painLength: zNumber(z.number()),
	painOverride: zString(z.string().nullable().optional()),
	verticals: z.array(zString()),
	reportsTo: z
		.number()
		.or(zString())
		.nullable()
		.optional()
		.transform(v => {
			if (v === 'other' || v === '-') return null
			return v
		}),
	reportsOverride: zString().nullable().optional(),
	jobTitles: zString(),
})

export const PersonaSignalsAPISchema = SignalTemplateAPISchema.pick({ id: true, name: true }).array()

export const PersonaExpertiseAndTypeAPISchema = z.record(z.string(), z.array(z.string()))

export const CopyValidatePersonaFormSchema = z.object({
	personas: z.string(),
})

export const DeletePersonasFormSchema = z.object({
	personas: z.string().transform(val => JSON.parse(val)),
})

export const CopyPersonaFormSchema = z.object({
	personas: z.string().transform(val => JSON.parse(val)),
	targetVerticals: z.string().transform(val => JSON.parse(val)),
})

export const CopyValidatePersonaPayloadSchema = CopyValidatePersonaFormSchema.extend({
	personas: z.array(z.string()),
})

export const CopyValidatePersonaAPIResSchema = z.array(
	EcosystemAPISchema.pick({
		id: true,
		name: true,
		verticals: true,
	}).extend({
		verticals: z.array(
			EcosystemAPIVerticalSchema.pick({
				id: true,
				name: true,
			}).extend({
				personas: z.array(
					EcosystemAPIPersonaSchema.pick({
						id: true,
						type: true,
						expertise: true,
					}),
				),
			}),
		),
	}),
)

export const GetTypesOfTasksFormSchema = z.object({
	jobTitles: zString(),
	verticalId: zNumber(z.number()),
})

export const GenTypesOfTasksAPISchema = z.object({
	typesOfTasks: z.string(),
})

export const PersonasSchema = EcosystemAPIPersonaSchema.extend({
	verticalName: z.string(),
	ecosystemName: z.string(),
	ecosystemId: z.number(),
	verticalId: z.number(),
})

export const PersonasAPIResSchema = PaginatedAPIResSchema(PersonasSchema)

export type PersonasQueryPayload = {
	page?: number
	ecosystem_id?: string
	vertical_id?: string
	status?: string
	priority?: string
	type?: string
	expertise?: string
	per_page?: number
}

export type PersonasExpertiseQueryPayload = {
	ecosystem_id?: string
	vertical_id?: string
}

export const PersonasExpertiseAPIResSchema = z.array(z.string())
