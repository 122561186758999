import { type LoaderFunctionArgs } from 'react-router'
import { companyChatRoleplayScenarioQueries } from '#src/api/icp/company/chat/roleplay/scenario/queries'
import { client } from '#src/main'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem-management/ecosystem/queries'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)

	return {
		scenarioTemplates: await client.fetchQuery(companyChatRoleplayScenarioQueries.templates(company.id)),
		ecosystems: await client.fetchQuery(ecosystemsQuery(company.id)),
		voices: await client.fetchQuery(companyChatRoleplayScenarioQueries.voices(company.id)),
	}
}
