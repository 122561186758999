import { type LoaderFunctionArgs } from 'react-router'
import { adminPlaysExpertsQueries } from '#src/api/icp/admin/plays-experts/queries'
import { client } from '#src/main'
import { validateRouteParams } from '#src/utils/misc'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	validateRouteParams(params, ['expertId'])

	return {
		data: {
			expert: await client.fetchQuery(adminPlaysExpertsQueries.item(Number(params.expertId))),
		},
	}
}
