import { Icon } from '#src/components/ui/icon'
import useCompany from '#src/hooks/useCompany'
import { useSendMessageMutation } from '#src/routes/enable/chat/mutations'
import { cn } from '#src/utils/misc'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'

export function MessageLoading({ message, className }: { message: string; className?: string }) {
	return <p className={cn('flex items-center gap-0.5 text-label-sm text-neutral-3-fg', className)}>{message}</p>
}

export function MessageError({ message, className }: { message: string; className?: string }) {
	return (
		<p className={cn('flex items-center gap-0.5 text-label-sm tracking-[0.021em] text-status-danger-fg', className)}>
			<Icon name="warning-filled" size="sm" /> {message}
		</p>
	)
}

export function MessageResend({ message }: { message: string }) {
	const { company } = useCompany()
	const params = useParsedRouteParams(['conversationId'])

	const { send } = useSendMessageMutation({
		companyId: company.id,
		conversationId: params.conversationId,
	})

	return (
		<button
			type="button"
			className="text-status-danger-fg"
			onClick={() => {
				send(message, 'resend')
			}}
		>
			<Icon name="restart" size="md" />
		</button>
	)
}
