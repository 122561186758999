import { Link } from 'react-router'
import { type z } from 'zod'
import { type PlaysItemAPISchema } from '#src/api/icp/company/plays/schemas'
import Markdown from '#src/components/markdown'
import { AccordionItem, AccordionTrigger } from '#src/components/ui/accordion'
import { Icon } from '#src/components/ui/icon'
import { MODAL_NAME } from '#src/constants/modals'
import { useModals } from '#src/hooks/useModals'
import { PlayEditModal } from '#src/modals/plays/PlayEdit'

type Props = {
	play: z.infer<typeof PlaysItemAPISchema>
}

const PlayAccordion = (props: Props) => {
	const { play } = props
	const { openModal } = useModals()

	return (
		<AccordionItem
			value={`item-${play.id}`}
			className="p-6"
			trigger={
				<div className="flex flex-row items-center justify-between gap-6">
					<div className="flex-1">
						<AccordionTrigger index={play.id} dataLength={1} className="text-title-sm">
							{play.name}
						</AccordionTrigger>
					</div>
					<Link
						to="#"
						onClick={e => {
							e.preventDefault()
							openModal({
								name: MODAL_NAME.PlayEdit,
								component: PlayEditModal,
								props: {
									playId: play.id,
								},
							})
						}}
						className="inline-flex h-full items-center gap-1 whitespace-nowrap"
					>
						<Icon name="edit" size="sm" />
					</Link>
				</div>
			}
			contentProps={{
				className:
					'overflow-hidden px-4 radix-state-closed:animate-[acc-slide-up_150ms_ease-in-out] radix-state-open:animate-[acc-slide-down_150ms_ease-in-out]',
			}}
		>
			<div className="flex w-full max-w-2xl flex-col gap-6">
				<Markdown className="text-body-md text-neutral-2-fg">{play.instructions}</Markdown>
			</div>
		</AccordionItem>
	)
}

export default PlayAccordion
