import { type ActionFunctionArgs, redirect } from 'react-router'
import { adminCompanyMutations } from '#src/api/icp/admin/company/mutations'
import { CompanyBillingFormSchema } from '#src/api/icp/admin/company/schemas'
import { showToast } from '#src/context/ToastContext'
import { parseFormDataNew } from '#src/utils/forms'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'

export const action = async ({ params, request }: ActionFunctionArgs) => {
	validateRouteParams(params, ['companyId'])
	const formData = await request.formData()

	try {
		const submission = parseFormDataNew(formData, CompanyBillingFormSchema)
		await adminCompanyMutations.updateBilling(params.companyId, submission.value)

		showToast({
			message: 'Company billing details successfully saved',
			type: 'success',
		})
	} catch (e) {
		await serverFormErrorHandler(e)
	}

	return redirect(`${routes.admin.company.edit({ companyId: params.companyId })}?tab=billing`)
}
