import { type ActionFunctionArgs, redirect } from 'react-router'
import { personaMutations } from '#src/api/icp/company/persona/mutations'
import { showToast } from '#src/context/ToastContext'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export const action = async ({ params }: ActionFunctionArgs) => {
	validateRouteParams(params, ['personaId', 'verticalId', 'ecosystemId'])
	const { company, companyId } = await getCompany(params)

	try {
		await personaMutations.deletePersona(company.id, params.personaId)
		showToast({
			type: 'success',
			message: 'Persona deleted.',
		})
		return redirect(
			routes.calibrate.persona.index({
				companyId,
				ecosystemId: params.ecosystemId,
				verticalId: params.verticalId,
				personaId: null,
			}),
		)
	} catch (_) {
		showToast({
			type: 'error',
			message: 'Unexpected error occurred.',
		})
	}

	return null
}
