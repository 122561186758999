import { z } from 'zod'
import {
	CriterionAnswerValueSchema,
	CriterionEnrichedAnswersAPISchema,
	CriterionQuestionAPISchema,
} from '#src/routes/calibrate/ecosystem-management/criteria/schema'
import { EcosystemAPISchema } from '#src/routes/calibrate/ecosystem-management/ecosystem/schema'
import { fileCsv, RegionsSelectFieldSchema, zString } from '#src/utils/validation'

export const CreateCsvFormSchema = z.object({
	file: fileCsv,
	ecosystemId: z.string(),
})
export const VerticalCriterionAPISchema = z.object({
	criterionId: z.number(),
	question: z.string().nullable(),
	value: z.string().or(z.number()).or(z.array(z.string())).or(RegionsSelectFieldSchema).nullable(),
	type: CriterionQuestionAPISchema.shape.type,
	unit: CriterionQuestionAPISchema.shape.unit,
	logic: CriterionQuestionAPISchema.shape.logic,
	isEnrichment: CriterionQuestionAPISchema.shape.isEnrichment,
})

export const VerticalCriteriaAPISchema = z.array(VerticalCriterionAPISchema)

export const VerticalAPISchema = z.object({
	id: z.number(),
	name: z.string(),
	ecosystem: EcosystemAPISchema.pick({ id: true, name: true, type: true }).required(),
	answers: VerticalCriteriaAPISchema,
	enrichedAnswers: CriterionEnrichedAnswersAPISchema,
	status: z.string(),
	priority: z.string(),
	domain: z.string().nullable(),
	bizDescription: z.string().nullable().optional(),
	bizDescriptionOverride: z.string().nullable().optional(),
	bizModelLen: z.number().nullable(),
	prompt: z.object({
		status: z.string().nullable(),
	}),
	listBuildingNotes: z.string().nullable().optional(),
})

export const VerticalListAPISchema = z.array(VerticalAPISchema)

export const VerticalFormSchemaBase = z.object({
	name: zString(),
	status: z.string().optional(),
	priority: z.string().optional(),
	tamEstimateMin: zString(z.string().optional()),
	tamEstimateMax: zString(z.string().optional()),
	bizModelLen: z.number().optional(),
	domain: z.string().optional(),
	bizDescriptionOverride: zString(z.string().nullable().optional()),
	exampleCompanies: zString(z.string().optional()),
	listBuildingNotes: zString(z.string().nullable().optional()),
	ecosystemId: z.string(),
	answers: z
		.array(
			z.object({
				criterionId: z.string(),
				value: CriterionAnswerValueSchema,
			}),
		)
		.optional(),
})

export type CriterionFieldId = `${string}::${string}`
