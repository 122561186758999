import { type LoaderFunctionArgs } from 'react-router'
import { z } from 'zod'
import { client } from '#src/main'
import { companiesQuery } from '#src/routes/company/queries'
import { paramsToObjectSafe } from '#src/utils/requests'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ request }: LoaderFunctionArgs) => {
	let companies = await client.fetchQuery(companiesQuery())
	const filtersSelected = paramsToObjectSafe(new URL(request.url).searchParams, z.object({ search: z.string() }))

	if (filtersSelected.search) {
		const search = (
			Array.isArray(filtersSelected.search) ? filtersSelected.search[0] : filtersSelected.search
		).toLowerCase()
		companies = companies.filter(company => {
			if (company.name.toLowerCase().includes(search)) {
				return true
			}
			return false
		})
	}

	return {
		data: {
			companies,
		},
		filters: {
			selected: filtersSelected,
			values: {}, // todo: there will be filters for active/inactive conmpaines ...
		},
	}
}
