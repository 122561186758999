import { type LoaderFunctionArgs } from 'react-router'
import { companyPersonaQueries } from '#src/api/icp/company/persona/queries'
import { client } from '#src/main'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem-management/ecosystem/queries'
import { findEcosystemByVerticalId, validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type LoaderResType = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	validateRouteParams(params, ['verticalId'])

	const ecosystems = await client.fetchQuery(ecosystemsQuery(company.id))

	const ecosystem = findEcosystemByVerticalId(params.verticalId, ecosystems)

	if (!ecosystem) {
		throw new Response('Ecosystem not found', {
			status: 400,
			statusText: 'Bad Request',
		})
	}

	const [personas, expertises] = await Promise.all([
		client.fetchQuery(
			companyPersonaQueries.list(company.id, {
				page: 1,
				ecosystem_id: String(ecosystem.id),
				vertical_id: params.verticalId,
			}),
		),
		client.fetchQuery(
			companyPersonaQueries.expertiseList(company.id, {
				ecosystem_id: String(ecosystem.id),
				vertical_id: params.verticalId,
			}),
		),
	])

	return {
		ecosystems,
		// companyId: params.companyId,
		verticalId: params.verticalId,
		personas,
		expertises,
	}
}
