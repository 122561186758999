import { useFetcher } from 'react-router'
import { type z } from 'zod'
import { type CompanyUserAPISchema } from '#src/api/icp/company/user/schemas'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { CustomTooltip } from '#src/components/ui/tooltip'
import useCompany from '#src/hooks/useCompany'
import { COMPANY_USER_STATUS } from '#src/utils/enumerations'
import { routes } from '#src/utils/routes'

export const ActivateUserForm = ({ user }: { user: z.infer<typeof CompanyUserAPISchema> }) => {
	const { companyId } = useCompany()
	const fetcher = useFetcher()
	const action = routes.settings.team.activate({ companyId, userId: user.id })
	const method = 'PUT'

	const handleSubmit = () => {
		void fetcher.submit(null, {
			method: method,
			action,
		})
	}

	return (
		user.status === COMPANY_USER_STATUS.deactivated && (
			<fetcher.Form method={method} action={action} id={`activateMember-${user.id}`}>
				<div className="flex">
					<CustomTooltip label="Grant access">
						<Button
							onClick={handleSubmit}
							variant="icon"
							type="submit"
							size="sm"
							disabled={fetcher.state !== 'idle'}
							className="flex gap-2"
						>
							<Icon name="reset" />
						</Button>
					</CustomTooltip>
				</div>
			</fetcher.Form>
		)
	)
}
