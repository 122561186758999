import { captureException } from '@sentry/react'
import { type ActionFunctionArgs, redirect } from 'react-router'
import { userMutations } from '#src/api/icp/company/user/mutations'
import { userQueries } from '#src/api/icp/company/user/queries'
import { showToast } from '#src/context/ToastContext'
import { client } from '#src/main'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ params }: ActionFunctionArgs) => {
	validateRouteParams(params, ['userId'])
	const { company, companyId } = await getCompany(params)

	try {
		const user = await client.fetchQuery(userQueries.item(company.id, params.userId))
		await userMutations.reset(company.id, user.email)

		showToast({
			message: `An email has been sent to ${user.email} with further instructions`,
			type: 'success',
		})

		return redirect(routes.settings.team.index({ companyId }))
	} catch (error) {
		captureException(error)

		showToast({
			message: `Failed to reset user password`,
			type: 'error',
		})

		return null
	}
}
