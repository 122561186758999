import { cva, type VariantProps } from 'class-variance-authority'
import { type ReactNode } from 'react'
import { PERSONA_TYPES } from '#src/utils/enumerations'
import { cn, formatAvatarAbbr } from '#src/utils/misc'

export const avatarVariants = cva(
	'inline-flex h-8 w-8 items-center justify-center rounded-full bg-brand-3-bg font-semibold text-neutral-inverse-fg',
	{
		variants: {
			variant: {
				blue: 'bg-brand-1-bg text-brand-1-fg',
				blueInverse: 'bg-blue-10 text-brand-1-fg',
				green: 'bg-green-70',
				orange: 'bg-orange-70',
				gray: 'bg-neutral-2-bd',
				disabled: 'border bg-transparent bg-none border-neutral-3-bd border-dashed text-neutral-3-fg-disabled',
			},
			size: {
				xs: 'h-6 w-6 text-label-sm',
				sm: 'h-8 w-8 text-body-sm',
				md: 'h-10 w-10 text-body-sm',
				lg: 'h-16 w-16 text-title-md',
				xl: 'h-24 w-24 text-heading-sm',
			},
		},
		defaultVariants: {
			variant: 'blue',
			size: 'sm',
		},
	},
)

export default function ContactAvatar({
	initial,
	initialCount = 1,
	variant,
	size,
	className,
	badge,
	disabled,
}: {
	initial: string
	initialCount?: number
	className?: string
	badge?: ReactNode
	disabled?: boolean
} & VariantProps<typeof avatarVariants>) {
	const avatarVariant = disabled ? 'disabled' : variant

	return (
		<div className={cn(avatarVariants({ variant: avatarVariant, size, className }))}>
			{formatAvatarAbbr(initial, initialCount) ?? 'P'}
			{badge && badge}
		</div>
	)
}

export const avatarVariantByPersonaType = (personaType: string | undefined) => {
	switch (personaType) {
		case PERSONA_TYPES.decisionmaker.toLowerCase():
			return 'blue'
		case PERSONA_TYPES.influencer.toLowerCase():
			return 'green'
		case PERSONA_TYPES.champion.toLowerCase():
			return 'orange'
		default:
			return 'blue'
	}
}
