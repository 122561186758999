import { type LoaderFunctionArgs } from 'react-router'
import { type z } from 'zod'
import { client } from '#src/main'
import { type CRITERION_TYPE_ENUM } from '#src/routes/calibrate/ecosystem-management/criteria/constants'
import {
	criteriaListQuery,
	criteriaTemplatesQuery,
	externalCriteriaQuery,
} from '#src/routes/calibrate/ecosystem-management/criteria/queries'
import { ECOSYSTEM_TYPE } from '#src/routes/calibrate/ecosystem-management/ecosystem/constants'
import { ecosystemQuery } from '#src/routes/calibrate/ecosystem-management/ecosystem/queries'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type LoaderResType = Awaited<ReturnType<ReturnType<typeof loader>>>

export const loader =
	(type: z.infer<typeof CRITERION_TYPE_ENUM>) =>
	async ({ params }: LoaderFunctionArgs) => {
		validateRouteParams(params, ['ecosystemId'])
		const { company } = await getCompany(params)
		const ecosystem = await client.fetchQuery(ecosystemQuery(company.id, params.ecosystemId))
		const isCriteriaSandbox = ecosystem.type === ECOSYSTEM_TYPE.CRITERIA_SANDBOX

		const [sandboxCriteriaList, criteriaList, externalCriteriaList, criteriaTemplatesList] = await Promise.all([
			client.fetchQuery(criteriaListQuery(company.id, true)),
			client.fetchQuery(criteriaListQuery(company.id, false)).then(data => {
				if (!isCriteriaSandbox) {
					return data.filter(item => (type === 'enrichment' ? item.isEnrichment : !item.isEnrichment))
				}

				return data
			}),
			client
				.fetchQuery(externalCriteriaQuery(company.id))
				.then(data => data.filter(item => (type === 'enrichment' ? item.isEnrichment : !item.isEnrichment))),
			client.fetchQuery(criteriaTemplatesQuery(company.id)),
		])

		return {
			values: {
				ecosystemId: params.ecosystemId,
				type,
			},
			data: {
				criteriaList,
				sandboxCriteriaList,
				externalCriteriaList,
				criteriaTemplatesList,
				ecosystem,
			},
		}
	}
