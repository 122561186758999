import { memo, useCallback, useEffect, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { Link, useFetcher, useLoaderData } from 'react-router'
import { type z } from 'zod'
import { FormField } from '#src/components/forms/v2/FormField'
import { Accordion } from '#src/components/ui/accordion'
import { Button } from '#src/components/ui/button'
import { Divider } from '#src/components/ui/Divider'
import { Icon } from '#src/components/ui/icon'
import { CustomTooltip } from '#src/components/ui/tooltip'
import { type CriteriaEditAnswersLoaderType } from '#src/routes/calibrate/ecosystem-management/criteria/routes/editAnswers'
import { cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { type CriterionAnswerAPISchema, type CriterionAPISchema } from '../../schema'
import { generateCriterionAnswerFieldId, generateCriterionAnswerPerVertical } from '../../utils'

type CriterionAnswersCard = {
	criterion: z.infer<typeof CriterionAPISchema>['criterion']
	answers: z.infer<typeof CriterionAPISchema>['answers']
}

export const CriterionAnswersCard = memo(({ criterion, answers }: CriterionAnswersCard) => {
	const {
		values: { companyId, ecosystemId, type },
	} = useLoaderData<CriteriaEditAnswersLoaderType>()
	const fetcher = useFetcher()
	const { setValue, control, getValues, watch } = useFormContext()

	const handleUnassign = useCallback(() => {
		void fetcher.submit(null, {
			method: 'PATCH',
			action: routes.calibrate.criteria.unassign({
				companyId,
				ecosystemId,
				criterionType: type,
				criterionId: String(criterion.id),
			}),
		})
	}, [fetcher, companyId, ecosystemId, type, criterion.id])

	const getCriterionToggles = () => Object.keys(getValues()).filter(name => name.endsWith(`${criterion.id}Toggle`))

	const watchedToggles = useWatch({
		control,
		name: getCriterionToggles(),
	})

	const handleToggleAllVerticals = (v: boolean) => getCriterionToggles().forEach(fieldName => setValue(fieldName, v))

	const handleCopyValueToAll = (answer: z.infer<typeof CriterionAnswerAPISchema>) => {
		const formValues = watch()
		const targetField = generateCriterionAnswerFieldId(answer.verticalId, criterion.id)
		const targetValue = getValues(targetField) as string | number | null

		Object.keys(formValues).forEach(key => {
			if (key.endsWith('Toggle') && (formValues as Record<string, unknown>)[key] === true) {
				const fieldKey = key.replace('Toggle', '')
				const isSingleCardFields = fieldKey.endsWith(String(criterion.id))

				if (fieldKey in formValues && isSingleCardFields) {
					setValue(fieldKey, targetValue)
				}
			}
		})
	}

	useEffect(() => {
		const allEnabled = watchedToggles.every(item => item === true)
		setValue(`toggleAll${criterion.id}`, allEnabled)
	}, [watchedToggles, setValue, criterion.id])

	const data = useMemo(
		() =>
			answers.length
				? [
						{
							trigger: (
								<div className="flex w-full items-center justify-between text-left">
									<span>{criterion.name}</span>
									<div className="flex">
										<div onClick={e => e.stopPropagation()}>
											<CustomTooltip label="Edit criterion">
												<Button variant="icon" size="icon" asChild>
													<Link
														to={routes.calibrate.criteria.edit({
															companyId: companyId,
															ecosystemId: ecosystemId,
															criterionType: type,
															criterionId: String(criterion.id),
														})}
													>
														<Icon name="edit" size="sm" />
													</Link>
												</Button>
											</CustomTooltip>
										</div>
										<div onClick={e => e.stopPropagation()}>
											<CustomTooltip label="Remove criterion">
												<Button
													variant="icon"
													size="icon"
													type="button"
													className="text-status-danger-fg"
													onClick={handleUnassign}
												>
													<Icon name="trash" size="sm" />
												</Button>
											</CustomTooltip>
										</div>
									</div>
								</div>
							),
							content: (
								<div className="flex flex-col gap-4.5 px-3.5 pb-4 pt-5">
									<div className="flex items-center justify-between">
										<span className="text-body-md font-semibold">Enable all</span>
										<FormField
											name={`toggleAll${criterion.id}`}
											fieldType="switch"
											onChange={v => handleToggleAllVerticals(v as boolean)}
											disableLabel
										/>
									</div>
									<Divider marginY="none" />
									{answers.map(item => (
										<div key={item.verticalId + item.criterionId} className="flex gap-1">
											{generateCriterionAnswerPerVertical(
												criterion,
												item,
												<div className="self-end">
													<CustomTooltip label="Copy value to all" sideOffset={14}>
														<Button onClick={() => handleCopyValueToAll(item)} variant="secondary" type="button">
															<Icon name="copy" size="sm" className="text-neutral-3-fg" />
														</Button>
													</CustomTooltip>
												</div>,
											)}
										</div>
									))}
								</div>
							),
						},
					]
				: [],

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[answers, companyId, criterion, ecosystemId, handleUnassign, type],
	)

	const isPending = fetcher.state === 'submitting' || fetcher.state === 'loading'

	return (
		!!data.length && (
			<Accordion
				data={data}
				type="multiple"
				defaultValue={['item-0']}
				contentProps={{ forceMount: true }}
				className={cn('rounded border border-neutral-1-bd', isPending && 'pointer-events-none opacity-50')}
				triggerProps={{
					className:
						'w-full flex items-center text-neutral-1-fg bg-neutral-2-bg px-3.5 py-2 rounded-t radix-state-closed:rounded-b overflow-hidden',
				}}
			/>
		)
	)
})

CriterionAnswersCard.displayName = 'CriterionAnswersCard'
