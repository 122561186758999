import { z } from 'zod'
import { ECOSYSTEM_TYPE } from '#src/routes/calibrate/ecosystem-management/ecosystem/constants'
import { zNumber } from '#src/utils/validation'

export const EcosystemAPIPersonaSchema = z.object({
	id: z.number(),
	type: z.string(),
	jobTitles: z.string().nullable().optional(),
	jobSeniority: z.string().nullable().optional(),
	expertise: z.string(),
	status: z.string(),
	priority: z.string(),
})

export const EcosystemAPIVerticalSchema = z.object({
	id: z.number(),
	name: z.string(),
	personas: EcosystemAPIPersonaSchema.array(),
	status: z.string(),
	priority: z.string(),
})

export const EcosystemAPISchema = z.object({
	id: z.number(),
	name: z.string(),
	verticals: EcosystemAPIVerticalSchema.array(),
	type: z.nativeEnum(ECOSYSTEM_TYPE),
})

export const EcosystemListAPISchema = z.array(EcosystemAPISchema)

export const EcosystemTemplate = z.object({
	id: z.number(),
	name: z.string(),
})

export const EcosystemTemplateListAPISchema = z.array(EcosystemTemplate)

export const EcosystemTemplateFormSchema = z.object({
	ecosystemId: z.string(),
	name: z.string().optional(),
})

export const EcosystemFormSchema = z.object({
	id: zNumber(z.number()).optional(),
	name: z.string(),
	type: z.nativeEnum(ECOSYSTEM_TYPE),
	template: z.string().nullable().optional(),
})

export const EcosystemsOrderFormSchema = z.object({
	ecosystems: z.array(z.number()),
})
