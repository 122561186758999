import { useContext } from 'react'
import { PanelContext, type PanelContextType } from '#src/context/PanelContext'

export const usePanel = (): PanelContextType => {
	const context = useContext(PanelContext)
	if (!context) {
		throw new Error('PanelContext does not exist. Check if your components is not using it outside the provider')
	}

	return context
}
