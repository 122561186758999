import { type z } from 'zod'
import { contactsEnrichEndpoints } from '#src/api/icp/company/contacts-enrich/endpoints'
import { ContactsEnrichListAPISchema } from '#src/api/icp/company/contacts-enrich/schemas'
import { type CompanySchema } from '#src/routes/company/schema'
import { PaginatedAPIResSchema } from '#src/schemas/global'
import { safeFetchMeta } from '#src/utils/safeFetch'

const keys = {
	all: ['icp', 'company', 'contactsEnrich'] as const,
	list: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		page: number,
		sort: string[],
		filters: Record<string, string | string[]>,
	) => [...keys.all, 'list', companyId, page, ...sort, JSON.stringify(filters)] as const,
}

const listQuery = (
	companyId: z.infer<typeof CompanySchema.shape.id>,
	page: number,
	sort: string[],
	filters: Record<string, string | string[]>,
) => ({
	queryKey: keys.list(companyId, page, sort, filters),
	queryFn: async () =>
		await safeFetchMeta(
			PaginatedAPIResSchema(ContactsEnrichListAPISchema),
			contactsEnrichEndpoints.list(companyId, page, sort, filters),
			{
				method: 'GET',
			},
		),
})

const queries = {
	list: listQuery,
}

export { queries as contactsEnrichQueries, keys as contactsEnrichQueriesKeys }
