import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useLoaderData } from 'react-router'
import { companyPersonaEndpoints } from '#src/api/icp/company/persona/endpoints'
import { companyPersonaQueriesKeys } from '#src/api/icp/company/persona/queries'
import { PersonasAPIResSchema, type PersonasQueryPayload } from '#src/api/icp/company/persona/schemas'
import { EmptyStateCard } from '#src/components'
import useCompany from '#src/hooks/useCompany'
import { useEcosystem } from '#src/hooks/useEcosystem'
import { PersonasCards, PersonasFilters } from '#src/routes/enable/personas/views/components/index'
import { safeFetchMeta } from '#src/utils/safeFetch'
import usePaginator from '#src/utils/usePaginator'
import { type PersonasIndexLoaderResType } from '../routes/index'

export const usePersonasQuery = (companyId: string, queryParams: PersonasQueryPayload) => {
	const { data: personasData, isFetching: personasLoading } = useQuery({
		queryKey: companyPersonaQueriesKeys.list(companyId, queryParams),
		queryFn: async () =>
			await safeFetchMeta(PersonasAPIResSchema, companyPersonaEndpoints.list(companyId, queryParams), {
				method: 'GET',
			}),
		placeholderData: prev => prev,
	})

	return {
		personasData,
		personasLoading,
	}
}

export type FiltersState = {
	type?: string
	status?: string
	priority?: string
	expertise?: string
}

const filtersIntlVal: FiltersState = {
	type: '',
	status: '',
	priority: '',
	expertise: '',
}

export const Index = () => {
	const [filters, setFilters] = useState<FiltersState>(filtersIntlVal)
	const { personas, verticalId } = useLoaderData<PersonasIndexLoaderResType>()
	const { findEcosystemByVerticalId } = useEcosystem()
	const { currentPage, setCurrentPage } = usePaginator({
		totalItems: personas.meta.total,
	})
	const { company } = useCompany()
	const { personasData, personasLoading } = usePersonasQuery(company.id, {
		page: currentPage,
		ecosystem_id: String(findEcosystemByVerticalId(verticalId)?.id),
		vertical_id: verticalId,
		status: filters.status,
		priority: filters.priority,
		type: filters.type,
		expertise: filters.expertise,
	})

	useEffect(() => {
		setFilters(filtersIntlVal)
	}, [verticalId])

	useEffect(() => {
		setCurrentPage(0)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters])

	return (
		<div className="flex w-full flex-col gap-7">
			<PersonasFilters filters={filters} setFilters={setFilters} />
			{!!personasData?.data.length && (
				<PersonasCards
					personas={personasData}
					isLoading={personasLoading}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
				/>
			)}
			{personasData && !personasData.data.length && (
				<>
					<EmptyStateCard title="No personas found" icon="user-multiple" />
				</>
			)}
		</div>
	)
}
