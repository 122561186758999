import { useQuery } from '@tanstack/react-query'
import { useLoaderData, Navigate } from 'react-router'
import { Logo } from '#src/components/ui/logo'
import { type CopilotLoaderRes } from '#src/routes/copilot/routes'
import { statusQuery } from '#src/routes/enable/chat/queries'
import { routes } from '#src/utils/routes'

export const View = () => {
	const {
		data: { conversation },
	} = useLoaderData<CopilotLoaderRes>()

	const { data: contact } = useQuery({
		...statusQuery(conversation.company, conversation.conversationId),
		refetchInterval: ({ state: { data } }) => {
			if (data?.status !== 'ready')
				return 30 * 1000 // 0.5min
			else return false
		},
		refetchIntervalInBackground: true,
	})

	if (contact?.status === 'ready')
		return (
			<Navigate
				to={routes.enable.copilot.index({
					companyId: conversation.company,
					conversationId: conversation.conversationId,
				})}
			/>
		)

	return (
		<div className="relative flex h-screen w-screen flex-col items-center justify-center gap-4 overflow-hidden">
			<div className="pointer-events-none absolute inset-0 flex animate-pulsing-rings-1 select-none flex-col items-center justify-center opacity-0">
				<div className="min-h-[670px] w-[42.5%] min-w-[670px] rounded-full border border-[#0A1E7A] bg-transparent pb-[42.5%] opacity-[0.06]" />
			</div>
			<div className="pointer-events-none absolute inset-0 flex animate-pulsing-rings-2 select-none flex-col items-center justify-center opacity-0">
				<div className="min-h-[500px] w-[32.5%] min-w-[500px] rounded-full border border-[#0A1E7A] bg-transparent pb-[32.5%] opacity-[0.09]" />
			</div>
			<div className="pointer-events-none absolute inset-0 flex animate-pulsing-rings-3 select-none flex-col items-center justify-center opacity-0">
				<div className="min-h-[330px] w-[22.5%] min-w-[330px] rounded-full border border-[#0A1E7A] bg-transparent pb-[22.5%] opacity-[0.14]" />
			</div>
			<Logo size="xl" type="symbol" />
		</div>
	)
}
