import { useEffect } from 'react'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { PlayDeleteModal } from '#src/modals/plays/PlayDelete'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'

export const View = () => {
	const { openModal } = useModals()
	const { playId } = useParsedRouteParams(['playId'])
	const { companyId } = useCompany()

	useEffect(() => {
		openModal({
			name: MODAL_NAME.PlayDelete,
			component: PlayDeleteModal,
			settings: {
				routeToRedirectOnClose: routes.enable.plays.index({ companyId }),
			},
			props: {
				playId: Number(playId),
			},
		})
	}, [companyId, openModal, playId])

	return null
}
