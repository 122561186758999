import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { baseApi } from '#src/utils/endpoints'

const base = (companyId: z.infer<typeof CompanySchema>['id']) => `${baseApi}/company/${companyId}/enriched-contacts`

const endpoints = {
	list: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		page: number,
		sort: string[],
		filters: Record<string, string | string[]>,
	) => {
		const query = new URLSearchParams({
			page: page.toString(),
			...filters,
		})
		sort.forEach(value => {
			query.append('sort[]', value)
		})
		return `${base(companyId)}?${new URLSearchParams(query).toString()}`
	},
	create: (companyId: z.infer<typeof CompanySchema>['id']) => base(companyId),
	download: (companyId: z.infer<typeof CompanySchema>['id'], sort: string[], filters: Record<string, string>) => {
		const query = new URLSearchParams(filters)

		sort.forEach(value => {
			query.append('sort[]', value)
		})
		return `${base(companyId)}/download-csv?${new URLSearchParams(query).toString()}`
	},
}

export { endpoints as contactsEnrichEndpoints }
