import { type LoaderFunctionArgs } from 'react-router'
import { playsQueries } from '#src/api/icp/company/plays/queries'
import { orgListsQueries } from '#src/api/organise/lists/queries'
import { client } from '#src/main'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem-management/ecosystem/queries'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)

	return {
		data: {
			ecosystems: await client.fetchQuery(ecosystemsQuery(company.id, { personas: false, exclude: true })),
			plays: await client.fetchQuery(playsQueries.list(company.id)),
			tags: (await client.fetchQuery(orgListsQueries.list(company.id, 1, []))).meta.filters!.tags,
		},
	}
}
