import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useActionData } from 'react-router'
import { type z } from 'zod'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import { StatusButton } from '#src/components/ui/status-button'
import { type AuthLoginActionRes } from '#src/routes/auth/routes/login'
import { LoginFormSchema } from '#src/routes/auth/schema'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

const LoginForm = () => {
	const actionData = useActionData<AuthLoginActionRes>()

	const isPending = useIsPending()

	const form = useForm<z.infer<typeof LoginFormSchema>>({
		resolver: zodResolver(LoginFormSchema),
		defaultValues: {
			username: '',
			password: '',
		},
		mode: 'onChange',
	})

	return (
		<FormWrapper
			method="POST"
			action={routes.auth.login}
			formProps={form}
			className="flex w-full flex-col gap-4"
			actionResponse={actionData}
		>
			<FormField fieldType="text" label="Username" name="username" autoFocus />
			<FormField fieldType="password" label="Password" name="password" />

			<StatusButton type="submit" status={isPending ? 'pending' : 'idle'} disabled={isPending}>
				Login
			</StatusButton>
		</FormWrapper>
	)
}

export default LoginForm
