import { type RouteObject } from 'react-router'
import { action as archiveAction } from './actions/archive'
import { action as editAction } from './actions/edit'
import { loader as archiveLoader } from './loaders/archive'
import { loader as editLoader } from './loaders/edit'
import { LRACreateView, lraCreateLoader, lraCreateAction } from './routes/create'
import { lraDownloadAction, lraDownloadLoader } from './routes/download'
import { lraDownloadTemplateAction } from './routes/downloadTemplate'
import { LRAItemView, lraItemLoader, type LRAItemLoaderRes } from './routes/item'
import { LRAListView, lraListLoader } from './routes/list'
import { lraStopAction, lraStopLoader } from './routes/stop'

export default {
	path: 'lra',
	handle: {
		crumb: () => [
			{
				label: 'Lead Research Agent Jobs',
				icon: 'chart-column',
			},
		],
	},
	children: [
		{
			path: '',
			loader: lraListLoader,
			element: <LRAListView />,
			handle: {
				layout: {
					enableHeader: true,
					fullWidth: true,
				},
			},
			children: [
				{
					path: 'new',
					element: <LRACreateView />,
					loader: lraCreateLoader,
					action: lraCreateAction,
				},
			],
		},
		{
			path: ':jobId',
			element: <LRAItemView />,
			loader: lraItemLoader,
			handle: {
				layout: {
					enableHeader: true,
				},
				crumb: (data: LRAItemLoaderRes) => [
					{
						label: data.data.job.title,
					},
				],
			},
			children: [
				{
					path: 'stop',
					loader: lraStopLoader,
					action: lraStopAction,
				},
				{
					path: 'download',
					loader: lraDownloadLoader,
					action: lraDownloadAction,
				},
				{
					path: 'edit',
					loader: editLoader,
					action: editAction,
				},
				{
					path: 'archive',
					loader: archiveLoader,
					action: archiveAction,
				},
			],
		},
		{
			path: 'download-template',
			action: lraDownloadTemplateAction,
		},
	],
} satisfies RouteObject
