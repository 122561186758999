import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { type z } from 'zod'
import {
	NewValuePropositionFormSchema,
	type ValuePropositionItemAPISchema,
} from '#src/api/icp/company/value-proposition/schemas'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import { Button } from '#src/components/ui/button'
import useCompany from '#src/hooks/useCompany'
import { getWordCount, useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

type Props = {
	item: z.infer<typeof ValuePropositionItemAPISchema>
}

export const AccordionContent = (props: Props) => {
	const { item } = props
	const { companyId } = useCompany()
	const formProps = useForm<typeof NewValuePropositionFormSchema>({
		resolver: zodResolver(NewValuePropositionFormSchema),
		defaultValues: { ...item },
	})
	const { reset, formState } = formProps
	const { isDirty } = formState

	const action = routes.calibrate.valueProposition.update({ companyId, pointId: String(item.id) })

	const isPending = useIsPending({
		formAction: action,
		formMethod: 'PATCH',
	})

	useEffect(() => {
		if (isDirty) {
			reset({ ...item })
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [item])

	const words = getWordCount(formProps.watch('description') ?? '')

	return (
		<FormWrapper formProps={formProps} action={action} formId={`valueProposition-${item.id}`} method="PATCH">
			<div className="mb-10 flex flex-col gap-3.5 px-4.5 py-5">
				<FormField name="name" label="Point Name" fieldType="text" />
				<FormField name="description" fieldType="markdown" label={`Description (${words} words)`} />
				<div className="flex gap-2">
					<Button type="submit" disabled={isPending}>
						Save Changes
					</Button>
					{isDirty && (
						<Button variant="ghost" type="button" onClick={() => reset()}>
							Discard Changes
						</Button>
					)}
				</div>
			</div>
		</FormWrapper>
	)
}
