import { type z } from 'zod'
import {
	LeadResearchAgentItemAPISchema,
	LeadResearchAgentListItemAPISchema,
	LeadResearchAgentStatisticsAPISchema,
} from '#src/api/organise/lists/schemas'
import { type CompanySchema } from '#src/routes/company/schema'
import { PaginatedAPIResSchema } from '#src/schemas/global'
import { safeFetch, safeFetchMeta } from '#src/utils/safeFetch'
import { orgListsEndpoints } from './endpoints'

const keys = {
	all: ['org', 'lists'] as const,
	list: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		page: number,
		sort: string[],
		filters?: Record<string, string | string[]>,
	) => [...keys.all, 'list', companyId, page, ...sort, filters] as const,
	item: (jobId: z.infer<typeof LeadResearchAgentItemAPISchema.shape.id>) => [...keys.all, 'item', jobId] as const,
	statistics: (companyId: z.infer<typeof CompanySchema.shape.id>) => [...keys.all, 'statistics', companyId] as const,
}

export const listQuery = (
	companyId: z.infer<typeof CompanySchema.shape.id>,
	page: number,
	sort: string[],
	filters?: Record<string, string | string[]>,
) => ({
	queryKey: keys.list(companyId, page, sort, filters),
	queryFn: async () => {
		return await safeFetchMeta(
			PaginatedAPIResSchema(LeadResearchAgentListItemAPISchema),
			orgListsEndpoints.list(companyId, page, sort, filters),
			{ method: 'GET' },
		)
	},
	staleTime: 30000,
})

export const itemQuery = (jobId: z.infer<typeof LeadResearchAgentItemAPISchema>['id']) => ({
	queryKey: keys.item(jobId),
	queryFn: async () => {
		return await safeFetch(LeadResearchAgentItemAPISchema, orgListsEndpoints.item(jobId), { method: 'GET' })
	},
	staleTime: 30000,
})

export const statisticsQuery = (companyId: z.infer<typeof CompanySchema.shape.id>) => ({
	queryKey: keys.statistics(companyId),
	queryFn: async () => {
		return await safeFetch(LeadResearchAgentStatisticsAPISchema, orgListsEndpoints.statistics(companyId), {
			method: 'GET',
		})
	},
	staleTime: 30000,
})

const queries = {
	list: listQuery,
	item: itemQuery,
	statistics: statisticsQuery,
}

export { queries as orgListsQueries, keys as orgListsQueriesKeys }
