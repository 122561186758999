import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useActionData, useFetcher, useLoaderData } from 'react-router'
import { CreateCsvFormSchema } from '#src/api/icp/company/verticals/schemas'
import { Chip } from '#src/components/chip'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import ProductTip from '#src/components/product-tip'
import { Button } from '#src/components/ui/button'
import { Dialog } from '#src/components/ui/dialog'
import { Icon } from '#src/components/ui/icon'
import useCompany from '#src/hooks/useCompany'
import { ECOSYSTEM_TYPE } from '#src/routes/calibrate/ecosystem-management/ecosystem/constants'
import { type ActionRes } from '#src/routes/calibrate/ecosystem-management/verticals/actions/import'
import { type LoaderResType } from '#src/routes/calibrate/ecosystem-management/verticals/loaders/import'
import { PRIORITY, STATUS } from '#src/utils/enumerations'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

export const View = () => {
	const { companyId } = useCompany()
	const {
		data: { ecosystem },
		values: { ecosystemId },
	} = useLoaderData<LoaderResType>()
	const actionData = useActionData<ActionRes>()

	const formProps = useForm<typeof CreateCsvFormSchema._type>({
		resolver: zodResolver(CreateCsvFormSchema),
		defaultValues: {
			ecosystemId,
		},
	})

	const formId = 'uploadCsv'
	const method = 'POST'
	const action = routes.calibrate.verticals.import({ companyId, ecosystemId })
	const closeTo = routes.calibrate.verticals.index({ companyId, ecosystemId })
	const isPending = useIsPending({ formAction: action, formMethod: method })
	const downloadFetcher = useFetcher<ActionRes>()

	const handleDownload = () => {
		void downloadFetcher.submit(null, {
			method: method,
			action: routes.calibrate.verticals.downloadTemplate({ companyId, ecosystemType: ecosystem.type }),
		})
	}

	useEffect(() => {
		if (actionData && 'message' in actionData) {
			formProps.setError('file', { type: 'server', message: actionData.message })
		}
	}, [actionData, formProps])

	return (
		<Dialog
			defaultOpen={true}
			closeTo={closeTo}
			dialogHeading="Import CSV"
			isSubmitting={isPending}
			actions={[
				{
					label: 'Cancel',
					type: 'cancel',
				},
				{
					label: 'Import',
					type: 'submit',
					formId,
				},
			]}
		>
			<FormWrapper
				formProps={formProps}
				action={action}
				formId={formId}
				method={method}
				encType="multipart/form-data"
				className="flex flex-col gap-4"
			>
				<FormField
					name="file"
					fieldType="file"
					disableLabel
					accept={{ 'text/csv': ['.csv'] }}
					label="Import CSV"
					infoContent={
						<div className="flex flex-col items-center gap-8">
							<div className="flex flex-col gap-4">
								<Icon name="upload-thin" className="block h-10 w-10 text-brand-2-fg" />
								<div className="flex flex-col gap-1">
									<p className="text-center text-title-sm text-neutral-1-fg">Drag your CSV file here</p>
									<p className="text-center text-body-md text-neutral-2-fg">File must-have columns:</p>
								</div>
							</div>
							<div className="flex flex-row items-center justify-center gap-1">
								<>
									<Chip variant="blue">Account Name</Chip>
									<Icon name="add" className="text-neutral-4-fg" />
									<Chip variant="blue">Domain</Chip>
									{ecosystem.type === ECOSYSTEM_TYPE.KEY_ACCOUNT && (
										<>
											<Icon name="add" className="text-neutral-4-fg" />
											<Chip variant="blue" className="flex gap-1">
												<ProductTip
													content={
														<>
															Available options: <b>{Object.values(STATUS).join(', ')}</b>.
															<div className="mt-1 block">
																If no status is specified, the default value will be set to <b>{STATUS.ongoing}</b>.
															</div>
														</>
													}
												/>
												Status
											</Chip>
											<Icon name="add" className="text-neutral-4-fg" />
											<Chip variant="blue" className="flex gap-1">
												<ProductTip
													content={
														<>
															Available options: <b>{Object.values(PRIORITY).join(', ')}</b>.
															<div className="mt-1 block">
																If no priority is specified, the default value will be set to <b>{PRIORITY.high}</b>.
															</div>
														</>
													}
												/>
												Priority
											</Chip>
										</>
									)}
								</>
							</div>
							<div className="flex flex-col gap-2">
								<Button type="button">Upload</Button>
								<Button
									onClick={e => {
										e.preventDefault()
										e.stopPropagation()
										handleDownload()
									}}
									type="button"
									variant="ghost"
									size="sm"
									disabled={downloadFetcher.state !== 'idle'}
								>
									Download example
								</Button>
							</div>
						</div>
					}
				/>
			</FormWrapper>
		</Dialog>
	)
}
