import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useLoaderData } from 'react-router'
import { ScenarioSaveTagsFormSchema } from '#src/api/icp/company/chat/roleplay/scenario/schemas'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import { Dialog } from '#src/components/ui/dialog'
import { type MultiSelectOption } from '#src/components/ui/MultiSelect'
import useCompany from '#src/hooks/useCompany'
import { type LoaderRes } from '#src/routes/enable/roleplay-scenarios/loaders/save-tags'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

export const View = () => {
	const { companyId } = useCompany()
	const {
		data: { scenario, allTags },
	} = useLoaderData<LoaderRes>()
	const formProps = useForm({
		resolver: zodResolver(ScenarioSaveTagsFormSchema),
		defaultValues: {
			tags: scenario.tags,
		},
	})
	const isPending = useIsPending()
	const formId = 'addNewTag'
	const closeTo = routes.enable.roleplay.scenario.index({ companyId })

	return (
		<Dialog
			open={true}
			dialogHeading="Add Tag"
			closeTo={closeTo}
			actions={[
				{
					label: 'Cancel',
					type: 'cancel',
					to: closeTo,
				},
				{
					label: 'Save',
					type: 'submit',
					formId,
				},
			]}
			isSubmitting={isPending}
		>
			<FormWrapper formProps={formProps} formId={formId} className="flex flex-col gap-4">
				<FormField
					fieldType="multiselect"
					name="tags"
					label="Tags"
					options={allTags as MultiSelectOption[]}
					enableCreate
				/>
			</FormWrapper>
		</Dialog>
	)
}
