import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router'
import { type z } from 'zod'
import { Dropdown, DropdownItem } from '#src/components/ui/dropdown'
import { Icon } from '#src/components/ui/icon'
import useAuth from '#src/hooks/useAuth'
import { PanelSectionItem, PanelSectionItemAction } from '#src/layouts/Panel'
import { type EcosystemAPISchema } from '#src/routes/calibrate/ecosystem-management/ecosystem/schema'
import { getEcosystemCTALabel, getVerticalCTALabel } from '#src/routes/calibrate/ecosystem-management/ecosystem/utils'
import { DeleteEcosystemAsyncForm } from '#src/routes/calibrate/ecosystem-management/ecosystem/views/delete'
import { type CalibrateNavEcosystem } from '#src/routes/calibrate/ecosystem-management/sidebar/schema'
import { VerticalItem } from '#src/routes/calibrate/ecosystem-management/sidebar/views/components/VerticalItem'
import { cn } from '#src/utils/misc'

export const EcosystemItem = ({ ecosystems }: { ecosystems: CalibrateNavEcosystem[] }) => {
	const { readOnlySession } = useAuth()
	const [open, setOpen] = useState<string[]>([])
	const params = useParams()

	useEffect(() => {
		if (params.ecosystemId) {
			const newOpen = open?.length ? [...open, params.ecosystemId] : [params.ecosystemId]
			setOpen(newOpen)
		} else {
			setOpen([])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.ecosystemId])

	return (
		<AccordionPrimitive.Root type="multiple" value={open} onValueChange={setOpen}>
			{ecosystems?.map(ecosystem => (
				<AccordionPrimitive.Item key={ecosystem.id} value={ecosystem.id!.toString()}>
					<PanelSectionItem
						className="group items-start justify-between"
						isTrigger
						isActive={params.ecosystemId === ecosystem.id?.toString() && !params.verticalId && !params.personaId}
					>
						<section className="-my-2 flex w-full flex-nowrap items-start gap-1 truncate py-2">
							<AccordionPrimitive.Trigger className="group" disabled={!ecosystem.verticals?.length && readOnlySession}>
								<Icon
									name="chevron-down"
									size="sm"
									aria-hidden
									className="transform text-neutral-3-fg group-disabled:invisible group-radix-state-open:rotate-180"
								/>
							</AccordionPrimitive.Trigger>
							<Link
								to={ecosystem.to}
								className={cn(
									'-my-2 w-full truncate py-2',
									open.includes(ecosystem.id!.toString()) ? 'font-bold' : 'font-semibold',
								)}
							>
								{ecosystem.name}
							</Link>
						</section>

						{readOnlySession ? null : (
							<section
								className={cn('panel-section-item-actions-meta', 'grid-cols-[repeat(2,20px)] justify-items-end')}
							>
								<Dropdown
									key={ecosystem.id}
									trigger={
										<PanelSectionItemAction asChild isTrigger>
											<button type="button" data-type="contextmenu">
												<Icon name="overflow-menu-horizontal" size="sm" />
											</button>
										</PanelSectionItemAction>
									}
									contentProps={{
										side: 'bottom',
										align: 'start',
									}}
								>
									<DropdownItem asChild className="justify-start">
										<Link to={ecosystem.rename}>
											<Icon name="request-quote" size="sm" />
											{getEcosystemCTALabel('Rename', ecosystem.type)}
										</Link>
									</DropdownItem>
									<DropdownItem asChild className="justify-start" onSelect={e => e.preventDefault()}>
										<DeleteEcosystemAsyncForm ecosystem={ecosystem as unknown as z.infer<typeof EcosystemAPISchema>}>
											<div className="flex items-center gap-2">
												<Icon name="trash" size="sm" />
												{getEcosystemCTALabel('Delete', ecosystem.type)}
											</div>
										</DeleteEcosystemAsyncForm>
									</DropdownItem>
								</Dropdown>

								<PanelSectionItemAction asChild isTrigger>
									<Link to={ecosystem.addVertical}>
										<Icon name="category-new" size="sm" />
									</Link>
								</PanelSectionItemAction>
							</section>
						)}

						<section
							className={cn(
								readOnlySession ? 'grid' : 'panel-section-item-actions-primary',
								'grid-cols-[repeat(4,20px)] justify-items-end',
							)}
						>
							<PanelSectionItemAction className="text-label-sm font-normal text-neutral-3-fg">
								<Icon name="category" size="sm" />
							</PanelSectionItemAction>
							<PanelSectionItemAction className="text-label-sm font-normal text-neutral-3-fg">
								<span className="block h-5 w-5 pt-[0.15rem] text-center">{ecosystem.verticals.length ?? '0'}</span>
							</PanelSectionItemAction>
							<PanelSectionItemAction className="text-label-sm font-normal text-neutral-3-fg">
								<Icon name="user" size="sm" />
							</PanelSectionItemAction>
							<PanelSectionItemAction className="text-label-sm font-normal text-neutral-3-fg">
								<span className="block h-5 w-5 pt-[0.15rem] text-center">
									{ecosystem.verticals.reduce(
										(totalPersonaCount, vertical) =>
											totalPersonaCount + (vertical.personas?.length ? vertical.personas.length : 0),
										0,
									)}
								</span>
							</PanelSectionItemAction>
						</section>
					</PanelSectionItem>

					<AccordionPrimitive.Content
						className={cn(
							'overflow-hidden',
							'transition-opacity radix-state-closed:opacity-50 radix-state-open:opacity-100',
							'radix-state-closed:animate-[acc-slide-up_150ms_ease-in-out] radix-state-open:animate-[acc-slide-down_150ms_ease-in-out]',
						)}
					>
						{ecosystem.verticals?.length ? (
							<>
								<VerticalItem verticals={ecosystem.verticals} />
								<div className="w-full pb-2" />
							</>
						) : !readOnlySession ? (
							<PanelSectionItem
								asChild
								isTrigger
								className="gap-2 pl-8 text-body-md text-link hover:bg-transparent hover:text-link-hover active:text-link-pressed"
							>
								<Link to={ecosystem.addVertical}>
									<Icon name="category-new" size="sm" aria-hidden />
									{getVerticalCTALabel('Add', ecosystem.type)}
								</Link>
							</PanelSectionItem>
						) : null}
					</AccordionPrimitive.Content>
				</AccordionPrimitive.Item>
			))}
		</AccordionPrimitive.Root>
	)
}
