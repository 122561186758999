import { type LoaderFunctionArgs } from 'react-router'
import { companyAccountExportQueries } from '#src/api/icp/company/account-export/queries'
import { orgDataEnrichmentWorkflowsQueries } from '#src/api/organise/data-enrichment/workflows/queries'
import { DATA_ENRICHMENT_JOB_ENRICHERS } from '#src/api/organise/data-enrichment/workflows/schemas'
import { orgListsQueries } from '#src/api/organise/lists/queries'
import { showToast } from '#src/context/ToastContext'
import { client } from '#src/main'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)

	const [leadResearchAgentResponse, contactWaterfallingResponse, accountEnrichingResponse, dataAccountExportResponse] =
		await Promise.allSettled([
			client.fetchQuery(orgListsQueries.statistics(company.id)),
			client.fetchQuery(
				orgDataEnrichmentWorkflowsQueries.statistics(company.id, DATA_ENRICHMENT_JOB_ENRICHERS.Waterfalling),
			),
			client.fetchQuery(
				orgDataEnrichmentWorkflowsQueries.statistics(company.id, DATA_ENRICHMENT_JOB_ENRICHERS.EnrichmentCriteria),
			),
			client.fetchQuery(companyAccountExportQueries.statistics(company.id)),
		])

	let leadResearchAgentStats = {
		launched: 0,
		completed: 0,
	}
	if (leadResearchAgentResponse.status === 'fulfilled')
		leadResearchAgentStats = {
			launched: leadResearchAgentResponse.value.inProgressCount + leadResearchAgentResponse.value.inQueueCount,
			completed: leadResearchAgentResponse.value.completedCount,
		}

	let contactWaterfallingStats = {
		launched: 0,
		completed: 0,
	}
	if (contactWaterfallingResponse.status === 'fulfilled')
		contactWaterfallingStats = {
			launched: contactWaterfallingResponse.value.inProgressCount + contactWaterfallingResponse.value.inQueueCount,
			completed: contactWaterfallingResponse.value.completedCount,
		}

	let accountEnrichmentStats = {
		launched: 0,
		completed: 0,
	}
	if (accountEnrichingResponse.status === 'fulfilled')
		accountEnrichmentStats = {
			launched: accountEnrichingResponse.value.inProgressCount + accountEnrichingResponse.value.inQueueCount,
			completed: accountEnrichingResponse.value.completedCount,
		}

	let dataAccountExportStats = {
		launched: 0,
		completed: 0,
	}
	if (dataAccountExportResponse.status === 'fulfilled')
		dataAccountExportStats = {
			launched: dataAccountExportResponse.value.inProgressCount + dataAccountExportResponse.value.inQueueCount,
			completed: dataAccountExportResponse.value.completedCount,
		}

	if (
		leadResearchAgentResponse.status === 'rejected' ||
		contactWaterfallingResponse.status === 'rejected' ||
		accountEnrichingResponse.status === 'rejected' ||
		dataAccountExportResponse.status === 'rejected'
	)
		showToast({
			message: `Failed to load statistics`,
			type: 'error',
		})

	return {
		data: {
			leadResearchAgentStats,
			contactWaterfallingStats,
			accountEnrichmentStats,
			dataAccountExportStats,
		},
	}
}
