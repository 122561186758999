import { type z } from 'zod'
import { type AccountExportListItemAPISchema } from '#src/api/icp/company/account-export/schemas'
import { type CompanySchema } from '#src/routes/company/schema'
import { baseApi } from '#src/utils/endpoints'
import { buildAPISearchParams } from '#src/utils/requests'

const base = (companyId: z.infer<typeof CompanySchema.shape.id>) => `${baseApi}/company/${companyId}/account-export`

const endpoints = {
	list: (companyId: z.infer<typeof CompanySchema.shape.id>, page: number) =>
		`${base(companyId)}?${buildAPISearchParams({ page }).toString()}`,
	statistics: (companyId: z.infer<typeof CompanySchema.shape.id>) => `${base(companyId)}/statistics`,
	createSettings: (companyId: z.infer<typeof CompanySchema.shape.id>) => `${base(companyId)}/job/settings`,
	createValidate: (companyId: z.infer<typeof CompanySchema.shape.id>) => `${base(companyId)}/job/validate`,
	create: (companyId: z.infer<typeof CompanySchema.shape.id>) => `${base(companyId)}/job/create`,
	itemDownload: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		jobId: z.infer<typeof AccountExportListItemAPISchema.shape.id>,
	) => `${base(companyId)}/${jobId}/download`,
	item: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		jobId: z.infer<typeof AccountExportListItemAPISchema.shape.id>,
	) => `${base(companyId)}/${jobId}`,
}

export { endpoints as companyAccountExportEndpoints }
