import { type ActionFunctionArgs } from 'react-router'
import { criteriaMutations } from '#src/api/icp/company/criteria/mutations'
import { showToast } from '#src/context/ToastContext'
import { GetInstructionsFormSchema } from '#src/routes/calibrate/ecosystem-management/criteria/schema'
import { parseFormDataNew } from '#src/utils/forms'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'

export type ActionRes = Awaited<ReturnType<typeof action>>

//Not used currently
export const action = async ({ params, request }: ActionFunctionArgs) => {
	validateRouteParams(params, ['ecosystemId'])
	const { company } = await getCompany(params)
	const formData = await request.formData()

	try {
		const submission = parseFormDataNew(formData, GetInstructionsFormSchema)
		const res = await criteriaMutations.getInstructions(company.id, submission.value)

		showToast({
			message: `Successfully generated`,
			type: 'success',
		})

		return { ok: true, result: res.instructions }
	} catch (err) {
		return await serverFormErrorHandler(err)
	}
}
