import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { PaginatedAPIResSchema } from '#src/schemas/global'
import { safeFetch, safeFetchMeta } from '#src/utils/safeFetch'
import { orgDataEnrichmentWorkflowsEndpoints } from './endpoints'
import {
	DataEnrichmentStatisticsAPISchema,
	DataEnrichmentListItemAPISchema,
	DataEnrichmentItemAPISchema,
	type DATA_ENRICHMENT_JOB_ENRICHERS,
} from './schemas'

const keys = {
	all: ['org', 'data-enrichment', 'workflows'] as const,
	list: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		page: number,
		sort: string[],
		enricher: DATA_ENRICHMENT_JOB_ENRICHERS,
	) => [...keys.all, 'list', companyId, page, sort, enricher] as const,
	item: (jobId: z.infer<typeof DataEnrichmentItemAPISchema.shape.id>) => [...keys.all, 'item', jobId] as const,
	statistics: (companyId: z.infer<typeof CompanySchema.shape.id>, enricher: DATA_ENRICHMENT_JOB_ENRICHERS) =>
		[...keys.all, 'statistics', companyId, enricher] as const,
}

export const listQuery = (
	companyId: z.infer<typeof CompanySchema.shape.id>,
	page: number,
	sort: string[],
	enricher: DATA_ENRICHMENT_JOB_ENRICHERS,
) => ({
	queryKey: keys.list(companyId, page, sort, enricher),
	queryFn: async () => {
		return await safeFetchMeta(
			PaginatedAPIResSchema(DataEnrichmentListItemAPISchema),
			orgDataEnrichmentWorkflowsEndpoints.list(companyId, page, sort, enricher),
			{ method: 'GET' },
		)
	},
	staleTime: 30000,
})

export const itemQuery = (jobId: z.infer<typeof DataEnrichmentItemAPISchema.shape.id>) => ({
	queryKey: keys.item(jobId),
	queryFn: async () => {
		return await safeFetch(DataEnrichmentItemAPISchema, orgDataEnrichmentWorkflowsEndpoints.item(jobId), {
			method: 'GET',
		})
	},
	staleTime: 30000,
})

export const statisticsQuery = (
	companyId: z.infer<typeof CompanySchema.shape.id>,
	enricher: DATA_ENRICHMENT_JOB_ENRICHERS,
) => ({
	queryKey: keys.statistics(companyId, enricher),
	queryFn: async () => {
		return await safeFetch(
			DataEnrichmentStatisticsAPISchema,
			orgDataEnrichmentWorkflowsEndpoints.statistics(companyId, enricher),
			{
				method: 'GET',
			},
		)
	},
	staleTime: 30000,
})

const queries = {
	list: listQuery,
	item: itemQuery,
	statistics: statisticsQuery,
}

export { queries as orgDataEnrichmentWorkflowsQueries, keys as orgDataEnrichmentWorkflowsQueriesKeys }
