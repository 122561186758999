import { Link } from 'react-router'
import { Button } from '#src/components/ui/button'
import GuestPageLayout from '#src/components/ui/GuestPageLayout'
import { routes } from '#src/utils/routes'

export const View = () => (
	<GuestPageLayout
		title="This link is no longer valid"
		description="If you think this is a mistake or if you have trouble logging into the workspace, please contact the workspace admins or support."
	>
		<div className="text-center">
			<Button asChild>
				<Link to={routes.auth.login}>Go to Login</Link>
			</Button>
		</div>
	</GuestPageLayout>
)
