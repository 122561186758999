import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useFetcher, useNavigate } from 'react-router'
import { type z } from 'zod'
import { CompanyRequestCreditsFormSchema } from '#src/api/icp/company/base/schema'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import { Dialog } from '#src/components/ui/dialog'
import useCompany from '#src/hooks/useCompany'
import { formatNumber } from '#src/utils/numbers'
import { routes } from '#src/utils/routes'
import { type ActionRes } from '../actions/request'

const FORM_ID = 'billing-request-form'

export const View = () => {
	const { companyId, company } = useCompany()
	const navigate = useNavigate()
	const actionFetcher = useFetcher<ActionRes>({
		key: FORM_ID,
	})

	const form = useForm<z.infer<typeof CompanyRequestCreditsFormSchema>>({
		resolver: zodResolver(CompanyRequestCreditsFormSchema),
		defaultValues: {
			type: '10000',
		},
		mode: 'onSubmit',
	})

	return (
		<Dialog
			defaultOpen
			onOpenChange={isOpen => !isOpen && void navigate(routes.billing.index({ companyId }))}
			dialogHeading="Buy credits"
			isSubmitting={actionFetcher.state === 'submitting'}
			headerProps={{
				dialogHeading: 'Buy credits',
			}}
			actions={[
				{
					type: 'cancel',
					label: 'Cancel',
					onClick: () => void navigate(routes.billing.index({ companyId })),
				},
				{
					type: 'submit',
					label: 'Send request',
					formId: FORM_ID,
				},
			]}
		>
			<FormWrapper formId={FORM_ID} formProps={form}>
				<FormField
					fieldType="radioCards"
					disableLabel
					name="type"
					className="flex-col"
					options={[
						{
							value: '10000',
							label: `${formatNumber(10000)} credits - ${formatNumber(1000, company.currency)}`,
						},
						{
							value: '20000',
							label: `${formatNumber(20000)} credits - ${formatNumber(1000, company.currency)}`,
						},
						{
							value: '30000',
							label: `${formatNumber(30000)} credits - ${formatNumber(1000, company.currency)}`,
						},
						{
							value: '40000',
							label: `${formatNumber(40000)} credits - ${formatNumber(1000, company.currency)}`,
						},
						{
							value: '50000',
							label: `${formatNumber(50000)} credits - ${formatNumber(1000, company.currency)}`,
						},
						{
							value: 'custom',
							label: 'Other amount',
							innerContent: (
								<div className="mt-4 w-full">
									<FormField
										fieldType="number"
										name="custom"
										placeholder="Enter amount"
										disableLabel
										className="bg-white"
									/>
								</div>
							),
						},
					]}
				/>
			</FormWrapper>
		</Dialog>
	)
}
