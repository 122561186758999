import { type ActionFunctionArgs } from 'react-router'
import { MODAL_NAME } from '#src/constants/modals'
import { client } from '#src/main'
import { parseFormDataNew } from '#src/utils/forms'
import { getCompany } from '#src/utils/server/company'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'
import { closeModalServer } from '#src/utils/server/modals'
import { createGlossaryTerm, updateGlossaryTerm } from './mutations'
import { glossaryKeys } from './queries'
import { EditTermFormSchema } from './schema'

export type SaveGlossaryTermActionResponse = Awaited<ReturnType<typeof action>>

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const { company } = await getCompany(params)

	const formData = await request.formData()

	try {
		const submission = parseFormDataNew(formData, EditTermFormSchema)
		if (params.termId) {
			await updateGlossaryTerm(company.id, params.termId, submission.value)
		} else {
			await createGlossaryTerm(company.id, submission.value)
		}
		await client.invalidateQueries({
			queryKey: glossaryKeys.all,
		})
		closeModalServer(MODAL_NAME.TermEdit)
	} catch (e) {
		return await serverFormErrorHandler(e)
	}
}
