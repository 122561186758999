import { type LoaderFunctionArgs } from 'react-router'
import { orgDataEnrichmentWorkflowsQueries } from '#src/api/organise/data-enrichment/workflows/queries'
import {
	DATA_ENRICHMENT_JOB_ENRICHERS,
	DataEnrichmentListItemAPISchema,
} from '#src/api/organise/data-enrichment/workflows/schemas'
import { client } from '#src/main'
import { getValidSortKeys } from '#src/utils/requests'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	const page = parseInt(new URL(request.url).searchParams.get('page') ?? '1')
	const sort = getValidSortKeys(new URL(request.url).searchParams.getAll('sort'), DataEnrichmentListItemAPISchema)

	const values = {
		page: Number.isNaN(page) ? 1 : page,
		sort: sort.length ? sort : ['-createdAt'],
	}

	const [statistics, jobs] = await Promise.all([
		client.fetchQuery(
			orgDataEnrichmentWorkflowsQueries.statistics(company.id, DATA_ENRICHMENT_JOB_ENRICHERS.EnrichmentCriteria),
		),
		client.fetchQuery(
			orgDataEnrichmentWorkflowsQueries.list(
				company.id,
				values.page,
				values.sort,
				DATA_ENRICHMENT_JOB_ENRICHERS.EnrichmentCriteria,
			),
		),
	])

	return {
		values,
		data: {
			statistics,
			jobs,
		},
	}
}
