import { type RouteObject } from 'react-router'
import { playsCreateAction, PlaysCreateView } from '#src/routes/enable/plays/root/routes/create'
import { playsDeleteAction, PlaysDeleteView } from '#src/routes/enable/plays/root/routes/delete'
import { playsEditAction, PlaysEditView } from '#src/routes/enable/plays/root/routes/edit'
import { playsItemLoader } from '#src/routes/enable/plays/root/routes/item'
import playsTemplatesRouter from '#src/routes/enable/plays/templates/router'
import { loader as playsCreateLoader } from './root/loaders/create'
import { playsListLoader, PlaysListView } from './root/routes/list'
import PlaysPanelContent from './root/views/components/PlaysPanelContent'

export default {
	path: 'plays',
	id: 'plays-module',
	loader: playsListLoader,
	handle: {
		layout: {
			enableHeader: true,
			fullWidth: true,
			sidePanelContent: <PlaysPanelContent />,
		},
		crumb: () => [
			{
				label: 'Plays',
				icon: 'continue',
			},
		],
	},
	element: <PlaysListView />,
	children: [
		{
			path: 'new',
			loader: playsCreateLoader,
			action: playsCreateAction,
			element: <PlaysCreateView />,
		},
		{
			path: ':playId',
			loader: playsItemLoader,
			children: [
				{
					path: 'edit',
					loader: playsCreateLoader,
					action: playsEditAction,
					element: <PlaysEditView />,
				},
				{
					path: 'delete',
					action: playsDeleteAction,
					element: <PlaysDeleteView />,
				},
			],
		},
		playsTemplatesRouter,
	],
} satisfies RouteObject
