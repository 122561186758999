import { type ActionFunctionArgs, redirect } from 'react-router'
import { companyMutations } from '#src/api/icp/company/base/mutations'
import { CompanyRequestCreditsFormAPISchema, CompanyRequestCreditsFormSchema } from '#src/api/icp/company/base/schema'
import { showToast } from '#src/context/ToastContext'
import { parseFormDataNew } from '#src/utils/forms'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const { company, companyId } = await getCompany(params)

	const formData = await request.formData()

	try {
		const submission = parseFormDataNew(formData, CompanyRequestCreditsFormSchema)
		const submissionData = CompanyRequestCreditsFormAPISchema.parse(submission.value)
		await companyMutations.requestCredits(company.id, submissionData)
		showToast({
			message: 'Credits requested successfully',
			type: 'success',
		})
	} catch (e) {
		await serverFormErrorHandler(e)
	}

	throw redirect(routes.billing.index({ companyId }))
}
