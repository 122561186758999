import { createContext, type ReactNode, useState } from 'react'

const PANEL_CONTEXT_DEFAULTS = {
	floating: localStorage.getItem('builder-sidebar-minimized') === 'true',
	open: false,
}

export type PanelContextType = {
	value: typeof PANEL_CONTEXT_DEFAULTS
	setValue: (data: Partial<typeof PANEL_CONTEXT_DEFAULTS>) => void
}

export const PanelContext = createContext<PanelContextType | undefined>(undefined)

export const PanelProvider = ({ children }: { children: ReactNode }) => {
	const [value, setValue] = useState(PANEL_CONTEXT_DEFAULTS)

	const setValueMapper = (data: Partial<typeof PANEL_CONTEXT_DEFAULTS>) => {
		if ('floating' in data) {
			localStorage.setItem('builder-sidebar-minimized', String(data.floating))
		}
		setValue(curr => ({ ...curr, ...data }))
	}

	return <PanelContext.Provider value={{ value, setValue: setValueMapper }}>{children}</PanelContext.Provider>
}
