import { type RouteObject } from 'react-router'
import { loader as rootLoader } from '#src/routes/prioritize/loaders/root'
import { prioritizeItemAssignAction } from '#src/routes/prioritize/routes/assign'
import { prioritizeItemExampleAction } from '#src/routes/prioritize/routes/example'
import {
	prioritizeItemLoader,
	type PrioritizeItemLoaderRes,
	PrioritizeItemView,
} from '#src/routes/prioritize/routes/item'
import { prioritizeListLoader, PrioritizeListView } from '#src/routes/prioritize/routes/list'
import { prioritizeListFiltersAction } from '#src/routes/prioritize/routes/list-filters'
import { prioritizeItemSaveAction } from '#src/routes/prioritize/routes/save'
import EnrichPanel from '#src/routes/prioritize/views/components/item/EnrichPanel'

export default {
	id: 'prioritize-module',
	path: 'prioritize',
	loader: rootLoader,
	handle: {
		crumb: () => [
			{
				label: 'Prioritize',
				icon: 'upstream',
			},
		],
	},
	children: [
		{
			path: '',
			element: <PrioritizeListView />,
			loader: prioritizeListLoader,
			handle: {
				layout: {
					fullWidth: true,
				},
			},
			children: [
				{
					path: 'filters',
					action: prioritizeListFiltersAction,
				},
			],
		},
		{
			path: 'details/:signalId',
			element: <PrioritizeItemView />,
			loader: prioritizeItemLoader,
			handle: {
				layout: {
					noPadding: true,
					enableHeader: true,
					fullWidth: true,
					sidePanelContent: <EnrichPanel />,
				},
				crumb: (data: PrioritizeItemLoaderRes) => [
					{
						label: data.data.signalTemplate.name,
					},
				],
			},
			children: [
				{
					path: 'save',
					action: prioritizeItemSaveAction,
				},
				{
					path: 'assign',
					action: prioritizeItemAssignAction,
				},
				{
					path: 'example/:personaId',
					action: prioritizeItemExampleAction,
				},
			],
		},
	],
} satisfies RouteObject
