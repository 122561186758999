import { type ActionFunctionArgs, redirect } from 'react-router'
import { client } from '#src/main'
import {
	createEcosystem,
	createEcosystemFromTemplate,
	updateEcosystem,
} from '#src/routes/calibrate/ecosystem-management/ecosystem/mutations'
import { ecosystemKeys } from '#src/routes/calibrate/ecosystem-management/ecosystem/queries'
import { EcosystemFormSchema } from '#src/routes/calibrate/ecosystem-management/ecosystem/schema'
import { parseFormDataNew } from '#src/utils/forms'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const { company, companyId } = await getCompany(params)

	const formData = await request.formData()

	try {
		const submission = parseFormDataNew(formData, EcosystemFormSchema)
		const { id, template, ...data } = submission.value
		if (template !== 'new' && template) {
			const response = await createEcosystemFromTemplate(company.id, {
				ecosystemId: template,
				name: data.name,
			})

			await client.invalidateQueries({
				queryKey: ecosystemKeys.all,
			})

			return redirect(
				routes.calibrate.verticals.index({
					companyId,
					ecosystemId: response.id.toString(),
				}),
			)
		} else if (id) {
			await updateEcosystem(company.id, id, data)

			await client.invalidateQueries({
				queryKey: ecosystemKeys.all,
			})

			return redirect(
				routes.calibrate.verticals.index({
					companyId,
					ecosystemId: String(id),
				}),
			)
		} else {
			const response = await createEcosystem(company.id, data)

			await client.invalidateQueries({
				queryKey: ecosystemKeys.all,
			})

			return redirect(
				routes.calibrate.verticals.create({
					companyId,
					ecosystemId: response.id.toString(),
				}),
			)
		}
	} catch (e) {
		return await serverFormErrorHandler(e)
	}
}
