import { type LoaderFunctionArgs } from 'react-router'
import { z } from 'zod'
import { playsQueries } from '#src/api/icp/company/plays/queries'
import { playsTemplatesQueries } from '#src/api/icp/company/plays-templates/queries'
import { PlaysTemplatesItemAPISchema } from '#src/api/icp/company/plays-templates/schemas'
import { client } from '#src/main'
import { paramsToObjectSafe } from '#src/utils/requests'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ request, params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)

	const filtersSelected = {
		category: 'all',
		...paramsToObjectSafe(
			new URL(request.url).searchParams,
			PlaysTemplatesItemAPISchema.extend({ search: z.string(), category: z.string() }),
		),
	}
	let templates = await client.fetchQuery(playsTemplatesQueries.list(company.id))

	//firstly we filter them by category, to get correct created by (do not show which will not be included anyways)
	const category = Array.isArray(filtersSelected.category) ? filtersSelected.category[0] : filtersSelected.category
	switch (category) {
		case 'recently': {
			const plays = await client.fetchQuery(playsQueries.list(company.id, { all_plays: 'true' }))
			const playsTemplatesIds = [...new Set(plays.filter(play => !!play.templateId).map(play => play.templateId))]
			templates = templates.filter(template => playsTemplatesIds.includes(template.id))
			break
		}
		case 'team': {
			templates = templates.filter(template => !template.isGlobal)
			break
		}
		case 'evergrowth': {
			templates = templates.filter(template => template.isGlobal && !template.templateExpertId)
			break
		}
		case 'expert': {
			templates = templates.filter(template => template.isGlobal && template.templateExpertId)
			break
		}
		default: {
			break
		}
	}

	const createdBy = [
		...new Set(
			templates.map(template => {
				if (template.templateExpert) {
					return template.templateExpert.fullName
				}
				return template.createdBy
			}),
		),
	]

	if (filtersSelected.createdBy) {
		const createdBy = Array.isArray(filtersSelected.createdBy) ? filtersSelected.createdBy : [filtersSelected.createdBy]

		templates = templates.filter(template => {
			if (template.templateExpert) {
				return createdBy.includes(template.templateExpert.fullName)
			}
			return createdBy.includes(template.createdBy)
		})
	}
	if (filtersSelected.search) {
		const search = (
			Array.isArray(filtersSelected.search) ? filtersSelected.search[0] : filtersSelected.search
		).toLowerCase()
		templates = templates.filter(play => {
			if (play.name.toLowerCase().includes(search)) {
				return true
			}
			if (play.tags.find(item => item.toLowerCase().includes(search))) {
				return true
			}
			if (play.createdBy.toLowerCase().includes(search)) {
				return true
			}

			return false
		})
	}

	return {
		filters: {
			values: {
				createdBy,
			},
			selected: filtersSelected,
		},
		data: {
			templates,
		},
	}
}
