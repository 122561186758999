import { type LoaderFunctionArgs, redirect } from 'react-router'
import { companyChatRoleplayScenarioQueries } from '#src/api/icp/company/chat/roleplay/scenario/queries'
import { showToast } from '#src/context/ToastContext'
import { client } from '#src/main'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem-management/ecosystem/queries'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	validateRouteParams(params, ['scenarioId'])
	const { company, companyId } = await getCompany(params)

	const scenario = await client.fetchQuery(companyChatRoleplayScenarioQueries.item(company.id, params.scenarioId))

	if (!scenario.canEdit) {
		showToast({
			type: 'error',
			message: `${scenario.name} scenario cannot be edited, because it already has assigned personas`,
		})
		throw redirect(routes.enable.roleplay.scenario.index({ companyId }))
	}

	const [scenarioTemplates, ecosystems, voices] = await Promise.all([
		client.fetchQuery(companyChatRoleplayScenarioQueries.templates(company.id)),
		client.fetchQuery(ecosystemsQuery(company.id)),
		client.fetchQuery(companyChatRoleplayScenarioQueries.voices(company.id)),
	])

	return {
		scenario,
		scenarioTemplates,
		ecosystems,
		voices,
	}
}
