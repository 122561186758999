import { Fragment, useMemo } from 'react'
import { Icon } from '#src/components/ui/icon'
import { cn } from '#src/utils/misc'

type Step = {
	name: string
	label: string
}

type Props<T extends readonly Step[]> = {
	active: T[number]['name']
	steps: T
}

export const FormStepper = <T extends readonly Step[]>(props: Props<T>) => {
	const { active, steps } = props

	const stepsMapped = useMemo(() => steps.map(({ name }) => name), [steps])

	return (
		<div className="w-full border-t-2 border-neutral-1-bd">
			<div className="-m-[2px] flex w-full flex-row justify-between">
				{steps.map((step, index) => {
					const isActive = step.name === active
					const isCompleted = stepsMapped.indexOf(active) > stepsMapped.indexOf(step.name)
					return (
						<Fragment key={`step-${step.name}`}>
							{index > 0 && (
								<div
									className={cn(
										'flex-1 border-t-2 py-3',
										isActive || isCompleted ? 'border-brand-1-bd' : 'border-transparent',
									)}
								/>
							)}
							<div
								className={cn('border-t-2 py-3', isActive || isCompleted ? 'border-brand-1-bd' : 'border-transparent')}
							>
								<Icon
									name={isCompleted ? 'checkmark-filled' : isActive ? 'incomplete' : 'circle-dash'}
									size="sm"
									className={cn(
										'mr-1',
										'text-neutral-3-fg',
										isActive && 'text-brand-1-fg',
										isCompleted && 'text-green-70',
									)}
								/>
								<span className="text-body-md text-neutral-1-fg">{step.label}</span>
							</div>
						</Fragment>
					)
				})}
			</div>
		</div>
	)
}
