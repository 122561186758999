import { type z } from 'zod'
import { PlaysExpertsItemAPISchema, PlaysExpertsListAPISchema } from '#src/api/icp/admin/plays-experts/schemas'
import { safeFetch } from '#src/utils/safeFetch'
import { adminPlaysExpertsEndpoints } from './endpoints'

const keys = {
	all: ['icp', 'admin', 'plays-experts'] as const,
	list: () => [...keys.all, 'list'] as const,
	item: (expertId: z.infer<typeof PlaysExpertsItemAPISchema.shape.id>) => [...keys.all, 'item', expertId] as const,
}

const listQuery = () => ({
	queryKey: keys.list(),
	queryFn: async () =>
		await safeFetch(PlaysExpertsListAPISchema, adminPlaysExpertsEndpoints.list(), {
			method: 'GET',
		}),
})

const itemQuery = (expertId: z.infer<typeof PlaysExpertsItemAPISchema.shape.id>) => ({
	queryKey: keys.item(expertId),
	queryFn: async () =>
		await safeFetch(PlaysExpertsItemAPISchema, adminPlaysExpertsEndpoints.item(expertId), {
			method: 'GET',
		}),
})

const queries = {
	list: listQuery,
	item: itemQuery,
}

export { queries as adminPlaysExpertsQueries, keys as adminPlaysExpertsQueriesKeys }
