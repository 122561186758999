import { type RouteObject } from 'react-router'
import ecosystemRouter from '#src/routes/calibrate/ecosystem-management/ecosystem/router'
import verticalsRouter from '#src/routes/calibrate/ecosystem-management/verticals/router'
import valuePropRouter from '#src/routes/calibrate/value-proposition/router'

export default {
	path: 'calibrate',
	handle: {
		crumb: () => [
			{
				label: 'Centralized Intelligence',
				icon: 'machine-learning-model',
			},
		],
	},
	children: [
		{
			path: '',
			children: [ecosystemRouter, verticalsRouter, valuePropRouter],
		},
	],
} satisfies RouteObject
