import { type z } from 'zod'
import {
	PersonaSignalsAPISchema,
	PersonaExpertiseAndTypeAPISchema,
	PersonaAPISchema,
	PersonasAPIResSchema,
	PersonasExpertiseAPIResSchema,
	type PersonasExpertiseQueryPayload,
	type PersonasQueryPayload,
} from '#src/api/icp/company/persona/schemas'
import { type VerticalAPISchema } from '#src/api/icp/company/verticals/schemas'
import { type CompanySchema } from '#src/routes/company/schema'
import { buildQueryParams } from '#src/utils/misc'
import { safeFetch, safeFetchMeta } from '#src/utils/safeFetch'
import { companyPersonaEndpoints } from './endpoints'

const keys = {
	all: ['icp', 'company', 'persona'] as const,
	item: (companyId: z.infer<typeof CompanySchema>['id'], itemId: z.infer<typeof PersonaAPISchema>['id']) =>
		[...keys.all, 'item', companyId, itemId] as const,
	signals: (companyId: z.infer<typeof CompanySchema.shape.id>, personaId: z.infer<typeof PersonaAPISchema.shape.id>) =>
		[...keys.all, 'signals', companyId, personaId] as const,
	expertiseAndType: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		verticalIds: z.infer<typeof VerticalAPISchema.shape.id>[],
	) => [...keys.all, 'expertiseAndType', companyId, ...verticalIds.sort((a, b) => (a < b ? 1 : -1))] as const,
	expertiseList: (companyId: z.infer<typeof CompanySchema.shape.id>, queryParams: PersonasExpertiseQueryPayload) =>
		[...keys.all, 'expertiseList', companyId, buildQueryParams(queryParams)] as const,
	list: (companyId: z.infer<typeof CompanySchema.shape.id>, queryParams: PersonasQueryPayload) =>
		[...keys.all, 'list', companyId, buildQueryParams(queryParams)] as const,
	details: () => [...keys.all, 'details'] as const,
	copyValidate: (companyId: z.infer<typeof CompanySchema.shape.id>) => [...keys.details(), companyId] as const,
}

const itemQuery = (companyId: z.infer<typeof CompanySchema>['id'], itemId: z.infer<typeof PersonaAPISchema>['id']) => ({
	queryKey: keys.item(companyId, itemId),
	queryFn: async () => {
		return await safeFetch(PersonaAPISchema, companyPersonaEndpoints.item(companyId, itemId), {
			method: 'GET',
		})
	},
})

const signalsQuery = (
	companyId: z.infer<typeof CompanySchema.shape.id>,
	personaId: z.infer<typeof PersonaAPISchema.shape.id>,
) => ({
	queryKey: keys.signals(companyId, personaId),
	queryFn: async () =>
		await safeFetch(PersonaSignalsAPISchema, companyPersonaEndpoints.signals(companyId, personaId), {
			method: 'GET',
		}),
})

const expertiseAndTypeQuery = (
	companyId: z.infer<typeof CompanySchema.shape.id>,
	verticalIds: z.infer<typeof VerticalAPISchema.shape.id>[],
) => ({
	queryKey: keys.expertiseAndType(companyId, verticalIds),
	queryFn: async () =>
		await safeFetch(
			PersonaExpertiseAndTypeAPISchema,
			companyPersonaEndpoints.expertiseAndType(companyId, verticalIds),
			{
				method: 'GET',
			},
		),
})

export const personasExpertiseQuery = (companyId: string, queryParams: PersonasExpertiseQueryPayload) => ({
	queryKey: keys.expertiseList(companyId, queryParams),
	queryFn: async () =>
		await safeFetch(PersonasExpertiseAPIResSchema, companyPersonaEndpoints.expertiseList(companyId, queryParams), {
			method: 'GET',
		}),
})

export const personasQuery = (companyId: string, queryParams: PersonasQueryPayload) => ({
	queryKey: keys.list(companyId, queryParams),
	queryFn: async () =>
		await safeFetchMeta(PersonasAPIResSchema, companyPersonaEndpoints.list(companyId, queryParams), {
			method: 'GET',
		}),
})

const queries = {
	signals: signalsQuery,
	expertiseAndType: expertiseAndTypeQuery,
	item: itemQuery,
	expertiseList: personasExpertiseQuery,
	list: personasQuery,
}

export { queries as companyPersonaQueries, keys as companyPersonaQueriesKeys }
