import { useFormContext } from 'react-hook-form'
import { type z } from 'zod'
import { Chip } from '#src/components/chip'
import { FormField } from '#src/components/forms/v2/FormField'
import { type RadioGroupOption } from '#src/components/ui/RadioGroup'
import { CRITERION_SOURCE_TYPES } from '#src/routes/calibrate/ecosystem-management/criteria/constants'
import { type CriterionQuestionAPISchema } from '#src/routes/calibrate/ecosystem-management/criteria/schema'
import { InstructionsField } from '#src/routes/calibrate/ecosystem-management/criteria/views/components/InstructionsField'
import { type FormData } from './Form'

type DetailsSectionProps = {
	disableField: boolean
	ecosystemsInUse?: z.infer<typeof CriterionQuestionAPISchema>['ecosystems']
}

export const FormDetailsSection = ({ disableField, ecosystemsInUse }: DetailsSectionProps) => {
	const { watch } = useFormContext<FormData>()
	const source = watch('source')

	const options: RadioGroupOption[] = disableField
		? [
				{
					value: source,
					label:
						source === CRITERION_SOURCE_TYPES.WEB_SCRAPING
							? CRITERION_SOURCE_TYPES.WEB_SCRAPING
							: 'Provided in CSV upload for LRA',
				},
			]
		: [
				{
					value: CRITERION_SOURCE_TYPES.WEB_SCRAPING,
					label: 'Web Scraping Agent',
				},
				{
					value: CRITERION_SOURCE_TYPES.DATA_SET,
					label: 'Provided in CSV upload for LRA',
				},
			]

	return (
		<>
			<FormField fieldType="text" name="name" clearable disableField={disableField} />
			<section className="flex flex-col gap-4 rounded bg-neutral-2-bg p-3">
				<FormField fieldType="radio" name="source" disabled={disableField} options={options} />
				<InstructionsField />
			</section>
			{!!ecosystemsInUse?.length && (
				<div className="mt-2 flex flex-col items-start gap-2">
					<span className="text-label-sm">{`Used in ${ecosystemsInUse.length} ${ecosystemsInUse.length > 1 ? 'ecosystems' : 'ecosystem'}:`}</span>
					<div className="flex flex-wrap gap-1">
						{ecosystemsInUse.map(item => (
							<Chip key={item} variant="black">
								{item}
							</Chip>
						))}
					</div>
				</div>
			)}
			<FormField fieldType="switch" name="inBizmodel" labelText="Include in business model description" disableLabel />
		</>
	)
}
