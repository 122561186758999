import { format } from 'date-fns'

/**
 * Formats a given date into a human-readable string.
 *
 * Example output: "12 August 2024"
 */
export const formatDateToReadableString = (date: Date | string, options?: { includeTime?: boolean }): string | null => {
	if (!date) return null

	return format(new Date(date), `dd MMMM, yyyy ${options?.includeTime ? 'HH:mm' : ''}`)
}
