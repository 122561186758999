import { type LoaderFunctionArgs } from 'react-router'
import { companyAccountExportQueries } from '#src/api/icp/company/account-export/queries'
import { client } from '#src/main'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	const jobId = new URL(request.url).searchParams.get('jobId')

	const [settings, job] = await Promise.all([
		client.fetchQuery(companyAccountExportQueries.createSettings(company.id)),
		jobId ? client.fetchQuery(companyAccountExportQueries.item(company.id, Number(jobId))) : undefined,
	])

	return {
		data: {
			settings,
			job,
		},
	}
}
