import { type ActionFunctionArgs, redirect } from 'react-router'
import { verticalsMutations } from '#src/api/icp/company/verticals/mutations'
import { verticalsQueriesKeys } from '#src/api/icp/company/verticals/queries'
import { VerticalFormSchemaBase } from '#src/api/icp/company/verticals/schemas'
import { client } from '#src/main'
import { criteriaKeys } from '#src/routes/calibrate/ecosystem-management/criteria/queries'
import { ecosystemKeys } from '#src/routes/calibrate/ecosystem-management/ecosystem/queries'
import {
	generateSaveVerticalContext,
	transformVerticalSaveSubmissionToPayload,
} from '#src/routes/calibrate/ecosystem-management/verticals/utils'
import { parseFormData } from '#src/utils/forms'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'
import { transformAndCleanToggleableFieldsSchema } from '#src/utils/validation'

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const { company, companyId } = await getCompany(params)
	validateRouteParams(params, ['ecosystemId'])
	const formData = await request.formData()
	const intent = formData.get('intent')

	try {
		const { schema } = await generateSaveVerticalContext(company.id, params.ecosystemId)
		const submission = parseFormData(formData, schema.transform(transformAndCleanToggleableFieldsSchema))
		const payload = transformVerticalSaveSubmissionToPayload(submission.value, params.ecosystemId)
		const parsed = VerticalFormSchemaBase.parse(payload)

		if (intent === 'vertical') {
			if (!params.verticalId) {
				await verticalsMutations.create(company.id, Number(params.ecosystemId), parsed)
			} else {
				await verticalsMutations.update(company.id, Number(params.verticalId), parsed)
			}

			await Promise.all([
				client.invalidateQueries({
					queryKey: [...ecosystemKeys.all, 'list'],
				}),
				client.invalidateQueries({
					queryKey: verticalsQueriesKeys.all,
				}),
				client.invalidateQueries({
					queryKey: criteriaKeys.all,
				}),
			])

			return redirect(
				routes.calibrate.verticals.index({
					companyId,
					ecosystemId: params.ecosystemId,
				}),
			)
		}
	} catch (e) {
		return await serverFormErrorHandler(e)
	}
}
