import { COMPANY_TRANSACTION_TYPE } from '#src/api/icp/admin/company/constants'
import { Chip, type ChipProps } from '#src/components/chip'
import { COMPANY_TRANSACTION_TYPE_LABELS } from '#src/routes/admin/company/constants'

type Props = {
	type: COMPANY_TRANSACTION_TYPE
}

const VARIANTS: Record<COMPANY_TRANSACTION_TYPE, ChipProps['variant']> = {
	[COMPANY_TRANSACTION_TYPE.Correction]: 'orange',
	[COMPANY_TRANSACTION_TYPE.TopUp]: 'green',
	[COMPANY_TRANSACTION_TYPE.ContractCredits]: 'purple',
	[COMPANY_TRANSACTION_TYPE.Discount]: 'gray',
	[COMPANY_TRANSACTION_TYPE.Spend]: 'red',
}

const TransactionTypeChip = (props: Props) => {
	const { type } = props

	return <Chip variant={VARIANTS[type]}>{COMPANY_TRANSACTION_TYPE_LABELS[type]}</Chip>
}

export default TransactionTypeChip
