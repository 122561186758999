import { z } from 'zod'
import { zNumber } from '#src/utils/validation'

export const SalesCoachAdjustmentsAPISchema = z.object({
	questionCount: z.number(),
	defuse: z.string().nullable(),
	wordsMin: z.number().nullable(),
	wordsMax: z.number().nullable(),
	criteria: z.string().nullable(),
	valueProp: z.string().nullable(),
})

export const SalesCoachAPISchema = z.object({
	id: z.number(),
	month: z.number().nullable().optional(),
	persona: z.object({
		id: z.number(),
		type: z.string(),
	}),
	timingContext: z
		.string()
		.transform(v => v.replace(/(?<=\d):\s*\n?/g, '. '))
		.nullable(),
	questions: z
		.string()
		.transform(v => v.replace(/(?<=\d):\s*\n?/g, '. '))
		.array()
		.nullable(),
	adjustments: SalesCoachAdjustmentsAPISchema,
	prompt: z.object({
		status: z.string().nullable(),
		promptedAt: z.string().nullable(),
	}),
	selected: z.boolean().nullable(),
})

export const SalesCoachListSchema = z.object({
	calibrated: SalesCoachAPISchema.omit({
		adjustments: true,
		month: true,
		selected: true,
	}).optional(),
	timing: SalesCoachAPISchema.omit({
		adjustments: true,
	})
		.required({ month: true })
		.nullable()
		.optional(),
	adjustments: SalesCoachAdjustmentsAPISchema.nullable().optional(),
	rest: SalesCoachAPISchema.array(),
})

export const SalesCoachListAPISchema = SalesCoachAPISchema.array()

export const SalesCoachAdjustmentsFormSchema = z.object({
	questionCount: zNumber(z.number()),
	wordsMin: zNumber(z.number()),
	wordsMax: zNumber(z.number()),
	defuse: z.string().nullable().optional(),
	criteria: z.string().nullable().optional(),
	valueProp: z.string().nullable().optional(),
	month: zNumber(z.number()).nullable().optional(),
})
