export enum FILE_TYPES {
	Accounts = 'account',
	AccountsAndContacts = 'account_contact',
}

export enum LRA_JOB_STATUS {
	Launching = 'launching',
	InProgress = 'in_progress',
	Completed = 'completed',
	CompletedWithQuestions = 'completed_with_questions',
	Cancelled = 'cancelled',
	Error = 'error',
}

export enum LRA_JOB_STATUS_REASONING {
	InvalidDecisionTree = 'invalid_decision_tree',
}

export enum CONTACT_FINDER_SETTINGS_TYPE {
	ContactsPerAccount = 'contacts_per_account',
	ContactsPerPersonas = 'contacts_per_persona',
}
