import { type RouteObject } from 'react-router'
import { personasIndexLoader, PersonasIndexView } from '#src/routes/enable/personas/routes'
import {
	personasDetailsLoader,
	type PersonasDetailsLoaderResType,
	PersonasDetailsView,
} from '#src/routes/enable/personas/routes/details'
import { personasRootLoader } from '#src/routes/enable/personas/routes/root'
import { PersonasSidebar } from '#src/routes/enable/personas/views/components/index'

export default {
	path: 'personas',
	loader: personasRootLoader,
	handle: {
		crumb: () => [
			{
				label: 'Personas',
				icon: 'user-multiple',
			},
		],
	},
	children: [
		{
			path: ':verticalId',
			children: [
				{
					index: true,
					id: 'personas-loader',
					element: <PersonasIndexView />,
					loader: personasIndexLoader,
					handle: {
						layout: {
							enableHeader: true,
							sidePanelContent: <PersonasSidebar />,
							fullWidth: true,
						},
					},
				},
				{
					path: ':personaId',
					element: <PersonasDetailsView />,
					loader: personasDetailsLoader,
					handle: {
						layout: {
							enableHeader: true,
							fullWidth: true,
						},
						crumb: (data: PersonasDetailsLoaderResType) => [
							{
								label: data.persona.type,
								icon: 'user',
							},
						],
					},
				},
			],
		},
	],
} satisfies RouteObject
