import { useEffect } from 'react'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { PlayTemplateEditModal } from '#src/modals/play-templates/PlayTemplateEdit'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'

export const View = () => {
	const { openModal } = useModals()
	const { templateId } = useParsedRouteParams(['templateId'])
	const { companyId } = useCompany()

	useEffect(() => {
		openModal({
			name: MODAL_NAME.PlayTemplateEdit,
			component: PlayTemplateEditModal,
			props: {
				templateId: Number(templateId),
			},
			settings: {
				routeToRedirectOnClose: routes.enable.playTemplates.index({ companyId }),
			},
		})
	}, [companyId, openModal, templateId])

	return null
}
