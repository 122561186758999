import { adminPlaysExpertsQueries } from '#src/api/icp/admin/plays-experts/queries'
import { client } from '#src/main'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async () => {
	return {
		data: {
			experts: await client.fetchQuery(adminPlaysExpertsQueries.list()),
		},
	}
}
