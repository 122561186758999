import { Slot } from '@radix-ui/react-slot'
import { forwardRef, type HTMLAttributes, type ReactNode, useEffect } from 'react'
import { Icon } from '#src/components/ui/icon'
import { usePanel } from '#src/hooks/layouts/usePanel'
import { cn } from '#src/utils/misc'

export function PanelToggle() {
	const { value, setValue } = usePanel()

	if (!value.floating) return null

	return (
		<button
			type="button"
			onClick={() => setValue({ floating: false, open: false })}
			onMouseEnter={() => setValue({ open: true })}
			onMouseLeave={() => setValue({ open: false })}
			className="group flex items-center p-2 text-neutral-3-fg"
		>
			<Icon name="side-panel-open" className="hidden group-hover:block" />
			<Icon name="menu" className="block group-hover:hidden" />
		</button>
	)
}

export default function Panel({
	children,
	header,
	defaultExpanded,
}: {
	children: ReactNode
	header?: ReactNode
	defaultExpanded?: boolean
}) {
	const { value, setValue } = usePanel()

	useEffect(() => {
		if (defaultExpanded) {
			setValue({ floating: false, open: false })
		}
	}, [defaultExpanded, setValue])

	const handleClose = () => {
		setValue({ floating: true, open: false })
	}

	return (
		<nav
			className={cn(
				'pointer-events-none sticky left-0 top-0 z-30 flex h-full w-[var(--builder-sidebar-width)] shrink-0 grow-0 flex-col overflow-y-auto overflow-x-hidden border-r border-r-neutral-1-bd transition-all',
				value.floating &&
					'fixed left-[var(--navsidebar-width)] z-0 -translate-x-[100%] pt-[calc(var(--builder-header-height)+var(--credits-warning-height))]',
				value.floating && value.open && 'z-30 translate-x-0 shadow',
			)}
			onMouseEnter={() => {
				if (value.floating) {
					setValue({ open: true })
				}
			}}
			onMouseLeave={() => {
				if (value.floating) {
					setValue({ open: false })
				}
			}}
		>
			<div
				className={cn(
					'pointer-events-auto flex h-screen w-full flex-col overflow-y-auto bg-neutral-1-bg pb-4 transition-all',
				)}
			>
				<div className="sticky top-0 z-40 flex flex-nowrap items-center bg-neutral-1-bg p-2 pr-3">
					{!value.floating && (
						<button onClick={handleClose} type="button" className="flex items-center p-2">
							<Icon name="side-panel-close" />
						</button>
					)}
					{header}
				</div>

				{children}
			</div>
		</nav>
	)
}

type PanelSectionItemProps = {
	className?: string
	asChild?: boolean
	isTrigger?: boolean
	isActive?: boolean
	children: ReactNode | ReactNode[]
} & HTMLAttributes<HTMLDivElement>
export const PanelSectionItem = forwardRef<HTMLDivElement, PanelSectionItemProps>(
	({ className, asChild, isTrigger, isActive, children, ...props }, ref) => {
		const Comp = asChild ? Slot : 'div'
		return (
			<Comp
				ref={ref}
				className={cn(
					'mt-1 flex flex-nowrap items-center rounded-sm py-2 pl-3 pr-2 text-body-md transition-all',
					isTrigger ? 'cursor-pointer hover:bg-neutral-1-bg-hover' : '',
					isActive ? 'bg-neutral-1-bg-selected text-neutral-1-fg' : 'bg-transparent text-neutral-2-fg',
					className,
				)}
				{...props}
			>
				{children}
			</Comp>
		)
	},
)
PanelSectionItem.displayName = 'PanelSectionItem'

type PanelSectionItemActionProps = {
	className?: string
	asChild?: boolean
	isTrigger?: boolean
	children: ReactNode
}

export const PanelSectionItemAction = forwardRef<HTMLDivElement, PanelSectionItemActionProps>(
	({ className, asChild, isTrigger, children, ...props }, ref) => {
		const Comp = asChild ? Slot : 'div'
		return (
			<Comp
				{...props}
				ref={ref}
				className={cn(
					'flex h-5 w-5 items-center justify-center',
					'rounded-sm text-neutral-2-fg transition-colors',
					isTrigger ? 'hover:bg-neutral-3-bg-hover' : '',
					className,
				)}
			>
				{children}
			</Comp>
		)
	},
)
PanelSectionItemAction.displayName = 'PanelSectionItemAction'
