import { useFetcher } from 'react-router'
import { type z } from 'zod'
import { type CompanyUserAPISchema } from '#src/api/icp/company/user/schemas'
import DeleteConfirm from '#src/components/delete-confirm'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { CustomTooltip } from '#src/components/ui/tooltip'
import useCompany from '#src/hooks/useCompany'
import { COMPANY_USER_STATUS } from '#src/utils/enumerations'
import { routes } from '#src/utils/routes'

type DeactivateUserFormProps = {
	user: z.infer<typeof CompanyUserAPISchema>
	onlyIcon?: boolean
}

export const DeactivateUserForm = ({ user, onlyIcon }: DeactivateUserFormProps) => {
	const { companyId } = useCompany()
	const fetcher = useFetcher()
	const action = routes.settings.team.deactivate({ companyId, userId: user.id })
	const method = 'PUT'

	return (
		user.status !== COMPANY_USER_STATUS.deactivated && (
			<DeleteConfirm
				trigger={
					<div className="flex">
						<CustomTooltip label="Remove access" disabled={!onlyIcon}>
							<Button
								variant={onlyIcon ? 'icon' : 'danger-outline'}
								type="submit"
								size="sm"
								disabled={fetcher.state !== 'idle'}
								className="flex gap-2"
							>
								<Icon name="user-remove" className={onlyIcon ? 'text-status-danger-fg' : ''} />
								{!onlyIcon && 'Remove Access'}
							</Button>
						</CustomTooltip>
					</div>
				}
				formId={`deactivateMember-${user.id}`}
				method={method}
				action={action}
				actionTitle="Remove"
				title="Remove Access?"
				description={
					<>
						Are you sure you want to remove access for <b>{user.email}</b>?
					</>
				}
			/>
		)
	)
}
