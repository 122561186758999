import { type z } from 'zod'
import DeleteConfirm from '#src/components/delete-confirm'
import { MODAL_NAME } from '#src/constants/modals'
import { useModals } from '#src/hooks/useModals'
import { type CompanySchema } from '#src/routes/company/schema'
import { routes } from '#src/utils/routes'

type AdminCompanyDeleteModalProps = {
	companyId: z.infer<typeof CompanySchema.shape.id>
}

export const AdminCompanyDeleteModal = (props: AdminCompanyDeleteModalProps) => {
	const { closeModal } = useModals()

	return (
		<DeleteConfirm
			defaultOpen={true}
			onOpenChange={isOpen => !isOpen && closeModal(MODAL_NAME.AdminCompanyDelete)}
			action={routes.admin.company.delete({ companyId: props.companyId })}
			title="Delete company?"
			method="DELETE"
			actionTitle="Delete"
			description="This action cannot be undone. This will permanently delete the company and any associated data such as ecosystems, verticals, personas etc."
		/>
	)
}
