import { type ReactNode } from 'react'
import { Outlet } from 'react-router'
import PageLoader from '#src/components/loader'
import { PanelProvider } from '#src/context/PanelContext'
import useLayoutProps from '#src/hooks/layouts/useLayoutProps'
import NavSidebar from '#src/layouts/NavSidebar'
import OutOfCreditsWarning from '#src/layouts/OutOfCreditsWarning'
import Panel from '#src/layouts/Panel'
import SupportInit from '#src/layouts/SupportInit'
import { cn } from '#src/utils/misc'
import Header from './header/Header'

type LayoutProps = {
	enableHeader?: boolean
	fullWidth?: boolean
	sidePanelDefaultExpanded?: boolean
	disableSidebar?: boolean
	sidePanelHeader?: ReactNode
	sidePanelContent?: ReactNode
	noPadding?: boolean
}

const Layout = (props: LayoutProps) => {
	const layout = useLayoutProps(props)
	return (
		<PanelProvider>
			<OutOfCreditsWarning />
			<PageLoader />
			{!layout.disableSidebar && <NavSidebar />}
			{layout.enableHeader && (
				<Header panelToggle={!!layout.sidePanelContent} className="border-b border-neutral-1-bd" />
			)}
			<div
				id="layout-container"
				className={cn(
					'relative flex w-full flex-1 overflow-hidden bg-neutral-1-bg text-neutral-1-fg',
					!layout.disableSidebar && 'pl-14',
				)}
			>
				{layout.sidePanelContent && (
					<Panel header={layout.sidePanelHeader as ReactNode} defaultExpanded={layout.sidePanelDefaultExpanded}>
						{layout.sidePanelContent}
					</Panel>
				)}
				<div className="relative flex h-full w-full flex-col overflow-y-auto" id="app-layout">
					{/* #page-top-sticky-portal is used for top message display, ex: lra job */}
					<div id="page-top-sticky-portal" className="sticky left-0 top-0 z-10"></div>
					<div
						className={cn(
							'mx-auto w-full',
							'flex flex-1 flex-col',
							!layout.fullWidth && 'max-w-[1446px]',
							!layout.noPadding && 'px-[var(--layout-px)] py-4',
							!layout.enableHeader && !layout.noPadding && 'pt-14',
						)}
						id="main-content"
					>
						<Outlet />
					</div>
				</div>
			</div>
			<SupportInit />
		</PanelProvider>
	)
}

export default Layout
