import { Link, useLoaderData, useNavigate } from 'react-router'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { PageLayout } from '#src/components/ui/PageLayout'
import { Table } from '#src/components/ui/Table'
import { CustomTooltip } from '#src/components/ui/tooltip'
import LayoutContentHack from '#src/layouts/LayoutContentHack'
import { routes } from '#src/utils/routes'
import { type LoaderRes } from '../loaders/list'

export const View = () => {
	const {
		data: { experts },
	} = useLoaderData<LoaderRes>()
	const navigate = useNavigate()

	return (
		<PageLayout
			title="Plays experts"
			actions={[
				{
					icon: 'add',
					title: 'Add new',
					to: routes.admin.playsExperts.create,
				},
			]}
		>
			<LayoutContentHack>
				<Table
					data={experts}
					onItemClick={item => navigate(routes.admin.playsExperts.edit({ expertId: String(item.id) }))}
					columns={[
						{
							name: 'id',
							heading: 'ID',
							render: item => item.id,
						},
						{
							name: 'full_name',
							heading: 'Full name',
							render: item => item.fullName,
						},
						{
							name: 'linkedin',
							heading: 'LinkedIn',
							render: item => (
								<a
									onClick={e => e.stopPropagation()}
									href={item.linkedin}
									target="_blank"
									rel="noreferrer"
									className="text-link"
								>
									{item.linkedin}
								</a>
							),
						},
						{
							name: 'about',
							heading: 'About',
							render: item => (
								<CustomTooltip label={item.about}>
									<div className="w-full max-w-[650px] truncate">{item.about}</div>
								</CustomTooltip>
							),
						},
						{
							name: 'actions',
							heading: '',
							render: item => (
								<div
									className="flex flex-row justify-end"
									onClick={e => {
										e.preventDefault()
										e.stopPropagation()
									}}
								>
									<Link
										to={routes.admin.playsExperts.delete({ expertId: String(item.id) })}
										className="inline-flex h-full items-center gap-1 whitespace-nowrap"
									>
										<Button type="button" variant="icon" size="icon">
											<Icon name="trash" size="sm" className="text-status-danger-fg" />
										</Button>
									</Link>
								</div>
							),
						},
					]}
				/>
			</LayoutContentHack>
		</PageLayout>
	)
}
