import { useEffect } from 'react'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { PlayTemplateCreateModal } from '#src/modals/play-templates/PlayTemplateCreate'
import { routes } from '#src/utils/routes'

export const View = () => {
	const { openModal } = useModals()
	const { companyId } = useCompany()

	useEffect(() => {
		openModal({
			name: MODAL_NAME.PlayTemplateCreate,
			component: PlayTemplateCreateModal,
			settings: { routeToRedirectOnClose: routes.enable.playTemplates.index({ companyId }) },
		})
	}, [companyId, openModal])

	return null
}
