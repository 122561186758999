import { buildQueryParams } from '#src/utils/misc'

export const baseApi = import.meta.env.VITE_API_URL
export const adminApi = import.meta.env.VITE_ADMIN_API_URL
export const organizeApi = import.meta.env.VITE_ORGANIZE_API_URL

export const admin = {
	conversation: (companyId: string, conversationId: string) =>
		`${adminApi}/company/${companyId}/copilot/conversation/${conversationId}/restart`,
	roleplay: (companyId: string, conversationId: string) =>
		`${adminApi}/company/${companyId}/chat/roleplay/${conversationId}/restart`,
}

export const api = {
	user: {
		me: `${baseApi}/user/me`,
		token: (token: string) => `${baseApi}/reset/${token}`,
		password: `${baseApi}/reset/password`,
	},
	company: {
		list: `${baseApi}/company/list`,
		details: (companyId: string) => `${baseApi}/company/${companyId}`,
		stats: (companyId: string) => `${baseApi}/company/${companyId}/stats`,
		sort: (companyId: string) => `${baseApi}/company/${companyId}/ecosystem/sort`,
	},
	ecosystem: {
		create: (companyId: string) => `${baseApi}/company/${companyId}/ecosystem`,
		list: (
			companyId: string,
			queryParams?: {
				verticals?: boolean
				personas?: boolean
				exclude?: boolean
				includeCriteriaSandbox?: boolean
			},
		) => `${baseApi}/company/${companyId}/ecosystem/list${buildQueryParams(queryParams)}`,
		details: (companyId: string, ecosystemId: string) => `${baseApi}/company/${companyId}/ecosystem/${ecosystemId}`,
		criteria: (companyId: string, ecosystemId: string) =>
			`${baseApi}/company/${companyId}/ecosystem/${ecosystemId}/criteria`,
		verticals: (companyId: string, ecosystemId: string) =>
			`${baseApi}/company/${companyId}/ecosystem/${ecosystemId}/verticals`,
		templates: (companyId: string) => `${baseApi}/company/${companyId}/ecosystem/templates`,
	},
	criterion: {
		list: {
			all: (companyId: string, isSandbox?: boolean) =>
				`${baseApi}/company/${companyId}/criterion/list?onlySandbox=${isSandbox ?? false}`,
			external: (companyId: string) => `${baseApi}/company/${companyId}/criterion/external`,
			templates: (companyId: string) => `${baseApi}/company/${companyId}/criterion/templates`,
			assigned: (companyId: string, ecosystemId: string) =>
				`${baseApi}/company/${companyId}/ecosystem/${ecosystemId}/criteria`,
		},
		update: {
			allVerticalsAnswers: (companyId: string, ecosystemId: string) =>
				`${baseApi}/company/${companyId}/ecosystem/${ecosystemId}/qualifications`,
			details: (companyId: string, criterionId: string) => `${baseApi}/company/${companyId}/criterion/${criterionId}`,
			assignedAnswers: (companyId: string, criterionId: string) =>
				`${baseApi}/company/${companyId}/criterion/${criterionId}/answers`,
		},
		create: (companyId: string) => `${baseApi}/company/${companyId}/criterion`,
		assign: (companyId: string) => `${baseApi}/company/${companyId}/criterion/assign`,
		details: (companyId: string, criterionId: string) => `${baseApi}/company/${companyId}/criterion/${criterionId}`,
		unassign: (companyId: string) => `${baseApi}/company/${companyId}/criterion/remove`,
		delete: (companyId: string, criterionId: string) => `${baseApi}/company/${companyId}/criterion/${criterionId}`,
	},
	datapoint: {
		create: (companyId: string) => `${baseApi}/company/${companyId}/datapoint`,
		details: (companyId: string, dataPointId: string) => `${baseApi}/company/${companyId}/datapoint/${dataPointId}`,
	},
	vertical: {
		//TODO: Delete
		create: (companyId: string) => `${baseApi}/company/${companyId}/vertical`,
		//TODO: Delete
		details: (companyId: string, verticalId: number) => `${baseApi}/company/${companyId}/vertical/${verticalId}`,
		personas: (companyId: string, verticalId: string) =>
			`${baseApi}/company/${companyId}/vertical/${verticalId}/personas`,
		recalculate: (companyId: string, verticalId: string) =>
			`${baseApi}/company/${companyId}/vertical/${verticalId}/recalculate`,
	},
	salescoach: {
		create: (companyId: string, personaId: string) => `${baseApi}/company/${companyId}/salescoach/${personaId}`,
		list: (companyId: string, personaId: string) => `${baseApi}/company/${companyId}/salescoach/${personaId}`,
		details: (companyId: string, personaId: string, salescoachId: number) =>
			`${baseApi}/company/${companyId}/salescoach/${personaId}/${salescoachId}`,
		recalculate: (companyId: string, personaId: string, salescoachId: string) =>
			`${baseApi}/company/${companyId}/salescoach/${personaId}/recalculate/${salescoachId}`,
	},
	signal: {
		list: (companyId: string) => `${baseApi}/company/${companyId}/signal/all`,
		assigned: (companyId: string) => `${baseApi}/company/${companyId}/signal`,
		assign: (companyId: string, signalId: string) => `${baseApi}/company/${companyId}/signal/${signalId}/assign`,
		config: (companyId: string, signalId: string) => `${baseApi}/company/${companyId}/signal/${signalId}/config`,
		example: (companyId: string, signalId: string, personaId: string) =>
			`${baseApi}/company/${companyId}/signal/${signalId}/example/${personaId}`,
	},
	chat: {
		contact: (contactId: string) => `${baseApi}/company/copilot/contact/${contactId}`,
		status: (companyId: string, conversationId: string) =>
			`${baseApi}/company/${companyId}/copilot/conversation/${conversationId}/status`,
		messages: (companyId: string, conversationId: string) =>
			`${baseApi}/company/${companyId}/copilot/conversation/${conversationId}/messages`,
		new: (companyId: string) => `${baseApi}/company/${companyId}/copilot/conversation/linkedin`,
		context: (companyId: string, conversationId: string) =>
			`${baseApi}/company/${companyId}/copilot/conversation/${conversationId}`,
		delete: (companyId: string, conversationId: string) =>
			`${baseApi}/company/${companyId}/copilot/conversation/${conversationId}`,
		list: (companyId: string) => `${baseApi}/company/${companyId}/copilot/conversation/list`,
		validateLinkedinUrl: (companyId: string) => `${baseApi}/company/${companyId}/chat/linkedin/save`,
		stop: (companyId: string, conversationId: string) =>
			`${baseApi}/company/${companyId}/copilot/conversation/${conversationId}/messages/cancel`,
	},
	roleplay: {
		session: {
			new: (companyId: string) => `${baseApi}/company/${companyId}/chat/roleplay/session/new`,
			index: (companyId: string, conversationId: string) =>
				`${baseApi}/company/${companyId}/chat/roleplay/session/${conversationId}`,
			end: (companyId: string, conversationId: string) =>
				`${baseApi}/company/${companyId}/chat/roleplay/session/${conversationId}/end`,
			status: (companyId: string, conversationId: string) =>
				`${baseApi}/company/${companyId}/chat/roleplay/session/${conversationId}/status`,
			messages: (companyId: string, conversationId: string) =>
				`${baseApi}/company/${companyId}/chat/roleplay/session/${conversationId}/messages`,
			restart: (companyId: string, conversationId: string) =>
				`${baseApi}/company/${companyId}/chat/roleplay/session/${conversationId}/restart`,
		},
	},
	glossary: {
		list: (companyId: string) => `${baseApi}/company/${companyId}/glossary`,
		create: (companyId: string) => `${baseApi}/company/${companyId}/glossary`,
		details: (companyId: string, termId: string) => `${baseApi}/company/${companyId}/glossary/${termId}`,
	},
}
