import { SessionDataSchema } from '#src/routes/enable/roleplay-sessions/schema'
import { api } from '#src/utils/endpoints'
import { fetch, safeFetch } from '#src/utils/safeFetch'

export const endRoleplaySession = async (companyId: string, conversationId: string) => {
	return await fetch(api.roleplay.session.end(companyId, conversationId), {
		method: 'PUT',
	})
}

export const restartRoleplaySession = async (companyId: string, conversationId: string) => {
	return await safeFetch(SessionDataSchema, api.roleplay.session.restart(companyId, conversationId), {
		method: 'PUT',
	})
}

export const deleteRoleplaySession = async (companyId: string, conversationId: string) => {
	return await fetch(api.roleplay.session.index(companyId, conversationId), {
		method: 'DELETE',
	})
}
