import { z } from 'zod'
import { PersonalitySchema } from '#src/api/icp/company/chat/roleplay/session/schemas'
import { FilterAPISchema, PaginatedAPIResSchema } from '#src/schemas/global'
import { zNumber, zString } from '#src/utils/validation'

export enum Status {
	WaitingForContact = 'waiting_for_contact',
	Closed = 'closed',
	Ready = 'ready',
	Initializing = 'initializing',
	Crashed = 'crashed',
}

export const StatusSchema = z.enum([
	Status.WaitingForContact,
	Status.Closed,
	Status.Ready,
	Status.Initializing,
	Status.Crashed,
])

export const Participant = z.object({
	id: z.string(),
	status: StatusSchema,
	username: z.string(),
	createdAt: z.string(),
	completedAt: z.string().nullable(),
	attempts: z.number(),
})

export const ScenarioTemplateEvaluationTemplateAPISchema = z.object({
	id: z.number(),
	name: z.string(),
	description: z.string(),
})

export const ScenarioAPISchema = z.object({
	id: z.string(),
	name: z.string(),
	title: z.string(), // todo to remove waiting for templateMapping
	cycleFrequencyUnit: z.enum(['week']).nullable(),
	cycleFrequencyValue: z.number().nullable(),
	createdBy: z.string(),
	createdAt: z.string(),
	scenario: z.string().optional().nullable(),
	personaId: z.number(),
	templateId: z.number().nullable(),
	personaMapping: z
		.object({
			ecosystemId: z.number(),
			verticalId: z.number(),
			ecosystem: z.string(),
			vertical: z.string(),
			personaType: z.string(),
			expertise: z.string(),
		})
		.nullable(),
	linkedinUrl: z.string().optional().nullable(),
	contact: z.string().optional().nullable(),
	personality: PersonalitySchema.merge(
		z.object({
			voiceMapping: z.object({
				id: z.string(),
				name: z.string(),
			}),
		}),
	),
	evaluation: z.string().optional().nullable(),
	instructions: z.string().nullable(),
	canEdit: z.boolean(),
	isValid: z.boolean(),
	tags: z.array(z.string()),
	evaluationTemplate: ScenarioTemplateEvaluationTemplateAPISchema.nullable(),
})

export const ScenarioListAPISchema = z.object({
	id: z.string(),
	name: z.string(),
	title: z.string(), // todo to remove waiting for templateMapping
	cycleFrequencyUnit: z.enum(['week']).nullable(),
	cycleFrequencyValue: z.number().nullable(),
	createdBy: z.string(),
	createdAt: z.string(),
	participantsCount: z.number(),
	tags: ScenarioAPISchema.shape.tags,
	personaMapping: z
		.object({
			vertical: z.string(),
			ecosystem: z.string(),
			expertise: z.string(),
			personaType: z.string(),
		})
		.nullable(),
})

export const ScenariosAPISchema = PaginatedAPIResSchema(ScenarioListAPISchema)

export const ScenarioTemplateAPISchema = z.object({
	id: z.number(),
	name: z.string(),
	scenario: z.string().optional().nullable(),
	evaluationTemplates: z.array(ScenarioTemplateEvaluationTemplateAPISchema),
})
export const ScenarioTemplatesAPISchema = ScenarioTemplateAPISchema.array()

export const VoiceAPISchema = z.object({
	voiceId: z.string(),
	name: z.string(),
})

export const VoicesAPISchema = VoiceAPISchema.array()

export const CreateScenarioTestSessionSchema = z.object({
	conversationId: z.string(),
	company: z.string(),
})
export const ScenarioParticipantAPISchema = z.object({
	id: z.number(),
	username: z.string(),
	createdAt: z.string(),
})

export const ScenarioInviteFormSchema = z.object({
	members: z.array(zNumber(z.number())).nonempty('You need to select at least one participant'),
})

export const ScenarioFormDetailsSchema = z.object({
	name: zString(),
	templateId: zNumber(z.number()),
	scenario: z.string().nullable(),
})
export const ScenarioFormPersonaMappingSchema = z.object({
	// they are ignored on backend
	ecosystemId: zNumber(z.number()),
	// they are ignored on backend
	verticalId: zNumber(z.number()),
	personaId: zNumber(z.number()),
	linkedinUrl: z.string().url().or(z.string().max(0)).nullable(), // url or empty string
	contactId: z.string().optional(),
})
export const ScenarioFormPersonalitySchema = z.object({
	personality: PersonalitySchema,
})
export const ScenarioFormEvaluationSchema = z.object({
	evaluationTemplateId: zNumber(z.number()).nullable(),
	evaluation: z.string().nullable(),
})
export const ScenarioFormInstructionsFrequencySchema = z
	.discriminatedUnion('cycleFrequencyEnable', [
		z.object({
			cycleFrequencyEnable: z.literal(true),
			cycleFrequencyUnit: z.enum(['week']),
			cycleFrequencyValue: zNumber(z.number().min(1).max(10)),
		}),
		z.object({
			cycleFrequencyEnable: z.literal(false),
		}),
	])
	.and(
		z.object({
			instructions: z.string().nullable(),
		}),
	)
export const ScenarioFormParticipantsSchema = z.object({
	assignUserIds: z.array(zNumber(z.number())).default([]),
})

export const ScenarioCreateFormSchema = ScenarioFormInstructionsFrequencySchema.and(
	z
		.object({})
		.merge(ScenarioFormDetailsSchema)
		.merge(ScenarioFormPersonaMappingSchema)
		.merge(ScenarioFormPersonalitySchema)
		.merge(ScenarioFormEvaluationSchema)
		.merge(ScenarioFormParticipantsSchema),
)

export const ScenarioEditFormSchema = ScenarioFormInstructionsFrequencySchema.and(
	z
		.object({})
		.merge(ScenarioFormDetailsSchema)
		.merge(ScenarioFormPersonaMappingSchema)
		.merge(ScenarioFormPersonalitySchema)
		.merge(ScenarioFormEvaluationSchema),
)

export const ScenarioUsersAPISchema = z
	.object({
		id: z.number(),
		username: z.string(),
	})
	.array()

export const ScenarioSessionAPISchema = z.object({
	id: z.string(),
	user: z.string(),
	attempts: z.number(),
	createdAt: z.string(),
	dueAt: z.string().nullable(),
})

export const ScenarioSessionsFiltersAPISchema = z.object({
	user: z.array(FilterAPISchema),
	hasAttempts: z.array(FilterAPISchema),
})

export const ScenarioSaveTagsFormSchema = z.object({
	tags: z.array(z.string()).default([]),
})

export const ScenarioFiltersAPISchema = z.object({
	tags: z.array(FilterAPISchema),
	createdBy: z.array(FilterAPISchema),
	personaType: z.array(FilterAPISchema),
	agenda: z.array(FilterAPISchema),
	dateFrom: z.array(FilterAPISchema),
	dateTo: z.array(FilterAPISchema),
})
