import { type RouteObject } from 'react-router'
import { action as createAction } from './actions/create'
import { action as downloadAction } from './actions/download'
import { action as stopAction } from './actions/stop'
import { loader as downloadLoader } from './loaders/download'
import { loader as itemLoader, type LoaderRes as ItemLoaderRes } from './loaders/item'
import { loader as listLoader } from './loaders/list'
import { loader as stopLoader } from './loaders/stop'
import { View as CreateView } from './views/create'
import { View as ItemView } from './views/item'
import { View as ListView } from './views/list'

const contactWaterfallingRouter = {
	path: 'contact-waterfalling',
	handle: {
		crumb: () => [
			{
				label: 'Contact Waterfalling',
				icon: 'collaborate',
			},
		],
	},
	children: [
		{
			path: '',
			loader: listLoader,
			element: <ListView />,
			handle: {
				layout: {
					enableHeader: true,
					fullWidth: true,
				},
			},
			children: [
				{
					path: 'new',
					element: <CreateView />,
					action: createAction,
				},
			],
		},
		{
			path: ':jobId',
			element: <ItemView />,
			loader: itemLoader,
			handle: {
				layout: {
					enableHeader: true,
				},
				crumb: (data: ItemLoaderRes) => [
					{
						label: data.data.job.title,
					},
				],
			},
			children: [
				{
					path: 'stop',
					loader: stopLoader,
					action: stopAction,
				},
				{
					path: 'download',
					loader: downloadLoader,
					action: downloadAction,
				},
			],
		},
	],
} satisfies RouteObject

export default contactWaterfallingRouter
