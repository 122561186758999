import { type ActionFunctionArgs } from 'react-router'
import { companyChatRoleplayScenarioMutations } from '#src/api/icp/company/chat/roleplay/scenario/mutations'
import {
	ScenarioCreateFormSchema,
	ScenarioFormDetailsSchema,
	ScenarioFormParticipantsSchema,
	ScenarioFormPersonalitySchema,
	ScenarioFormPersonaMappingSchema,
} from '#src/api/icp/company/chat/roleplay/scenario/schemas'
import { MODAL_NAME } from '#src/constants/modals'
import { validateLinkedinUrl } from '#src/routes/enable/chat/mutations'
import { parseFormDataNew } from '#src/utils/forms'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'
import { closeModalServer } from '#src/utils/server/modals'
import { FORM_STEPS } from '../constants'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ request, params }: ActionFunctionArgs) => {
	const { company, companyId } = await getCompany(params)

	const formData = await request.formData()

	try {
		const submission = parseFormDataNew(formData, ScenarioCreateFormSchema)

		if (submission.value.linkedinUrl) {
			const { uuid: contactId } = await validateLinkedinUrl(company.id, {
				personaId: submission.value.personaId,
				linkedinUrl: submission.value.linkedinUrl,
			})
			submission.value.contactId = contactId
		}

		const res = await companyChatRoleplayScenarioMutations.create(company.id, submission.value)

		closeModalServer(
			MODAL_NAME.RoleplayScenarioCreate,
			routes.enable.roleplay.scenario.item({
				companyId,
				scenarioId: res.id,
			}),
		)

		return { ok: true }
	} catch (e) {
		return await serverFormErrorHandler(e, {
			steps: [
				{
					step: FORM_STEPS.Details,
					schema: ScenarioFormDetailsSchema,
				},
				{
					step: FORM_STEPS.PersonaMapping,
					schema: ScenarioFormPersonaMappingSchema,
				},
				{
					step: FORM_STEPS.Personality,
					schema: ScenarioFormPersonalitySchema,
				},
				{
					step: FORM_STEPS.Participants,
					schema: ScenarioFormParticipantsSchema,
				},
			],
		})
	}
}
