import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useLoaderData } from 'react-router'
import { type unknown, type z } from 'zod'
import { CancelEnrichCriterionAnswersFormSchema } from '#src/api/icp/company/criteria/schema'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { InlineSpinner } from '#src/components/ui/InlineSpinner'
import useCompany from '#src/hooks/useCompany'
import { type CriterionAPISchema } from '#src/routes/calibrate/ecosystem-management/criteria/schema'
import { type LoaderResType } from '#src/routes/calibrate/ecosystem-management/verticals/loaders'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

type CreateEnrichmentFormProps = {
	criterion: z.infer<typeof CriterionAPISchema>
}

export const CancelEnrichmentForm = ({ criterion }: CreateEnrichmentFormProps) => {
	const {
		handle: { ecosystemId },
	} = useLoaderData<LoaderResType>()
	const { companyId } = useCompany()

	const form = useForm<typeof unknown>({
		resolver: zodResolver(CancelEnrichCriterionAnswersFormSchema),
		defaultValues: {
			ecosystemId: Number(ecosystemId),
			criterionId: criterion.criterion.id,
		},
	})

	const action = routes.calibrate.verticals.criteriaSandbox.cancelEnrichment({
		companyId,
		ecosystemId,
		criterionId: String(criterion.criterion.id),
	})
	const method = 'POST'

	const isPending = useIsPending({
		formMethod: method,
		formAction: action,
	})
	const formId = `cancelEnrichment-${criterion.criterion.id}`

	return (
		<FormWrapper formProps={form} action={action} formId={formId} method={method}>
			<Button
				variant="icon"
				size="icon"
				disabled={isPending}
				className="absolute right-[64px] top-1/2 -translate-y-1/2"
			>
				{isPending ? (
					<InlineSpinner size="sm" textVariant="none" />
				) : (
					<Icon name="stop-filled-alt" size="sm" className="text-brand-1-fg" />
				)}
			</Button>
		</FormWrapper>
	)
}
