import { Link } from 'react-router'
import { DashboardCard } from '#src/components/dashboard-card'
import { Icon } from '#src/components/ui/icon.js'
import { PageLayout } from '#src/components/ui/PageLayout'
import useCompany from '#src/hooks/useCompany'
import { routes } from '#src/utils/routes.js'

export const View = () => {
	const { companyId } = useCompany()

	return (
		<PageLayout title="Settings">
			<div className="grid w-full grid-cols-2 grid-rows-1 gap-6">
				<Link
					to={routes.settings.team.index({
						companyId,
					})}
					className="block w-full"
				>
					<DashboardCard
						icon="user-multiple"
						title={
							<div className="flex w-full items-center justify-between">
								Team members <Icon name="chevron-right" size="font" />
							</div>
						}
						isHoverable
					/>
				</Link>
				<Link
					to={routes.settings.copilot.index({
						companyId,
					})}
					className="block w-full"
				>
					<DashboardCard
						icon="chat"
						title={
							<div className="flex w-full items-center justify-between">
								Copilot settings <Icon name="chevron-right" size="font" />
							</div>
						}
						isHoverable
					/>
				</Link>
			</div>
		</PageLayout>
	)
}
