import { createSearchParams, useRouteLoaderData, useSearchParams } from 'react-router'
import ListItems from '#src/components/ListItems'
import { type PlaysListLoaderRes } from '#src/routes/enable/plays/root/routes/list'

const PlaysPanelContent = () => {
	const { filters } = useRouteLoaderData<PlaysListLoaderRes>('plays-module') as PlaysListLoaderRes
	const [, setSearchParams] = useSearchParams()

	return (
		<div className="flex w-full flex-col px-2">
			<div className="p-2 text-label-sm text-neutral-3-fg">Plays by status</div>
			<ListItems
				onSelect={value => {
					const payload = Object.fromEntries(
						Object.entries({
							...filters.selected,
							status: value,
						}).filter(([, val]) => val.length > 0),
					)
					setSearchParams(`?${createSearchParams(payload as Record<string, string[]>).toString()}`)
				}}
				selected={filters.selected.status as string}
				items={filters.values.status}
			/>
		</div>
	)
}

export default PlaysPanelContent
