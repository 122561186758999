import { type ActionFunctionArgs } from 'react-router'
import { playsMutations } from '#src/api/icp/company/plays/mutations'
import { PlaysFormSchema } from '#src/api/icp/company/plays/schemas'
import { MODAL_NAME } from '#src/constants/modals'
import { showToast } from '#src/context/ToastContext'
import { parseFormDataNew } from '#src/utils/forms'
import { getCompany } from '#src/utils/server/company'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'
import { closeModalServer } from '#src/utils/server/modals'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const { company } = await getCompany(params)

	const formData = await request.formData()

	try {
		const submission = parseFormDataNew(formData, PlaysFormSchema)
		await playsMutations.create(company.id, submission.value)
		showToast({
			message: 'Play created',
			type: 'success',
		})
		closeModalServer(MODAL_NAME.PlayCreate)
		closeModalServer(MODAL_NAME.PlayTemplates)
		return { ok: true }
	} catch (e) {
		return await serverFormErrorHandler(e)
	}
}
