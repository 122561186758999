import { type ReactNode } from 'react'
import Markdown from '#src/components/markdown'
import { cn } from '#src/utils/misc'
import { ChatAvatar } from './ChatAvatar'

export const ChatLoader = ({
	animate,
	icon,
	message,
	messageClassName,
	subMessage,
	className,
}: {
	animate: boolean
	icon?: ReactNode
	message: string
	messageClassName?: string
	subMessage?: string
	className?: string
}) => {
	return (
		<section className={cn('flex h-full flex-1 flex-col items-center justify-center gap-6', className)}>
			<ChatAvatar animate={animate} icon={icon} />
			<div className="relative z-0 flex max-w-screen-lg flex-col items-center justify-center gap-2 px-6">
				<Markdown className={cn('text-center text-title-lg text-neutral-1-fg', messageClassName)}>{message}</Markdown>
				{subMessage ? <p className="z-0 text-label-sm text-neutral-3-fg">{subMessage}</p> : null}
			</div>
		</section>
	)
}
