import { useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useFetcher } from 'react-router'
import { type z } from 'zod'
import { type PlaysTemplatesFormSchema } from '#src/api/icp/company/plays-templates/schemas'
import { FormField } from '#src/components/forms/v2/FormField'
import useAuth from '#src/hooks/useAuth'
import useCompany from '#src/hooks/useCompany'
import { routes } from '#src/utils/routes'
import { type LoaderRes } from '../../loaders/create'

const PlayTemplateFormFields = () => {
	const fetcher = useFetcher<LoaderRes>()
	const { companyId } = useCompany()

	const { adminSession } = useAuth()

	useEffect(() => {
		if (fetcher.state === 'idle' && !fetcher.data) {
			void fetcher.load(routes.enable.playTemplates.create({ companyId }))
		}
	}, [fetcher, companyId])

	const personaTypesOptions = useMemo(() => {
		if (!fetcher.data) {
			return []
		}
		return fetcher.data.data.personaTypes.map(item => ({ value: item, label: item }))
	}, [fetcher.data])

	const tagsOptions = useMemo(() => {
		if (!fetcher.data) {
			return []
		}
		return fetcher.data.data.tags.map(item => ({ value: item, label: item }))
	}, [fetcher.data])

	const expertsOptions = useMemo(() => {
		if (!fetcher.data) {
			return []
		}
		return fetcher.data.data.experts.map(item => ({ value: item.id, label: item.fullName }))
	}, [fetcher.data])

	const form = useFormContext<z.infer<typeof PlaysTemplatesFormSchema>>()

	const isGlobalWatcher = form.watch('isGlobal')
	useEffect(() => {
		if (!isGlobalWatcher) {
			form.setValue('templateExpertId', null)
		}
	}, [isGlobalWatcher, form])

	return (
		<>
			<FormField fieldType="text" name="name" label="Template name" />
			<FormField fieldType="multiselect" name="tags" label="Tags" options={tagsOptions} enableCreate />
			<div className="flex flex-col gap-2">
				<FormField
					fieldType="switch"
					name="inCrm"
					disableLabel
					labelText="Use this template in Lead Research Agent jobs"
				/>
				<FormField fieldType="switch" name="inCopilot" disableLabel labelText="Use this template in Copilot" />
			</div>
			<FormField fieldType="textarea" name="instructions" label="Template instructions" rows={14} />
			<FormField fieldType="multiselect" name="personaTypes" label="Persona types" options={personaTypesOptions} />
			{adminSession && (
				<FormField
					fieldType="cardSwitch"
					name="isGlobal"
					disableLabel
					heading="Global template"
					description="You can make it as a global or expert template"
					innerContent={
						<FormField fieldType="select" name="templateExpertId" options={expertsOptions} label="Expert (optional)" />
					}
				/>
			)}
		</>
	)
}

export default PlayTemplateFormFields
