import { type ActionFunctionArgs, redirect } from 'react-router'
import { companyChatRoleplaySessionMutations } from '#src/api/icp/company/chat/roleplay/session/mutations'
import {
	SessionCreateFormSchema,
	SessionFormDetailsSchema,
	SessionFormEvaluationSchema,
	SessionFormPersonalitySchema,
	SessionFormPersonaMappingSchema,
} from '#src/api/icp/company/chat/roleplay/session/schemas'
import { showToast } from '#src/context/ToastContext'
import { validateLinkedinUrl } from '#src/routes/enable/chat/mutations'
import { FORM_STEPS } from '#src/routes/enable/roleplay-sessions/constants'
import { getEarliestFailedStepFromResErrors } from '#src/utils/actions'
import { parseFormDataNew } from '#src/utils/forms'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ request, params }: ActionFunctionArgs) => {
	const { company, companyId } = await getCompany(params)

	const formData = await request.formData()

	try {
		const submission = parseFormDataNew(formData, SessionCreateFormSchema)

		if (submission.value.linkedinUrl) {
			const { uuid: contactId } = await validateLinkedinUrl(company.id, {
				personaId: submission.value.personaId,
				linkedinUrl: submission.value.linkedinUrl,
			})
			submission.value.contactId = contactId
		}

		const res = await companyChatRoleplaySessionMutations.create(company.id, submission.value)

		return redirect(
			routes.enable.roleplay.session.attempts({
				companyId,
				conversationId: res.conversationId,
			}),
		)
	} catch (e) {
		try {
			const res = (e as Response).clone()
			const body = (await res.json()) as {
				success: boolean
				message: string
				errors?: Record<string, string[]>
			}
			showToast({
				message: body.message,
				type: 'error',
			})

			const failedStep = getEarliestFailedStepFromResErrors(body.errors, [
				{
					step: FORM_STEPS.Details,
					schema: SessionFormDetailsSchema,
				},
				{
					step: FORM_STEPS.PersonaMapping,
					schema: SessionFormPersonaMappingSchema,
				},
				{
					step: FORM_STEPS.Personality,
					schema: SessionFormPersonalitySchema,
				},
				{
					step: FORM_STEPS.Evaluation,
					schema: SessionFormEvaluationSchema,
				},
			])

			return {
				ok: false,
				errors: body.errors,
				failedStep,
			}
		} catch (err) {
			showToast({
				message: 'Unexpected error while creating Roleplay session',
				type: 'error',
			})
			return {
				ok: false,
			}
		}
	}
}
