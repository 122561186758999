import { type z } from 'zod'
import { companyChatRoleplayScenarioQueriesKeys } from '#src/api/icp/company/chat/roleplay/scenario/queries'
import {
	CreateScenarioTestSessionSchema,
	ScenarioAPISchema,
	type ScenarioCreateFormSchema,
	type ScenarioEditFormSchema,
	type ScenarioInviteFormSchema,
	type ScenarioParticipantAPISchema,
	type ScenarioSaveTagsFormSchema,
} from '#src/api/icp/company/chat/roleplay/scenario/schemas'
import { companyChatRoleplaySessionQueriesKeys } from '#src/api/icp/company/chat/roleplay/session/queries'
import { client } from '#src/main'
import { type CompanySchema } from '#src/routes/company/schema'
import { fetch, safeFetch } from '#src/utils/safeFetch'
import { companyChatRoleplayScenarioEndpoints } from './endpoints'

const mutations = {
	testSession: async (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>,
	) => {
		const res = await safeFetch(
			CreateScenarioTestSessionSchema,
			companyChatRoleplayScenarioEndpoints.createTestSession(companyId, scenarioId),
			{
				method: 'POST',
			},
		)

		await client.invalidateQueries({
			queryKey: companyChatRoleplayScenarioQueriesKeys.item(companyId, scenarioId),
		})

		await client.invalidateQueries({
			queryKey: companyChatRoleplaySessionQueriesKeys.all,
		})

		return res
	},
	assignUsers: async (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>,
		userIds: z.infer<typeof ScenarioInviteFormSchema.shape.members>,
	) => {
		const res = await Promise.allSettled(
			userIds.map(
				async userId =>
					await fetch(companyChatRoleplayScenarioEndpoints.assign(companyId, scenarioId, userId), {
						method: 'PUT',
					}),
			),
		)

		await client.invalidateQueries({
			queryKey: companyChatRoleplayScenarioQueriesKeys.item(companyId, scenarioId),
		})
		await client.invalidateQueries({
			queryKey: companyChatRoleplayScenarioQueriesKeys.itemAvailableUsers(companyId, scenarioId),
		})
		await client.invalidateQueries({
			queryKey: companyChatRoleplaySessionQueriesKeys.all,
		})

		return {
			succeeded: res.filter(promise => promise.status === 'fulfilled').length,
			failed: res.filter(promise => promise.status === 'rejected').length,
		}
	},
	update: async (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>,
		data: z.infer<typeof ScenarioEditFormSchema>,
	) => {
		const res = await safeFetch(ScenarioAPISchema, companyChatRoleplayScenarioEndpoints.update(companyId, scenarioId), {
			method: 'PATCH',
			body: JSON.stringify(data),
		})

		await client.invalidateQueries({
			queryKey: companyChatRoleplayScenarioQueriesKeys.all,
		})

		return res
	},
	createTags: async (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>,
		data: z.infer<typeof ScenarioSaveTagsFormSchema>,
	) => {
		const res = await safeFetch(
			ScenarioAPISchema,
			companyChatRoleplayScenarioEndpoints.createTags(companyId, scenarioId),
			{
				method: 'PATCH',
				body: JSON.stringify(data),
			},
		)

		await client.invalidateQueries({
			queryKey: companyChatRoleplayScenarioQueriesKeys.all,
		})

		return res
	},
	create: async (companyId: z.infer<typeof CompanySchema.shape.id>, data: z.infer<typeof ScenarioCreateFormSchema>) => {
		const res = await safeFetch(ScenarioAPISchema, companyChatRoleplayScenarioEndpoints.create(companyId), {
			method: 'POST',
			body: JSON.stringify(data),
		})

		await client.invalidateQueries({
			queryKey: companyChatRoleplayScenarioQueriesKeys.all,
		})

		return res
	},
	delete: async (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>,
	) => {
		await fetch(companyChatRoleplayScenarioEndpoints.delete(companyId, scenarioId), {
			method: 'DELETE',
			headers: {
				Accept: 'application/json',
			},
		})
		await client.invalidateQueries({
			queryKey: companyChatRoleplayScenarioQueriesKeys.all,
		})
	},
	unassign: async (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		scenarioId: z.infer<typeof ScenarioAPISchema.shape.id>,
		participantId: z.infer<typeof ScenarioParticipantAPISchema.shape.id>,
	) => {
		await fetch(companyChatRoleplayScenarioEndpoints.unassign(companyId, scenarioId, participantId), {
			method: 'DELETE',
			headers: {
				Accept: 'application/json',
			},
		})
		await client.invalidateQueries({
			queryKey: companyChatRoleplayScenarioQueriesKeys.all,
		})
	},
}

export { mutations as companyChatRoleplayScenarioMutations }
