import { parseWithZod } from '@conform-to/zod'
import { type ActionFunctionArgs } from 'react-router'
import { personaMutations } from '#src/api/icp/company/persona/mutations'
import { DeletePersonasFormSchema } from '#src/api/icp/company/persona/schemas'
import { verticalsQueriesKeys } from '#src/api/icp/company/verticals/queries'
import { showToast } from '#src/context/ToastContext'
import { client } from '#src/main'
import { ecosystemKeys } from '#src/routes/calibrate/ecosystem-management/ecosystem/queries'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export const action = async ({ params, request }: ActionFunctionArgs) => {
	validateRouteParams(params, ['ecosystemId'])
	const { company } = await getCompany(params)
	const formData = await request.formData()

	const submission = parseWithZod(formData, {
		schema: DeletePersonasFormSchema,
	})

	if (submission.status !== 'success') {
		throw Error('Failed to parse form data')
	}

	await personaMutations.deletePersonas(company.id, {
		personas: submission.value.personas as string[],
	})

	await client.invalidateQueries({
		queryKey: verticalsQueriesKeys.all,
	})

	void client.invalidateQueries({
		queryKey: ecosystemKeys.list(company.id),
	})

	showToast({
		message: 'Personas successfully deleted',
		type: 'success',
	})

	return { success: true, message: 'Personas successfully deleted' }
}
