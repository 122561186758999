import { Link, useLoaderData, useNavigate } from 'react-router'
import { EmptyStateCard } from '#src/components'
import ContactAvatar from '#src/components/chat/avatar'
import { Chip } from '#src/components/chip'
import FiltersSection from '#src/components/FiltersSection'
import Markdown from '#src/components/markdown'
import { Button } from '#src/components/ui/button'
import { Dropdown, DropdownItem } from '#src/components/ui/dropdown'
import { Icon } from '#src/components/ui/icon'
import { LabeledValue } from '#src/components/ui/LabeledValue'
import { PageLayout } from '#src/components/ui/PageLayout'
import useCompany from '#src/hooks/useCompany'
import LayoutContentHack from '#src/layouts/LayoutContentHack'
import { type PlaysListLoaderRes } from '#src/routes/enable/plays/root/routes/list'
import { Table } from '#src/routes/prioritize/views/components/Table'
import { cn, formatAvatarAbbr } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

const AddNewButton = () => {
	const { companyId } = useCompany()

	return (
		<Dropdown
			trigger={
				<Button key="add-new" variant="default">
					<Icon name="add" />
					<span className="mx-3">Add new</span>
					<Icon name="carret-down" />
				</Button>
			}
			contentProps={{
				side: 'bottom',
				align: 'center',
			}}
		>
			<DropdownItem asChild className="justify-start">
				<Link to={routes.enable.plays.create({ companyId })}>Start from scratch</Link>
			</DropdownItem>
			<DropdownItem asChild className="justify-start">
				<Link to={routes.enable.playTemplates.index({ companyId })}>Use template</Link>
			</DropdownItem>
		</Dropdown>
	)
}

export const View = () => {
	const {
		data: { plays, ecosystems },
		filters,
	} = useLoaderData<PlaysListLoaderRes>()
	const { companyId } = useCompany()
	const navigate = useNavigate()

	return (
		<PageLayout title="Plays" actions={[<AddNewButton key="plays-create-btn" />]}>
			<LayoutContentHack>
				<Table
					stickyHeader
					noDataComponent={
						<EmptyStateCard
							icon="chat"
							title="You don't have any plays"
							actions={[<AddNewButton key="plays-create-btn" />]}
						/>
					}
					filtersComponent={
						<FiltersSection
							total={plays.length}
							selected={filters.selected}
							values={filters.values}
							filters={[
								{
									type: 'search',
									name: 'search',
									label: 'Search',
								},
								{
									name: 'personaTypes',
									label: 'Persona type',
								},
								{
									name: 'expertise',
									label: 'Expertise',
								},
								{
									name: 'tags',
									label: 'Tags',
								},
								{
									name: 'inCrm',
									label: 'Used in LRA',
									multiple: false,
								},
								{
									name: 'inCopilot',
									label: 'Used in Copilot',
									multiple: false,
								},
								{
									name: 'createdBy',
									label: 'Created by',
								},
							]}
						/>
					}
					data={plays}
					expandable
					onItemClick={item =>
						void navigate(
							routes.enable.plays.edit({
								companyId,
								playId: String(item.id),
							}),
						)
					}
					columns={[
						{
							name: 'name',
							heading: {
								title: 'Name',
							},
							body: {
								render: item => item.name,
							},
						},
						{
							name: 'personaType',
							heading: {
								title: 'Persona Type',
							},
							body: {
								render: item => (
									<>
										{item.personaTypes.length ? (
											<div className="flex flex-row flex-wrap gap-1">
												{item.personaTypes.map(item => (
													<Chip variant="blue" key={`personaType-${item}`}>
														{item}
													</Chip>
												))}
											</div>
										) : (
											<span className="text-body-sm text-neutral-3-fg">-</span>
										)}
									</>
								),
							},
						},
						{
							name: 'expertise',
							heading: {
								title: 'Expertise',
							},
							body: {
								render: item => (
									<>
										{item.expertise.length ? (
											<div className="flex flex-row flex-wrap gap-1">
												{item.expertise.map(item => (
													<Chip variant="indigo" key={`expertise-${item}`}>
														{item}
													</Chip>
												))}
											</div>
										) : (
											<span className="text-body-sm text-neutral-3-fg">-</span>
										)}
									</>
								),
							},
						},
						{
							name: 'tags',
							heading: {
								title: 'Tags',
							},
							body: {
								render: item => (
									<>
										{item.tags.length ? (
											<div className="flex flex-row flex-wrap gap-1">
												{item.tags.map(item => (
													<Chip variant="gray" key={`tag-${item}`}>
														{item}
													</Chip>
												))}
											</div>
										) : (
											<span className="text-body-sm text-neutral-3-fg">-</span>
										)}
									</>
								),
							},
						},
						{
							name: 'used_in',
							heading: {
								title: 'Used in',
								className: 'w-[150px]',
							},
							body: {
								render: item => (
									<div className="flex flex-col">
										<div
											className={cn(
												'inline-flex items-center gap-1 text-body-md',
												item.inCrm ? 'text-neutral-1-fg' : 'text-neutral-3-fg',
											)}
										>
											<Icon
												name={item.inCrm ? 'checkmark-filled' : 'checkmark-outline'}
												size="sm"
												className={cn(item.inCrm && 'text-green-70')}
											/>
											LRA
										</div>
										<div
											className={cn(
												'inline-flex items-center gap-1 text-body-md',
												item.inCopilot ? 'text-neutral-1-fg' : 'text-neutral-3-fg',
											)}
										>
											<Icon
												name={item.inCopilot ? 'checkmark-filled' : 'checkmark-outline'}
												size="sm"
												className={cn(item.inCopilot && 'text-green-70')}
											/>
											Copilot
										</div>
									</div>
								),
							},
						},
						{
							name: 'created_by',
							heading: {
								title: 'Created by',
							},
							body: {
								render: item => (
									<div className="flex items-center gap-2 text-body-sm font-medium text-neutral-2-fg">
										<ContactAvatar
											className="inline-flex shrink-0 items-center justify-center rounded-full"
											variant="gray"
											size="sm"
											initial={formatAvatarAbbr(item.createdBy)}
										/>
										{item.createdBy}
									</div>
								),
							},
						},
						{
							name: 'actions',
							heading: {
								title: '',
								className: 'w-20',
							},
							body: {
								render: item => (
									<div
										className="flex flex-row justify-end gap-4"
										onClick={e => {
											e.preventDefault()
											e.stopPropagation()
										}}
									>
										<Link
											to={routes.enable.plays.delete({ companyId, playId: String(item.id) })}
											className="inline-flex h-full items-center gap-1 whitespace-nowrap"
										>
											<Button type="button" variant="icon" size="icon">
												<Icon name="trash" size="sm" className="text-status-danger-fg" />
											</Button>
										</Link>
									</div>
								),
							},
						},
					]}
					expandableColumns={[
						{
							name: 'empty-1',
							body: {
								render: () => null,
							},
						},
						{
							name: 'more_details',
							body: {
								colSpan: 4,
								render: item => {
									const selectedVerticalsObject = ecosystems
										.flatMap(ecosystem =>
											ecosystem.verticals.map(vertical => ({
												vertical: {
													id: vertical.id,
													name: vertical.name,
												},
												ecosystem: { id: ecosystem.id, name: ecosystem.name },
											})),
										)
										.filter(mapped => item.verticals.includes(mapped.vertical.id))
									const ecosystemsMapped: {
										ecosystem: { id: number; name: string }
										verticals: { id: number; name: string }[]
									}[] = []
									selectedVerticalsObject.forEach(selected => {
										const existingEcosystem = ecosystemsMapped.find(
											mappedItem => mappedItem.ecosystem.id === selected.ecosystem.id,
										)
										if (existingEcosystem) {
											existingEcosystem.verticals.push(selected.vertical)
										} else {
											ecosystemsMapped.push({
												ecosystem: {
													id: selected.ecosystem.id,
													name: selected.ecosystem.name,
												},
												verticals: [
													{
														id: selected.vertical.id,
														name: selected.vertical.name,
													},
												],
											})
										}
									})
									return (
										<div className="flex flex-col gap-6">
											<div className="flex flex-col gap-4">
												<h6 className="text-title-sm">Instructions</h6>
												<Markdown className="w-full max-w-[650px]">{item.instructions}</Markdown>
											</div>
											{!!ecosystemsMapped.length && (
												<div className="flex flex-col gap-4">
													<h6 className="text-title-sm">Ecosystems and verticals</h6>
													{ecosystemsMapped.map(mapped => (
														<LabeledValue label={mapped.ecosystem.name} key={`ecosystem-${mapped.ecosystem.id}`}>
															<div className="flex flex-row flex-wrap gap-1">
																{mapped.verticals.map(vertical => (
																	<Chip variant="blue" key={`ecosystem-${mapped.ecosystem.id}-vertical-${vertical.id}`}>
																		{vertical.name}
																	</Chip>
																))}
															</div>
														</LabeledValue>
													))}
												</div>
											)}
										</div>
									)
								},
							},
						},
						{
							name: 'empty-2',
							body: {
								colSpan: 3,
								render: () => null,
							},
						},
					]}
				/>
			</LayoutContentHack>
		</PageLayout>
	)
}
