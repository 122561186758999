import {
	CommunicationStyle,
	DecisionMakingProcess,
	EmotionalState,
	PersonalityTrait,
} from '#src/api/icp/company/chat/roleplay/session/schemas'

enum TraitsDetail {
	Traits = 'Traits',
	Scenario = 'Scenario',
	ResponseStrategy = 'Response Strategy',
}

export const PERSONALITY_TRAITS_DETAILS: Record<PersonalityTrait, Record<TraitsDetail, string>> = {
	[PersonalityTrait.Analytical]: {
		[TraitsDetail.Traits]: 'Detail-oriented, data-driven, methodical',
		[TraitsDetail.Scenario]: 'the prospect asks for detailed information, case studies, and data to support claims',
		[TraitsDetail.ResponseStrategy]: 'Provide specific data, detailed explanations, and case studies',
	},
	[PersonalityTrait.Amiable]: {
		[TraitsDetail.Traits]: 'Relationship-focused, supportive, collaborative',
		[TraitsDetail.Scenario]: 'the prospect emphasizes the importance of team buy-in and company adoption',
		[TraitsDetail.ResponseStrategy]:
			'Highlight team collaboration features and how your solution fits with the company setup',
	},
	[PersonalityTrait.Expressive]: {
		[TraitsDetail.Traits]: 'Enthusiastic, visionary, big-picture thinker',
		[TraitsDetail.Scenario]: 'the prospect is excited about innovative solutions and future possibilities',
		[TraitsDetail.ResponseStrategy]: 'Emphasize the innovative aspects of your solution and its long-term benefits',
	},
	[PersonalityTrait.Driver]: {
		[TraitsDetail.Traits]: 'Results-focused, decisive, goal-oriented',
		[TraitsDetail.Scenario]: 'the prospect is focused on achieving specific results and making quick decisions',
		[TraitsDetail.ResponseStrategy]: 'Present clear benefits, ROI, and how your solution meets their goals efficiently',
	},
}

export const EMOTIONAL_STATES_DETAILS: Record<EmotionalState, Record<TraitsDetail, string>> = {
	[EmotionalState.Skeptical]: {
		[TraitsDetail.Traits]: 'Doubtful, cautious, requires convincing',
		[TraitsDetail.Scenario]: 'The prospect questions the effectiveness and reliability of your solution',
		[TraitsDetail.ResponseStrategy]: 'Use testimonials, success stories, and concrete evidence to build trust',
	},
	[EmotionalState.Supportive]: {
		[TraitsDetail.Traits]: 'Cooperative, encouraging, open-minded',
		[TraitsDetail.Scenario]: 'the prospect is receptive and encourages the discussion',
		[TraitsDetail.ResponseStrategy]:
			'Maintain a collaborative tone and explore how your solution can further support their needs',
	},
	[EmotionalState.Busy]: {
		[TraitsDetail.Traits]: 'Time-constrained, hurried, prioritizes efficiency',
		[TraitsDetail.Scenario]: 'the prospect has limited time and wants concise information',
		[TraitsDetail.ResponseStrategy]: 'Be direct, concise, and focus on the most critical points quickly',
	},
	[EmotionalState.Indecisive]: {
		[TraitsDetail.Traits]: 'Hesitant, uncertain, needs guidance',
		[TraitsDetail.Scenario]: 'the prospect struggles to make a decision and seeks reassurance',
		[TraitsDetail.ResponseStrategy]: 'Provide clear options, benefits, and a path forward to help them make a decision',
	},
}

export const COMMUNICATION_STYLES_DETAILS: Record<CommunicationStyle, Record<TraitsDetail, string>> = {
	[CommunicationStyle.Direct]: {
		[TraitsDetail.Traits]: 'Straightforward, no-nonsense, clear',
		[TraitsDetail.Scenario]: 'the prospect appreciates clear and straightforward communication',
		[TraitsDetail.ResponseStrategy]: 'Be concise, clear, and avoid unnecessary details',
	},
	[CommunicationStyle.Indirect]: {
		[TraitsDetail.Traits]: 'Subtle, diplomatic, reads between the lines',
		[TraitsDetail.Scenario]: 'the prospect is subtle and may not express needs explicitly',
		[TraitsDetail.ResponseStrategy]: 'Ask probing questions and listen carefully to uncover underlying needs',
	},
	[CommunicationStyle.Formal]: {
		[TraitsDetail.Traits]: 'Polite, structured, prefers professionalism',
		[TraitsDetail.Scenario]: 'the prospect values formal and professional interactions',
		[TraitsDetail.ResponseStrategy]: 'Maintain a professional tone and structure in your communication',
	},
	[CommunicationStyle.Informal]: {
		[TraitsDetail.Traits]: 'Casual, friendly, conversational',
		[TraitsDetail.Scenario]: 'the prospect prefers a more relaxed and friendly interaction',
		[TraitsDetail.ResponseStrategy]: 'Be personable, use a conversational tone, and build rapport',
	},
}

export const DECISSION_MAKING_PROCESSES_DETAILS: Record<DecisionMakingProcess, Record<TraitsDetail, string>> = {
	[DecisionMakingProcess.Collaborative]: {
		[TraitsDetail.Traits]: 'Involves team, seeks consensus',
		[TraitsDetail.Scenario]: 'the prospect needs to consult with their team before making a decision',
		[TraitsDetail.ResponseStrategy]:
			'Offer to provide information that can be shared with the team and suggest a follow-up meeting with key stakeholders',
	},
	[DecisionMakingProcess.Autonomous]: {
		[TraitsDetail.Traits]: 'Independent, makes decisions alone',
		[TraitsDetail.Scenario]: 'the prospect has the authority to make decisions without consulting others',
		[TraitsDetail.ResponseStrategy]: 'Focus on persuading the prospect directly, emphasizing benefits and ROI',
	},
	[DecisionMakingProcess.Hierarchical]: {
		[TraitsDetail.Traits]: 'Follows a chain of command, seeks approval from higher-ups',
		[TraitsDetail.Scenario]: 'the prospect needs approval from superiors before making a decision',
		[TraitsDetail.ResponseStrategy]:
			'Provide materials that can be presented to decision-makers and offer to participate in discussions with higher-ups',
	},
}

export enum FORM_STEPS {
	Details = 'details',
	PersonaMapping = 'persona_mapping',
	Personality = 'personality',
	Evaluation = 'evaluation',
}
