import { type ActionFunctionArgs, redirect } from 'react-router'
import { client } from '#src/main'
import { deleteEcosystem } from '#src/routes/calibrate/ecosystem-management/ecosystem/mutations'
import { ecosystemKeys } from '#src/routes/calibrate/ecosystem-management/ecosystem/queries'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export const action = async ({ params }: ActionFunctionArgs) => {
	const { company, companyId } = await getCompany(params)
	validateRouteParams(params, ['ecosystemId'])

	await deleteEcosystem(company.id, params.ecosystemId)

	await client.invalidateQueries({
		queryKey: ecosystemKeys.all,
	})

	return redirect(
		routes.calibrate.index({
			companyId,
		}),
	)
}
