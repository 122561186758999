import { type z } from 'zod'
import { type CriterionQuestionAPISchema } from '#src/routes/calibrate/ecosystem-management/criteria/schema'
import { type CompanySchema } from '#src/routes/company/schema'
import { baseApi } from '#src/utils/endpoints'

const base = (companyId: z.infer<typeof CompanySchema.shape.id>) => `${baseApi}/company/${companyId}/criterion`

const endpoints = {
	createEnrichCriterionAnswers: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		itemId: z.infer<typeof CriterionQuestionAPISchema.shape.id>,
	) => `${base(companyId)}/${itemId}/enrich-answers`,
	cancelEnrichCriterionAnswers: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		itemId: z.infer<typeof CriterionQuestionAPISchema.shape.id>,
	) => `${base(companyId)}/${itemId}/enrich-answers/cancel`,
	getInstructions: (companyId: z.infer<typeof CompanySchema.shape.id>) => `${base(companyId)}/instructions`,
	modelTypes: (companyId: z.infer<typeof CompanySchema.shape.id>) => `${base(companyId)}/model-types`,
}

export { endpoints as criteriaEndpoints }
