import { parseWithZod } from '@conform-to/zod'
import { type ActionFunctionArgs, redirect } from 'react-router'
import { type z } from 'zod'
import { companyCopilotConversationMutations } from '#src/api/icp/company/copilot/conversation/mutations'
import { settingsCopilotQueries } from '#src/api/icp/company/settings/copilot/queries'
import {
	SettingsCopilotFormParserSchema,
	type SettingsCopilotFormSchema,
} from '#src/api/icp/company/settings/copilot/schemas'
import { showToast } from '#src/context/ToastContext'
import { client } from '#src/main'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { getUser } from '#src/utils/server/user'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ request, params }: ActionFunctionArgs) => {
	validateRouteParams(params, ['conversationId'])
	const { company, companyId } = await getCompany(params)

	const { readOnlySession } = await getUser()
	if (readOnlySession) {
		throw new Response('Access Denied', {
			status: 403,
			statusText: 'Forbidden',
		})
	}

	const formData = await request.formData()

	let data: z.infer<typeof SettingsCopilotFormSchema>
	if (formData.get('intent') === 'reset') {
		data = await client.fetchQuery(settingsCopilotQueries.get(company.id))
	} else {
		const submission = parseWithZod(formData, {
			schema: SettingsCopilotFormParserSchema,
		})

		if (submission.status !== 'success') {
			showToast({
				message: 'Failed to parse form data',
				type: 'error',
			})
			return {
				ok: false,
			}
		}
		data = submission.value
	}

	try {
		await companyCopilotConversationMutations.update(company.id, params.conversationId, data)
		showToast({
			message: 'Conversation settings updated',
			type: 'success',
		})
		return redirect(routes.enable.copilot.index({ companyId, conversationId: params.conversationId }))
	} catch (e) {
		if (e instanceof Response) {
			const response = (await e.json()) as { message: string; errors: Record<string, string[]> }
			showToast({
				message: response.message,
				type: 'error',
			})
			return {
				ok: false,
			}
		}
		showToast({
			message: 'Failed api response. Please contact support',
			type: 'error',
		})
		return {
			ok: false,
		}
	}
}
