import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useFetcher } from 'react-router'
import { type z } from 'zod'
import {
	PlaysTemplatesFormSchema,
	type PlaysTemplatesItemAPISchema,
} from '#src/api/icp/company/plays-templates/schemas'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import LoaderRequiredComponent from '#src/components/LoaderRequiredComponent'
import { Dialog } from '#src/components/ui/dialog'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { type ActionRes } from '#src/routes/enable/plays/root/actions/edit'
import { type LoaderRes } from '#src/routes/enable/plays/templates/loaders/item'
import PlayTemplateFormFields from '#src/routes/enable/plays/templates/views/components/PlayTemplateFormFields'
import { routes } from '#src/utils/routes'

export type PlayTemplateEditModalProps = {
	templateId: z.infer<typeof PlaysTemplatesItemAPISchema.shape.id>
}

export const PlayTemplateEditModal = (props: PlayTemplateEditModalProps) => {
	const { companyId } = useCompany()

	return (
		<LoaderRequiredComponent<LoaderRes, PlayTemplateEditModalProps>
			route={routes.enable.playTemplates.item({ companyId, templateId: String(props.templateId) })}
			component={ModalInner}
			initialProps={props}
		/>
	)
}

const FORM_ID = (id: number) => `edit-play-template-form-${id}`

const ModalInner = (props: PlayTemplateEditModalProps & LoaderRes) => {
	const {
		data: { template },
	} = props
	const { closeModal } = useModals()
	const { companyId } = useCompany()
	const actionFetcher = useFetcher<ActionRes>()

	const form = useForm<z.infer<typeof PlaysTemplatesFormSchema>>({
		resolver: zodResolver(PlaysTemplatesFormSchema),
		defaultValues: {
			name: template.name,
			instructions: template.instructions,
			inCopilot: template.inCopilot,
			inCrm: template.inCrm,
			personaTypes: template.personaTypes,
			tags: template.tags,
			status: template.status,
			isGlobal: template.isGlobal,
			templateExpertId: template.templateExpertId,
		},
		mode: 'onSubmit',
	})

	return (
		<Dialog
			defaultOpen={true}
			onOpenChange={isOpen => !isOpen && closeModal(MODAL_NAME.PlayTemplateEdit)}
			dialogHeading="Edit Play Template"
			actions={[
				{ type: 'cancel', label: 'Close' },
				{ type: 'submit', formId: FORM_ID(template.id), label: 'Save' },
			]}
			size="xl"
			isSubmitting={actionFetcher.state === 'submitting'}
		>
			<FormWrapper
				formId={FORM_ID(template.id)}
				action={routes.enable.playTemplates.edit({ companyId, templateId: String(template.id) })}
				formProps={form}
				className="flex flex-col gap-6"
				fetcher={actionFetcher}
				actionResponse={actionFetcher.data}
			>
				<PlayTemplateFormFields />
			</FormWrapper>
		</Dialog>
	)
}
