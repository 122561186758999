import { type LoaderFunctionArgs } from 'react-router'
import { client } from '#src/main'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem-management/ecosystem/queries'
import { assignedSignalsQuery, signalTemplateQuery } from '#src/routes/prioritize/queries'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	validateRouteParams(params, ['signalId'])

	return {
		handle: {
			signalId: params.signalId,
		},
		data: {
			assignedSignals: (await client.fetchQuery(assignedSignalsQuery(company.id))).filter(
				s => s.signalId === Number(params.signalId),
			),
			ecosystems: await client.fetchQuery(ecosystemsQuery(company.id)),
			signalTemplate: await client.fetchQuery(signalTemplateQuery(company.id, params.signalId)),
		},
	}
}
