import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useFetcher } from 'react-router'
import { type z } from 'zod'
import { PlaysExpertsFormSchema, type PlaysExpertsItemAPISchema } from '#src/api/icp/admin/plays-experts/schemas'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import LoaderRequiredComponent from '#src/components/LoaderRequiredComponent'
import { Dialog } from '#src/components/ui/dialog'
import { MODAL_NAME } from '#src/constants/modals'
import { useModals } from '#src/hooks/useModals'
import { type ActionRes } from '#src/routes/admin/plays-experts/actions/edit'
import { type LoaderRes } from '#src/routes/admin/plays-experts/loaders/item'
import PlaysExpertsFormFields from '#src/routes/admin/plays-experts/views/components/PlaysExpertsFormFields'
import { routes } from '#src/utils/routes'

export type AdminPlaysExpertsEditModalProps = {
	expertId: z.infer<typeof PlaysExpertsItemAPISchema.shape.id>
}

export const AdminPlaysExpertsEditModal = (props: AdminPlaysExpertsEditModalProps) => (
	<LoaderRequiredComponent<LoaderRes, AdminPlaysExpertsEditModalProps>
		route={routes.admin.playsExperts.item({ expertId: String(props.expertId) })}
		component={ModalInner}
		initialProps={props}
	/>
)

const FORM_ID = (id: z.infer<typeof PlaysExpertsItemAPISchema.shape.id>) => `admin-plays-experts-edit-form-${id}`

const ModalInner = (props: AdminPlaysExpertsEditModalProps & LoaderRes) => {
	const {
		data: { expert },
	} = props
	const { closeModal } = useModals()
	const actionFetcher = useFetcher<ActionRes>()

	const form = useForm<z.infer<typeof PlaysExpertsFormSchema>>({
		resolver: zodResolver(PlaysExpertsFormSchema),
		defaultValues: {
			fullName: expert.fullName,
			linkedin: expert.linkedin,
			about: expert.about,
		},
		mode: 'onSubmit',
	})

	return (
		<Dialog
			defaultOpen={true}
			onOpenChange={isOpen => !isOpen && closeModal(MODAL_NAME.AdminPlaysExpertsEdit)}
			dialogHeading="Edit Plays Expert"
			actions={[
				{ type: 'cancel', label: 'Close' },
				{ type: 'submit', formId: FORM_ID(expert.id), label: 'Save' },
			]}
			size="xl"
			isSubmitting={actionFetcher.state === 'submitting'}
		>
			<FormWrapper
				formId={FORM_ID(expert.id)}
				action={routes.admin.playsExperts.edit({ expertId: String(expert.id) })}
				formProps={form}
				className="flex flex-col gap-6"
				fetcher={actionFetcher}
				actionResponse={actionFetcher.data}
			>
				<PlaysExpertsFormFields />
			</FormWrapper>
		</Dialog>
	)
}
