import { type z } from 'zod'
import { type PersonaAPISchema, type PersonasSchema } from '#src/api/icp/company/persona/schemas'
import ProductTip from '#src/components/product-tip'
import { Button } from '#src/components/ui/button'
import { MODAL_NAME } from '#src/constants/modals'
import useAuth from '#src/hooks/useAuth'
import { useModals } from '#src/hooks/useModals'
import { PersonaCreateModal } from '#src/modals/personas/PersonaCreate'
import Aside from '#src/routes/calibrate/ecosystem-management/personas/views/components/Aside'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'

type Props = {
	persona: z.infer<typeof PersonaAPISchema>
	reportsToPersona: z.infer<typeof PersonasSchema> | undefined
}

const PersonaSetupCard = (props: Props) => {
	const { persona, reportsToPersona } = props
	const { readOnlySession } = useAuth()
	const { openModal } = useModals()
	const { personaId, ecosystemId, verticalId } = useParsedRouteParams(['personaId', 'ecosystemId', 'verticalId'])

	return (
		<Aside
			title={
				<div className="flex items-center justify-between">
					Setup info
					{!readOnlySession && (
						<Button
							variant="ghost"
							className="flex gap-1.5"
							onClick={() =>
								openModal({
									name: MODAL_NAME.PersonaCreateModal,
									component: PersonaCreateModal,
									props: {
										ecosystemId: Number(ecosystemId),
										verticalId: Number(verticalId),
										personaId: Number(personaId),
									},
								})
							}
						>
							Edit
						</Button>
					)}
				</div>
			}
			list={[
				{
					label: (
						<div className="flex items-center gap-1">
							Expertise
							<ProductTip content="Expertise: Define the primary area of expertise relevant to the persona (e.g., Revenue)." />
						</div>
					),
					value: persona.expertise,
				},
				{
					label: (
						<div className="flex items-center gap-1">
							Job Experience
							<ProductTip content="Job Experience: Indicate the required years of experience (e.g., +7 years)." />
						</div>
					),
					value: persona.jobExperience,
				},
				{
					label: (
						<div className="flex items-center gap-1">
							Job Titles
							<ProductTip content="Job Title: Provide examples of relevant job titles (e.g., CRO, Chief, President, SVP, VP, Director of Revenue or Sales)." />
						</div>
					),
					value: persona.jobTitles,
				},
				{
					label: (
						<div className="flex items-center gap-1">
							Job Seniority
							<ProductTip content="Job Seniority: Specify the seniority level (e.g., Chief, President, SVP, VP, Director)." />
						</div>
					),
					value: persona.jobSeniority,
				},
				{
					label: (
						<div className="flex items-center gap-1">
							Reports To
							<ProductTip content="Reports To: Specify the reporting structure (e.g., CEO)." />
						</div>
					),
					value: reportsToPersona ? String(reportsToPersona.jobTitles ?? persona.reportsTo?.name ?? '-') : '-',
				},
				{
					label: 'Reports To (manual)',
					value: persona.reportsOverride ?? '-',
				},
				{
					label: (
						<div className="flex items-center gap-1">
							Types of Tasks
							<ProductTip content="Types of Tasks: Describe key responsibilities and tasks undertaken by the persona (e.g., Implementing account-based methodologies)." />
						</div>
					),
					value: persona.typeOfTask,
				},
				{
					label: 'Pain (manual)',
					value: persona.painOverride ?? '-',
				},
				{
					label: (
						<div className="flex items-center gap-1">
							Pain Length (# of words)
							<ProductTip content="Pain Length (# of words): Set the word count for describing pain points." />
						</div>
					),
					value: persona.painLength,
				},
			]}
		/>
	)
}

export default PersonaSetupCard
