import * as SliderPrimitive from '@radix-ui/react-slider'
import { type SliderProps as RadixSliderProps } from '@radix-ui/react-slider'
import { cn } from '#src/utils/misc'

export type SliderProps = RadixSliderProps & {
	disableMinMaxCount?: boolean
}

export const Slider = ({ disableMinMaxCount, ...props }: SliderProps) => {
	return (
		<div className="flex w-full gap-3 text-body-md">
			{!disableMinMaxCount && <span>{props.min}</span>}
			<SliderPrimitive.Root {...props} className="relative flex h-5 w-full touch-none items-center">
				<SliderPrimitive.Track className="relative h-[3px] w-full grow rounded-full bg-neutral-2-bg">
					<SliderPrimitive.Range className="absolute h-full rounded-full bg-neutral-inverse-bg" />
				</SliderPrimitive.Track>
				<SliderPrimitive.Thumb
					className={cn(
						'block h-[16px] w-[16px] rounded-full bg-neutral-inverse-bg',
						'focus:outline-none focus:ring-2 focus:ring-neutral-2-bd',
					)}
				/>
			</SliderPrimitive.Root>
			{!disableMinMaxCount && <span>{props.max}</span>}
		</div>
	)
}
