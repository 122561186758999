import { type LoaderFunctionArgs } from 'react-router'
import { companyChatArchiveQueries } from '#src/api/icp/company/chat/archive/queries'
import { client } from '#src/main'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	validateRouteParams(params, ['scenarioId', 'sessionId'])

	return {
		handle: {
			scenarioId: params.scenarioId,
			sessionId: params.sessionId,
		},
		attempts: await client.fetchQuery(companyChatArchiveQueries.list(company.id, params.sessionId)),
	}
}
