import { useEffect } from 'react'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { CopilotCreateModal } from '#src/modals/copilot/CopilotCreate'
import { routes } from '#src/utils/routes'

export default function NewChat() {
	const { openModal } = useModals()
	const { companyId } = useCompany()

	useEffect(() => {
		openModal({
			name: MODAL_NAME.CopilotCreate,
			component: CopilotCreateModal,
			settings: { routeToRedirectOnClose: routes.enable.copilot.index({ companyId, conversationId: null }) },
		})
	}, [companyId, openModal])

	return null
}
