import { type ReactNode } from 'react'
import { type z } from 'zod'
import {
	type ScenarioAPISchema,
	type ScenarioParticipantAPISchema,
} from '#src/api/icp/company/chat/roleplay/scenario/schemas'
import DeleteConfirm from '#src/components/delete-confirm'
import useCompany from '#src/hooks/useCompany'
import { routes } from '#src/utils/routes'

type Props = {
	scenario: z.infer<typeof ScenarioAPISchema>
	participant: z.infer<typeof ScenarioParticipantAPISchema>
	children: ReactNode
}

export const UnassignUserModal = (props: Props) => {
	const { scenario, participant, children } = props
	const { companyId } = useCompany()

	return (
		<DeleteConfirm
			trigger={children}
			action={routes.enable.roleplay.scenario.unassign({
				companyId,
				scenarioId: scenario.id,
				participantId: String(participant.id),
			})}
			formId={`${scenario.id}-unassign-${participant.id}`}
			actionTitle="Unassign"
			title="Unassign user from scenario?"
			description={
				<>
					Are you sure you want to unassign <b>{participant.username}</b> from scenario?
				</>
			}
		/>
	)
}
