import { useQuery } from '@tanstack/react-query'
import { useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { useLoaderData } from 'react-router'
import { type z } from 'zod'
import { CONTACT_FINDER_SETTINGS_TYPE, FILE_TYPES, LRA_JOB_STATUS } from '#src/api/organise/lists/constants'
import { orgListsQueries } from '#src/api/organise/lists/queries'
import { type LeadResearchAgentItemAPISchema } from '#src/api/organise/lists/schemas'
import ContactAvatar from '#src/components/chat/avatar'
import { Chip } from '#src/components/chip'
import { Button } from '#src/components/ui/button'
import { Icon, type IconName } from '#src/components/ui/icon'
import { LabeledValue } from '#src/components/ui/LabeledValue'
import { PageLayout, type PageLayoutProps } from '#src/components/ui/PageLayout'
import { Surface } from '#src/components/ui/surface'
import { CustomTooltip } from '#src/components/ui/tooltip'
import { MODAL_NAME } from '#src/constants/modals'
import useAuth from '#src/hooks/useAuth'
import { useModals } from '#src/hooks/useModals'
import { LeadResearchAgentJobArchiveModal } from '#src/modals/lead-research-agent/Archive'
import { LeadResearchAgentJobDownloadModal } from '#src/modals/lead-research-agent/Download'
import { LeadResearchAgentJobEditModal } from '#src/modals/lead-research-agent/Edit'
import { LeadResearchAgentJobStopModal } from '#src/modals/lead-research-agent/Stop'
import { JobStatusName } from '#src/routes/organize/lead-research-agent/constants'
import { canDownload, canStop } from '#src/routes/organize/lead-research-agent/utils'
import { formatDateToReadableString } from '#src/utils/date'
import { formatAvatarAbbr } from '#src/utils/misc'
import { type LRAItemLoaderRes } from '../routes/item'
import { BarLineStatistics, ItemStatisticsCard, type ItemStatisticsCardProps } from './components'

const FIRST_CARD_ICON: Record<LRA_JOB_STATUS, IconName> = {
	[LRA_JOB_STATUS.Launching]: 'loading-sm',
	[LRA_JOB_STATUS.Completed]: 'checkmark-filled',
	[LRA_JOB_STATUS.CompletedWithQuestions]: 'checkmark-filled-warning',
	[LRA_JOB_STATUS.Cancelled]: 'stop-sign-filled',
	[LRA_JOB_STATUS.Error]: 'stop-sign-filled',
	[LRA_JOB_STATUS.InProgress]: 'loading-sm',
} as const

const FIRST_CARD_ICON_VARIANT: Record<LRA_JOB_STATUS, ItemStatisticsCardProps['iconVariant']> = {
	[LRA_JOB_STATUS.Launching]: undefined,
	[LRA_JOB_STATUS.Completed]: 'success',
	[LRA_JOB_STATUS.CompletedWithQuestions]: 'success',
	[LRA_JOB_STATUS.Cancelled]: 'danger',
	[LRA_JOB_STATUS.Error]: 'danger',
	[LRA_JOB_STATUS.InProgress]: undefined,
} as const

export const View = () => {
	const {
		values: { jobId },
		data,
	} = useLoaderData<LRAItemLoaderRes>()

	const { userSession, adminSession } = useAuth()

	const { data: job } = useQuery({
		...orgListsQueries.item(jobId),
		initialData: data.job,
		refetchInterval: ({ state }) => {
			if (!state.data) {
				return 0
			}
			if (canStop(state.data.status)) {
				return 5000
			}
			return 0
		},
	})

	const [copyIdTooltipShow, setCopyIdTooltipShow] = useState(false)
	const onClickCopyId = () => {
		navigator.clipboard
			.writeText(job.id)
			.then(() => {
				setCopyIdTooltipShow(true)
				setTimeout(() => setCopyIdTooltipShow(false), 1500)
			})
			.catch(() => {})
	}

	const contactsPerPersonasFiltered = useMemo(() => {
		if (!job.contactFinderSettings.contactsPerPersonas) {
			return null
		}

		return Object.fromEntries(
			Object.entries(job.contactFinderSettings.contactsPerPersonas)
				.map(([key, val]) => {
					return [key, Object.fromEntries(Object.entries(val).filter(([, val2]) => val2 > 0))]
				})
				.filter(([, val2]) => Object.keys(val2).length > 0),
		) as z.infer<typeof LeadResearchAgentItemAPISchema.shape.contactFinderSettings.shape.contactsPerPersonas>
	}, [job.contactFinderSettings.contactsPerPersonas])

	const ecosystemsMapped = useMemo(() => {
		const ecosystems: { id: string; title: string; verticals: { id: string; title: string }[] }[] = []
		job.verticals.forEach(vertical => {
			const matchingEcosystem = ecosystems.find(ecosystem => ecosystem.id === vertical.ecosystem.id)
			if (!matchingEcosystem) {
				ecosystems.push({
					id: vertical.ecosystem.id,
					title: vertical.ecosystem.title,
					verticals: [{ id: vertical.id, title: vertical.title }],
				})
			} else {
				matchingEcosystem.verticals.push({ id: vertical.id, title: vertical.title })
			}
		})

		return ecosystems
	}, [job.verticals])

	const { openModal } = useModals()

	return (
		<PageLayout
			title={job.title}
			actions={[
				...((canStop(job.status)
					? [
							<Button
								key="lra-job-stop"
								variant="danger-outline"
								onClick={() =>
									openModal({
										name: MODAL_NAME.LeadResearchAgentJobStop,
										component: LeadResearchAgentJobStopModal,
										props: {
											jobId: job.id,
										},
									})
								}
							>
								<Icon name="stop-filled-alt" className="mr-2 text-status-danger-fg" />
								Stop
							</Button>,
						]
					: []) satisfies PageLayoutProps['actions']),
				...((canDownload(job.status)
					? [
							<Button
								key="lra-job-download"
								onClick={() =>
									openModal({
										name: MODAL_NAME.LeadResearchAgentJobDownload,
										component: LeadResearchAgentJobDownloadModal,
										props: {
											jobId: job.id,
										},
									})
								}
							>
								<Icon name="download" className="mr-2" />
								Download
							</Button>,
						]
					: []) satisfies PageLayoutProps['actions']),
				<Button
					key="lra-job-edit"
					variant="outline"
					onClick={() =>
						openModal({
							name: MODAL_NAME.LeadResearchAgentJobEdit,
							component: LeadResearchAgentJobEditModal,
							props: {
								jobId: job.id,
							},
						})
					}
				>
					<Icon name="edit" className="mr-2" />
					Edit
				</Button>,
				<Button
					key="lra-job-archive"
					variant="danger-outline"
					onClick={() =>
						openModal({
							name: MODAL_NAME.LeadResearchAgentJobArchive,
							component: LeadResearchAgentJobArchiveModal,
							props: {
								jobId: job.id,
							},
						})
					}
				>
					<Icon name="trash" className="mr-2 text-status-danger-fg" />
					Archive
				</Button>,
			]}
		>
			{job.statusReason &&
				createPortal(
					<section className="w-full bg-red-10 p-2">
						<p className="item-center flex justify-center gap-1 text-center text-body-sm font-semibold text-neutral-2-fg">
							<Icon name="warning-alt-filled" size="sm" className="text-status-danger-fg" />
							The agent could not create the decision-tree for qualification. Feel free to reach out to your Customer
							Success Manager for more information
						</p>
					</section>,
					document.getElementById('page-top-sticky-portal') ?? document.body,
				)}
			<div className="grid w-full grid-cols-1 grid-rows-1 gap-6 lg:grid-cols-2">
				<ItemStatisticsCard
					icon={FIRST_CARD_ICON[job.status]}
					iconVariant={FIRST_CARD_ICON_VARIANT[job.status]}
					name={JobStatusName[job.status]}
				>
					<BarLineStatistics
						name="Accounts"
						items={[
							{
								id: 'in_queue',
								icon: 'circle-dash',
								name: 'In queue',
								color: 'bg-brand-2-fg',
								iconColor: 'text-neutral-3-fg',
								value: job.statistic.accountInQueueCount,
							},
							{
								id: 'in_progress',
								icon: 'incomplete',
								name: 'In progress',
								color: 'bg-brand-1-fg',
								iconColor: 'text-brand-1-fg',
								value: job.statistic.accountInProgressCount,
							},
							{
								id: 'completed',
								icon: 'checkmark-filled',
								name: 'Completed',
								color: 'bg-status-success-bg',
								iconColor: 'text-status-success-bg',
								value: job.statistic.accountCompletedCount,
							},
							{
								id: 'with_errors',
								icon: 'checkmark-filled',
								name: 'With errors',
								color: 'bg-status-danger-bg',
								iconColor: 'text-status-danger-bg',
								value: job.statistic.accountWithErrorsCount,
							},
						]}
					/>
					<BarLineStatistics
						name="Contacts"
						items={[
							{
								id: 'in_queue',
								icon: 'circle-dash',
								name: 'In queue',
								color: 'bg-brand-2-fg',
								iconColor: 'text-neutral-3-fg',
								value: job.statistic.contactInQueueCount,
							},
							{
								id: 'in_progress',
								icon: 'incomplete',
								name: 'In progress',
								color: 'bg-brand-1-fg',
								iconColor: 'text-brand-1-fg',
								value: job.statistic.contactInProgressCount,
							},
							{
								id: 'completed',
								icon: 'checkmark-filled',
								name: 'Completed',
								color: 'bg-status-success-bg',
								iconColor: 'text-status-success-bg',
								value: job.statistic.contactCompletedCount,
							},
							{
								id: 'with_errors',
								icon: 'checkmark-filled',
								name: 'With errors',
								color: 'bg-status-danger-bg',
								iconColor: 'text-status-danger-bg',
								value: job.statistic.contactWithErrorsCount,
							},
						]}
					/>
				</ItemStatisticsCard>
				<ItemStatisticsCard
					icon="user-check"
					name="ICP/IBP ratio"
					tip="This view shows the number of accounts and contacts from your uploaded list that match the Ideal Customer Profile (ICP) and Ideal Buyer Profile (IBP) criteria."
				>
					<BarLineStatistics
						name="ICP"
						items={[
							{
								id: 'in_queue',
								icon: 'circle-dash',
								name: 'In queue',
								color: 'bg-brand-2-fg',
								iconColor: 'text-neutral-3-fg',
								value: job.statistic.icpStatusNullCount,
							},
							{
								id: 'yes',
								icon: 'checkmark-outline',
								name: 'ICP Yes',
								color: 'bg-status-success-bg',
								iconColor: 'text-status-success-bg',
								value: job.statistic.icpStatusYesCount,
							},
							{
								id: 'no',
								icon: 'cross-outline',
								name: 'ICP No',
								color: 'bg-status-danger-bg',
								iconColor: 'text-status-danger-bg',
								value: job.statistic.icpStatusNoCount,
							},
							{
								id: 'inconclusive',
								icon: 'error',
								name: 'ICP Inconclusive',
								color: 'bg-status-warning-bg',
								iconColor: 'text-status-warning-bg',
								value: job.statistic.icpStatusInconclusiveCount,
							},
						]}
					/>
					<div className="flex flex-col gap-4">
						<BarLineStatistics
							name="IBP"
							items={[
								{
									id: 'in_queue',
									icon: 'circle-dash',
									name: 'In queue',
									color: 'bg-brand-2-fg',
									iconColor: 'text-neutral-3-fg',
									value: job.statistic.ibpStatusNullCount,
								},
								{
									id: 'yes',
									icon: 'checkmark-outline',
									name: 'IBP Yes',
									color: 'bg-status-success-bg',
									iconColor: 'text-status-success-bg',
									value: job.statistic.ibpStatusYesCount,
								},
								{
									id: 'no',
									icon: 'cross-outline',
									name: 'IBP No',
									color: 'bg-status-danger-bg',
									iconColor: 'text-status-danger-bg',
									value: job.statistic.ibpStatusNoCount,
								},
								{
									id: 'inconclusive',
									icon: 'error',
									name: 'IBP Inconclusive',
									color: 'bg-status-warning-bg',
									iconColor: 'text-status-warning-bg',
									value: job.statistic.ibpStatusInconclusiveCount,
								},
							]}
						/>
						<div className="w-full border-b border-neutral-1-bd" />
						<div className="flex flex-row items-center justify-between gap-1 py-1.5">
							<div className="flex flex-row items-center justify-between gap-1 text-neutral-2-fg">
								<Icon name="email" size="sm" />
								<div className="text-body-sm">Contacts with Email/Phone</div>
							</div>
							<div className="text-body-sm">{job.statistic.contactWithEmailPhoneCount}</div>
						</div>
					</div>
				</ItemStatisticsCard>
			</div>
			<Surface className="flex w-full flex-col gap-8 p-10">
				<h2 className="text-title-lg">Details</h2>
				<div className="flex w-full flex-row gap-8">
					<div className="flex flex-1 flex-col gap-6">
						<LabeledValue label="Name">{job.title}</LabeledValue>
						{!!job.comment && (
							<LabeledValue label="Comment">
								<div className="whitespace-pre-wrap">{job.comment}</div>
							</LabeledValue>
						)}
						{ecosystemsMapped.length && (
							<section className="flex flex-col gap-4">
								<h4 className="text-title-sm">Ecosystems and verticals</h4>
								{ecosystemsMapped.map(ecosystem => (
									<LabeledValue key={`ecosystem-${ecosystem.id}`} label={ecosystem.title}>
										<div className="flex flex-row flex-wrap gap-1">
											{ecosystem.verticals.map(vertical => (
												<Chip variant="gray" key={`ecosystem-${ecosystem.id}-vertical-${vertical.id}`}>
													{vertical.title}
												</Chip>
											))}
										</div>
									</LabeledValue>
								))}
							</section>
						)}
						{job.plays.length > 0 && job.contactEnrichingEnabled && (
							<section className="flex flex-col gap-4">
								<h4 className="text-title-sm">Plays</h4>
								<div className="markdown">
									<ul>
										{job.plays.map(play => (
											<li key={`play-${play.id}`}>{play.title}</li>
										))}
									</ul>
								</div>
							</section>
						)}
						{job.enrichmentCriterias.length > 0 && (
							<section className="flex flex-col gap-4">
								<h4 className="text-title-sm">Enrichment criteria</h4>
								<div className="markdown">
									<ul>
										{job.enrichmentCriterias.map(enrichmentCriteria => (
											<li key={`enrichment-${enrichmentCriteria.id}`}>{enrichmentCriteria.title}</li>
										))}
									</ul>
								</div>
							</section>
						)}
					</div>
					<div className="flex w-full max-w-[330px] flex-col gap-6 rounded-lg bg-neutral-2-bg p-6">
						<LabeledValue label="Id">
							<span>{job.id}</span>
							<CustomTooltip
								rootProps={{
									open: copyIdTooltipShow,
								}}
								label="Copied"
							>
								<Icon
									name="copy"
									className="ml-1 size-4 cursor-pointer text-neutral-3-fg hover:text-neutral-1-fg"
									onClick={onClickCopyId}
								/>
							</CustomTooltip>
						</LabeledValue>
						{job.importType && (
							<LabeledValue label="Type">
								{job.importType === FILE_TYPES.Accounts ? 'Accounts' : 'Accounts + Contacts'}
							</LabeledValue>
						)}
						{(userSession || adminSession) && job.userEmail && (
							<LabeledValue label="Created by">
								<div className="flex items-center gap-2">
									<ContactAvatar
										className="inline-flex shrink-0 items-center justify-center rounded-full"
										variant="blue"
										size="sm"
										initial={formatAvatarAbbr(job.userEmail)}
									/>
									{job.userEmail}
								</div>
							</LabeledValue>
						)}
						<LabeledValue label="Date created">{formatDateToReadableString(job.createdAt)}</LabeledValue>
						{!!job.importFileUrl && (
							<LabeledValue label="List source file">
								<a
									href={job.importFileUrl}
									target="_blank"
									rel="noreferrer"
									className="flex w-full flex-row items-center gap-1"
								>
									<Icon name="document" size="md" className="block text-brand-1-fg" />
									<div className="flex flex-1 flex-col truncate">
										<div className="max-w-full truncate text-body-md text-brand-1-fg">Uploaded file</div>
										{/*<div className="text-body-sm text-neutral-2-fg">500 KB</div>*/}
									</div>
								</a>
							</LabeledValue>
						)}
						<LabeledValue label="Accounts/Contacts uploaded">{job.accountsContactsImported ?? '-'}</LabeledValue>
						<LabeledValue label="Job settings">
							{/*<p>Account enrichment: {job.contactFinderEnabled ? 'On' : 'Off'}</p>*/}
							<p>Contact waterfalling: {job.contactWaterfallingEnabled ? 'On' : 'Off'}</p>
							<p>Contact enrichment: {job.contactEnrichingEnabled ? 'On' : 'Off'}</p>
							<p>Email verification: {job.emailVerificationEnabled ? 'On' : 'Off'}</p>
							<p>
								Contact Finder: {job.contactFinderEnabled ? 'On' : 'Off'}{' '}
								{job.contactFinderEnabled && (
									<>
										{job.contactFinderSettings.type === CONTACT_FINDER_SETTINGS_TYPE.ContactsPerAccount &&
											job.contactFinderSettings.contactsPerAccount !== null && (
												<small className="text-body-sm">
													({job.contactFinderSettings.contactsPerAccount} Contacts per account)
												</small>
											)}
										{job.contactFinderSettings.type === CONTACT_FINDER_SETTINGS_TYPE.ContactsPerPersonas &&
											contactsPerPersonasFiltered !== null && (
												<small className="text-body-sm">
													(
													{Object.entries(contactsPerPersonasFiltered)
														.map(
															([key, val]) =>
																`${key}: ${Object.entries(val)
																	.map(([key2, val2]) => `${key2} - ${val2}`)
																	.join(', ')}`,
														)
														.join(`, `)}
													)
												</small>
											)}
									</>
								)}
							</p>
						</LabeledValue>
						{/*<LabeledValue label="Contact Finder (Beta) enabled">{job.contactFinderEnabled ? 'Yes' : 'No'}</LabeledValue>*/}
						{/*<LabeledValue label="Contact Waterfalling enabled">*/}
						{/*	{job.contactWaterfallingEnabled ? 'Yes' : 'No'}*/}
						{/*</LabeledValue>*/}
					</div>
				</div>
			</Surface>
			<Surface className="flex w-full flex-col gap-8 p-10">
				<h2 className="text-title-lg">Report</h2>
				<iframe
					className="aspect-video w-full"
					src={`https://evergrowth.metabaseapp.com/public/dashboard/58453dcb-dae4-4d47-97db-91a842678709?criteria=-&icp=&job=${job.id}`}
				/>
			</Surface>
		</PageLayout>
	)
}
