import { useLoaderData } from 'react-router'
import { PageLayout } from '#src/components/ui/PageLayout'
import { TabSwitcher } from '#src/components/ui/tabs'
import { CompanyBillingForm } from '#src/routes/admin/company/views/components/CompanyBillingForm'
import CompanyCreditsUsage from '#src/routes/admin/company/views/components/CompanyCreditsUsage'
import { CompanyDashboardForm } from '#src/routes/admin/company/views/components/CompanyDashboardForm'
import { CompanyDetailsForm } from '#src/routes/admin/company/views/components/CompanyDetailsForm'
import { type LoaderRes } from '../loaders/edit'

export default function EditCompanyDashboard() {
	const { company, credits } = useLoaderData<LoaderRes>()

	return (
		<PageLayout title={company.name}>
			<TabSwitcher
				tabs={[
					{
						value: 'details',
						label: 'Details',
						content: (
							<div className="max-w-lg">
								<CompanyDetailsForm company={company} />
							</div>
						),
					},
					{
						value: 'urls',
						label: 'URLs',
						content: (
							<div className="max-w-lg">
								<CompanyDashboardForm company={company} />
							</div>
						),
					},
					{
						value: 'billing',
						label: 'Billing',
						content: (
							<div className="max-w-lg">
								<CompanyBillingForm company={company} />
							</div>
						),
					},
					{
						value: 'credits_usage',
						label: 'Credits usage',
						content: <CompanyCreditsUsage company={company} data={credits} />,
					},
				]}
			/>
		</PageLayout>
	)
}
