import { type RouteObject } from 'react-router'
import chatRouter from '#src/routes/enable/chat/router'
import contactsEnrichRouter from '#src/routes/enable/contacts-enrich/router'
import EnableDashboard, { loader as enableDashboardLoader } from '#src/routes/enable/dashboard'
import glossaryRouter from '#src/routes/enable/glossary/router'
import personasRouter from '#src/routes/enable/personas/router'
import playsRouter from '#src/routes/enable/plays/router'
import roleplayScenariosRouter from '#src/routes/enable/roleplay-scenarios/router'
import roleplayRouter from '#src/routes/enable/roleplay-sessions/router'

export default {
	path: 'enable',
	handle: {
		crumb: () => [
			{
				label: 'Enable',
				icon: 'data-enrichment',
			},
		],
	},
	children: [
		{
			index: true,
			loader: enableDashboardLoader,
			element: <EnableDashboard />,
		},
		personasRouter,
		glossaryRouter,
		chatRouter,
		roleplayRouter,
		roleplayScenariosRouter,
		playsRouter,
		contactsEnrichRouter,
	],
} satisfies RouteObject
