import { type ActionFunctionArgs } from 'react-router'
import { personaMutations } from '#src/api/icp/company/persona/mutations'
import { companyPersonaQueriesKeys } from '#src/api/icp/company/persona/queries'
import { PersonaFormSchema, PersonaListAPISchema } from '#src/api/icp/company/persona/schemas'
import { MODAL_NAME } from '#src/constants/modals'
import { showToast } from '#src/context/ToastContext'
import { client } from '#src/main'
import { ecosystemKeys } from '#src/routes/calibrate/ecosystem-management/ecosystem/queries'
import { parseFormDataNew } from '#src/utils/forms'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'
import { closeModalServer } from '#src/utils/server/modals'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ params, request }: ActionFunctionArgs) => {
	validateRouteParams(params, ['ecosystemId', 'verticalId'])
	const { companyId, company } = await getCompany(params)
	const formData = await request.formData()
	const isEditing = params.personaId

	try {
		const submission = parseFormDataNew(formData, PersonaFormSchema)

		const res = isEditing
			? await personaMutations.update(company.id, Number(params.personaId), submission.value)
			: await personaMutations.create(company.id, submission.value)

		await Promise.all([
			client.invalidateQueries({ queryKey: companyPersonaQueriesKeys.all }),
			client.invalidateQueries({ queryKey: ecosystemKeys.all }),
		])

		showToast({
			message: `Successfully ${params.personaId ? 'updated' : 'created'} persona`,
			type: 'success',
		})

		const list = !isEditing ? PersonaListAPISchema.parse(res) : []

		closeModalServer(
			MODAL_NAME.PersonaCreateModal,
			!isEditing
				? routes.calibrate.persona.index({
						companyId,
						ecosystemId: params.ecosystemId,
						verticalId: params.verticalId,
						personaId: String(list[0].id),
					})
				: undefined,
		)

		return { ok: true }
	} catch (err) {
		return await serverFormErrorHandler(err)
	}
}
