import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useFetcher } from 'react-router'
import { type z } from 'zod'
import { PlaysExpertsFormSchema } from '#src/api/icp/admin/plays-experts/schemas'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import { Dialog } from '#src/components/ui/dialog'
import { MODAL_NAME } from '#src/constants/modals'
import { useModals } from '#src/hooks/useModals'
import { type ActionRes } from '#src/routes/admin/plays-experts/actions/new'
import PlaysExpertsFormFields from '#src/routes/admin/plays-experts/views/components/PlaysExpertsFormFields'
import { routes } from '#src/utils/routes'

const FORM_ID = 'admin-plays-experts-create-form'

export const AdminPlaysExpertsCreateModal = () => {
	const { closeModal } = useModals()
	const actionFetcher = useFetcher<ActionRes>()

	const form = useForm<z.infer<typeof PlaysExpertsFormSchema>>({
		resolver: zodResolver(PlaysExpertsFormSchema),
		defaultValues: {
			fullName: '',
			about: '',
			linkedin: '',
		},
		mode: 'onSubmit',
	})

	return (
		<Dialog
			defaultOpen={true}
			onOpenChange={isOpen => !isOpen && closeModal(MODAL_NAME.AdminPlaysExpertsCreate)}
			dialogHeading="Add new Plays Expert"
			actions={[
				{ type: 'cancel', label: 'Close' },
				{ type: 'submit', formId: FORM_ID, label: 'Create' },
			]}
			size="xl"
			isSubmitting={actionFetcher.state === 'submitting'}
		>
			<FormWrapper
				formId={FORM_ID}
				formProps={form}
				action={routes.admin.playsExperts.create}
				className="flex flex-col gap-6"
				fetcher={actionFetcher}
				actionResponse={actionFetcher.data}
			>
				<PlaysExpertsFormFields />
			</FormWrapper>
		</Dialog>
	)
}
