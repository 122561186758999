import { useEffect } from 'react'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { RoleplayScenarioCreateModal } from '#src/modals/RoleplayScenarioCreate'
import { routes } from '#src/utils/routes'

const View = () => {
	const { openModal } = useModals()
	const { companyId } = useCompany()

	useEffect(() => {
		openModal({
			name: MODAL_NAME.RoleplayScenarioCreate,
			component: RoleplayScenarioCreateModal,
			props: undefined,
			settings: { routeToRedirectOnClose: routes.enable.roleplay.scenario.index({ companyId }) },
		})
	}, [companyId, openModal])

	return null
}

export default View
