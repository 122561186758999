import { type z } from 'zod'
import { type VerticalAPISchema } from '#src/api/icp/company/verticals/schemas'
import { type CompanySchema } from '#src/routes/company/schema'
import { safeFetch } from '#src/utils/safeFetch'
import { companyEcosystemsEndpoints } from './endpoints'
import { EcosystemsCriteriaListAPISchema } from './schemas'

const keys = {
	all: ['icp', 'company', 'ecosystems'] as const,
	criteria: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		verticals: z.infer<typeof VerticalAPISchema.shape.id>[] | undefined,
		type: 'enrichment' | 'qualification',
	) => [...keys.all, companyId, 'criteria', type, Array.isArray(verticals) ? verticals.sort() : undefined] as const,
}

const criteriaQuery = (
	companyId: z.infer<typeof CompanySchema.shape.id>,
	verticals: z.infer<typeof VerticalAPISchema.shape.id>[] | undefined,
	type: 'enrichment' | 'qualification',
) => ({
	queryKey: keys.criteria(companyId, verticals, type),
	queryFn: async () =>
		await safeFetch(EcosystemsCriteriaListAPISchema, companyEcosystemsEndpoints.criteria(companyId), {
			method: 'POST',
			body: JSON.stringify({
				type,
				verticals,
			}),
		}),
})

const queries = {
	criteria: criteriaQuery,
}

export { queries as companyEcosystemsQueries, keys as companyEcosystemsQueriesKeys }
