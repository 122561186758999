import { type z } from 'zod'
import { type PlaysExpertsItemAPISchema } from '#src/api/icp/admin/plays-experts/schemas'
import { adminApi } from '#src/utils/endpoints'

const base = `${adminApi}/plays-experts`

const endpoints = {
	list: () => base,
	create: () => base,
	item: (expertId: z.infer<typeof PlaysExpertsItemAPISchema.shape.id>) => `${base}/${expertId}`,
	edit: (expertId: z.infer<typeof PlaysExpertsItemAPISchema.shape.id>) => `${base}/${expertId}`,
	delete: (expertId: z.infer<typeof PlaysExpertsItemAPISchema.shape.id>) => `${base}/${expertId}`,
}

export { endpoints as adminPlaysExpertsEndpoints }
