import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { NewContactEnrichFormSchema } from '#src/api/icp/company/contacts-enrich/schemas'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import { Dialog } from '#src/components/ui/dialog'
import useCompany from '#src/hooks/useCompany'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

export const NewModal = () => {
	const { companyId } = useCompany()
	const formProps = useForm({
		resolver: zodResolver(NewContactEnrichFormSchema),
	})
	const isPending = useIsPending()
	const formId = 'addNewContact'
	const closeTo = routes.enable.contactsEnrich.index({ companyId })

	return (
		<Dialog
			open={true}
			dialogHeading="Add Contact's LinkedIn URL"
			closeTo={closeTo}
			actions={[
				{
					label: 'Cancel',
					type: 'cancel',
					to: closeTo,
				},
				{
					label: 'Enrich',
					type: 'submit',
					formId,
				},
			]}
			isSubmitting={isPending}
		>
			<FormWrapper formProps={formProps} formId={formId} className="mt-2 flex flex-col">
				<FormField fieldType="text" name="linkedinUrl" label="Linkedin URL" clearable />
			</FormWrapper>
			{isPending && <p className="mt-4 text-body-sm text-neutral-3-fg">This may take a moment, please wait.</p>}
		</Dialog>
	)
}
