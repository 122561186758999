import { type RouteObject } from 'react-router'
import { downloadAction, indexLoader, IndexView, newAction } from './routes/index'
import { NewModal } from './views/components'

export default {
	id: 'contacts-enrich',
	path: 'waterfall-enrichment',
	handle: {
		layout: {
			enableHeader: true,
		},
		crumb: () => [
			{
				label: 'Waterfall enrichment',
				icon: 'share-knowledge',
			},
		],
	},
	children: [
		{
			path: '',
			element: <IndexView />,
			loader: indexLoader,
			handle: {
				layout: {
					fullWidth: true,
				},
			},
			children: [
				{
					path: 'new',
					action: newAction,
					element: <NewModal />,
				},
				{
					path: 'download',
					action: downloadAction,
				},
			],
		},
	],
} satisfies RouteObject
