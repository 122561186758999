import { useSearchParams } from 'react-router'
import { type z } from 'zod'
import { CompanyTransactionAPISchema } from '#src/api/icp/admin/company/schemas'
import { EmptyStateCard } from '#src/components'
import ContactAvatar from '#src/components/chat/avatar'
import { PageLayout } from '#src/components/ui/PageLayout'
import { PaginatedTable } from '#src/components/ui/PaginatedTable'
import { CustomTooltip } from '#src/components/ui/tooltip'
import TransactionTypeChip from '#src/routes/admin/company/views/components/TransactionTypeChip'
import { type CompanySchema } from '#src/routes/company/schema'
import { PaginatedAPIResSchema } from '#src/schemas/global'
import { formatDateToReadableString } from '#src/utils/date'
import { formatAvatarAbbr } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

const DataType = PaginatedAPIResSchema(CompanyTransactionAPISchema)

type Props = {
	company: z.infer<typeof CompanySchema>
	data: z.infer<typeof DataType>
}

const CompanyCreditsUsage = (props: Props) => {
	const { company, data } = props
	const [searchParams, setSearchParams] = useSearchParams()

	return (
		<PageLayout
			title="Credits usage"
			actions={[
				{
					icon: 'add',
					title: 'Add credits',
					to: {
						pathname: routes.admin.company.addCredits({ companyId: company.id }),
						search: searchParams.toString(),
					},
				},
			]}
			outlet={false}
		>
			{data.data.length ? (
				<PaginatedTable
					headerVariant="gray"
					data={data.data}
					currentPage={data.meta.current_page}
					totalPages={data.meta.last_page}
					onPageChange={page => setSearchParams({ tab: 'credits_usage', page: (page + 1).toString() })}
					columns={[
						{
							name: 'id',
							heading: '#',
							render: transaction => transaction.id,
						},
						{
							name: 'amount',
							heading: 'Amount',
							render: transaction => transaction.amount,
						},
						{
							name: 'type',
							heading: 'Type',
							render: transaction => <TransactionTypeChip type={transaction.transactionType} />,
						},
						{
							name: 'comment',
							heading: 'Comment',
							render: transaction => (
								<CustomTooltip label={transaction.comment ?? '-'}>
									<div className="w-full max-w-[500px] truncate">{transaction.comment ?? '-'}</div>
								</CustomTooltip>
							),
						},
						{
							name: 'created_by',
							heading: 'Created by',
							render: transaction => (
								<div className="flex items-center gap-2 text-body-sm font-medium text-neutral-2-fg">
									<div className="flex items-center gap-2 text-body-sm font-medium text-neutral-2-fg">
										<ContactAvatar
											className="inline-flex shrink-0 items-center justify-center rounded-full"
											variant="gray"
											size="sm"
											initial={formatAvatarAbbr(transaction.createdBy)}
										/>
										{transaction.createdBy}
									</div>
								</div>
							),
						},
						{
							name: 'created_at',
							heading: 'Date',
							render: scenario => formatDateToReadableString(scenario.transactionDate),
						},
					]}
				/>
			) : (
				<EmptyStateCard icon="database" title="Company does not have any transactions yet" />
			)}
		</PageLayout>
	)
}

export default CompanyCreditsUsage
