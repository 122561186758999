import { Form, useLoaderData, useLocation } from 'react-router'
import { VALUE_PROPOSITION_MAX_WORDS } from '#src/api/icp/company/value-proposition/constants'
import AlertBanner from '#src/components/AlertBanner'
import { Dialog } from '#src/components/ui/dialog'
import useCompany from '#src/hooks/useCompany'
import { type PublishLoaderRes } from '#src/routes/calibrate/value-proposition/routes'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

export const PublishWarnModal = () => {
	const { companyId } = useCompany()
	const { state } = useLocation() as { state: { redirectPath: string } }
	const {
		data: { wordCount },
	} = useLoaderData<PublishLoaderRes>()

	const formId = 'publishWarnValuePropositionForm'
	const method = 'POST'
	const action = routes.calibrate.valueProposition.publish({ companyId })
	const isPending = useIsPending({ formAction: action, formMethod: method })
	const redirectPath = state?.redirectPath
	const baseUrl = window.location.origin
	const closeTo = `${baseUrl}${state?.redirectPath}`

	return (
		<Dialog
			open={true}
			dialogHeading="Publish before leaving?"
			closeTo={closeTo ?? routes.calibrate.valueProposition.index({ companyId })}
			actions={[
				{
					label: 'Leave',
					type: 'cancel',
					to: redirectPath,
				},
				{
					label: 'Yes, Publish',
					type: 'submit',
					formId,
					disabled: wordCount > VALUE_PROPOSITION_MAX_WORDS,
				},
			]}
			isSubmitting={isPending}
		>
			<Form id={formId} action={action} method={method}>
				{wordCount > VALUE_PROPOSITION_MAX_WORDS && (
					<AlertBanner icon="warning-alt-filled" className="mb-4" variant="error">
						You value proposition is too long to publish it. Please adjust the word count before publishing. Current
						word count: <b>{wordCount}</b>, word count limit: <b>{VALUE_PROPOSITION_MAX_WORDS}</b>.
					</AlertBanner>
				)}
				The value proposition will not be active until it’s published
			</Form>
		</Dialog>
	)
}
