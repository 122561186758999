import { type LoaderFunctionArgs } from 'react-router'
import { verticalsQueries } from '#src/api/icp/company/verticals/queries'
import { client } from '#src/main'
import { userQuery } from '#src/routes/auth/queries'
import { criteriaAssignedQuery } from '#src/routes/calibrate/ecosystem-management/criteria/queries'
import { splitCriteriaByType } from '#src/routes/calibrate/ecosystem-management/criteria/utils'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem-management/ecosystem/queries'
import { checkIsReadOnlySession, validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type LoaderResType = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company, companyId } = await getCompany(params)
	validateRouteParams(params, ['ecosystemId'])

	void client.prefetchQuery({
		...verticalsQueries.list(company.id, Number(params.ecosystemId)),
	})

	const user = await client.fetchQuery(userQuery())
	const ecosystems = await client.fetchQuery(ecosystemsQuery(company.id, { includeCriteriaSandbox: true }))
	const ecosystem = ecosystems?.find(e => e.id === Number(params.ecosystemId))

	const allCriteria = await client.fetchQuery(criteriaAssignedQuery(company.id, params.ecosystemId))
	const { criteria, enrichmentCriteria } = splitCriteriaByType(allCriteria)

	return {
		handle: {
			companyId,
			ecosystemId: params.ecosystemId,
			ecosystemName: ecosystem?.name,
			readOnlySession: checkIsReadOnlySession(user.roles),
		},
		ecosystem,
		ecosystems,
		criteria,
		enrichmentCriteria,
		allCriteria,
		user,
	}
}
