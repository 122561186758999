import { type LoaderFunctionArgs } from 'react-router'
import { statsQueries } from '#src/api/icp/company/stats/queries'
import { orgDataEnrichmentWorkflowsQueries } from '#src/api/organise/data-enrichment/workflows/queries'
import { DATA_ENRICHMENT_JOB_ENRICHERS } from '#src/api/organise/data-enrichment/workflows/schemas'
import { orgListsQueries } from '#src/api/organise/lists/queries'
import { showToast } from '#src/context/ToastContext'
import { client } from '#src/main'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)

	let lraStats = {
		inQueueCount: 0,
		inProgressCount: 0,
		completedCount: 0,
	}
	let cwStats = {
		inQueueCount: 0,
		inProgressCount: 0,
		completedCount: 0,
	}
	let aeStats = {
		inQueueCount: 0,
		inProgressCount: 0,
		completedCount: 0,
	}

	const [lraResponse, cwResponse, aeResponse] = await Promise.allSettled([
		client.fetchQuery(orgListsQueries.statistics(company.id)),
		client.fetchQuery(
			orgDataEnrichmentWorkflowsQueries.statistics(company.id, DATA_ENRICHMENT_JOB_ENRICHERS.Waterfalling),
		),
		client.fetchQuery(
			orgDataEnrichmentWorkflowsQueries.statistics(company.id, DATA_ENRICHMENT_JOB_ENRICHERS.EnrichmentCriteria),
		),
	])
	if (lraResponse.status === 'fulfilled') lraStats = lraResponse.value
	if (cwResponse.status === 'fulfilled') cwStats = cwResponse.value
	if (aeResponse.status === 'fulfilled') aeStats = aeResponse.value

	if (lraResponse.status === 'rejected' || cwResponse.status === 'rejected')
		showToast({
			message: `Failed to load organize statistics`,
			type: 'error',
		})

	return {
		data: {
			stats: await client.fetchQuery(statsQueries.dashboard(company.id)),
			lraStats,
			cwStats,
			aeStats,
		},
	}
}
