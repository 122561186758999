import { type LoaderFunctionArgs } from 'react-router'
import {
	companyChatRoleplaySessionQueries,
	companyChatRoleplaySessionQueriesKeys,
} from '#src/api/icp/company/chat/roleplay/session/queries'
import { SessionStatus } from '#src/api/icp/company/chat/roleplay/session/schemas'
import { client } from '#src/main'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'
import { getUser } from '#src/utils/server/user'
import { restartRoleplaySession } from '../mutations'

export const action = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	validateRouteParams(params, ['conversationId'])
	const { adminSession } = await getUser()

	if (!adminSession)
		throw new Response('Access Denied', {
			status: 403,
			statusText: 'Forbidden',
		})

	const response = await restartRoleplaySession(company.id, params.conversationId)
	const checkStatus = async () => {
		let status = null

		while (status !== SessionStatus.Ready) {
			const statusCheck = await client.fetchQuery({
				...companyChatRoleplaySessionQueries.itemStatus(company.id, response.conversation.id),
				staleTime: 0,
			})
			status = statusCheck.status

			await new Promise(resolve => setTimeout(resolve, 15 * 1000)) // 15 seconds
		}

		return status
	}

	await checkStatus()

	await client.invalidateQueries({
		queryKey: companyChatRoleplaySessionQueriesKeys.all,
	})

	return null
}
