import { PageLayout } from '#src/components/ui/PageLayout'
import { TabContent, Tabs, TabsList, TabTrigger } from '#src/components/ui/tabs'
import CommandsForm from '#src/routes/settings/copilot/views/components/CommandsForm'
import GeneralSettingsForm from '#src/routes/settings/copilot/views/components/GeneralSettingsForm'

export const View = () => {
	return (
		<PageLayout title="Copilot settings">
			<Tabs defaultValue="general">
				<TabsList>
					<TabTrigger value="general">General</TabTrigger>
					<TabTrigger value="default-commands">Default commands</TabTrigger>
				</TabsList>
				<TabContent value="general">
					<GeneralSettingsForm />
				</TabContent>
				<TabContent value="default-commands">
					<CommandsForm />
				</TabContent>
			</Tabs>
		</PageLayout>
	)
}
