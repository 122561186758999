import { type RouteObject } from 'react-router'
import { CopilotView, copilotLoader } from './routes/index'

/**
 * WARNING: This route is used by the Copilot feature.
 * It is referenced in client's CRM's and should not be removed.
 * If need be, please implement a redirect.
 */
export default {
	path: '/copilot/:contactId',
	element: <CopilotView />,
	loader: copilotLoader,
} satisfies RouteObject
