import { type RouteObject } from 'react-router'
import { CompanyDashboardView, companyDashboardLoader } from '#src/routes/company/routes/dashboard'

export default {
	path: '',
	children: [
		{
			index: true,
			element: <CompanyDashboardView />,
			loader: companyDashboardLoader,
		},
	],
} satisfies RouteObject
