import { type ActionFunctionArgs } from 'react-router'
import { showToast } from '#src/context/ToastContext'
import { LoginFormSchema } from '#src/routes/auth/schema'
import { isAuth0Error, login } from '#src/routes/auth/utils'
import { parseFormDataNew } from '#src/utils/forms'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ request }: ActionFunctionArgs) => {
	const formData = await request.formData()

	try {
		const submission = parseFormDataNew(formData, LoginFormSchema)
		const { username, password } = submission.value
		await login(username, password)

		return { ok: true }
	} catch (e) {
		if (isAuth0Error(e)) {
			showToast({
				message: e.error_description ?? e.errorDescription ?? 'Authentication service error',
				type: 'error',
			})
			return { ok: false }
		}
		return await serverFormErrorHandler(e)
	}
}
