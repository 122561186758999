import { Link, type LinkProps } from 'react-router'
import { Chip } from '#src/components/chip'
import { FormFieldTypeChip } from '#src/components/shared/FormFieldTypeChip'

type SelectableCriteriaCardProps = {
	heading: string
	description: string
	ecosystemsInUse: string[]
	fieldType: FormFieldTypeChip
	linkProps: LinkProps
}

export const SelectableCriterionCard = ({
	heading,
	description,
	ecosystemsInUse,
	fieldType,
	linkProps,
}: SelectableCriteriaCardProps) => {
	return (
		<Link {...linkProps}>
			<section className="w-full cursor-pointer rounded border border-neutral-2-bd hover:border-neutral-2-bd-selected">
				<div className="flex items-center justify-between p-6">
					<div className="flex flex-col gap-0.5">
						<h2 className="text-title-sm">{heading}</h2>
						<p className="text-body-md">{description}</p>
					</div>
					<FormFieldTypeChip type={fieldType} />
				</div>
				{!!ecosystemsInUse.length && (
					<div className="flex flex-col items-start gap-2 rounded-b border-t border-neutral-1-bd bg-neutral-2-bg px-6 py-4">
						<span className="text-label-sm">{`Used in ${ecosystemsInUse.length} ${ecosystemsInUse.length > 1 ? 'ecosystems' : 'ecosystem'}:`}</span>
						<div className="flex flex-wrap gap-1">
							{ecosystemsInUse.map(item => (
								<Chip key={item} variant="black">
									{item}
								</Chip>
							))}
						</div>
					</div>
				)}
			</section>
		</Link>
	)
}
