import { type LoaderFunctionArgs } from 'react-router'
import { companyAccountExportQueries } from '#src/api/icp/company/account-export/queries'
import { client } from '#src/main'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	validateRouteParams(params, ['jobId'])

	try {
		const result = await client.fetchQuery(companyAccountExportQueries.itemDownload(company.id, Number(params.jobId)))
		window.location.replace(result.downloadUrl)
		setTimeout(() => window.close(), 5000)
		return { ok: true }
	} catch (e) {
		return await serverFormErrorHandler(e)
	}
}
