import { type RouteObject } from 'react-router'
import { action as settingsCopilotCommandEditAction } from './actions/command-edit'
import { action as settingsCopilotGeneralEditAction } from './actions/general-edit'
import { loader as settingsCopilotLoader } from './loaders'
import { View as SettingsCopilotView } from './views'

export default {
	path: 'copilot',
	handle: {
		layout: {
			enableHeader: true,
		},
		crumb: () => [
			{
				label: 'Copilot settings',
				icon: 'chat',
			},
		],
	},
	children: [
		{
			index: true,
			element: <SettingsCopilotView />,
			loader: settingsCopilotLoader,
		},
		{
			path: 'default-commands/:commandId',
			action: settingsCopilotCommandEditAction,
		},
		{
			path: 'general-settings',
			action: settingsCopilotGeneralEditAction,
		},
	],
} satisfies RouteObject
