import { type ReactNode } from 'react'
import Markdown from '#src/components/markdown'
import { InlineError } from '#src/components/ui/InlineError'
import { InlineSpinner } from '#src/components/ui/InlineSpinner'

type PersonaMappingItemProps = {
	id: string
	heading: ReactNode
	text?: string | null
	isGenerating: boolean
	hasCrashed: boolean
}

export const PersonaAIGeneratingItem = ({ id, heading, text, isGenerating, hasCrashed }: PersonaMappingItemProps) => {
	const renderContent = (): ReactNode => {
		if (text) {
			return <Markdown>{text}</Markdown>
		}

		if (isGenerating) {
			return <InlineSpinner textVariant="output" />
		}

		if (hasCrashed) {
			return <InlineError textVariant="output" size="md" />
		}

		return '-'
	}

	return (
		<div className="space-y-6">
			<h4 className="flex scroll-mt-4 items-center gap-2 text-title-md" id={id}>
				{heading}
			</h4>
			{renderContent()}
		</div>
	)
}
