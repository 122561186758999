import { type ActionFunctionArgs } from 'react-router'
import { orgListsCsvMutations } from '#src/api/organise/lists-csv/mutations'
import { LeadResearchAgentTemplateDownloadFormSchema } from '#src/api/organise/lists-csv/schemas'
import { makeDownloadableFromRes } from '#src/utils/files'
import { parseFormDataNew } from '#src/utils/forms'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ request }: ActionFunctionArgs) => {
	const formData = await request.formData()

	try {
		const submission = parseFormDataNew(formData, LeadResearchAgentTemplateDownloadFormSchema)
		const res = await orgListsCsvMutations.downloadTemplate(submission.value)
		await makeDownloadableFromRes(res, `${submission.value.type}-template`)

		return {
			ok: true,
		}
	} catch (e) {
		return await serverFormErrorHandler(e)
	}
}
