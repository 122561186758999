import { forwardRef, type ChangeEvent } from 'react'
import { NumberInput, type NumberInputProps } from '#src/components/ui/NumberInput'
import { Slider } from '#src/components/ui/Slider'
import { cn } from '#src/utils/misc'

export type SliderInputProps = NumberInputProps & {
	value?: number
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void
	min: number
	max: number
	step?: number
}

export const SliderInput = forwardRef<HTMLDivElement, SliderInputProps>(
	({ value = 0, onChange, min = 0, max = 100, step = 1, ...props }, ref) => {
		const handleSliderChange = (val: number[]) => {
			onChange?.({
				target: { value: val[0] },
			} as unknown as ChangeEvent<HTMLInputElement>)
		}
		const handleNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
			const val = e.target.valueAsNumber

			if (val > max) return max
			if (val < min) return min

			onChange?.(e)
		}

		return (
			<div ref={ref} className={cn('flex items-center gap-4')}>
				<Slider min={min} max={max} step={step} value={[value]} onValueChange={handleSliderChange} />
				<div className="min-w-[65px]">
					<NumberInput {...props} value={value} min={min} max={max} step={step} onChange={handleNumberChange} />
				</div>
			</div>
		)
	},
)

SliderInput.displayName = 'SliderInput'
