import { type ReactNode, useState } from 'react'
import { createSearchParams, useSearchParams } from 'react-router'
import { type z } from 'zod'
import { type FilterAPISchema } from '#src/api/icp/company/chat/roleplay/session/schemas'
import { Search } from '#src/components/chat/search'
import FilterSelect from '#src/components/FilterSelect'
import { DateRangePicker } from '#src/components/ui/DatePicker'
import { Icon } from '#src/components/ui/icon'
import { CustomTooltip } from '#src/components/ui/tooltip'
import useDebounce from '#src/hooks/useDebounce'
import { cn } from '#src/utils/misc'

type SelectFilter = {
	type?: 'select'
	name: string
	label: string
	multiple?: boolean
	clearable?: boolean
}

type DateRangeFilter = {
	type: 'dateRange'
	name?: [string, string]
	label: string
	multiple?: boolean
}

type SearchFilter = {
	type: 'search'
	name: string
	label: string
}

type Filter = SelectFilter | DateRangeFilter | SearchFilter

type Props = {
	minimalistic?: boolean
	selected: Record<string, string | string[]>
	values: Record<string, z.infer<typeof FilterAPISchema>[] | { value: string; label: ReactNode }[]>
	filters: Filter[]
	total?: number
}

const FiltersSection = (props: Props) => {
	const { selected, values, filters, minimalistic, total } = props

	// it checks for provided filters and selected one, matches them to find out if any of visible filters are selected
	const hasFilters = !!Object.entries(
		Object.entries(selected).filter(([key, val]) => {
			const filterNames = filters
				.map(filter => filter.name)
				.filter(name => name !== undefined)
				.flat()
			return filterNames.includes(key) && val !== null
		}),
	).length
	const [, setSearchParams] = useSearchParams()

	const onFilterChange = (name: string | [string, string], value: string[]) => {
		if (Array.isArray(name)) {
			const [startKey, endKey] = name
			const payload = Object.fromEntries(
				Object.entries({
					...selected,
					[startKey]: value[0] || '',
					[endKey]: value[1] || '',
				}).filter(([, val]) => val),
			)
			setSearchParams(`?${createSearchParams(payload as Record<string, string>).toString()}`)
		} else {
			const payload = Object.fromEntries(
				Object.entries({
					...selected,
					[name]: value,
				}).filter(([, val]) => val.length > 0),
			)
			setSearchParams(`?${createSearchParams(payload as Record<string, string[]>).toString()}`)
		}
	}

	const [search, setSearch] = useState<string>((selected['search'] as string | undefined) ?? '')
	useDebounce(search, 500, e => {
		onFilterChange('search', e === '' ? [] : [e])
	})

	const handleSearch = (query: string) => {
		setSearch(query.toLowerCase())
	}

	return (
		<div className="flex flex-1 flex-wrap items-center gap-2">
			{!minimalistic && (
				<CustomTooltip label={total !== undefined ? `${total} records` : 'Filters'}>
					<Icon name="filter" size="sm" className="text-neutral-2-fg" />
				</CustomTooltip>
			)}
			{filters.map(filter => {
				switch (true) {
					case filter.type === 'dateRange': {
						const [startKey, endKey] = Array.isArray(filter.name) ? filter.name : ['dateFrom', 'dateTo']

						return (
							<DateRangePicker
								key={`${startKey}-${endKey}`}
								value={[selected[startKey] as string, selected[endKey] as string | undefined]}
								onChange={range => onFilterChange([startKey, endKey], range?.map(date => date || '') || ['', ''])}
								placeholder={filter.label}
								className={cn(
									'h-8.5 group flex w-fit items-center gap-1 rounded border bg-transparent py-2 pl-3 pr-2 text-body-md text-button-sm text-neutral-1-fg outline-none transition-colors radix-state-open:border-brand-1-bd',
									selected[startKey] || selected[endKey]
										? 'border-brand-1-bd text-brand-1-fg'
										: 'border-neutral-1-bd text-neutral-1-fg',
								)}
								triggerRightIconProps={{
									name: 'carret-down',
									className: cn(
										'group-radix-state-open:rotate-180',
										selected[startKey] || selected[endKey] ? 'text-brand-1-fg' : 'text-neutral-1-fg',
									),
								}}
								triggerLeftIconProps={null}
								placeholderClassName="text-neutral-1-fg"
							/>
						)
					}
					case filter.type === 'search': {
						return (
							<Search
								key={filter.name}
								value={search}
								onSearch={handleSearch}
								placeholder={filter.label}
								className={cn(minimalistic ? 'flex-1' : 'w-full max-w-[280px]')}
							/>
						)
					}
					default: {
						return (
							<FilterSelect
								key={filter.name}
								label={filter.label}
								value={selected[filter.name] as string | string[] | undefined}
								multiple={filter.multiple}
								options={
									values[filter.name]?.map(item =>
										typeof item === 'string'
											? {
													value: item,
													label: item,
												}
											: item,
									) ?? []
								}
								onChange={val => onFilterChange(filter.name, val)}
								clearable={filter.clearable}
							/>
						)
					}
				}
			})}
			{!minimalistic && (
				<button
					type="submit"
					name="intent"
					value="clear-all"
					disabled={!hasFilters}
					className={cn(
						'text-button-sm',
						hasFilters ? 'text-link hover:text-link-hover active:text-link-pressed' : 'text-neutral-1-fg-disabled',
					)}
					onClick={e => {
						e.preventDefault()
						setSearchParams()
						setSearch('')
					}}
				>
					Reset filters
				</button>
			)}
		</div>
	)
}

export default FiltersSection
