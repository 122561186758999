import { useFetcher } from 'react-router'
import { type z } from 'zod'
import { type CompanyUserAPISchema } from '#src/api/icp/company/user/schemas'
import DeleteConfirm from '#src/components/delete-confirm'
import { Button } from '#src/components/ui/button'
import useCompany from '#src/hooks/useCompany'
import { routes } from '#src/utils/routes'

type ResetPasswordFormProps = {
	user: z.infer<typeof CompanyUserAPISchema>
}

export const ResetPasswordForm = ({ user }: ResetPasswordFormProps) => {
	const { companyId } = useCompany()
	const fetcher = useFetcher()
	const action = routes.settings.team.reset({ companyId, userId: user.id })
	const method = 'POST'

	return (
		<DeleteConfirm
			trigger={
				<Button type="submit" disabled={fetcher.state !== 'idle'} className="mt-4">
					Reset Password
				</Button>
			}
			formId={`resetPassword-${user.id}`}
			method={method}
			action={action}
			actionTitle="Reset"
			title="Reset Password?"
			description={
				<>
					Are you sure you want to reset password for <b>{user.email}</b>?
					<p className="mt-2">An email will be sent with further instructions.</p>
				</>
			}
		/>
	)
}
