import { zodResolver } from '@hookform/resolvers/zod'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useFetcher } from 'react-router'
import { type z } from 'zod'
import { companyEcosystemsQueries } from '#src/api/icp/company/ecosystems/queries'
import { companyPersonaQueries } from '#src/api/icp/company/persona/queries'
import { CONTACT_FINDER_SETTINGS_TYPE, FILE_TYPES } from '#src/api/organise/lists/constants'
import { LeadResearchAgentJobFormSchema } from '#src/api/organise/lists/schemas'
import { EmptyStateCard } from '#src/components'
import AlertBanner from '#src/components/AlertBanner'
import { Chip } from '#src/components/chip'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import LoaderRequiredComponent from '#src/components/LoaderRequiredComponent'
import { FormFieldTypeChip } from '#src/components/shared'
import { Button } from '#src/components/ui/button'
import { Dialog } from '#src/components/ui/dialog'
import { FormStepper } from '#src/components/ui/FormStepper'
import { FullPageAccordion } from '#src/components/ui/FullPageAccordion'
import { Icon } from '#src/components/ui/icon'
import { type MultiSelectOption } from '#src/components/ui/MultiSelect'
import { Surface } from '#src/components/ui/surface'
import { Switch } from '#src/components/ui/switch'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { FORM_STEPS } from '#src/routes/organize/lead-research-agent/constants'
import {
	type LRACreateActionRes,
	type LRACreateLoaderRes,
} from '#src/routes/organize/lead-research-agent/routes/create'
import { type LRADownloadTemplateActionRes } from '#src/routes/organize/lead-research-agent/routes/downloadTemplate'
import LRAPlaysCheckbox from '#src/routes/organize/lead-research-agent/views/components/LRAPlaysCheckbox'
import { type APIFieldType } from '#src/schemas/global'
import { KNOWLEDGE_LINKS } from '#src/utils/enumerations'
import { cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

export const LeadResearchAgentJobCreateModal = () => {
	const { companyId } = useCompany()

	return (
		<LoaderRequiredComponent<LRACreateLoaderRes>
			route={routes.leadResearchAgent.create({ companyId })}
			component={ModalInner}
		/>
	)
}

const FORM_NAME = 'lra-job-create'

const ModalInner = (props: LRACreateLoaderRes) => {
	const {
		data: { ecosystems, plays, tags },
	} = props
	const { company, companyId } = useCompany()
	const actionFetcher = useFetcher<LRACreateActionRes>()
	const actionData = actionFetcher.data

	const [step, setStep] = useState<FORM_STEPS>(FORM_STEPS.File)

	const form = useForm<z.infer<typeof LeadResearchAgentJobFormSchema>>({
		resolver: zodResolver(LeadResearchAgentJobFormSchema),
		defaultValues: {
			companyId: company.id,
			type: FILE_TYPES.Accounts,
			verticals: ecosystems.map(ecosystem => ecosystem.verticals.map(vertical => String(vertical.id))).flat(),
			enrichmentCriterias: [],
			plays: plays.filter(play => play.inCrm).map(play => String(play.id)),
			contactFinderEnabled: true,
			emailVerificationEnabled: true,
			contactEnrichingEnabled: true,
			contactFinderSettings: {
				type: CONTACT_FINDER_SETTINGS_TYPE.ContactsPerAccount,
				contactsPerAccount: 1,
			},
			contactWaterfallingEnabled: false,
			comment: null,
			tags: [],
		},
		mode: 'onSubmit',
	})

	/** @description this method fetches personatypes and expertises, required for contactFinderSettings ContactsPerPersonas form values */
	const { data: personaExpertiseAndTypesData, isLoading: personaExpertiseAndTypesLoading } = useQuery({
		...companyPersonaQueries.expertiseAndType(
			company.id,
			form.watch('verticals').map(i => Number(i)),
		),
		enabled:
			step === FORM_STEPS.Settings &&
			form.watch('contactFinderSettings.type') === CONTACT_FINDER_SETTINGS_TYPE.ContactsPerPersonas,
	})

	/** @description this effect sets required contactsPerPersonas default values */
	useEffect(() => {
		form.setValue(
			'contactFinderSettings.contactsPerPersonas',
			{},
			{ shouldDirty: false, shouldValidate: false, shouldTouch: false },
		)
		if (personaExpertiseAndTypesData) {
			// this maps response to default values of 1
			form.setValue(
				'contactFinderSettings.contactsPerPersonas',
				Object.fromEntries(
					Object.entries(personaExpertiseAndTypesData).map(([key, val]) => [
						key,
						Object.fromEntries(val.map(i => [i, 0])),
					]),
				),
				{ shouldDirty: false, shouldValidate: false, shouldTouch: false },
			)
		}
	}, [form, personaExpertiseAndTypesData])

	/** @description this method fetches criterias data, which is needed for Criterias step */
	const { data: criteriasData, isLoading: criteriasDataLoading } = useQuery({
		...companyEcosystemsQueries.criteria(
			company.id,
			form.watch('verticals').map(i => Number(i)),
			'enrichment',
		),
		enabled: step === FORM_STEPS.Criteria,
	})

	useEffect(() => {
		form.setValue(
			'enrichmentCriterias',
			(criteriasData ?? []).map(item => item.id),
			{ shouldDirty: false, shouldValidate: false, shouldTouch: false },
		)
	}, [form, criteriasData])

	useEffect(() => {
		const failedStep = actionData && 'failedStep' in actionData ? actionData?.failedStep : undefined
		if (failedStep) {
			setStep(failedStep)
		}
		const errors = actionData && 'errors' in actionData ? actionData.errors : undefined
		if (errors) {
			Object.entries(errors).forEach(([fieldName, errorMessages]) => {
				form.setError(fieldName as keyof z.infer<typeof LeadResearchAgentJobFormSchema>, {
					type: 'server',
					message: errorMessages.join(', '),
				})
			})
		}
	}, [actionData, form])

	const typeWatch = form.watch('type')
	const contactFinderEnabledWatch = form.watch('contactFinderEnabled')
	const fileWatch = form.watch('file')

	useEffect(() => {
		form.setValue('contactWaterfallingEnabled', typeWatch === FILE_TYPES.AccountsAndContacts)
		form.setValue('contactFinderEnabled', false)
	}, [form, typeWatch])

	useEffect(() => {
		form.setValue('contactWaterfallingEnabled', contactFinderEnabledWatch)
	}, [form, contactFinderEnabledWatch])

	useEffect(() => {
		form.setValue('title', fileWatch instanceof File ? fileWatch.name : '')
	}, [form, fileWatch])

	const handleContinue = async () => {
		switch (step) {
			case FORM_STEPS.File: {
				await form.trigger('type')
				await form.trigger('file')
				if (!('type' in form.formState.errors) && !('file' in form.formState.errors)) {
					setStep(FORM_STEPS.Verticals)
				}
				break
			}
			case FORM_STEPS.Verticals: {
				await form.trigger('verticals')
				if (!('verticals' in form.formState.errors)) {
					setStep(FORM_STEPS.Criteria)
				}
				break
			}
			case FORM_STEPS.Criteria: {
				await form.trigger('enrichmentCriterias')
				if (!('enrichmentCriterias' in form.formState.errors)) {
					setStep(FORM_STEPS.Settings)
				}
				break
			}
			case FORM_STEPS.Settings: {
				// this triggers all nested values inside, thank you react hook forms <3
				await form.trigger('contactFinderSettings')
				if (!('contactFinderSettings' in form.formState.errors)) {
					setStep(FORM_STEPS.AdditionalInfo)
				}
				break
			}
			case FORM_STEPS.AdditionalInfo: {
				await form.trigger('title')
				await form.trigger('comment')
				if (!('title' in form.formState.errors) && !('comment' in form.formState.errors)) {
					const formEl = document.getElementById(FORM_NAME) as HTMLFormElement
					formEl.requestSubmit()
				}
				break
			}
		}
	}

	const handleCancel = () => {
		switch (step) {
			case FORM_STEPS.File:
				closeModal(MODAL_NAME.LeadResearchAgentJobCreate)
				break
			case FORM_STEPS.Verticals:
				setStep(FORM_STEPS.File)
				break
			case FORM_STEPS.Criteria:
				setStep(FORM_STEPS.Verticals)
				break
			case FORM_STEPS.Settings:
				setStep(FORM_STEPS.Criteria)
				break
			case FORM_STEPS.AdditionalInfo:
				setStep(FORM_STEPS.Settings)
				break
		}
	}

	const downloadFetcher = useFetcher<LRADownloadTemplateActionRes>()
	const handleDownload = (type: FILE_TYPES) => {
		void downloadFetcher.submit(
			{ type },
			{
				method: 'POST',
				action: routes.leadResearchAgent.downloadTemplate({ companyId }),
			},
		)
	}

	const { closeModal } = useModals()

	return (
		<Dialog
			size="xl"
			defaultOpen={true}
			onOpenChange={isOpen => !isOpen && closeModal(MODAL_NAME.LeadResearchAgentJobCreate)}
			dialogHeading="Create Lead Research Agent Job"
			{...(step === FORM_STEPS.File && {
				footerInfo: (
					<span className="text-neutral-4-fg">
						{`Discover more tips of how to use LRA launcher `}
						<a href={KNOWLEDGE_LINKS.LRA_HOW_TO} target="__blank" className="text-link underline">
							Learn more
						</a>
					</span>
				),
			})}
			{...(step === FORM_STEPS.Verticals && {
				footerInfo: (
					<>
						<b>{form.watch('verticals')?.length ?? 0}</b> verticals selected
					</>
				),
			})}
			isSubmitting={actionFetcher.state === 'submitting'}
			actions={[
				{
					type: 'cancel',
					label: 'Back',
					onClick: e => {
						e.preventDefault()
						handleCancel()
					},
				},
				{
					type: 'submit',
					formId: FORM_NAME,
					label: step === FORM_STEPS.AdditionalInfo ? 'Start' : 'Continue',
					onClick: e => {
						e.preventDefault()
						void handleContinue()
					},
				},
			]}
		>
			<FormWrapper
				formId={FORM_NAME}
				formProps={form}
				method="POST"
				action={routes.leadResearchAgent.create({ companyId })}
				encType="multipart/form-data"
				className="flex flex-col gap-6"
				fetcher={actionFetcher}
			>
				<FormStepper
					active={step}
					steps={[
						{ name: FORM_STEPS.File, label: 'Import file' },
						{ name: FORM_STEPS.Verticals, label: 'Select ecosystems' },
						{ name: FORM_STEPS.Criteria, label: 'Select criteria' },
						{ name: FORM_STEPS.Settings, label: 'Job settings' },
						{ name: FORM_STEPS.AdditionalInfo, label: 'Additional info' },
					]}
				/>
				{step === FORM_STEPS.File && (
					<>
						<FormField
							fieldType="radioCards"
							disableLabel
							name="type"
							options={[
								{
									value: FILE_TYPES.Accounts,
									label: 'Accounts',
									icon: 'portfolio',
								},
								{
									value: FILE_TYPES.AccountsAndContacts,
									label: 'Accounts + Contacts',
									icon: 'user-multiple',
								},
							]}
						/>
						<FormField
							fieldType="file"
							name="file"
							accept={{ 'text/csv': ['.csv'] }}
							label="Import CSV"
							infoContent={
								<div className="flex flex-col items-center gap-8">
									<div className="flex flex-col gap-4">
										<Icon name="upload-thin" className="block h-10 w-10 text-brand-2-fg" />
										<div className="flex flex-col gap-1">
											<p className="text-center text-title-sm text-neutral-1-fg">Drag your CSV file here</p>
											<p className="text-center text-body-md text-neutral-2-fg">File must-have columns:</p>
										</div>
									</div>
									<div className="flex flex-row items-center justify-center gap-1">
										{form.watch('type') === FILE_TYPES.Accounts ? (
											<>
												<Chip variant="blue">Account Name</Chip>
												<Icon name="add" className="text-neutral-4-fg" />
												<Chip variant="blue">Domain</Chip>
											</>
										) : (
											<>
												<Chip variant="blue">Account Name</Chip>
												<Icon name="add" className="text-neutral-4-fg" />
												<Chip variant="blue">Domain</Chip>
												<Icon name="or-operation" className="size-6 text-neutral-4-fg" />
												<div className="flex flex-col gap-2">
													<Chip variant="orange">Contact LinkedIn URL</Chip>
													<div className="flex flex-row items-center justify-center gap-1">
														<Chip variant="indigo">First Name</Chip>
														<Icon name="add" className="text-neutral-4-fg" />
														<Chip variant="indigo">Last Name</Chip>
													</div>
												</div>
											</>
										)}
									</div>
									<div className="flex flex-col gap-2">
										<Button type="button">Upload</Button>
										<Button
											onClick={e => {
												e.preventDefault()
												e.stopPropagation()
												handleDownload(form.getValues('type'))
											}}
											type="button"
											variant="ghost"
											size="sm"
											disabled={downloadFetcher.state !== 'idle'}
										>
											Download template
										</Button>
									</div>
								</div>
							}
						/>
					</>
				)}
				{step === FORM_STEPS.Verticals && (
					<FormField
						name="verticals"
						fieldType="checkboxTree"
						searchPlaceholder="Find verticals"
						options={ecosystems.map(ecosystem => ({
							id: String(ecosystem.id),
							label: ecosystem.name,
							children: ecosystem.verticals.map(vertical => ({
								id: String(vertical.id),
								label: vertical.name,
							})),
						}))}
						noItemsText="No Ongoing Verticals"
					/>
				)}
				{step === FORM_STEPS.Criteria && (
					<>
						{criteriasDataLoading ? (
							<Surface className="flex flex-row items-center gap-2 p-4">
								<Icon name="loading-sm" className="animate-spin" size="sm" />
								<p className="flex-1">Fetching criteria for selected vertical&apos;s ...</p>
							</Surface>
						) : criteriasData?.length ? (
							<div className="flex flex-col gap-3">
								{criteriasData.map(criteria => (
									<div
										key={`criteria-${criteria.id}`}
										className="flex flex-col overflow-hidden rounded border border-neutral-1-bd"
									>
										<div className="flex flex-row items-center p-6">
											<div className="flex-1">
												<Switch
													labelText={<span className="text-title-sm">{criteria.name}</span>}
													checked={form.watch('enrichmentCriterias').includes(criteria.id)}
													onCheckedChange={val =>
														form.setValue(
															'enrichmentCriterias',
															val
																? [...form.watch('enrichmentCriterias'), criteria.id]
																: form.watch('enrichmentCriterias').filter(criteriaId => criteriaId !== criteria.id),
														)
													}
												/>
											</div>
											<FormFieldTypeChip type={criteria.type as APIFieldType} />
										</div>
										<div className="flex flex-col gap-1 bg-neutral-2-bg px-6 py-4">
											<p className="text-label-sm text-neutral-2-fg">
												Used in {criteria.ecosystems.length} ecosystem{criteria.ecosystems.length > 1 ? 's' : ''}:
											</p>
											<div className="flex flex-row flex-wrap gap-1">
												{criteria.ecosystems.map(ecosystem => (
													<Chip key={`criteria-${criteria.id}-ecosystem-${ecosystem.id}`} variant="black">
														{ecosystem.name}
													</Chip>
												))}
											</div>
										</div>
									</div>
								))}
							</div>
						) : (
							<EmptyStateCard
								title="No criteria found"
								description="No criteria found for selected verticals in previous step"
								icon="information-filled"
							/>
						)}
					</>
				)}
				{step === FORM_STEPS.Settings && (
					<>
						{form.watch('type') === FILE_TYPES.Accounts && (
							<FormField
								name="contactFinderEnabled"
								fieldType="cardSwitch"
								size="sm"
								disableLabel
								heading="Contact finder"
								description="Finds contacts for accounts according to your IBP mapping"
								innerContent={
									<FormField
										name="contactFinderSettings.type"
										disableLabel
										fieldType="radio"
										options={[
											{
												value: CONTACT_FINDER_SETTINGS_TYPE.ContactsPerAccount,
												label: 'Contacts per account',
												content: (
													<FormField
														name="contactFinderSettings.contactsPerAccount"
														fieldType="number"
														disableLabel
														placeholder="Enter number..."
														min={1}
														max={5}
													/>
												),
											},
											{
												value: CONTACT_FINDER_SETTINGS_TYPE.ContactsPerPersonas,
												label: 'Specify the number of contacts per persona expertise and type',
												content: (
													<>
														{personaExpertiseAndTypesLoading ? (
															<Surface className="flex flex-row items-center gap-2 p-4">
																<Icon name="loading-sm" className="animate-spin" size="sm" />
																<p className="flex-1">
																	Fetching selected vertical&apos;s personas types and expertises ...
																</p>
															</Surface>
														) : !Object.entries(form.watch('contactFinderSettings.contactsPerPersonas') ?? {})
																.length ? (
															<Surface className="flex flex-row items-center gap-2 p-4">
																<Icon name="warning-alt" size="sm" />
																<p className="flex-1">No personas found for selected verticals</p>
															</Surface>
														) : (
															<>
																<FullPageAccordion
																	defaultExpandedSections={[]}
																	contentClassName="py-0 px-8"
																	sections={Object.entries(form.watch('contactFinderSettings.contactsPerPersonas')).map(
																		([expertise]) => {
																			const sum = Object.values(
																				form.watch(`contactFinderSettings.contactsPerPersonas.${expertise}`),
																			).reduce((curr, a) => curr + (a === -Infinity ? 0 : a), 0)
																			return {
																				id: expertise,
																				header: (
																					<div className="flex flex-1 flex-row justify-between">
																						<p>{expertise}</p>
																						<p className="text-brand-1-fg">{sum}</p>
																					</div>
																				),
																				content: (
																					<div className="flex flex-col">
																						{Object.entries(
																							form.watch(`contactFinderSettings.contactsPerPersonas.${expertise}`),
																						).map(([personaType]) => (
																							<div
																								className={cn(
																									'flex flex-row items-center justify-between border-t border-neutral-2-bd py-1.5 first:border-t-0',
																								)}
																								key={`${expertise}.${personaType}`}
																							>
																								<div>{personaType}</div>
																								<div className="w-[250px]">
																									<FormField
																										name={`contactFinderSettings.contactsPerPersonas.${expertise}.${personaType}`}
																										fieldType="number"
																										disableLabel
																										placeholder=""
																										min={0}
																										max={3}
																									/>
																								</div>
																							</div>
																						))}
																					</div>
																				),
																			}
																		},
																	)}
																/>
															</>
														)}
													</>
												),
											},
										]}
									/>
								}
							/>
						)}
						<FormField
							size="sm"
							name="contactWaterfallingEnabled"
							disableLabel
							fieldType="cardSwitch"
							heading="Contact waterfalling"
							description="Combines multiple vendors to find email and phone number"
							locked={form.watch('type') === FILE_TYPES.Accounts}
							{...(form.watch('type') === FILE_TYPES.Accounts && {
								tooltip: 'Contact Waterfalling follows rules of Contact Finder',
							})}
						/>
						<FormField
							name="emailVerificationEnabled"
							fieldType="cardSwitch"
							size="sm"
							disableLabel
							heading="Email verification"
							description="Verifies found emails with 3rd party providers"
							disableField={form.watch('contactWaterfallingEnabled') === false}
							{...(form.watch('contactWaterfallingEnabled') === false && {
								tooltip: 'Email verification requires Contact Waterfalling to be enabled',
							})}
						/>
						<FormField
							name="contactEnrichingEnabled"
							fieldType="cardSwitch"
							size="sm"
							disableLabel
							heading="Persona messaging enrichment"
							description="Enrich contacts with the persona mapping data points to unlock the copilot agent and AI plays"
							disableField={form.watch('type') === FILE_TYPES.Accounts && form.watch('contactFinderEnabled') === false}
							{...(form.watch('type') === FILE_TYPES.Accounts &&
								form.watch('contactFinderEnabled') === false && {
									tooltip: 'Persona messaging enrichment requires Contact Finder to be enabled',
								})}
							innerContent={<LRAPlaysCheckbox options={plays} />}
						/>
					</>
				)}
				{step === FORM_STEPS.AdditionalInfo && (
					<>
						<AlertBanner icon="information-filled">Additional info does not affect the LRA job results</AlertBanner>
						<FormField name="title" fieldType="text" label="Name" placeholder="Enter job name" clearable />
						<FormField
							name="comment"
							fieldType="textarea"
							label="Note"
							placeholder="You can type extra notes about your job"
							rows={10}
						/>
						<FormField
							fieldType="multiselect"
							name="tags"
							label="Tags"
							options={tags as MultiSelectOption[]}
							enableCreate
						/>
					</>
				)}
			</FormWrapper>
		</Dialog>
	)
}
