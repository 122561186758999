import { useCallback, useMemo } from 'react'
import { Link, useLoaderData, useNavigate } from 'react-router'
import { type z } from 'zod'
import { CRITERION_ANSWERS_ENRICHING } from '#src/api/icp/company/criteria/constants'
import { type VerticalListAPISchema } from '#src/api/icp/company/verticals/schemas'
import Role from '#src/components/Role'
import { getQueueStatus, QueueStatusLabel } from '#src/components/shared'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { LabeledValue } from '#src/components/ui/LabeledValue'
import { CustomTooltip } from '#src/components/ui/tooltip'
import useCompany from '#src/hooks/useCompany'
import {
	type CriterionAPISchema,
	type CriterionEnrichedAnswerAPISchema,
	type CriterionQuestionAPISchema,
} from '#src/routes/calibrate/ecosystem-management/criteria/schema'
import { renderCriterionAnswerValue } from '#src/routes/calibrate/ecosystem-management/criteria/utils'
import { autofocusFieldMutation } from '#src/routes/calibrate/ecosystem-management/verticals/autofocus'
import { type LoaderResType } from '#src/routes/calibrate/ecosystem-management/verticals/loaders'
import { generateCriterionFieldId } from '#src/routes/calibrate/ecosystem-management/verticals/utils'
import {
	CancelEnrichmentForm,
	CreateEnrichmentForm,
} from '#src/routes/calibrate/ecosystem-management/verticals/views/components'
import { formatDateToReadableString } from '#src/utils/date'
import { QUEUE_STATUS } from '#src/utils/enumerations'
import { routes } from '#src/utils/routes'

export const useCriteriaSandboxGroup = (verticals: z.infer<typeof VerticalListAPISchema>) => {
	const {
		handle: { ecosystemId, readOnlySession },
		allCriteria,
		user,
	} = useLoaderData<LoaderResType>()
	const { companyId } = useCompany()
	const navigate = useNavigate()

	const hasActiveEnrichment = useMemo(
		() => (criterionId: z.infer<typeof CriterionQuestionAPISchema>['id']) =>
			verticals.some(vertical =>
				vertical.enrichedAnswers?.some(
					answer => answer.criterionId === criterionId && CRITERION_ANSWERS_ENRICHING.includes(answer.status),
				),
			),
		[verticals],
	)

	const criterionVerticalsHasAtLeastOneAnswer = useCallback(
		(criterionId: z.infer<typeof CriterionAPISchema>['criterion']['id']) =>
			verticals.some(vertical => vertical.answers.some(answer => answer.criterionId === criterionId)),
		[verticals],
	)

	const header = useMemo(
		() => ({
			collapsible: {
				options: { sticky: true },
				columns: [
					{
						children: (
							<section className="flex w-full items-center justify-between">
								<p className="flex items-center gap-1 text-body-md font-semibold text-neutral-2-fg">
									Criteria
									<span className="font-normal text-neutral-3-fg">{allCriteria.length}</span>
								</p>
								{!readOnlySession && (
									<div className="flex items-center">
										<CustomTooltip label="Add criterion">
											<Button variant="icon" size="icon" asChild>
												<Link
													to={routes.calibrate.criteria.list({ companyId, ecosystemId, criterionType: 'enrichment' })}
												>
													<Icon name="add" size="sm" />
												</Link>
											</Button>
										</CustomTooltip>
										<CustomTooltip label="Edit criteria">
											<Button variant="icon" size="icon" asChild>
												<Link
													to={routes.calibrate.criteria.editAnswers({
														companyId,
														ecosystemId,
														criterionType: 'enrichment',
													})}
												>
													<Icon name="edit" size="sm" />
												</Link>
											</Button>
										</CustomTooltip>
									</div>
								)}
							</section>
						),
					},
					...verticals.map(() => ({ className: 'border-r-transparent', children: '' })),
				],
			},
		}),
		[allCriteria, verticals, readOnlySession, companyId, ecosystemId],
	)

	const groups = useMemo(() => {
		return allCriteria.map(item => ({
			collapsible: {
				options: { collapsible: true, collapsibleCondition: !!item.enrichedAnswers.length },
				columns: [
					{
						className: 'align-top bg-white',
						children: (
							<div className="w-full">
								<CustomTooltip
									label={
										<>
											AI Agent instructions:&nbsp;
											{item.criterion.instructions}
										</>
									}
									side="right"
									sideOffset={16}
								>
									<div className="flex flex-col gap-0.5">
										{item.enrichedAnswers?.[0]?.[0].createdAt && (
											<p className="flex gap-1 text-label-sm text-neutral-3-fg">
												<Icon name="time" />
												{formatDateToReadableString(item.enrichedAnswers?.[0]?.[0].createdAt, { includeTime: true })}
											</p>
										)}
										<p className="whitespace-pre-line break-words pe-[100px] text-body-md font-medium">
											{item.criterion.name}
										</p>
									</div>
									<div className="absolute right-0 top-0 h-0 w-0 border-b-0 border-l-[15px] border-t-[13px] border-l-transparent border-t-brand-2-bd" />
								</CustomTooltip>
								<div className="flex items-center">
									<Button variant="icon" size="icon" asChild className="absolute right-[16px] top-1/2 -translate-y-1/2">
										<Link
											to={routes.calibrate.criteria.edit({
												companyId,
												ecosystemId,
												criterionId: String(item.criterion.id),
												criterionType: 'enrichment',
												returnTo: 'index',
											})}
										>
											<Icon name="edit" size="sm" />
										</Link>
									</Button>
									<Button variant="icon" size="icon" asChild className="absolute right-[40px] top-1/2 -translate-y-1/2">
										<Link
											to={routes.calibrate.criteria.duplicate({
												companyId,
												ecosystemId,
												criterionId: String(item.criterion.id),
												criterionType: 'enrichment',
												returnTo: 'index',
											})}
										>
											<Icon name="copy" size="sm" />
										</Link>
									</Button>
									{/*<Button*/}
									{/*	variant="icon"*/}
									{/*	size="icon"*/}
									{/*	className="absolute right-[64px] top-1/2 -translate-y-1/2"*/}
									{/*	onClick={() =>*/}
									{/*		openModal({*/}
									{/*			name: MODAL_NAME.ReasoningAndTips,*/}
									{/*			component: ReasoningAndTipsModal,*/}
									{/*		})*/}
									{/*	}*/}
									{/*>*/}
									{/*	<Icon name="data-enrichment" size="sm" />*/}
									{/*</Button>*/}
									{hasActiveEnrichment(item.criterion.id) ? (
										<CancelEnrichmentForm criterion={item} />
									) : (
										<CreateEnrichmentForm
											criterion={item}
											criterionVerticalsHasAtLeastOneAnswer={criterionVerticalsHasAtLeastOneAnswer}
										/>
									)}
								</div>
							</div>
						),
					},
					...verticals.map(vertical => ({
						...(readOnlySession
							? {}
							: {
									onClick: () => {
										autofocusFieldMutation(
											generateCriterionFieldId(
												vertical.answers?.find(answer => answer.criterionId === item.criterion.id)?.criterionId ?? 0,
											),
										)
										void navigate(
											routes.calibrate.verticals.edit({
												companyId,
												ecosystemId,
												verticalId: String(vertical.id),
											}),
											{ replace: true, preventScrollReset: true },
										)
									},
								}),
						className: 'align-top bg-transparent',
						children: (
							<p className="text-body-md font-normal">
								{renderCriterionAnswerValue(vertical.answers?.find(answer => answer.criterionId === item.criterion.id))}
							</p>
						),
					})),
				],
			},
			body: {
				rows: item.enrichedAnswers.map((runGroup, i) => ({
					columns: [
						{
							className: 'align-top bg-brand-1-bg',
							children: (
								<div>
									<p className="whitespace-pre-line break-words pe-[100px] text-body-md font-medium">{`RUN ${i + 1}`}</p>
								</div>
							),
						},
						...verticals.map(vertical => {
							const answer = vertical.enrichedAnswers.find(answer =>
								runGroup.some(groupItem => groupItem.id === answer.id),
							)

							const getAnswerContent = (answer?: z.infer<typeof CriterionEnrichedAnswerAPISchema>) => {
								if (!answer) return null

								switch (answer.status) {
									case QUEUE_STATUS.completed:
										return answer.value || 'Not Found'
									default:
										return <QueueStatusLabel status={getQueueStatus(answer.status)} />
								}
							}

							const content = getAnswerContent(answer)

							return {
								className: 'align-top bg-brand-1-bg',
								children: (
									<CustomTooltip
										variant="secondary"
										disabled={answer?.status !== QUEUE_STATUS.completed}
										side="bottom"
										label={
											<div className="flex flex-col space-y-3">
												{!!answer?.sourceLinks?.length && (
													<LabeledValue label="Source">
														<div className="flex flex-col gap-1">
															{answer.sourceLinks.map(item => (
																<a
																	href={item}
																	target="_blank"
																	rel="noopener noreferrer"
																	key={item}
																	className="text-link"
																>
																	{item}
																</a>
															))}
														</div>
													</LabeledValue>
												)}

												{answer?.sourceReasoning && (
													<LabeledValue label="Reasoning">{answer.sourceReasoning}</LabeledValue>
												)}
												{answer?.langsmithUrl && (
													<Role user={user} allow={['admin']}>
														<LabeledValue label="Langsmith link">
															<a
																href={answer.langsmithUrl}
																target="_blank"
																rel="noopener noreferrer"
																className="text-link"
															>
																{answer.langsmithUrl}
															</a>
														</LabeledValue>
													</Role>
												)}
											</div>
										}
									>
										<p className="text-body-md font-normal">{content}</p>
									</CustomTooltip>
								),
							}
						}),
					],
				})),
			},
		}))
	}, [
		allCriteria,
		companyId,
		criterionVerticalsHasAtLeastOneAnswer,
		ecosystemId,
		hasActiveEnrichment,
		navigate,
		readOnlySession,
		user,
		verticals,
	])

	return [header, ...groups]
}
