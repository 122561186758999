import { useMemo } from 'react'
import { type z } from 'zod'
import { type PersonaAPISchema } from '#src/api/icp/company/persona/schemas'
import { Icon } from '#src/components/ui/icon'
import { useSalesCoachQuery } from '#src/hooks/useSalesCoachQuery'
import { PersonaAIGeneratingBody } from '#src/routes/enable/personas/views/components/details/PersonaAIGeneratingBody'
import { MONTH_SELECT_OPTIONS } from '#src/utils/enumerations'

type PersonaSalesCoachSectionProps = {
	persona: z.infer<typeof PersonaAPISchema>
}

export const PersonaSalesCoachSection = ({ persona }: PersonaSalesCoachSectionProps) => {
	const { salesCoachData, isSalesCoachEmpty, salesCoachGeneralPromptStatus, salesCoachTimingPromptStatus } =
		useSalesCoachQuery(persona)

	const salesCoachMappingItems = useMemo(
		() => [
			{
				id: 'sales-coach-calibrated-questions',
				heading: 'Calibrated Questions',
				value: salesCoachData?.calibrated?.questions
					? (salesCoachData?.calibrated?.questions?.length ?? 0) > 1
						? salesCoachData?.calibrated.questions.join('\n\n')
						: salesCoachData?.calibrated.questions.join('\n')
					: '',
				isFieldGenerating: salesCoachGeneralPromptStatus.isGenerating,
			},
			...(salesCoachData?.timing
				? [
						{
							id: 'sales-coach-month',
							heading: `Context for ${
								MONTH_SELECT_OPTIONS.find(m => Number(m.value) === salesCoachData?.timing?.month)?.label
							}`,
							value: salesCoachData?.timing?.timingContext,
							isFieldGenerating: salesCoachTimingPromptStatus.isGenerating,
						},
					]
				: []),
		],
		[salesCoachTimingPromptStatus, salesCoachGeneralPromptStatus, salesCoachData],
	)

	if (isSalesCoachEmpty)
		return (
			<section className="flex min-h-[220px] w-full flex-col items-center justify-center gap-4">
				<Icon name="education" size="xl" className="text-neutral-3-fg" />
				<h1 className="text-body-lg text-neutral-2-fg">Sales coach is not calibrated yet</h1>
			</section>
		)

	return (
		<PersonaAIGeneratingBody hasCrashed={salesCoachGeneralPromptStatus.hasCrashed} items={salesCoachMappingItems} />
	)
}
