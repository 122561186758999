import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { PaginatedAPIResSchema } from '#src/schemas/global'
import { safeFetchMeta } from '#src/utils/safeFetch'
import { adminCompanyEndpoints } from './endpoints'
import { CompanyTransactionAPISchema } from './schemas'

const keys = {
	all: ['icp', 'admin', 'company'] as const,
	transactions: (companyId: z.infer<typeof CompanySchema.shape.id>, page: number | null) =>
		[...keys.all, 'transactions', companyId, page] as const,
}

const transactionsQuery = (companyId: z.infer<typeof CompanySchema.shape.id>, page: number) => ({
	queryKey: keys.transactions(companyId, page),
	queryFn: async () =>
		await safeFetchMeta(
			PaginatedAPIResSchema(CompanyTransactionAPISchema),
			adminCompanyEndpoints.transactions(companyId, page),
			{
				method: 'GET',
			},
		),
})

const queries = {
	transactions: transactionsQuery,
}

export { queries as adminCompanyQueries, keys as adminCompanyQueriesKeys }
