import { type ActionFunctionArgs } from 'react-router'
import { orgListsMutations } from '#src/api/organise/lists/mutations'
import {
	LeadResearchAgentJobAdditionalInfoFormSchema,
	LeadResearchAgentJobCriteriaFormSchema,
	LeadResearchAgentJobFileFormSchema,
	LeadResearchAgentJobFormSchema,
	LeadResearchAgentJobVerticalsFormSchema,
} from '#src/api/organise/lists/schemas'
import { MODAL_NAME } from '#src/constants/modals'
import { showToast } from '#src/context/ToastContext'
import { FORM_STEPS } from '#src/routes/organize/lead-research-agent/constants'
import { getEarliestFailedStepFromResErrors } from '#src/utils/actions'
import { parseFormDataNew } from '#src/utils/forms'
import { closeModalServer } from '#src/utils/server/modals'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ request }: ActionFunctionArgs) => {
	const formData = await request.formData()

	try {
		const submission = parseFormDataNew(formData, LeadResearchAgentJobFormSchema)
		await orgListsMutations.create(submission.value)

		showToast({
			message: 'Job created successfully',
			type: 'success',
		})
		closeModalServer(MODAL_NAME.LeadResearchAgentJobCreate)

		return { ok: true }
	} catch (e) {
		try {
			const res = (e as Response).clone()
			const body = (await res.json()) as {
				success: boolean
				message: string
				errors?: Record<string, string[]>
			}
			showToast({
				message: body.message,
				type: 'error',
			})

			const failedStep = getEarliestFailedStepFromResErrors(body.errors, [
				{
					step: FORM_STEPS.File,
					schema: LeadResearchAgentJobFileFormSchema,
				},
				{
					step: FORM_STEPS.Verticals,
					schema: LeadResearchAgentJobVerticalsFormSchema,
				},
				{
					step: FORM_STEPS.Criteria,
					schema: LeadResearchAgentJobCriteriaFormSchema,
				},
				{
					step: FORM_STEPS.AdditionalInfo,
					schema: LeadResearchAgentJobAdditionalInfoFormSchema,
				},
			])

			return {
				ok: false,
				errors: body.errors,
				failedStep,
			}
		} catch (err) {
			showToast({
				message: 'Unexpected error while creating Lead Research Agent job',
				type: 'error',
			})
			return {
				ok: false,
			}
		}
	}
}
