import { type z } from 'zod'
import { VerticalAPISchema, VerticalListAPISchema } from '#src/api/icp/company/verticals/schemas'
import { ECOSYSTEM_TYPE } from '#src/routes/calibrate/ecosystem-management/ecosystem/constants'
import { type CompanySchema } from '#src/routes/company/schema'
import { api } from '#src/utils/endpoints'
import { PRIORITY_RANK, STATUS_RANK } from '#src/utils/enumerations'
import { safeFetch } from '#src/utils/safeFetch'

const keys = {
	all: ['icp', 'company', 'verticals'] as const,
	list: (companyId: z.infer<typeof CompanySchema.shape.id>, ecosystemId: number) =>
		[...keys.all, 'list', companyId, ecosystemId] as const,
	details: () => [...keys.all, 'details'] as const,
	detail: (companyId: z.infer<typeof CompanySchema.shape.id>, itemId: z.infer<typeof VerticalAPISchema.shape.id>) =>
		[...keys.details(), companyId, itemId] as const,
}

const listQuery = (companyId: z.infer<typeof CompanySchema.shape.id>, ecosystemId: number) => ({
	queryKey: keys.list(companyId, ecosystemId),
	queryFn: async () => {
		const verticals = await safeFetch(
			VerticalListAPISchema,
			api.ecosystem.verticals(companyId, ecosystemId.toString()),
			{
				method: 'GET',
			},
		)

		const isCriteriaSandboxEcosystem = verticals[0]?.ecosystem.type === ECOSYSTEM_TYPE.CRITERIA_SANDBOX

		// Criteria sandbox does not need sorting (no statuses, no priorities)
		if (isCriteriaSandboxEcosystem) {
			return verticals
		}

		verticals.sort((a, b) => {
			const statusA = STATUS_RANK[a.status]
			const statusB = STATUS_RANK[b.status]
			const statusComparison = statusA - statusB
			if (statusComparison !== 0) {
				return statusComparison
			}

			const priorityComparison = PRIORITY_RANK[a.priority] - PRIORITY_RANK[b.priority]
			return priorityComparison
		})

		return verticals
	},
})

const itemQuery = (
	companyId: z.infer<typeof CompanySchema.shape.id>,
	itemId: z.infer<typeof VerticalAPISchema.shape.id>,
) => ({
	queryKey: keys.detail(companyId, itemId),
	queryFn: async () => {
		return await safeFetch(VerticalAPISchema, api.vertical.details(companyId, Number(itemId)), {
			method: 'GET',
		})
	},
})

const queries = {
	list: listQuery,
	item: itemQuery,
}

export { queries as verticalsQueries, keys as verticalsQueriesKeys }
