import { Outlet, useLoaderData, useMatches, useNavigation } from 'react-router'
import { Dialog, type DialogProps } from '#src/components/ui/dialog'
import { routes } from '#src/utils/routes'
import { type LoaderResType } from '../loaders/modalLayout'

export type ModalHandle = {
	getDialogConfig: (data: LoaderResType) => DialogProps
}

export const ModalLayout = () => {
	const {
		values: { companyId, ecosystemId, type, redirectTo, isCriteriaSandbox, isDuplicating },
	} = useLoaderData<LoaderResType>()
	const matches = useMatches()
	const match = matches[matches.length - 1]
	const handle = match.handle as ModalHandle
	const dialogConfig = handle.getDialogConfig(match.data as LoaderResType)
	const navigation = useNavigation()
	const isSubmitting = navigation.state === 'submitting'
	const mode = isDuplicating ? 'Duplicate' : match?.params?.criterionId ? 'Edit' : 'Add'

	const closeTo =
		redirectTo === 'index'
			? routes.calibrate.verticals.index({
					companyId,
					ecosystemId,
				})
			: routes.calibrate.criteria.editAnswers({
					companyId,
					ecosystemId,
					criterionType: type,
				})

	const criterionLabel = isCriteriaSandbox
		? 'Criterion'
		: type === 'enrichment'
			? 'Enrichment Criterion'
			: 'Qualification Criterion'

	const dialogHeading = `${mode} ${criterionLabel}`

	return (
		<Dialog
			open={true}
			dialogHeading={dialogHeading}
			size="lg"
			closeTo={closeTo}
			isSubmitting={isSubmitting}
			{...dialogConfig}
		>
			<Outlet />
		</Dialog>
	)
}
