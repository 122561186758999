import { type ActionFunctionArgs } from 'react-router'
import { companyChatRoleplayScenarioMutations } from '#src/api/icp/company/chat/roleplay/scenario/mutations'
import { companyChatRoleplayScenarioQueries } from '#src/api/icp/company/chat/roleplay/scenario/queries'
import {
	ScenarioEditFormSchema,
	ScenarioFormDetailsSchema,
	ScenarioFormParticipantsSchema,
	ScenarioFormPersonalitySchema,
	ScenarioFormPersonaMappingSchema,
} from '#src/api/icp/company/chat/roleplay/scenario/schemas'
import { MODAL_NAME } from '#src/constants/modals'
import { client } from '#src/main'
import { validateLinkedinUrl } from '#src/routes/enable/chat/mutations'
import { parseFormDataNew } from '#src/utils/forms'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'
import { closeModalServer } from '#src/utils/server/modals'
import { FORM_STEPS } from '../constants'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ request, params }: ActionFunctionArgs) => {
	validateRouteParams(params, ['scenarioId'])
	const { company } = await getCompany(params)
	const scenario = await client.fetchQuery(companyChatRoleplayScenarioQueries.item(company.id, params.scenarioId))

	const formData = await request.formData()

	try {
		const submission = parseFormDataNew(formData, ScenarioEditFormSchema)

		if (submission.value.linkedinUrl) {
			if (scenario.linkedinUrl !== submission.value.linkedinUrl) {
				const { uuid: contactId } = await validateLinkedinUrl(company.id, {
					personaId: submission.value.personaId,
					linkedinUrl: submission.value.linkedinUrl,
				})
				submission.value.contactId = contactId
			}
		} else {
			submission.value.contactId = undefined
		}

		await companyChatRoleplayScenarioMutations.update(company.id, scenario.id, submission.value)

		closeModalServer(MODAL_NAME.RoleplayScenarioEdit)

		return { ok: true }
	} catch (e) {
		return await serverFormErrorHandler(e, {
			steps: [
				{
					step: FORM_STEPS.Details,
					schema: ScenarioFormDetailsSchema,
				},
				{
					step: FORM_STEPS.PersonaMapping,
					schema: ScenarioFormPersonaMappingSchema,
				},
				{
					step: FORM_STEPS.Personality,
					schema: ScenarioFormPersonalitySchema,
				},
				{
					step: FORM_STEPS.Participants,
					schema: ScenarioFormParticipantsSchema,
				},
			],
		})
	}
}
