import { useLoaderData } from 'react-router'
import { PageLayout } from '#src/components/ui/PageLayout'
import { Surface } from '#src/components/ui/surface'
import usePersonaDetails from '#src/hooks/usePersonaDetails'
import { type LoaderRes } from '#src/routes/calibrate/ecosystem-management/personas/loaders/item'
import PersonaDetails from '#src/routes/calibrate/ecosystem-management/personas/views/components/PersonaDetails'
import PersonaHeader from '#src/routes/calibrate/ecosystem-management/personas/views/components/PersonaHeader'
import PersonaNav from '#src/routes/calibrate/ecosystem-management/personas/views/components/PersonaNav'
import PersonaPromptError from '#src/routes/calibrate/ecosystem-management/personas/views/components/PersonaPromptError'

export const View = () => {
	const { persona, personas, ecosystems, plays, intents, reportsToPersona } = useLoaderData<LoaderRes>()
	const { data: personaDetails } = usePersonaDetails({
		personaId: persona.id,
		initialData: persona,
	})

	return (
		<PageLayout>
			<PersonaNav active={persona.id} personas={personas} />
			<Surface className="w-full overflow-clip p-0">
				<PersonaHeader persona={personaDetails} ecosystems={ecosystems} />
				{persona?.prompt.status === 'Crashed' ? (
					<PersonaPromptError persona={personaDetails} />
				) : (
					<PersonaDetails
						persona={personaDetails}
						plays={plays}
						intents={intents}
						reportsToPersona={reportsToPersona}
					/>
				)}
			</Surface>
		</PageLayout>
	)
}
