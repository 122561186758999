import { type ReactNode } from 'react'

type FormSectionProps = {
	sections: {
		heading: ReactNode
		content: ReactNode
	}[]
}

export const FormSections = ({ sections }: FormSectionProps) => (
	<section className="flex flex-col gap-6">
		{sections.map((item, i) => (
			<div key={i} className="flex flex-col gap-3.5">
				<h2 className="text-title-sm">{item.heading}</h2>
				<div className="flex flex-col gap-3.5">{item.content}</div>
			</div>
		))}
	</section>
)
