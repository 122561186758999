import { type z } from 'zod'
import { type PlaysTemplatesItemAPISchema } from '#src/api/icp/company/plays-templates/schemas'
import DeleteConfirm from '#src/components/delete-confirm'
import LoaderRequiredComponent from '#src/components/LoaderRequiredComponent'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { type LoaderRes } from '#src/routes/enable/plays/templates/loaders/item'
import { routes } from '#src/utils/routes'

export type PlayTemplateDeleteModalProps = {
	templateId: z.infer<typeof PlaysTemplatesItemAPISchema.shape.id>
}

export const PlayTemplateDeleteModal = (props: PlayTemplateDeleteModalProps) => {
	const { companyId } = useCompany()

	return (
		<LoaderRequiredComponent<LoaderRes, PlayTemplateDeleteModalProps>
			route={routes.enable.playTemplates.item({ companyId, templateId: String(props.templateId) })}
			component={ModalInner}
			initialProps={props}
		/>
	)
}

const ModalInner = (props: PlayTemplateDeleteModalProps & LoaderRes) => {
	const {
		data: { template },
	} = props
	const { closeModal } = useModals()
	const { companyId } = useCompany()

	return (
		<DeleteConfirm
			defaultOpen={true}
			onOpenChange={isOpen => !isOpen && closeModal(MODAL_NAME.PlayTemplateDelete)}
			action={routes.enable.playTemplates.delete({
				companyId,
				templateId: String(template.id),
			})}
			method="DELETE"
			actionTitle="Delete"
			title="Delete play template?"
			description={
				<>
					Are you sure you want to remove the <b>{template.name}</b> Play template?
				</>
			}
		/>
	)
}
