import * as SelectPrimitive from '@radix-ui/react-select'
import { cva, type VariantProps } from 'class-variance-authority'
import {
	forwardRef,
	type ComponentPropsWithoutRef,
	type ElementRef,
	type AriaAttributes,
	type ReactNode,
	useEffect,
} from 'react'

import { cn, isIconName } from '#src/utils/misc'
import { Icon, type IconName } from './icon'

export type SelectProps = AriaAttributes &
	ComponentPropsWithoutRef<typeof SelectPrimitive.Root> & {
		triggerProps?: ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
		contentProps?: ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
		id?: string
		className?: string
		placeholder?: string
		iconName?: IconName
		iconRight?: IconName | Exclude<ReactNode, string>
	}

const Select = forwardRef<ElementRef<typeof SelectPrimitive.Root>, SelectProps>(
	(
		{ children, className, triggerProps, contentProps, placeholder, iconName = 'chevron-down', iconRight, ...props },
		ref,
	) => {
		return (
			<SelectRoot {...props}>
				<SelectTrigger
					className={cn(
						'relative transition-all',
						'grid h-10 w-full grid-cols-[1fr,max-content] grid-rows-1 items-center rounded px-3 py-2.5',
						'bg-white disabled:bg-neutral-2-bg',
						'border border-neutral-2-bd outline-none hover:border-neutral-2-bd-selected focus-visible:border-brand-2-bd aria-[invalid]:border-status-danger-bd data-[placeholder]:text-neutral-3-fg',
						triggerProps?.autoFocus && 'focus-visible:border-brand-2-bd',
						'text-body-md text-neutral-1-fg disabled:text-neutral-inverse-fg-disabled',
						'disabled:cursor-not-allowed disabled:opacity-50',
						'file:border-0 file:bg-transparent file:text-button-sm',
						'gap-1 *:w-full *:overflow-hidden *:truncate *:text-left',
						props['aria-invalid'] && 'border-status-danger-bd',
						className,
					)}
					{...triggerProps}
					ref={ref}
				>
					<SelectValue placeholder={placeholder} />
					<SelectIcon>
						{iconRight &&
							(isIconName(iconRight) ? (
								<Icon name={iconRight} className="mr-2 text-body-md text-neutral-4-fg" />
							) : (
								<span className="me-1.5 items-center text-body-sm text-neutral-4-fg">{iconRight}</span>
							))}
						{iconName && <Icon name={iconName} />}
					</SelectIcon>
				</SelectTrigger>
				<SelectContent {...contentProps}>{children}</SelectContent>
			</SelectRoot>
		)
	},
)
Select.displayName = SelectPrimitive.Root.displayName

const SelectRoot = SelectPrimitive.Root

const SelectValue = SelectPrimitive.Value

const SelectIcon = SelectPrimitive.Icon

const SelectTrigger = SelectPrimitive.Trigger

const SelectContent = forwardRef<
	ElementRef<typeof SelectPrimitive.Content>,
	ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ children, className, ...props }, ref) => {
	return (
		<SelectPrimitive.Content
			className={cn(
				'z-[100] flex flex-col gap-2 overflow-hidden rounded border border-neutral-1-bd bg-neutral-1-bg py-2 text-neutral-2-fg shadow',
				className,
			)}
			ref={ref}
			{...props}
		>
			<SelectPrimitive.ScrollUpButton />
			<SelectPrimitive.Viewport>{children}</SelectPrimitive.Viewport>
			<SelectPrimitive.ScrollDownButton />
		</SelectPrimitive.Content>
	)
})
SelectContent.displayName = SelectPrimitive.Content.displayName

const SelectItem = forwardRef<
	ElementRef<typeof SelectPrimitive.Item>,
	ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ children, className, ...props }, ref) => {
	return (
		<SelectPrimitive.Item
			className={cn(
				'flex cursor-pointer select-none items-center justify-between gap-2 px-2 py-1.5 text-body-md outline-none hover:bg-neutral-1-bg-hover focus-visible:bg-neutral-1-bg-hover radix-disabled:cursor-not-allowed radix-disabled:opacity-50 radix-disabled:hover:bg-transparent',
				className,
			)}
			{...props}
			ref={ref}
		>
			<SelectItemText>{children}</SelectItemText>
			<SelectItemIndicator>
				<Icon name="check" />
			</SelectItemIndicator>
		</SelectPrimitive.Item>
	)
})
SelectItem.displayName = SelectPrimitive.Item.displayName

const SelectItemText = SelectPrimitive.ItemText
const SelectItemIndicator = SelectPrimitive.ItemIndicator

export type SelectOption = {
	value: string | number
	label: string | ReactNode
	description?: string
	disabled?: boolean
}

export const selectTriggerVariants = cva('', {
	variants: {
		size: {
			sm: 'h-8',
			md: 'h-10',
			lg: 'h-12',
		},
	},
	defaultVariants: {
		size: 'md',
	},
})

export type SelectFieldProps = VariantProps<typeof selectTriggerVariants> & {
	options: SelectOption[]
	placeholder?: string
	onValueChange?: (value: string | undefined) => void
	value?: string
	triggerProps?: ComponentPropsWithoutRef<typeof Select>
	contentProps?: ComponentPropsWithoutRef<typeof Select>
	iconName?: IconName
	iconRight?: IconName | Exclude<ReactNode, string>
	selectFirstByDefault?: boolean
	disabled?: boolean

	/**
	 * This property is used when select options are dynamically updated on the same page.
	 * Enabling this ensures that the select UI updates in real-time without glitches.
	 */
	enableRealTimeUpdate?: boolean
}

export const SelectField = forwardRef<ElementRef<typeof SelectPrimitive.Root>, SelectFieldProps>(
	(
		{
			options,
			placeholder,
			onValueChange,
			value,
			triggerProps,
			contentProps,
			iconName,
			size,
			iconRight,
			selectFirstByDefault,
			enableRealTimeUpdate,
			...props
		},
		ref,
	) => {
		useEffect(() => {
			if (selectFirstByDefault && !value && options.length) {
				onValueChange?.(String(options[0].value))
			}

			if (value && enableRealTimeUpdate) {
				const isValueValid = options.some(option => String(option.value) === String(value))

				if (!isValueValid && options.length) {
					onValueChange?.(String(options[options.length - 1].value))
				}

				if (!options.length && value !== '') {
					onValueChange?.('')
				}
			}
		}, [onValueChange, options, props.disabled, selectFirstByDefault, value, enableRealTimeUpdate])

		return (
			<Select
				{...props}
				ref={ref}
				value={props.disabled && value === '-' ? '' : value}
				onValueChange={onValueChange}
				placeholder={placeholder}
				className={cn(selectTriggerVariants({ size }))}
				triggerProps={triggerProps}
				contentProps={contentProps}
				iconName={iconName}
				iconRight={iconRight}
			>
				{!!options?.length &&
					options.map(option => (
						<SelectItem key={option.value} value={String(option.value)} disabled={option.disabled}>
							{option.label}
							{option.description && <p className="text-body-sm text-neutral-2-fg">{option.description}</p>}
						</SelectItem>
					))}
			</Select>
		)
	},
)

SelectField.displayName = 'SelectField'

export {
	Select,
	SelectRoot,
	SelectValue,
	SelectIcon,
	SelectItem,
	SelectItemText,
	SelectItemIndicator,
	SelectTrigger,
	SelectContent,
}
