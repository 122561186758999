import { parseWithZod } from '@conform-to/zod'
import { type ActionFunctionArgs } from 'react-router'
import { settingsCopilotMutations } from '#src/api/icp/company/settings/copilot/mutations'
import { SettingsCopilotFormParserSchema } from '#src/api/icp/company/settings/copilot/schemas'
import { showToast } from '#src/context/ToastContext'
import { getCompany } from '#src/utils/server/company'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ request, params }: ActionFunctionArgs) => {
	const { company } = await getCompany(params)

	const formData = await request.formData()
	const submission = parseWithZod(formData, {
		schema: SettingsCopilotFormParserSchema,
	})

	if (submission.status !== 'success') {
		showToast({
			message: 'Failed to parse form data',
			type: 'error',
		})
		return {
			ok: false,
		}
	}

	try {
		await settingsCopilotMutations.update(company.id, submission.value)
		showToast({
			message: 'Default settings updated',
			type: 'success',
		})
		return {
			ok: true,
		}
	} catch (e) {
		if (e instanceof Response) {
			const response = (await e.json()) as { message: string; errors: Record<string, string[]> }
			showToast({
				message: response.message,
				type: 'error',
			})
			return {
				ok: false,
			}
		}
		showToast({
			message: 'Failed api response. Please contact support',
			type: 'error',
		})
		return {
			ok: false,
		}
	}
}
