import { z } from 'zod'
import { CompanySchema } from '#src/routes/company/schema'
import { fileCsv, zNumber } from '#src/utils/validation'

export const DataEnrichmentStatisticsAPISchema = z.object({
	inQueueCount: z.number(),
	inProgressCount: z.number(),
	completedCount: z.number(),
})

export enum DATA_ENRICHMENT_WORKFLOW_STATUS {
	Cancelled = 'cancelled',
	Completed = 'completed',
	Error = 'error',
	InProgress = 'in_progress',
	Pending = 'pending',
}

export const DataEnrichmentListItemAPISchema = z.object({
	id: z.string(),
	title: z.string(),
	createdBy: z.string().nullable(),
	status: z.nativeEnum(DATA_ENRICHMENT_WORKFLOW_STATUS),
	comment: z.string().nullable(),
	companyId: z.string(),
	companyName: z.string(),
	originalEntryCount: z.number().nullable(),
	createdAt: z.string(),
	statistics: z
		.object({
			waterfalling: z
				.object({
					emails: z.array(
						z.object({
							provider: z.string(),
							count: z.number(),
						}),
					),
					phones: z.array(
						z.object({
							provider: z.string(),
							count: z.number(),
						}),
					),
					emailsCount: z.number(),
					phonesCount: z.number(),
					completedCount: z.number(),
					errorCount: z.number(),
					inProgressCount: z.number(),
					inQueueCount: z.number(),
				})
				.nullable()
				.optional(),
			enrichmentCriteria: z
				.object({
					completedCount: z.number(),
					errorCount: z.number(),
					inProgressCount: z.number(),
					inQueueCount: z.number(),
				})
				.nullable()
				.optional(),
		})
		.nullable(),
})

export const DataEnrichmentItemAPISchema = DataEnrichmentListItemAPISchema.merge(
	z.object({
		importFileUrl: z.string().nullable(),
		metadata: z
			.object({
				enrichmentCriterias: z.array(
					z.object({
						dataPointSource: z.string().nullable().optional(),
						dataPointTitle: z.string(),
						dataPointVerticalValue: z.string().nullable().optional(),
						dataPointInstructions: z.string().nullable().optional(),
					}),
				),
			})
			.nullable(),
	}),
)

export const DataEnrichmentJobFileFormSchema = z.object({
	file: fileCsv,
})

export const DataEnrichmentJobAdditionalInfoFormSchema = z.object({
	title: z.string(),
	comment: z.string().nullable(),
})

export enum DATA_ENRICHMENT_JOB_ENRICHERS {
	Waterfalling = 'waterfalling',
	EnrichmentCriteria = 'enrichmentCriteria',
}

export const ContactWaterfallingJobFormSchema = z
	.object({
		companyId: CompanySchema.shape.id,
		enrichers: z.array(z.nativeEnum(DATA_ENRICHMENT_JOB_ENRICHERS)),
	})
	.merge(DataEnrichmentJobFileFormSchema)
	.merge(DataEnrichmentJobAdditionalInfoFormSchema)

export const AccountEnrichmentCriteriaFormSchema = z.object({
	metadata: z.object({
		enrichmentCriteriasIds: z.array(zNumber(z.number())).nonempty('Select at least one Enrichment criteria'),
	}),
})

export const AccountEnrichmentJobFormSchema = z
	.object({
		companyId: CompanySchema.shape.id,
		enrichers: z.array(z.nativeEnum(DATA_ENRICHMENT_JOB_ENRICHERS)),
	})
	.merge(DataEnrichmentJobFileFormSchema)
	.merge(AccountEnrichmentCriteriaFormSchema)
	.merge(DataEnrichmentJobAdditionalInfoFormSchema)

export enum WORKFLOW_EXPORT_TYPE {
	All = 'all',
	OnlyFound = 'only-found',
}

export const DataEnrichmentJobDownloadFormSchema = z.object({
	type: z.nativeEnum(WORKFLOW_EXPORT_TYPE, { message: 'Incorrect exporting type' }),
})
