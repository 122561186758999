import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useFetcher } from 'react-router'
import { type z } from 'zod'
import { type PersonaAPISchema, PersonaFormSchema } from '#src/api/icp/company/persona/schemas'
import { type VerticalAPISchema } from '#src/api/icp/company/verticals/schemas'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import LoaderRequiredComponent from '#src/components/LoaderRequiredComponent'
import ProductTip from '#src/components/product-tip'
import { Dialog } from '#src/components/ui/dialog'
import { Icon } from '#src/components/ui/icon'
import { StatusButton } from '#src/components/ui/status-button'
import { CustomTooltip } from '#src/components/ui/tooltip'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { type EcosystemAPISchema } from '#src/routes/calibrate/ecosystem-management/ecosystem/schema'
import { type ActionRes as TypesOfTasksActionRes } from '#src/routes/calibrate/ecosystem-management/personas/actions/getTypesOfTasks'
import { type ActionRes } from '#src/routes/calibrate/ecosystem-management/personas/actions/save'
import { type LoaderRes } from '#src/routes/calibrate/ecosystem-management/personas/loaders/save'
import { PERSONA_TYPE_OPTIONS, PERSONA_TYPES } from '#src/utils/enumerations'
import { getAvailablePriorities, getAvailableStatuses } from '#src/utils/fieldOptions'
import { routes } from '#src/utils/routes'

export type PersonaCreateModalProps = {
	personaId?: z.infer<typeof PersonaAPISchema.shape.id>
	ecosystemId: z.infer<typeof EcosystemAPISchema.shape.id>
	verticalId: z.infer<typeof VerticalAPISchema.shape.id>
	duplicating?: boolean
}

export const PersonaCreateModal = (props: PersonaCreateModalProps) => {
	const { companyId } = useCompany()

	return (
		<LoaderRequiredComponent<LoaderRes, PersonaCreateModalProps>
			route={routes.calibrate.persona.edit({
				companyId,
				ecosystemId: String(props.ecosystemId),
				verticalId: String(props.verticalId),
				personaId: props.personaId ? String(props.personaId) : null,
				duplicating: props.duplicating,
			})}
			component={ModalInner}
			initialProps={props}
		/>
	)
}

const ModalInner = (props: PersonaCreateModalProps & LoaderRes) => {
	const { ecosystemId, verticalId, personas, persona, isDuplicating } = props
	const { closeModal } = useModals()
	const { companyId } = useCompany()
	const actionFetcher = useFetcher<ActionRes>()
	const typesOfTasksFetcher = useFetcher<TypesOfTasksActionRes>()

	const form = useForm<z.infer<typeof PersonaFormSchema>>({
		resolver: zodResolver(PersonaFormSchema),
		defaultValues: {
			verticals: [verticalId],
			type: persona?.type ?? PERSONA_TYPES.decisionmaker,
			status: persona?.status ?? 'Ongoing',
			priority: persona?.priority ?? 'High',
			expertise: persona?.expertise,
			jobSeniority: persona?.jobSeniority ?? 'VP, Director or Head',
			jobExperience: persona?.jobExperience ?? '+7 years',
			typeOfTask: persona?.typeOfTask,
			pain: persona?.pain,
			painLength: typeof persona?.painLength === 'number' ? persona.painLength : 100,
			painOverride: persona?.painOverride || undefined,
			reportsTo: persona?.reportsTo?.id,
			reportsOverride: persona?.reportsOverride,
			jobTitles: persona?.jobTitles,
		},
	})

	useEffect(() => {
		const data = typesOfTasksFetcher.data

		if (data && 'result' in data) {
			form.setValue('typeOfTask', data.result)
		}
	}, [form, typesOfTasksFetcher.data])

	const jobTitlesValue = form.watch('jobTitles')
	const expertiseValue = form.watch('expertise')
	const seniorityValue = form.watch('jobSeniority')
	const reportsToValue = form.watch('reportsTo')

	const method = 'POST'
	const formId = `PersonaCreateModal-${persona ? persona.id : ''}`

	const action = routes.calibrate.persona.edit({
		companyId: companyId,
		ecosystemId: ecosystemId,
		verticalId: verticalId,
		personaId: persona?.id ? String(persona?.id) : null,
	})

	const handleGenerateTypeOfTask = () => {
		void typesOfTasksFetcher.submit(
			{
				jobTitles: jobTitlesValue,
				verticalId: verticalId,
			},
			{
				method: method,
				action: routes.calibrate.persona.getTypesOfTasks({ companyId, verticalId, ecosystemId }),
			},
		)
	}

	const handleOnTypeChange = (v: unknown) => {
		if (!Object.values(PERSONA_TYPES).includes(v as keyof typeof PERSONA_TYPES)) return

		const personaConfig = {
			[PERSONA_TYPES.decisionmaker]: {
				jobSeniority: 'VP, Director or Head',
				jobExperience: '+7 years',
				jobTitlesBase: 'VP, Director or Head',
			},
			[PERSONA_TYPES.influencer]: {
				jobSeniority: 'Head, Manager',
				jobExperience: '+5 years',
				jobTitlesBase: 'Head, Manager',
			},
			[PERSONA_TYPES.champion]: {
				jobSeniority: 'Associate, coordinator, assistant',
				jobExperience: '+3 years',
				jobTitlesBase: 'Associate, coordinator, assistant',
			},
		}

		const config = personaConfig[v as keyof typeof PERSONA_TYPES]

		const { jobSeniority, jobExperience, jobTitlesBase } = config
		const expertise = expertiseValue ? ` of ${expertiseValue}` : ''

		form.setValue('jobSeniority', jobSeniority)
		form.setValue('jobExperience', jobExperience)
		form.setValue('jobTitles', `${jobTitlesBase}${expertise}`)
	}

	const handleOnExpertiseChange = (v: unknown) =>
		form.setValue('jobTitles', `${seniorityValue ?? ''}${v ? ` of ${v as string}` : ''}`)

	const handleOnJobSeniorityChange = (v: unknown) =>
		form.setValue('jobTitles', `${(v as string) ?? ''}${expertiseValue ? ` of ${expertiseValue}` : ''}`)

	const heading = (() => {
		switch (true) {
			case isDuplicating:
				return `Duplicate Persona (${persona?.expertise})`
			case !!persona?.id:
				return 'Edit Persona'
			default:
				return 'Create Persona'
		}
	})()

	return (
		<Dialog
			defaultOpen={true}
			onOpenChange={isOpen => !isOpen && closeModal(MODAL_NAME.PersonaCreateModal)}
			dialogHeading={heading}
			actions={[
				{ type: 'cancel', label: 'Close' },
				{ type: 'submit', formId, name: 'intent', value: 'persona' },
			]}
			size="xl"
			isSubmitting={actionFetcher.state === 'submitting'}
		>
			<FormWrapper
				formId={formId}
				action={action}
				method={method}
				formProps={form}
				fetcher={actionFetcher}
				className="flex flex-col gap-4"
			>
				<div className="grid grid-cols-3 gap-4">
					<FormField
						fieldType="select"
						name="type"
						label={
							<div className="flex items-center gap-1">
								Type
								<ProductTip content="Specify the role of the persona (e.g., Decision Maker)." />
							</div>
						}
						options={PERSONA_TYPE_OPTIONS}
						onChange={handleOnTypeChange}
					/>
					<FormField
						fieldType="select"
						name="status"
						label={
							<div className="flex items-center gap-1">
								Status
								<ProductTip content="Indicates the current state of the persona in your sales process." />
							</div>
						}
						options={getAvailableStatuses()}
					/>
					<FormField
						fieldType="select"
						name="priority"
						label={
							<div className="flex items-center gap-1">
								Priority
								<ProductTip content="Set the priority level (High, Medium, Low) for resource allocation and focus." />
							</div>
						}
						options={getAvailablePriorities()}
					/>
				</div>
				<section>
					<h2 className="mb-3.5 mt-1 text-title-sm">Setup Info</h2>
					<div className="flex flex-col gap-4">
						<FormField
							fieldType="text"
							name="expertise"
							label={
								<div className="flex items-center gap-1">
									Expertise
									<ProductTip content="Define the primary area of expertise relevant to the persona (e.g., Revenue)." />
								</div>
							}
							onChange={handleOnExpertiseChange}
						/>
						<FormField
							fieldType="text"
							name="jobSeniority"
							label={
								<div className="flex items-center gap-1">
									Job Seniority
									<ProductTip content="Specify the seniority level (e.g., Chief, President, SVP, VP, Director)." />
								</div>
							}
							onChange={handleOnJobSeniorityChange}
						/>
						<FormField
							fieldType="text"
							name="jobExperience"
							label={
								<div className="flex items-center gap-1">
									Job Experience
									<ProductTip content="Indicate the required years of experience (e.g., +7 years)." />
								</div>
							}
						/>
						<FormField
							fieldType="text"
							name="jobTitles"
							label={
								<div className="flex items-center gap-1">
									Job Title examples
									<ProductTip content="Provide examples of relevant job titles (e.g., CRO, Chief, President, SVP, VP, Director of Revenue or Sales)." />
								</div>
							}
						/>
						<FormField
							fieldType="textarea"
							name="typeOfTask"
							disableField={typesOfTasksFetcher.state !== 'idle'}
							label={
								<div className="flex w-full items-center justify-between">
									<div className="flex items-center gap-1">
										Types of Tasks
										<ProductTip content="Describe the key responsibilities and tasks undertaken by the persona (e.g., implementing account-based methodologies). Be as specific and concrete as possible, since this information will influence the pain points and personas that the model generates." />
									</div>
									<div>
										<CustomTooltip
											label="The job title examples field is required to generate"
											disabled={!!jobTitlesValue}
										>
											<StatusButton
												onClick={handleGenerateTypeOfTask}
												status={typesOfTasksFetcher.state !== 'idle' ? 'pending' : 'idle'}
												disabled={!jobTitlesValue || typesOfTasksFetcher.state !== 'idle'}
												type="button"
												variant="ghost"
												size="none"
												className="flex gap-1 py-0 text-body-sm"
											>
												<Icon name="magic-wand" />
												Generate with AI
											</StatusButton>
										</CustomTooltip>
									</div>
								</div>
							}
						/>
						<FormField
							fieldType="select"
							name="reportsTo"
							label={
								<div className="flex items-center gap-1">
									Reports To
									<ProductTip content="Specify the reporting structure (e.g., CEO)." />
								</div>
							}
							options={[
								{ value: '-', label: '-' },
								...personas.map(p => ({ value: p.id, label: p.type })),
								{ value: 'other', label: 'Other' },
							]}
						/>
						<FormField
							fieldType="text"
							name="reportsOverride"
							label="Reports To (manual)"
							disableField={reportsToValue !== 'other' && !form.getValues('reportsOverride')}
						/>
						<FormField
							fieldType="number"
							name="painLength"
							label={
								<div className="flex items-center gap-1">
									Pain Length (# of words)
									<ProductTip content="Pain Length (# of words): Set the word count for describing pain points." />
								</div>
							}
							placeholder="Enter # of words"
						/>
						<FormField
							fieldType="textarea"
							name="painOverride"
							label={
								<div className="flex items-center gap-1">
									Fine tune pain
									<ProductTip content="Provide detailed pain points to guide AI prompts (e.g., Focus on new business revenue and account expansion, not retention)." />
								</div>
							}
						/>
					</div>
				</section>
			</FormWrapper>
		</Dialog>
	)
}
