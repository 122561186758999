import { captureException } from '@sentry/react'
import { type ActionFunctionArgs, redirect } from 'react-router'
import { verticalsMutations } from '#src/api/icp/company/verticals/mutations'
import { CreateCsvFormSchema } from '#src/api/icp/company/verticals/schemas'
import { showToast } from '#src/context/ToastContext'
import { parseFormDataNew } from '#src/utils/forms'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ request, params }: ActionFunctionArgs) => {
	const { company } = await getCompany(params)
	validateRouteParams(params, ['ecosystemId'])
	const formData = await request.formData()
	const submission = parseFormDataNew(formData, CreateCsvFormSchema)

	try {
		await verticalsMutations.import(company.id, submission.value)

		showToast({
			message: `Import successful`,
			type: 'success',
		})
	} catch (err) {
		captureException(err)

		if (err instanceof Response) {
			const { message } = (await err.json()) as { message: string }

			return {
				ok: false,
				message,
			}
		}
	}

	return redirect(routes.calibrate.verticals.index({ companyId: company.id, ecosystemId: params.ecosystemId }))
}
