import { type ActionFunctionArgs } from 'react-router'
import { orgDataEnrichmentWorkflowsMutations } from '#src/api/organise/data-enrichment/workflows/mutations'
import { MODAL_NAME } from '#src/constants/modals'
import { showToast } from '#src/context/ToastContext'
import { validateRouteParams } from '#src/utils/misc'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'
import { closeModalServer } from '#src/utils/server/modals'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ params }: ActionFunctionArgs) => {
	validateRouteParams(params, ['jobId'])

	try {
		await orgDataEnrichmentWorkflowsMutations.stop(params.jobId)

		showToast({
			message: 'Job stopped successfully',
			type: 'success',
		})
		closeModalServer(MODAL_NAME.AccountEnrichmentStop)

		return {
			ok: true,
		}
	} catch (e) {
		return await serverFormErrorHandler(e)
	}
}
