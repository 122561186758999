import { captureException } from '@sentry/react'
import { type ActionFunctionArgs, redirect } from 'react-router'
import { companyChatRoleplayScenarioMutations } from '#src/api/icp/company/chat/roleplay/scenario/mutations'
import { ScenarioSaveTagsFormSchema } from '#src/api/icp/company/chat/roleplay/scenario/schemas'
import { showToast } from '#src/context/ToastContext'
import { parseFormDataNew } from '#src/utils/forms'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ request, params }: ActionFunctionArgs) => {
	validateRouteParams(params, ['scenarioId'])
	const { company } = await getCompany(params)
	const formData = await request.formData()
	const submission = parseFormDataNew(formData, ScenarioSaveTagsFormSchema)

	try {
		await companyChatRoleplayScenarioMutations.createTags(company.id, params.scenarioId, submission.value)

		showToast({
			message: `Tags successfully saved`,
			type: 'success',
		})

		return redirect(routes.enable.roleplay.scenario.index({ companyId: company.id }))
	} catch (error) {
		captureException(error)

		showToast({
			message: `Failed to save tags`,
			type: 'error',
		})

		return null
	}
}
