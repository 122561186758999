import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { forwardRef, type ButtonHTMLAttributes } from 'react'
import { cn } from '#src/utils/misc'

export const buttonVariants = cva(
	'inline-flex items-center justify-center rounded transition-colors outline-none disabled:pointer-events-none disabled:text-neutral-1-fg-disabled disabled:bg-neutral-1-bg-disabled disabled:cursor-not-allowed',
	{
		variants: {
			variant: {
				default:
					'border border-transparent bg-brand-3-bg text-neutral-inverse-fg hover:bg-brand-3-bg-hover active:bg-brand-3-bg-pressed',
				secondary:
					'border border-transparent bg-neutral-1-bg hover:bg-neutral-1-bg-hover active:bg-neutral-1-bg-pressed text-brand-1-fg',
				outline:
					'border border-brand-1-bd bg-transparent text-brand-1-fg hover:border-brand-1-bd-hover hover:text-white hover:bg-brand-3-bg-hover active:bg-content-brand active:border-brand-1-bd-pressed disabled:border-neutral-2-bd disabled:bg-transparent',
				danger:
					'border border-status-danger-bg bg-status-danger-bg text-neutral-inverse-fg hover:border-status-danger-bg-hover hover:bg-status-danger-bg-hover active:border-status-danger-bg-pressed active:bg-status-danger-bg-pressed disabled:border-neutral-1-bg-disabled',
				['danger-outline']:
					'border border-status-danger-bg bg-transparent text-status-danger-fg hover:border-status-danger-bg-hover active:border-status-danger-bg-pressed disabled:border-neutral-1-bg-disabled',
				ghost:
					'border border-transparent text-brand-1-fg bg-transparent hover:not(:disabled):bg-neutral-2-bg active:not(:disabled):bg-neutral-2-bg disabled:bg-transparent',
				icon: 'border border-transparent bg-transparent hover:bg-neutral-2-bg-hover active:bg-neutral-1-bg-pressed text-neutral-3-fg data-[state=open]:bg-neutral-2-bg',
				['icon-interactive']:
					'border border-transparent bg-brand-1-bg hover:bg-brand-1-bg-hover active:bg-brand-1-bg-pressed data-[state=open]:bg-brand-1-fg text-brand-1-fg data-[state=open]:text-neutral-inverse-fg',
				none: 'border-none bg-transparent text-neutral-1-fg outline-none focus-visible:ring-0 focus-within:ring-0 ring-neutral-2-bd ring-offset-0',
			},
			size: {
				default: 'text-button-sm px-3 py-2 h-10',
				xs: 'text-button-sm px-1.5 py-1 h-8',
				sm: 'text-button-sm px-3 py-2 h-8',
				md: 'text-button-sm px-3 py-3 h-10',
				lg: 'text-button-lg px-3 py-6 h-12',
				icon: 'text-button-lg h-6 w-6',
				none: 'text-button-sm p-0 h-auto w-auto',
			},
		},
		defaultVariants: {
			variant: 'default',
			size: 'default',
		},
	},
)

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
	asChild?: boolean
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant, size, asChild = false, ...props }, ref) => {
		const Comp = asChild ? Slot : 'button'
		return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />
	},
)
Button.displayName = 'Button'
