import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { PlayTemplatesModal } from '#src/modals/play-templates/PlayTemplates'
import { routes } from '#src/utils/routes'

export const View = () => {
	const { openModal } = useModals()
	const { companyId } = useCompany()
	const { pathname } = useLocation()

	useEffect(() => {
		if (pathname !== routes.enable.playTemplates.index({ companyId })) {
			return
		}

		openModal({
			name: MODAL_NAME.PlayTemplates,
			component: PlayTemplatesModal,
			settings: { routeToRedirectOnClose: routes.enable.plays.index({ companyId }) },
		})
	}, [companyId, openModal, pathname])

	return <Outlet />
}
