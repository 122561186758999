import { type z } from 'zod'
import { companyChatRoleplaySessionQueriesKeys } from '#src/api/icp/company/chat/roleplay/session/queries'
import { client } from '#src/main'
import { type CompanySchema } from '#src/routes/company/schema'
import { safeFetch } from '#src/utils/safeFetch'
import { companyChatRoleplaySessionEndpoints } from './endpoints'
import { SessionCreateAPISchema, type SessionCreateFormSchema } from './schemas'

const mutations = {
	create: async (companyId: z.infer<typeof CompanySchema.shape.id>, data: z.infer<typeof SessionCreateFormSchema>) => {
		const res = await safeFetch(SessionCreateAPISchema, companyChatRoleplaySessionEndpoints.create(companyId), {
			method: 'POST',
			body: JSON.stringify(data),
		})

		await client.invalidateQueries({
			queryKey: companyChatRoleplaySessionQueriesKeys.all,
		})

		return res
	},
}

export { mutations as companyChatRoleplaySessionMutations }
