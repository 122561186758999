import { useQuery } from '@tanstack/react-query'
import { createSearchParams, Link, useLoaderData, useNavigate, useSearchParams } from 'react-router'
import { LRA_JOB_STATUS as JobStatus, LRA_JOB_STATUS_REASONING } from '#src/api/organise/lists/constants'
import { orgListsQueries } from '#src/api/organise/lists/queries'
import { Chip } from '#src/components/chip'
import FiltersSection from '#src/components/FiltersSection'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { PageLayout } from '#src/components/ui/PageLayout'
import { PaginatedTable } from '#src/components/ui/PaginatedTable'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import LayoutContentHack from '#src/layouts/LayoutContentHack'
import { LeadResearchAgentJobArchiveModal } from '#src/modals/lead-research-agent/Archive'
import { LeadResearchAgentJobDownloadModal } from '#src/modals/lead-research-agent/Download'
import { LeadResearchAgentJobEditModal } from '#src/modals/lead-research-agent/Edit'
import { LeadResearchAgentJobStopModal } from '#src/modals/lead-research-agent/Stop'
import { canDownload, canStop } from '#src/routes/organize/lead-research-agent/utils'
import { formatDateToReadableString } from '#src/utils/date'
import { formatNumberToPct } from '#src/utils/numbers'
import { routes } from '#src/utils/routes'
import { type LRAListLoaderRes } from '../routes/list'
import { ListStatisticsCard, StatusLabel } from './components'

export const View = () => {
	const {
		values: { sort, page },
		data,
		filters,
	} = useLoaderData<LRAListLoaderRes>()
	const { company, companyId } = useCompany()

	const { data: statistics } = useQuery({
		...orgListsQueries.statistics(company.id),
		initialData: data.statistics,
		refetchInterval: 10000,
	})

	const { data: jobs } = useQuery({
		...orgListsQueries.list(company.id, page, sort, filters),
		initialData: data.jobs,
		...(statistics.inProgressCount + statistics.inQueueCount > 0 && {
			refetchInterval: 10000,
		}),
	})

	const navigate = useNavigate()
	const [searchParams] = useSearchParams()

	const { openModal } = useModals()

	return (
		<PageLayout
			title="Lead Research Agent Jobs"
			actions={[
				{
					icon: 'add',
					title: 'Create job',
					to: {
						pathname: routes.leadResearchAgent.create({ companyId }),
						search: searchParams.toString(),
					},
					variant: 'default',
				},
			]}
		>
			<div className="grid w-full grid-cols-3 grid-rows-1 gap-6">
				<ListStatisticsCard name="In queue" count={statistics.inQueueCount} icon="circle-dash" />
				<ListStatisticsCard name="In progress" count={statistics.inProgressCount} icon="incomplete" />
				<ListStatisticsCard
					name="Completed"
					count={statistics.completedCount}
					icon="checkmark-filled"
					iconVariant="success"
				/>
			</div>
			<LayoutContentHack>
				<PaginatedTable
					stickyHeader
					filtersComponent={
						<FiltersSection
							total={jobs.meta.total}
							selected={filters}
							values={jobs.meta.filters ?? {}}
							filters={[
								{
									type: 'search',
									name: 'search',
									label: 'Search',
								},
								{
									name: 'tags',
									label: 'Tags',
								},
								{
									name: 'archived',
									label: 'Archived',
									multiple: false,
									clearable: false,
								},
							]}
						/>
					}
					currentPage={jobs.meta.current_page}
					totalPages={jobs.meta.last_page}
					sort={sort}
					onPageChange={page =>
						navigate({
							search: createSearchParams({
								...Object.fromEntries(searchParams),
								page: (page + 1).toString(),
							}).toString(),
						})
					}
					onSortChange={data =>
						navigate({
							search: createSearchParams({
								...Object.fromEntries(searchParams),
								sort: data,
							}).toString(),
						})
					}
					onItemClick={item =>
						navigate(
							routes.leadResearchAgent.view({
								companyId,
								jobId: item.id.toString(),
							}),
						)
					}
					columns={[
						{
							name: 'title',
							heading: 'Job name',
							render: item => (
								<Link
									to={routes.leadResearchAgent.view({
										companyId,
										jobId: item.id.toString(),
									})}
								>
									{item.title}
								</Link>
							),
						},
						{
							name: 'createdAt',
							heading: 'Date created',
							sortable: true,
							render: item => formatDateToReadableString(item.createdAt),
						},
						{
							name: 'icp',
							heading: 'ICP Ratio',
							render: item => `${formatNumberToPct(item.statistic.icpRatio)}%`,
						},
						{
							name: 'ibp',
							heading: 'IBP Ratio',
							render: item => `${formatNumberToPct(item.statistic.ibpRatio)}%`,
						},
						{
							name: 'status',
							heading: 'Status',
							render: item => (
								<StatusLabel
									status={item.status}
									{...(item.status === JobStatus.CompletedWithQuestions && {
										tooltip: 'Completed with questions',
									})}
									{...(item.statusReason === LRA_JOB_STATUS_REASONING.InvalidDecisionTree && {
										tooltip:
											'The agent could not create the decision-tree for qualification. Feel free to reach out to your Customer Success Manager for more information',
										suffix: 'warning-alt',
									})}
								/>
							),
						},
						{
							name: 'totalRows',
							heading: 'Items uploaded',
							render: item => item.accountsContactsImported,
						},
						{
							name: 'tags',
							heading: 'Tags',
							render: item => (
								<div className="flex flex-row flex-wrap gap-1">
									{!!item.tags.length &&
										item.tags.map(item => (
											<Chip variant="gray" key={`tag-${item}`}>
												{item}
											</Chip>
										))}
								</div>
							),
						},
						{
							name: 'actions',
							heading: '',
							render: item => (
								<div className="inline-flex flex-row" onClick={e => e.stopPropagation()}>
									{canStop(item.status) && (
										<Button
											variant="icon"
											size="icon"
											onClick={() =>
												openModal({
													name: MODAL_NAME.LeadResearchAgentJobStop,
													component: LeadResearchAgentJobStopModal,
													props: {
														jobId: item.id,
													},
												})
											}
										>
											<Icon name="stop-filled-alt" size="font" className="text-status-danger-fg" />
										</Button>
									)}
									{canDownload(item.status) && (
										<Button
											variant="icon"
											size="icon"
											onClick={() =>
												openModal({
													name: MODAL_NAME.LeadResearchAgentJobDownload,
													component: LeadResearchAgentJobDownloadModal,
													props: {
														jobId: item.id,
													},
												})
											}
										>
											<Icon name="download" size="font" className="text-brand-1-fg" />
										</Button>
									)}
									<Button
										variant="icon"
										size="icon"
										onClick={() =>
											openModal({
												name: MODAL_NAME.LeadResearchAgentJobEdit,
												component: LeadResearchAgentJobEditModal,
												props: {
													jobId: item.id,
												},
											})
										}
									>
										<Icon name="edit" size="font" />
									</Button>
									{!item.archived && (
										<Button
											variant="icon"
											size="icon"
											onClick={() =>
												openModal({
													name: MODAL_NAME.LeadResearchAgentJobArchive,
													component: LeadResearchAgentJobArchiveModal,
													props: {
														jobId: item.id,
													},
												})
											}
										>
											<Icon name="trash" size="font" className="text-status-danger-fg" />
										</Button>
									)}
								</div>
							),
						},
					]}
					data={jobs.data}
				/>
			</LayoutContentHack>
		</PageLayout>
	)
}
