import { type LoaderFunctionArgs } from 'react-router'
import { client } from '#src/main'
import { ecosystemsQuery } from '#src/routes/calibrate/ecosystem-management/ecosystem/queries'
import { getCompany } from '#src/utils/server/company'
import { getUser } from '#src/utils/server/user'

export type NewChatLoaderResponse = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	const { readOnlySession } = await getUser()

	if (readOnlySession) {
		throw new Response('Page Not Found', {
			status: 404,
			statusText: 'Not Found',
		})
	}

	return {
		conversationId: params.conversationId ?? null,
		ecosystems: await client.fetchQuery(ecosystemsQuery(company.id)),
	}
}
