import { z } from 'zod'
import { FilterAPISchema } from '#src/schemas/global'
import { QUEUE_STATUS } from '#src/utils/enumerations'
import { zString } from '#src/utils/validation'

export const ContactsEnrichItemAPISchema = z.object({
	id: z.number(),
	firstName: z.string().nullable(),
	lastName: z.string().nullable(),
	email: z.string().email().nullable(),
	phone: z.string().nullable(),
	companyName: z.string().nullable(),
	companyWebsite: z.string().nullable(),
	linkedinUrl: z.string().nullable(),
	status: z.nativeEnum(QUEUE_STATUS),
	emailProvider: z.string().nullable(),
	phoneProvider: z.string().nullable(),
	createdAt: z.string().datetime(),
	updatedAt: z.string().datetime().nullable(),
})

export const ContactsEnrichListAPISchema = ContactsEnrichItemAPISchema

export const NewContactEnrichFormSchema = z.object({
	linkedinUrl: zString(),
})

export const DownloadContactsEnrichSchema = z.object({
	ids: z.array(z.number()),
})

export const ContactsEnrichFiltersAPISchema = z.object({
	status: z.array(FilterAPISchema),
	email: z.array(FilterAPISchema),
	phone: z.array(FilterAPISchema),
	dateFrom: z.array(FilterAPISchema),
	dateTo: z.array(FilterAPISchema),
})
