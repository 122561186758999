import { type ActionFunctionArgs } from 'react-router'
import { playsTemplatesMutations } from '#src/api/icp/company/plays-templates/mutations'
import { MODAL_NAME } from '#src/constants/modals'
import { showToast } from '#src/context/ToastContext'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'
import { closeModalServer } from '#src/utils/server/modals'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ params, request }: ActionFunctionArgs) => {
	if (request.method !== 'DELETE') {
		throw new Response('Wrong action method', {
			status: 400,
			statusText: 'Bad Request',
		})
	}
	const { company } = await getCompany(params)
	validateRouteParams(params, ['templateId'])

	try {
		await playsTemplatesMutations.delete(company.id, Number(params.templateId))
		closeModalServer(MODAL_NAME.PlayTemplateDelete)
		showToast({
			message: 'Play template deleted',
			type: 'success',
		})
		return { ok: true }
	} catch (e) {
		return await serverFormErrorHandler(e)
	}
}
