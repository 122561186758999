import { z } from 'zod'
import { PaginatedAPIResSchema } from '#src/schemas/global'
import { zNumber, zString } from '#src/utils/validation'

export enum SessionStatus {
	WaitingForContact = 'waiting_for_contact',
	Closed = 'closed',
	Ready = 'ready',
	Initializing = 'initializing',
	Crashed = 'crashed',
}

export const StatusSchema = z.enum([
	SessionStatus.WaitingForContact,
	SessionStatus.Closed,
	SessionStatus.Ready,
	SessionStatus.Initializing,
	SessionStatus.Crashed,
])

export const SessionStatusAPISchema = z.object({
	status: StatusSchema,
})

export const SessionsListItemAPISchema = z.object({
	id: z.string(),
	title: z.string().nullable(),
	createdBy: z.string(),
	user: z.string(),
	attempts: z.number(),
	scenarioTitle: z.string().nullable(),
	name: z.string(),
	personaType: z.string().nullable(),
	createdAt: z.string(),
	dueAt: z.string().nullable(),
})

export const SessionsAPISchema = PaginatedAPIResSchema(SessionsListItemAPISchema)

export const FilterAPISchema = z.string().or(
	z.object({
		label: z.string(),
		value: z.string(),
	}),
)

export const SessionsFiltersAPISchema = z.object({
	agenda: z.array(FilterAPISchema),
	createdBy: z.array(FilterAPISchema),
	user: z.array(FilterAPISchema),
	hasAttempts: z.array(FilterAPISchema),
})

export enum PersonalityTrait {
	Analytical = 'analytical',
	Amiable = 'amiable',
	Expressive = 'expressive',
	Driver = 'driver',
}

export enum EmotionalState {
	Skeptical = 'skeptical',
	Supportive = 'supportive',
	Busy = 'busy',
	Indecisive = 'indecisive',
}

export enum CommunicationStyle {
	Direct = 'direct',
	Indirect = 'indirect',
	Formal = 'formal',
	Informal = 'informal',
}

export enum DecisionMakingProcess {
	Collaborative = 'collaborative',
	Autonomous = 'autonomous',
	Hierarchical = 'hierarchical',
}

export const PersonalitySchema = z.object({
	personalityTrait: z.nativeEnum(PersonalityTrait),
	emotionalState: z.nativeEnum(EmotionalState),
	communicationStyle: z.nativeEnum(CommunicationStyle),
	decisionMakingProcess: z.nativeEnum(DecisionMakingProcess),
	voice: z.string(),
})

export const ConversationSchema = z.object({
	id: z.string(),
	name: z.string(),
	user: z.string(),
	createdAt: z.string(),
	canDelete: z.boolean().optional(),
	type: z.string(),
	status: StatusSchema,
	lastMessage: z.string().nullable(),
	evaluation: z
		.object({
			status: z.string(),
			value: z.string().nullable(),
		})
		.nullable(),
	personality: PersonalitySchema,
	title: z.string().optional().nullable(),
	scenarioTitle: z.string().optional().nullable(),
	scenario: z
		.object({
			id: z.string(),
			// personaMapping: z
			// 	.object({
			// 		ecosystem: z.string(),
			// 		vertical: z.string(),
			// 		personaType: z.string(),
			// 		expertise: z.string(),
			// 	})
			// 	.nullable(),
			title: z.string(),
			instructions: z.string().nullable(),
		})
		.nullable(),
	attempts: z.number(),
	createdBy: z.string(),
	personaInfo: z
		.object({
			ecosystem: z.string().nullable(),
			vertical: z.string().nullable(),
			type: z.string().nullable(),
			expertise: z.string().nullable(),
		})
		.nullable(),
})

export const SessionAPISchema = z.object({
	conversation: ConversationSchema,
	participant: z
		.object({
			info: z.object({ title: z.string().optional().nullable() }),
			persona: z.object({ type: z.string().optional().nullable() }),
		})
		.nullable(),
	writable: z.boolean(),
})

export const SessionFormDetailsSchema = z.object({
	title: zString(),
	templateId: zNumber(z.number()),
	scenario: z.string().nullable(),
	scenarioTitle: z.string(),
})
export const SessionFormPersonaMappingSchema = z.object({
	// they are ignored on backend
	ecosystemId: zNumber(z.number()),
	// they are ignored on backend
	verticalId: zNumber(z.number()),
	personaId: zNumber(z.number()),
	linkedinUrl: z.string().url().or(z.string().max(0)).nullable(), // url or empty string
	contactId: z.string().optional(),
})
export const SessionFormPersonalitySchema = z.object({
	personality: PersonalitySchema,
})
export const SessionFormEvaluationSchema = z.object({
	evaluationTemplateId: zNumber(z.number()).nullable(),
	evaluation: z.string().nullable(),
})

export const SessionCreateFormSchema = z
	.object({})
	.merge(SessionFormDetailsSchema)
	.merge(SessionFormPersonaMappingSchema)
	.merge(SessionFormPersonalitySchema)
	.merge(SessionFormEvaluationSchema)

export const SessionCreateAPISchema = z.object({
	conversationId: z.string(),
})
