import { type LoaderFunctionArgs } from 'react-router'
import { companyChatArchiveQueries } from '#src/api/icp/company/chat/archive/queries'
import { companyChatRoleplaySessionQueries } from '#src/api/icp/company/chat/roleplay/session/queries'
import { client } from '#src/main'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	validateRouteParams(params, ['conversationId', 'attemptId'])

	return {
		attempt: await client.fetchQuery(
			companyChatArchiveQueries.item(company.id, params.conversationId, Number(params.attemptId)),
		),
		session: await client.fetchQuery(companyChatRoleplaySessionQueries.item(company.id, params.conversationId)),
	}
}
