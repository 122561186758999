import { useQuery } from '@tanstack/react-query'
import { createSearchParams, useLoaderData, useNavigate, useSearchParams } from 'react-router'
import { contactsEnrichQueries } from '#src/api/icp/company/contacts-enrich/queries'
import ContactAvatar from '#src/components/chat/avatar'
import FiltersSection from '#src/components/FiltersSection'
import { getQueueStatus, QueueStatusLabel } from '#src/components/shared'
import { Icon } from '#src/components/ui/icon'
import { InlineSpinner } from '#src/components/ui/InlineSpinner'
import { PageLayout } from '#src/components/ui/PageLayout'
import { PaginatedTable } from '#src/components/ui/PaginatedTable'
import { CustomTooltip } from '#src/components/ui/tooltip'
import LayoutContentHack from '#src/layouts/LayoutContentHack'
import { formatDateToReadableString } from '#src/utils/date'
import { QUEUE_STATUS } from '#src/utils/enumerations'
import { routes } from '#src/utils/routes'
import { type IndexLoaderRes } from '../routes'
import { SingleDownload } from './components'

export const View = () => {
	const {
		filters,
		data: { initialData },
		values: { companyId, baseQueryParams },
	} = useLoaderData<IndexLoaderRes>()
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()

	const { data: enrichedContacts } = useQuery({
		...contactsEnrichQueries.list(companyId, baseQueryParams.page, baseQueryParams.sort, filters.selected),
		initialData,
		refetchInterval: 5000,
		enabled: !!initialData,
	})

	return (
		<PageLayout
			title="Waterfall Enrichment"
			actions={[
				{
					title: 'Add Contact',
					icon: 'add',
					to: routes.enable.contactsEnrich.new({ companyId }),
				},
			]}
		>
			<LayoutContentHack>
				<PaginatedTable
					stickyHeader
					filtersComponent={
						<FiltersSection
							total={enrichedContacts.meta.total}
							selected={filters.selected}
							values={filters.values}
							filters={[
								{
									name: ['dateFrom', 'dateTo'],
									label: 'Date added',
									type: 'dateRange',
								},
								{
									name: 'status',
									label: 'Status',
									multiple: false,
								},
								{
									name: 'email',
									label: 'Email',
								},
								{
									name: 'phone',
									label: 'Phone',
								},
							]}
						/>
					}
					data={enrichedContacts.data}
					currentPage={enrichedContacts.meta.current_page}
					totalPages={enrichedContacts.meta.last_page}
					totalEntriesCount={enrichedContacts.meta.total}
					sort={baseQueryParams.sort}
					onPageChange={page =>
						navigate({
							search: createSearchParams({
								...Object.fromEntries(searchParams),
								page: (page + 1).toString(),
							}).toString(),
						})
					}
					onSortChange={data =>
						navigate({
							search: createSearchParams({
								...Object.fromEntries(searchParams),
								sort: data,
							}).toString(),
						})
					}
					exportSelectedFetcherProps={{
						method: 'POST',
						action: routes.enable.contactsEnrich.download({ companyId }),
					}}
					exportAllFetcherProps={{
						method: 'POST',
						action: routes.enable.contactsEnrich.download({ companyId }),
					}}
					headerVariant="gray"
					enableCheckbox
					columns={[
						{
							name: 'name',
							heading: 'Name',
							render: item => (
								<div className="flex items-center gap-2 font-medium">
									<ContactAvatar
										variant="blueInverse"
										size="sm"
										initial={`${item.firstName} ${item.lastName}`}
										initialCount={2}
									/>
									{`${item.firstName} ${item.lastName}`}
								</div>
							),
						},
						{
							name: 'email',
							heading: 'Email',
							render: ({ status, email, emailProvider }) => {
								if (email) {
									return emailProvider ? (
										<CustomTooltip label={`Provider: ${emailProvider} / unverified`}>
											<span className="text-link">{email}</span>
										</CustomTooltip>
									) : (
										<span className="text-link">{email}</span>
									)
								}

								return status === QUEUE_STATUS.completed ? (
									'-'
								) : (
									<CustomTooltip label="Enriching in progress...">
										<InlineSpinner textVariant="none" className="inline-flex" />
									</CustomTooltip>
								)
							},
						},
						{
							name: 'phone',
							heading: 'Phone',
							render: ({ status, phone, phoneProvider }) => {
								if (phone) {
									return phoneProvider ? (
										<CustomTooltip label={`Provider: ${phoneProvider} / unverified`}>
											<span className="text-link">{phone}</span>
										</CustomTooltip>
									) : (
										<span className="text-link">{phone}</span>
									)
								}

								return status === QUEUE_STATUS.completed ? (
									'-'
								) : (
									<CustomTooltip label="Enriching in progress...">
										<InlineSpinner textVariant="none" className="inline-flex" />
									</CustomTooltip>
								)
							},
						},
						{
							name: 'companyWebsite',
							heading: 'Company Website',
							render: item =>
								item.companyWebsite ? (
									<a href={item.companyWebsite} target="__blank" className="text-link">
										{item.companyWebsite}
									</a>
								) : item.status !== QUEUE_STATUS.completed ? (
									<InlineSpinner textVariant="none" className="inline-flex" />
								) : (
									'-'
								),
						},
						{
							name: 'createdAt',
							heading: 'Date added',
							sortable: true,
							render: item => formatDateToReadableString(item.createdAt),
						},
						{
							name: 'linkedinUrl',
							heading: 'Links',
							render: item =>
								item.linkedinUrl && (
									<a href={item.linkedinUrl} target="__blank" className="text-neutral-2-fg hover:text-brand-1-fg">
										<Icon name="linkedin" />
									</a>
								),
						},
						{
							name: 'status',
							heading: 'Status',
							render: item => <QueueStatusLabel status={getQueueStatus(item.status)} />,
						},
						{
							name: 'actions',
							heading: '',
							render: item => <SingleDownload item={item} />,
						},
					]}
				/>
			</LayoutContentHack>
		</PageLayout>
	)
}
