import { type LoaderFunctionArgs } from 'react-router'
import { type z } from 'zod'
import { adminPlaysExpertsQueries } from '#src/api/icp/admin/plays-experts/queries'
import { type PlaysExpertsListAPISchema } from '#src/api/icp/admin/plays-experts/schemas'
import { playsTemplatesQueries } from '#src/api/icp/company/plays-templates/queries'
import { client } from '#src/main'
import { PERSONA_TYPES } from '#src/utils/enumerations'
import { getCompany } from '#src/utils/server/company'
import { getUser } from '#src/utils/server/user'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	const { adminSession } = await getUser()

	let experts: z.infer<typeof PlaysExpertsListAPISchema> = []
	if (adminSession) {
		experts = await client.fetchQuery(adminPlaysExpertsQueries.list())
	}

	return {
		data: {
			personaTypes: Object.values(PERSONA_TYPES),
			tags: [
				...new Set((await client.fetchQuery(playsTemplatesQueries.list(company.id))).map(play => play.tags).flat(1)),
			],
			experts,
		},
	}
}
