import { captureException } from '@sentry/react'
import { type ActionFunctionArgs } from 'react-router'
import { contactsEnrichMutations } from '#src/api/icp/company/contacts-enrich/mutations'
import {
	ContactsEnrichFiltersAPISchema,
	ContactsEnrichItemAPISchema,
	DownloadContactsEnrichSchema,
} from '#src/api/icp/company/contacts-enrich/schemas'
import { showToast } from '#src/context/ToastContext'
import { parseFormData } from '#src/utils/forms'
import { getValidSortKeys, paramsToObjectSafe } from '#src/utils/requests'
import { getCompany } from '#src/utils/server/company'

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const { company } = await getCompany(params)
	const formData = await request.formData()
	const queryParamsString = formData.get('queryParams') as string
	const queryParams = new URLSearchParams(queryParamsString)
	const sort = getValidSortKeys(queryParams.getAll('sort'), ContactsEnrichItemAPISchema)
	const filtersSelected = paramsToObjectSafe(queryParams, ContactsEnrichFiltersAPISchema)

	const submission = parseFormData(formData, DownloadContactsEnrichSchema)
	try {
		await contactsEnrichMutations.download(
			company.id,
			submission.value,
			sort,
			filtersSelected as Record<string, string>,
		)
	} catch (err) {
		console.error(err)
		captureException(err)
		showToast({
			message: 'Unexpected error while downloading',
			type: 'error',
		})
	}

	return null
}
