import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useFetcher } from 'react-router'
import { type z } from 'zod'
import { type PersonaAPISchema } from '#src/api/icp/company/persona/schemas'
import { type VerticalAPISchema } from '#src/api/icp/company/verticals/schemas'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import LoaderRequiredComponent from '#src/components/LoaderRequiredComponent'
import ProductTip from '#src/components/product-tip'
import { Dialog } from '#src/components/ui/dialog'
import { Surface } from '#src/components/ui/surface'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { type EcosystemAPISchema } from '#src/routes/calibrate/ecosystem-management/ecosystem/schema'
import {
	type SalesCoachActionResponse,
	type SalesCoachLoaderResponse,
} from '#src/routes/calibrate/ecosystem-management/personas/coach'
import { SalesCoachAdjustmentsFormSchema } from '#src/routes/calibrate/ecosystem-management/personas/coach/schema'
import { MONTH_SELECT_OPTIONS } from '#src/utils/enumerations'
import { routes } from '#src/utils/routes'

const FORM_ID = (personaId: z.infer<typeof PersonaAPISchema.shape.id>) => `persona-sales-coach-${personaId}-edit-form`

type PersonaSalesCoachEditProps = {
	personaId: z.infer<typeof PersonaAPISchema.shape.id>
	ecosystemId: z.infer<typeof EcosystemAPISchema.shape.id>
	verticalId: z.infer<typeof VerticalAPISchema.shape.id>
}

export const PersonaSalesCoachEditModal = (props: PersonaSalesCoachEditProps) => {
	const { companyId } = useCompany()

	return (
		<LoaderRequiredComponent<SalesCoachLoaderResponse, PersonaSalesCoachEditProps>
			route={routes.calibrate.persona.coach.index({
				companyId,
				verticalId: String(props.verticalId),
				ecosystemId: String(props.ecosystemId),
				personaId: String(props.personaId),
			})}
			component={ModalInner}
			initialProps={props}
		/>
	)
}

export const ModalInner = (props: SalesCoachLoaderResponse & PersonaSalesCoachEditProps) => {
	const { salesCoach, personaId, verticalId, ecosystemId } = props
	const { closeModal } = useModals()
	const { companyId } = useCompany()
	const actionFetcher = useFetcher<SalesCoachActionResponse>()

	const form = useForm<z.infer<typeof SalesCoachAdjustmentsFormSchema>>({
		resolver: zodResolver(SalesCoachAdjustmentsFormSchema),
		defaultValues: {
			questionCount: 5,
			defuse: salesCoach?.adjustments?.defuse ?? '',
			wordsMin: salesCoach?.adjustments?.wordsMin ?? 30,
			wordsMax: salesCoach?.adjustments?.wordsMax ?? 50,
			criteria: salesCoach?.adjustments?.criteria ?? '',
			valueProp: salesCoach?.adjustments?.valueProp ?? '',
			month: salesCoach?.timing?.month ? salesCoach.timing.month : undefined,
		},
		mode: 'onSubmit',
	})

	return (
		<Dialog
			defaultOpen={true}
			onOpenChange={isOpen => !isOpen && closeModal(MODAL_NAME.PersonaSalesCoachEdit)}
			dialogHeading="Sales Coach Adjustments"
			actions={[
				{ type: 'cancel', label: 'Close' },
				{ type: 'submit', formId: FORM_ID(personaId), label: 'Save' },
			]}
			isSubmitting={actionFetcher.state === 'submitting'}
		>
			<FormWrapper
				formId={FORM_ID(personaId)}
				formProps={form}
				action={routes.calibrate.persona.coach.index({
					companyId,
					ecosystemId: String(ecosystemId),
					verticalId: String(verticalId),
					personaId: String(personaId),
				})}
				className="flex flex-col gap-6"
				fetcher={actionFetcher}
				actionResponse={actionFetcher.data}
				method="PATCH"
			>
				<Surface className="flex flex-col gap-6 p-4">
					<h6 className="text-title-sm">
						Question calibration{' '}
						<ProductTip content="Calibrated Questions: Tailored questions designed to engage prospects meaningfully by acknowledging their current efforts and introducing strategic areas for improvement." />
					</h6>
					<FormField
						name="questionCount"
						fieldType="number"
						label={
							<>
								Number of Questions
								<ProductTip content="Number of Questions: Set the number of calibrated questions for sales interactions to control the depth and focus of prospect engagement." />
							</>
						}
					/>
					<div className="grid grid-cols-2 gap-6">
						<FormField
							name="wordsMin"
							fieldType="number"
							label={
								<div className="flex items-center gap-1">
									Number of Questions
									<ProductTip content="Number of Questions: Set the number of calibrated questions for sales interactions to control the depth and focus of prospect engagement." />
								</div>
							}
						/>
						<FormField
							name="wordsMax"
							fieldType="number"
							label={
								<div className="flex items-center gap-1">
									Max # of words for Qs
									<ProductTip content="Min/Max # of Words for Questions: Define the word count range for each question to ensure they are concise yet comprehensive enough for meaningful engagements." />
								</div>
							}
						/>
					</div>
					<FormField
						name="criteria"
						fieldType="textarea"
						label={
							<div className="flex items-center gap-1">
								Manual input for calibrated questions
								<ProductTip content="Manual Input for Calibrated Questions: Enter specific questions to address the unique challenges and needs of your prospects." />
							</div>
						}
						rows={5}
					/>
				</Surface>
				<Surface className="flex flex-col gap-6 p-4">
					<h6 className="text-title-sm">Timing + context</h6>
					<FormField
						fieldType="select"
						name="month"
						options={MONTH_SELECT_OPTIONS}
						label={
							<div className="flex items-center gap-1">
								Choose month
								<ProductTip content="Choose Month: Select the month for setting the context and timing of your sales strategy to ensure it is timely and relevant to market conditions." />
							</div>
						}
					/>
					<FormField
						name="valueProp"
						fieldType="textarea"
						label={
							<div className="flex items-center gap-1">
								Manual input for timing context
								<ProductTip content="Manual Input for Timing Context: Provide additional context for the selected month, such as seasonal trends or industry events, to tailor your sales efforts to current market happenings." />
							</div>
						}
						rows={5}
					/>
				</Surface>
				<Surface className="flex flex-col gap-6 p-4">
					<h6 className="text-title-sm">Objection calibration</h6>
					<FormField
						name="defuse"
						fieldType="textarea"
						label={
							<div className="flex items-center gap-1">
								Objections to defuse
								<ProductTip content="Objection Calibration - Objections to Defuse: Common objections from prospects to prepare effective responses for overcoming them during sales conversations." />
							</div>
						}
						rows={5}
					/>
				</Surface>
			</FormWrapper>
		</Dialog>
	)
}
