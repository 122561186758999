import { forwardRef, useEffect } from 'react'
import { useFetcher } from 'react-router'
import { Button, type ButtonProps } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { Toast, ToastDescription, ToastViewport } from '#src/components/ui/toast'
import useCompany from '#src/hooks/useCompany'
import { type SortActionResponse } from '#src/routes/calibrate/ecosystem-management/ecosystem/actions/sort'
import { cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

interface SortEcosystemsAsyncFormProps extends ButtonProps {
	enabled: boolean
	toggleEnabled: () => void
	order: number[]
	onError: () => void
	onCancel: () => void
}

export const SORT_FETCHER_KEY = 'sort-ecosystems'

export const SortEcosystemsAsyncForm = forwardRef<HTMLButtonElement, SortEcosystemsAsyncFormProps>(
	({ enabled, toggleEnabled, order, onError, onCancel, ...props }, ref) => {
		const { companyId } = useCompany()
		const fetcher = useFetcher<SortActionResponse>({ key: SORT_FETCHER_KEY })
		const action = routes.calibrate.ecosystem.sort({
			companyId,
		})

		useEffect(() => {
			if (fetcher.state === 'loading' && !fetcher.data?.ok) {
				onError()
			}
		}, [fetcher.data, fetcher.state, onError])

		return (
			<>
				<Button
					{...props}
					ref={ref}
					type="button"
					disabled={fetcher.state !== 'idle'}
					className={cn(fetcher.state !== 'idle' ? 'animate-pulse' : '', 'flex flex-nowrap items-center gap-1')}
					variant="outline"
					size="sm"
					onClick={() => {
						if (enabled) {
							void fetcher.submit({ ecosystems: order }, { method: 'POST', action, encType: 'application/json' })
						}

						toggleEnabled()
					}}
				>
					{['submitting', 'loading'].includes(fetcher.state) ? (
						<Icon name="update" className="animate-spin" size="sm" />
					) : enabled ? (
						<Icon name="checkmark-outline" size="sm" />
					) : (
						<Icon name="drag-vertical" size="sm" />
					)}
					{enabled ? 'Done' : 'Reorder'}
				</Button>
				{enabled && !['submitting', 'loading'].includes(fetcher.state) ? (
					<Button
						type="button"
						className="flex flex-nowrap items-center gap-1"
						variant="ghost"
						size="sm"
						onClick={() => {
							onCancel()
							toggleEnabled()
						}}
					>
						<Icon name="cross-1" size="sm" />
						Cancel
					</Button>
				) : null}
				<Toast shouldOpen={fetcher.state === 'loading' && !fetcher.data?.ok} duration={3000}>
					<ToastDescription className="flex items-center gap-3">
						<Icon name="error-filled" size="md" className="text-red-60" />
						{fetcher.data?.result ?? 'Error while sorting ecosystems. Try again.'}
					</ToastDescription>
				</Toast>
				<Toast shouldOpen={fetcher.state === 'loading' && !!fetcher.data?.ok} duration={3000}>
					<ToastDescription className="flex items-center gap-3">
						<Icon name="checkmark-filled" size="md" className="text-green-70" />
						Ecosystems sorted successfully
					</ToastDescription>
				</Toast>
				<div className="absolute">
					<ToastViewport />
				</div>
			</>
		)
	},
)
SortEcosystemsAsyncForm.displayName = 'SortEcosystemsAsyncForm'
