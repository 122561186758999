import { z } from 'zod'
import { ConversationSchema } from '#src/api/icp/company/chat/roleplay/session/schemas'

export const SessionDataSchema = z.object({
	conversation: ConversationSchema,
	participant: z
		.object({
			info: z.object({ title: z.string().optional().nullable() }),
			persona: z.object({ type: z.string().optional().nullable() }),
		})
		.nullable(),
	writable: z.boolean(),
})
