import { type VariantProps } from 'class-variance-authority'
import { type ButtonHTMLAttributes, forwardRef, type ReactNode } from 'react'
import { useFetcher, useParams } from 'react-router'
import { Button, type buttonVariants } from '#src/components/ui/button'
import useCompany from '#src/hooks/useCompany'
import { cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

export interface RecalculatePersonasAsyncFormProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	ecosystemId: number
	verticalId: string
	children: ReactNode
	onSubmit?: () => void
}

export const RecalculatePersonasAsyncForm = forwardRef<
	HTMLButtonElement,
	RecalculatePersonasAsyncFormProps & VariantProps<typeof buttonVariants>
>(({ ecosystemId, verticalId, children, className, ...props }, ref) => {
	const params = useParams()
	const { companyId } = useCompany()

	const fetcher = useFetcher()
	const action = routes.calibrate.persona.recalculate({
		companyId,
		ecosystemId: ecosystemId.toString(),
		verticalId: verticalId,
		personaId: params.personaId || null,
	})

	return (
		<Button
			variant="none"
			{...props}
			ref={ref}
			type="button"
			disabled={fetcher.state !== 'idle'}
			className={cn('w-full', fetcher.state !== 'idle' ? 'animate-pulse' : '', className)}
			onClick={() => {
				void fetcher.submit({ intent: 'all' }, { method: 'PUT', action })
			}}
		>
			{children}
		</Button>
	)
})
RecalculatePersonasAsyncForm.displayName = 'RecalculatePersonasAsyncForm'

export interface RecalculatePersonaAsyncFormProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	ecosystemId: number
	verticalId: string
	personaId: string
	children: ReactNode
	onSubmit?: () => void
}

export const RecalculatePersonaAsyncForm = forwardRef<
	HTMLButtonElement,
	RecalculatePersonaAsyncFormProps & VariantProps<typeof buttonVariants>
>(({ ecosystemId, verticalId, personaId, children, className, ...props }, ref) => {
	const { companyId } = useCompany()

	const fetcher = useFetcher()
	const action = routes.calibrate.persona.recalculate({
		companyId,
		ecosystemId: ecosystemId.toString(),
		verticalId: verticalId,
		personaId: personaId,
	})

	return (
		<Button
			variant="none"
			{...props}
			ref={ref}
			type="button"
			disabled={fetcher.state !== 'idle'}
			className={cn('w-full', fetcher.state !== 'idle' ? 'animate-pulse' : '', className)}
			onClick={() => {
				void fetcher.submit({ intent: 'single' }, { method: 'PUT', action })
			}}
		>
			{children}
		</Button>
	)
})
RecalculatePersonaAsyncForm.displayName = 'RecalculatePersonaAsyncForm'
