import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useMemo, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useFetcher } from 'react-router'
import { type z } from 'zod'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import LoaderRequiredComponent from '#src/components/LoaderRequiredComponent'
import { PersonaAvatar } from '#src/components/persona'
import { Dialog } from '#src/components/ui/dialog'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { type NewChatActionResponse } from '#src/routes/enable/chat/actions/new'
import { type NewChatLoaderResponse } from '#src/routes/enable/chat/loaders/new'
import { NewConversationFormSchema } from '#src/routes/enable/chat/schema'
import { routes } from '#src/utils/routes'

const FORM_ID = 'create-copilot-form'

export const CopilotCreateModal = () => {
	const { companyId } = useCompany()

	return (
		<LoaderRequiredComponent<NewChatLoaderResponse>
			route={routes.enable.copilot.new({ companyId, conversationId: null })}
			component={ModalInner}
		/>
	)
}

export const ModalInner = (props: NewChatLoaderResponse) => {
	const { ecosystems } = props
	const { closeModal } = useModals()
	const { companyId } = useCompany()
	const actionFetcher = useFetcher<NewChatActionResponse>()

	const form = useForm<z.infer<typeof NewConversationFormSchema>>({
		resolver: zodResolver(NewConversationFormSchema),
		defaultValues: {
			ecosystemId: undefined,
			verticalId: undefined,
			personaId: undefined,
			linkedinUrl: undefined,
		},
		mode: 'onSubmit',
	})

	const ecosystemId = form.watch('ecosystemId')
	const initialEcosystemId = useRef<number | null>(ecosystemId)
	const selectedEcosystemVerticals = useMemo(
		() => ecosystems.find(ecosystem => String(ecosystem.id) === String(ecosystemId))?.verticals ?? [],
		[ecosystemId, ecosystems],
	)
	const verticalId = form.watch('verticalId')
	const initialVerticalId = useRef<number | null>(verticalId)
	const selectedVerticalPersonas = useMemo(
		() => selectedEcosystemVerticals.find(vertical => String(vertical.id) === String(verticalId))?.personas ?? [],
		[verticalId, selectedEcosystemVerticals],
	)

	useEffect(() => {
		if (initialEcosystemId.current === ecosystemId) {
			return
		}
		initialEcosystemId.current = null
		form.setValue('verticalId', selectedEcosystemVerticals[0]?.id)
	}, [ecosystemId, form, selectedEcosystemVerticals])

	useEffect(() => {
		if (initialVerticalId.current === verticalId) {
			return
		}
		initialVerticalId.current = null
		form.setValue('personaId', selectedVerticalPersonas[0]?.id)
	}, [verticalId, form, selectedVerticalPersonas])

	return (
		<Dialog
			defaultOpen={true}
			onOpenChange={isOpen => !isOpen && closeModal(MODAL_NAME.CopilotCreate)}
			dialogHeading="Create Chat"
			actions={[
				{ type: 'cancel', label: 'Close' },
				{ type: 'submit', formId: FORM_ID, label: 'Create' },
			]}
			isSubmitting={actionFetcher.state === 'submitting'}
		>
			<FormWrapper
				formId={FORM_ID}
				formProps={form}
				action={routes.enable.copilot.new({ companyId, conversationId: null })}
				className="flex flex-col gap-6"
				fetcher={actionFetcher}
				actionResponse={actionFetcher.data}
			>
				<FormField
					name="ecosystemId"
					fieldType="select"
					label="Ecosystem"
					options={ecosystems.map(ecosystem => ({
						value: ecosystem.id,
						label: `${ecosystem.name} (${ecosystem.verticals.length} verticals)`,
						disabled: !ecosystem.verticals.length,
					}))}
				/>
				<FormField
					name="verticalId"
					fieldType="select"
					label="Vertical"
					options={selectedEcosystemVerticals.map(vertical => ({
						value: vertical.id,
						label: `${vertical.name} (${vertical.personas.length} personas)`,
						disabled: !vertical.personas.length,
					}))}
				/>
				<FormField
					name="personaId"
					fieldType="select"
					label="Persona"
					options={selectedVerticalPersonas.map(persona => ({
						value: persona.id,
						label: (
							<div className="grid grid-cols-[max-content,1fr] grid-rows-2 items-center gap-x-2">
								<PersonaAvatar type={persona.type} size="sm" className="row-span-2" />
								<h3 className="text-label-sm text-neutral-3-fg">{persona.expertise}</h3>
								<p className="w-full truncate text-label-sm text-neutral-2-fg">{persona.jobTitles}</p>
							</div>
						),
					}))}
					size="lg"
				/>
				<FormField name="linkedinUrl" fieldType="text" label="LinkedIn URL" placeholder="Enter LinkedIn URL" />
			</FormWrapper>
		</Dialog>
	)
}
