import { type RouteObject } from 'react-router'
import { action as deletePersonaAction } from '#src/routes/calibrate/ecosystem-management/personas/actions/delete'
import { action as recalculatePersonasAction } from '#src/routes/calibrate/ecosystem-management/personas/actions/recalculate'
import SalesCoach, {
	action as salesCoachAction,
	loader as salesCoachLoader,
} from '#src/routes/calibrate/ecosystem-management/personas/coach'
// import { action as recalculateSalesCoachAction } from '#src/routes/calibrate/ecosystem-management/personas/coach/recalculate'
import { autofocusVerticalMutation } from '#src/routes/calibrate/ecosystem-management/verticals/autofocus'
import { routes } from '#src/utils/routes'
import { action as personaGetTypesOfTasksAction } from './actions/getTypesOfTasks'
import { action as personaSaveAction } from './actions/save'
import { loader as personaItemLoader, type LoaderRes as PersonasItemLoaderRes } from './loaders/item'
import { loader as listLoader } from './loaders/list'
import { loader as personasRootLoader, type LoaderRes as PersonasRootLoaderRes } from './loaders/root'
import { loader as personaSaveLoader } from './loaders/save'
import { View as EditView } from './views/edit'
import { View as ItemView } from './views/item'
import { View as ListView } from './views/list'

const calibratePersonasRouterChildren = [
	{
		path: 'edit',
		loader: personaSaveLoader,
		action: personaSaveAction,
		element: <EditView />,
	},
	{
		path: 'recalculate',
		action: recalculatePersonasAction,
	},
] satisfies RouteObject[]

const calibratePersonasRouter = {
	path: 'personas/:ecosystemId/:verticalId',
	loader: personasRootLoader,
	handle: {
		layout: {
			noPadding: false,
		},
		crumb: (data: PersonasRootLoaderRes) => [
			{
				to: routes.calibrate.verticals.index({
					companyId: data.handle.companyId,
					ecosystemId: data.handle.ecosystemId,
				}),
				label: data.ecosystem.name || 'Ecosystem',
				icon: 'ibm-cloud-pak',
			},
			{
				to: routes.calibrate.persona.index({
					companyId: data.handle.companyId,
					ecosystemId: data.handle.ecosystemId,
					verticalId: data.handle.verticalId,
					personaId: null,
				}),
				label: data.vertical.name,
				icon: 'category',
				onClick: () => autofocusVerticalMutation(data.handle.verticalId),
			},
		],
	},
	children: [
		{
			path: '',
			element: <ListView />,
			loader: listLoader,
			children: calibratePersonasRouterChildren,
		},
		{
			path: 'get-types-of-tasks',
			action: personaGetTypesOfTasksAction,
		},
		{
			path: ':personaId',
			element: <ItemView />,
			loader: personaItemLoader,
			handle: {
				crumb: (data: PersonasItemLoaderRes) => [
					{
						to: routes.calibrate.persona.index({
							companyId: data.handle.companyId,
							ecosystemId: data.handle.ecosystemId,
							verticalId: data.handle.verticalId,
							personaId: data.handle.personaId,
						}),
						label: data.persona?.type ?? 'Persona',
						icon: 'user',
					},
				],
				action: (data: PersonasItemLoaderRes) =>
					data.handle.readOnlySession
						? []
						: [
								{
									to: routes.calibrate.persona.edit({
										companyId: data.handle.companyId,
										ecosystemId: data.handle.ecosystemId,
										verticalId: data.handle.verticalId,
										personaId: data.handle.personaId,
									}),
									label: 'Add persona',
									icon: 'user-follow',
								},
							],
			},
			children: [
				...calibratePersonasRouterChildren,
				{
					path: 'delete',
					action: deletePersonaAction,
				},
				{
					path: 'coach',
					element: <SalesCoach />,
					loader: salesCoachLoader,
					action: salesCoachAction,
					// todo: maybe lets remove recalculate, because its not used
					// children: [
					// 	{
					// 		path: 'recalculate/:salescoachId',
					// 		action: recalculateSalesCoachAction,
					// 	},
					// ],
				},
			],
		},
	],
} satisfies RouteObject

export default calibratePersonasRouter
