import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useNavigate, useLoaderData } from 'react-router'
import { type z } from 'zod'
import { ScenarioInviteFormSchema } from '#src/api/icp/company/chat/roleplay/scenario/schemas'
import ContactAvatar from '#src/components/chat/avatar'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import { Dialog } from '#src/components/ui/dialog'
import useCompany from '#src/hooks/useCompany'
import { type LoaderRes } from '#src/routes/enable/roleplay-scenarios/loaders/invite'
import { formatAvatarAbbr } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

const FORM_ID = `roleplay-scenario-invite-participants`

const View = () => {
	const { users, scenarioId } = useLoaderData<LoaderRes>()
	const { companyId } = useCompany()
	const navigate = useNavigate()

	const form = useForm<z.infer<typeof ScenarioInviteFormSchema>>({
		resolver: zodResolver(ScenarioInviteFormSchema),
		defaultValues: {
			members: [],
		},
		mode: 'onSubmit',
	})

	return (
		<Dialog
			defaultOpen={true}
			onOpenChange={isOpen => !isOpen && void navigate(routes.enable.roleplay.scenario.item({ companyId, scenarioId }))}
			dialogHeading="Invite participants"
			actions={[
				{ type: 'cancel', label: 'Close' },
				{ type: 'submit', formId: FORM_ID, label: 'Invite' },
			]}
		>
			<FormWrapper formId={FORM_ID} formProps={form} className="flex flex-col gap-6">
				<FormField
					fieldType="checkbox"
					name="members"
					disableLabel
					options={users.map(item => ({
						value: item.id,
						label: (
							<div className="flex cursor-pointer items-center gap-2 text-body-sm">
								<ContactAvatar
									className="inline-flex shrink-0 items-center justify-center rounded-full"
									variant="gray"
									size="sm"
									initial={formatAvatarAbbr(item.username)}
								/>
								<span className="text-body-sm text-neutral-1-fg">{item.username}</span>
							</div>
						),
					}))}
				/>
			</FormWrapper>
		</Dialog>
	)
}

export default View
