import { type VariantProps } from 'class-variance-authority'
import { STATUS, QUEUE_STATUS } from '#src/utils/enumerations'
import { cn } from '#src/utils/misc'
import { Chip, type chipVariants } from './chip'
import { Icon } from './ui/icon'

const STATUS_VARIANTS = {
	[STATUS.ongoing]: 'green',
	[STATUS.paused]: 'orange',
	[STATUS.crashed]: 'red',
	[QUEUE_STATUS.queued]: 'gray',
	[QUEUE_STATUS.inProgress]: 'blue',
	default: 'green',
}

const STATUS_COLORS = {
	[STATUS.ongoing]: 'text-green-70',
	[STATUS.paused]: 'text-orange-70',
	[STATUS.crashed]: 'text-red-60',
	[QUEUE_STATUS.queued]: 'text-gray-60',
	[QUEUE_STATUS.inProgress]: 'text-blue-60',
	default: '',
}

const Status = ({ status }: { status: string }) => {
	const variant = (STATUS_VARIANTS[status] || STATUS_VARIANTS.default) as NonNullable<
		VariantProps<typeof chipVariants>['variant']
	>

	return (
		<Chip className="py-0.5 pl-0.5 pr-2" variant={variant}>
			<StatusDot status={status} />
			<p>{status}</p>
		</Chip>
	)
}

export const StatusDot = ({ status }: { status: string }) => {
	const colorClass = STATUS_COLORS[status] || STATUS_COLORS.default

	return (
		<Icon name="circle-fill" size="sm" className={cn('flex flex-nowrap items-center transition-colors', colorClass)} />
	)
}

export default Status
