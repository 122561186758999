import { captureException } from '@sentry/react'
import { type ActionFunctionArgs } from 'react-router'
import { z } from 'zod'
import { client } from '#src/main'
import { ecosystemKeys, ecosystemsQuery } from '#src/routes/calibrate/ecosystem-management/ecosystem/queries'
import { EcosystemsOrderFormSchema } from '#src/routes/calibrate/ecosystem-management/ecosystem/schema'
import { reorderEcosystems } from '#src/routes/company/mutations'
import { getCompany } from '#src/utils/server/company'

export type SortActionResponse = Awaited<ReturnType<typeof action>>

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const { company } = await getCompany(params)

	const data = await request.json()
	const submission = await EcosystemsOrderFormSchema.superRefine(async (data, ctx) => {
		const ecosystems = await client.fetchQuery(ecosystemsQuery(company.id, { includeCriteriaSandbox: true }))
		const ecosystemIds = ecosystems.map(({ id }) => id)

		if (!data.ecosystems.every(id => ecosystemIds.includes(id))) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: 'should have all ecosystem ids',
			})
		}
	}).safeParseAsync(data)

	if (!submission.success) {
		// NOTE: This is to ensure state can be reset appropriately
		await client.invalidateQueries({
			queryKey: ecosystemKeys.all,
		})

		return {
			ok: false,
			result: 'Failed to reorder ecosystems',
		}
	}

	try {
		await reorderEcosystems(company.id, submission.data)

		await client.invalidateQueries({
			queryKey: ecosystemKeys.all,
		})

		return {
			ok: true,
		}
	} catch (error) {
		captureException(error)
		return {
			ok: false,
			result: 'Failed to reorder ecosystems',
		}
	}
}
