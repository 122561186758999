import { createSearchParams, useLoaderData, useNavigate, useSearchParams } from 'react-router'
import { type USER_ROLES } from '#src/api/icp/company/user/schemas'
import ContactAvatar from '#src/components/chat/avatar'
import { PageLayout } from '#src/components/ui/PageLayout'
import { PaginatedTable } from '#src/components/ui/PaginatedTable'
import useCompany from '#src/hooks/useCompany'
import { ActivateUserForm } from '#src/routes/settings/team/views/components/ActivateUserForm'
import { formatDateToReadableString } from '#src/utils/date'
import { COMPANY_USER_STATUS } from '#src/utils/enumerations'
import { routes } from '#src/utils/routes'
import { type LoaderRes } from '../loaders'
import { DeactivateUserForm, InviteForm, StatusLabel } from './components'

export const View = () => {
	const { companyId } = useCompany()
	const {
		data: { members, allRoles },
	} = useLoaderData<LoaderRes>()
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()

	const roleText = (role: USER_ROLES) => {
		return (
			Object.entries(allRoles).find((entry) => entry[1] === role)?.[0] ?? 'Unknown role' // prettier-ignore
		)
	}

	return (
		<PageLayout title="Manage Your Team">
			<InviteForm key={members.meta.total} />
			<PaginatedTable
				data={members.data}
				currentPage={members.meta.current_page}
				totalPages={members.meta.last_page}
				totalEntriesCount={members.meta.total}
				headerVariant="gray"
				onPageChange={page =>
					navigate({
						search: createSearchParams({
							...Object.fromEntries(searchParams),
							page: (page + 1).toString(),
						}).toString(),
					})
				}
				onItemClick={item => {
					if (item.status !== COMPANY_USER_STATUS.deactivated) {
						void navigate(routes.settings.team.edit({ companyId, userId: item.id }))
					}
				}}
				isItemDisabled={item => item.status === COMPANY_USER_STATUS.deactivated}
				columns={[
					{
						name: 'email',
						heading: 'Email',
						render: item => (
							<div className="flex items-center gap-2 font-medium">
								<ContactAvatar
									variant="blueInverse"
									size="sm"
									initial={item.email}
									disabled={item.status === COMPANY_USER_STATUS.deactivated}
								/>
								{item.email}
							</div>
						),
					},
					{
						name: 'name',
						heading: 'Name',
						render: item => item.name ?? '-',
					},
					{
						name: 'role',
						heading: 'Role',
						render: item => item.roles.map(roleText).join(', '),
					},
					{
						name: 'status',
						heading: 'Status',
						render: item => <StatusLabel status={item.status} />,
					},
					{
						name: 'createdAt',
						heading: 'Invited',
						render: item => formatDateToReadableString(item.createdAt),
					},
					{
						name: 'actions',
						heading: '',
						render: item => (
							<div
								onClick={e => {
									e.preventDefault()
									e.stopPropagation()
								}}
							>
								<DeactivateUserForm user={item} onlyIcon />
								<ActivateUserForm user={item} />
							</div>
						),
					},
				]}
			/>
		</PageLayout>
	)
}
