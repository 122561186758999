import { useNavigate, useLoaderData, Link } from 'react-router'
import { Dialog } from '#src/components/ui/dialog'
import { Icon } from '#src/components/ui/icon'
import { LabeledValue } from '#src/components/ui/LabeledValue'
import useCompany from '#src/hooks/useCompany'
import { type LoaderRes } from '#src/routes/enable/roleplay-scenarios/loaders/attempts'
import { formatDateToReadableString } from '#src/utils/date'
import { cn } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

const View = () => {
	const {
		handle: { scenarioId, sessionId },
		attempts,
	} = useLoaderData<LoaderRes>()
	const { companyId } = useCompany()
	const navigate = useNavigate()

	return (
		<Dialog
			defaultOpen={true}
			onOpenChange={isOpen => !isOpen && void navigate(routes.enable.roleplay.scenario.item({ companyId, scenarioId }))}
			dialogHeading="Select evaluation"
			actions={[{ type: 'cancel', label: 'Close' }]}
			contentProps={{
				className: 'p-0',
			}}
		>
			{!attempts.length ? (
				<p className="p-6">No attempts yet</p>
			) : (
				<div className="flex flex-col">
					{attempts.map((attempt, index) => (
						<Link
							key={attempt.id}
							to={routes.enable.roleplay.session.review({
								companyId,
								conversationId: sessionId,
								attemptId: String(attempt.id),
							})}
							className="flex flex-row items-center gap-4 border-b border-neutral-1-bd p-4 last:border-b-0"
						>
							<div className="flex-1">
								<div className="flex flex-row items-center">
									<p className="text-title-sm text-neutral-1-fg">Attempt {index + 1}</p>
									<p
										className={cn(
											'flex flex-nowrap items-center gap-1 py-0.5 pl-0.5 pr-2 text-label-md transition-colors',
											attempt.evaluation?.status === 'Completed' ? 'text-green-70' : 'text-orange-70',
										)}
									>
										<Icon name="circle-fill" size="sm" />
										<span>{attempt.evaluation?.status ?? 'Evaluating'}</span>
									</p>
								</div>
								<p className="text-body-md">{formatDateToReadableString(attempt.createdAt, { includeTime: true })}</p>
							</div>
							<div>
								<LabeledValue label="Message count">
									<div className="text-right">{attempt.messageCount}</div>
								</LabeledValue>
							</div>
							<Icon name="chevron-right" className="text-neutral-3-fg" />
						</Link>
					))}
				</div>
			)}
		</Dialog>
	)
}

export default View
