import { useEffect } from 'react'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { AccountDataExplorerCreateModal } from '#src/modals/account-data-explorer/Create'
import { routes } from '#src/utils/routes'

export const View = () => {
	const { openModal } = useModals()
	const { companyId } = useCompany()

	useEffect(() => {
		openModal({
			name: MODAL_NAME.AccountDataExplorerCreate,
			component: AccountDataExplorerCreateModal,
			settings: {
				routeToRedirectOnClose: routes.organize.accountDataExplorer.index({ companyId }),
			},
		})
	}, [companyId, openModal])

	return null
}
