import { type z } from 'zod'
import { type PlaysExpertsItemAPISchema } from '#src/api/icp/admin/plays-experts/schemas'
import DeleteConfirm from '#src/components/delete-confirm'
import LoaderRequiredComponent from '#src/components/LoaderRequiredComponent'
import { MODAL_NAME } from '#src/constants/modals'
import { useModals } from '#src/hooks/useModals'
import { type LoaderRes } from '#src/routes/admin/plays-experts/loaders/item'
import { routes } from '#src/utils/routes'

export type AdminPlaysExpertsDeleteModalProps = {
	expertId: z.infer<typeof PlaysExpertsItemAPISchema.shape.id>
}

export const AdminPlaysExpertsDeleteModal = (props: AdminPlaysExpertsDeleteModalProps) => (
	<LoaderRequiredComponent<LoaderRes, AdminPlaysExpertsDeleteModalProps>
		route={routes.admin.playsExperts.item({ expertId: String(props.expertId) })}
		component={ModalInner}
		initialProps={props}
	/>
)

const ModalInner = (props: AdminPlaysExpertsDeleteModalProps & LoaderRes) => {
	const {
		data: { expert },
	} = props
	const { closeModal } = useModals()

	return (
		<DeleteConfirm
			defaultOpen={true}
			onOpenChange={isOpen => !isOpen && closeModal(MODAL_NAME.AdminPlaysExpertsDelete)}
			action={routes.admin.playsExperts.delete({
				expertId: String(expert.id),
			})}
			method="DELETE"
			actionTitle="Delete"
			title="Delete Plays Expert?"
			description={
				<>
					Are you sure you want to remove <b>{expert.fullName}</b> from Plays experts?
				</>
			}
		/>
	)
}
