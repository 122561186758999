import { queryOptions } from '@tanstack/react-query'
import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { safeFetch } from '#src/utils/safeFetch'
import { playsEndpoints } from './endpoints'
import { PlaysCriteriasAPISchema, PlaysItemAPISchema, PlaysListItemAPISchema, PlaysOperatorsAPISchema } from './schemas'

const keys = {
	all: ['icp', 'company', 'plays'] as const,
	list: (companyId: z.infer<typeof CompanySchema.shape.id>, filters?: Record<string, string>) =>
		[...keys.all, 'list', companyId, JSON.stringify(filters ?? {})] as const,
	item: (companyId: z.infer<typeof CompanySchema.shape.id>, playId: z.infer<typeof PlaysItemAPISchema.shape.id>) =>
		[...keys.all, 'item', companyId, playId] as const,
	criterias: (companyId: z.infer<typeof CompanySchema.shape.id>) => [...keys.all, 'criterias', companyId] as const,
	operators: (companyId: z.infer<typeof CompanySchema.shape.id>) => [...keys.all, 'operators', companyId] as const,
}

const listQuery = (companyId: z.infer<typeof CompanySchema.shape.id>, filters?: Record<string, string>) =>
	queryOptions({
		queryKey: keys.list(companyId, filters),
		queryFn: async () =>
			await safeFetch(PlaysListItemAPISchema, playsEndpoints.list(companyId, filters), {
				method: 'GET',
			}),
	})

const itemQuery = (
	companyId: z.infer<typeof CompanySchema.shape.id>,
	playId: z.infer<typeof PlaysItemAPISchema.shape.id>,
) =>
	queryOptions({
		queryKey: keys.item(companyId, playId),
		queryFn: async () =>
			await safeFetch(PlaysItemAPISchema, playsEndpoints.item(companyId, playId), {
				method: 'GET',
			}),
	})

const criteriasQuery = (companyId: z.infer<typeof CompanySchema.shape.id>) =>
	queryOptions({
		queryKey: keys.criterias(companyId),
		queryFn: async () =>
			await safeFetch(PlaysCriteriasAPISchema, playsEndpoints.criterias(companyId), {
				method: 'GET',
			}),
	})

const operatorsQuery = (companyId: z.infer<typeof CompanySchema.shape.id>) =>
	queryOptions({
		queryKey: keys.operators(companyId),
		queryFn: async () =>
			await safeFetch(PlaysOperatorsAPISchema, playsEndpoints.operators(companyId), {
				method: 'GET',
			}),
	})

const queries = {
	list: listQuery,
	item: itemQuery,
	criterias: criteriasQuery,
	operators: operatorsQuery,
}

export { queries as playsQueries, keys as playsQueriesKeys }
