import { useLoaderData } from 'react-router'
import { Chip } from '#src/components/chip'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { PageLayout, type PageLayoutPropsAction } from '#src/components/ui/PageLayout'
import { MODAL_NAME } from '#src/constants/modals'
import useAuth from '#src/hooks/useAuth'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { RoleplayScenarioCreateModal } from '#src/modals/RoleplayScenarioCreate'
import { type LoaderRes } from '#src/routes/enable/roleplay-scenarios/loaders/item'
import { DeleteScenarioModal } from '#src/routes/enable/roleplay-scenarios/views/components/DeleteScenarioModal'
import ParticipationHistory from '#src/routes/enable/roleplay-scenarios/views/components/ParticipationHistory'
import { ScenarioTabs } from '#src/routes/enable/roleplay-scenarios/views/components/ScenarioTabs'
import { routes } from '#src/utils/routes'
import Participants from './components/Participants'

const View = () => {
	const { companyId } = useCompany()
	const { scenario } = useLoaderData<LoaderRes>()
	const { user } = useAuth()
	const { openModal } = useModals()

	return (
		<PageLayout
			title={scenario.name ?? scenario.title ?? 'Scenario Details'}
			description={
				!!scenario.tags.length && (
					<div className="flex flex-row flex-wrap gap-1">
						{scenario.tags.map(tag => (
							<Chip variant="gray" key={`tag-${tag}`}>
								{tag}
							</Chip>
						))}
					</div>
				)
			}
			actions={[
				{
					to: routes.enable.roleplay.scenario.testSession({
						companyId,
						scenarioId: scenario.id,
					}),
					icon: 'play-filled-alt',
					title: 'Test Roleplay Session',
					variant: 'outline',
				},
				{
					onClick: () =>
						openModal({
							name: MODAL_NAME.RoleplayScenarioCreate,
							component: RoleplayScenarioCreateModal,
							props: {
								duplicateScenarioId: scenario.id,
							},
						}),
					icon: 'copy',
					title: 'Duplicate',
					variant: 'outline',
				},
				...(scenario.canEdit
					? [
							{
								to: routes.enable.roleplay.scenario.edit({
									companyId,
									scenarioId: scenario.id,
								}),
								icon: 'pencil-1',
								title: 'Edit',
								variant: 'outline',
							} satisfies PageLayoutPropsAction,
						]
					: []),
				...(user.email === scenario.createdBy
					? [
							<DeleteScenarioModal scenarioId={scenario.id} key="delete-scenario">
								<Button variant="danger-outline" className="flex flex-nowrap items-center gap-2">
									<Icon name="trash" size="font" className="text-status-danger-fg" />
								</Button>
							</DeleteScenarioModal>,
						]
					: []),
			]}
		>
			<ScenarioTabs />
			<Participants />
			<ParticipationHistory />
		</PageLayout>
	)
}

export default View
