import { cva } from 'class-variance-authority'
import { PLAY_STATUS } from '#src/api/icp/company/plays/constants'
import { Icon } from '#src/components/ui/icon'
import { PLAY_STATUS_NAME } from '#src/routes/enable/plays/root/constants'
import { cn } from '#src/utils/misc'

type Props = {
	status: PLAY_STATUS
}

export const statusDisplayVariants = cva('inline-flex items-center gap-1 text-label-md py-0.5', {
	variants: {
		status: {
			[PLAY_STATUS.Draft]: 'text-neutral-4-fg',
			[PLAY_STATUS.Paused]: 'text-status-warning-fg',
			[PLAY_STATUS.Archived]: 'text-status-danger-fg',
			[PLAY_STATUS.Ongoing]: 'text-status-success-fg',
			[PLAY_STATUS.ToReview]: 'text-status-warning-fg',
		},
	},
	defaultVariants: {
		status: PLAY_STATUS.Draft,
	},
})

const StatusDisplay = (props: Props) => {
	const { status } = props

	return (
		<div className={cn(statusDisplayVariants({ status }))}>
			<Icon name="dot" size="sm" />
			{PLAY_STATUS_NAME[status]}
		</div>
	)
}

export default StatusDisplay
