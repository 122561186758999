import { type ActionFunctionArgs, redirect } from 'react-router'
import { companyChatRoleplayScenarioMutations } from '#src/api/icp/company/chat/roleplay/scenario/mutations'
import { showToast } from '#src/context/ToastContext'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export const action = async ({ params, request }: ActionFunctionArgs) => {
	console.log('daejo iki cia')
	const { company, companyId } = await getCompany(params)
	validateRouteParams(params, ['scenarioId', 'participantId'])

	if (request.method !== 'DELETE') {
		showToast({
			message: 'Unsupported method',
			type: 'error',
		})
		return { ok: false }
	}

	try {
		await companyChatRoleplayScenarioMutations.unassign(company.id, params.scenarioId, Number(params.participantId))

		showToast({
			message: 'Participant unassigned',
			type: 'success',
		})

		return redirect(
			routes.enable.roleplay.scenario.index({
				companyId,
			}),
		)
	} catch (error) {
		showToast({
			message: 'Failed to unassign participant',
			type: 'error',
		})
	}

	return { ok: false }
}
