import { type z } from 'zod'
import { type CompanySchema } from '#src/routes/company/schema'
import { organizeApi } from '#src/utils/endpoints'
import { buildAPISearchParams } from '#src/utils/requests'
import { type LeadResearchAgentItemAPISchema } from './schemas'

const base = () => `${organizeApi}/lists`

const endpoints = {
	list: (
		companyId: z.infer<typeof CompanySchema.shape.id>,
		page: number,
		sort: string[],
		filters?: Record<string, string | string[]>,
	) =>
		`${base()}/?${buildAPISearchParams({
			companyId,
			page,
			sort,
			...filters,
		}).toString()}`,
	item: (jobId: z.infer<typeof LeadResearchAgentItemAPISchema.shape.id>) => `${base()}/${jobId}`,
	itemStop: (jobId: z.infer<typeof LeadResearchAgentItemAPISchema.shape.id>) => `${base()}/${jobId}/cancel`,
	create: () => `${base()}/`,
	statistics: (companyId: z.infer<typeof CompanySchema.shape.id>) => `${base()}/company/${companyId}/statistics`,
	update: (jobId: z.infer<typeof LeadResearchAgentItemAPISchema.shape.id>) => `${base()}/${jobId}`,
}

export { endpoints as orgListsEndpoints }
