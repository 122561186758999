import { type TreeNode } from '#src/components/ExpandableTree'
import { type TreeViewItem } from '#src/components/forms/CheckboxTreeInput'
import { Icon, type IconName } from '#src/components/ui/icon'

export const checkboxTreeViewDataMock: TreeViewItem[] = [
	{
		id: '1',
		label: 'Parent Item 1',
		info: <Icon name="search" size="font" />,
		children: [
			{
				id: '1-1',
				label: 'Child Item 1-1',
				info: <Icon name="close-filled" size="font" />,
			},
			{
				id: '1-2',
				label: 'Child Item 1-2',
				info: <Icon name="category" size="font" />,
			},
		],
	},
	{
		id: '2',
		label: 'Parent Item 2',
		info: <Icon name="category" size="font" />,
		children: [
			{
				id: '2-1',
				label: 'Child Item 2-1',
				info: <Icon name="category" size="font" />,
			},
		],
	},
	{
		id: '3',
		label: 'Parent Item 3',
		info: <Icon name="category" size="font" />,
		children: [
			{
				id: '3-1',
				label: 'Child Item 3-1',
				info: <Icon name="category" size="font" />,
			},
			{
				id: '3-2',
				label: 'Child Item 3-2',
			},
		],
	},
]

export const expandableTreeDataMock: TreeNode[] = [
	{
		id: '1',
		label: 'Dashboard',
		icon: 'data-enrichment' as IconName,
		childrenCount: 3,
		children: [
			{
				id: '1-1',
				label: 'Reports',
				icon: 'user-avatar',
				childrenCount: 0,
			},
			{
				id: '1-2',
				label: 'Analytics',
				icon: 'analytics' as IconName,
				childrenCount: 0,
			},
			{
				id: '1-3',
				label: 'Statistics',
				icon: 'statistics' as IconName,
				childrenCount: 0,
			},
		],
	},
	{
		id: '2',
		label: 'Users',
		icon: 'user-avatar' as IconName,
		childrenCount: 2,
		children: [
			{
				id: '2-1',
				label: 'Manage Users',
				childrenCount: 0,
			},
			{
				id: '2-2',
				label: 'User Permissions',
				childrenCount: 0,
			},
		],
	},
	{
		id: '3',
		label: 'Settings',
		icon: 'settings',
		childrenCount: 1,
		children: [
			{
				id: '3-1',
				label: 'General Settings',
				icon: 'general-settings' as IconName,
				childrenCount: 0,
			},
		],
	},
]
