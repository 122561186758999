import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useFetcher, useNavigate, useSearchParams } from 'react-router'
import { type z } from 'zod'
import {
	DATA_ENRICHMENT_JOB_ENRICHERS,
	ContactWaterfallingJobFormSchema,
} from '#src/api/organise/data-enrichment/workflows/schemas'
import AlertBanner from '#src/components/AlertBanner'
import { Chip } from '#src/components/chip'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import { Button } from '#src/components/ui/button'
import { Dialog } from '#src/components/ui/dialog'
import { FormStepper } from '#src/components/ui/FormStepper'
import { Icon } from '#src/components/ui/icon'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { type ActionRes } from '#src/routes/organize/contact-waterfalling/actions/create'
import { FORM_STEPS } from '#src/routes/organize/contact-waterfalling/constants'
import { routes } from '#src/utils/routes'

const FORM_NAME = 'cw-job-create'

export const ContactWaterfallingCreateModal = () => {
	const { company, companyId } = useCompany()

	const actionFetcher = useFetcher<ActionRes>()
	const actionData = actionFetcher.data

	const navigate = useNavigate()

	const [step, setStep] = useState<FORM_STEPS>(FORM_STEPS.File)

	const form = useForm<z.infer<typeof ContactWaterfallingJobFormSchema>>({
		resolver: zodResolver(ContactWaterfallingJobFormSchema),
		defaultValues: {
			companyId: company.id,
			enrichers: [DATA_ENRICHMENT_JOB_ENRICHERS.Waterfalling],
			comment: null,
		},
		mode: 'onSubmit',
	})

	useEffect(() => {
		const failedStep = actionData && 'failedStep' in actionData ? actionData?.failedStep : undefined
		if (failedStep) {
			setStep(failedStep)
		}
		const errors = actionData && 'errors' in actionData ? actionData.errors : undefined
		if (errors) {
			Object.entries(errors).forEach(([fieldName, errorMessages]) => {
				form.setError(fieldName as keyof z.infer<typeof ContactWaterfallingJobFormSchema>, {
					type: 'server',
					message: errorMessages.join(', '),
				})
			})
		}
	}, [actionData, form])

	const fileWatch = form.watch('file')

	useEffect(() => {
		form.setValue('title', fileWatch instanceof File ? fileWatch.name : '')
	}, [form, fileWatch])

	const handleContinue = async () => {
		switch (step) {
			case FORM_STEPS.File: {
				await form.trigger('file')
				if (!('file' in form.formState.errors)) {
					setStep(FORM_STEPS.AdditionalInfo)
				}
				break
			}
			case FORM_STEPS.AdditionalInfo: {
				await form.trigger('title')
				await form.trigger('comment')
				if (!('title' in form.formState.errors) && !('comment' in form.formState.errors)) {
					const formEl = document.getElementById(FORM_NAME) as HTMLFormElement
					formEl.requestSubmit()
				}
				break
			}
		}
	}

	const handleCancel = () => {
		switch (step) {
			case FORM_STEPS.File:
				void navigate({
					pathname: routes.organize.contactWaterfalling.index({ companyId }),
					search: searchParams.toString(),
				})
				break
			case FORM_STEPS.AdditionalInfo:
				setStep(FORM_STEPS.File)
				break
		}
	}

	const [searchParams] = useSearchParams()
	const { closeModal } = useModals()

	return (
		<Dialog
			size="lg"
			defaultOpen={true}
			onOpenChange={isOpen => !isOpen && closeModal(MODAL_NAME.ContactWaterfallingCreate)}
			dialogHeading="Create Contact Waterfalling Job"
			actions={[
				{
					type: 'cancel',
					label: 'Back',
					onClick: e => {
						e.preventDefault()
						handleCancel()
					},
				},
				{
					type: 'submit',
					formId: FORM_NAME,
					label: step === FORM_STEPS.AdditionalInfo ? 'Start' : 'Continue',
					onClick: e => {
						e.preventDefault()
						void handleContinue()
					},
				},
			]}
			isSubmitting={actionFetcher.state === 'submitting'}
		>
			<FormWrapper
				formId={FORM_NAME}
				formProps={form}
				method="POST"
				action={routes.organize.contactWaterfalling.create({ companyId })}
				encType="multipart/form-data"
				className="flex flex-col gap-6"
				fetcher={actionFetcher}
			>
				<FormStepper
					active={step}
					steps={[
						{ name: FORM_STEPS.File, label: 'Import file' },
						{ name: FORM_STEPS.AdditionalInfo, label: 'Additional info' },
					]}
				/>
				{step === FORM_STEPS.File && (
					<>
						<FormField
							fieldType="file"
							name="file"
							accept={{ 'text/csv': ['.csv'] }}
							label="Import CSV"
							infoContent={
								<div className="flex flex-col items-center gap-8">
									<div className="flex flex-col gap-4">
										<Icon name="upload-thin" className="block h-10 w-10 text-brand-2-fg" />
										<div className="flex flex-col gap-1">
											<p className="text-center text-title-sm text-neutral-1-fg">Drag your CSV file here</p>
											<p className="text-center text-body-md text-neutral-2-fg">
												For best results, please provide as many details as possible to ensure the highest accuracy:
											</p>
										</div>
									</div>
									<div className="flex flex-row items-center justify-center gap-1">
										<div className="flex flex-col items-end gap-2">
											<Chip variant="orange">Contact LinkedIn URL</Chip>
											<div className="flex flex-row items-center justify-center gap-1">
												<Chip variant="indigo">First Name</Chip>
												<Icon name="add" className="text-neutral-4-fg" />
												<Chip variant="indigo">Last Name</Chip>
											</div>
										</div>
										<Icon name="or-operation-2" className="h-[32px] w-[33px] text-neutral-4-fg" />
										<Icon name="add" className="text-neutral-4-fg" />
										<Icon name="optional-operation" className="h-[62px] w-[71px] text-neutral-4-fg" />
										<div className="flex flex-col items-start gap-2">
											<Chip variant="blue">Company LinkedIn URL</Chip>
											<Chip variant="blue">Domain</Chip>
											<Chip variant="blue">Account Name</Chip>
										</div>
									</div>
									<Button type="button">Upload</Button>
								</div>
							}
						/>
					</>
				)}
				{step === FORM_STEPS.AdditionalInfo && (
					<>
						<AlertBanner icon="information-filled">
							Additional info does not affect the Contact waterfalling job results
						</AlertBanner>
						<FormField name="title" fieldType="text" label="Name" placeholder="Enter job name" clearable />
						<FormField
							name="comment"
							fieldType="textarea"
							label="Note"
							placeholder="You can type extra notes about your job"
							rows={10}
						/>
					</>
				)}
			</FormWrapper>
		</Dialog>
	)
}
