import { type ActionFunctionArgs } from 'react-router'
import { companyAccountExportMutations } from '#src/api/icp/company/account-export/mutations'
import { AccountExportFormSchema } from '#src/api/icp/company/account-export/schemas'
import { MODAL_NAME } from '#src/constants/modals'
import { showToast } from '#src/context/ToastContext'
import { parseFormDataNew } from '#src/utils/forms'
import { getCompany } from '#src/utils/server/company'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'
import { closeModalServer } from '#src/utils/server/modals'
import { FORM_STEPS } from '../constants'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const { company } = await getCompany(params)
	const formData = await request.formData()

	try {
		const intent = formData.get('intent') as FORM_STEPS | undefined

		const submission = parseFormDataNew(formData, AccountExportFormSchema)
		switch (intent) {
			case FORM_STEPS.Query: {
				const response = await companyAccountExportMutations.createValidate(company.id, submission.value)
				return { ok: true, data: response }
			}
			case FORM_STEPS.Confirm: {
				await companyAccountExportMutations.create(company.id, submission.value)
				showToast({
					message: 'Job created successfully',
					type: 'success',
				})

				closeModalServer(MODAL_NAME.AccountDataExplorerCreate)

				return { ok: true }
			}
			default: {
				throw Error('intent is required')
			}
		}
	} catch (e) {
		return await serverFormErrorHandler(e)
	}
}
