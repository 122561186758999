import { type Params } from 'react-router'
import { type z } from 'zod'
import { client } from '#src/main'
import { companyQuery } from '#src/routes/company/queries'
import { type CompanySchema } from '#src/routes/company/schema'
import { getUser } from '#src/utils/server/user'

export const getCompany = async (params: Params) => {
	let companyId: z.infer<typeof CompanySchema.shape.id>
	if (params.companyId) {
		companyId = params.companyId
	} else {
		const { user } = await getUser()
		companyId = user.companyId
	}
	const company = await client.fetchQuery(companyQuery(companyId))

	if (!company) {
		throw new Response('Company Not Found', {
			status: 404,
			statusText: 'Not Found',
		})
	}

	return {
		company,
		/** @note use companyId when you need to pass it to routes, and company.id when you realy need it (f.e. queries) */
		companyId: params.companyId ?? null,
	}
}
