import { clsx } from 'clsx'
import { type ReactNode, type RefAttributes, useState } from 'react'
import { type FetcherFormProps, useFetcher } from 'react-router'
import { EmptyStateCard } from '#src/components'
import { Checkbox } from '#src/components/ui/checkbox'
import { DropdownButton } from '#src/components/ui/DropdownButton'
import { Icon } from '#src/components/ui/icon'
import { StatusButton } from '#src/components/ui/status-button'
import { cn } from '#src/utils/misc'
import { getObjectValueByKey } from '#src/utils/objects'

export type TableProps<T> = {
	data: T[]
	sort?: string[]
	columns: {
		hidden?: boolean
		name: string
		heading: string
		sortable?: boolean
		render: (data: T) => ReactNode
	}[]
	onItemClick?: (data: T) => void | Promise<void>
	isItemDisabled?: (data: T) => boolean
	onSortChange?: (data: string[]) => void | Promise<void>
	uniqueId?: string
	headerVariant?: 'default' | 'gray'
	totalEntriesCount?: number
	enableCheckbox?: boolean
	enableCheckboxDropdown?: boolean
	totalPages?: number
	exportSelectedFetcherProps?: FetcherFormProps & RefAttributes<HTMLFormElement>
	exportAllFetcherProps?: FetcherFormProps & RefAttributes<HTMLFormElement>
	stickyHeader?: boolean
	filtersComponent?: ReactNode
}

enum SORT {
	Asc = 'asc',
	Desc = 'desc',
}

export const Table = <T extends Record<string, unknown>>(props: TableProps<T>) => {
	const {
		columns,
		data,
		sort,
		onItemClick,
		isItemDisabled,
		onSortChange,
		uniqueId = 'id',
		headerVariant = 'gray',
		totalEntriesCount,
		enableCheckbox,
		enableCheckboxDropdown,
		totalPages,
		exportSelectedFetcherProps,
		exportAllFetcherProps,
		stickyHeader,
		filtersComponent,
	} = props
	const exportSelected = useFetcher()
	const exportAll = useFetcher()
	const [checkedRows, setCheckedRows] = useState<(string | number)[]>([])

	const handleExportSelected = () => {
		if (!checkedRows.length) return

		const formData = new FormData()
		checkedRows.forEach(id => formData.append('ids', String(id)))

		void exportSelected.submit(formData, exportSelectedFetcherProps)
	}

	const handleExportAll = () => {
		const formData = new FormData()
		const searchParams = new URLSearchParams(window.location.search)
		formData.append('queryParams', searchParams.toString())

		void exportAll.submit(formData, exportAllFetcherProps)
	}

	const actionInProgress = exportSelected.state !== 'idle' || exportAll.state !== 'idle'

	const isIndeterminate = () => {
		const currentIds = data.map(item => item.id as string | number)
		const checkedCount = currentIds.filter(id => checkedRows.includes(id)).length

		return checkedCount > 0 && checkedCount < currentIds.length
	}

	const isAllChecked = () => {
		if (!data?.length) return false

		const currentIds = data.map(item => item.id as string | number)

		return currentIds.every(id => checkedRows.includes(id))
	}

	const handleCheckAll = () => {
		if (isAllChecked()) {
			setCheckedRows([])

			return
		}
		setCheckedRows(prev => [...prev, ...data.map(item => item.id as string | number).filter(id => !prev.includes(id))])
	}

	const handleSingleCheck = (checked: boolean, id: string | number) => {
		setCheckedRows(prev => {
			if (checked) {
				return [...prev, id]
			}

			return prev.filter(rowId => rowId !== id)
		})
	}

	const clickable = typeof onItemClick !== 'undefined'

	const handleSortChange = (value: string) => {
		if (!sort) {
			return
		}
		const existing = sort.find(item => item === value || item === `-${value}`)
		const index = existing ? sort.indexOf(existing) : undefined
		let res = [...sort]
		if (index !== undefined) res.splice(index, 1)
		res = [!existing || existing.startsWith('-') ? value : `-${value}`, ...res]
		void onSortChange?.(res)
	}

	const getSortType = (columnName: string): SORT | undefined => {
		if (!sort) {
			return
		}
		const sortKey = sort.find(i => i === columnName || i === `-${columnName}`)
		return typeof sortKey === 'string' ? (sortKey === columnName ? SORT.Asc : SORT.Desc) : undefined
	}

	const headerCheckbox = (
		<Checkbox
			checked={isIndeterminate() ? 'indeterminate' : isAllChecked()}
			onCheckedChange={handleCheckAll}
			disabled={actionInProgress || !data?.length}
		/>
	)

	return (
		<table className="w-full">
			<thead className={cn('text-left', stickyHeader && 'sticky left-0 top-0 z-10 w-full')}>
				{(enableCheckbox || exportSelectedFetcherProps || exportAllFetcherProps) && (
					<tr className="bg-white">
						<th colSpan={columns.length + (enableCheckbox ? 1 : 0)} className="px-6 py-3">
							<div className="flex items-center gap-1.5 text-label-md">
								{enableCheckbox && <span className="me-3">{`${checkedRows.length} selected`}</span>}
								{!!checkedRows.length && exportSelectedFetcherProps && (
									<StatusButton
										onClick={handleExportSelected}
										status={exportSelected.state !== 'idle' ? 'pending' : 'idle'}
										size="sm"
										className="flex gap-2"
										disabled={exportSelected.state !== 'idle' || !checkedRows.length}
									>
										<Icon name="download" />
										Export Selected
									</StatusButton>
								)}
								{exportAllFetcherProps && (
									<StatusButton
										onClick={handleExportAll}
										status={exportAll.state !== 'idle' ? 'pending' : 'idle'}
										size="sm"
										className="flex gap-2"
										disabled={exportAll.state !== 'idle' || !data.length}
										variant="secondary"
									>
										<Icon name="download" />
										Export All
									</StatusButton>
								)}
							</div>
						</th>
					</tr>
				)}
				{filtersComponent && (
					<tr className="bg-white">
						<th colSpan={columns.length + (enableCheckbox ? 1 : 0)} className="px-6 py-3">
							{filtersComponent}
						</th>
					</tr>
				)}
				<tr>
					{enableCheckbox && (
						<th
							className={cn(
								'px-6 py-3.5 text-label-md text-neutral-2-fg',
								headerVariant === 'gray' && 'bg-neutral-2-bg',
								'w-0',
							)}
						>
							{enableCheckboxDropdown ? (
								<DropdownButton
									trigger={
										<div className="flex cursor-pointer items-center gap-0.5">
											{headerCheckbox}
											<Icon name="carret-down" />
										</div>
									}
									items={[
										{
											label: !isAllChecked() ? 'Select this page' : 'Deselect all',
											onSelect: handleCheckAll,
										},
										...(!isAllChecked() && totalPages !== 1
											? [
													{
														label: `Select all ${totalEntriesCount ? `(${totalEntriesCount})` : ''}`,
														onSelect: handleCheckAll,
													},
												]
											: []),
									]}
								/>
							) : (
								headerCheckbox
							)}
						</th>
					)}
					{columns
						.filter(column => !column.hidden)
						.map(column => {
							const sortType = getSortType(column.name)

							return (
								<th
									key={`th-${column.name}`}
									className={cn(
										'px-6 py-3.5 text-label-md text-neutral-2-fg',
										headerVariant === 'gray' && 'bg-neutral-2-bg',
									)}
								>
									<div className="inline-flex items-center">
										{column.heading}
										{column.sortable && (
											<>
												{sortType ? (
													<div
														className="relative inline-flex h-4 w-4 cursor-pointer items-center justify-center"
														onClick={() => handleSortChange(column.name)}
													>
														<Icon
															name="chevron-sort-asc"
															size="sm"
															className={cn(
																'absolute left-0 top-0',
																sortType === SORT.Asc ? 'opacity-100' : 'opacity-50',
															)}
														/>
														<Icon
															name="chevron-sort-desc"
															size="sm"
															className={cn(
																'absolute left-0 top-0',
																sortType === SORT.Desc ? 'opacity-100' : 'opacity-50',
															)}
															onClick={() => handleSortChange(column.name)}
														/>
													</div>
												) : (
													<Icon
														name="chevron-sort"
														size="sm"
														className="cursor-pointer"
														onClick={() => handleSortChange(column.name)}
													/>
												)}
											</>
										)}
									</div>
								</th>
							)
						})}
				</tr>
			</thead>

			<tbody className="text-left">
				{!data.length && (
					<tr>
						<td colSpan={columns.length}>
							<EmptyStateCard title="No results found" className="bg-transparent" icon="distribution-ledger" />
						</td>
					</tr>
				)}
				{data.map(item => {
					const isDisabled = isItemDisabled?.(item)

					return (
						<tr
							key={`tr-${getObjectValueByKey(item, uniqueId) as string}`}
							className={cn(
								'border-none bg-transparent transition-colors hover:bg-neutral-1-bg-hover',
								checkedRows.includes(item.id as string | number) && 'bg-brand-1-bg',
								clickable && 'cursor-pointer',
							)}
							onClick={e => {
								if (onItemClick) {
									e.preventDefault()
									e.stopPropagation()
									void onItemClick(item)
								}
							}}
						>
							{enableCheckbox && (
								<td className="px-6">
									<Checkbox
										checked={checkedRows.includes(item.id as string | number)}
										onCheckedChange={(checked: boolean) => handleSingleCheck(checked, item.id as string | number)}
										disabled={actionInProgress || !data?.length}
									/>
								</td>
							)}
							{columns
								.filter(column => !column.hidden)
								.map(column => (
									<td
										key={`td-${getObjectValueByKey(item, uniqueId) as string}-${column.name}`}
										className="px-6 py-3.5"
									>
										<div
											className={clsx(
												'truncate text-body-md text-neutral-1-fg',
												column.name === 'actions' && 'text-right',
												isDisabled && 'text-neutral-4-fg',
											)}
										>
											{column.render(item)}
										</div>
									</td>
								))}
						</tr>
					)
				})}
			</tbody>
		</table>
	)
}
