import { useQuery } from '@tanstack/react-query'
import { createSearchParams, Link, useLoaderData, useNavigate, useSearchParams } from 'react-router'
import { orgDataEnrichmentWorkflowsQueries } from '#src/api/organise/data-enrichment/workflows/queries'
import { DATA_ENRICHMENT_JOB_ENRICHERS } from '#src/api/organise/data-enrichment/workflows/schemas'
import { EmptyStateCard } from '#src/components'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { PageLayout } from '#src/components/ui/PageLayout'
import { PaginatedTable } from '#src/components/ui/PaginatedTable'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import LayoutContentHack from '#src/layouts/LayoutContentHack'
import { ContactWaterfallingJobDownloadModal } from '#src/modals/contact-waterfalling/Download'
import { ContactWaterfallingJobStopModal } from '#src/modals/contact-waterfalling/Stop'
import { formatDateToReadableString } from '#src/utils/date'
import { routes } from '#src/utils/routes'
import { type LoaderRes } from '../loaders/list'
import { canDownload, canStop } from '../utils'
import { ListStatisticsCard, StatusLabel } from './components'

export const View = () => {
	const {
		values: { sort, page },
		data,
	} = useLoaderData<LoaderRes>()
	const { company, companyId } = useCompany()

	const { data: statistics } = useQuery({
		...orgDataEnrichmentWorkflowsQueries.statistics(company.id, DATA_ENRICHMENT_JOB_ENRICHERS.Waterfalling),
		initialData: data.statistics,
		refetchInterval: 10000,
	})

	const { data: jobs } = useQuery({
		...orgDataEnrichmentWorkflowsQueries.list(company.id, page, sort, DATA_ENRICHMENT_JOB_ENRICHERS.Waterfalling),
		initialData: data.jobs,
		...(statistics.inProgressCount + statistics.inQueueCount > 0 && {
			refetchInterval: 10000,
		}),
	})

	const navigate = useNavigate()
	const [searchParams] = useSearchParams()

	const { openModal } = useModals()

	if (!jobs.meta.total) {
		return (
			<PageLayout title="Contact Waterfalling Jobs">
				<EmptyStateCard
					icon="distribution-ledger"
					title="No Contact Waterfalling jobs found"
					description="You don’t have any Contact Waterfalling jobs yet."
					actions={[
						{
							title: 'Create job',
							to: {
								pathname: routes.organize.contactWaterfalling.create({ companyId }),
								search: searchParams.toString(),
							},
						},
					]}
				/>
			</PageLayout>
		)
	}

	return (
		<PageLayout
			title="Contact Waterfalling Jobs"
			actions={[
				{
					icon: 'add',
					title: 'Create job',
					to: {
						pathname: routes.organize.contactWaterfalling.create({ companyId }),
						search: searchParams.toString(),
					},
					variant: 'default',
				},
			]}
		>
			<div className="grid w-full grid-cols-3 grid-rows-1 gap-6">
				<ListStatisticsCard name="In queue" count={statistics.inQueueCount} icon="circle-dash" />
				<ListStatisticsCard name="In progress" count={statistics.inProgressCount} icon="incomplete" />
				<ListStatisticsCard
					name="Completed"
					count={statistics.completedCount}
					icon="checkmark-filled"
					iconVariant="success"
				/>
			</div>
			<LayoutContentHack>
				<PaginatedTable
					currentPage={jobs.meta.current_page}
					totalPages={jobs.meta.last_page}
					sort={sort}
					onPageChange={page =>
						navigate({
							search: createSearchParams({
								...Object.fromEntries(searchParams),
								page: (page + 1).toString(),
							}).toString(),
						})
					}
					onSortChange={data =>
						navigate({
							search: createSearchParams({
								...Object.fromEntries(searchParams),
								sort: data,
							}).toString(),
						})
					}
					onItemClick={item =>
						navigate(
							routes.organize.contactWaterfalling.view({
								companyId,
								jobId: item.id.toString(),
							}),
						)
					}
					columns={[
						{
							name: 'title',
							heading: 'Job name',
							render: item => (
								<Link
									to={routes.organize.contactWaterfalling.view({
										companyId,
										jobId: item.id.toString(),
									})}
								>
									{item.title}
								</Link>
							),
						},
						{
							name: 'createdAt',
							heading: 'Date created',
							sortable: true,
							render: item => formatDateToReadableString(item.createdAt),
						},
						{
							name: 'status',
							heading: 'Status',
							render: item => <StatusLabel status={item.status} />,
						},
						{
							name: 'totalRows',
							heading: 'Items uploaded',
							render: item => item.originalEntryCount ?? '-',
						},
						{
							name: 'actions',
							heading: '',
							render: item => (
								<div className="inline-flex flex-row" onClick={e => e.stopPropagation()}>
									{canStop(item.status) && (
										<Button
											variant="icon"
											size="icon"
											onClick={() =>
												openModal({
													name: MODAL_NAME.ContactWaterfallingStop,
													component: ContactWaterfallingJobStopModal,
													props: {
														jobId: item.id,
													},
												})
											}
										>
											<Icon name="stop-filled-alt" size="font" className="text-status-danger-fg" />
										</Button>
									)}
									{canDownload(item.status) && (
										<Button
											variant="icon"
											size="icon"
											onClick={() =>
												openModal({
													name: MODAL_NAME.ContactWaterfallingDownload,
													component: ContactWaterfallingJobDownloadModal,
													props: {
														jobId: item.id,
													},
												})
											}
										>
											<Icon name="download" size="font" className="text-brand-1-fg" />
										</Button>
									)}
								</div>
							),
						},
					]}
					data={jobs.data}
				/>
			</LayoutContentHack>
		</PageLayout>
	)
}
