import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useFetcher } from 'react-router'
import { type z } from 'zod'
import { PLAY_STATUS } from '#src/api/icp/company/plays/constants'
import { PlaysTemplatesFormSchema } from '#src/api/icp/company/plays-templates/schemas'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import { Dialog } from '#src/components/ui/dialog'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { type ActionRes } from '#src/routes/enable/plays/root/actions/create'
import PlayTemplateFormFields from '#src/routes/enable/plays/templates/views/components/PlayTemplateFormFields'
import { routes } from '#src/utils/routes'

const FORM_ID = 'create-play-template-form'

export const PlayTemplateCreateModal = () => {
	const { closeModal } = useModals()
	const { companyId } = useCompany()
	const actionFetcher = useFetcher<ActionRes>()

	const form = useForm<z.infer<typeof PlaysTemplatesFormSchema>>({
		resolver: zodResolver(PlaysTemplatesFormSchema),
		defaultValues: {
			name: '',
			instructions: '',
			inCopilot: true,
			inCrm: false,
			personaTypes: [],
			expertise: [],
			tags: [],
			status: PLAY_STATUS.Draft,
			conditions: [],
			isGlobal: false,
			templateExpertId: null,
		},
		mode: 'onSubmit',
	})

	return (
		<Dialog
			defaultOpen={true}
			onOpenChange={isOpen => !isOpen && closeModal(MODAL_NAME.PlayTemplateCreate)}
			dialogHeading="Create new Play Template"
			actions={[
				{ type: 'cancel', label: 'Close' },
				{ type: 'submit', formId: FORM_ID, label: 'Create' },
			]}
			size="xl"
			isSubmitting={actionFetcher.state === 'submitting'}
		>
			<FormWrapper
				formId={FORM_ID}
				formProps={form}
				action={routes.enable.playTemplates.create({ companyId })}
				className="flex flex-col gap-6"
				fetcher={actionFetcher}
				actionResponse={actionFetcher.data}
			>
				<PlayTemplateFormFields />
			</FormWrapper>
		</Dialog>
	)
}
