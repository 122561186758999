import { Link, useLoaderData, type LoaderFunctionArgs } from 'react-router'
import { EmptyStateCard } from '#src/components'
import AiGenerated from '#src/components/ai-generated'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { PageLayout } from '#src/components/ui/PageLayout'
import { Surface } from '#src/components/ui/surface'
import useCompany from '#src/hooks/useCompany'
import { client } from '#src/main'
import { DeleteTermAsyncForm } from '#src/routes/enable/glossary/delete'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { getUser } from '#src/utils/server/user'
import { glossaryQuery } from './queries'

export type GlossaryLoaderResponse = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	const { company, companyId } = await getCompany(params)
	const { readOnlySession, enableOnlySession } = await getUser()
	const url = new URL(request.url)
	const searchParams = new URLSearchParams(url.search)
	const source = searchParams.get('source')
	const canCreate = !readOnlySession && !enableOnlySession && source === 'calibrate'

	return {
		handle: {
			companyId,
			canCreate,
		},
		glossary: await client.fetchQuery(glossaryQuery(company.id)),
		canCreate,
	}
}

export default function Glossary() {
	const { glossary, canCreate } = useLoaderData<GlossaryLoaderResponse>()
	const { companyId } = useCompany()

	return (
		<PageLayout>
			{!glossary?.length ? (
				<EmptyStateCard
					title="You don't have any Industry-jargons"
					icon="notebook-reference"
					{...(canCreate && {
						actions: [
							{
								title: 'Add new term',
								to: routes.enable.glossary.edit({
									companyId,
									termId: null,
								}),
							},
						],
					})}
				/>
			) : (
				<GlossaryList />
			)}
		</PageLayout>
	)
}

function GlossaryList() {
	const { glossary, canCreate } = useLoaderData<GlossaryLoaderResponse>()
	const { companyId } = useCompany()

	return (
		<div className="grid grid-cols-2 gap-4">
			{glossary.map(term => (
				<Surface key={term.id} className="flex flex-col gap-6">
					<section className="flex flex-col gap-2">
						<div className="flex items-center justify-between">
							<h2 className="text-title-md text-neutral-1-fg">{term.term}</h2>
							{canCreate && (
								<div className="flex items-center gap-1 justify-self-end text-neutral-3-fg">
									<Button variant="icon" size="icon" asChild>
										<Link
											to={`${routes.enable.glossary.edit({
												companyId,
												termId: term.id.toString(),
											})}?source=calibrate`}
											className="flex items-center justify-center"
										>
											<Icon name="edit" size="font" />
										</Link>
									</Button>
									<DeleteTermAsyncForm termId={term.id} />
								</div>
							)}
						</div>
						<p className="flex flex-col items-start gap-2 border-l-2 border-brand-2-bd bg-neutral-2-bg p-3 text-body-sm text-neutral-2-fg">
							<span className="text-brand-1-fg">
								<AiGenerated />
							</span>
							{term.definition}
						</p>
					</section>

					<section className="flex flex-col gap-2">
						<h3 className="text-label-md text-neutral-3-fg">Ecosystem</h3>
						<p className="text-body-sm">{term.ecosystem.name}</p>
					</section>

					{term.manualInput ? (
						<section className="flex flex-col gap-2">
							<h3 className="text-label-md text-neutral-3-fg">Manual input</h3>
							<p className="text-body-sm">{term.manualInput}</p>
						</section>
					) : (
						''
					)}
				</Surface>
			))}
		</div>
	)
}
