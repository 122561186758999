import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useFetcher } from 'react-router'
import { type z } from 'zod'
import { PlaysFormSchema, type PlaysItemAPISchema } from '#src/api/icp/company/plays/schemas'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import LoaderRequiredComponent from '#src/components/LoaderRequiredComponent'
import { Dialog } from '#src/components/ui/dialog'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { type ActionRes } from '#src/routes/enable/plays/root/actions/edit'
import { type LoaderRes } from '#src/routes/enable/plays/root/loaders/item'
import PlayFormFields from '#src/routes/enable/plays/root/views/components/PlayFormFields'
import { routes } from '#src/utils/routes'

export type PlayEditModalProps = {
	playId: z.infer<typeof PlaysItemAPISchema.shape.id>
}

export const PlayEditModal = (props: PlayEditModalProps) => {
	const { companyId } = useCompany()

	return (
		<LoaderRequiredComponent<LoaderRes, PlayEditModalProps>
			route={routes.enable.plays.item({ companyId, playId: String(props.playId) })}
			component={ModalInner}
			initialProps={props}
		/>
	)
}

const FORM_ID = (id: number) => `edit-play-form-${id}`

const ModalInner = (props: PlayEditModalProps & LoaderRes) => {
	const {
		data: { play },
	} = props
	const { closeModal } = useModals()
	const { companyId } = useCompany()
	const actionFetcher = useFetcher<ActionRes>({ key: 'play-edit' })

	const form = useForm<z.infer<typeof PlaysFormSchema>>({
		resolver: zodResolver(PlaysFormSchema),
		defaultValues: {
			name: play.name,
			instructions: play.instructions,
			inCopilot: play.inCopilot,
			inCrm: play.inCrm,
			personaTypes: play.personaTypes,
			expertise: play.expertise,
			tags: play.tags,
			status: play.status,
			conditions: play.conditions,
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-expect-error
			verticals: play.verticals.map(vertical => String(vertical)),
		},
		mode: 'onSubmit',
	})

	return (
		<Dialog
			defaultOpen={true}
			onOpenChange={isOpen => !isOpen && closeModal(MODAL_NAME.PlayEdit)}
			dialogHeading="Edit Play"
			actions={[
				{ type: 'cancel', label: 'Close' },
				{ type: 'submit', formId: FORM_ID(play.id), label: 'Save' },
			]}
			size="xl"
			isSubmitting={actionFetcher.state === 'submitting'}
		>
			<FormWrapper
				formId={FORM_ID(play.id)}
				action={routes.enable.plays.edit({ companyId, playId: String(play.id) })}
				formProps={form}
				className="flex flex-col gap-6"
				fetcher={actionFetcher}
				actionResponse={actionFetcher.data}
			>
				<PlayFormFields />
			</FormWrapper>
		</Dialog>
	)
}
