import { type LoaderFunctionArgs } from 'react-router'
import { companyPersonaQueries } from '#src/api/icp/company/persona/queries'
import { verticalsQueries } from '#src/api/icp/company/verticals/queries'
import { client } from '#src/main'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'
import { getUser } from '#src/utils/server/user'

export type LoaderRes = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
	validateRouteParams(params, ['ecosystemId', 'verticalId'])
	const { company, companyId } = await getCompany(params)
	const { readOnlySession } = await getUser()

	const [vertical, personas] = await Promise.all([
		client.fetchQuery(verticalsQueries.item(company.id, Number(params.verticalId))),
		client.fetchQuery(
			companyPersonaQueries.list(company.id, {
				ecosystem_id: params.ecosystemId,
				vertical_id: params.verticalId,
			}),
		),
	])

	return {
		handle: {
			readOnlySession,
			companyId,
			ecosystemId: params.ecosystemId,
			verticalId: params.verticalId,
		},
		vertical,
		personas: personas.data,
	}
}
