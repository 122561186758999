import { type ActionFunctionArgs } from 'react-router'
import { adminPlaysExpertsMutations } from '#src/api/icp/admin/plays-experts/mutations'
import { PlaysExpertsFormSchema } from '#src/api/icp/admin/plays-experts/schemas'
import { MODAL_NAME } from '#src/constants/modals'
import { showToast } from '#src/context/ToastContext'
import { parseFormDataNew } from '#src/utils/forms'
import { validateRouteParams } from '#src/utils/misc'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'
import { closeModalServer } from '#src/utils/server/modals'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ params, request }: ActionFunctionArgs) => {
	validateRouteParams(params, ['expertId'])
	try {
		const formData = await request.formData()
		const submission = parseFormDataNew(formData, PlaysExpertsFormSchema)
		await adminPlaysExpertsMutations.update(Number(params.expertId), submission.value)
		showToast({
			message: 'Expert updated',
			type: 'success',
		})
		closeModalServer(MODAL_NAME.AdminPlaysExpertsEdit)
		return { ok: true }
	} catch (e) {
		return await serverFormErrorHandler(e)
	}
}
