import { type LoaderFunctionArgs, redirect } from 'react-router'
import { companyChatRoleplaySessionQueriesKeys } from '#src/api/icp/company/chat/roleplay/session/queries'
import { client } from '#src/main'
import { userQuery } from '#src/routes/auth/queries'
import { deleteRoleplaySession } from '#src/routes/enable/roleplay-sessions/mutations'
import { checkIsReadOnlySession, validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'

export const action = async ({ params }: LoaderFunctionArgs) => {
	validateRouteParams(params, ['conversationId'])
	const { companyId, company } = await getCompany(params)

	const user = await client.fetchQuery(userQuery())
	const readOnly = checkIsReadOnlySession(user.roles)

	if (readOnly)
		throw new Response('Access Denied', {
			status: 403,
			statusText: 'Forbidden',
		})

	await deleteRoleplaySession(company.id, params.conversationId)

	void client.invalidateQueries({
		queryKey: [...companyChatRoleplaySessionQueriesKeys.all, 'list'],
	})
	return redirect(
		routes.enable.roleplay.index({
			companyId,
		}),
	)
}
