import { useEffect } from 'react'
import { MODAL_NAME } from '#src/constants/modals'
import { useModals } from '#src/hooks/useModals'
import { AdminPlaysExpertsDeleteModal } from '#src/modals/admin/PlaysExpertsDelete'
import { routes } from '#src/utils/routes'
import { useParsedRouteParams } from '#src/utils/use-parsed-route-params'

export const View = () => {
	const { openModal } = useModals()
	const { expertId } = useParsedRouteParams(['expertId'])

	useEffect(() => {
		openModal({
			name: MODAL_NAME.AdminPlaysExpertsDelete,
			component: AdminPlaysExpertsDeleteModal,
			props: {
				expertId: Number(expertId),
			},
			settings: {
				routeToRedirectOnClose: routes.admin.playsExperts.list,
			},
		})
	}, [openModal, expertId])

	return null
}
