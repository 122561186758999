import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu'
import { TabsContent, TabsTrigger } from '@radix-ui/react-tabs'
import { type RefObject, useEffect, useMemo, useState } from 'react'
import { Link, useFetcher, useLoaderData } from 'react-router'
import { type ChatInputForwardProps } from '#src/components/chat/input'
import { NoResults, Search } from '#src/components/chat/search'
import { Chip } from '#src/components/chip'
import { Button } from '#src/components/ui/button'
import { Dropdown } from '#src/components/ui/dropdown'
import { Icon } from '#src/components/ui/icon'
import { Tabs, TabsList } from '#src/components/ui/tabs'
import { Tooltip, TooltipProvider, TooltipTrigger, TooltipContent } from '#src/components/ui/tooltip'
import { MODAL_NAME } from '#src/constants/modals'
import useAuth from '#src/hooks/useAuth'
import useDebounce from '#src/hooks/useDebounce'
import { useModals } from '#src/hooks/useModals'
import { PlayCreateModal } from '#src/modals/plays/PlayCreate'
import { PlayEditModal } from '#src/modals/plays/PlayEdit'
import { type ChatLoaderResponse } from '#src/routes/enable/chat'

type Props = {
	disabled: boolean
	inputRef: RefObject<ChatInputForwardProps>
}

export const ChatInputPresets = (props: Props) => {
	const { disabled, inputRef } = props
	const { plays, defaultCommands } = useLoaderData<ChatLoaderResponse>()

	const [open, setOpen] = useState<boolean>(false)
	const [tooltip, setTooltip] = useState<boolean>(!sessionStorage.getItem('chat-presets-seen'))

	useEffect(() => {
		if (tooltip) {
			sessionStorage.setItem('chat-presets-seen', 'true')
			setTimeout(() => {
				setTooltip(false)
			}, 5000)
		}
	}, [tooltip])

	const [search, setSearch] = useState('')
	const searchQuery = useDebounce(search, 100)

	const handleSearch = (query: string) => {
		setSearch(query.toLowerCase())
	}

	const filteredPlays = useMemo(
		() =>
			(plays ?? []).filter(play => {
				if (play.name.toLowerCase().includes(searchQuery)) {
					return true
				}
				return !!play.tags.find(tag => tag.toLowerCase().includes(searchQuery))
			}),
		[plays, searchQuery],
	)

	const { openModal } = useModals()
	const { userSession, adminSession } = useAuth()

	const { state: playEditState } = useFetcher({ key: 'play-edit' })
	const playsUpdatingAfterEdit = playEditState !== 'idle'

	const onChange = (val: string) => {
		if (inputRef && inputRef.current) {
			inputRef.current.setValue?.(val)
			setOpen(false)
		}
	}

	return (
		<TooltipProvider>
			<Tooltip delayDuration={0} open={tooltip}>
				<TooltipTrigger asChild>
					<Dropdown
						open={open}
						trigger={
							<Button
								size="icon"
								variant="icon-interactive"
								type="button"
								disabled={disabled || playsUpdatingAfterEdit}
							>
								{playsUpdatingAfterEdit ? (
									<Icon name="loading-sm" size="sm" className="animate-spin" />
								) : (
									<Icon name="add-alt" size="sm" />
								)}
							</Button>
						}
						contentProps={{
							className: 'p-4 overflow-hidden w-[min(70vw,894px)] max-h-[25rem]',
							align: 'start',
						}}
						onOpenChange={setOpen}
					>
						<DropdownPrimitive.Arrow className="mt-[-1px] fill-white" />
						<Tabs defaultValue="default-commands" className="w-full overflow-hidden">
							<TabsList className="mb-1">
								<TabsTrigger
									value="default-commands"
									className="mb-[-2px] border-b-2 border-solid border-transparent px-4 py-3 text-body-sm data-[state=active]:border-brand-1-bd data-[state=active]:font-bold"
								>
									Default commands
								</TabsTrigger>
								<TabsTrigger
									value="plays"
									className="mb-[-2px] border-b-2 border-solid border-transparent px-4 py-3 text-body-sm data-[state=active]:border-brand-1-bd data-[state=active]:font-bold"
								>
									Plays
								</TabsTrigger>
							</TabsList>
							<TabsContent
								value="default-commands"
								className="flex h-full w-full flex-col gap-1 overflow-y-auto text-body-md"
							>
								{(defaultCommands ?? []).map(item => (
									<button
										key={item.id}
										className="rounded px-2 py-1 text-left hover:bg-neutral-1-bg-hover"
										onClick={() => onChange(item.value)}
									>
										{item.title}
									</button>
								))}
							</TabsContent>
							<TabsContent value="plays" className="flex h-full w-full flex-col gap-1 overflow-hidden text-body-md">
								<Search value={search} onSearch={handleSearch} />
								<div className="flex w-full flex-1 flex-col gap-1 overflow-y-auto text-body-md">
									{filteredPlays.length ? (
										filteredPlays.map(item => (
											<button
												key={item.id}
												className="rounded px-2 py-1 text-left hover:bg-neutral-1-bg-hover"
												onClick={() =>
													onChange(
														`Help me adjust the below template by replacing the part in brackets as per the instruction in between the brackets.\nAnswer format: Strictly do not write anything before the adjusted template. Strictly do not write anything after the adjusted template.\nStrictly only answer with the adjusted template. Strictly follow the instructions in between the brackets for spacing and empty lines.\nStrictly do not repeat the instructions from the brackets in your answer.\nDo not include <template> tags in your answer, these are only for you to understand where template begins and where it ends.\n\n<template>\n${item.instructions}\n</template>`,
													)
												}
											>
												<div className="flex flex-row items-center gap-2">
													<p className="flex-1">{item.name}</p>
													{item.tags.length > 0 && (
														<div className="flex flex-row flex-wrap gap-1">
															{item.tags.map(item => (
																<Chip variant="gray" key={`tag-${item}`}>
																	{item}
																</Chip>
															))}
														</div>
													)}
													{(userSession || adminSession) && (
														<div
															onClick={e => {
																e.preventDefault()
																e.stopPropagation()
																setOpen(false)
																openModal({
																	name: MODAL_NAME.PlayEdit,
																	component: PlayEditModal,
																	props: { playId: item.id },
																})
															}}
														>
															<Icon name="edit" size="sm" />
														</div>
													)}
												</div>
											</button>
										))
									) : (plays ?? []).length ? (
										<NoResults query={searchQuery} className="py-4" />
									) : (
										<p className="p-4">
											You don&apos;t have any plays yet. You can create them by clicking{' '}
											<Link
												to="#"
												onClick={e => {
													e.preventDefault()
													setOpen(false)
													openModal({
														name: MODAL_NAME.PlayCreate,
														component: PlayCreateModal,
													})
												}}
												className="text-link"
											>
												here
											</Link>
										</p>
									)}
								</div>
							</TabsContent>
						</Tabs>
					</Dropdown>
				</TooltipTrigger>
				<TooltipContent side="top" align="center" className="max-w-[280px]">
					You can manage your command presets here
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	)
}
