import { type LoaderFunctionArgs } from 'react-router'
import { contactsEnrichQueries } from '#src/api/icp/company/contacts-enrich/queries'
import {
	ContactsEnrichFiltersAPISchema,
	ContactsEnrichItemAPISchema,
} from '#src/api/icp/company/contacts-enrich/schemas'
import { client } from '#src/main'
import { QUEUE_STATUS, QUEUE_STATUS_OPTIONS } from '#src/utils/enumerations'
import { getValidSortKeys, paramsToObjectSafe } from '#src/utils/requests'
import { getCompany } from '#src/utils/server/company'

export type LoaderRes = Awaited<ReturnType<typeof loader>>
export const loader = async ({ request, params }: LoaderFunctionArgs) => {
	const { company } = await getCompany(params)
	const page = parseInt(new URL(request.url).searchParams.get('page') ?? '1')
	const sort = getValidSortKeys(new URL(request.url).searchParams.getAll('sort'), ContactsEnrichItemAPISchema)
	const filtersSelected = paramsToObjectSafe(new URL(request.url).searchParams, ContactsEnrichFiltersAPISchema)

	const baseQueryParams = {
		page: Number.isNaN(page) ? 1 : page,
		sort,
	}

	const initialData = await client.fetchQuery(
		contactsEnrichQueries.list(company.id, baseQueryParams.page, baseQueryParams.sort, filtersSelected),
	)

	const filters = {
		values: {
			status: QUEUE_STATUS_OPTIONS.filter(
				item => item.value !== QUEUE_STATUS.pending && item.value !== QUEUE_STATUS.crashed,
			),
			email: [{ value: 'true', label: 'Has email' }],
			phone: [{ value: 'true', label: 'Has phone' }],
		},
		selected: filtersSelected,
	}

	return {
		filters,
		data: { company, initialData },
		values: { companyId: company.id, baseQueryParams },
	}
}
