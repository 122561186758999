import { type VariantProps } from 'class-variance-authority'
import { Fragment, type ReactNode } from 'react'
import { z, type ZodType } from 'zod'
import { verticalsQueries } from '#src/api/icp/company/verticals/queries'
import {
	type VerticalAPISchema,
	type VerticalCriterionAPISchema,
	type VerticalListAPISchema,
} from '#src/api/icp/company/verticals/schemas'
import { Chip } from '#src/components/chip'
import { FormField, type labelSizeVariants } from '#src/components/forms/v2/FormField'
import { Icon } from '#src/components/ui/icon'
import { type MultiSelectOption } from '#src/components/ui/MultiSelect'
import { type RadioGroupOption } from '#src/components/ui/RadioGroup'
import { type SelectOption } from '#src/components/ui/select'
import { CustomTooltip } from '#src/components/ui/tooltip'
import { client } from '#src/main'
import { type CRITERION_TYPE_ENUM } from '#src/routes/calibrate/ecosystem-management/criteria/constants'
import { type APIFieldType, getFieldSchemaByType } from '#src/schemas/global'
import { API_TO_FIELD_TYPE_MAP } from '#src/utils/enumerations'
import { transformFieldOptionsFromApi, formatNumberWithSpaces } from '#src/utils/misc'
import { criteriaAssignedQuery } from './queries'
import {
	type CriterionAnswerAPISchema,
	type CriteriaAPISchema,
	type CriterionAnswerFieldId,
	type CriterionQuestionAPISchema,
} from './schema'

export const generateCriterionAnswerFieldId = (
	verticalId: z.infer<typeof CriterionAnswerAPISchema>['verticalId'],
	criterionId?: z.infer<typeof CriterionAnswerAPISchema>['criterionId'] | string,
): string => {
	return `${verticalId}::VERTICAL${criterionId ? `::${criterionId}` : ''}`
}

export const extractCriterionFieldValues = (
	fieldId: CriterionAnswerFieldId,
): {
	verticalId: string
	verticalName: string
	criterionId: string
} | null => {
	const parts = fieldId.split('::')
	const [verticalId, verticalName, criterionId] = parts

	return {
		verticalId,
		verticalName,
		criterionId,
	}
}

export const transformAnswersSubmissionToPayload = (
	submission: Record<CriterionAnswerFieldId, unknown>,
	type: z.infer<typeof CRITERION_TYPE_ENUM>,
	ecosystemId: string,
	criterionId?: string,
) => ({
	isEnrichment: type === 'enrichment',
	ecosystemId: ecosystemId,
	criteria: Object.entries(submission).flatMap(([fieldId, value]) => {
		const parsedField = extractCriterionFieldValues(fieldId as CriterionAnswerFieldId)

		return parsedField
			? {
					criterionId: criterionId ?? parsedField.criterionId,
					answers: [{ verticalId: parsedField.verticalId, value }],
				}
			: []
	}),
})

export const generateSchemaFromCriteriaAnswers = (
	criteria: z.infer<typeof CriteriaAPISchema>,
): z.ZodObject<Record<string, ZodType<unknown>>> => {
	const shape = criteria.reduce(
		(acc, question) => {
			question.answers.forEach(answer => {
				const fieldType = question.criterion.type
				const fieldName = generateCriterionAnswerFieldId(answer.verticalId, answer.criterionId)

				if (API_TO_FIELD_TYPE_MAP[fieldType]) {
					const isEnrichment = question.criterion.isEnrichment

					// For display purposes range is shown inside select component
					if (fieldType === 'range') {
						acc[fieldName] = getFieldSchemaByType({
							fieldType: 'select',
							isOptional: isEnrichment,
							isNullable: isEnrichment,
						})

						return acc
					}

					acc[fieldName] = getFieldSchemaByType({ fieldType, isOptional: isEnrichment, isNullable: isEnrichment })
				}
			})

			return acc
		},
		{} as Record<string, ZodType<unknown>>,
	)

	return z.object(shape)
}

export const processDefaultCriterionFieldValue = (
	fieldType: string,
	fieldId: string,
	value: unknown,
	options?: { fieldsDisabledByDefault?: boolean },
): Record<string, unknown> => {
	const result: Record<string, unknown> = {}

	// Check for fields disabled by default
	if (options?.fieldsDisabledByDefault && (value === undefined || value === '')) {
		result[`${fieldId}Toggle`] = false
	}

	// Process field value based on type
	if (fieldType === 'regions') {
		result[fieldId] = value || { included: [], excluded: [] }
	} else if (fieldType === 'multiselect') {
		result[fieldId] = value || []
	} else {
		result[fieldId] = value || undefined
	}

	return result
}

export const getCriterionAnswersDefaults = (
	criteria: z.infer<typeof CriteriaAPISchema>,
	options?: { fieldsDisabledByDefault?: boolean },
): Record<string, unknown> => {
	return criteria.reduce(
		(acc, item) => {
			item.answers.forEach(answer => {
				const fieldId = generateCriterionAnswerFieldId(answer.verticalId, answer.criterionId)
				Object.assign(acc, processDefaultCriterionFieldValue(item.criterion.type, fieldId, answer.value, options))
			})
			return acc
		},
		{} as Record<string, unknown>,
	)
}

export const generateEditAnswersContext = async (
	companyId: string,
	ecosystemId: string,
	type: z.infer<typeof CRITERION_TYPE_ENUM>,
) => {
	const verticals = await client.fetchQuery(verticalsQueries.list(companyId, Number(ecosystemId)))
	const criteria = await client.fetchQuery(criteriaAssignedQuery(companyId, ecosystemId))

	const criteriaFilteredByType = criteria.filter(item =>
		type === 'qualification' ? !item.criterion.isEnrichment : item.criterion.isEnrichment,
	)

	const updatedCriteria = fillCriteriaWithEmptyAnswers(criteriaFilteredByType, verticals)
	const defaultValues = getCriterionAnswersDefaults(updatedCriteria, { fieldsDisabledByDefault: true })
	const schema = generateSchemaFromCriteriaAnswers(updatedCriteria)

	return { schema, defaultValues, updatedCriteria }
}

export const getCriterionVerticalsAnswersDefaults = (
	verticals: z.infer<typeof VerticalListAPISchema>,
	criterion?: z.infer<typeof CriterionQuestionAPISchema>,
	options?: { fieldsDisabledByDefault?: boolean },
): Record<string, unknown> => {
	return verticals.reduce(
		(acc, item) => {
			const answer = criterion && item.answers.find(answer => answer.criterionId === criterion.id)
			const fieldId = generateCriterionAnswerFieldId(item.id, criterion?.id)

			if (answer) {
				Object.assign(acc, processDefaultCriterionFieldValue(criterion.type, fieldId, answer.value, options))
			} else {
				if (options?.fieldsDisabledByDefault) {
					acc[`${fieldId}Toggle`] = false
				}
			}

			return acc
		},
		{} as Record<string, unknown>,
	)
}

export const generateSaveCriterionSchema = (
	verticals: z.infer<typeof VerticalListAPISchema>,
	fieldType: APIFieldType,
	type: z.infer<typeof CRITERION_TYPE_ENUM>,
	criterion?: z.infer<typeof CriterionQuestionAPISchema>,
): z.ZodObject<Record<string, ZodType<unknown>>> => {
	const shape = verticals.reduce(
		(acc, vertical) => {
			const fieldName = generateCriterionAnswerFieldId(vertical.id, criterion?.id)

			if (API_TO_FIELD_TYPE_MAP[fieldType]) {
				const isEnrichment = criterion?.isEnrichment ?? type === 'enrichment'

				// For display purposes range is shown inside select component
				if (fieldType === 'range') {
					acc[fieldName] = getFieldSchemaByType({
						fieldType: 'select',
						isOptional: isEnrichment,
						isNullable: isEnrichment,
					})

					return acc
				}

				acc[fieldName] = getFieldSchemaByType({ fieldType, isOptional: isEnrichment, isNullable: isEnrichment })
			}

			return acc
		},
		{} as Record<string, ZodType<unknown>>,
	)

	return z.object(shape)
}

export const generateSaveCriterionContext = (
	verticals: z.infer<typeof VerticalListAPISchema>,
	fieldType: APIFieldType,
	type: z.infer<typeof CRITERION_TYPE_ENUM>,
	criterion?: z.infer<typeof CriterionQuestionAPISchema>,
) => {
	const schema = generateSaveCriterionSchema(verticals, fieldType, type, criterion)
	const defaultValues = getCriterionVerticalsAnswersDefaults(verticals, criterion, { fieldsDisabledByDefault: true })

	return { schema, defaultValues }
}

export const fillCriteriaWithEmptyAnswers = (
	criteria: z.infer<typeof CriteriaAPISchema>,
	verticals: z.infer<typeof VerticalAPISchema>[],
): z.infer<typeof CriteriaAPISchema> => {
	return criteria.map(item => {
		const answers = verticals.map(vertical => {
			const answer = item.answers.find(a => a.verticalId === vertical.id)

			return {
				criterionId: item.criterion.id,
				question: item.criterion.name,

				// Undefined means field is disabled, null - active
				value: answer?.value,
				verticalId: vertical.id,
				vertical: vertical.name,
			}
		})

		return {
			...item,
			answers,
		}
	})
}

export const splitCriteriaByType = (data: z.infer<typeof CriteriaAPISchema>) => {
	return {
		criteria: data.filter(item => !item.criterion.isEnrichment),
		enrichmentCriteria: data.filter(item => item.criterion.isEnrichment),
	}
}

export const generateCriterionAnswerPerVertical = (
	criterion: z.infer<typeof CriterionQuestionAPISchema>,
	answer: z.infer<typeof CriterionAnswerAPISchema>,
	fieldSuffix: ReactNode,
) => {
	const baseFieldProps = {
		name: generateCriterionAnswerFieldId(answer.verticalId, criterion.id),
		extendedPlaceholder: criterion.name || 'value',
		label: answer.vertical,
		labelClassName: 'text-neutral-2-fg',
		enableToggle: true,
		labelSize: 'md' as VariantProps<typeof labelSizeVariants>['size'],
		wrapperClassName: 'flex-1',
	}

	const fieldProps = {
		...baseFieldProps,
		...(criterion.type === 'boolean' ? { layout: 'horizontal' } : {}),
		...(criterion.type === 'select' || criterion.type === 'number' || criterion.type === 'range'
			? { iconRight: criterion.unit }
			: {}),
		...(criterion.type === 'text' ? { autoExpand: true } : {}),
		...(criterion.type === 'text' ? { rows: 1 } : {}),
	}

	switch (criterion.type) {
		case 'regions':
			return (
				<FormField
					{...baseFieldProps}
					fieldType="regions"
					key={criterion.id + answer.verticalId}
					fieldSuffix={fieldSuffix}
				/>
			)

		case 'range':
			return (
				<FormField
					{...fieldProps}
					fieldType="select"
					options={transformFieldOptionsFromApi(criterion.type, criterion.options) as SelectOption[]}
					key={criterion.id + answer.verticalId}
					fieldSuffix={fieldSuffix}
				/>
			)

		case 'select':
			return (
				<FormField
					{...fieldProps}
					fieldType="select"
					options={transformFieldOptionsFromApi(criterion.type, criterion.options) as SelectOption[]}
					key={criterion.id + answer.verticalId}
					fieldSuffix={fieldSuffix}
					enableRealTimeUpdate
				/>
			)
		case 'multiselect':
			return (
				<FormField
					{...fieldProps}
					fieldType="multiselect"
					options={transformFieldOptionsFromApi(criterion.type, criterion.options) as MultiSelectOption[]}
					chipSeparatorText={criterion.logic}
					key={criterion.id + answer.verticalId}
					fieldSuffix={fieldSuffix}
					enableRealTimeUpdate
				/>
			)
		case 'boolean':
			return (
				<FormField
					{...baseFieldProps}
					fieldType="radio"
					layout="horizontal"
					options={transformFieldOptionsFromApi(criterion.type, criterion.options) as RadioGroupOption[]}
					key={criterion.id + answer.verticalId}
					fieldSuffix={fieldSuffix}
				/>
			)

		// For now backend only support text type
		case 'text':
			return (
				<FormField
					{...fieldProps}
					fieldType="textarea"
					key={criterion.id + answer.verticalId}
					fieldSuffix={fieldSuffix}
				/>
			)
		default:
			return (
				<FormField
					{...fieldProps}
					// @ts-expect-error TypeScript may not recognize mapped valid criterion type values
					fieldType={API_TO_FIELD_TYPE_MAP[criterion.type]}
					key={criterion.id + answer.verticalId}
					fieldSuffix={fieldSuffix}
				/>
			)
	}
}

export const renderCriterionAnswerValue = (verticalCriterion?: z.infer<typeof VerticalCriterionAPISchema>) => {
	const value = verticalCriterion?.value
	const type = verticalCriterion?.type

	if (value === undefined)
		return (
			<CustomTooltip label="Disabled">
				<Icon name="error" className="text-neutral-3-fg" />
			</CustomTooltip>
		)

	if (!value) return null

	if (type === 'range' && typeof value === 'string') {
		const rangeItems = value.split(',').map(item => item.trim())
		const rangeInterval = rangeItems.length === 1 ? `${rangeItems[0]}+` : rangeItems.join('-')

		return (
			<Chip variant="blue" className="gap-0.5">
				{rangeInterval}
				{verticalCriterion.unit && <span>{verticalCriterion.unit}</span>}
			</Chip>
		)
	}

	if (type === 'multiselect' && Array.isArray(value)) {
		return (
			<div className="flex flex-wrap items-center gap-1">
				{value.map((item, i) => (
					<Fragment key={i}>
						<Chip variant="gray">{item}</Chip>
						{verticalCriterion.logic && i + 1 !== value.length && (
							<span className="mx-0.5 text-label-sm font-semibold text-neutral-3-fg">{verticalCriterion.logic}</span>
						)}
					</Fragment>
				))}
			</div>
		)
	}

	if (Array.isArray(value)) {
		return (
			<div className="flex flex-wrap gap-1">
				{value.map((item, index) => (
					<Chip key={index}>{item}</Chip>
				))}
			</div>
		)
	}

	if (value && typeof value === 'object' && type === 'regions') {
		const renderChips = (items?: string[], variant?: 'green' | 'red') =>
			items?.map((item, index) => (
				<Chip key={`${variant || 'default'}-${index}`} variant={variant} className="flex items-center gap-x-1.5">
					{item}
				</Chip>
			))

		return (
			<div className="flex flex-wrap gap-1">
				{renderChips(value.included, 'green')}
				{renderChips(value.excluded, 'red')}
			</div>
		)
	}

	if (type === 'number' && typeof value === 'string') {
		return (
			<Chip variant="blue" className="gap-0.5">
				{formatNumberWithSpaces(value)}
				{verticalCriterion.unit && <span>{verticalCriterion.unit}</span>}
			</Chip>
		)
	}

	if (typeof value === 'string') {
		return <span>{value}</span>
	}
}
