import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useLoaderData, useNavigate } from 'react-router'
import { type z } from 'zod'
import { type VerticalListAPISchema } from '#src/api/icp/company/verticals/schemas'
import ProductTip from '#src/components/product-tip'
import useCompany from '#src/hooks/useCompany'
import {
	autofocusFieldMutation,
	autofocusFieldQuery,
} from '#src/routes/calibrate/ecosystem-management/verticals/autofocus'
import { type LoaderResType } from '#src/routes/calibrate/ecosystem-management/verticals/loaders'
import { routes } from '#src/utils/routes'

export const useNotesGroup = (verticals: z.infer<typeof VerticalListAPISchema>) => {
	const {
		handle: { ecosystemId, readOnlySession },
	} = useLoaderData<LoaderResType>()
	const navigate = useNavigate()
	const { companyId } = useCompany()
	const { data: autofocus } = useQuery(autofocusFieldQuery())

	const header = useMemo(
		() => ({
			collapsible: {
				options: { sticky: true, collapsible: true },
				columns: [
					{
						children: (
							<p className="flex items-center gap-1 text-body-md font-semibold text-neutral-2-fg">
								Notes
								<ProductTip content="Provide guidelines, platforms, tools, and resources for list building and ICP mapping." />
							</p>
						),
					},
					...verticals.map(() => ({ className: 'border-r-transparent', children: '' })),
				],
			},
		}),
		[verticals],
	)

	const body = useMemo(
		() => ({
			id: 'group-5',
			rows: [
				{
					focus: autofocus?.includes('listBuildingNotes'),
					columns: [
						{ className: 'align-top', children: '' },
						...verticals.map(vertical => ({
							...(readOnlySession
								? {}
								: {
										onClick: () => {
											autofocusFieldMutation('listBuildingNotes')
											void navigate(
												routes.calibrate.verticals.edit({
													companyId,
													ecosystemId,
													verticalId: vertical.id.toString(),
												}),
												{ replace: true, preventScrollReset: true },
											)
										},
									}),
							className: 'align-top',
							children: (
								<p className="whitespace-pre-line text-body-md font-normal text-neutral-2-fg">
									{vertical.listBuildingNotes}
								</p>
							),
						})),
					],
				},
			],
		}),
		[autofocus, verticals, readOnlySession, navigate, companyId, ecosystemId],
	)

	return { ...header, body }
}
