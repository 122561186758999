import { type ActionFunctionArgs } from 'react-router'
import { personaMutations } from '#src/api/icp/company/persona/mutations'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'

export const action = async ({ request, params }: ActionFunctionArgs) => {
	validateRouteParams(params, ['verticalId'])
	const { company } = await getCompany(params)

	const formData = await request.formData()
	const intent = formData.get('intent')

	if (intent === 'all') {
		await personaMutations.recalculatePersonas(company.id, params.verticalId)
	} else if (intent === 'single' && params.personaId) {
		await personaMutations.recalculatePersona(company.id, params.personaId)
	} else {
		throw new Response('Missing parameters', {
			status: 400,
			statusText: 'Bad Request',
		})
	}

	return null
}
