import { useQuery } from '@tanstack/react-query'
import { type z } from 'zod'
import { companyPersonaQueries } from '#src/api/icp/company/persona/queries'
import { type PersonaAPISchema } from '#src/api/icp/company/persona/schemas'
import { BASE_REFETCH_INTERVAL } from '#src/constants/refetchIntervals'
import useCompany from '#src/hooks/useCompany'

type Props = {
	personaId: number | string
	initialData: z.infer<typeof PersonaAPISchema>
}

const usePersonaDetails = (props: Props) => {
	const { personaId, initialData } = props
	const { company } = useCompany()
	const { data, isLoading } = useQuery({
		...companyPersonaQueries.item(company.id, Number(personaId)),
		initialData,
		refetchInterval: ({ state: { data: currentData } }) =>
			currentData?.prompt?.status && ['Pending', 'In Progress', 'Queued'].includes(currentData.prompt.status)
				? BASE_REFETCH_INTERVAL
				: false,
		refetchOnWindowFocus: true,
	})

	return {
		data,
		isLoading,
	}
}

export default usePersonaDetails
