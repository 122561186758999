import { type z } from 'zod'
import { type PersonaAPISchema } from '#src/api/icp/company/persona/schemas'
import { ComponentErrorBoundary } from '#src/components/error'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { MODAL_NAME } from '#src/constants/modals'
import useAuth from '#src/hooks/useAuth'
import { useModals } from '#src/hooks/useModals'
import { PersonaCreateModal } from '#src/modals/personas/PersonaCreate'
import { RecalculatePersonaAsyncForm } from '#src/routes/calibrate/ecosystem-management/personas/recalculate'

type Props = {
	persona: z.infer<typeof PersonaAPISchema>
}

const PersonaPromptError = (props: Props) => {
	const { persona } = props
	const { openModal } = useModals()
	const { readOnlySession } = useAuth()

	if (!persona || readOnlySession) return null

	return (
		<ComponentErrorBoundary
			title="Ouch!"
			message="Failed to generate output!"
			description="It seems like there was an error when generating the output. Retry or try again later."
			className="px-10 py-32"
		>
			<div className="flex items-center gap-4">
				{persona.id ? (
					<RecalculatePersonaAsyncForm
						ecosystemId={Number(persona.ecosystem.id)}
						verticalId={persona.vertical.id.toString()}
						personaId={persona.id.toString()}
						variant="default"
						className="flex w-40 grow items-center gap-2"
					>
						<Icon name="rotate" size="sm" />
						Recalculate Persona
					</RecalculatePersonaAsyncForm>
				) : null}
				<Button asChild className="flex w-40 grow items-center justify-center gap-2 px-4" variant="outline">
					<Button
						size="icon"
						variant="icon"
						onClick={() =>
							openModal({
								name: MODAL_NAME.PersonaCreateModal,
								component: PersonaCreateModal,
								props: {
									ecosystemId: Number(persona.ecosystem.id),
									verticalId: Number(persona.vertical.id),
									personaId: Number(persona.id),
								},
							})
						}
					>
						<Icon name="edit" size="sm" />
					</Button>
				</Button>
			</div>
		</ComponentErrorBoundary>
	)
}

export default PersonaPromptError
