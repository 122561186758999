import { Link } from 'react-router'
import { type z } from 'zod'
import { type PlaysTemplatesItemAPISchema } from '#src/api/icp/company/plays-templates/schemas'
import { Chip } from '#src/components/chip'
import LoaderRequiredComponent from '#src/components/LoaderRequiredComponent'
import { Button } from '#src/components/ui/button'
import { Dialog } from '#src/components/ui/dialog'
import { LabeledValue } from '#src/components/ui/LabeledValue'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { type LoaderRes } from '#src/routes/enable/plays/templates/loaders/item'
import { routes } from '#src/utils/routes'

export type PlayTemplateViewModalProps = {
	templateId: z.infer<typeof PlaysTemplatesItemAPISchema.shape.id>
}

export const PlayTemplateViewModal = (props: PlayTemplateViewModalProps) => {
	const { companyId } = useCompany()

	return (
		<LoaderRequiredComponent<LoaderRes, PlayTemplateViewModalProps>
			route={routes.enable.playTemplates.item({ companyId, templateId: String(props.templateId) })}
			component={ModalInner}
			initialProps={props}
		/>
	)
}

const ModalInner = (props: PlayTemplateViewModalProps & LoaderRes) => {
	const {
		data: { template },
	} = props
	const { closeModal } = useModals()
	const { companyId } = useCompany()

	return (
		<Dialog
			defaultOpen={true}
			onOpenChange={isOpen => !isOpen && closeModal(MODAL_NAME.PlayTemplateView)}
			dialogHeading={`Play template: ${template.name}`}
			actions={[
				{ type: 'cancel', label: 'Close' },
				<Button key={`use-template-btn`} size="sm" asChild>
					<Link to={routes.enable.plays.create({ companyId }, String(template.id))}>Use template</Link>
				</Button>,
			]}
			size="xl"
		>
			<div className="flex flex-col gap-6">
				<LabeledValue label="Name">{template.name}</LabeledValue>
				<LabeledValue label="Instructions">{template.instructions}</LabeledValue>
				{template.tags.length > 0 && (
					<LabeledValue label="Tags">
						<div className="flex flex-row flex-wrap gap-1">
							{template.tags.map(item => (
								<Chip variant="gray" key={`tag-${item}`}>
									{item}
								</Chip>
							))}
						</div>
					</LabeledValue>
				)}
				<LabeledValue label="Persona types">
					{template.personaTypes.length ? (
						<div className="flex flex-row flex-wrap gap-1">
							{template.personaTypes.map(item => (
								<Chip variant="gray" key={`persona-type-${item}`}>
									{item}
								</Chip>
							))}
						</div>
					) : (
						'-'
					)}
				</LabeledValue>
				<LabeledValue label="Use this template in Lead Research Agent jobs">
					{template.inCrm ? 'Yes' : 'No'}
				</LabeledValue>
				<LabeledValue label="Use this template in Copilot">{template.inCopilot ? 'Yes' : 'No'}</LabeledValue>
			</div>
		</Dialog>
	)
}
