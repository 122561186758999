import { z } from 'zod'
import { COMPANY_USER_STATUS } from '#src/utils/enumerations'
import { zString } from '#src/utils/validation'

export enum USER_ROLES {
	User = 'user',
	ReadOnly = 'read-only',
	EnableOnly = 'enable-only',
	DataTrainer = 'data-trainer',
	Admin = 'admin',
}

export const CompanyUserAPISchema = z.object({
	id: z.string(),
	email: z.string(),
	name: z.string().nullable(),
	roles: z.nativeEnum(USER_ROLES).array(),
	status: z.nativeEnum(COMPANY_USER_STATUS),
	createdAt: z.string().datetime(),
})

export const RolesAPISchema = z.record(z.string(), z.nativeEnum(USER_ROLES))

export const InviteFormSchema = z.object({
	email: zString(z.string().email()),
	name: z.string().optional(),
	role: z.nativeEnum(USER_ROLES),
})

export const EditFormSchema = z.object({
	name: z.string().nullable().optional(),
	role: z.nativeEnum(USER_ROLES),
})
