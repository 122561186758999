import { type LabelHTMLAttributes, useMemo, useState } from 'react'
import CheckboxTreeInput, { type TreeViewItem } from '#src/components/forms/CheckboxTreeInput'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { Input } from '#src/components/ui/input'
import useDebounce from '#src/hooks/useDebounce'
import { selectionControlDisabledVariants } from '#src/theme'
import { cn } from '#src/utils/misc'

export type CheckboxTreeProps = {
	labelProps?: LabelHTMLAttributes<HTMLLabelElement>
	searchPlaceholder?: string
	hideSearch?: boolean
	options: TreeViewItem[]
	value?: string[] | undefined
	onChange?: (data: string[]) => void
	disabled?: boolean
	noItemsText?: string
}

export const CheckboxTreeViewWithSearch = (props: CheckboxTreeProps) => {
	const { value, onChange, searchPlaceholder, hideSearch, options, disabled, noItemsText } = props
	const [search, setSearch] = useState<string>('')
	const searchQuery = useDebounce(search, 100)

	const filteredOptions = useMemo(() => {
		if (!searchQuery) {
			return options
		}

		// search works only up to 2 node levels
		return options
			.map(item => ({
				...item,
				children: item.children?.filter(
					child =>
						child.label.toLowerCase().includes(searchQuery.toLowerCase()) ||
						item.label.toLowerCase().includes(searchQuery.toLowerCase()),
				),
			}))
			.filter(item => item.children?.length)
	}, [options, searchQuery])

	const flattenOptions = useMemo(() => {
		const recursiveOptionParse = (data: TreeViewItem): TreeViewItem[] => {
			if (data.children) {
				return data.children.flatMap(recursiveOptionParse)
			} else {
				return [data]
			}
		}

		return options.map(recursiveOptionParse).flat()
	}, [options])

	const onValueChange = (value: string[]) => {
		onChange?.(value)
	}

	const onSelectAll = () => {
		onValueChange(flattenOptions.map(item => item.id))
	}

	const onDeselectAll = () => {
		onValueChange([])
	}

	return (
		<div className={cn('flex flex-col gap-2', disabled && selectionControlDisabledVariants.true)}>
			{!hideSearch && (
				<Input
					value={search}
					onChange={e => setSearch(e.target.value)}
					clearable
					placeholder={searchPlaceholder}
					iconRight="search"
				/>
			)}
			{!search && (
				<div className="flex flex-row gap-2">
					<Button type="button" onClick={onSelectAll} size="sm" variant="ghost">
						Select all
					</Button>
					<Button type="button" onClick={onDeselectAll} size="sm" variant="ghost">
						Deselect all
					</Button>
				</div>
			)}
			<CheckboxTreeInput
				disabled={disabled}
				value={value ?? []}
				options={filteredOptions}
				onChange={onValueChange}
				closed
				noItemsText={noItemsText}
			/>
			{options.length && !filteredOptions.length ? (
				<section className="m-auto h-full gap-1 text-center">
					<Icon name="search-dialogue" className="mb-2 h-14 w-14 text-brand-1-fg" />
					<h3 className="text-center text-body-lg font-semibold text-neutral-2-fg">No search results</h3>
					<div className="mx-auto max-w-[400px]">
						<p className="truncate text-body-md">{`No results for “${search}”`}</p>
					</div>
				</section>
			) : (
				!options.length && (
					<section className="m-auto h-full gap-1 text-center">
						<Icon name="warning-alt" className="mb-2 h-14 w-14 text-orange-70" />
						<h3 className="text-center text-body-lg font-semibold text-neutral-2-fg">No options to select</h3>
						<div className="mx-auto max-w-[400px]">
							<p className="text-body-md">Missing setup to have available select options</p>
						</div>
					</section>
				)
			)}
		</div>
	)
}
