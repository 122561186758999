import { type z } from 'zod'
import { type PersonaAPISchema } from '#src/api/icp/company/persona/schemas'
import { PersonaDetailsCard } from '#src/components/shared'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { MODAL_NAME } from '#src/constants/modals'
import useAuth from '#src/hooks/useAuth'
import { useModals } from '#src/hooks/useModals'
import { PersonaCreateModal } from '#src/modals/personas/PersonaCreate'
import { type EcosystemListAPISchema } from '#src/routes/calibrate/ecosystem-management/ecosystem/schema'
import { DeletePersonaAsyncForm } from '#src/routes/calibrate/ecosystem-management/personas/delete'

type Props = {
	persona: z.infer<typeof PersonaAPISchema>
	ecosystems: z.infer<typeof EcosystemListAPISchema>
}

const PersonaHeader = (props: Props) => {
	const { persona } = props
	const { readOnlySession } = useAuth()
	const { openModal } = useModals()

	return (
		<section className="flex items-center justify-between px-10 pb-8 pt-10">
			<PersonaDetailsCard persona={persona} avatarSize="lg" />
			{!readOnlySession && (
				<section className="flex flex-nowrap items-center gap-1">
					<Button
						size="icon"
						variant="icon"
						onClick={() =>
							openModal({
								name: MODAL_NAME.PersonaCreateModal,
								component: PersonaCreateModal,
								props: {
									ecosystemId: Number(persona.ecosystem.id),
									verticalId: Number(persona.vertical.id),
									personaId: Number(persona.id),
									duplicating: true,
								},
							})
						}
					>
						<Icon name="copy" size="sm" />
					</Button>
					<Button
						size="icon"
						variant="icon"
						onClick={() =>
							openModal({
								name: MODAL_NAME.PersonaCreateModal,
								component: PersonaCreateModal,
								props: {
									ecosystemId: Number(persona.ecosystem.id),
									verticalId: Number(persona.vertical.id),
									personaId: Number(persona.id),
								},
							})
						}
					>
						<Icon name="edit" size="sm" />
					</Button>
					<DeletePersonaAsyncForm
						ecosystemId={String(persona.ecosystem.id)}
						verticalId={String(persona.vertical.id)}
						personaId={String(persona.id)}
						className="flex items-center justify-center"
					>
						<Icon name="trash" size="sm" />
					</DeletePersonaAsyncForm>
				</section>
			)}
		</section>
	)
}

export default PersonaHeader
