import { ECOSYSTEM_TYPE } from '#src/routes/calibrate/ecosystem-management/ecosystem/constants'
import { routes } from '#src/utils/routes'

export const getEcosystemCreateLink = (type: ECOSYSTEM_TYPE, companyId: string) => {
	switch (type) {
		case ECOSYSTEM_TYPE.KEY_ACCOUNT:
			return routes.calibrate.ecosystem.createKeyAccounts({ companyId })
		case ECOSYSTEM_TYPE.CRITERIA_SANDBOX:
			return routes.calibrate.ecosystem.createCriteriaSandbox({ companyId })
		default:
			return routes.calibrate.ecosystem.create({ companyId })
	}
}

export const getEcosystemCTALabel = (action: 'Add' | 'Delete' | 'Update' | 'Rename', type: ECOSYSTEM_TYPE) => {
	switch (type) {
		case ECOSYSTEM_TYPE.KEY_ACCOUNT:
			return `${action} Key Accounts`
		case ECOSYSTEM_TYPE.CRITERIA_SANDBOX:
			return `${action} Criteria Sandbox`
		default:
			return `${action} Ecosystem`
	}
}

export const getVerticalCTALabel = (
	action: 'Add' | 'Delete' | 'Update' | 'Edit',
	type: ECOSYSTEM_TYPE = ECOSYSTEM_TYPE.DEFAULT,
) => {
	switch (type) {
		case ECOSYSTEM_TYPE.KEY_ACCOUNT:
			return `${action} Key Account`
		case ECOSYSTEM_TYPE.CRITERIA_SANDBOX:
			return `${action} Account`
		default:
			return `${action} Vertical`
	}
}

export const getEcosystemEmptyStateTitle = (type: ECOSYSTEM_TYPE = ECOSYSTEM_TYPE.DEFAULT) => {
	const entity = (() => {
		switch (type) {
			case ECOSYSTEM_TYPE.KEY_ACCOUNT:
				return 'key accounts and personas for these key accounts'
			case ECOSYSTEM_TYPE.CRITERIA_SANDBOX:
				return 'accounts for this criteria sandbox'
			default:
				return 'verticals and personas for this ecosystem'
		}
	})()

	return `You don't have any ${entity} yet`
}

export const getEcosystemDeleteDescription = (type: ECOSYSTEM_TYPE) => {
	const entity = (() => {
		switch (type) {
			case ECOSYSTEM_TYPE.KEY_ACCOUNT:
				return 'key accounts'
			case ECOSYSTEM_TYPE.CRITERIA_SANDBOX:
				return 'criteria sandbox'
			default:
				return 'ecosystem'
		}
	})()

	return `This action cannot be undone. This will permanently delete the ${entity} and remove any associated data.`
}
