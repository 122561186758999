import { type ActionFunctionArgs, redirect } from 'react-router'
import { criteriaMutations } from '#src/api/icp/company/criteria/mutations'
import { CreateEnrichCriterionAnswersFormSchema } from '#src/api/icp/company/criteria/schema'
import { showToast } from '#src/context/ToastContext'
import { parseFormDataNew } from '#src/utils/forms'
import { validateRouteParams } from '#src/utils/misc'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'

export type ActionRes = Awaited<ReturnType<typeof action>>

export const action = async ({ params, request }: ActionFunctionArgs) => {
	validateRouteParams(params, ['ecosystemId'])
	const { company } = await getCompany(params)
	const formData = await request.formData()

	try {
		const submission = parseFormDataNew(formData, CreateEnrichCriterionAnswersFormSchema)
		await criteriaMutations.createEnrichCriterionAnswers(company.id, submission.value)

		showToast({
			message: `${submission.value.runs} criterion ${submission.value.runs === 1 ? 'run has' : 'runs have'} been successfully initiated`,
			type: 'success',
		})

		return redirect(routes.calibrate.verticals.index({ companyId: company.id, ecosystemId: params.ecosystemId }))
	} catch (err) {
		return await serverFormErrorHandler(err)
	}
}
