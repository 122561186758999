import * as TabsPrimitive from '@radix-ui/react-tabs'
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, type ReactNode } from 'react'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router'
import { ScrollArea, type ScrollAreaProps } from '#src/components/ScrollArea'
import { type ClassName } from '#src/types/styles'
import { cn } from '#src/utils/misc'

export const Tabs = forwardRef<
	ElementRef<typeof TabsPrimitive.Root>,
	ComponentPropsWithoutRef<typeof TabsPrimitive.Root>
>(({ className, children, ...props }, ref) => {
	return (
		<TabsPrimitive.Root
			{...props}
			ref={ref}
			className={cn(
				'grid h-full w-full grid-cols-1 grid-rows-[max-content,1fr] items-start justify-items-center',
				className,
			)}
		>
			{children}
		</TabsPrimitive.Root>
	)
})
Tabs.displayName = TabsPrimitive.Root.displayName

export const TabsList = forwardRef<
	ElementRef<typeof TabsPrimitive.List>,
	ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, children, ...props }, ref) => {
	return (
		<TabsPrimitive.List
			{...props}
			ref={ref}
			className={cn('flex w-full flex-nowrap items-center border-b-2 border-neutral-1-bd', className)}
		>
			{children}
		</TabsPrimitive.List>
	)
})
TabsList.displayName = TabsPrimitive.List.displayName

export const TabTrigger = forwardRef<
	ElementRef<typeof TabsPrimitive.Trigger>,
	ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, children, ...props }, ref) => {
	return (
		<TabsPrimitive.Trigger
			{...props}
			ref={ref}
			className={cn(
				'group relative min-w-[100px] bg-transparent px-4 py-3 text-center text-body-md text-neutral-2-fg transition-colors data-[state=active]:font-medium data-[state=active]:text-neutral-2-fg-selected',
				className,
			)}
		>
			{children}
			<div className="absolute bottom-[-2px] left-0 h-2 w-full border-b-2 border-transparent transition-colors hover:border-brand-1-bd-hover group-hover:border-brand-1-bd-hover group-data-[state=active]:border-brand-1-bd" />
		</TabsPrimitive.Trigger>
	)
})
TabTrigger.displayName = TabsPrimitive.Trigger.displayName

export const TabContent = forwardRef<
	ElementRef<typeof TabsPrimitive.Content>,
	ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, children, ...props }, ref) => {
	return (
		<TabsPrimitive.Content {...props} ref={ref} className={cn('w-full py-6', className)}>
			{children}
		</TabsPrimitive.Content>
	)
})
TabContent.displayName = TabsPrimitive.Content.displayName

export type TabItem = {
	label: ReactNode | string
	value: string
	content?: ReactNode
	empty?: ReactNode
}

type TabValues<T extends readonly TabItem[]> = T[number]['value']

type TabSwitcherProps<T extends readonly TabItem[]> = {
	tabs: T
	defaultValue?: TabValues<T>
	contentScrollable?: boolean
	scrollAreaProps?: Omit<ScrollAreaProps, 'children'>
	contentClassName?: ClassName
	listClassName?: ClassName
	className?: ClassName
	actions?: ReactNode[]
	disableQueryParamSync?: boolean
}

export const TabSwitcher = <T extends readonly TabItem[]>({
	tabs,
	defaultValue,
	contentScrollable,
	scrollAreaProps,
	contentClassName,
	listClassName,
	className,
	actions,
	disableQueryParamSync,
}: TabSwitcherProps<T>) => {
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()
	const currentTab = searchParams.get('tab') || defaultValue || tabs[0]?.value

	const handleTabChange = (v: string) => {
		if (!disableQueryParamSync) {
			void navigate({ search: createSearchParams({ tab: v }).toString() })
		}
	}

	return (
		<Tabs value={currentTab} defaultValue={defaultValue} onValueChange={handleTabChange} className={className}>
			<div className="relative flex w-full">
				<TabsList className={listClassName}>
					{tabs.map(tab => (
						<TabTrigger key={tab.value} value={tab.value}>
							{tab.label}
						</TabTrigger>
					))}
				</TabsList>
				{actions?.length && (
					<div className="absolute right-0 top-0 flex gap-2">
						{actions.map((action, i) => (
							<div key={i}>{action}</div>
						))}
					</div>
				)}{' '}
			</div>
			{tabs.map(tab => (
				<TabContent
					key={tab.value}
					value={tab.value}
					className={cn('h-full', !tab.content && 'flex items-center justify-center pb-0', contentClassName)}
				>
					{tab.content ? (
						contentScrollable ? (
							<ScrollArea
								{...scrollAreaProps}
								viewPortClassName={cn('pe-0 pb-3', scrollAreaProps?.viewPortClassName)}
								scrollbarClassName={cn('-me-3.5', scrollAreaProps?.scrollbarClassName)}
							>
								{tab.content}
							</ScrollArea>
						) : (
							tab.content
						)
					) : (
						tab.empty
					)}
				</TabContent>
			))}
		</Tabs>
	)
}
