import * as Sentry from '@sentry/react'
import { type LoaderFunctionArgs, redirect } from 'react-router'
import { COMPANY_USER_STATUS } from '#src/utils/enumerations'
import { routes } from '#src/utils/routes'
import { getCompany } from '#src/utils/server/company'
import { getUser } from '#src/utils/server/user'

export type MainLoaderResponse = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	const { user, enableOnlySession } = await getUser()

	if (user.status === COMPANY_USER_STATUS.deactivated) {
		throw redirect(routes.auth.logout)
	}

	const { company, companyId } = await getCompany(params)
	Sentry.setUser({
		userId: user.userId,
		companyId: company.id,
	})

	if (enableOnlySession && !new URL(request.url).pathname.includes('/enable'))
		throw redirect(routes.enable.index({ companyId }))

	return {
		user,
		company,
	}
}
