import { type z } from 'zod'
import { companyPersonaEndpoints } from '#src/api/icp/company/persona/endpoints'
import { companyPersonaQueriesKeys } from '#src/api/icp/company/persona/queries'
import {
	type CopyPersonaFormSchema,
	CopyValidatePersonaAPIResSchema,
	type CopyValidatePersonaPayloadSchema,
	GenTypesOfTasksAPISchema,
	type GetTypesOfTasksFormSchema,
	type PersonaAPISchema,
	type PersonaFormSchema,
	PersonaListAPISchema,
} from '#src/api/icp/company/persona/schemas'
import { verticalsQueriesKeys } from '#src/api/icp/company/verticals/queries'
import { client } from '#src/main'
import { ecosystemKeys } from '#src/routes/calibrate/ecosystem-management/ecosystem/queries'
import { type CompanySchema } from '#src/routes/company/schema'
import { api } from '#src/utils/endpoints'
import { fetch, safeFetch } from '#src/utils/safeFetch'

const mutations = {
	create: async (companyId: z.infer<typeof CompanySchema>['id'], data: z.infer<typeof PersonaFormSchema>) => {
		const res = await safeFetch(PersonaListAPISchema, companyPersonaEndpoints.create(companyId), {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
		await client.invalidateQueries({
			queryKey: companyPersonaQueriesKeys.all,
		})
		await client.invalidateQueries({
			queryKey: ecosystemKeys.all,
		})
		return res
	},
	update: async (
		companyId: z.infer<typeof CompanySchema>['id'],
		itemId: z.infer<typeof PersonaAPISchema>['id'],
		data: z.infer<typeof PersonaFormSchema>,
	) => {
		await fetch(companyPersonaEndpoints.update(companyId, itemId), {
			method: 'PATCH',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
		await client.invalidateQueries({
			queryKey: companyPersonaQueriesKeys.all,
		})
	},
	getTypesOfTasks: async (
		companyId: z.infer<typeof CompanySchema>['id'],
		data: z.infer<typeof GetTypesOfTasksFormSchema>,
	) => {
		return await safeFetch(GenTypesOfTasksAPISchema, companyPersonaEndpoints.getTypesOfTasks(companyId), {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
	},
	copyValidatePersonas: async (
		companyId: z.infer<typeof CompanySchema>['id'],
		data: z.infer<typeof CopyValidatePersonaPayloadSchema>,
	) => {
		return await safeFetch(CopyValidatePersonaAPIResSchema, companyPersonaEndpoints.copyValidate(companyId), {
			method: 'PUT',
			body: JSON.stringify(data),
		})
	},
	deletePersona: async (companyId: z.infer<typeof CompanySchema>['id'], personaId: string) => {
		const res = await fetch(companyPersonaEndpoints.item(companyId, Number(personaId)), {
			method: 'DELETE',
		})

		await client.invalidateQueries({
			queryKey: companyPersonaQueriesKeys.all,
		})
		await client.invalidateQueries({
			queryKey: ecosystemKeys.list(companyId),
		})

		return res
	},
	recalculatePersonas: async (companyId: z.infer<typeof CompanySchema>['id'], verticalId: string) => {
		try {
			return await fetch(api.vertical.recalculate(companyId, verticalId), {
				method: 'PUT',
			})
		} catch (error) {
			if (error instanceof Response && error.status === 429) {
				return Promise.resolve(new Response(null, { status: 200 }))
			}
			throw error
		}
	},
	recalculatePersona: async (companyId: z.infer<typeof CompanySchema>['id'], personaId: string) => {
		try {
			const res = await fetch(companyPersonaEndpoints.recalculate(companyId, Number(personaId)), {
				method: 'PUT',
			})

			await client.invalidateQueries({
				queryKey: companyPersonaQueriesKeys.all,
			})

			return res
		} catch (error) {
			if (error instanceof Response && error.status === 429) {
				return Promise.resolve(new Response(null, { status: 200 }))
			}
			throw error
		}
	},
	copyPersonas: async (companyId: z.infer<typeof CompanySchema>['id'], data: z.infer<typeof CopyPersonaFormSchema>) => {
		const res = await fetch(companyPersonaEndpoints.copy(companyId), {
			method: 'POST',
			body: JSON.stringify(data),
			headers: { 'content-type': 'application/json' },
		})

		await client.invalidateQueries({
			queryKey: verticalsQueriesKeys.all,
		})

		await client.invalidateQueries({
			queryKey: ecosystemKeys.all,
		})

		await client.invalidateQueries({
			queryKey: companyPersonaQueriesKeys.all,
		})

		return res
	},
	deletePersonas: async (
		companyId: z.infer<typeof CompanySchema>['id'],
		data: z.infer<typeof CopyValidatePersonaPayloadSchema>,
	) => {
		return await fetch(companyPersonaEndpoints.batchDelete(companyId), {
			method: 'DELETE',
			body: JSON.stringify(data),
			headers: { 'content-type': 'application/json' },
		})
	},
}

export { mutations as personaMutations }
