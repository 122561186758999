import { Icon, type IconName } from '#src/components/ui/icon'
import { type ClassName } from '#src/types/styles'
import { COMPANY_USER_STATUS } from '#src/utils/enumerations'
import { capitalizeFirstLetter, cn } from '#src/utils/misc'

const STATUS_STYLES: Record<COMPANY_USER_STATUS, { bg: ClassName; color: ClassName; icon: IconName }> = {
	[COMPANY_USER_STATUS.invited]: {
		bg: 'bg-neutral-2-bg',
		color: 'text-neutral-1-fg',
		icon: 'circle-dash',
	},
	[COMPANY_USER_STATUS.active]: {
		bg: 'bg-green-10',
		color: 'text-green-80',
		icon: 'checkmark-filled',
	},
	[COMPANY_USER_STATUS.deactivated]: {
		bg: 'bg-red-10',
		color: 'text-red-60',
		icon: 'stop-sign-filled',
	},
} as const

type StatusLabelProps = { status: COMPANY_USER_STATUS }

export const StatusLabel = ({ status }: StatusLabelProps) => (
	<div
		className={cn(
			'inline-flex items-center gap-1 rounded-xl py-1 pe-1.5 ps-1',
			STATUS_STYLES[status].bg,
			STATUS_STYLES[status].color,
		)}
	>
		<Icon
			name={STATUS_STYLES[status].icon}
			className={cn('h-4 w-4', STATUS_STYLES[status].icon.includes('loading') && 'animate-spin')}
		/>
		<div className="text-body-sm">{capitalizeFirstLetter(status)}</div>
	</div>
)
