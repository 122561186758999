import { type ReactNode } from 'react'
import { Link } from 'react-router'
import { Button, type ButtonProps } from '#src/components/ui/button'
import { Dropdown, type DropdownContentProps, DropdownItem } from '#src/components/ui/dropdown'
import { Icon, type IconProps } from '#src/components/ui/icon'
import { cn } from '#src/utils/misc'

type DropdownItem = {
	label: ReactNode
	to?: string
	onSelect?: (ev: Event) => void
	asChild?: boolean
	className?: string
}

type DropdownWithItems = {
	items: DropdownItem[]
	content?: never
}

type DropdownWithContent = {
	items?: never
	content: ReactNode
}

export type DropdownButtonProps = {
	buttonProps?: ButtonProps
	iconProps?: IconProps
	contentProps?: DropdownContentProps
	title?: ReactNode
	trigger?: ReactNode
} & (DropdownWithItems | DropdownWithContent)

/**
 * Renders a dropdown that can display either menu-like action items or static content.
 */
export const DropdownButton = ({
	buttonProps,
	iconProps,
	contentProps = { side: 'bottom', align: 'start' },
	title,
	trigger,
	items = [],
	content,
}: DropdownButtonProps) => {
	return (
		<Dropdown
			trigger={
				trigger ?? (
					<Button type="button" {...buttonProps} className={cn('flex items-center gap-1.5', buttonProps?.className)}>
						{title}
						{iconProps && <Icon {...iconProps} />}
					</Button>
				)
			}
			contentProps={contentProps}
		>
			{content
				? content
				: items?.map((item, i) =>
						item.to ? (
							<DropdownItem key={i} asChild className={item.className}>
								<Link to={item.to}>{item.label}</Link>
							</DropdownItem>
						) : (
							<DropdownItem key={i} asChild={item.asChild} className={item.className} onSelect={item.onSelect}>
								{item.label}
							</DropdownItem>
						),
					)}
		</Dropdown>
	)
}
