import { useEffect } from 'react'
import { MODAL_NAME } from '#src/constants/modals'
import { useModals } from '#src/hooks/useModals'
import { AdminPlaysExpertsCreateModal } from '#src/modals/admin/PlaysExpertsCreate'
import { routes } from '#src/utils/routes'

export const View = () => {
	const { openModal } = useModals()

	useEffect(() => {
		openModal({
			name: MODAL_NAME.AdminPlaysExpertsCreate,
			component: AdminPlaysExpertsCreateModal,
			settings: { routeToRedirectOnClose: routes.admin.playsExperts.list },
		})
	}, [openModal])

	return null
}
