import { Button } from '#src/components/ui/button'
import { PageLayout } from '#src/components/ui/PageLayout'

export const View = () => (
	<PageLayout title="">
		<div className="flex flex-1 flex-col items-center justify-center gap-4">
			<p>
				This page will close automatically after the download is complete. If it doesn’t, you can close it manually
				using the button below.
			</p>
			<Button onClick={() => window.close()}>Close window</Button>
		</div>
	</PageLayout>
)
