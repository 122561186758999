import { getUser } from '#src/utils/server/user'

export const loader = async () => {
	const { adminSession, userSession } = await getUser()

	if (!adminSession && !userSession) {
		throw new Response('Page Not Found', {
			status: 404,
			statusText: 'Not Found',
		})
	}

	return null
}
