import { type z } from 'zod'
import { type LeadResearchAgentItemAPISchema } from '#src/api/organise/lists/schemas'
import DeleteConfirm from '#src/components/delete-confirm'
import LoaderRequiredComponent from '#src/components/LoaderRequiredComponent'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { type LRAStopLoaderRes } from '#src/routes/organize/lead-research-agent/routes/stop'
import { routes } from '#src/utils/routes'

export type LeadResearchAgentJobStopModalProps = {
	jobId: z.infer<typeof LeadResearchAgentItemAPISchema.shape.id>
}

export const LeadResearchAgentJobStopModal = (props: LeadResearchAgentJobStopModalProps) => {
	const { companyId } = useCompany()

	return (
		<LoaderRequiredComponent<LRAStopLoaderRes, LeadResearchAgentJobStopModalProps>
			route={routes.leadResearchAgent.stop({ jobId: props.jobId, companyId })}
			component={ModalInner}
			initialProps={props}
		/>
	)
}

const ModalInner = (props: LeadResearchAgentJobStopModalProps & LRAStopLoaderRes) => {
	const {
		data: { job },
	} = props
	const { closeModal } = useModals()
	const { companyId } = useCompany()

	return (
		<DeleteConfirm
			defaultOpen={true}
			onOpenChange={isOpen => !isOpen && closeModal(MODAL_NAME.LeadResearchAgentJobStop)}
			action={routes.leadResearchAgent.stop({ jobId: job.id, companyId })}
			method="DELETE"
			actionTitle="Stop"
			title={`Stop "${job.title}"?`}
			description={
				<>
					Are you sure you want to stop the <b>{job.title}</b>?
				</>
			}
		/>
	)
}
