import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useFetcher } from 'react-router'
import { type z } from 'zod'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import LoaderRequiredComponent from '#src/components/LoaderRequiredComponent'
import { Dialog } from '#src/components/ui/dialog'
import { MODAL_NAME } from '#src/constants/modals'
import useCompany from '#src/hooks/useCompany'
import { useModals } from '#src/hooks/useModals'
import { type EditTermLoaderResponse } from '#src/routes/enable/glossary/edit'
import { type SaveGlossaryTermActionResponse } from '#src/routes/enable/glossary/save'
import { EditTermFormSchema, type GlossaryTermAPISchema } from '#src/routes/enable/glossary/schema'
import { routes } from '#src/utils/routes'

const FORM_ID = (glossaryId: z.infer<typeof GlossaryTermAPISchema.shape.id> | undefined) =>
	`term-${glossaryId ? `${glossaryId}-edit` : 'create'}-form`

export type TermEditModalProps = {
	termId?: z.infer<typeof GlossaryTermAPISchema.shape.id>
}

export const TermEditModal = (props: TermEditModalProps) => {
	const { companyId } = useCompany()

	return (
		<LoaderRequiredComponent<EditTermLoaderResponse, TermEditModalProps>
			route={routes.enable.glossary.edit({ companyId, termId: props.termId ? String(props.termId) : null })}
			component={ModalInner}
		/>
	)
}

export const ModalInner = (props: EditTermLoaderResponse) => {
	const { ecosystems, term } = props
	const { closeModal } = useModals()
	const { companyId } = useCompany()
	const actionFetcher = useFetcher<SaveGlossaryTermActionResponse>()

	const form = useForm<z.infer<typeof EditTermFormSchema>>({
		resolver: zodResolver(EditTermFormSchema),
		defaultValues: {
			ecosystemId: term?.ecosystem?.id ?? undefined,
			term: term?.term ?? '',
			manualInput: term?.manualInput ?? '',
		},
		mode: 'onSubmit',
	})

	return (
		<Dialog
			defaultOpen={true}
			onOpenChange={isOpen => !isOpen && closeModal(MODAL_NAME.TermEdit)}
			dialogHeading={`${term ? 'Edit' : 'New'} Term`}
			actions={[
				{ type: 'cancel', label: 'Close' },
				{ type: 'submit', formId: FORM_ID(term?.id), label: term ? 'Update' : 'Create' },
			]}
			isSubmitting={actionFetcher.state === 'submitting'}
		>
			<FormWrapper
				formId={FORM_ID(term?.id)}
				formProps={form}
				action={`${routes.enable.glossary.save({
					companyId,
					termId: term ? String(term.id) : null,
				})}?source=calibrate`}
				method={term ? 'PATCH' : 'POST'}
				className="flex flex-col gap-6"
				fetcher={actionFetcher}
				actionResponse={actionFetcher.data}
			>
				<FormField
					fieldType="select"
					name="ecosystemId"
					label="Ecosystem"
					options={ecosystems.map(ecosystem => ({ value: String(ecosystem.id), label: ecosystem.name }))}
				/>
				<FormField name="term" fieldType="text" />
				<FormField name="manualInput" fieldType="textarea" rows={5} />
			</FormWrapper>
		</Dialog>
	)
}
