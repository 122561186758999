import { type Dispatch, type SetStateAction } from 'react'
import { useLoaderData } from 'react-router'
import Priority from '#src/components/priority'
import Status from '#src/components/status'
import { Button } from '#src/components/ui/button'
import { Icon } from '#src/components/ui/icon'
import { SelectField } from '#src/components/ui/select'
import { usePersona } from '#src/hooks/usePersona'
import { type PersonasIndexLoaderResType } from '#src/routes/enable/personas/routes'
import { PERSONA_TYPE_OPTIONS, PRIORITY_OPTIONS, STATUS_OPTIONS } from '#src/utils/enumerations'
import { cn } from '#src/utils/misc'
import { type FiltersState } from '../../Index'

type PersonasFiltersProps = {
	filters: FiltersState
	setFilters: Dispatch<SetStateAction<FiltersState>>
}

export const PersonasFilters = ({ filters, setFilters }: PersonasFiltersProps) => {
	const { expertises } = useLoaderData<PersonasIndexLoaderResType>()
	const { getPersonasExpertiseOptions } = usePersona()

	const filtersConfig = [
		{
			key: 'type' as const,
			placeholder: 'Type',
			options: PERSONA_TYPE_OPTIONS,
		},
		{
			key: 'status' as const,
			placeholder: 'Status',
			options: STATUS_OPTIONS.map(({ value, label }) => ({
				value,
				label: <Status status={label} />,
			})),
		},
		{
			key: 'priority' as const,
			placeholder: 'Priority',
			options: PRIORITY_OPTIONS.map(({ value, label }) => ({
				value,
				label: <Priority priority={label} />,
			})),
		},
		{
			key: 'expertise' as const,
			placeholder: 'Expertise',
			options: getPersonasExpertiseOptions(expertises),
		},
	]

	const handleFilterChange = (field: keyof FiltersState, value: string | undefined) => {
		setFilters(prevFilters => ({ ...prevFilters, [field]: value }))
	}

	const clearFilters = () =>
		setFilters({
			type: '',
			status: '',
			priority: '',
			expertise: '',
		})

	const isFilterSelected = Object.values(filters).some(Boolean)

	return (
		<section className="flex gap-4">
			<p className="flex items-center gap-1 text-label-lg text-neutral-2-fg">
				<Icon name="filter" size="sm" /> Filter by:
			</p>
			<div className="flex items-center gap-2">
				{filtersConfig.map(({ key, placeholder, options }) => (
					<div key={key}>
						<SelectField
							placeholder={placeholder}
							options={options}
							value={filters[key]}
							onValueChange={v => handleFilterChange(key, v)}
							iconName="carret-down"
							size="sm"
						/>
					</div>
				))}
				<Button
					onClick={clearFilters}
					variant="ghost"
					size="sm"
					className={cn('font-normal', {
						'text-neutral-3-fg-disabled': !isFilterSelected,
						'text-brand-1-fg': isFilterSelected,
					})}
					disabled={!isFilterSelected}
				>
					Clear all
				</Button>
			</div>
		</section>
	)
}
