export const defaultLabelSizeVariants = {
	sm: 'text-body-sm',
	md: 'text-body-md',
	lg: 'text-body-lg',
}

// Variants for radio and checkbox input elements
export const selectionControlSizeVariants = {
	sm: 'h-4 w-4 min-w-4 min-h-4',
	md: 'h-4.5 w-4.5 min-w-4.5 min-h-4.5',
	lg: 'h-5 w-5 min-w-5 min-h-5',
}

export const selectionControlDisabledVariants = {
	true: 'cursor-not-allowed opacity-50 border-neutral-2-bd-disabled',
	false: 'cursor-pointer border-neutral-2-bd',
}

export const selectionControlLayoutVariants = {
	vertical: 'flex flex-col',
	horizontal: 'flex',
}

export const horizontalSelectionControlSpacingVariants = {
	sm: 'gap-1.5',
	md: 'gap-2.5',
	lg: 'gap-3.5',
}

export const verticalSelectionControlSpacingVariants = {
	sm: 'gap-1',
	md: 'gap-2',
	lg: 'gap-3',
}

export const selectionControlSpacingVariants = {
	vertical: verticalSelectionControlSpacingVariants,
	horizontal: horizontalSelectionControlSpacingVariants,
}
