import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { type z } from 'zod'
import { COMPANY_BILLING_FREQUENCY, COMPANY_PILLARS } from '#src/api/icp/admin/company/constants'
import { CompanyBillingFormSchema } from '#src/api/icp/admin/company/schemas'
import { FormField } from '#src/components/forms/v2/FormField'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import { StatusButton } from '#src/components/ui/status-button'
import { CURRENCIES } from '#src/constants/currencies'
import { COMPANY_PILLARS_LABELS } from '#src/routes/admin/company/constants'
import { type CompanySchema } from '#src/routes/company/schema'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

type Props = {
	company: z.infer<typeof CompanySchema>
}

export const CompanyBillingForm = (props: Props) => {
	const { company } = props
	const formProps = useForm<z.infer<typeof CompanyBillingFormSchema>>({
		resolver: zodResolver(CompanyBillingFormSchema),
		mode: 'onSubmit',
	})

	useEffect(() => {
		formProps.reset({
			contractStartDate: company.contractStartDate ?? undefined,
			totalSeats: company.totalSeats ?? undefined,
			credits: company.credits ?? undefined,
			activeModules: company.activeModules ?? [],
			outOfCredits: company.outOfCredits,
			billingFrequency: company.billingFrequency ?? COMPANY_BILLING_FREQUENCY.Yearly,
			currency: company.currency ?? undefined,
		})
	}, [company, formProps])

	const action = routes.admin.company.editBilling({ companyId: company.id })
	const method = 'PATCH'

	const isPending = useIsPending({
		formMethod: method,
		formAction: action,
	})
	const formId = 'company-billing-form'

	return (
		<FormWrapper
			formProps={formProps}
			formId={formId}
			action={action}
			method={method}
			className="flex w-full flex-col gap-6"
		>
			<FormField fieldType="date" name="contractStartDate" label="Start date" />
			<FormField fieldType="number" name="totalSeats" label="Manager seats" />
			<FormField fieldType="number" name="credits" label="Credits" />
			<FormField
				fieldType="select"
				name="currency"
				label="Currency"
				options={CURRENCIES.map(currency => ({ value: currency.code, label: currency.name }))}
			/>
			<FormField
				fieldType="radio"
				name="billingFrequency"
				label="Billing frequency"
				options={[
					{
						value: COMPANY_BILLING_FREQUENCY.Monthly,
						label: 'Monthly',
					},
					{
						value: COMPANY_BILLING_FREQUENCY.Quarterly,
						label: 'Quarterly',
					},
					{
						value: COMPANY_BILLING_FREQUENCY.Yearly,
						label: 'Yearly',
					},
				]}
			/>
			<FormField
				fieldType="checkbox"
				name="activeModules"
				label="Pillars"
				options={[
					{
						value: COMPANY_PILLARS.CentralizedInteligence,
						label: COMPANY_PILLARS_LABELS[COMPANY_PILLARS.CentralizedInteligence],
					},
					{
						value: COMPANY_PILLARS.Organize,
						label: COMPANY_PILLARS_LABELS[COMPANY_PILLARS.Organize],
					},
					{
						value: COMPANY_PILLARS.Prioritize,
						label: COMPANY_PILLARS_LABELS[COMPANY_PILLARS.Prioritize],
					},
					{
						value: COMPANY_PILLARS.EnableAIPlaysCopilots,
						label: COMPANY_PILLARS_LABELS[COMPANY_PILLARS.EnableAIPlaysCopilots],
					},
					{
						value: COMPANY_PILLARS.EnableGenAIVoiceRoleplay,
						label: COMPANY_PILLARS_LABELS[COMPANY_PILLARS.EnableGenAIVoiceRoleplay],
					},
				]}
			/>
			<FormField fieldType="cardSwitch" name="outOfCredits" heading="Out of credits" disableLabel />
			<div className="flex justify-start">
				<StatusButton
					status={isPending ? 'pending' : 'idle'}
					disabled={isPending}
					type="submit"
					size="sm"
					className="min-w-32"
				>
					Save
				</StatusButton>
			</div>
		</FormWrapper>
	)
}
