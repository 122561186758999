import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useLoaderData } from 'react-router'
import { EmptyStateCard } from '#src/components'
import { FormWrapper } from '#src/components/forms/v2/FormWrapper'
import ProductTip from '#src/components/product-tip'
import { Sidebar } from '#src/components/ui/sidebar'
import useCompany from '#src/hooks/useCompany'
import { type CriteriaEditAnswersLoaderType } from '#src/routes/calibrate/ecosystem-management/criteria/routes/editAnswers'
import { CriterionAnswersCard } from '#src/routes/calibrate/ecosystem-management/criteria/views/components'
import { useIsPending } from '#src/utils/misc'
import { routes } from '#src/utils/routes'

export const View = () => {
	const {
		values: { ecosystemId, type, defaultValues, schema, isCriteriaSandbox },
		data: { criteria },
	} = useLoaderData<CriteriaEditAnswersLoaderType>()
	const { companyId } = useCompany()

	const formProps = useForm({
		resolver: zodResolver(schema),
		defaultValues,
		mode: 'onSubmit',
		reValidateMode: 'onSubmit',
	})

	const formId = 'criteriaEditForm'
	const action = routes.calibrate.criteria.editAnswers({
		companyId: companyId,
		ecosystemId: ecosystemId,
		criterionType: type,
	})
	const method = 'PATCH'

	const isPending = useIsPending({
		formAction: action,
		formMethod: method,
	})

	const addNewRoute = routes.calibrate.criteria.list({
		companyId,
		ecosystemId,
		criterionType: type,
	})

	const emptyTitle = `You have not added any ${isCriteriaSandbox ? 'sandbox' : type} criteria yet.`

	const renderEmpty = (
		<EmptyStateCard
			icon="list-checked"
			title={emptyTitle}
			className="flex min-h-full flex-col"
			actions={[
				{
					title: 'Add New Criterion',
					to: addNewRoute,
				},
			]}
		/>
	)

	const productTip =
		type === 'qualification'
			? 'Qualification Criteria: The specific characteristics and attributes used to define and segment your personas based on market research and real customer data.'
			: 'Additional data to be collected about accounts that pass qualification.'

	const criterionLabel = isCriteriaSandbox ? 'Sandbox' : type === 'enrichment' ? 'Enrichment' : 'Qualification'

	return (
		<Sidebar
			headerProps={{
				heading: (
					<div className="flex items-center gap-1">
						{`${criterionLabel} Criteria`}
						{!isCriteriaSandbox && <ProductTip content={productTip} />}
					</div>
				),
				closeTo: routes.calibrate.verticals.index({ companyId, ecosystemId }),
				actions: [
					{
						title: 'Add',
						icon: 'add',
						to: addNewRoute,
						variant: 'default',
						className: 'h-[27px]',
					},
				],
			}}
			main={
				criteria.length ? (
					<FormWrapper
						formProps={formProps}
						formId={formId}
						action={action}
						method={method}
						className="flex flex-col gap-4"
					>
						{criteria.map(item => (
							<CriterionAnswersCard key={item.criterion.id} criterion={item.criterion} answers={item.answers} />
						))}
					</FormWrapper>
				) : (
					renderEmpty
				)
			}
			footerProps={{
				cancelButton: {
					label: 'Cancel',
				},
				submitButton: {
					label: 'Save',
					formId,
				},
				isSubmitting: isPending,
			}}
		/>
	)
}
