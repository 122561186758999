import { z } from 'zod'
import { API_FIELD_TYPE_ENUM, type APIFieldType } from '#src/schemas/global'
import { QUEUE_STATUS, UNITS } from '#src/utils/enumerations'
import {
	nonEmptyArray,
	rangeOnlyMin,
	RegionsSelectFieldSchema,
	transformFieldArrayValues,
	zString,
} from '#src/utils/validation'

export const CriterionAnswerValueSchema = z
	.string()
	.or(z.number())
	.or(z.array(z.string()))
	.or(RegionsSelectFieldSchema)
	.nullable()
	.optional()

export const CriterionAnswerAPISchema = z.object({
	criterionId: z.number(),
	verticalId: z.number(),
	vertical: z.string(),
	question: z.string(),
	value: CriterionAnswerValueSchema,
})

export const CriterionQuestionAPISchema = z.object({
	id: z.number(),
	name: z.string(),
	source: z.string(),
	instructions: z.string().nullable(),
	manualInstructions: z.string().nullable(),
	type: z.enum(API_FIELD_TYPE_ENUM),
	logic: z.string().nullable(),
	unit: z.string().nullable(),
	options: z.array(z.string()),
	usedOptions: z.array(z.string().nullable()).nullable(),
	isTemplate: z.boolean(),
	isThirdParty: z.boolean(),
	isEnrichment: z.boolean(),
	isSandbox: z.boolean(),
	inBizmodel: z.boolean().nullable(),
	ecosystems: z.array(z.string()),
	isAnsweredInEcosystem: z.boolean(),
	llmModel: z.string(),
})

export const CriteriaQuestionAPISchema = z.array(CriterionQuestionAPISchema)

export const CriterionEnrichedAnswerAPISchema = z.object({
	id: z.number(),
	criterionId: z.number(),
	question: z.string().nullable(),
	value: z.string().or(z.number()).nullable(),
	verticalId: z.number(),
	status: z.nativeEnum(QUEUE_STATUS),
	sourceLinks: z.array(z.string()),
	sourceReasoning: z.string().nullable(),
	langsmithUrl: z.string().nullable(),
	createdAt: z.string().datetime(),
})

export const CriterionEnrichedAnswersAPISchema = z.array(CriterionEnrichedAnswerAPISchema)

export const CriterionAPISchema = z.object({
	criterion: CriterionQuestionAPISchema,
	answers: z.array(CriterionAnswerAPISchema),
	enrichedAnswers: z.array(CriterionEnrichedAnswersAPISchema),
})

export const CriteriaAPISchema = z.array(CriterionAPISchema)

const OptionsSchema = z.array(z.union([zString(), nonEmptyArray()]))

export const CriterionNewSchema = z.object({
	name: zString(),
	source: zString(),
	instructions: zString(z.string().optional()).nullable(),
	manualInstructions: zString(z.string().optional()).nullable(),
	type: z.enum(API_FIELD_TYPE_ENUM),
	logic: z.string().nullable().optional(),
	options: OptionsSchema.optional(),
	unit: zString(z.string().optional()).or(z.nativeEnum(UNITS)),
	inBizmodel: z.boolean().optional(),
	checkboxUnit: z.array(zString()).optional(),
	checkboxManualInstructions: z.array(zString()).optional(),
	otherUnit: zString(z.string().optional()),
	toggleAll: z.boolean().optional(),
	llmModel: zString(z.string()),
})

export const CriterionNewPayloadSchema = CriterionNewSchema.extend({
	options: z.array(zString()).optional(),
	isEnrichment: z.boolean(),
	isSandbox: z.boolean().optional(),
})

export const getCriterionNewFormSchema = (type: APIFieldType) => {
	const rangeOptionsSchema = z.array(rangeOnlyMin).transform(transformFieldArrayValues)

	switch (type) {
		case 'multiselect':
			return CriterionNewSchema.extend({
				logic: zString(),
			})

		case 'number':
			return CriterionNewSchema.extend({
				otherUnit: zString(z.string().optional()),
			})

		case 'range':
			return CriterionNewSchema.extend({
				options: rangeOptionsSchema,
			})

		default:
			return CriterionNewSchema
	}
}

export const CriterionAssignPayloadSchema = z.object({
	criterionId: z.string(),
	ecosystemId: z.string(),
})

export type CriterionAnswerFieldId = `${string}::${string}::${string}`

export const CriterionAllAssignedVerticalsAnswersPayload = z.object({
	isEnrichment: z.boolean(),
	ecosystemId: z.string(),
	criteria: z.array(
		z.object({
			criterionId: z.string(),
			answers: z.array(
				z.object({
					verticalId: z.string(),
					value: CriterionAnswerValueSchema,
				}),
			),
		}),
	),
})

export const GetInstructionsAPISchema = z.object({
	instructions: z.string(),
})

export const GetInstructionsFormSchema = z.object({
	criterionName: z.string(),
	manualInstructions: z.string(),
	ecosystemName: z.string(),
	answers: z.array(
		z.object({
			verticalId: z.string(),
			value: CriterionAnswerValueSchema,
		}),
	),
})
