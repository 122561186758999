import { z } from 'zod'
import { PlaysExpertsItemAPISchema } from '#src/api/icp/admin/plays-experts/schemas'
import { PLAY_STATUS } from '#src/api/icp/company/plays/constants'
import { zNumber, zString } from '#src/utils/validation'

export const PlaysTemplatesItemAPISchema = z.object({
	id: z.number(),
	name: z.string(),
	instructions: z.string(),
	inCopilot: z.boolean(),
	inCrm: z.boolean(),
	createdAt: z.string(),
	personaTypes: z.array(z.string()),
	tags: z.array(z.string()),
	status: z.nativeEnum(PLAY_STATUS),
	createdBy: z.string(),
	templateExpertId: z.number().nullable(),
	isGlobal: z.boolean(),
	templateExpert: PlaysExpertsItemAPISchema.nullable(),
})

export const PlaysTemplatesListItemAPISchema = PlaysTemplatesItemAPISchema.array()

export const PlaysTemplatesFormSchema = z.object({
	name: zString(),
	instructions: zString(),
	inCopilot: z.boolean(),
	inCrm: z.boolean(),
	personaTypes: z.array(z.string()).default([]),
	expertise: z.array(z.string()).default([]),
	tags: z.array(z.string()).default([]),
	status: z.nativeEnum(PLAY_STATUS),
	conditions: z
		.array(
			z.object({
				variable_id: zNumber(z.number()),
				operator: z.string(),
				value: z.string().nullable(),
				condition: z.string(),
			}),
		)
		.default([]),
	isGlobal: z.boolean(),
	templateExpertId: zNumber(z.number()).nullable(),
})
