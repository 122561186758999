import { type ActionFunctionArgs } from 'react-router'
import { playsQueriesKeys } from '#src/api/icp/company/plays/queries'
import { verticalsQueriesKeys } from '#src/api/icp/company/verticals/queries'
import { showToast } from '#src/context/ToastContext'
import { client } from '#src/main'
import { criteriaKeys } from '#src/routes/calibrate/ecosystem-management/criteria/queries'
import { ecosystemKeys } from '#src/routes/calibrate/ecosystem-management/ecosystem/queries'
import { validateRouteParams } from '#src/utils/misc'
import { getCompany } from '#src/utils/server/company'
import { serverFormErrorHandler } from '#src/utils/server/form-errors'
import { unassignCriterion } from '../mutations'

export type ActionResType = Awaited<ReturnType<typeof action>>

export const action = async ({ params }: ActionFunctionArgs) => {
	validateRouteParams(params, ['ecosystemId', 'criterionId'])
	const { company } = await getCompany(params)

	try {
		await unassignCriterion(company.id, params.ecosystemId, params.criterionId)

		await Promise.all([
			client.invalidateQueries({ queryKey: criteriaKeys.all }),
			client.invalidateQueries({ queryKey: ecosystemKeys.all }),
			client.invalidateQueries({ queryKey: verticalsQueriesKeys.all }),
			// connected to plays, because it has criterias inside, so need to invalidate all queries below
			client.invalidateQueries({ queryKey: playsQueriesKeys.all }),
		])
		showToast({
			message: 'Criterion removed',
			type: 'success',
		})
		return { ok: true }
	} catch (e) {
		return await serverFormErrorHandler(e)
	}
}
